<template>
  <div class="home">
    
    <h1>{{searchevents(this.labels,'menu','mycontacts')}}</h1>

    <button v-if="this.selectedCompanyId!=null" type="button" class="btn btn-success" style="font-size: .8em;" @click="setSelectedCompanyId(null,null)" >{{searchevents(labels,'menu','backglobal')}}</button>
    <button v-if="this.selectedCompanyId==null" type="button" class="btn btn-success" style="font-size: .8em;" @click="backToMyCompanies()">{{searchevents(this.labels,'menu','mycompanies')}} </button>

    <!-- GoBiz Companies ########################################################################################### -->
    <div v-if="this.selectedCompanyId==null" class="container tablelist" style="max-width: 70%;">
            <h2>{{searchevents(this.labels,'menu','gobizmynetwork')}}</h2>

            <template>        
                <table class="table tablelist" >
                <thead>
                <tr>
                    <th>{{searchevents(this.labels,'companies','companyName')}}</th>
                    <th>{{searchevents(this.labels,'menu','openglobal')}}</th>
                    <th>{{searchevents(this.labels,'menu','decline')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="row in mynetwork" :key="row.id" >
                    <td  :key="row.companyName">
                        {{row.companyName}}
                    </td>
                    <td  :key="row.usersRoleRole">
                        <button type="button" class="btn btn-success" style="font-size: .8em;" @click="setSelectedCompanyId(row.id,'companies')" :key="row.id" >{{searchevents(labels,'menu','openglobal')}}</button>
                    </td>
                    <td  :key="row.usersRoleRole">
                        <button type="button" class="btn btn-danger" style="font-size: .8em;" @click="onClickDeleteNetwork(row.id,'companies')" :key="row.id" >{{searchevents(labels,'menu','delete')}}</button>
                    </td>
                </tr>
                </tbody>
            </table>
            
            </template>

    </div> <!-- termine container -->


    <div v-if="this.selectedCompanyId!=null" class="container tablelist" style="max-width: 70%;">
            <h2>{{this.mycompanies.companyName}}</h2>

            <template>        
                <table class="table tablelist" >
                <thead>
                <tr>
                    <th>{{searchevents(this.labels,'table','messagechat')}}</th>
                    <th>{{searchevents(this.labels,'table','messagetranslatedchat')}}</th>
                    <th>{{searchevents(this.labels,'table','viewedchat')}}</th>
                    <th>{{searchevents(this.labels,'table','createdatchat')}}</th>
                    <th>{{searchevents(this.labels,'table','usertextnamechat')}}</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                    <th>
                        <textarea class="form-control" rows="3" id="newmessage" v-model="newmessage"></textarea>
                    </th>
                    <th> </th>
                    <th>{{searchevents(this.labels,'chat','sendnewmessage')}}</th>
                    <th>
                        <button type="button" class="btn btn-success" style="font-size: .8em;" @click="sendNewMessage()" >{{searchevents(labels,'menu','sendglobal')}}</button>
                    </th>
                    <th> </th>
                </tr>
                <tr v-for="row in mynetworkChatList" :key="row.id" >
                    <td  :key="row.message">
                        <div :style="setBackgroudStyle(''+row.userId+'',''+getUserId+'')">{{row.message}}</div>
                    </td>
                    <td  :key="row.messageTranslated">
                        {{row.messageTranslated}}
                        <button v-if="row.messageTranslated=='' || row.messageTranslated==null" type="button" class="btn btn-success" style="font-size: .8em;" @click="translateGoogle(row.message, row.id)" :key="row.id" >{{searchevents(labels,'menu','translateglobal')}}</button>
                    </td>
                    <td  :key="row.viewed">
                        {{renderMessageViewed(row.viewed)}}
                    </td>
                    <td  :key="row.createdAt">
                        {{frontEndDateFormat(row.createdAt)}}
                    </td>
                    <td  :key="row.userInfo">
                        {{row.userInfo}}
                    </td>
                    
                </tr>
                </tbody>
            </table>
            
            </template>

    </div> <!-- termine container -->



  </div>

</template>

<script>

import router from '@/router';
import gAxios from 'axios';


export default {

  name: 'myusers',
  data () {
    return {
      mycompanies : [],
      myusers : [],
      mynetwork : [],
      mynetworkCH : [],
      mynetworkINFO : [],
      mynetworkChatList : [],
      mynetworkTable : null,
      selectedCompanyId : null,
      usersRoleRoleCompany : 'ooo',
      confirmDeleteNetwork : 'Confermi la cancellazione? / Confirm the cancellation?',
      newmessage : null
    }
  },
  methods : {
    backToMyCompanies(){
        router.push('/mycompany');
    },
    renderMessageViewed(viewed){
        if(viewed==1){return 'V'; }else{return ''; }
    },
    frontEndDateFormat(date) {
        
        let dateDay = date.split('T');
        let dateHourTemp = dateDay[1].split('Z');
        let dateHour = dateHourTemp[0].split('.');
        let dateEng = dateDay[0];
        let dateItaTemp = dateDay[0].split('-');
        let dateIta = dateItaTemp[2]+'-'+dateItaTemp[1]+'-'+dateItaTemp[0];
        
        if(this.lang=='Ita'){ return dateIta+' '+dateHour[0]; }else{ return dateEng+' '+dateHour[0]; }
        
    },
    setBackgroudStyle(userIdx,userIdMe){
        
        if(userIdMe == userIdx){
            return 'border: 2px solid red; padding: 20px; border-radius: 20px 60px; background-color: aquamarine;';
        }else{
            return 'border: 2px solid red; padding: 20px; border-radius: 60px 20px; background-color: #b3d7ff;';
        }
    },
    updateTranslation(messageTranslated,chatId){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    companyIdFrom : this.getCompanyIdTo,
                    companyIdTo : this.selectedCompanyId,
                    companyTable : this.mynetworkTable,
                    blockchainsHash : '',
                    message : this.newmessage,
                    messageTranslated : messageTranslated,
                    chatId : chatId,
                    viewed: '0',
                    actionToDo : 'MODT'
                },auth)
                .then(res => {
                    this.setSelectedCompanyId(this.selectedCompanyId,this.mynetworkTable);
                    return res;
                })
                .catch(
                    error => {
                        return error;
                    }
                );

            }else{
                return null;
            }
    },
    sendNewMessage(){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {
               
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    companyIdFrom : this.selectedCompanyId,
                    companyIdTo : this.getCompanyIdTo,
                    companyTable : this.mynetworkTable,
                    blockchainsHash : '',
                    message : this.newmessage,
                    messageTranslated : '',
                    chatId : '',
                    viewed: '0',
                    actionToDo : 'ADD'
                },auth)
                .then(res => {
                    this.setSelectedCompanyId(this.selectedCompanyId,this.mynetworkTable);
                    return res;
                })
                .catch(
                    error => {
                        return error;
                    }
                );
                this.newmessage = null;
            }else{
                return null;
            }
    },
    onClickDeleteNetwork(companyId,table){
      if (confirm(this.confirmDeleteNetwork))
        //this.delSelectedCompanyId(companyId,table)
        alert('Capiamo cosa fare'+table+companyId);
    },
    delSelectedCompanyId(companyId,table){
        
            const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {

                // recupero i dati aziendali
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/NetworkJoinByMe',
                {
                    userId : userId, 
                    companyId : companyId, 
                    companyTable : table,
                    actionToDo : 'DEL'
                },auth)
                .then(res => {
                    this.getMyNetwor();
                    return res;
                })
                .catch(
                    error => {
                        return error;
                    }
                );

            }else{
                return null;
            }
        
    },
    translateGoogle(text,chatId){

        let texToTranslate = text;
        let langFrom = this.lang;
        let langTo = '';
        let textTranslated = '';
        if(langFrom == 'Ita'){ langFrom = 'it'; langTo = 'en';}else{ langFrom = 'en'; langTo = 'it'; }
        gAxios.get('https://translate.googleapis.com/translate_a/single?client=gtx&sl='+langFrom+'&tl='+langTo+'&dt=t&q='+texToTranslate,
                {
                   
                })
                .then(res => {
                    
                    let tempTextTranslated = res.data[0];
                    tempTextTranslated.forEach(element => {
                        textTranslated += element[0]+' ';
                        
                    });
                    
                    // se ritorno correttamente, allora aggiorno la chat
                    this.updateTranslation(textTranslated,chatId);
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },
    setMyNetworkTable(table){
        this.mynetworkTable = table;
    },
    getMyNetwor(){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
            if(userId!=null)
            {
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.selectedCompanyId,
                   companyIdFrom : this.getCompanyIdTo,
                   companyTable : this.mynetworkTable,
                   userId :userId,
                   action : 'CONT'
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    this.mynetwork = network;
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
            }else{
                return null;
            }
    },
    setSelectedCompanyId(companyId,table){
        
        this.selectedCompanyId = companyId;
        this.setMyNetworkTable(table);
        

        if(this.selectedCompanyId!=null){
            // chiamo la chat e i dati dell'azienda
            const userId = this.getUserId;
            const userIdParent = this.getIdUsersParent;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {

                // recupero i dati aziendali
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompany',
                {
                    id : this.selectedCompanyId
                })
                .then(res => {
                    
                    this.mycompanies = res.data.company;
                    
                })
                .catch(
                    error => {
                        return error;
                    }
                );
                

                // recupero tutte le chat
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/getchats',
                {
                   companyIdTo : this.selectedCompanyId,
                   companyIdFrom : this.getCompanyIdTo,
                   companyTable : this.mynetworkTable,
                   userIdParent : userIdParent,
                   userId :userId,
                   action: 'CONT'
                },auth)
                .then(res1 => {
                    
                    this.mynetworkChatList = res1.data.chats;
                    
                    
                })
                .catch(
                    error => {
                        return error;
                    }
                );

            }else{
                return null;
            }
        }

    },
    modifyCompany(company){
        this.$store.dispatch('SetregisteredCompany', company );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updatecompany');
        
    },
    modifyUsers(user){
        this.$store.dispatch('SetregisteredUser', user );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updateuser');
        
    },
    getM(){
        
    return null;
    },
    searchevents(events,filterValue,label){
    //events = events2;
    if(events){
        
        const filter = event => 
        event.tableName.includes(filterValue) &&
        event.fieldName.includes(label)
    
        var filteredArray = events.filter(filter);
        if(filteredArray.length >0)
        {
        var labelName = 'label'+this.lang;
        return filteredArray[0][labelName];
        }else{
        return '--';
        }
        
    }else{
        return null;
    }
    
    }
        
  },
  computed :{
    
    lang(){
      var lng = this.$store.getters.lang;
        if(lng)
        {
          return lng;
        }else{
          return '';
        }
    },
    labels(){
      return this.$store.getters.labels;
    },
    dropM(){
      
      var menu = this.$store.getters.dropM;
      if(menu){
        //this.dropM1 = menu[11];
        return menu;
      }else{
        return null;
      }
      
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
    getUserId(){
        return this.$store.getters.getUserId;
    },
    getIdUsersParent(){
        return this.$store.getters.getIdUsersParent;
    },
    getCompanyIdTo(){
        return this.$store.getters.getCompamyIdTo;
    }
  },
  created() {
      
    
  },
  watch : {
      dropM: function () {
      // potrei anche leggere il nuovo e il vecchio valore (newdropMservLandlinePrefixId,olddropMservLandlinePrefixId)
      return null;
    },
      getM: function () {
          return null;
    }
        
  },
  components: {
    
  },
  mounted: function () {
     
    
    this.$nextTick(function () {

        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
            if(userId!=null)
            {
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.selectedCompanyId,
                   companyIdFrom : this.getCompanyIdTo,
                   companyTable : this.mynetworkTable,
                   userId :userId,
                   action : 'CONT'
                },auth)
                .then(res => {
                    
                    
                    let network = res.data.network;
                    let networkId = '';
                    network.forEach(element => {
                        if(networkId != element.id)
                        {
                            this.mynetwork.push(element);
                        }
                        networkId = element.id;
                    });

                    //this.mynetwork = network;
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
            }else{
                return null;
            }
    })
    
}
}

</script>
