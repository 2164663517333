<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/gobiz-logo-rectangle-blue.png" width="250em">
    
    <hr>
    
    <div  v-if="this.lang=='Ita'"> 
    <h5 style="text-align: left; margin-left: 10%; margin-right:10%">

    <strong>Vetrina</strong> <br> Realizzare una vetrina "virtuale" per promuovere la propria impresa a livello locale e internazionale
     <br> 
     <br> 
<strong>Matchmaking Smart e Advanced </strong> <br>Individuare partner, fornitori e clienti "su misura" proposti da algoritmi «intelligenti» offerti tramite un strumenti di «smart match making» che considera il profilo dell’impresa cliente e ottimizza i risultati in base al paese target e agli obiettivi di business.
 <br> 
     <br> 
<strong>Networking</strong> <br>Avviare relazioni commerciali ed interagire con imprese la cui identità/affidabilità è stata oggetto di verifica e certificazione e con le imprese presenti nei registri nazionali integrati (Italia, Danimarca e Regno Unito con progressiva estensione ad altri Paesi)
 <br>
     <br> 
<strong>Chat</strong> <br>Gestire ogni interazione/comunicazione con altre imprese sfruttando la traduzione simultanea multilingua <br> 
     <br>
    </h5>
   </div>

    <div  v-if="this.lang=='Eng'"> 
    <h5 style="text-align: left; margin-left: 10%; margin-right:10%">
    <strong>Vetrina</strong> <br> Realizzare una vetrina "virtuale" per promuovere la propria impresa a livello locale e internazionale
     <br> 
     <br> 
<strong>Matchmaking Smart e Advanced </strong> <br>Individuare partner, fornitori e clienti "su misura" proposti da algoritmi «intelligenti» offerti tramite un strumenti di «smart match making» che considera il profilo dell’impresa cliente e ottimizza i risultati in base al paese target e agli obiettivi di business.
 <br> 
     <br> 
<strong>Networking</strong> <br>Avviare relazioni commerciali ed interagire con imprese la cui identità/affidabilità è stata oggetto di verifica e certificazione e con le imprese presenti nei registri nazionali integrati (Italia, Danimarca e Regno Unito con progressiva estensione ad altri Paesi)
 <br>
     <br> 
<strong>Chat</strong> <br>Gestire ogni interazione/comunicazione con altre imprese sfruttando la traduzione simultanea multilingua <br> 
     <br>
    </h5>
   </div>
    
    <div  v-if="this.lang=='Den'"> 
    <h5 style="text-align: left; margin-left: 10%; margin-right:10%">
    <strong>Vetrina</strong> <br> Realizzare una vetrina "virtuale" per promuovere la propria impresa a livello locale e internazionale
     <br> 
     <br> 
<strong>Matchmaking Smart e Advanced </strong> <br>Individuare partner, fornitori e clienti "su misura" proposti da algoritmi «intelligenti» offerti tramite un strumenti di «smart match making» che considera il profilo dell’impresa cliente e ottimizza i risultati in base al paese target e agli obiettivi di business.
 <br> 
     <br> 
<strong>Networking</strong> <br>Avviare relazioni commerciali ed interagire con imprese la cui identità/affidabilità è stata oggetto di verifica e certificazione e con le imprese presenti nei registri nazionali integrati (Italia, Danimarca e Regno Unito con progressiva estensione ad altri Paesi)
 <br>
     <br> 
<strong>Chat</strong> <br>Gestire ogni interazione/comunicazione con altre imprese sfruttando la traduzione simultanea multilingua <br> 
     <br>
    </h5> 
   </div>


    <br>
    <div  v-if="this.lang=='Ita'" v-html="this.titlePage"> </div>
    <h4 v-if="this.lang=='Ita'"><div v-html="this.contentPage">  </div> </h4>
    
    <div  v-if="this.lang=='Eng'" v-html="this.titlePageEng"> </div>
    <h4 v-if="this.lang=='Eng'"><div v-html="this.contentPageEng">  </div> </h4>

    <div  v-if="this.lang=='Den'" v-html="this.titlePageDen"> </div>
    <h4 v-if="this.lang=='Den'"><div v-html="this.contentPageDen">  </div> </h4>
    
  </div>
</template>

<script>
// @ is an alias to /src
import gAxios from 'axios';

export default {
  name: 'help',
  data () {
    return {
      name : 'stefano',
      surname : '',
      email : '',
      password : '',
      titlePage : null,
      titlePageEng : null,
      titlePageDen : null,
      contentPage : null,
      contentPageEng : null,
      contentPageDen : null
    }
  },
  computed : {
    labels(){
      
      return this.$store.getters.labels;
    },
    lang(){
      return this.$store.getters.lang;
    },
  },
  components: {
    
  },
  mounted: function(){
    this.$nextTick(function () {
        
        // ITA
        gAxios.get('./pages/new_ita.txt',
        {
            
        })
        .then(res => {
          
          this.titlePage = '';
          this.contentPage = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

        // ENG
        gAxios.get('./pages/new_eng.txt',
        {
            
        })
        .then(res => {
          this.titlePageEng = '';
          this.contentPageEng = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

        // DEN
        gAxios.get('./pages/new_den.txt',
        {
            
        })
        .then(res => {
          this.titlePageDen = '';
          this.contentPageDen = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );
            
    })
  }
}



</script>






<style>

.full-width-image {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none; /* Per evitare che l'immagine interferisca con l'interazione dell'utente */
}

</style>
