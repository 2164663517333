<template>
  <div class="container">
    <div class="row" style="margin-bottom:100px;">
      <div class="col">
        <template v-if="poupvisibile">
          <div class="alert alert-danger" role="alert">
            <i class="fas fa-fw fa-exclamation-circle fa-2x mr-2 align-self-center"></i>
            <h4>{{msg}}</h4>
          </div>
        </template>
        <form @submit.prevent="onSubmit">
          <div class="form-group">
            <img alt="Vue logo" src="../../assets/gobiz-logo-rectangle-blue.png" width="250em" style="width: 160px;">
          </div>


          
    <div class="form-group">
            <!-- <h2>{{searchevents(this.labels,'login','header')}}</h2> -->
            <h3>{{searchevents(this.labels,'dialog','enteranewera')}}</h3>
            <hr>
          </div>      
   <section>
       <h1>{{searchevents(this.labels,'dialog','googleaccess')}}</h1>
      <!-- <button @click="login">Login Using Google</button> -->
      <GoogleLogin :callback="callbackz" class="custom-google-button" />
   </section>
  <!-- 
<template>
  <GoogleLogin :callback="callbackz">
    <button type="submit" style="max-width: 70%;" class="btn btn-dark loginbutton">Login Using Google</button>
  </GoogleLogin>
</template>
-->
<br>
    <hr>
     <h1>{{searchevents(this.labels,'dialog','mailaccess')}}</h1>
          <div class="form-group">
                    
            <!-- <label for="email" style="width: 100%;">{{searchevents(this.labels,'login','emaillogin')}}</label> -->
            <input class="form-control logininput" style="max-width: 50%;" type="email" id="email" v-model="email" :placeholder="searchevents(this.labels,'login','emaillogin')" required>
          </div>
         
         
          <div class="form-group" v-if="this.hidepws">
            <!-- <label for="password" style="width: 100%;">{{searchevents(this.labels,'login','password')}}</label> -->
            <input class="form-control logininput" style="margin-left: 33px; max-width: 50%;" :type="type" id="password" v-model="password" :placeholder="searchevents(this.labels,'login','password')" required>
            <span v-if="type=='password'" class="fa fa-eye" style="font-size: 30px; color: rgb(44, 62, 80); width: 100%;cursor: pointer; display: inline; position: relative; right: 40px;top: 4px;" @click="showPassword"></span>
            <span v-if="type!='password'" class="fa fa-eye-slash" style="font-size: 30px; color: rgb(44, 62, 80); width: 100%;cursor: pointer; display: inline; position: relative; right: 40px;top: 4px;" @click="showPassword"></span>
           </div>
           <hr>
          <div class="form-group">
            <button type="submit" style="max-width: 50%;" class="btn btn-dark loginbutton">{{searchevents(this.labels,'login','accesslogin')}}</button>
          </div>
          <div class="form-group">
            {{searchevents(this.labels,'menu','rememberpassword')}}
            
            <a ><router-link :to="{ name : 'remember'}">{{searchevents(this.labels,'menu','rememberherepassword')}}</router-link></a>
          </div>
        </form>
      </div>
    </div>

    




    <br>
     <br>


    <div>
  
 
</div>

    
    <!--
    <hr style="margin-top:80px;">
    <div class="row" style="margin-bottom:100px;">
      <div class="col">

        <template v-if="poupvisibile2">
          <div class="alert alert-warning" role="alert">
            {{msg}}
          </div>
        </template>

        <form @submit.prevent="onSubmitR">
          <h2>{{searchevents(this.labels,'login','remember')}}</h2>
          <hr>
          <div class="form-group">
            
            <input class="form-control logininput" type="email" id="emailR" v-model="emailR" :placeholder="searchevents(this.labels,'login','emaillogin')">
          </div>
          
          <button type="submit" class="btn btn-dark loginbutton">{{searchevents(this.labels,'login','rememnberaccess')}}</button>
        </form>
      </div>
    </div>
    -->

  </div>
</template>


<script>

//import PopUpRisposta from '@/components/PopUpRisposta.vue';
import gAxios from 'axios';
//import GoogleLogin from "vue3-google-login";
import { googleAuthCodeLogin } from "vue3-google-login";
import { decodeCredential } from 'vue3-google-login';
//import GAuth from 'vue3-google-oauth2';



export default {

name: 'Login',
  data () {
    return {
      type: 'password',
      email : '',
      emailR : '',
      password : '',
      poupvisibile : false,
      poupvisibile2 : false,
      msg : '',
      user: '',
      hidepws: true
    }
  },
  
  components : {
    //GoogleLogin,
  },

  computed : {
    lang(){
      return this.$store.getters.lang;
    },
    labels(){
      return this.$store.getters.labels;
    },
    
  },
  methods : {
    // signInWithGoogle() {
      // Avvia l'autenticazione con Google utilizzando il metodo signIn
    //  this.$refs.googleLogin.signIn();
    // },
    callbackz(response){
      
      const userData = decodeCredential(response.credential);
      //console.log("Handle the userData", userData);

      //console.log("Handle the userData", userData.email);
      //console.log("Handle the userData", userData.family_name);
      //console.log("Handle the userData", userData.given_name);
      //console.log("Handle the userData", userData.name);

      this.email = userData.email;
      this.password = 'fammipassare';
      if(userData.email!='' && userData.email!=null){
        this.hidepws=false;
      }
      this.onSubmit();
    },

    login(){
      googleAuthCodeLogin().then((response) => {
        //console.log("Handle the response", response);
        
        this.callbackz(response);
        
        
      })
    },
    
    showPassword() {
        if(this.type === 'password') {
            this.type = 'text'
        } else {
            this.type = 'password'
        }
    },
    onSubmit(){
      const formData = {
        email : this.email,
        password : this.password
      }
      
      //console.log(this.email + "-------" + this.password);

      this.$store.dispatch('login', {email: formData.email, password: formData.password})
      
      setTimeout(this.vefifyUserLogged,1000);

    },
    onSubmitR(){
      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/rememberPassword',
                {
                   email : this.emailR
                })
                .then(res => {
                    
                    if(res.data.messages=='OK')
                    {
                      this.poupvisibile2 = true;
                      this.msg = this.searchevents(this.labels,'login','emailsentsuccessfully');
                      
                      setTimeout(this.closeAlert,3000);
                    }else{
                      this.poupvisibile2 = true;
                      this.msg = this.searchevents(this.labels,'login','emailnotpresent');
                      
                      setTimeout(this.closeAlert,3000);
                    }

                    this.emailR = '';
                })
                .catch(
                error => {
                
                return error;
                }
                );
      

    },
    vefifyUserLogged(){
      this.$store.dispatch('notIsLogged').then(()=>{
        this.poupvisibile = true;
        this.msg = this.searchevents(this.labels,'login','accessdenied');
        
        
        setTimeout(this.closeAlert,3000);
      }).catch(()=>{
        this.poupvisibile = false;
        
      })
      
    },
    closeAlert(){
      this.poupvisibile = false;
      this.poupvisibile2 = false;
    },
    searchevents(events,filterValue,label){
        if(events){
          const filter = event => 
            event.tableName.includes(filterValue) &&
            event.fieldName.includes(label)
        
          var filteredArray = events.filter(filter);
          if(filteredArray.length >0)
          {
            if(this.lang)
            {
              var labelName = 'label'+this.lang;
              return filteredArray[0][labelName];
            }else{
              return '--';
            }
            
          }else{
            return '--';
          }
        }else{
          return null;
        }
      }

    }
 }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.count{
  font-size: 7em;
}
.btn{
  font-size: 2.65em;
}
.logininput{
    width: 70%;
    min-height: 90px;
    display: inline-block;
    background-color: #ffffff;
    border: 1px solid #0c294b; 
    color: #0c294b;
    font-size: 1.3em;
}

</style>
