import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router';
//import {VueRouter,createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Counter from '../components/Counter.vue'
import Register from '../components/auth/Register.vue'
import RegisterGoogle from '../components/auth/RegisterGoogle.vue'
import Remember from '../components/auth/Remember.vue'
import UpdateCompany from '../components/auth/UpdateCompany.vue'
import UpdateUser from '../components/auth/UpdateUser.vue'
import MyCompanies from '../components/auth/MyCompanies.vue'
import MyUsers from '../components/auth/MyUsers.vue'
import MyNetwork from '../components/auth/MyNetwork.vue'
import Login from '../components/auth/Login.vue'
import Dashboard from '../components/auth/Dashboard.vue'
import MyContacts from '../components/auth/MyContacts.vue'
import MatchMaking from '../components/auth/MatchMaking.vue'
import MyAgreements from '../components/auth/MyAgreements.vue'
import ThankRegistration from '../views/ThankRegistration.vue'
import ConfirmRegistration from '../views/ConfirmRegistration.vue';
import ConfirmNotInviting from '../views/ConfirmNotInviting.vue';
import MyChats from '../components/auth/MyChats.vue';
import TermsAndConditions from '../views/TermsAndCondition.vue';
import Privacy from '../views/Privacy.vue';
import Support from '../views/Support.vue';
import Aboutus from '../views/Aboutus.vue';
import Help from '../views/Help.vue';
import Faq from '../views/Faq.vue';
import Lovegreen from '../views/Lovegreen.vue';
import store from '../store';
import VueGtag from "vue-gtag";
import AddMailBo from '../components/auth/AddMailBo.vue';
import MyAdvisoring from '../components/auth/MyAdvisoring.vue';
import MyOndemandA from '../components/auth/MyOndemandA.vue';
import MyOndemandB from '../components/auth/MyOndemandB.vue';
import MyOndemandC from '../components/auth/MyOndemandC.vue';
import Report from '../components/auth/Report.vue';
import Overview from '../views/Overview.vue';
import Ourusers from '../views/Ourusers.vue';
import Ourdata from '../views/Ourdata.vue';
import Markets from '../views/Markets.vue';
//import NotFoundComponent from '../views/NotFoundComponent.vue';

import VueMeta from 'vue-meta'


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [

      // Rotta catch-all per la gestione dell'errore 404
     // {
    //    path: '/:pathMatch(.*)*',
     //   name: 'NotFound',
      //  component: NotFoundComponent,
    //  },
    //  { path: '/:pathMatch(.*)*' , beforeEnter: (to, from, next) => { next('/404') } },
   //   {
   //       path: '/404',
   //       name: 'NotFound',
          //component: () => import('../views/NotFoundComponent.vue')
   //   },
   //  {
  //    path: '/',
  //    name: 'Home',
   //   component: Home,
   // },
{
    path: '/dashboard',
    component: Dashboard,
    name: 'dashboard',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  } ,
  {
    path: '/report',
    component: Report,
    name: 'report',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  } ,
 
  {
    path: '/termsandconditions',
    name: 'termsandconditions',
    component: TermsAndConditions
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/counter',
    component: Counter,
    name: 'counter',
  },
  {
    path: '/thankregistration',
    component: ThankRegistration,
    name: 'thankregistration',
  },
  {
    path: '/lovegreen',
    component: Lovegreen,
    name: 'lovegreen',
  },
  
  {
    path: '/confirmnotinviting/:guid?/:tab?/:coo?',
    component: ConfirmNotInviting,
    name: 'confirmnotinviting',
  },
  {
    path: '/confirmregistration/:guid?/:act?',
    component: ConfirmRegistration,
    name: 'confirmregistration',
  },
  {
    path: '/register',
    component: Register,
    name: 'register',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next('/dashboard');
      }
      next();
    }
  },
  {
    path: '/registergoogle',
    component: RegisterGoogle,
    name: 'registergoogle',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next('/dashboard');
      }
      next();
    }
  },
  {
    path: '/remember',
    component: Remember,
    name: 'remember',
  },
  {
    path: '/support',
    component: Support,
    name: 'support',
  },
  {
    path: '/aboutus',
    component: Aboutus,
    name: 'aboutus',
    meta: { title: 'Gobiz - Be Smart Go Global',name: 'about us', content: 'La nuova piattaforma Europea per la ricerca di partner e fornitori.' }
  },
  {
    path: '/help',
    component: Help,
    name: 'help',
  },
  {
    path: '/faq',
    component: Faq,
    name: 'faq',
  },
  {
    path: '/overview',
    component: Overview,
    name: 'overview',
  },
  {
    path: '/ourdata',
    component: Ourdata,
    name: 'ourdata',
  },
  {
    path: '/markets',
    component: Markets,
    name: 'markets',
  },
  {
    path: '/ourusers',
    component: Ourusers,
    name: 'ourusers',
  },
  {
    path: '/addmailbo',
    component: AddMailBo,
    name: 'addmailbo',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  },
  {
    path: '/myuser',
    component: MyUsers,
    name: 'myusers',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  },
  {
    path: '/mynetwork',
    component: MyNetwork,
    name: 'mynetwork',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  },
  {
    path: '/mychats',
    component: MyChats,
    name: 'mychats',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  },
  {
    path: '/matchmaking:mm',
    component: MatchMaking,
    name: 'matchmaking',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  },
  {
    path: '/mycontacts',
    component: MyContacts,
    name: 'mycontacts',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  },
  {
    path: '/mycompany',
    component: MyCompanies,
    name: 'mycompany',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  },
  {
    path: '/updatecompany',
    component: UpdateCompany,
    name: 'updatecompany',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  },
  {
    path: '/updateuser',
    component: UpdateUser,
    name: 'updateuser',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  },
  {
    path: '/MyAdvisoring',
    component: MyAdvisoring,
    name: 'MyAdvisoring',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  },
  {
    path: '/MyOndemandA',
    component: MyOndemandA,
    name: 'MyOndemandA',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  },
  {
    path: '/MyOndemandB',
    component: MyOndemandB,
    name: 'MyOndemandB',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  }, 
  {
    path: '/MyOndemandC',
    component: MyOndemandC,
    name: 'MyOndemandC',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  }, 
  {
    path: '/login',
    component: Login,
    name: 'login',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next('/login');
      }
      next();
    }
  },
  {
    path: '/myagreements',
    component: MyAgreements,
    name: 'myagreements',
    beforeEnter (to,from,next){
      if(store.getters.isAuth){
        next();
      }else{
        next('/login');
      }
      next();
    }
  },
  {
    path: '/:guid?/:tab?/:coo?',
    //path: '/',
    name: 'home',
    component: Home
  },

]});




Vue.use(VueMeta);

const getGDPR = localStorage.getItem('GDPR:accepted');

Vue.use(VueGtag, {
  config: {  
      //Google analytics - superato in questo punto - vale quello su Navigator
    //id: "UA-213188542-1"   "UA-213188542-2" id: this.$gtag.config.id"
   //id: "UA-213188542-2" 
    // id: "G-R217XPWD40", // ---> --> analytics test test cambiare con quello di produzione sotto
    //appName: 'GoBiz',
    id: "G-E600WMEGX5"   //Produz
  },
  bootstrap: getGDPR === 'true',
  enabled: getGDPR === 'true',
  //appName: 'Gobiz',
  //pageTrackerScreenviewEnabled: true 
  }, router);


export default router;
