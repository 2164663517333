<template>

<div class="container" >
 
    <form @submit.prevent="onSubmit">
 
<div class="alert alert-light alert-dismissible fade show" style="margin-left: 10%; margin-right: 10%; height: auto;">  
  <div class="row" style="margin-left: 5%;margin-right: 5%;">
   <div class="col">
     <div class="card bg-light mt-6">
      <h6><br></h6>
        <h5><i class="fas fa-fw fa-door-open fa-2x mr-2 align-self-center"></i></h5>
        <br>
    <br>
   <!-- inizio text info box -->
          <div class="card-body">
           <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
       <!--  <i class="far fa-fw fa-handshake fa-2x mr-2 align-self-center"></i>  -->
              <strong><h3>{{searchevents(this.labels,'dialog','welcometext')}}</h3></strong>

              <hr>
              <h5><strong>{{searchevents(this.labels,'textinfo','registrationremindergratis')}}</strong></h5>
              <p class="card-text"> </p><br>

           </div>
         </div>
      </div> 
    </div>
  </div>

 <br>
 <div style="margin-left: 5%;margin-right: 5%;">
 <hr>
 </div> 
   <div class="card-header" style="margin-left: 5%;margin-right: 5%;">
     <h3><i class="far fa-fw fa-edit fa-2x mr-2 align-self-center"></i></h3>
      <h1>{{searchevents(this.labels,'menu','companyregistering')}}</h1>
    <br>
                <h5 style="text-decoration: underline red;">{{searchevents(this.labels,'dialog','googlenews')}} </h5>
                 <h5 style="text-decoration: underline red;"> {{searchevents(this.labels,'dialog','googlenews2')}}  </h5>
  
    <section>
      <GoogleLogin :callback="callbackz" class="custom-google-button" />
    </section>
      <h5>{{searchevents(this.labels,'menu','registeralert1')}}</h5>
    </div>
   <!--  fine text info box -->

   
<!-- radio button registrazione full e light 
   <br>
   <div class="btn-group btn-group-toggle" data-toggle="buttons" @click="onRegistrationTypeChange()">
      <br>
    <label style="font-size: .9em;" class="btn btn-success active">
    <input type="radio" name="options" id="option1" value="complete" checked > {{searchevents(this.labels,'menu','registerfull')}}
  </label>
  <label style="font-size: .9em;" class="btn btn-success">
    <input type="radio" name="options" id="option2" value="light" > {{searchevents(this.labels,'menu','registerlight')}}
  </label>
    <br> 
 
    </div>
  -->
    <div class="form-group" style="margin-left: 5%;margin-right: 5%;">    

    </div>

 <br>
 <div style="margin-left: 5%;margin-right: 5%;">
 <hr>
 </div> 
      <!-- ste -->
      <div class="form-group" style="margin-left: 5%;margin-right: 5%;">
        <h3>{{searchevents(this.labels,'menu','companydataregistering')}}</h3>
        <h6>{{searchevents(this.labels,'menu','companyrequiredfields')}}</h6><br>
        
        <div class="row">
          <div class="col-sm">
              <label for="countryOfOrigin">{{searchevents(this.labels,'companies','countryOfOrigin')}}*</label>
                <select class="form-control" @change="setCompanyCategoryId()" v-model="countryOfOrigin" required>
                    <template v-for="countryOfOrigin in this.dropMofficeRegionRegister ">
                        <option v-for="(value, key) in countryOfOrigin" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                    </template>
                </select>
          </div>
          <!-- idTax = codice fiscale, mentre vatNumber = PIVA -->
          <!--
          <div class="col-sm" v-if="this.countryOfOrigin==1 && this.countryOfOrigin!=''">
            <label for="idTax">{{searchevents(this.labels,'companies','idTax')}}</label>
            <input class="form-control" type="idTax" id="idTax" v-model="idTax" required>
          </div>
          -->
          <div class="col-sm" v-if="this.countryOfOrigin==2 && this.countryOfOrigin!=''">
            <label for="companyNumber">Company Number*</label>
            <input class="form-control" type="companyNumber" id="companyNumber" v-model="companyNumber" required>
          </div>
          <div class="col-sm" v-if="this.countryOfOrigin==504 && this.countryOfOrigin!=''">
            <label for="companyNumber">Firmanummer/CVR*</label>
            <input class="form-control" type="companyNumber" id="companyNumber" v-model="companyNumber" required>
          </div>
          <div class="col-sm" v-if="this.countryOfOrigin==1 && this.countryOfOrigin!=''">
            <label for="companyNumber">Codice Fiscale Impresa*</label>
            <input class="form-control" type="companyNumber" id="companyNumber" :maxlength="16" :minlength="10" v-model="companyNumber" required>
          </div>
          <!-- altri paesi -->
          <div class="col-sm" v-if="this.countryOfOrigin!=1 && this.countryOfOrigin!=2 && this.countryOfOrigin!=504 && this.countryOfOrigin!=''">
            <label for="companyNumber">Company Identification Number*</label>
            <input class="form-control" type="companyNumber" id="companyNumber" v-model="companyNumber" required>
          </div>
          
          <div class="col-sm" v-if="this.countryOfOrigin==''">
            <label for="companyNumber">-</label>
            <input class="form-control" type="companyNumberxx" id="companyNumberxx" readonly>
          </div>
          
        </div>
      </div>

      <div class="form-group" style="margin-left: 5%;margin-right: 5%;">
        <div class="row">
          <div class="col-sm">
              <label for="companyName">{{searchevents(this.labels,'companies','companyNameFull')}}*</label>
              <input class="form-control" type="companyName" id="companyName" v-model="companyName" required >  
          </div>
          <div v-if="this.countryOfOrigin==1 || this.countryOfOrigin==2 || this.countryOfOrigin==504" class="col-sm" >
            <label for="servCompaniesCategoryId">{{searchevents(this.labels,'companies','servCompaniesCategoryId')}}*</label>
                
                <select class="form-control" @change="onServCompaniesCategoryidChange($event)" v-if="this.countryOfOrigin==1 && this.countryOfOrigin!=''" v-model="servCompaniesCategoryId" required >
                    <template v-for="servCompaniesCategoryId in this.dropsCompaniesCategory_it " >
                        <option v-for="(value, key) in servCompaniesCategoryId" :value="value['id']" :key="key" >{{value['categoryIta']}}</option>
                    </template>
                </select>
                <select class="form-control" @change="onServCompaniesCategoryidChange($event)" v-if="this.countryOfOrigin==2 && this.countryOfOrigin!=''" v-model="servCompaniesCategoryId" required >
                    <template v-for="servCompaniesCategoryId in this.dropsCompaniesCategory_uk ">
                        <option v-for="(value, key) in servCompaniesCategoryId" :value="value['id']" :key="key" >{{value['categoryEng']}}</option>
                    </template>
                </select>
                <select class="form-control" @change="onServCompaniesCategoryidChange($event)" v-if="this.countryOfOrigin==504 && this.countryOfOrigin!=''" v-model="servCompaniesCategoryId" required >
                    <template v-for="servCompaniesCategoryId in this.dropsCompaniesCategory_de ">
                        <option v-for="(value, key) in servCompaniesCategoryId" :value="value['id']" :key="key" >{{value['categoryDen']}}</option>
                    </template>
                </select>
                <select class="form-control" @change="onServCompaniesCategoryidChange($event)" v-if="this.countryOfOrigin!=1 && this.countryOfOrigin!=2 && this.countryOfOrigin!=504 && this.countryOfOrigin!=''" v-model="servCompaniesCategoryId" required>
                    <template v-for="servCompaniesCategoryId in this.dropsCompaniesCategory_de ">
                        <option v-for="(value, key) in servCompaniesCategoryId" :value="value['id']" :key="key" >{{value['categoryEng']}}</option>
                    </template>
                </select>
                <!-- aggiunta casella vuota -->
            <div v-if="this.countryOfOrigin==''">
            <input class="form-control" type="vuoto1" id="vuoto1" readonly>
          </div>

          </div>
        </div>
      </div>



      <div class="form-group" style="margin-left: 5%;margin-right: 5%;">
        <div class="row">

          <div class="col-sm" v-if="this.countryOfOrigin==1 && this.countryOfOrigin!=''">
            <label for="pec">{{searchevents(this.labels,'companies','pec')}}*</label>
            <input class="form-control" type="email" id="pec" v-model="pec" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"  required >
          </div>
          <div class="col-sm" v-if="this.countryOfOrigin!=1 && this.countryOfOrigin!=''">
            <label for="emailAddress">{{searchevents(this.labels,'companies','emailAddress')}}*</label>
            <input class="form-control" type="email" id="emailAddress" v-model="emailAddress" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" required>
          </div>
          <div class="col-sm" v-if="this.countryOfOrigin==''">
            <label for="companyNumber">----</label>
            <input class="form-control" type="companyNumberxxx" id="companyNumberxxx" readonly>
          </div>

        </div>
      </div>

      <!--
      <div class="form-group">
        <div class="row">
          <div class="col-sm">

            <div class="row">
              <div class="col-sm">
                <label for="vatNumber">{{searchevents(this.labels,'companies','servLandlinePrefixId')}}</label>
                <select class="form-control" v-model="servLandlinePrefixId" required>
                    <template v-for="servLandlinePrefixId in this.dropMservLandlinePrefixId">
                        <option v-for="(value, key) in servLandlinePrefixId" :value="value['id']" :key="key">{{value['prefix']}}</option>
                        
                    </template>
                </select>
              </div>
              <div class="col-sm">
                <label for="landlinePhone">{{searchevents(this.labels,'companies','landlinePhone')}}</label>
                <input class="form-control" type="landlinePhone" id="landlinePhone" v-model="landlinePhone" required>
              </div>
            </div>

          </div>
          <div class="col-sm">
          
          <div class="row">
              <div class="col-sm">
                
              </div>
              <div class="col-sm">
                  <label for="officeDistrict">{{searchevents(this.labels,'companies','officeDistrict')}}</label>
                  <input class="form-control" type="officeDistrict" id="officeDistrict" v-model="officeDistrict" required>
              </div>
          </div>

          </div>
        </div>
      </div>
      
      <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="officeCity">{{searchevents(this.labels,'companies','officeCity')}}</label>
                <input class="form-control" type="officeCity" id="officeCity" v-model="officeCity" required>
              </div>
              <div class="col-sm">
                <label for="officeAddress">{{searchevents(this.labels,'companies','officeAddress')}}</label>
                <input class="form-control" type="officeAddress" id="officeAddress" v-model="officeAddress" required>
              </div>
         </div>
      </div>
      -->
<!-- nuovo radio button -->
    <div class="form-group" style="margin-left: 5%;margin-right: 5%;">
    <hr>
    <h3>{{searchevents(this.labels,'menu','useradmindataregistering')}}</h3>
   

    <!-- 
    <div class="btn-group"  @click="onRegistrationTypeChange()">
        <br>
        <label style="font-size: .9em;" class="btn btn-success">
            <input type="radio" name="options" id="option1" value="Googleaccount" > Usa Google {{searchevents(this.labels,'menu','registerfull')}}
           
        </label>
       
        <label style="font-size: .9em;" class="btn btn-success">
            <input type="radio" name="options" id="option3" value="mailsignup" > Registrati con la tua mail{{searchevents(this.labels,'menu','registerlight')}}
        </label>
        <br> 
    </div>
    -->

    <br> 
    <br> 
  
  
 
 
<!-- fine nuovo radio button -->

  <div v-if="selectedRegistrationType === 'mailsignup'">
     <div class="form-group" style="margin-left: 5%;margin-right: 5%;">
        <div class="row">
              <div class="col-sm">
                <label for="name">{{searchevents(this.labels,'users','name')}}*</label>

                <input class="form-control" type="name" id="name" :minlength="2" @paste="isLetter($event)" @keypress="isLetter($event)" v-model="name" required>
              </div>
              <div class="col-sm">
                <label for="surname">{{searchevents(this.labels,'users','surname')}}*</label>
                <input class="form-control" type="surname" id="surname" :minlength="2" @paste="isLetter($event)" @keypress="isLetter($event)" v-model="surname" required>

              </div>
        </div>
       </div>
   

      <div class="form-group" style="margin-left: 5%;margin-right: 5%;">
        <div class="row">
              <div class="col-sm">
                <label for="countryOfResidence">{{searchevents(this.labels,'users','countryOfResidence')}}*</label>
                <select class="form-control" v-model="countryOfResidence" @change="mobilePrefix()" required>
                    <template v-for="countryOfResidence in this.dropMofficeRegion ">
                        <option v-for="(value, key) in countryOfResidence" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                    </template>
                </select>
              </div>

                <div class="col-sm" v-if="this.countryOfResidence==1 && this.countryOfResidence!=''">
                <label for="pec">{{searchevents(this.labels,'users','fiscalCode')}}*</label>
                <input class="form-control" type="fiscalCode" id="fiscalCode" :maxlength="16" :minlength="16" v-model="fiscalCode" required  >
              </div>

              <div class="col-sm" v-if="(this.countryOfResidence==2 || this.countryOfResidence==504) && this.countryOfResidence!=''">
                <label for="emailAddress">{{searchevents(this.labels,'users','fiscalCodeExt')}}*</label>
                <input class="form-control" type="fiscalCode" id="fiscalCode" :maxlength="10" :minlength="10" v-model="fiscalCode" required  >
              </div>

              <div class="col-sm" v-if="this.countryOfResidence!=1 && this.countryOfResidence!=2 && this.countryOfResidence!=504 && this.countryOfResidence!=''">
                <label for="emailAddress">{{searchevents(this.labels,'users','fiscalCodeExt')}}*</label>
                <input class="form-control" type="fiscalCode" id="fiscalCode" v-model="fiscalCode" required >
              </div>

              <div class="col-sm" v-if="this.countryOfResidence==''">
                <label for="companyNumber">----</label>
                <input class="form-control" type="fiscalCode" id="fiscalCode" readonly >
              </div>


        </div>
      </div>

  
      <div class="form-group" style="margin-left: 5%;margin-right: 5%;">
        <div class="row">
          <div class="col-3">
          </div>
                <div class="col-sm">
                <div class="row">
                  <div class="col-4" style="max-width: 35%;">
                  <label for="servMobilePrefixId">{{searchevents(this.labels,'companies','servLandlinePrefixId')}}*</label>
                    <select class="form-control" v-model="servMobilePrefixId" required >
                        <template v-for="servMobilePrefixId in this.dropMservLandlinePrefixId">
                            <option v-for="(value, key) in servMobilePrefixId" :value="value['id']" :key="key">{{value['prefixName']}} {{value['prefix']}} </option>
                        </template>
                    </select>
                  </div>
                  <div class="col-6">
                    <label for="mobile">{{searchevents(this.labels,'users','mobile')}}*</label>

                    <input class="form-control" type="mobile" id="mobile" :minlength="6"  @paste="isNumber($event)" @keypress="isNumber($event)" v-model="mobile" required>

                  </div>
                </div>
              </div>


        </div>
      </div>
     
<br>
      <div class="form-group" style="margin-left: 5%;margin-right: 5%;">
        <div class="row">
              <div class="col-sm">
                <label for="email">{{searchevents(this.labels,'users','email')}}*</label>
                <input class="form-control" type="email" id="email" v-model="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" required>
              </div>
              <div v-if="this.browserInfo!='opera' && this.browserInfo!='safari'" class="col-sm">
                <label for="password">{{searchevents(this.labels,'users','password')}}*</label>
                <input class="form-control" type="password" id="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" v-model="password" required>
                <div v-if="this.lang=='Ita'" style="font-size: .8em;">Deve contenere almeno un numero e una lettera maiuscola e minuscola e almeno 8 o più caratteri</div>
                <div v-if="this.lang=='Eng'" style="font-size: .8em;">Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters</div>
                <div v-if="this.lang=='Den'" style="font-size: .8em;">Skal indeholde mindst et tal og ét store og små bogstaver og mindst 8 eller flere tegn</div>
              </div>
              <div v-if="this.browserInfo!='opera' && this.browserInfo!='safari'" class="col-sm">
                <label for="password">{{searchevents(this.labels,'users','repeatpassword')}}*</label>
                <input class="form-control" type="password" id="repeatpassword" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" v-model="repeatpassword" required>
                <div v-if="this.lang=='Ita'" style="font-size: .8em;">Deve contenere almeno un numero e una lettera maiuscola e minuscola e almeno 8 o più caratteri</div>
                <div v-if="this.lang=='Eng'" style="font-size: .8em;">Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters</div>
                <div v-if="this.lang=='Den'" style="font-size: .8em;">Skal indeholde mindst et tal og ét store og små bogstaver og mindst 8 eller flere tegn</div>
              </div>
              
              <!-- per opera e safari -->
              <div v-if="this.browserInfo=='opera' || this.browserInfo=='safari'" class="col-sm">
                <label for="password">{{searchevents(this.labels,'users','password')}}*</label>
                <input class="form-control" type="password" id="password" v-model="password" required>
                <div v-if="this.lang=='Ita'" style="font-size: .8em;">Deve contenere almeno 8 o più caratteri</div>
                <div v-if="this.lang=='Eng'" style="font-size: .8em;">Must contain at least 8 or more characters</div>
                <div v-if="this.lang=='Den'" style="font-size: .8em;">Skal indeholde mindst 8 eller flere tegn</div>
              </div>
              <div v-if="this.browserInfo=='opera' || this.browserInfo=='safari'" class="col-sm">
                <label for="password">{{searchevents(this.labels,'users','repeatpassword')}}*</label>
                <input class="form-control" type="password" id="repeatpassword" v-model="repeatpassword" required>
                <div v-if="this.lang=='Ita'" style="font-size: .8em;">Deve contenere almeno 8 o più caratteri</div>
                <div v-if="this.lang=='Eng'" style="font-size: .8em;">Must contain at least 8 or more characters</div>
                <div v-if="this.lang=='Den'" style="font-size: .8em;">Skal indeholde mindst 8 eller flere tegn</div>
              </div>
        </div>
      </div>

   </div>
 </div>
        
      <!--
      <div class="form-group">
        <div class="row">
              <div class="col-sm">
                
              </div>
              <div class="col-sm">
                
              </div>
              <div class="col-sm">
                
              </div>
              <div class="col-sm">
                <label for="isNotAdmin">{{searchevents(this.labels,'users','isNotAdmin')}}</label>
                <input class="form-control" type="checkbox" id="isNotAdmin" value="isNotAdmin" v-model="isNotAdmin" required> 
              </div>
        </div>
      </div>
      
    
      
    
      <hr>
      <label for="addadmin">{{searchevents(this.labels,'users','addadmin')}}</label>
      <div class="form-group">
        <div class="row">
              <div class="col-sm">
                <label for="a_name">{{searchevents(this.labels,'users','name')}}</label>
                <input class="form-control" type="a_name" id="a_name" v-model="a_name" required>
              </div>
              <div class="col-sm">
                <label for="a_surname">{{searchevents(this.labels,'users','surname')}}</label>
                <input class="form-control" type="a_surname" id="a_surname" v-model="a_surname" required>
              </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
              <div class="col-sm">
                <label for="a_email">{{searchevents(this.labels,'users','email')}}</label>
                <input class="form-control" type="email" id="a_email" v-model="a_email" required>
              </div>
              <div class="col-sm">
                <label for="a_password">{{searchevents(this.labels,'users','password')}}</label>
                <input class="form-control" type="password" id="a_password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" v-model="a_password" required>
              </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
              <div class="col-sm">
                <label for="a_fiscalCode">{{searchevents(this.labels,'users','fiscalCode')}}</label>
                <input class="form-control" type="a_fiscalCode" id="a_fiscalCode" v-model="a_fiscalCode" required>
              </div>
              <div class="col-sm">
                <label for="a_servMobilePrefixId">{{searchevents(this.labels,'companies','servLandlinePrefixId')}}</label>
                <select class="form-control" v-model="a_servMobilePrefixId" required>
                    <template v-for="a_servMobilePrefixId in this.dropMservLandlinePrefixId" >
                        <option v-for="(value, key) in a_servMobilePrefixId" :value="value['id']" :key="key">{{value['prefix']}}</option>
                    </template>
                </select>
              </div>
              <div class="col-sm">
                <label for="a_mobile">{{searchevents(this.labels,'users','mobile')}}</label>
                <input class="form-control" type="a_mobile" id="a_mobile" v-model="a_mobile" required>
              </div>
        </div>
      </div>
-->
<div v-if="selectedRegistrationType != 'mailsignup'">
     <div class="form-group" style="margin-left: 5%;margin-right: 5%;">
        <div class="row">
              <div class="col-3">
              </div>
              <div class="col-6">
                <label for="email">{{searchevents(this.labels,'users','email')}}*</label>
                <input class="form-control" type="email" id="email" v-model="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" required>
              </div>
              <div class="col-3">
              </div>
        </div>
    </div>    
  </div>  

      <br>
      <div class="form-group" style="margin-left: 5%;margin-right: 5%;">
        <div class="row" v-if="selectedRegistrationType != 'mailsignup'">
              <div class="col-sm" style="max-width: 20%;">
                <input style="font-size:0.8em;margin-left:45%;" class="form-control" type="checkbox" id="disclaimer01" value="disclaimer01" @click="verifyPassword()" v-model="disclaimer01" required>
              </div>
              <div class="col-sm" style="text-align: left;margin-top:4px;font-size:1.1em;">
                <label for="disclaimer01" v-html="searchevents(this.labels,'disclaimer','disclaimer01')"></label>
                <br>  (google case) <br> 
              </div>
        </div>
      

        <div class="row" v-if="selectedRegistrationType === 'mailsignup'">
              <div class="col-sm" style="max-width: 20%;">
                <input style="font-size:0.8em;margin-left:45%;" class="form-control" type="checkbox" id="disclaimer01" value="disclaimer01" @click="verifyPassword()" v-model="disclaimer01" required>
              </div>
              <div class="col-sm" style="text-align: left;margin-top:4px;font-size:1.1em;">
                <label for="disclaimer01" v-html="searchevents(this.labels,'disclaimer','disclaimer01')"></label>
              </div>
        </div>
    </div>
       <div style="margin-left: 5%;margin-right: 5%;">
      <hr>
     </div> 
      <br>
      <div class="form-group" style="margin-left: 5%;margin-right: 5%;">
        <h3>{{searchevents(this.labels,'disclaimer','disclaimerHeader')}}</h3>
        <br>
        <div class="row">
              <div class="col-sm" style="max-width: 20%;">
                <input style="font-size:0.8em;margin-left:45%;" class="form-control" type="checkbox" id="disclaimer02" value="disclaimer02" @click="verifyPassword()" v-model="disclaimer02" required> 
              </div>
              <div class="col-sm" style="text-align: left;margin-top:3px;font-size:1.1em;">
                
              <div v-if="this.lang=='Ita'">Confermo di avere letto e di accettare le <b> <a :href="'https://assets.gobiz.be/documents/TermsConditions_IT.pdf' " target="_blank" > Condizioni generali del servizio GoBiz</a></b>.*</div>
              <div v-if="this.lang=='Eng'">I confirm that I have read and accept the <b> <a :href="'https://assets.gobiz.be/documents/TermsConditions_EN.pdf' " target="_blank" > GoBiz general terms and conditions</a></b>.*</div>
              <div v-if="this.lang=='Den'">Jeg bekræfter, at jeg har læst og accepterer <b> <a :href="'https://assets.gobiz.be/documents/TermsConditions_DK.pdf' " target="_blank" > GoBizs generelle servicevilkår</a></b>. (på italiensk og engelsk)* /</div>
              <div v-if="this.lang=='Den'">I confirm that I have read and accept the <b> <a :href="'https://assets.gobiz.be/documents/TermsConditions_EN.pdf' " target="_blank" > GoBiz general terms and conditions</a></b>.*</div>

                
               <!--https://info.gobiz.be/it/privacy-cookie-policy/
                 
                <div v-if="this.lang=='Ita'">Confermo di avere letto e di accettare le <b> <a :href="remoteServer + '/servicefiles/TermsConditions_IT.pdf' " target="_blank" > Condizioni generali del servizio GoBiz</a></b>.*</div>
                <div v-if="this.lang=='Eng'">I confirm that I have read and accept the <b> <a :href="remoteServer + '/servicefiles/TermsConditions_EN.pdf' " target="_blank" > GoBiz general terms and conditions</a></b>.*</div>
                <div v-if="this.lang=='Den'">Jeg bekræfter, at jeg har læst og accepterer <b> <a :href="remoteServer + '/servicefiles/TermsConditions_DK.pdf' " target="_blank" > GoBizs generelle servicevilkår</a></b>.*</div>
                
                <div v-if="this.lang=='Ita'">Confermo di avere letto e di accettare le <b ><router-link v-bind:to="'termsandconditions'">Condizioni generali del servizio GoBiz</router-link></b>.*</div>
                <div v-if="this.lang=='Eng'">I confirm that I have read and accept the <b><router-link v-bind:to="'termsandconditions'">GoBiz general terms and conditions</router-link></b>.*</div>
                <div v-if="this.lang=='Den'">Jeg bekræfter, at jeg har læst og accepterer <b><router-link v-bind:to="'termsandconditions'">GoBizs generelle servicevilkår</router-link></b>.*</div>
                 -->
              </div>
        </div>
        
        <!-- Eliminazione vessatorie su registrati
        <div class="row">
              <div class="col-sm" style="max-width: 20%;">
                <input style="font-size:0.8em;margin-top:10px;margin-left:45%;" class="form-control" type="checkbox" id="disclaimer03" value="disclaimer03" @click="verifyPassword()" v-model="disclaimer03" required>
              </div>
              <div class="col-sm" style="text-align: left;margin-top:12px;font-size:1.1em;">
                <label for="disclaimer03" v-html="searchevents(this.labels,'disclaimer','disclaimer03')"></label>
              </div>
        </div>
         -->
        <div class="row">
              <div class="col-sm" style="max-width: 20%;">
                <input style="font-size:0.8em;margin-top:8px;margin-left:45%;" class="form-control" type="checkbox" id="disclaimer04" value="disclaimer04" @click="verifyPassword()" v-model="disclaimer04" required> 
              </div>
              <!--
              <div class="col-sm" style="text-align: left;font-size:1.1em;">
                <div style="margin-top:10px;"  v-if="this.lang=='Ita'">Dichiaro di avere preso visione dell'<b> <a :href="remoteServer + '/servicefiles/Privacy&Cookie_IT.pdf' " target="_blank" > Informativa Privacy del servizio GoBiz</a></b>.*</div>
                <div style="margin-top:10px;"  v-if="this.lang=='Eng'">I declare that I have read the <b><a :href="remoteServer + '/servicefiles/Privacy&Cookie_EN.pdf' " target="_blank" >Privacy Policy of the GoBiz service</a></b>.*</div>
                <div style="margin-top:10px;"  v-if="this.lang=='Den'">Jeg erklærer, at jeg har læst <b><a :href="remoteServer + '/servicefiles/Privacy&Cookie_DK.pdf' " target="_blank" >GoBiz-tjenestens fortrolighedspolitik</a></b>.*</div>
                
              </div>
              -->
              <div class="col-sm" style="text-align: left;font-size:1.1em;">
                <div style="margin-top:10px;"  v-if="this.lang=='Ita'">Dichiaro di avere preso visione dell'<b> <a :href="'https://assets.gobiz.be/documents/Privacy&Cookie_IT.pdf'" target="_blank" > Informativa Privacy del servizio GoBiz</a></b>.*</div>
                <div style="margin-top:10px;"  v-if="this.lang=='Eng'">I declare that I have read the <b><a :href="'https://assets.gobiz.be/documents/Privacy&Cookie_EN.pdf' " target="_blank" >Privacy Policy of the GoBiz service</a></b>.*</div>
                <div style="margin-top:10px;"  v-if="this.lang=='Den'">Jeg erklærer, at jeg har læst <b><a :href="'https://assets.gobiz.be/documents/Privacy&Cookie_DK.pdf' " target="_blank" >GoBiz-tjenestens fortrolighedspolitik</a></b>. (på italiensk og engelsk)*  /</div>
                <div v-if="this.lang=='Den'">I declare that I have read the <b><a :href="'https://assets.gobiz.be/documents/Privacy&Cookie_EN.pdf' " target="_blank" >Privacy Policy of the GoBiz service</a></b>.*</div>
                
              </div>
        </div>
        <div class="row">
              <div class="col-sm" style="max-width: 20%;">
                <input style="font-size:0.8em;margin-top:10px;margin-left:45%;" class="form-control" type="checkbox" id="disclaimer05" value="disclaimer05" @click="verifyPassword()" v-model="disclaimer05" > 
              </div>
              <div class="col-sm" style="text-align: left;margin-top:12px;font-size:1.1em;">
                <label for="disclaimer05" v-html="searchevents(this.labels,'disclaimer','disclaimer05')"></label>
                <div style="margin-top:-6px;" v-if="this.lang=='Den'">I provide consent for commercial contact activities via email, sms and other automated services indicated in article 2.2 of the Privacy Policy</div>
                </div>
        </div>
      </div>

    
      <div class="form-group">
        <div class="row">
              <div class="col-sm">
                <button type="submit" class="btn btn-dark" style="margin-bottom:100px; margin-top: 30px;font-size:2.5em;">{{searchevents(this.labels,'controls','saveandcontinueregister')}}</button>
              </div>
        </div>
      </div>

      <!--
      <div class="form-group">
        <div class="row">
              <div class="col-sm" style="text-align: left;">
                <label for="disclaimerfooter01" v-html="searchevents(this.labels,'disclaimer','disclaimerfooter01')"></label>
              </div>
        </div>
        <div class="row">
              <div class="col-sm" style="text-align: left;">
                <label for="disclaimerfooter02" v-html="searchevents(this.labels,'disclaimer','disclaimerfooter02')"></label>
              </div>
        </div>
        <div class="row" style="margin-bottom:100px;">
              <div class="col-sm" style="text-align: left;">
                <label for="disclaimerfooter03" v-html="searchevents(this.labels,'disclaimer','disclaimerfooter03')"></label>
              </div>
        </div>
        
      </div>
      -->



      
    </form>
  </div>
</template>


<script>

import gAxios from 'axios';
import { googleAuthCodeLogin } from "vue3-google-login";
import { decodeCredential } from 'vue3-google-login';
import router from '@/router';

export default {
  name: 'Register',
  data () {
    return {
      remoteServer : process.env.VUE_APP_CONN_NODEJS,
      error: '',
      companyName : '',
      servCompaniesCategoryId : null,
      servCompaniesCategoryText : '',
      pec : '',
      emailAddress : '',
      idTax : '',
      vatNumber : '',
      companyNumber : '',
      servLandlinePrefixId : '',
      landlinePhone : '',
      officeRegion : '',
      officeDistrict : '',
      officeCity : '',
      officeAddress : '',
      countryOfOrigin : '',
      countryOfResidence : '',
      //servDocTypeId : '',
      //documentNumber : '',
      name : '',
      surname : '',
      email : '',
      password : '',
      repeatpassword : '',
      fiscalCode : '',
      servMobilePrefixId : '',
      mobile : '',
      isNotAdmin : false,
      isAdmin : 'Y',
      idUsersParent : '-1',
      a_name : '',
      a_surname : '',
      a_email : '',
      a_password : '',
      a_fiscalCode : '',
      a_servMobilePrefixId : '',
      a_mobile : '',
      disclaimer01: null,
      disclaimer02: null,
      disclaimer03: null,
      disclaimer04: null,
      disclaimer05: null,
      dropMservLandlinePrefixId : null,
      dropMofficeRegion : null,
      dropMofficeRegionRegister : null,
      dropsCompaniesCategory_it : null,
      dropsCompaniesCategory_uk : null,
      dropsCompaniesCategory_de : null,
      dropCompaniesDocs : null,
      dropCompaniesDocsAll : null,
      documentNumber : '-',
      // fiscalCode : 'Not Provided',
      servDocTypeId  : 3,
      selectedRegistrationType: 'mailsignup',

           
    }
  },
  methods : {
    setCompanyCategoryId(){
      if(this.countryOfOrigin!=1 && this.countryOfOrigin!=2 && this.countryOfOrigin!=504){
        this.servCompaniesCategoryId = 173;
      }
    },
    callbackz(response){

    const userData = decodeCredential(response.credential);

    this.email = userData.email;
    this.password = 'fammipassare';
    
    localStorage.setItem('gmail',userData.email);
    localStorage.setItem('gname',userData.given_name);
    localStorage.setItem('gsurname', userData.family_name);
    

    localStorage.setItem('gcompanyName',this.companyName);
    localStorage.setItem('gservCompaniesCategoryId',this.servCompaniesCategoryId);
    localStorage.setItem('gservCompaniesCategoryText',this.servCompaniesCategoryText);
    localStorage.setItem('gpec',this.pec);
    localStorage.setItem('gemailAddress',this.emailAddress);
    localStorage.setItem('gidTax',this.idTax);
    localStorage.setItem('gvatNumber',this.vatNumber);
    localStorage.setItem('gcompanyNumber',this.companyNumber);
    localStorage.setItem('gcountryOfOrigin',this.countryOfOrigin);
    localStorage.setItem('gservLandlinePrefixId',this.servLandlinePrefixId);
    localStorage.setItem('glandlinePhone',this.landlinePhone);
    localStorage.setItem('gofficeRegion',this.officeRegion);
    localStorage.setItem('gofficeDistrict',this.officeDistrict);
    localStorage.setItem('gofficeCity',this.officeCity);
    localStorage.setItem('gofficeAddress',this.officeAddress);
    localStorage.setItem('gcountryOfResidence',this.countryOfResidence);
    localStorage.setItem('gservDocTypeId',this.servDocTypeId);
    localStorage.setItem('gdocumentNumber',this.documentNumber);
    

    router.push('/registergoogle');  
    },

    login(){
    googleAuthCodeLogin().then((response) => {
      
      this.callbackz(response);
      
      
    })
    },
     onRegistrationTypeChange() {
    this.selectedRegistrationType = document.querySelector('input[name="options"]:checked').value;
     },
     //onRegistrationTypeChange() {
    //if (this.selectedRegistrationType !== 'mailsignup') {
    //  this.selectedRegistrationType = 'mailsignup';
   // }
     // },

    //  onRegistrationTypeChange() {
  // if (document.getElementById('option1').checked) {
   //   this.selectedRegistrationType = 'Googleaccount';
  //  } else if (document.getElementById('option2').checked) {
   //   this.selectedRegistrationType = 'Linkedinaccount';
  //  } else if (document.getElementById('option3').checked) {
 //     this.selectedRegistrationType = 'mailsignup';
  //  }
 // }
  
       isLetter(e) {

      //console.log(e);
      let char = String.fromCharCode(e.keyCode);
      if (/^[a-zA-Z\\'\s]+$/.test(char)){
        return true;
      }else{
        //console.log('nnnnoo');
        e.preventDefault();
      } 

    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    mobilePrefix(){
      this.servMobilePrefixId = null;
      if(this.countryOfResidence==1){ this.servMobilePrefixId = '4'; }
      if(this.countryOfResidence==2){ this.servMobilePrefixId = '2'; }
      if(this.countryOfResidence==504){ this.servMobilePrefixId = '59'; }
      
    },
    onSubmit(){

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/checkUser',
                {
                   email : this.email,
                   fiscalCode: this.fiscalCode
                })
                .then(res => {
                    
                    if(res.data.message=='OK')
                    {

                      // verifico se non è blacklistata -----------------------------------------------------------

                      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/verifycompanyCheckBleackList',
                      {
                        companyName : this.companyName,
                        servCompaniesCategoryId : this.servCompaniesCategoryId,
                        pec : this.pec,
                        emailAddress : this.emailAddress,
                        idTax : this.idTax,
                        vatNumber : this.vatNumber,
                        companyNumber : this.companyNumber,
                        countryOfOrigin : this.countryOfOrigin,
                        servLandlinePrefixId : this.servLandlinePrefixId,
                        landlinePhone : this.landlinePhone,
                        officeRegion : this.officeRegion,
                        officeDistrict : this.officeDistrict,
                        officeCity : this.officeCity,
                        officeAddress : this.officeAddress,
                        countryOfResidence : this.countryOfResidence,
                        servDocTypeId : this.servDocTypeId,
                        documentNumber : this.documentNumber,
                        disclaimerContact : true,
                        name : this.name,
                        surname : this.surname,
                        email : this.email,
                        password : this.password,
                        fiscalCode : this.fiscalCode,
                        servMobilePrefixId : this.servMobilePrefixId,
                        mobile : this.mobile,
                        isAdmin : this.isAdmin,
                        isAdminVerified : 'N',
                        acceptNotifications : 'Y',
                        deleted : '0',
                        usersRoleRole : 'USR',
                        servUsersStateUsersState : 'S',
                        a_name : this.a_name,
                        a_surname : this.a_surname,
                        a_email : this.a_email,
                        a_password : this.a_password,
                        a_fiscalCode : this.a_fiscalCode,
                        a_servMobilePrefixId : this.a_servMobilePrefixId,
                        a_mobile : this.a_mobile,
                        //dropMservLandlinePrefixId : this.dropMservLandlinePrefixId,
                        //dropMofficeRegion : this.dropMofficeRegion,
                        a_idUsersParent : '-1',
                        a_isAdmin : 'Y',
                        a_isAdminVerified : 'N',
                        a_acceptNotifications : 'Y',
                        a_deleted : '0',
                        a_usersRoleRole : 'USR',
                        a_servUsersStateUsersState : 'S',
                        usersRoleCompanyRole : 'ADM',
                        idUsersParent : '-1'
                      })
                      .then(res2 => {
                          //console.log(res2);
                          if(res2.data.message=='OK')
                          {
                              // parte la form di registrazione
                              this.onSubmit2();

                            }else{
                              let msg = this.searchevents(this.labels,'dialog','blacklist');
                              alert(msg);
                              this.companyNumber = null;
                            }
                        })
                        .catch(
                        error => {
                        //console.log(error);
                        return error;
                        }
                        );
                      
                      // se la risposta è OK, allora controllo il codice fiscale
                      // non lo faccio più 21/01/2021
                      /*
                      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/checkUser2',
                      {
                        email : this.email,
                        fiscalCode: this.fiscalCode
                      })
                      .then(res => {
                          
                          if(res.data.message=='OK')
                          {
                            
                            this.onSubmit2();
                          }else{
                            let msg = this.searchevents(this.labels,'dialog','opnotpermittedcfpresent');
                            
                            alert(msg);
                          }
                      })
                      .catch(
                      error => {
                      //console.log(error);
                      return error;
                      }
                      );
                      */

                      //this.onSubmit2();

                    }else{
                      let msg = this.searchevents(this.labels,'dialog','opnotpermittedemailpresent');
                      alert(msg);
                    }
                })
                .catch(
                error => {
                //console.log(error);
                return error;
                }
                );
    },
    verifyPassword(){
      
      if(this.password != this.repeatpassword ){

        this.msg = this.searchevents(this.labels,'dialog','passworddonotmatch');
          
        alert(this.msg);
        
        return false;
      }else{
        return true;
      }
    },
    onServCompaniesCategoryidChange(event){
      
      var name = event.target.options[event.target.options.selectedIndex].text;
      this.servCompaniesCategoryText = name;
      //console.log('----------->' + name);
    },
    onSubmit2(){
      if(this.isNotAdmin===true){
        this.isAdmin = 'N';
      }

      if(this.password != this.repeatpassword){
        
        this.msg = this.searchevents(this.labels,'dialog','passworddonotmatch');
          
        alert(this.msg);
        return false;
      }

      if(this.password.length<8 || this.repeatpassword.length<8 ){
          
          this.msg = this.searchevents(this.labels,'dialog','passworddonotmatch8');
          
          alert(this.msg);
          return false;
      }

      if(this.countryOfOrigin==1){
        // verifico PEC
        //if(this.pec.includes('pec') || this.pec.includes('PEC')){
          // nothing
        //}else{
        //  alert('Inserire un indirizzo PEC corretto.');
        //  return false;
        //}
      }

      let disclaimerContactNew = 'N';
      //console.log('---------------------------------------->' + this.disclaimer05+'<<<')
      if(this.disclaimer05==true){ disclaimerContactNew = 'Y'; }
      //console.log('---------------------------------------->' + disclaimerContactNew+'<<<')

      const formData = {
        companyName : this.companyName,
        servCompaniesCategoryId : this.servCompaniesCategoryId,
        servCompaniesCategoryText : this.servCompaniesCategoryText,
        pec : this.pec,
        emailAddress : this.emailAddress,
        idTax : this.idTax,
        vatNumber : this.vatNumber,
        companyNumber : this.companyNumber,
        countryOfOrigin : this.countryOfOrigin,
        servLandlinePrefixId : this.servLandlinePrefixId,
        landlinePhone : this.landlinePhone,
        officeRegion : this.officeRegion,
        officeDistrict : this.officeDistrict,
        officeCity : this.officeCity,
        officeAddress : this.officeAddress,
        countryOfResidence : this.countryOfResidence,
        servDocTypeId : this.servDocTypeId,
        documentNumber : this.documentNumber,
        disclaimerContact : disclaimerContactNew,
        name : this.name,
        surname : this.surname,
        email : this.email,
        password : this.password,
        fiscalCode : this.fiscalCode,
        servMobilePrefixId : this.servMobilePrefixId,
        mobile : this.mobile,
        isAdmin : this.isAdmin,
        isAdminVerified : 'N',
        acceptNotifications : 'Y',
        deleted : '0',
        usersRoleRole : 'USR',
        servUsersStateUsersState : 'S',
        a_name : this.a_name,
        a_surname : this.a_surname,
        a_email : this.a_email,
        a_password : this.a_password,
        a_fiscalCode : this.a_fiscalCode,
        a_servMobilePrefixId : this.a_servMobilePrefixId,
        a_mobile : this.a_mobile,
        //dropMservLandlinePrefixId : this.dropMservLandlinePrefixId,
        //dropMofficeRegion : this.dropMofficeRegion,
        a_idUsersParent : '-1',
        a_isAdmin : 'Y',
        a_isAdminVerified : 'N',
        a_acceptNotifications : 'Y',
        a_deleted : '0',
        a_usersRoleRole : 'USR',
        a_servUsersStateUsersState : 'S',
        usersRoleCompanyRole : 'ADM',
        idUsersParent : '-1'

      }
      
     this.$store.dispatch('register', formData)
    },
    searchevents(events,filterValue,label){
        if(events){
          const filter = event => 
            event.tableName.includes(filterValue) &&
            event.fieldName.includes(label)
        
          var filteredArray = events.filter(filter);
          if(filteredArray.length >0)
          {
            if(this.lang)
            {
              var labelName = 'label'+this.lang;
              return filteredArray[0][labelName];
            }else{
              return '--';
            }
            
          }else{
            return '--';
          }
        }else{
          return null;
        }
      },
      returnMenu(num){
        var menu = this.dropM;
        if(menu)
        {
          return menu[num];
        }else{
          return null;
        }
      }
  },
  computed :{
    browserInfo() {

      let userAgent = navigator.userAgent;
      let browserName;
      
      if(userAgent.match(/chrome|chromium|crios/i)){
          browserName = "chrome";
        }else if(userAgent.match(/firefox|fxios/i)){
          browserName = "firefox";
        }  else if(userAgent.match(/safari/i)){
          browserName = "safari";
        }else if(userAgent.match(/opr\//i)){
          browserName = "opera";
        } else if(userAgent.match(/edg/i)){
          browserName = "edge";
        }else{
          browserName="No browser detection";
        }

      //console.log('browserDetect');
      return browserName;
    },
    lang(){
      return this.$store.getters.lang;
    },
    labels(){
      return this.$store.getters.labels;
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
    dropM(){
      
      var menu = this.$store.getters.dropM;
      if(menu){
        //this.dropM1 = menu[11];
        return menu;
      }else{
        return null;
      }
      
    },
    dropCompaniesDocsAllC: function() {
      var vm = this;
        let newdoc = [];
        let newdoc2 = [];
        
        vm.dropCompaniesDocsAll.forEach(u => {
          
          u.forEach(u2 => {
           
            if(u2.docTypeIta=="Passaporto" || u2.docTypeIta=="Patente" || u2.docTypeIta=="Carta identità"  ){
              newdoc.push(u2);
            }
          });
          
        });
        vm.dropCompaniesDocsAll.forEach(u => {
          
          u.forEach(u2 => {
           
            if(u2.docTypeIta=="Altro" ){
              newdoc.push(u2);
            }
          });
          
        });
        newdoc2.push(newdoc);
        
				return newdoc2;
        
       //return vm.dropCompaniesDocs;
			
		},
  dropCompaniesDocsC: function() {
      var vm = this;
        let newdoc = [];
        let newdoc2 = [];
        
        vm.dropCompaniesDocs.forEach(u => {
          
          u.forEach(u2 => {
            
            if(u2.docTypeIta=="Passaporto" || u2.docTypeIta=="Patente" || u2.docTypeIta=="Carta identità" ){
              newdoc.push(u2);
            }
          });
          
        });
        vm.dropCompaniesDocs.forEach(u => {
          
          u.forEach(u2 => {
            
            if(u2.docTypeIta=="Altro"){
              newdoc.push(u2);
            }
          });
          
        });
        newdoc2.push(newdoc);
        
				return newdoc2;
        
       //return vm.dropCompaniesDocs;
			
		}
  },
  created(){
    if(this.dropMservLandlinePrefixId===null && this.dropM!=null){
      this.dropMservLandlinePrefixId = this.dropM.serv_mobile_prefixes;
    }
    if(this.dropMofficeRegion===null && this.dropM!=null){
      this.dropMofficeRegion = this.dropM.serv_countries_lists_all;
    }
    if(this.dropMofficeRegionRegister===null && this.dropM!=null){
      this.dropMofficeRegionRegister = this.dropM.serv_countries_lists;
    }
    
    if(this.dropsCompaniesCategory_it===null && this.dropM!=null){
      this.dropsCompaniesCategory_it = this.dropM.serv_companies_categories_it;
    }
    if(this.dropsCompaniesCategory_uk===null && this.dropM!=null){
      this.dropsCompaniesCategory_uk = this.dropM.serv_companies_categories_uk;
    }
    if(this.dropsCompaniesCategory_de===null && this.dropM!=null){
      this.dropsCompaniesCategory_de = this.dropM.serv_companies_categories_de;
    }
    if(this.dropCompaniesDocs===null && this.dropM!=null){
      this.dropCompaniesDocs = this.dropM.serv_doc_types_ita;
    }
    if(this.dropCompaniesDocsAll===null && this.dropM!=null){
      this.dropCompaniesDocsAll = this.dropM.serv_doc_types;
    }
    
  },
  watch : {
      dropM: function () {
      // potrei anche leggere il nuovo e il vecchio valore (newdropMservLandlinePrefixId,olddropMservLandlinePrefixId)
      this.dropMservLandlinePrefixId = this.dropM.serv_mobile_prefixes;
      this.dropMofficeRegion = this.dropM.serv_countries_lists_all;
      this.dropMofficeRegionRegister = this.dropM.serv_countries_lists;
      
      this.dropsCompaniesCategory_it = this.dropM.serv_companies_categories_it;
      this.dropsCompaniesCategory_uk = this.dropM.serv_companies_categories_uk;
      this.dropsCompaniesCategory_de = this.dropM.serv_companies_categories_de;
      this.dropCompaniesDocs = this.dropM.serv_doc_types_ita;
      this.dropCompaniesDocsAll = this.dropM.serv_doc_types;
    }
  },
  mounted(){
    
  },
  beforeUpdate(){
    
  },
  updated(){
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.count{
  font-size: 7em;
}
.btn{
  font-size: 3em;
}
hr{
  border-top: 2px solid #2c3e50;
}
.btn.btn-success.active,
.btn.btn-success:active {
  color: #fff;
  background-color: #2c3e50;
  border-color: #2c3e50;
  outline: none;
}



</style>
