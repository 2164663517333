<template>
  <div class="container">
    
   <div class="row">
     <div class="col-12">
        <h1>Aggiornamento / verifica email</h1>
       </div>
   </div>

   <div class="row contentRow" style="margin-top:15px;">
     <div class="col-3">
      <b> Ricerca Azienda target <br>  per aggiornamento mail </b><br>  (Cod Fisc./CVR/C.Number) <br>
      </div>
      <div class="col-2">
        <input class="form-control" type="companyNumberTo" id="companyNumberTo" v-model="companyNumberTo" >
      </div>
    
      <div class="col-2">
        Paese
      </div>
      <div class="col-2">
        <select class="form-control" v-model="countryOfOrigin" @change="selectInviteChange()" >
              <option value="0"></option>
              <option value="1">Italia</option>
              <option value="2">UK</option>
              <option value="504">Danimarca</option>
        </select>
      </div>
      <div class="col-2">
        <button type="button" class="btn btn-pdf" style="font-size: .9em" @click="searchCompanyForApdate()" >Cerca</button>
      </div>
  </div>

  <div class="row contentRow" style="margin-top:40px;">
      <div class="col-2">
        Azienda
      </div>
      <div class="col-2">
         <strong> {{companyNameToUpdate}} </strong>
      </div>
      <div class="col-2">
        Indirizzo email
      </div>
      <div class="col-3">
          <input class="form-control" type="emailAddressTo" id="emailAddressTo" v-model="emailAddressTo" >
      </div>
      <div class="col-1" v-if="countryOfOrigin=='1'" style="text-align: right;">
          PEC 
      </div>
      <div class="col-1" v-if="countryOfOrigin=='1'" style="text-align: left;">
          <input class="form-control" style="max-width: 25px; display: initial;" type="checkbox" id="sendEmail"  v-model="sendEmailPec" >  
      </div>
      <div class="col-1">
        <button type="button" class="btn btn-pdf" style="font-size: .9em" @click="updateCompany()" >Aggiorna</button>
      </div>
  </div>
<hr>
<div class="row contentRow" style="margin-top:5px;">
      <div class="col-2" style="text-align: right;"> 
        <b>Dettagli contattabilità  --></b>
      </div>
      <div class="col-2">
        <b>Azienda Contattabile?</b> <br> 1=ok <br> 9=non contattabile <br> 0=mail non presente <br> ("MM Mail")
      </div>
      <div class="col-1" style="font-size:150%">
        <mark><b> {{MMmail}} </b></mark>
      </div>
      <div class="col-2">
        <b>Azienda senza negatività?</b> <br> 1=ok <br> 0=presenti negatività <br> ("MM check")
      </div>
      <div class="col-1" style="font-size:150%">
        <mark><b> {{MMcheck}} </b></mark>
      </div>
       <div class="col-2">
         Motivazione per eventuale negatività <br>  ("MM reasons")
      </div>
      <div class="col-2" style="font-size:110%">
          {{MMdescr}}
      </div>
  </div>
  <hr>

  <div class="row contentRow" style="margin-top:40px;">
     <div class="col-12">
        <h1>Invio email richiesta registrazione</h1>
     </div>
  </div>
       <div class="row contentRow" style="margin-top:20px;">
       <div class="col-3">
      <b> <br>Azienda invitante <br>
      registrata a Gobiz</b><br>
      (Cod Fisc./CVR/C.Number)
     
      </div>
      <div class="col-3">
          <input class="form-control" type="companyNumberInvite" id="companyNumberInvite" v-model="companyNumberInvite" >
      </div>
      <div class="col-3">
        Paese di registrazione
      </div>
      <div class="col-3">
        <select class="form-control" v-model="countryOfOriginInvite" >
              <option value="0"></option>
              <option value="1">Italia</option>
              <option value="2">UK</option>
              <option value="504">Danimarca</option>
        </select>
      </div>
  </div>
  <div class="row contentRow" style="margin-top:40px;">
   <div class="col-3">
      <b> <br>Azienda target <br>
      DB esterni</b><br>
       (Cod Fisc./CVR/C.Number)
      </div>
      <div class="col-3">
          <input class="form-control" type="companyNumberInvited" id="companyNumberInvited" v-model="companyNumberInvited" >
      </div>
      <div class="col-3">
        Paese DB esterno
      </div>
      <div class="col-3">
        <select class="form-control" v-model="countryOfOriginInvited" >
              <option value="0"></option>
              <option value="1">Italia - Infocamere</option>
              <option value="2">UK - Company House</option>
              <option value="504">Danimarca - CVR</option>
        </select>
      </div>
   </div>
   <div class="row contentRow" style="margin-top:40px;">
     <div class="col-2">
        {{companyNameInvite}}
    </div>
    <div class="col-2">
        <i class="fas fa-arrow-right fa-lg mr-2 align-self-center"></i>
    </div>
    <div class="col-2" style="margin-right:10px;">
        {{companyNameInvited}}
    </div>
    <div class="col-2" style="display: contents;">
      <div class="row">
        <div class="col-12"  v-if="companyNameInvite!='' && companyNameInvited!=''">
          <span style="margin-right:4px;">Email</span> <input v-if="companyNameInvite!='' && companyNameInvited!=''" class="form-control" style="max-width: 25px; display: initial;" type="checkbox" id="sendEmail"  v-model="sendEmail" >  
        </div>
        
      </div>
    </div>
    <div class="col-2">
        <button v-if="companyNameInvite!='' && companyNameInvited!=''" type="button" class="btn btn-pdf" style="font-size: .9em" @click="sendEmailInvite(companyIdInvited, '', '', companyEmailAddressInvited, '')" >Invia email</button>
    </div>
    <div class="col-2">
        <button type="button" class="btn btn-pdf" style="font-size: .9em" @click="searchToInvite()" >Cerca </button>
      </div>
   </div>

  </div>
</template>


<script>


import gAxios from 'axios';

export default {
  name: 'AddMailBo',
  data () {
    return {
      companyNumberTo : '',
      companyNumberFrom : '',
      countryOfOrigin : 0,
      countryOfOriginToUpdate : 0,
      emailAddressTo : '',
      emailAddressFrom : '',
      companyNameToUpdate : '------',
      companyNumberInvite : '',
      companyNumberInvited : '',
      countryOfOriginInvite : '',
      countryOfOriginInvited : '',
      MMmail : '',
      MMcheck : '',
      MMdescr : '',

      companyNameInvite : '',
      companyNameInvited : '',

      companyEmailAddressInvite : '',
      companyEmailAddressInvited : '',

      companyIdInvite : '',
      companyIdInvited : '',

      sendEmail : true,
      sendEmailPec : false,
    }
  },
  components : {
    
  },
  computed : {
    lang(){
      return this.$store.getters.lang;
    },
    labels(){
      return this.$store.getters.labels;
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
  },
  methods : {
    selectInviteChange(){
      this.companyNameToUpdate = '',
      this.emailAddressTo = '';
      this.MMmail = '';
      this.MMcheck = '';
      this.MMdescr = '';
    },
    sendEmailInvite(companyIdTo, companyTable, action, emailAddressTo, newMessage){

        action = 'INVITE';
        newMessage = 'Qualcuno ti chiede di iscriverti a GoBiz / Someone asks you to sign up for GoBiz';
        if(this.countryOfOriginInvited==1){
          companyTable = 'company_infocamere';
        }
        if(this.countryOfOriginInvited==2){
          companyTable = 'company_house';
        }
        if(this.countryOfOriginInvited==504){
          companyTable = 'company_denmark';
        }

        //const userId = this.getUser.id;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
            
            if(emailAddressTo==''){
              // l'indirizzo email del BO
              //emailAddressTo = 'stefano.fabbri@kconsulting.it';
              emailAddressTo = 'admin@gobiz.be';
            }
            
           
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/sendemail',
            {
                userId : -1,
                emailAddressTo : emailAddressTo,
                emailAddressFrom : this.companyEmailAddressInvite,
                companyFrom : this.companyIdInvite,
                message : newMessage,
                companyTable : companyTable,
                companyIdTo : companyIdTo,
                action: action,
                sendEmailPec : this.sendEmailPec
            },auth)
            .then(res => {
                alert('Email inviata');
                return res;
            })
            .catch(
                error => {
                alert('Si è verificato un errore');
                return error;
                }
            );
                      

    },
    searchToInvite(){

      //console.log(this.companyNumberInvited+'<<<<<<<<');
      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
            
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/returnCompanyData',
                {
                   companyNumberTo : this.companyNumberInvited,
                   countryOfOrigin : this.countryOfOriginInvited,
                },auth)
                .then(res => {
                //console.log(res);
                
                this.companyNameInvited = res.data.companyName;
                this.companyEmailAddressInvited = res.data.emalAddressCompany;
                this.companyIdInvited = res.data.id;
                //alert('Operazione non consentita, la username inserita risulta già registrata si prega di selezionarne una diversa');

      });

      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/returnCompanyDataGoBiz',
                {
                   companyNumberTo : this.companyNumberInvite,
                   countryOfOrigin : this.countryOfOriginInvite,
                },auth)
                .then(res => {
                //console.log(res);
                
                this.companyNameInvite = res.data.companyName;
                this.companyEmailAddressInvite = res.data.emalAddressCompany;
                this.companyIdInvite = res.data.id;
                //alert('Operazione non consentita, la username inserita risulta già registrata si prega di selezionarne una diversa');

      });
      
      
    },
    resResult(what){
      return what;
    },
    searchCompanyForApdate(){

      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
            
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/returnCompanyData',
                {
                   companyNumberTo : this.companyNumberTo,
                   countryOfOrigin : this.countryOfOrigin
                },auth)
                .then(res => {
                //console.log(res);
                
                this.companyNameToUpdate = res.data.companyName;
                this.emailAddressTo = res.data.emalAddressCompany;
                this.countryOfOriginToUpdate = this.countryOfOrigin;
                this.sendEmailPec = res.data.sendEmailPec;
                this.MMmail = res.data.MMmail;
                this.MMcheck = res.data.MMcheck;
                this.MMdescr = res.data.MMdescr;
                
                //alert('Operazione non consentita, la username inserita risulta già registrata si prega di selezionarne una diversa');

      });
    },
    updateCompany(){

      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
            
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/updateCompanyEmail',
                {
                   companyNumberTo : this.companyNumberTo,
                   countryOfOrigin : this.countryOfOriginToUpdate,
                   emailAddressTo : this.emailAddressTo,
                   sendEmailPec : this.sendEmailPec,
                },auth)
                .then(res => {
                //console.log(res);
                
                this.companyNameInvite = '';
                this.companyNameInvited = '';
                //this.countryOfOriginInvite = null;
                //this.countryOfOriginInvited = null;

                this.resResult(res);

                alert('Aggiornamento effettuato');
                this.searchCompanyForApdate();
      });
    }

    }
 }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.count{
  font-size: 7em;
}
.btn{
  font-size: 2.65em;
}
.logininput{
    width: 70%;
    min-height: 90px;
    display: inline-block;
    background-color: #ffffff;
    border: 1px solid #0c294b; 
    color: #0c294b;
    font-size: 1.3em;
}

.contentRow{
  display: flex;
  justify-content: center;
  align-items: center;
  
  max-width: 1176px;
  position: relative;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container {
    margin-bottom: 200px;
}

</style>
