
<template>



  <div class="footer">

      <!--
      <footer class="cookiefooter" :style="{ display: displaycookie}">

        <CookiePage></CookiePage>
        <cookie-law v-on:accept="ThankYouMethod()" class="cookieclass" theme="base" :buttonText="searchevents(this.labels,'dialog','cookieacceptance')"  message=""  > 
          
          
        </cookie-law>
        
      

      </footer>
      -->

      
      <div >&copy; Gobiz Start Srl - Italy </div>
      <div ><router-link v-bind:to="'termsandconditions'">Terms & Conditions</router-link> | <router-link v-bind:to="'privacy'">Privacy</router-link></div>
  <!-- {{this.$gtag.config.id}} -->

    

   </div> <!-- footer -->


    

</template>

<script>
 //import CookieLaw from 'vue-cookie-law'
 //import CookiePage from '@/components/CookiePage.vue'
/*
 app.component('button-counter', {
  data: function () {
    return {
      count: 0
    }
  },
  template: '<button v-on:click="count++">You clicked me {{ count }} times.</button>'
})
*/


import router from '@/router';


  export default {
    name: 'Footers',
    data () {
    return {
      filterValue : 'menu',
      displaycookie : 'inline',
      myGuid : null,
      myTab : null,
      myCoo : null
      }
    },
    components: { 
      
      },
    computed : {
      
      lang(){
        var lng = this.$store.getters.lang;
          if(lng)
          {
            return lng;
          }else{
            return '';
          }
      },
      labels(){
        return this.$store.getters.labels;
      },
      getcompanyIdBusinessCard(){
        return this.$store.getters.getcompanyIdBusinessCard;
      },
      getcompanyTableBusinessCard(){
        return this.$store.getters.getcompanyTableBusinessCard;
      },
      getToken(){
       // return null;
       return this.$store.getters.getUserToken;
      },
    },
    methods : {
    
      //ThankYouMethod(){
        //let cookieaccept = this.$cookies.get("cookies:accept");
        //console.log('ThankYouMethod');
      //  this.displaycookie = 'none';
      //},
      accetpCookies(){
        return true;
      },
      returnYear(dt){
        if(dt!=null){
        return dt.substring(0,4);
        }else{
          return '';
        }
      },
      searchevents(events,filterValue,label){
        if(events){
          const filter = event => 
            event.tableName.includes(filterValue) &&
            event.fieldName.includes(label)
        
          var filteredArray = events.filter(filter);
          if(filteredArray.length >0)
          {
            if(this.lang)
            {
              var labelName = 'label'+this.lang;
              return filteredArray[0][labelName];
            }else{
              return '--';
            }
            
          }else{
            return '--';
          }
        }else{
          return null;
        }
      },
      

    },mounted(){
        //console.log('mounted');
        
        //console.log('>>>>>>>>>>>'+this.$route.params.guid+'<<<<<<<<');
        if(this.myGuid!=null && this.myTab!=null && this.myCoo!=null){
          // ritorna risposta positiva o negativa
          
          //console.log('>>>>>>>>>>>'+this.myGuid+'<<<<<<<<'+this.myTab+'<<<<<<<<'+this.myCoo+'<<<<<<<<');

            router.push('/confirmnotinviting/'+this.myGuid+'/'+this.myTab+'/'+this.myCoo);
            this.myGuid = null;
            this.myTab = null;
            this.myCoo = null;
          }
      

    },created() {
      
      //console.log('created');
      if(this.$route.params.guid!=undefined && this.$route.params.tab!=undefined && this.$route.params.coo!=undefined){
        this.myGuid = this.$route.params.guid;
        this.myTab = this.$route.params.tab;
        this.myCoo =this.$route.params.coo;
        }

    },
    watch : {
      
    
    }

  }
</script>

<style scoped>
.cookietext{
    left: 20px;
    top: 20px;
    text-align: left;
    color: #000000;
    padding: 25px;

}

.skew{
border-radius: 25px;
    padding-left: 10px;
    padding-right: 10px;
    border-color: #cccccc;
    border-style: solid;
}

.cookiebutton{
position: absolute;
    right: 20px;
    top: 30px;
}

.cookiefooter{
  left: 30%;
    right: 30%;
    top: 30%;
    width: auto;
    height: fit-content;
    min-height: 150px;
    box-shadow: 5px 5px 5px #00000075;
    border-radius: 25px;
    background: #ffffff;
    position: fixed;
}


.Cookie--base {
  position: initial;
  background: transparent;
}

@media (max-width: 768px) {
    .cookiefooter{
        left: 8%;
        right: 8%;
        top: 12%;
        width: auto;
        height: fit-content;
        min-height: 150px;
        box-shadow: 5px 5px 5px #00000075;
        border-radius: 25px;
        background: #ffffff;
        position: fixed;
    }
}

</style>
