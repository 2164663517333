<template>
   
  <div class="container" style="max-width: 65% !important;">
    
     <h1> Terms & Conditions </h1>
    

    <button type="button" class="btn btn-success" style="font-size: .8em;" @click="backTohome()">{{searchevents(this.labels,'menu','backglobal')}} </button>
    <br>
    <br> 
         
                <div style="display: inline-block;" v-if="this.lang=='Ita'" >
                <a class=" btn btn-success" style="font-size: .8em; width: 200px; " :href="'https://assets.gobiz.be/documents/TermsConditions_IT.pdf' " target="_blank" >{{searchevents(this.labels,'dialog','clickhere')}} {{searchevents(this.labels,'dialog','seetermsconditions')}}</a><br>
                <br>
                <!-- <iframe frameborder="1" scrolling="auto" height="1100" width="850" style="border:1px solid #666CCC" name="_pdfembedded" id="iframepdf" title="PDF in an i-Frame" :src="'https://info.gobiz.be/it/privacy-cookie-policy/'"></iframe> -->
                <hr>
                </div>         
                <!--  <h4 v-if="this.lang=='Ita'"  ><div v-html="this.contentPage.content.rendered"> </div><br></h4> -->
  
                <div style="display: inline-block;" v-if="this.lang=='Eng'" >
                <a class=" btn btn-success" style="font-size: .8em; width: 200px; " :href="'https://assets.gobiz.be/documents/TermsConditions_EN.pdf' " target="_blank"  >{{searchevents(this.labels,'dialog','clickhere')}} {{searchevents(this.labels,'dialog','seetermsconditions')}}</a><br>
                 <hr>
                </div>
               <!--  <h4 v-if="this.lang=='Eng'"><div v-html="this.contentPageEng.content.rendered"> </div> <br> </h4> -->

                <div style="display: inline-block;" v-if="this.lang=='Den'" >
                <a class=" btn btn-success" style="font-size: .8em; width: 200px; " :href="'https://assets.gobiz.be/documents/TermsConditions_DK.pdf' " target="_blank"  >{{searchevents(this.labels,'dialog','clickhere')}} {{searchevents(this.labels,'dialog','seetermsconditions')}}</a><br>
                 <hr>
                </div>
                <!-- <h4 v-if="this.lang=='Den'"><div v-html="this.contentPageDen.content.rendered"> </div> <br> </h4> -->
  
                

    <!-- <button type="button" class="btn btn-success" style="font-size: .8em;" @click="backTochat()" >{{searchevents(labels,'menu','messagesarea')}}</button> -->
     <br>
    <!-- <button type="button" class="btn btn-success" style="font-size: .8em;" @click="customizeCookies()">{{searchevents(this.labels,'menu','backglobal')}} </button> -->
    

    </div>


</template>




<script>

import router from '@/router';
// @ is an alias to /src
import gAxios from 'axios';

export default {
name: 'privacy',
  data () {
    return {
      remoteServer : process.env.VUE_APP_CONN_NODEJS,
      titlePage : null,
      titlePageEng : null,
      titlePageDen : null,
      contentPage : null,
      contentPageEng : null,
      contentPageDen : null
    }
  },
  methods : {
    backTohome(){
        router.push('/');
    },
    setMyCompanyIdTo(companyIdTo,action){
        this.$store.dispatch('setcompanyIdTo', companyIdTo );
        if(action=='NET'){
            router.push('/mynetwork');
        }

        if(action=='CONT'){
            router.push('/mycontacts');
        }
        
    },
    goTo(where){
        window.location.href = where;
    },
    modifyCompany(company){
        this.$store.dispatch('SetregisteredCompany', company );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updatecompany');
      
    },
    getM(){
        
    return null;
    },
    searchevents(events,filterValue,label){
    //events = events2;
      if(events){
        
        const filter = event => 
          event.tableName.includes(filterValue) &&
          event.fieldName.includes(label)
      
        var filteredArray = events.filter(filter);
        if(filteredArray.length >0)
        {
          var labelName = 'label'+this.lang;
          return filteredArray[0][labelName];
        }else{
          return '--';
        }
        
      }else{
        return null;
      }
      
    },       
 },
   computed : {
    labels(){
      
      return this.$store.getters.labels;
    },
    lang(){
      return this.$store.getters.lang;
    },
  },
   components: {
    
  },
  // inizio multilingua axios
  mounted: function(){
    this.$nextTick(function () {
        
        // ITA
        gAxios.get('https://info.gobiz.be/wp-json/wp/v2/pages/55',
        //gAxios.get('https://www.stefanofabbriprogrammatore.it/wp-json/wp/v2/pages/881',
        //gAxios.get('https://gobiz.be/2020/wp-json/wp/v2/pages/1300',
        {
            
        })
        .then(res => {
          
          this.titlePage = '';
          this.contentPage = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

        // ENG
        gAxios.get('https://info.gobiz.be/wp-json/wp/v2/pages/57',
        {
            
        })
        .then(res => {
          this.titlePageEng = '';
          this.contentPageEng = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

        // DEN
        gAxios.get('https://info.gobiz.be/wp-json/wp/v2/pages/59',
        {
            
        })
        .then(res => {
          this.titlePageDen = '';
          this.contentPageDen = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );
            
    })
  }
}

  
</script>



<style scoped lang="scss">

#myCookieModal .switch {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 26px;
}

#myCookieModal .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

#myCookieModal .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

#myCookieModal .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: #e6d235;
  -webkit-transition: .4s;
  transition: .4s;
}

#myCookieModal input:checked + .slider {
  background-color: #0c294b;
}

#myCookieModal input:focus + .slider {
  box-shadow: 0 0 1px #0c294b;
}

#myCookieModal input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
#myCookieModal .slider.round {
  border-radius: 34px;
}

#myCookieModal .slider.round:before {
  border-radius: 50%;
}



.badge{
  background-color: red;
    /* max-width: 10px; */
    /* max-height: 25px; */
    border-radius: .85rem;
    font-size: 35%;
    /* top: -10px; */
    position: absolute;
    color: white;
}

.fa-bell{
  color: #a3b9bd !important;
}

//menu notifiche
.liBell{
  padding: 10px;
    width: max-content;
    background-color: lightgray;
    //    background-color: #0c294b;
}

.liBellH{
  padding: 10px;
  font-weight: bold;
}
.hamburger-giallo.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(230,210,53, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  // background-image: percorso locale da inserire
}
.bordo-hamburger.navbar-toggler {
  border-color: #e6d235;
  color: #e6d235;
  } 
 

 .nav-link {
  display: block;
  padding: .0rem 1rem;
  padding-top: 0.5rem;
     }

.col-6 {
   
    text-align: left;
}
     
.col-5 {
   
    text-align: left;
}



// per i colori delle icone (badge home vai sopra dove c'è scritto class="nav-item dropdown" v-if="auth && .....
// .navbar-brand{
    //color: $navbar-light-brand-color;
   // color: red;
    // @include hover-focus {
     // color: $navbar-light-brand-hover-color;
//} 
//} 
</style>

