<template>
  <div class="container" style="max-width: 100% !important;">
    <h1>{{searchevents(this.labels,'menu','myusers')}}</h1>

    <div class="container tablelist" style="max-width: 95%;">
            <div style="float:center; text-align: center;" :class="'row '" v-if="this.getIdUsersParent=='-1'">
             <!-- spostato nella header  <router-link class="nav-link buttNavGreen" :to="{ name : 'updateuser'}">{{searchevents(this.labels,'menu','insertuser')}}</router-link>  --> 
            </div>


            <template>        
              <table class="table tablelist" >
              <thead>
              <tr>
                <td>
                  <div :class="'row blu-header'">
                      <div class="col-sm-2" >{{searchevents(this.labels,'users','name')}} {{searchevents(this.labels,'users','surname')}}</div>
                      <!-- <div class="col-sm-2" >{{searchevents(this.labels,'users','usersRoleRole')}}</div> -->
                      <div class="col-sm-2" >{{searchevents(this.labels,'users','companyaffiliation')}}</div>
                      <div class="col-sm-2" >{{searchevents(this.labels,'users','usersRoleRoleCompany')}}</div>

                      <div class="col-sm-4" ></div>
                      
                  </div>
                </td>
                  
              </tr>
              </thead>
              <tbody>
                  <tr><td >
                       <template>
                        <div>
                        <div v-for="(row, index) in myusers"  class="list-group-item" :key="index" >
                          <div :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">
                              <div class="col-sm-2" > 
                                <a class="nav-link" href="#" @click="modifyUsers(row)" >{{ row.name }} {{ row.surname }} </a>
                              </div>
                              <!--
                                <div class="col-sm-2" >
                                 {{ row.usersRoleRole }} 
                              </div>
                              -->
                              <div class="col-sm-2" v-if="row.companies[0]!=null">
                                  <div v-for="(value1, key1) in row.companies" :key="key1" >
                                   <a class="nav-link" href="#" @click="modifyCompany(value1)" >{{ value1.companyName }}</a> 
                                  </div>
                              </div>
                              <div class="col-sm-2" v-if="row.companies[0]==null">
                                  <div  >
                                    
                                  </div>
                              </div>

                              <div class="col-sm-2" v-if="row.companies[0]!=null && row.companies[0].users_to_companies.usersRoleCompanyRole=='COL'">
                                  {{searchevents(labels,'users','usersRoleCompanyRoleCOL')}}
                              </div>
                              <div class="col-sm-2" v-if="row.companies[0]!=null && row.companies[0].users_to_companies.usersRoleCompanyRole=='ADM'">
                                  {{searchevents(labels,'users','usersRoleCompanyRoleLR')}}
                              </div>

                              <div class="col-sm-2" v-if="row.companies[0]==null">
                                  
                              </div>

                              <div class="col-sm-4" v-if="row.idUsersParent!='-1' && getIdUsersParent=='-1' ">
                                 <button type="button" class="btn btn-warning" style="font-size: .8em;" @click="deleteUserConfirm(row.id)"  >{{searchevents(labels,'menu','delete')}}</button>
                              </div>
                              <div class="col-sm-4" v-if="row.companies[0]==null && row.idUserParent=='-1' ">
                                 {{searchevents(this.labels,'menu','administratoruser')}}
                              </div>
                      </div>
                       </div>
                        </div>
                      </template>
                      
                  </td>
                  </tr>
              </tbody>
          </table>
          
          </template>
            

    </div>
  </div>

</template>

<script>

import router from '@/router';
import gAxios from 'axios';


export default {

  name: 'myusers',
  data () {
    return {
      mycompanies : [],
      myusers : [],
      usersRoleRoleCompany : 'ooo',
      confirmDeleteUserIta : 'Confermi l\'operazione?',
      confirmDeleteUserEng : 'Do you confirm the operation?',
      confirmDeleteUserDen : 'Bekræfte operation?'
    }
  },
  methods : {
    deleteUserConfirm(userId){
    
    if(this.lang=='Ita'){
      if (confirm(this.confirmDeleteUserIta)){
        this.deleteUser(userId);
      }
    }

    if(this.lang=='Eng'){
      if (confirm(this.confirmDeleteUserEng)){
        this.deleteUser(userId);
      }
    }

    if(this.lang=='Den'){
      if (confirm(this.confirmDeleteUserDen)){
        this.deleteUser(userId);
      }
    }
      
        
    },
    deleteUser(idToDelete){
      
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
            
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/physicsDelete',
        {
            id : idToDelete
        },auth)
        .then(res => {
            this.getAllUserByMe();
            return res;
        })
        .catch(
        error => {
        
        return error; 
        }
        );
            
    },
    getAllUserByMe(){

      const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/allUsersMe',
                {
                   userId : userId
                },auth)
                .then(res => {
                    
                    let users = res.data.users;
                    this.myusers = users;
                    
                    users.forEach(u => {
                        let company = u.companies;
                        company.forEach(comp => {
                            
                            // prima di aggiungere la company verifico che non sia già presente
                            let trovata = 0;
                            this.mycompanies.forEach(myC => {
                                if(myC.id == comp.id){ trovata++; }
                            })
                            if(trovata==0){ this.mycompanies.push(comp); }
                        });
                    });
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },
    modifyCompany(company){
        this.$store.dispatch('SetregisteredCompany', company );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updatecompany');
      
    },
    modifyUsers(user){
        this.$store.dispatch('SetregisteredUser', user );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updateuser');
        
    },
    getM(){
        
    return null;
    },
    searchevents(events,filterValue,label){
    //events = events2;
    if(events){
        
        const filter = event => 
        event.tableName.includes(filterValue) &&
        event.fieldName.includes(label)
    
        var filteredArray = events.filter(filter);
        if(filteredArray.length >0)
        {
        var labelName = 'label'+this.lang;
        return filteredArray[0][labelName];
        }else{
        return '--';
        }
        
    }else{
        return null;
    }
    
    }
        
  },
  computed :{
    
    lang(){
      var lng = this.$store.getters.lang;
        if(lng)
        {
          return lng;
        }else{
          return '';
        }
    },
    labels(){
      return this.$store.getters.labels;
    },
    getIdUsersParent(){
       // return null;
       if(this.$store.getters.getIdUsersParent){
         return this.$store.getters.getIdUsersParent;
       }else{
         return null;
       }
       
    },
    dropM(){
      
      var menu = this.$store.getters.dropM;
      if(menu){
        //this.dropM1 = menu[11];
        return menu;
      }else{
        return null;
      }
      
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
    getUserId(){
        return this.$store.getters.getUserId;
    }
  },
  created() {
      
  },
  watch : {
      dropM: function () {
      // potrei anche leggere il nuovo e il vecchio valore (newdropMservLandlinePrefixId,olddropMservLandlinePrefixId)
      return null;
    },
      getM: function () {
          return null;
      }
  },
  components: {
    
  },
  mounted: function () {
     
    
    this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been rendered
        this.$store.dispatch('ResetregisteredCompany' );
        this.$store.dispatch('ResetregisteredUser' );


        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
            if(userId!=null)
            {
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/allUsersMe',
                {
                   userId : userId
                },auth)
                .then(res => {
                  
                    let users = res.data.users;
                    this.myusers = users;
                    
                    users.forEach(u => {
                        let company = u.companies;
                        company.forEach(comp => {
                            
                            // prima di aggiungere la company verifico che non sia già presente
                            let trovata = 0;
                            this.mycompanies.forEach(myC => {
                                if(myC.id == comp.id){ trovata++; }
                            })
                            if(trovata==0){ this.mycompanies.push(comp); }
                        });
                    });
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
            }else{
                return null;
            }
    })
    
}
}

</script>
