<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/gobiz-logo-rectangle-blue.png" width="250em">
    

    <hr>
    
    <div  v-if="this.lang=='Ita'"> 
    <h5 style="text-align: left; margin-left: 10%; margin-right:10%">
     Ti aiutiamo a trovare qualsiasi partner, cliente, in tutta Europa, per agevolare la tua crescita e il tuo Export.
     <br>
      <br>
     Ti aiutiamo a valutare le tue controparti commerciali con gli strumenti per valutarne la solidità e avviare un nuovo business (Servizio verifica imprese).
     <br>
      <br>
      Utilizziamo il nostro networking e gli utenti registrati oltre a basi dati pubbliche, private ed algoritmi evoluti per trovare il tuo miglior partner B2B.<br>
       <br>Potrai fare ricerche in autonomia o con la nostra assistenza oppure usare il servizio " Ricerca Azienda e primo meeting": con questo servizio ci occuperemo noi di tutto. Tu ci fornisci i dettagli su cosa cerchi e noi troviamo il partner giusto per te fissando persino il primo meeting di business, dopo averne verificato preventivamente la rispondenza ai tuoi requisiti.
       <br>
       <br>
        
<hr>
<strong>Con la registrazione a Gobiz puoi utilizzare i seguenti servizi:</strong>
<br>
<br>
<br>
    <strong>1) Vetrina - Gratuito</strong> <br> Realizzare una vetrina "virtuale" per promuovere la propria impresa a livello locale e internazionale
     <br> 
     <br> 
<strong>2) Matchmaking Smart - Gratuito</strong> <br>Individuare partner, fornitori e clienti "su misura" basato su algoritmo «intelligente» che, tramite uno strumento di «smart match making», consente la ricerca per parole chiave considerando il profilo dell’impresa cliente e ottimizza i risultati in base al paese target e agli obiettivi di business.
 <br> 
     <br> 

           <div> 
            <img src="https://assets.gobiz.be/imghome/mmoutput.png" alt="" width="354" height="200" title="" srcset="https://assets.gobiz.be/imghome/mmoutput.png 1181w, https://assets.gobiz.be/imghome/mmoutput.png 980w, https://assets.gobiz.be/imghome/mmoutput.png 480w" sizes="((min-width: 0px) and (max-width: 480px)) 480px, ((min-width: 481px) and (max-width: 980px)) 980px, (min-width: 981px) 1181px, 100vw" />
           </div>
           <br>
<!--
            <div class="scrolling-container">
      <img class="full-width-image" src="https://assets.gobiz.be/imghome/mmoutput.png" alt="Immagine" />
    </div>
  -->
<br>
<strong>3) Networking - Gratuito</strong> <br>Avviare relazioni commerciali ed interagire con imprese la cui identità/affidabilità è stata oggetto di verifica e certificazione e con le imprese presenti nei registri nazionali integrati (Italia, Danimarca e Regno Unito con progressiva estensione ad altri Paesi)
 <br>
     <br> 
<strong>4) Chat - Gratuito</strong> <br>Gestire ogni interazione/comunicazione con altre imprese sfruttando la traduzione simultanea multilingua <br> 
     <br> 
     <hr> 
        <br>
<strong>5) Matchmaking Advanced - Servizio Premium</strong> <br>Individuare partner, fornitori e clienti "su misura" basato su algoritmo «intelligente» che permette una ricerca "verticale" su uno specifico settore di business scelto dall'utente, ad esempio distribuzione o produzione di beni/servizi specifici. La ricerca avviene per bene o parola chiave.
 <br> 
     <br> 

           <div> 
            <img src="https://assets.gobiz.be/imghome/mmoutput.png" alt="" width="354" height="200" title="" srcset="https://assets.gobiz.be/imghome/mmoutput.png 1181w, https://assets.gobiz.be/imghome/mmoutput.png 980w, https://assets.gobiz.be/imghome/mmoutput.png 480w" sizes="((min-width: 0px) and (max-width: 480px)) 480px, ((min-width: 481px) and (max-width: 980px)) 980px, (min-width: 981px) 1181px, 100vw" />
           </div>
           <br>
<!--
            <div class="scrolling-container">
      <img class="full-width-image" src="https://assets.gobiz.be/imghome/mmoutput.png" alt="Immagine" />
    </div>
  -->
<br>
<br>
<strong>6) Creazione e Firma Accordi - Servizio Premium</strong><br>Redigere contratti mediante format contrattuali redatti sulla base delle normative vigenti a livello Comunitario <br> 
     <br> 

<strong>7) Notarizzazione della Chat e degli accordi  - Servizio Premium</strong> <br> Utilizzare un notaio digitale che certifica integrità, autenticità e marcatura temporale di comunicazioni/ contratti scambiati con altre imprese da utilizzare per risolvere eventuali dispute

     <br> 
     <br>
     <hr> 
        <br>
     <strong>8) Verifica Azienda - Servizio On demand</strong> <br> Verificare l’affidabilità della tua controparte per la concessione di credito commerciale
 <br> 
     <br> 
<strong>9) Ricerca Azienda e primo meeting - Servizio On demand</strong><br>Avviare relazioni commerciali attraverso ricerche «taylor made» effettuate con il supporto di un team dedicato, verso qualsiasi paese EU, con primo appuntamento organizzato da noi.
 <br> 
     <br> 
    </h5>
     <!--
    <br>
 <br>
          <div class="btn btn-dark btnjoin" style="margin: 0 auto; width:30%; display: block;font-weight:bold;"> 
           <router-link style="color:white;" v-bind:to="'register'">Registrati</router-link>
           </div> 

   -->
    </div>
    
    <div  v-if="this.lang=='Eng'"> 
    <h5 style="text-align: left; margin-left: 10%; margin-right:10%">
We help you find any partner, customer, throughout Europe, to facilitate your growth and your export.
      <br>
       <br>
      We help you evaluate your commercial counterparts with the tools to evaluate their solidity and start a new business (Company Verification service).
      <br>
       <br>
       We use our networking and registered users as well as public and private databases and advanced algorithms to find your best B2B partner.<br>
        <br>You can do research independently or with our assistance or use the "Company search and first meeting" service: with this service we will take care of everything. You provide us with the details of what you are looking for and we find the right partner for you, even scheduling the first business meeting, after having checked in advance that they meet your requirements.
        <br>
        <br>

<hr>
<strong>By registering with Gobiz you can use the following services:</strong>
<br>
<br>
<br>
     <strong>1) Showcase - Free Service</strong> <br> Create a "virtual" showcase to promote your business locally and internationally
      <br>
      <br>
<strong>2) Smart Matchmaking - Free Service</strong> <br>Identify "tailor-made" partners, suppliers and customers based on an "intelligent" algorithm which, through a "smart match making" tool, allows you to search by keywords considering the profile of the client company and optimize the results based on the target country and business objectives.
  <br>
      <br>

            <div>
              <img src="https://assets.gobiz.be/imghome/mmoutput.png" alt="" width="354" height="200" title="" srcset="https://assets.gobiz.be/imghome/mmoutput.png 1181w, https://assets.gobiz.be/imghome/mmoutput.png 980w, https://assets.gobiz.be/imghome/mmoutput.png 480w" sizes="((min-width: 0px) and (max-width: 480px)) 480px, ((min-width: 481px) and (max-width: 980px)) 980px, (min-width: 981px) 1181px, 100vw" />
            </div>
            <br>
<!--
             <div class="scrolling-container">
       <img class="full-width-image" src="https://assets.gobiz.be/imghome/mmoutput.png" alt="Image" />
     </div>
   -->
<br>
<strong>3) Networking - Free service</strong> <br>Start commercial relations and interact with companies whose identity/reliability has been subject to verification and certification and with companies present in the integrated national registers (Italy, Denmark and United Kingdom with progressive extension to other countries)
  <br>
      <br>
<strong>4) Chat - Free - Free Service </strong> <br>Manage every interaction/communication with other companies by taking advantage of multilingual simultaneous translation <br>
      <br>
      <hr>
         <br>
<strong>5) Advanced Matchmaking - Premium Service</strong> <br>Identify "tailor-made" partners, suppliers and customers based on an "intelligent" algorithm that allows a "vertical" search on a specific business sector chosen by the user, for example distribution or production of specific goods/services. The search is done by property or keyword.
  <br>
      <br>

            <div>
              <img src="https://assets.gobiz.be/imghome/mmoutput.png" alt="" width="354" height="200" title="" srcset="https://assets.gobiz.be/imghome/mmoutput.png 1181w, https://assets.gobiz.be/imghome/mmoutput.png 980w, https://assets.gobiz.be/imghome/mmoutput.png 480w" sizes="((min-width: 0px) and (max-width: 480px)) 480px, ((min-width: 481px) and (max-width: 980px)) 980px, (min-width: 981px) 1181px, 100vw" />
           </div>
            <br>
<!--
             <div class="scrolling-container">
       <img class="full-width-image" src="https://assets.gobiz.be/imghome/mmoutput.png" alt="Image" />
     </div>
   -->
<br>
<br>
<strong>6) Agreements Creation and Sign- Premium Service</strong><br>Drawing up contracts using contractual formats drawn up on the basis of the regulations in force at Community level <br>
      <br>

<strong>7) Chat and agreements notarization - Premium Service</strong> <br> Use a digital notary that certifies the integrity, authenticity and time stamp of communications/contracts exchanged with other companies to be used to resolve any disputes

      <br>
      <br>
      <hr>
         <br>
      <strong>8) Company Verification - On Demand Service</strong> <br> Check the reliability of your counterparty for the granting of trade credit
  <br>
      <br>
<strong>9) Company research and first meeting - On demand service</strong><br>Start commercial relations through "tailor made" searches carried out with the support of a dedicated team, to any EU country, with a first appointment organized by us .
  <br>
      <br>
     </h5>
      <!--
     <br>
 <br>

           <div class="btn btn-dark btnjoin" style="margin: 0 auto; width:30%; display: block;font-weight:bold;"> 
           <router-link style="color:white;" v-bind:to="'register'">Register</router-link>
           </div> 
      -->         
     </div>

    
    <div  v-if="this.lang=='Den'"> 
    <h5 style="text-align: left; margin-left: 10%; margin-right:10%">
Vi hjælper dig med at finde enhver partner, kunde i hele Europa for at lette din vækst og din eksport.
      <br>
       <br>
      Vi hjælper dig med at evaluere dine kommercielle modparter med værktøjerne til at evaluere deres soliditet og starte en ny virksomhed (Virksomhedsbekræftelse).
      <br>
       <br>
       Vi bruger vores netværk og registrerede brugere samt offentlige og private databaser og avancerede algoritmer til at finde din bedste B2B-partner.<br>
        <br>Du kan lave research uafhængigt eller med vores assistance eller bruge tjenesten "Virksomhedssøgning og første møde": med denne service tager vi os af alt. Du giver os detaljerne om, hvad du leder efter, og vi finder den rigtige partner til dig, selv planlægger det første forretningsmøde, efter at have kontrolleret på forhånd, at de opfylder dine krav.
        <br>
        <br>
<hr>
<strong>Ved at registrere dig hos Gobiz kan du bruge følgende tjenester:</strong>
<br>
<br>
<br>
     <strong>1) Fremvisning - Gratis tjenester</strong> <br> Opret et "virtuelt" udstillingsvindue for at promovere din virksomhed lokalt og internationalt
      <br>
      <br>
<strong>2) Smart Matchmaking - Gratis tjenester</strong> <br>Identificer "skræddersyede" partnere, leverandører og kunder baseret på en "intelligent" algoritme, som gennem et "smart matchmaking"-værktøj giver dig mulighed for at søg efter nøgleord under hensyntagen til kundevirksomhedens profil og optimer resultaterne baseret på mållandet og forretningsmålene.
  <br>
      <br>

            <div>
              <img src="https://assets.gobiz.be/imghome/mmoutput.png" alt="" width="354" height="200" title="" srcset="https://assets.gobiz.be/imghome/mmoutput.png 1181w, https://assets.gobiz.be/imghome/mmoutput.png 980w, https://assets.gobiz.be/imghome/mmoutput.png 480w" sizes="((min-width: 0px) and (max-width: 480px)) 480px, ((min-width: 481px) and (max-width: 980px)) 980px, (min-width: 981px) 1181px, 100vw" />
             </div>
            <br>
<!--
             <div class="scrolling-container">
       <img class="full-width-image" src="https://assets.gobiz.be/imghome/mmoutput.png" alt="Billede" />
     </div>
   -->
<br>
<strong>3) Netværk - Gratis tjenester</strong> <br>Start kommercielle relationer og interager med virksomheder, hvis identitet/pålidelighed har været genstand for verifikation og certificering og med virksomheder, der er til stede i de integrerede nationale registre (Italien, Danmark og United States) Kongerige med gradvis udvidelse til andre lande)
  <br>
      <br>
<strong>4) Chat - Gratis tjenester</strong> <br>Administrer enhver interaktion/kommunikation med andre virksomheder ved at drage fordel af flersproget simultanoversættelse <br>
      <br>
      <hr>
         <br>
<strong>5) Avanceret Matchmaking - "Premium" tjenester</strong> <br>Identificer "skræddersyede" partnere, leverandører og kunder baseret på en "intelligent" algoritme, der tillader en "lodret" søgning på en bestemt erhvervssektor valgt af brugeren, for eksempel distribution eller produktion af bestemte varer/ydelser. Søgningen udføres efter egenskab eller nøgleord.
  <br>
      <br>

            <div>
             <img src="https://assets.gobiz.be/imghome/mmoutput.png" alt="" width="354" height="200" title="" srcset="https://assets.gobiz.be/imghome/mmoutput.png 1181w, https://assets.gobiz.be/imghome/mmoutput.png 980w, https://assets.gobiz.be/imghome/mmoutput.png 480w" sizes="((min-width: 0px) and (max-width: 480px)) 480px, ((min-width: 481px) and (max-width: 980px)) 980px, (min-width: 981px) 1181px, 100vw" />
             </div>
            <br>
<!--
             <div class="scrolling-container">
       <img class="full-width-image" src="https://assets.gobiz.be/imghome/mmoutput.png" alt="Billede" />
     </div>
   -->
<br>
<br>
<strong>6) Oprettelse af aftaler - "Premium" tjenester</strong><br>Udarbejdelse af kontrakter ved hjælp af kontraktlige formater udarbejdet på grundlag af de gældende regler på fællesskabsplan <br>
      <br>

<strong>7) Chat og aftalenotarisering - "Premium" tjenester</strong> <br> Brug en digital notar, der attesterer integriteten, ægtheden og tidsstemplet for kommunikation/kontrakter udvekslet med andre virksomheder, der skal bruges til at løse eventuelle tvister

      <br>
      <br>
      <hr>
         <br>
      <strong>8) Virksomhedsbekræftelse - On Demand-tjeneste</strong> <br> Tjek pålideligheden af din modpart for tildeling af handelskredit
  <br>
      <br>
<strong>9) Virksomhedsundersøgelse og første møde - On Demand-tjeneste</strong><br>Start kommercielle relationer gennem "skræddersyede" søgninger udført med støtte fra et dedikeret team, til ethvert EU-land, med en første aftale organiseret af os.
  <br>
      <br>
     </h5>
 <br>
 <br>
  <!--
 <br>
 <br>
             <div class="btn btn-dark btnjoin" style="margin: 0 auto; width:30%; display: block;font-weight:bold;"> 
           <router-link style="color:white;" v-bind:to="'register'">Tilmeld dig nu!</router-link>
           </div> 
 -->
     </div>
          
          <div class="btn btn-success btnjoin" style="margin: 0 auto; width:26%; display: block;font-weight:bold;font-size: 1.1rem;"> 
          <router-link style="color:#0c294b;" v-bind:to="'home'">Home</router-link>
          </div>
    <br> <br> <br> 
    <br>
    <!--  pagina esterna con gaxios 
    <div  v-if="this.lang=='Ita'" v-html="this.titlePage"> </div>
    <h4 v-if="this.lang=='Ita'"><div v-html="this.contentPage">  </div> </h4>
    
    <div  v-if="this.lang=='Eng'" v-html="this.titlePageEng"> </div>
    <h4 v-if="this.lang=='Eng'"><div v-html="this.contentPageEng">  </div> </h4>

    <div  v-if="this.lang=='Den'" v-html="this.titlePageDen"> </div>
    <h4 v-if="this.lang=='Den'"><div v-html="this.contentPageDen">  </div> </h4>
    -->

  </div>
</template>

<script>
// @ is an alias to /src
import gAxios from 'axios';

export default {
  name: 'help',
  data () {
    return {
      name : 'stefano',
      surname : '',
      email : '',
      password : '',
      titlePage : null,
      titlePageEng : null,
      titlePageDen : null,
      contentPage : null,
      contentPageEng : null,
      contentPageDen : null
    }
  },
  computed : {
    labels(){
      
      return this.$store.getters.labels;
    },
    lang(){
      return this.$store.getters.lang;
    },
  },
  components: {
    
  },
  mounted: function(){
    this.$nextTick(function () {
        
        // ITA
        gAxios.get('./pages/new_ita.txt',
        {
            
        })
        .then(res => {
          
          this.titlePage = '';
          this.contentPage = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

        // ENG
        gAxios.get('./pages/new_eng.txt',
        {
            
        })
        .then(res => {
          this.titlePageEng = '';
          this.contentPageEng = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

        // DEN
        gAxios.get('./pages/new_den.txt',
        {
            
        })
        .then(res => {
          this.titlePageDen = '';
          this.contentPageDen = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );
            
    })
  }
}



</script>






<style>

.full-width-image {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none; /* Per evitare che l'immagine interferisca con l'interazione dell'utente */
}

</style>
