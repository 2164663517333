<template>
  <div class="container" style="margin-top:20px;">
    
      <!-- <h1>{{searchevents(this.labels,'menu','headerfirststepregister')}}</h1> -->
      
      <div class="form-group">
        
        <div class="row">
          <div class="col" v-if="this.response=='OK'">
            <br>
            <i class="far fa-fw fa-check-circle fa-3x mr-2 align-self-center"></i>
            <br>
            <br>
            <!-- registrazioni differenziate in base a paese sede legale - per italia riferimento alla PEC -->
            <h4 style="margin-left:10%;margin-right:10%"> <div for="disclaimer04" v-if="this.countryOfOrigin==1"   v-html="searchevents(this.labels,'menu','bodyfirststepregisterok1')"></div></h4>
            <h4 style="margin-left:10%;margin-right:10%"> <div for="disclaimer04" v-if="this.countryOfOrigin!=1"   v-html="searchevents(this.labels,'dialog','activateyourprofile')"></div></h4>
            </div>
          <div class="col" v-if="this.response=='KO'">
            <br>
            <i class="far fa-fw fa-times-circle fa-3x mr-2 align-self-center"></i>
            <br>
            <h4>  <div v-if="this.secondMessageITA=='' || this.secondMessageITA==null" for="disclaimer04" v-html="searchevents(this.labels,'dialog','opnotpermittedalreaypresent')"></div> </h4>
            <br>
            <br>
            <!-- registrazioni differenziate in base lingua di visualizzazione -->
            <h4 style="margin-left:10%;margin-right:10%"> <div for="disclaimer04" v-if="this.lang=='Ita'"   v-html="this.secondMessageITA"></div></h4>
            <h4 style="margin-left:10%;margin-right:10%"> <div for="disclaimer04" v-if="this.lang=='Eng'"   v-html="this.secondMessageENG"></div></h4>
            <h4 style="margin-left:10%;margin-right:10%"> <div for="disclaimer04" v-if="this.lang=='Den'" v-html="this.secondMessageDEN"></div></h4>
          </div>
        </div>
          
      </div>

      
  </div>
</template>


<script>

import router from '@/router';
import gAxios from 'axios';

export default {
  name: 'Register',
  data () {
    return {
      registeredCompany : null,
      countryOfOrigin : null,
      companyNumber : null,
      vatNumber : null,
      id : null,
      companyName : null,
      response : null,
      secondMessageITA : null,
      secondMessageENG : null,
      secondMessageDEN : null
    }
  },
  methods : {
    verifyCompanyStatus(){
      
       const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }

 
       gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/verifycompany',
                {
                   id : this.id,
                   countryOfOrigin : this.countryOfOrigin,
                   companyNumber : this.companyNumber,
                   userId : this.getUserId,
                   vatNumber : this.vatNumber
                },auth)
        .then(res => {

          this.response = res.data.message;

          this.secondMessageITA = res.data.secondMessageITA;
          this.secondMessageENG = res.data.secondMessageENG;
          this.secondMessageDEN = res.data.secondMessageDEN;

          //console.log(res.data.message);

          if(this.response=='KO'){
            this.$store.dispatch('ResetregisteredCompany' );
          }else{
            //console.log('passo per scrivere il messaggio');
            this.sendNewMessageBusinessCard();
          }
          
          //console.log(res);
          return res;
          //router.push('/dashboard');
        })
        .catch(
          error => {
          
            //console.log(error);
          // faccio qualcosa qui
          this.response = 'KO';
            return error;
          }
          );

    },
    generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        
        return newGuid;
    },
     sendNewMessageBusinessCard(){
            //let userId = this.getUserId; 
            
            const auth = {
              headers: {Authorization:'JWT ' + this.getToken} 
            }

            //console.log('--------->' + this.id);
            if(this.id!=null)
            {
                let guid = this.generateUUID();
                let chatMessage = '';
                chatMessage = this.searchevents(this.labels,'chat','welcomemessage');
                
                //console.log('--------->' + guid);

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/addwelcomechat',
                {
                    userId : 501,
                    companyIdFrom : 483,
                    companyIdTo : this.id,
                    companyTable : 'companies',
                    guid : guid,
                    firstMessage: 'Y',
                    blockchainsHash : '',
                    message : chatMessage,
                    messageTranslated : '',
                    toBeNotarized : 'Y',
                    chatId : '',
                    viewed: '0',
                    actionToDo : 'ADD'
                },auth)
                .then(res => {
                    // decido se mandare anche l'email oppure no
                    //this.sendEmail(companyIdfromCard,companyTable,'SEND','',this.newmessage,'Y');
                    
                    //console.log('----- res ---->' + res);
                    return res;
                })
                .catch(
                    error => {
                      //console.log('----error----->' + error);
                      if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        //console.log(error.response.data);
                        //console.log(error.response.status);
                        //console.log(error.response.headers);
                      } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        //console.log(error.request);
                      } else {
                        // Something happened in setting up the request that triggered an Error
                        //console.log('Error', error.message);
                      }
                      //console.log(error.config);
                    
                    
                    
                      return error;
                    }
                );
                
            }else{
                return null;
            }
    },
    searchevents(events,filterValue,label){
        if(events){
          const filter = event => 
            event.tableName.includes(filterValue) &&
            event.fieldName.includes(label)
        
          var filteredArray = events.filter(filter);
          if(filteredArray.length >0)
          {
            if(this.lang)
            {
              var labelName = 'label'+this.lang;
              return filteredArray[0][labelName];
            }else{
              return '--';
            }
            
          }else{
            return '--';
          }
        }else{
          return null;
        }
      },
  },
  computed :{
    lang(){
      return this.$store.getters.lang;
    },
    labels(){
      return this.$store.getters.labels;
    },
    FistStepRegister(){
        return this.$store.getters.FistStepRegister;
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
    getUserId(){
       // return null;
       return this.$store.getters.getUserId;
    },
    getregisteredCompany(){
      var rcomp = this.$store.getters.getregisteredCompany;
      if(rcomp){
        
        return rcomp;
      }else{ 
        return 0;
      }
    },
    
  },
  created(){
    // non la uso
    if(this.FistStepRegister==9000){
        //console.log('esci');
        router.push('/');
    }else{
        //console.log('resta');
    }
  },
  watch : {
      getregisteredCompany: function(){
      this.registeredCompany = this.getregisteredCompany;
      this.id = this.getregisteredCompany.id;
      this.companyName = this.getregisteredCompany.companyName;
      
      this.vatNumber = this.getregisteredCompany.vatNumber;
      this.companyNumber = this.getregisteredCompany.companyNumber;

      let splitComanyNumber = this.vatNumber;
        if(splitComanyNumber!=null){
          splitComanyNumber = this.vatNumber.split("-");
        }
      if(splitComanyNumber.length>1){
        this.vatNumber = splitComanyNumber[1];
        
      }
      
      this.countryOfOrigin = this.getregisteredCompany.countryOfOrigin;

      
    }
  },
  mounted(){
    this.$nextTick(function () {
      var reg = this.$store.getters.getregisteredCompany;
      if(reg!=null){ this.registeredCompany = reg; }
      
      if(this.registeredCompany && this.registeredCompany.id == null){
        router.push('/mycompany');
        
      }else{
        this.id = this.getregisteredCompany.id;
        this.companyName = this.getregisteredCompany.companyName;
        
        this.vatNumber = this.getregisteredCompany.vatNumber;

        let splitComanyNumber = this.vatNumber;
        if(splitComanyNumber!=null){
          splitComanyNumber = this.vatNumber.split("-");
            
            if(splitComanyNumber.length>1){
            this.vatNumber = splitComanyNumber[1];
          }
        }
        
        
        this.countryOfOrigin = this.getregisteredCompany.countryOfOrigin;
        this.companyNumber = this.getregisteredCompany.companyNumber;
        
        
        this.verifyCompanyStatus();
        
      }
    });
    // carico i dati e verifico lo stato
        
      
  },
  beforeUpdate(){
    
  },
  updated(){
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.count{
  font-size: 7em;
}
.btn{
  font-size: 3em;
}
hr{
  border-top: 2px solid #2c3e50;
}
</style>
