import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vue3GoogleLogin from 'vue3-google-login';

//var rootUrl = "https://accounts.google.com/o/oauth2/v2/auth";
//var options = {
 // redirect_uri: "https://www.gobiz.be/api/sessions/oauth/go",
 // client_id: "1039789772050-vjp3510t6o94p0mlims9cuaianq2dfl6.apps.googleusercontent.com",
 // access_type: 'offline',
//};

  //redirect_uri: "http://localhost:8080/api/sessions/oauth/go",
  //client_id: "340587491280-pnnqevb3lc0ddfd5pka49tqs0o7t4bi2.apps.googleusercontent.com",
  
  //import Amplify, * as AmplifyModules from 'aws-amplify'
  // @ts-ignore
  //import { AmplifyPlugin } from 'aws-amplify-vue'
  // @ts-ignore
 // import aws_exports from './aws-exports'
 //Amplify.configure(aws_exports)
  
  //app.use(AmplifyPlugin, AmplifyModules)

const app = createApp({
  render: (h) => h(App),
  router,
  store
})

app.config.productionTip = false;

const Rollbar = require('vue-rollbar');

app.use(vue3GoogleLogin, {
//testgobizbe
//clientId: '1039789772050-bim8u179da2u78fslpvj7lqie3nfk4sq.apps.googleusercontent.com'
 clientId: process.env.VUE_APP_CLIENT_ID

 //production
 //clientId: '573794307718-sej0r2nvh4a4b3gmp1j0ib7p0d5me1c3.apps.googleusercontent.com'
 //localhost
 //clientId: '340587491280-pnnqevb3lc0ddfd5pka49tqs0o7t4bi2.apps.googleusercontent.com'
 //rollbar
 //test
 //accessToken: 'fb91defed7024d838b044ec680db3aac', 
  //production
 //accessToken: '2d7bd1be976c4f9bb8e60899128e16fd', 
});


app.use(Rollbar, {
  accessToken: '2d7bd1be976c4f9bb8e60899128e16fd',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: false, // true,
  source_map_enabled: true,
  environment: 'dev',
  payload: {
    client: {
      javascript: {
        code_version: '1.0',
        plugin: 'vue-rollbar'
      }
    }
  }
});



app.config.errorHandler = (err, vm) => {  vm.$rollbar.error(err);
  //console.log(`Error: ${err.toString()}\nInfo: ${info}`);
  throw err; // rethrow
};

app.use(router);
app.use(store);

app.mount('#app');

