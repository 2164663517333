<template>
  <div class="container" style="margin-top:80px;">
    
      <h1 v-if="this.companyNameInvited!='' && this.companyNameInvited!=null">{{searchevents(this.labels,'menu','confirmnotinvitedheader')}}</h1>
      
      <div v-if="this.companyNameInvited!='' && this.companyNameInvited!=null" class="form-group" >
        
        <div class="row">
          <div class="col-12">
             {{this.companyNameInvited}}
          </div>
          <div class="col-12">
              <button type="button" class="btn btn-pdf" style="font-size: .9em;" @click="deleteCompany()" >{{searchevents(this.labels,'menu','confirmnotinvitedbutton')}}</button>
          </div>
        </div>
          
      </div>

      
  </div>
</template>


<script>

import router from '@/router';
import gAxios from 'axios';

export default {
  name: 'ConfirmNotInviting',
  data () {
    return {
      message : null,
      companyNumberInvited : null,
      countryOfOriginInvited : null,
      companyNameInvited : null,
      companyIdInvited : null
    }
  },
  methods : {
    resResult(what){
      return what;
    },
    deleteCompany(){
      let table = this.gettableToNotInvite;
      this.countryOfOriginInvited = this.getcompanyCountryOfOriginInvite;
      this.companyNumberInvited = this.getcompanyNumberToNotInvite;

      
      //console.log(table+'---'+this.countryOfOriginInvited+'||||||'+this.companyNumberInvited);
      
      if(table=='companies'){
          gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/returnCompanyDataGoBizD',
                  {
                    companyNumberTo : this.companyNumberInvited,
                    countryOfOrigin : this.countryOfOriginInvited,
                  })
                  .then(res => {
                  //console.log(res);
                  this.resResult(res);
                  
        }).catch(
                error => {
                
                return error;
                }
                );
      }else{
          gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/returnCompanyDataD',
                  {
                    companyNumberTo : this.companyNumberInvited,
                    countryOfOrigin : this.countryOfOriginInvited,
                  })
                  .then(res => {
                  //console.log(res);
                  this.resResult(res);

                  let risposta = this.searchevents(this.labels,'modify','operationsuccess');
                  alert(risposta);
                  router.push('/');
                  
        }).catch(
                error => {
                //console.log(error);
                return error;
                }
                );
      }
    },
    callCompany(){

      let table = this.gettableToNotInvite;
      this.countryOfOriginInvited = this.getcompanyCountryOfOriginInvite;
      this.companyNumberInvited = this.getcompanyNumberToNotInvite;

      
      //console.log(table+'---'+this.countryOfOriginInvited+'||||||'+this.companyNumberInvited);
      
      if(table=='companies'){
          gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/returnCompanyDataGoBizF',
                  {
                    companyNumberTo : this.companyNumberInvited,
                    countryOfOrigin : this.countryOfOriginInvited,
                  })
                  .then(res => {
                  //console.log(res);
                  
                  this.companyNameInvited = res.data.companyName;
                  this.companyIdInvited = res.data.id;
                  //alert('Operazione non consentita, la username inserita risulta già registrata si prega di selezionarne una diversa');
                  
        }).catch(
                error => {
                
                return error;
                }
                );
      }else{
          gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/returnCompanyDataF',
                  {
                    companyNumberTo : this.companyNumberInvited,
                    countryOfOrigin : this.countryOfOriginInvited,
                  })
                  .then(res => {
                  //console.log(res);
                  
                  this.companyNameInvited = res.data.companyName;
                  this.companyIdInvited = res.data.id;
                  //alert('Operazione non consentita, la username inserita risulta già registrata si prega di selezionarne una diversa');
                  
        }).catch(
                error => {
                
                return error;
                }
                );
      }


    },
    searchevents(events,filterValue,label){
        if(events){
          const filter = event => 
            event.tableName.includes(filterValue) &&
            event.fieldName.includes(label)
        
          var filteredArray = events.filter(filter);
          if(filteredArray.length >0)
          {
            if(this.lang)
            {
              var labelName = 'label'+this.lang;
              return filteredArray[0][labelName];
            }else{
              return '--';
            }
            
          }else{
            return '--';
          }
        }else{
          return null;
        }
      },
  },
  computed :{
    lang(){
      return this.$store.getters.lang;
    },
    labels(){
      return this.$store.getters.labels;
    },
    gettableToNotInvite(){
      return this.$store.getters.gettableToNotInvite;
    },
    getcompanyNumberToNotInvite(){
      return this.$store.getters.getcompanyNumberToNotInvite;
    },
    getcompanyCountryOfOriginInvite(){
      return this.$store.getters.getcompanyCountryOfOriginInvite;
    }
     
    
  },
  created(){
     
    if(this.$route.params.guid!=''){
        // ritorna risposta positiva o negativa
        
        if(this.$route.params.guid!='AskConfirm'){
          //console.log('segno la richiesta<<<<');
          let guid = this.$route.params.guid;
          let tab = this.$route.params.tab;
          let countryOfOrigin = this.$route.params.coo;
          let table = '';
          if(tab==1){
            table = 'company_infocamere';
          }
          if(tab==2){
            table = 'company_house';
          }
          if(tab==504){
            table = 'company_denmark';
          }
          if(tab==0){
            table = 'companies';
          }
          //console.log(guid+'<---->'+table);
          this.$store.dispatch('ConfirmNotInviting', guid );
          this.$store.dispatch('ConfirmNotInvitingTab', table );
          this.$store.dispatch('ConfirmNotInvitingCoo', countryOfOrigin );
        }

        if(this.$route.params.guid=='AskConfirm'){
          //let companyNumberToNotInvite = this.$store.getters.getcompanyNumberToNotInvite;
          //console.log('chiedo conferma' + companyNumberToNotInvite);
          this.callCompany();
        }
        

        //if(this.$route.params.act=='LR')
        //{

          /*
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/confirmemail',
            {
                guidIdentity : this.$route.params.guid,
                guidContract : this.$route.params.guid,
                action : this.$route.params.act
            })
            .then(res => {
                if(res.data.act=='LR'){
                    
                    res.data.user.password = 'fammipassare';
                    this.$store.dispatch('login', res.data.user );
                }else{
                    //console.log(res.data.user);
                    //this.$store.dispatch('confirmEmailAct', res.data.message );
                    res.data.user.password = 'fammipassare';
                    this.$store.dispatch('login', res.data.user );
                }
                
                //console.log(this.$route.params.guid);
                //console.log(this.$route.params.act);

                //console.log(res);
                return res;
            })
            .catch(
                error => {
                this.$store.dispatch('confirmEmailAct', error );
                //console.log(error);
                return error;
                }
            );
        //}

        */

        this.message = 'Yooooooooo';
        
    }else{
        this.message = 'NO!';
    }
  },
  watch : {
     
  },
  mounted(){
    this.message = this.getConfirmedEmail;
  },
  beforeUpdate(){
    
  },
  updated(){
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.count{
  font-size: 7em;
}
.btn{
  font-size: 3em;
}
hr{
  border-top: 2px solid #2c3e50;
}
</style>
