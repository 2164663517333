<template>
  <div class="container" style="max-width: 100% !important;">
    
    <h1>{{searchevents(this.labels,'menu','mycompanies')}}</h1>

    <div class="container tablelist" style="max-width: 95%;">

        <div style="float:center; text-align: center;" :class="'row '">
                <router-link class="nav-link buttNavGreen" :to="{ name : 'updatecompany'}">{{searchevents(this.labels,'menu','insertcompany')}}</router-link>
                <!-- <button type="button" class="btn btn-success" style="font-size: .8em;" @click="goTo('updatecompany')" >{{searchevents(labels,'menu','insertcompany')}}</button> -->
        </div>



      <template>        
              <table class="table tablelist" >
              <thead>
              <tr>
                <td>
                  <div :class="'row blu-header'">
                      <div class="col-sm" >{{searchevents(this.labels,'table','companynamelabel')}}</div>
                      <div class="col-sm" >{{searchevents(this.labels,'menu','editcompany')}}</div>
                      <div class="col-sm" >{{searchevents(this.labels,'menu','mynetwork')}}</div>
                  </div>
                </td>
                  
              </tr>
              </thead>
              <tbody>
                  <tr><td >
                    <template>
                      <div>
                      <div v-for="(row, index) in mycompanies"  class="list-group-item" :key="row.companyName">
                          <div  :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">
                              <div class="col-sm" > {{row.companyName}}</div>
                              
                              <div class="col-sm" >
                                  <button type="button" class="btn btn-success" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'menu','editcompany')}}</button>
                              </div>
                              <div class="col-sm">
                                  <button type="button" class="btn btn-info" style="font-size: .8em;" @click="setMyCompanyIdTo(row.id,'NET')" >{{searchevents(labels,'menu','mynetwork')}}</button>
                              </div> 
                      </div>
                </div></div>
                      </template>
                      
                  </td>
                  </tr>
              </tbody>
          </table>
          
          </template>



    </div>      

    <!-- <a class="nav-link" href="#" @click="getM()" >carica9</a> -->
  </div>
</template>

<script>

import router from '@/router';
import gAxios from 'axios';


export default {

  name: 'mycompanies',
  data () {
    return {
      mycompanies : []
    }
  },
  methods : {
    setMyCompanyIdTo(companyIdTo,action){
        this.$store.dispatch('setcompanyIdTo', companyIdTo );
        if(action=='NET'){
            router.push('/mynetwork');
        }

        if(action=='CONT'){
            router.push('/mycontacts');
        }
        
    },
    goTo(where){
        window.location.href = where;
    },
    modifyCompany(company){
        this.$store.dispatch('SetregisteredCompany', company );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updatecompany');
      
    },
    getM(){
        
    return null;
    },
    searchevents(events,filterValue,label){
    //events = events2;
      if(events){
        
        const filter = event => 
          event.tableName.includes(filterValue) &&
          event.fieldName.includes(label)
      
        var filteredArray = events.filter(filter);
        if(filteredArray.length >0)
        {
          var labelName = 'label'+this.lang;
          return filteredArray[0][labelName];
        }else{
          return '--';
        }
        
      }else{
        return null;
      }
      
    }
        
  },
  computed :{
    
    lang(){
      var lng = this.$store.getters.lang;
        if(lng)
        {
          return lng;
        }else{
          return '';
        }
    },
    labels(){
      return this.$store.getters.labels;
    },
    dropM(){
      
      var menu = this.$store.getters.dropM;
      if(menu){
        //this.dropM1 = menu[11];
        return menu;
      }else{
        return null;
      }
      
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
    getUserId(){
        return this.$store.getters.getUserId;
    },
    getCompanyIdTo(){
        return this.$store.getters.getCompamyIdTo;
    }
  },
  created() {
      
    
  },
  watch : {
      dropM: function () {
      // potrei anche leggere il nuovo e il vecchio valore (newdropMservLandlinePrefixId,olddropMservLandlinePrefixId)
      return null;
    },
      getM: function () {
          return null;
      }
  },
  components: {
    
  },
  mounted: function () {
      
     
    
    this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been rendered
        this.$store.dispatch('ResetregisteredCompany' );

        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
            if(userId!=null)
            {
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/allUsersMe',
                {
                   userId : userId
                },auth)
                .then(res => {
                    
                    
                    let users = res.data.users;
                    users.forEach(u => {
                        let company = u.companies;
                        company.forEach(comp => {
                            
                            // prima di aggiungere la company verifico che non sia già presente
                            let trovata = 0;
                            this.mycompanies.forEach(myC => {
                                if(myC.id == comp.id){ trovata++; }
                            })
                            if(trovata==0){ 
                                this.mycompanies.push(comp); 
                                //if(u.companies.length==1 && comp.servCompaniesStateCompaniesState=='R'){
                                  
                                //  this.modifyCompany(comp);
                                //}
                              }
                        });
                    });
                    
                    if(this.mycompanies.length==1 && this.mycompanies[0].servCompaniesStateCompaniesState=='R'){
                                
                                this.modifyCompany(this.mycompanies);
                              }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
            }else{
                return null;
            }
    })
    
}
}

</script>

<style lang="scss">

    .butt{
        width:300px; 
        display: initial; 
        background-color: antiquewhite;
    }
</style>