<template>
  <div class="container" style="max-width: 95% !important;">
     <div class="text-center">
     &nbsp;<h1 v-if="this.isRequestedVisura=='no'">{{searchevents(this.labels,'menu','messagesarea')}}</h1>
     &nbsp;<h1 v-if="this.isRequestedVisura!='no'" v-html="searchevents(this.labels,'menu','messagesareavisura')"></h1> 
    
       
    <button v-if="this.selectedCompanyId==null" type="button" class="btn btn-success" style="font-size: .8em;" @click="backToDashoboard()">{{searchevents(this.labels,'menu','backglobal')}} </button>
    
     <!-- link refresh alternativo
             <div>
              <a class=nav-link> <router-link :to="{ name : 'mychats'}"> {{searchevents(labels,'dialog','checknewmsgs')}}</router-link></a>
            </div>
      -->

    </div>

  
    <!-- messaggio visura richiesta -->
             <div v-if="this.selectedCompanyId==null" class="alert alert-light alert-dismissible fade show" style="margin-left: 12%; margin-right: 12%; height: fill-available;">
               <div class="row">
                <div class="col-sm">
                  <div class="card bg-light mt-6">
                      <div class="card-body">
                        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        <div  style="font-weight: bold">
                        <i class="fas fa-fw fa-lg fa-info-circle mr-2 align-self-center" style="font-size:25px;width: 100%;margin-left: 10px;margin-right: 10px;color: #0C294B;"></i>
                        <h5 v-if="this.isRequestedVisura=='no'" style="font-size:1.2em;" v-html="searchevents(this.labels,'textinfo','chatdescr')"></h5>
                        <h5 v-if="this.isRequestedVisura!='no'" style="font-size:1.2em;" v-html="searchevents(this.labels,'textinfo','infoareavisura')"></h5>
                        </div>
                        <!-- <h5><strong>OLD (ex tooltip):</strong></h5>
                        <h5>{{searchevents(labels,'dialog','mmalgoritmogobiz')}}</h5> -->
                       </div> 
                </div>
              </div>
           </div>
        </div> 


 

    <!-- scheda informativa per anagrafiche approvate --> 
        <div v-if="this.selectedCompanyId!=null" class="alert alert-light alert-dismissible fade show" style="box-sizing: content-box;margin-top:0 px; margin-left: 14%; margin-right: 14%; height: fill-available;" >
               <div class="row">
                <div class="col-sm">
                  <div class="card bg-light mt-6">
                     <div class="card-body">
                     <h5>  <i style="margin-top:0 px" class="fas fa-fw fa-info-circle fa-lg justify-center align-self-center"></i></h5>
                    <h5 style="text-align: center;" v-html="searchevents(this.labels,'dialog','edittranslationpreview')"></h5>
                    <!-- DA SPOSTARE SU TOOLTIP <hr>
                    <h6 v-html="searchevents(this.labels,'dialog','edittranslationofmessage')"></h6> -->
                       <hr> 
                    <!-- <i class="fas fa-fw fa-lock mr-2 align-self-center" style="font-size:25px;color:#28a745;justify-center;margin-left: 7px;;" ></i> -->
                     <button class="btn-tooltip-info" v-tooltip="{content: searchevents(labels,'tooltipval','greenlock'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                        <span class="fas fa-fw fa-lock mr-2 align-self-center" aria-hidden="true" style="font-size:25px;color:#28a745;justify-center;margin-left: 7px;"></span>
                        </button>
                    <div style="text-align: center;">
                     <h5  v-html="searchevents(this.labels,'dialog','verifynotarization')"></h5>
                     </div>
                       <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                          
                 </div> 
                </div>
              </div>
           </div>
        </div> 



                    <!--
                    <div class="col">
                        <div class="row" style="font-size: 0.8em;  margin-left: 20px; padding: 5px;">
                            <div style="background-color: #07ff31; width: 20px; margin-right: 5px; margin-left: 10px;"></div>
                            <div> {{searchevents(this.labels,'menu','emailtoread')}}  </div>
                        </div>
                        <div class="row" style="font-size: 0.8em;  margin-left: 20px; padding: 5px;">
                            <div style="background-color: #ffc107; width: 20px; margin-right: 5px; margin-left: 10px;"></div>
                            <div> {{searchevents(this.labels,'menu','emailpendingextragobiz')}}  </div>
                        </div>
                        <div class="row" style="font-size: 0.8em;  margin-left: 20px; padding: 5px;">  
                            <div style="background-color: #07baff; width: 20px; margin-right: 5px; margin-left: 10px;"></div>
                            <div> {{searchevents(this.labels,'menu','emailfirstmessage')}}  </div>
                        </div>
                    </div>
                    -->
                    

    <!-- <button v-if="this.selectedCompanyId!=null" type="button" class="btn btn-success" style="font-size: .8em;" @click="setSelectedCompanyId(null,null,null,null,null)" >{{searchevents(labels,'menu','backglobal')}}</button> -->
    
        
        
      
    <!-- My Messages ########################################################################################### -->
    <div v-if="this.selectedCompanyId==null" class="container tablelist" style="max-width: 95%;">
            <!-- <h2>{{searchevents(this.labels,'menu','mynetworkallworked')}}</h2> -->
            <hr>
            <template>        
                    <table class="table tablelist" >
                    
                    <thead>
                    <tr>
                      <td>
                        <div :class="'row blu-header'">
                            <div class="col-sm" >{{searchevents(labels,'matchmaking','companyDB')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'companies','companyName')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'matchmaking','companydetails')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'chat','previewlastmessage')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'dialog','datelasmessage')}}</div>

                            <!--<div class="col-sm" >{{searchevents(labels,'companies','countryOfOrigin')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'matchmaking','ateco1')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'matchmaking','mainactivity')}}</div> -->
                            
                            <!-- <div class="col-sm" >{{searchevents(this.labels,'table','viewedchat')}}</div> -->
                             <!-- colonna più larga per nuovo tasto per accordi e contratti -->
                            <div class="col-sm-4" >{{searchevents(this.labels,'dialog','seechat')}}</div>

                            <!-- <div class="col-sm" >{{searchevents(labels,'matchmaking','mynetworkstatus')}}</div> -->

                            <!--
                            <div class="col-sm" >{{searchevents(this.labels,'menu','networkstatusglobal')}}</div>
                            <div class="col-sm" >{{searchevents(this.labels,'table','viewedchat')}}</div>
                            <div class="col-sm" >{{searchevents(this.labels,'companies','companyName')}}</div>
                            <div class="col-sm" >{{searchevents(this.labels,'matchmaking','companydetails')}}</div>
                            <div class="col-sm" >{{searchevents(this.labels,'menu','workglobal')}} </div>
                            -->
                        </div>
                      </td>
                        
                    </tr>
                    </thead>
                    <tbody>
                        <tr><td >
                        <!-- <template v-for="row0 in MMComanies" > -->
                    <template>
                    <div>
                        <div v-for="(row, index) in mynetworkAll"  class="list-group-item" :key="index+25"  >
                                <div :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">
                                    
                                    <div class="col-sm" >{{returnDB(row.db)}}</div>

                                    <div class="col-sm" >{{printCompanyName('companyName',row.aa1, row.ab1)}}</div>

                                    <div class="col-sm" >
                                        <!-- <a data-toggle="collapse" :href="'#collapseMM'+index" class="list-group-item">{{searchevents(labels,'matchmaking','companydetails')}}</a> -->
                                        <button v-if="row.db=='companies'" type="button" class="btn btn-primary" style="font-size: 0.8em;" @click="setSelectedCompanyIdDetail(renderCompanyProfileId(row),row.companyTable, 'N', 'N')">{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                        <button v-if="row.db!='companies'" type="button" class="btn btn-primary" style="font-size: 0.8em;" @click="setSelectedCompanyIdDetail(renderCompanyProfileId(row),row.companyTable, 'N', 'N')">{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                    </div>

                                   
                                    <div class="col-sm" :style="setVisuraRedStyle(''+row.message+'')">{{trimMessage(row.message)}}</div>
                                      <!-- <div class="col-sm" v-html="trimData(row.createdAt)">{{trimData(row.createdAt)}}</div> -->
                                    <div class="col-sm" v-html="trimData(row.createdAt)"></div>

                                    <!-- <div class="col-sm" >{{printCompanyName('companyCountry',row.aa2, row.ab2)}}</div>
                                    <div class="col-sm" >{{printCompanyName('companyAteco',row.aa3, row.ab3)}}</div>
                                    <div class="col-sm" >{{printCompanyName('companyDescription',row.aa4, row.ab4)}}</div> -->
                                   
                                    
                                    <!-- <div class="col-sm" >{{returnViewed(row.viewed)}} </div> -->

                                   <!-- nuovo tasto per accordi e contratti -->
                                   
                                    <div class="col-sm-4" v-if="row.db=='companies' && row.banned=='N' && ((row.servCompaniesStateCompaniesState1!='H' && row.servCompaniesStateCompaniesState2!='H'))">
                                        <button type="button" class="btn btn-success minpadding" style="font-size: .8em; height: 40px; width: 43%;" @click="setSelectedCompanyId(row.id,row.companyTable,row.guid,row.companyId1,row.companyId2)" :key="row.id" >{{searchevents(labels,'menu','workglobal')}}</button>
                                       <button v-if="getIdUsersParent==-1 && getregisteredCompany.servCompaniesStateCompaniesState=='A' " type="button" class="btn btn-success minpadding" style="font-size: .8em; height: 40px; width: 43%; margin-left: 4px;;margin-right: 0px;" @click="gotoagreements(getregisteredCompany.id,row.companyId1,row.companyId2,row.guid)">{{searchevents(labels,'menu','setyouragreement')}} <span class="badge minmarginleft" v-if="row.contractsOpened>0">{{row.contractsOpened}} </span> </button>
                                  </div>
                                    
                                    <div class="col-sm-4" v-if="row.db=='companies' && row.banned=='Y' && ((row.servCompaniesStateCompaniesState1!='H' && row.servCompaniesStateCompaniesState2!='H'))">
                                         {{searchevents(labels,'dialog','opnotpermittenomessage')}}
                                    </div>
                                    
                                    <div class="col-sm-4" v-if="row.db=='companies' && (row.servCompaniesStateCompaniesState1=='H' || row.servCompaniesStateCompaniesState2=='H' )  " >
                                        {{searchevents(labels,'companies','suspended')}}
                                    </div>

                                    <div class="col-sm-4" v-if="row.db!='companies'">
                                        {{searchevents(labels,'matchmaking','nomoreactionspobbile')}}
                                    </div>

                                    <!-- <div class="col-sm" :style="setBackgroudStyleStatus(row.userId, row.firstMessage, row.companyTable)">{{setBackgroudStyleStatusText(row.userId, row.firstMessage, row.companyTable)}}</div> -->
                            </div>
                            <div :key="index"  :id="'collapseMM'+index" class="panel-collapse collapse">
                                <div class="col-sm-12" >
                                    {{renderCompanyProfile(row)}}
                                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myBusinessCard" >{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                    <button type="button" class="btn btn-warning" style="font-size: .8em;" @click="banCompanyConfirm(row.guid)"  >{{searchevents(labels,'menu','gobanned')}}</button>
                                </div>
                            </div>
                              </div>
                                </div>
                            </template>
                            
                        </td>
                        </tr>
                    </tbody>
                </table>
                </template>

            

    </div> <!-- termine container -->

  <!-- loader da implementare   <div class="text-center"> 
        <button v-if="this.loading" class="btn btn-success" style=" border-color:#e5d237; background-color: #0c294b; position: fixed; top: 45%; z-index: 20; font-size: inherit; opacity: 1;" disabled>
      <span class="spinner-border spinner-border-sm"></span>
      Loading..
    </button>
    </div>

  -->
   <div v-if="this.selectedCompanyId!=null">
    <button v-if="this.selectedCompanyId!=null" type="button" class="btn btn-success" style="font-size: .9em; width: 18%; min-height:60px; min-width: 180px" @click="backToDashoboard()" >{{searchevents(labels,'menu','backglobal')}}</button>
     &nbsp;&nbsp;&nbsp;&nbsp;
    <button v-if="this.selectedCompanyId!=null" type="button" class="btn btn-primary" style="font-size: .9em; width: 18%; min-height:60px; min-width: 180px" @click="refreshaction()" > {{searchevents(labels,'dialog','checknewmsgs')}}
     </button>  
     &nbsp;&nbsp;&nbsp; &nbsp; 
    <button v-if="this.selectedCompanyId!=null && getIdUsersParent==-1 && mynetworkChatList.length>0 && getregisteredCompany.servCompaniesStateCompaniesState=='A' " type="button" class="btn btn-dark" style="font-size: .9em; width: 18%; min-height:60px; min-width: 180px" @click="gotoagreements2(getregisteredCompany.id,mynetworkChatList[0].companyIdFrom,mynetworkChatList[0].companyIdTo,mynetworkChatList[0].guid)" >{{searchevents(labels,'dialog','newagreements')}}"{{this.mycompanies.companyName}}"</button>
    <hr> 
   </div> 
   

    <div v-if="this.selectedCompanyId!=null" class="container tablelist" style="max-width: 95%;">
            <h2 style="margin-bottom:20px;">{{this.mycompanies.companyName}} </h2>

            <template>        
                <table class="table tablelist" >
                <!--
                <thead>
                <tr>
                    <th>{{searchevents(this.labels,'table','messagechat')}} {{setViewedAllChatsByGuid()}}</th>
                    <th>{{searchevents(this.labels,'table','messagetranslatedchat')}}</th>
                    <th>{{searchevents(this.labels,'table','viewedchat')}}</th>
                    <th>{{searchevents(this.labels,'table','createdatchat')}}</th>
                    <th>{{searchevents(this.labels,'table','usertextnamechat')}}</th>
                    <th>{{searchevents(this.labels,'table','blockchainsHash')}}</th>
                </tr>
                </thead>
                -->
                <tbody>
                <tr>
                    <td colspan="6">
                        <div style="float:left; width:72%;margin-left: 10px;"><textarea class="form-control" rows="2" id="newmessage" v-model="newmessage" :placeholder="searchevents(this.labels,'dialog','newmessage')" ></textarea></div>
                        <div style="float:left; "><button type="button" class="btn bigbutton" style="font-size: .8em; float: left; margin-left: 20px;  width: 60px;" @click="sendNewMessage()" ><span class="fa fa-angle-double-right" style="font-size:25px;color:#ffffff;width: 100%;" ></span> {{searchevents(labels,'menu','sendglobal')}}</button></div>
                        <div style="float:left; margin-left: 20px; ">
                            <h4 style="cursor: pointer; float: left; width: 80px;" class="btn bigbutton" @click="openSelectionFile()"><i class="fas fa-paperclip"></i></h4> 
                        </div>
                        <div style="float:left; ">
                            <button type="button" class="btn btn-dark bigbutton" style="font-size: 1em; float: left; height: 60px; width: 100px; margin-left: 25px;" @click="removeAttachmentsFile()" >rimuovi file</button> 
                        </div>
                        <div style="float:left; ">
                            <button type="button" class="btn btn-dark bigbutton" style="font-size: 1em; float: left; height: 60px; width: 100px; margin-left: 25px;" @click="translateGoogleNew(newmessage)" >{{searchevents(labels,'menu','translatetoeng')}}</button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <input style="margin-left: 10px; width:0px; float: left; display:none;" type="file" @change="this.previewFile" ref="mynewdoc" class="form-control-file " id="newDocs" accept=".pdf, .jpg, .png, .doc, .docx "> 
                        <div style="float:left; margin-left: 12px;" v-if="this.newDocDescriptionSelected!='' && this.newDocDescriptionSelected!=null ">File: {{this.newDocDescriptionSelected}}</div>
                    </td>
                   
                </tr>
                  <template>
                    <div>
                        <div v-for="(row,index) in mynetworkChatList" :key="row.createdAt">
            
                <template v-if="mynetworkChatList[index-1]==undefined "  > 
                <tr  >
                    <td colspan="6">
                        <h3 style="margin-top: 20px;">{{trimData1(row.createdAt)}} </h3>
                    </td>
                </tr>
                </template>
                   <template  v-if="mynetworkChatList[index-1]!=undefined && trimData1(row.createdAt)!=trimData1(mynetworkChatList[index-1].createdAt)"  > 
                <tr >
                    <td colspan="6">
                       <h3 style="margin-top: 20px;"> {{trimData1(row.createdAt)}} </h3>
                    </td>
                </tr>
                 
                </template>
                
                
                <!-- inizio sequenza CHAT -->
                <tr >
                    
                    <td  :key="row.createdAt" colspan="6">

                        <template v-if="row.userId==getUserId">
                            <div :style="setBackgroudStyle(''+row.userId+'',''+getUserId+'',''+row.message+'')"><span v-if="row.message!='null'"> {{row.message}} </span>

                                <div v-if="row.filename!=null && row.filename!=''" style="font-style: oblique; font-size: 0.8em; padding: 0px; margin: 0px; float: right; color: black;">
                                    <a class="nav-link" :href="remoteServer + '/docs/doc_chats/' + row.filename" target="_blank"  >{{row.filedescription}}</a>
                                </div>
                            </div>
                            <div style="float:left; top:0px; margin-left: 15px; max-width: 110px;">
                                <span class="fa fa-check-circle" style="font-size:25px;color:#28a745;width: 100%; text-align: left;" v-if="row.viewed==1"><div style="float: right; font-size: small;" v-html="trimData(row.updatedAt)"></div></span>
                                <span class="fa fa-check-circle" style="font-size:25px;color:#444444;width: 100%; text-align: left;" v-if="row.viewed==0"></span>

                                <span class="fa fa-lock " style="font-size:25px;color:#28a745;width: 100%; text-align: left;" v-if="row.notarized=='Y'"><div style="float: right; font-size: small;" v-html="trimData(row.notarizedDate)"></div></span>
                                <span class="fa fa-lock " style="font-size:25px;color:#444444;width: 100%; text-align: left;" v-if="row.notarized=='N'"></span>

                                <!-- {{renderMessageViewed(row.viewed)}} -->
                            </div>
                            <div style="float:left; width:100%; margin-bottom:20px;"></div>
                        
                        </template>
                        <template v-if="row.userId!=getUserId">
                            
                            <div style="float:right; top:0px; margin-left: 15px; max-width: 110px;">
                                <span class="fa fa-check-circle" style="font-size:25px;color:#28a745;width: 100%; text-align: left;" v-if="row.viewed==1"></span>
                                <span class="fa fa-check-circle" style="font-size:25px;color:#444444;width: 100%; text-align: left;" v-if="row.viewed==0"></span>

                                <span class="fa fa-lock " style="font-size:25px;color:#28a745;width: 100%; text-align: left;" v-if="row.notarized=='Y'"><div style="float: right; font-size: small;" v-html="trimData(row.notarizedDate)"></div></span>
                                <span class="fa fa-lock " style="font-size:25px;color:#444444;width: 100%; text-align: left;" v-if="row.notarized=='N'"></span>

                                <!-- {{renderMessageViewed(row.viewed)}} -->
                            </div>
                            <div :style="setBackgroudStyle(''+row.userId+'',''+getUserId+'',''+row.message+'')"><span v-if="row.message!='null'"> {{row.message}} </span>

                                <div style="font-style: oblique; font-size: 0.8em; padding: 0px; margin: 0px; float: right; color: black;">({{row.userInfo}})
                                    <!-- <div v-if="row.filename!=null && row.filename!=''" style="font-style: oblique; font-size: 0.8em; padding: 0px; margin: 0px; float: right; color: black;"> -->
                                        <a v-if="row.filename!=null && row.filename!=''" class="nav-link" :href="remoteServer + '/docs/doc_chats/' + row.filename" target="_blank"  >{{row.filedescription}}</a>
                                    <!-- </div> -->
                                </div>
                                
                            </div>
                            <div style="width:100%;float:right;margin-top: 5px;"></div>
                            <div style="float: right; width:100%">
                                <button v-if="row.messageTranslated=='' || row.messageTranslated==null" type="button" class="btn btn-warning" style="font-size: .8em; float: right; margin-right: 20%; width: 120px;" @click="translateGoogle(row.message, row.id)" :key="row.id" >{{searchevents(labels,'menu','translateglobal')}}</button>
                            </div>
                            <div style="padding: 20px; border-radius: 10px 40px 0px 10px; background-color: #f7f6f6; margin-right: 50px;  width: 25%; float: right;">{{row.messageTranslated}}</div>

                            <div style="float:left; width:100%; margin-bottom:20px;"></div>
                            
                        </template>

                    </td>
                
                </tr>
                 </div>
                        </div> 
                


               

                </template>
                </tbody>
            </table>
      <!-- check nuovi messaggi
      <div>
      <button type="button" class="btn btn-success" style="font-size: .9em; width: 22%; min-width: 180px" @click="refreshaction()" > {{searchevents(labels,'dialog','checknewmsgs')}}
      </button>
      <br>
      <br>
      <br>
      </div>  -->
            </template>

    </div> <!-- termine container -->



  </div> <!-- secondo container -->



</template>

<script>

import router from '@/router';
import gAxios from 'axios';


export default {

  name: 'myusers',
  data () {
    return {
      mycompanies : [],
      mycompaniesDetails : [],
      mycompaniesDetailsDocs : [
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''}],
      mycompaniesDetailsCountries: [
      {"cnam1":''}],
      companyTypeSelected : null,
      remoteServer : process.env.VUE_APP_CONN_NODEJS,
      myusers : [],
      mynetwork : [],
      mynetworkAll : [],
      mynetworkAllBanned : [], // quelli che io ho bannato
      mynetworkCH : [],
      mynetworkINFO : [],
      mynetworkChatList : [],
      mynetworkTable : null,
      selectedCompanyId : null,
      selectedGuid : '-1',
      selectedCompanyTable : null,
      MyCompanyId : null,
      usersRoleRoleCompany : 'ooo',
      confirmDeleteNetwork : 'Confermi la cancellazione? / Confirm the cancellation?',
      confirmBanCompany : 'Confermi di volere bannare questa compagnia? / Are you sure you want to ban this company? ',
      confirmUnBanCompany : 'Confermi di NON volere più bannare questa compagnia? / Are you sure you want to NOT ban this company? ',
      newmessage : null,
      previewDataText : '',
      isRequestedVisura : 'no',
      newDocs : null,
      newDocDescriptionSelected : null,
    }
  },
  methods : {
  // TAG Google Analytics rilevamento pagina
// track() {
//     this.$gtag.pageview("/mychats"),
//      this.$gtag.screenview({
//        app_name: 'page_view',
//        screen_name: 'Accesso a pagina chat',
//      })
//    },

    openSelectionFile(){
        this.$refs.mynewdoc.click();
    },
    removeAttachmentsFile(){
        this.newDocs = null;
        this.newDocDescriptionSelected = null;
        this.$refs.mynewdoc.value=null;
    },
    previewFile(){
      this.newDocs = this.$refs.mynewdoc.files[0];
      this.newDocDescriptionSelected = this.$refs.mynewdoc.files[0].name;
      //console.log(this.newDocs);
      if(this.newDocs.size>2594830){
          alert('file troppo grande, massimo 2,5 MB');
          this.newDocs = null;
          this.newDocDescriptionSelected = null;
          this.$refs.mynewdoc.value=null;
      }
    },
    backToDashoboard(){
        router.push('/dashboard');
    },
    gotoagreements(comp,comp1,comp2,guid){

        //alert(comp)
        //console.log(comp+'<>'+comp1+'<>'+comp2);
        let compTo = null;
        if(comp1!=null){ compTo = comp1; }
        if(comp2!=null){ compTo = comp2; }
        this.$store.dispatch('setSetselectAgreementsGuid',guid );
        this.$store.dispatch('setSetselectAgreementsComp1',comp );
        this.$store.dispatch('setSetselectAgreementsComp2',compTo );

        //console.log(this.getselectAgreementsComp2+'XXX');
        router.push('/myagreements');
    },
    gotoagreements2(comp,comp1,comp2,guid){

        //alert(comp)
        //console.log(comp+'<>'+comp1+'<>'+comp2);
        let compTo = null;
        
        if(comp1==comp){ compTo = comp2; }
        if(comp2==comp){ compTo = comp1; }

        this.$store.dispatch('setSetselectAgreementsGuid',guid );
        this.$store.dispatch('setSetselectAgreementsComp1',comp );
        this.$store.dispatch('setSetselectAgreementsComp2',compTo );

        //console.log(this.getselectAgreementsComp2+'XXX');
        router.push('/myagreements');
    },
    refreshaction(){
         
        this.setSelectedCompanyId(this.selectedCompanyId,this.mynetworkTable,this.selectedGuid,null,null);
        this.newmessage = null;

    },

    addToMyNetwork(companyId,companyTable,score,action,emailAddressTo, messageYN){
        const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
        }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/NetworkJoinByMe',
        {
            userId : this.getUserId,
            companyId : companyId,
            companyTable : companyTable,
            score : score,
            actionToDo : action
        },auth)
        .then(res => {
            // in base alla action passata, eseguo delle operazioni: aggiungo alla chat
            
            this.sendEmail(companyId,'companies','ADD','','Qualcuno ti ha aggiunto al proprio network / Someone added you to their network','N');
            if(messageYN=='Y'){
                if(this.lang=='Ita'){ alert('Operazione completata con successo!'); }
                if(this.lang=='Eng'){ alert('Operation completed succesfully!'); }
                if(this.lang=='Den'){ alert('Operation afsluttet med succes!'); }
            }
            

            return res+emailAddressTo;
        })
        .catch(
        error => {
        
        return error;
        }
        );
    },
    businessCardAction(action, companyId, guid, companyTable){

      
      if(action=='addtomyfavorite'){
        // in ogni caso aggiungo ai preferiti, il server controlla se è già stata aggiunta
        this.addToMyNetwork(companyId,companyTable,'0','ADD','','Y');
      }

      if(action=='sendmessage'){
        // in ogni caso aggiungo ai preferiti, il server controlla se è già stata aggiunta
        this.addToMyNetwork(companyId,companyTable,'0','ADD','','N');

        // quest'operazione è possibile solo nelle pagine: area messaggi, network, match making
        router.push('/mychats');
      }

      if(action=='reqvisura'){
        // quest'operazione è possibile solo nelle pagine: area messaggi, network, match making
        this.sendRequestVisura(companyId,guid, companyTable);
      }

      //alert(action+'--'+companyId);
      return action+guid;
    },
    doNothing(){
      return null;
    },
    returnYear(dt){
      if(dt){
       return dt.substring(0,4);
      }else{
        return '';
      }
    },
    setSelectedCompanyIdDetail(companyId,table,addNetwork,sendMessage){

        this.$store.dispatch('setCompanyIdBusinessCard',companyId );
        this.$store.dispatch('setCompanyTableBusinessCard',table );

        let canSendMessage = sendMessage;
        
        //this.$store.dispatch('setCompanyMessageBusinessCard',sendMessage );

        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/isInMyNetwork',
                {
                   companyId : companyId,
                   MyCompanyId: this.MyCompanyId,
                   table : table
                },auth)
                .then(res => {
                    
                    
                    let response = res.data.message;
                    //console.log(response+'kokokokoko');
                    //alert(response);
                    if(response=='OK'){
                        
                        this.$store.dispatch('setCompanyNetworkBusinessCard','N' );
                    }else{
                        
                        this.$store.dispatch('setCompanyNetworkBusinessCard','Y' );
                    }
                    
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
        
        // banned or not 
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/bannedornot',
                {
                    companyIdFrom : this.MyCompanyId,
                    companyIdTo : companyId,
                    companyTable : table,
                   
                },auth)
                .then(res => {
                  //console.log(res);
                   if(res.data.message=='OK'){
                       canSendMessage = 'Y';
                     this.$store.dispatch('setCompanyMessageBusinessCard',canSendMessage );
                   }else{
                       canSendMessage = 'N';
                     this.$store.dispatch('setCompanyMessageBusinessCard',canSendMessage );
                   }
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
        
        /*
        this.selectedCompanyTable = table;
        
        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                {
                   companyId : companyId,
                   action : table,
                   myuserId : this.getUserId
                },auth)
                .then(res => {
                    
                    this.mycompaniesDetails = res.data.company[0];

                    if(table=='companies'){
                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_doc'
                        },auth)
                        .then(res1 => {
                            
                            // docs here
                           
                            if(res1.data.company.length>0)
                            {
                                this.mycompaniesDetailsDocs = res1.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );

                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_countries'
                        },auth)
                        .then(res2 => {
                            
                            // docs here
                            
                            
                            if(res2.data.company.length>0)
                            {
                                this.mycompaniesDetailsCountries = res2.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );

                    }
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
        
        */

    },
    rememberPreviewDataText(dataText){
        this.previewDataText = dataText;
        return '';
    },
    trimData1(dataOra){
        
        if(dataOra==null || dataOra == undefined){
            return '';
        }
        
        let newTextDataOraPre0 = dataOra.split('T');
        let newTextDataOraPre1 = newTextDataOraPre0[0].split('-');
        let newTextDataOra = '';

        newTextDataOra = newTextDataOraPre1[2]+'/'+newTextDataOraPre1[1]+'/'+newTextDataOraPre1[0];
        
        return newTextDataOra;
    },
    trimData(dataOra){
        
        if(dataOra==null || dataOra == undefined){
            return '';
        }
        let newTextDataOraPre0 = dataOra.split('T');
        let newTextDataOraPre1 = newTextDataOraPre0[0].split('-');
        let newTextDataOra = '';

        if(this.lang=='Ita'){
            newTextDataOra = newTextDataOraPre1[2]+'/'+newTextDataOraPre1[1]+'/'+newTextDataOraPre1[0];
        }else{
            newTextDataOra = newTextDataOraPre1[1]+'/'+newTextDataOraPre1[2]+'/'+newTextDataOraPre1[0];
        }
        let oraPre = newTextDataOraPre0[1].substring(0,8);
        newTextDataOra = newTextDataOra + '<br>'+oraPre;
        
        return newTextDataOra;
    },
    trimMessage(msg){
        if(msg.length>15){
            return msg.substring(0, 30) + '...';
        }else{
            return msg;
        }
    },
    checkblockchainsHash(hash){
        if(hash!='' && hash!=null){
            return 'VV';
        }else{
            return '';
        }
      },
    setViewedAllChatsByGuid(){
        const auth = {
            headers: {Authorization:'JWT ' + this.getToken} 
        }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    guid : this.selectedGuid,
                    userId : this.getUserId,
                    actionToDo : 'VIEWED'
                },auth)
                .then(res => {
                    
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
        return '';
    },
    banCompanyConfirm(guid){
        if (confirm(this.confirmBanCompany))
        this.banCompany(guid,'BAN');
    },
    unBanCompanyConfirm(guid){
        if (confirm(this.confirmUnBanCompany))
        this.banCompany(guid,'UNBAN');
    },
    returnDB(db){
        if(db == 'company_infocamere'){ return 'Infocamere'; }
        if(db == 'company_house'){ return 'Company house'; }
        if(db == 'companies'){ return 'GoBiz'; }
    },
    banCompany(guid,action){

        const auth = {
            headers: {Authorization:'JWT ' + this.getToken} 
        }
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    guid : guid,
                    actionToDo : action
                },auth)
                .then(res => {
                    this.getMyNetwork();
                    
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
    },
    renderCompanyProfile(row){
        if(row.companyId1!= null && row.companyId1!='')
        {
            return row.companyName1 + ' ' + row.companyId1;
        }

         if(row.companyId2!= null && row.companyId2!='')
        {
            return row.companyName2 + ' ' + row.companyId2;
        }
    },
    renderCompanyProfileId(row){
        if(row.companyId1!= null && row.companyId1!='')
        {
            return row.companyId1;
        }

         if(row.companyId2!= null && row.companyId2!='')
        {
            return row.companyId2;
        }
    },
    returnViewed(viewed){
        if(viewed=='1'){
            if(this.lang=='Ita'){
                return 'vista';
            }else{
                return 'viewed';
            }
        }else{
            if(this.lang=='Ita'){
                return 'non vista';
            }else{
                return 'not viewed';
            }
        }
    },
    generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        
        return newGuid;
    },
    setBackgroudStyleStatus(userId, firstMessage, companyTable){
        if(userId != this.getUserId){ return 'background-color: #07ff31; width: 50px;'; } // email da leggere

        if(userId == this.getUserId){ 
            if(companyTable!='companies' && firstMessage=='Y'){ return 'background-color: #ffc107; width: 50px;';  } // richiesta pendente verso companies extra GoBiz
            if(companyTable=='companies' && firstMessage=='Y'){ return 'background-color: #07baff; width: 50px;';  } // primo messaggio inviato e in attesa di risposta
            if(companyTable=='companies' && firstMessage=='N'){ return 'background-color: #ff07f7; width: 50px;';  } // messaggio inviato e in attesa di risposta
        }
        
    },
    setBackgroudStyleStatusText(userId, firstMessage, companyTable){
        if(userId != this.getUserId){ 
            if(this.lang=='Ita'){
                return 'Email da leggere'; 
            }else{
                return 'Emai to read'; 
            }
        } // email da leggere

        if(userId == this.getUserId){ 
            if(companyTable!='companies' && firstMessage=='Y'){ 
                if(this.lang=='Ita'){
                    return 'Richiesta pendente'; 
                }else{
                    return 'Pending request'; 
                }
            } // richiesta pendente verso companies extra GoBiz

            if(companyTable=='companies' && firstMessage=='Y'){ 
                if(this.lang=='Ita'){
                    return 'In attesa di risposta'; 
                }else{
                    return 'Waiting for an answer'; 
                }  
            } // primo messaggio inviato e in attesa di risposta

            if(companyTable=='companies' && firstMessage=='N'){ 
                if(this.lang=='Ita'){
                    return 'In attesa di risposta'; 
                }else{
                    return 'Waiting for an answer'; 
                }  
            } // messaggio inviato e in attesa di risposta
        }
        
    },
    printCompanyName(cosa, cName1, cName2){
        
      if(cosa!=''){
          if(cName1!=null && cName1!=''){ return cName1; }
        if(cName2!=null && cName2!=''){ return cName2; }
      }  
        
    },
    backToMyCompanies(){
        this.setSelectedCompanyId(null,null,null,null,null);
        this.selectedCompanyId=null;
        //router.push('/dashboard');
    },
    renderMessageViewed(viewed){
        if(viewed==1){return 'V'; }else{return ''; }
    },
    frontEndDateFormat(date) {
        
        let dateDay = date.split('T');
        let dateHourTemp = dateDay[1].split('Z');
        let dateHour = dateHourTemp[0].split('.');
        let dateEng = dateDay[0];
        let dateItaTemp = dateDay[0].split('-');
        let dateIta = dateItaTemp[2]+'-'+dateItaTemp[1]+'-'+dateItaTemp[0];
        
        if(this.lang=='Ita'){ return dateIta+' '+dateHour[0]; }else{ return dateEng+' '+dateHour[0]; }
        
    },
    checkMessageStyle(message){
        if(message=='Request for an Updated Version of the Company Report' || message=='An updated version of the Company Report has been shared' || message=='Registration accomplished, thanks for the invitation!' || message=='Request to access to the Company Report' || message=='Company Report has been shared' || message=='Company Report has not been shared' ){
            return true;
        }else{
            return false;
        }
    },
    setBackgroudStyle(userIdx,userIdMe,message){
        // colore sfondo chat
        let textColor = '';

        if(this.checkMessageStyle(message)==true){ textColor = 'color:#356fb2;font-weight: bold'; }

        if(userIdMe == userIdx){
            return 'padding: 20px; border-radius: 40px 10px 10px 0px; margin-left:10px; background-color: #c9f1fd; width: 40%; float: left;' + textColor;
        }else{
            return 'padding: 20px; border-radius: 40px 10px 10px 0px; background-color: #f7f6f6; margin-right:10px; width: 40%; float: right;' + textColor;
        }
    },
    setVisuraRedStyle(message){
        // colore sfondo chat
        let textColor = '';
        if(this.checkMessageStyle(message)==true){ textColor = 'color:#356fb2;font-weight: bold'; }
        return textColor;
    },
    updateTranslation(messageTranslated,chatId){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    companyIdFrom : this.getCompanyIdTo,
                    companyIdTo : this.selectedCompanyId,
                    companyTable : this.mynetworkTable,
                    blockchainsHash : '',
                    message : this.newmessage,
                    messageTranslated : messageTranslated,
                    chatId : chatId,
                    viewed: '0',
                    actionToDo : 'MODT'
                },auth)
                .then(res => {
                    this.setSelectedCompanyId(this.selectedCompanyId,this.mynetworkTable, this.selectedGuid,null,null);
                    
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );

            }else{
                return null;
            }
    },
    sendEmail(companyIdTo, companyTable, action,emailAddressTo,newMessage,printMessage){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

        if(companyTable=='companies'){
            
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyAll',
                        {
                            id : companyIdTo,
                            table : companyTable,
                            what : ''
                        },auth)
                        .then(res => {
                            
                            let emailTo = '';
                            res.data.network.forEach(em =>{
                                if(emailTo==''){ emailTo = em.email; }else{
                                    emailTo = emailTo + ',' + em.email;
                                }
                            });
                            
                            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/sendemail',
                            {
                                userId : userId,
                                emailAddressTo : emailTo,
                                emailAddressFrom : this.selectedCompanyEmailFrom,
                                companyFrom : this.selectedCompanyName,
                                message : newMessage,
                                action: action
                            },auth)
                            .then(res => {
                                
                                return res;
                            })
                            .catch(
                                error => {
                                //console.log(error);
                                return error;
                                }
                            );
                        })
                        .catch(
                            error => {
                            
                            return error;
                            }
                        );

                if(printMessage=='Y'){
                    if(this.lang=='Ita'){ alert('Operazione completata con successo!'); }
                    if(this.lang=='Eng'){ alert('Operation completed succesfully!'); }
                    if(this.lang=='Den'){ alert('Operation afsluttet med succes!'); } 
                }
                this.newmessage = null;
        }

    },
    sendRequestVisura(companyIdfromCard, guid, companyTable){
        this.newmessage = 'Request for an Updated Version of the Company Report';
        
        this.sendNewMessageBusinessCard(companyIdfromCard,guid, companyTable);
    },
    sendNewMessageBusinessCard(companyIdfromCard,guid, companyTable){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {
                let firstMessage = 'N';
                if(guid=='' || guid==null){ 
                  firstMessage = 'Y'; 
                  guid = this.generateUUID();
                }
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    companyIdFrom : this.MyCompanyId,
                    companyIdTo : companyIdfromCard,
                    companyTable : companyTable,
                    guid : guid,
                    firstMessage: firstMessage,
                    blockchainsHash : '',
                    message : this.newmessage,
                    messageTranslated : '',
                    toBeNotarized : 'Y',
                    chatId : '',
                    viewed: '0',
                    actionToDo : 'ADD'
                },auth)
                .then(res => {
                    this.sendEmail(companyIdfromCard,companyTable,'SEND','',this.newmessage,'Y');
                    //this.setSelectedCompanyIdDetail(companyIdfromCard,companyTable);
                    this.newmessage = null;

                    let msg = this.searchevents(this.labels,'dialog','bcompanyreportisavailable');
                    alert( msg );
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
                
            }else{
                return null;
            }
    },
    sendNewMessage(){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken, 'content-type': 'multipart/form-data'} 
            }

            if(userId!=null)
            {
                let firstMessage = 'N';
                if(this.mynetworkChatList.length==0){ firstMessage = 'Y'; }
                let getCompanyIdToAll = this.getregisteredCompany;

                let fd = new FormData();
                fd.append('userId',userId);
                fd.append('companyIdFrom',getCompanyIdToAll.id);
                fd.append('companyIdTo',this.selectedCompanyId);
                fd.append('companyTable',this.mynetworkTable);
                fd.append('guid',this.selectedGuid);
                fd.append('firstMessage',firstMessage);
                fd.append('blockchainsHash','');

                fd.append('message',this.newmessage);
                fd.append('messageTranslated','');
                fd.append('toBeNotarized','Y');
                fd.append('chatId','');

                fd.append('viewed','0');
                fd.append('actionToDo','ADD');
                
                fd.append('docs',this.newDocs);
                fd.append('docsName',this.newDocDescriptionSelected);

                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                fd,auth)
                .then(res => {
                    //this.sendEmail(this.selectedCompanyId,this.mynetworkTable,'SEND','',this.newmessage,'N');
                    this.setSelectedCompanyId(this.selectedCompanyId,this.mynetworkTable,this.selectedGuid,null,null);
                    
                    this.newDocs = null;
                    this.newDocDescriptionSelected = null;
                    this.$refs.mynewdoc.value=null;

                    this.newmessage = null;
                    return res;
                })
                .catch(
                    error => {

                    //console.log(error);

                    return error;
                    }
                );

                
            }else{
                return null;
            }
    },
    onClickDeleteNetwork(companyId,table){
      if (confirm(this.confirmDeleteNetwork))
        this.delSelectedCompanyId(companyId,table)
    },
    delSelectedCompanyId(companyId,table){
        
            const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {

                // recupero i dati aziendali
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/NetworkJoinByMe',
                {
                    userId : userId, 
                    companyId : companyId, 
                    companyTable : table,
                    actionToDo : 'DEL'
                },auth)
                .then(res => {
                    this.getMyNetwork();
                
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );

            }else{
                return null;
            }
        
    },
    translateGoogle(text,chatId){

        let texToTranslate = text;
        let langFrom = this.lang;
        let langTo = '';
        let textTranslated = '';
        if(langFrom == 'Ita'){ langFrom = 'auto'; langTo = 'it';}
        if(langFrom == 'Eng'){ langFrom = 'auto'; langTo = 'en'; }
        if(langFrom == 'Den'){ langFrom = 'auto'; langTo = 'da'; }
        gAxios.get('https://translate.googleapis.com/translate_a/single?client=gtx&sl='+langFrom+'&tl='+langTo+'&dt=t&q='+texToTranslate,
                {
                   
                })
                .then(res => {
                    
                    let tempTextTranslated = res.data[0];
                    tempTextTranslated.forEach(element => {
                        textTranslated += element[0]+' ';
                        
                    });
                    
                    // se ritorno correttamente, allora aggiorno la chat
                    this.updateTranslation(textTranslated,chatId);
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },
    translateGoogleNew(text){
 
        let texToTranslate = text;
        let langFrom = this.lang;
        let langTo = '';
        let textTranslated = '';
        
        if(langFrom == 'Ita'){ langFrom = 'it'; langTo = 'en';}
        if(langFrom == 'Eng'){ langFrom = 'en'; langTo = 'en'; }
        if(langFrom == 'Den'){ langFrom = 'da'; langTo = 'en'; }
        gAxios.get('https://translate.googleapis.com/translate_a/single?client=gtx&sl='+langFrom+'&tl='+langTo+'&dt=t&q='+texToTranslate,
                {
                   
                })
                .then(res => {
                    let tempTextTranslated = res.data[0];
                    tempTextTranslated.forEach(element => {
                        textTranslated += element[0]+' ';
                        
                    });
                    
                    this.newmessage = textTranslated;
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },
    setMyNetworkTable(table){
        this.mynetworkTable = table;
    },
    getMyNetwork(){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {
                this.mynetworkAll = [];
                this.mynetwork = [];
                this.mynetworkAllBanned = [];
                
                
                // non lavorati 1
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ1',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetwork.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetwork.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                // non lavorati 2
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ2',
                   userId :userId
                },auth)
                .then(res => {
                   
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetwork.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetwork.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }

                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                // non lavorati 3
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ3',
                   userId :userId
                },auth)
                .then(res => {

                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetwork.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetwork.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
            
                return error;
                }
                );

                
                // bannati from me and to me
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLBANNED1',
                   userId :userId
                },auth)
                .then(res => {

                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAllBanned.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAllBanned.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLBANNED2',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAllBanned.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAllBanned.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL1',
                   isRequestedVisura : this.isRequestedVisura,
                   userId :userId
                },auth)
                .then(res => {
                    //console.log(res);
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAll.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAll.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                   
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
                /*
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL2',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            this.mynetworkAll.push(n);
                        }) 
                    }
                   
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL3',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            this.mynetworkAll.push(n);
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                return error;
                }
                );
                */

            }else{
                return null;
            }
    },
    setSelectedCompanyId(companyId,table,guid,id1,id2){

        this.selectedCompanyId = companyId;
        if(id1!=null && id1!=''){ this.selectedCompanyId = id1; }
        if(id2!=null && id2!=''){ this.selectedCompanyId = id2; }
        
        this.selectedGuid = guid;
        this.setViewedAllChatsByGuid();
        this.setMyNetworkTable(table);
        if(guid == '-1'){
            guid = this.generateUUID();
            this.selectedGuid = guid;
        }


        if(this.selectedCompanyId!=null){
            // chiamo la chat e i dati dell'azienda
            const userId = this.getUserId;
            const newGuid = guid;
            const userIdParent = this.getIdUsersParent;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {

                // recupero i dati aziendali
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyAll',
                {
                    id : this.selectedCompanyId,
                    table : table,
                    what : 'details'
                })
                .then(res => {
                    
                    this.mycompanies = res.data.network[0];
                    
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
                

                // recupero tutte le chat
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/getchats',
                {
                   companyIdTo : this.selectedCompanyId,
                   companyIdFrom : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   userIdParent : userIdParent,
                   guid : newGuid,
                   userId :userId,
                   action: 'ALL'
                },auth)
                .then(res1 => {
                    //console.log(res1)
                    this.mynetworkChatList = res1.data.chats;
                    
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );

            }else{
                return null;
            }
        }else{
            this.getMyNetwork();
        }

    },
    /*
    setSelectedCompanyIdDetail(companyId,table){
        this.selectedCompanyTable = table;
        
        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                {
                   companyId : companyId,
                   action : table
                },auth)
                .then(res => {
                    
                    this.mycompaniesDetails = res.data.company[0];

                    if(table=='companies'){
                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_doc'
                        },auth)
                        .then(res1 => {
                            
                            // docs here
                            
                            if(res1.data.company.length>0)
                            {
                                this.mycompaniesDetailsDocs = res1.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );
                    }
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
        


    },
    */
    modifyCompany(company){
        this.$store.dispatch('SetregisteredCompany', company );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updatecompany');
        
    },
    modifyUsers(user){
        this.$store.dispatch('SetregisteredUser', user );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updateuser');
        
    },
    getM(){
        
    return null;
    },
    searchevents(events,filterValue,label){
    //events = events2;
    if(events){
        
        const filter = event => 
        event.tableName.includes(filterValue) &&
        event.fieldName.includes(label)
    
        var filteredArray = events.filter(filter);
        if(filteredArray.length >0)
        {
        var labelName = 'label'+this.lang;
        return filteredArray[0][labelName];
        }else{
        return '--';
        }
        
    }else{
        return null;
    }
    
    }
        
  },
  computed :{
    
    getUserRole(){
      return this.$store.getters.getUserRole;
    },
    lang(){
      var lng = this.$store.getters.lang;
        if(lng)
        {
          return lng;
        }else{
          return '';
        }
    },
    labels(){
      return this.$store.getters.labels;
    },
    dropM(){
      
      var menu = this.$store.getters.dropM;
      if(menu){
        //this.dropM1 = menu[11];
        return menu;
      }else{
        return null;
      }
      
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
    getUserId(){
        return this.$store.getters.getUserId;
    },
    getIdUsersParent(){
        return this.$store.getters.getIdUsersParent;
    },
    getCompanyIdTo(){
        return this.$store.getters.getCompamyIdTo;
    },
    getselectAgreementsComp1(){
        return this.$store.getters.getselectAgreementsComp1;
    },
    getselectAgreementsComp2(){
        return this.$store.getters.getselectAgreementsComp2;
    },
    getregisteredCompany(){
      var rcomp = this.$store.getters.getregisteredCompany;
      if(rcomp){
        
        return rcomp;
      }else{ 
        return 0;
      }
    },

    
  },
  created() {
     
    
  },
  watch : {
      dropM: function () {
      // potrei anche leggere il nuovo e il vecchio valore (newdropMservLandlinePrefixId,olddropMservLandlinePrefixId)
      return null;
    },
      getM: function () {
          return null;
    }
        
  },
  components: {
    
  },
  mounted: function () {
    
    // DEVE ESSERE STATA SELEZIONATA IN PRECEDENZA
    //this.MyCompanyId = this.getCompanyIdTo;
    var reg = this.$store.getters.getregisteredCompany;
    if(reg!=null){ 
        this.MyCompanyId = reg.id;
        
    }
    
    this.mynetworkAllBanned = [];

    // alla partenza ######################################################################################################################################à
    this.$nextTick(function () {
        
        if(this.$route.params.mm!=null){
            
            this.isRequestedVisura = this.$route.params.mm;
        }else{
            
            this.isRequestedVisura = 'no';
        }

        this.$store.dispatch('setSetselectAgreementsComp1',null );
        this.$store.dispatch('setSetselectAgreementsComp1',null );

        //console.log(this.isRequestedVisura + '<<<<<<');
        // prima di tutto raccolge eventuali parametri passati via post
        if(this.$route.params.idCompany!=null && this.$route.params.idCompany!=undefined){
            this.selectedCompanyId = this.$route.params.idCompany;
            
            this.setSelectedCompanyId(this.selectedCompanyId,this.$route.params.table,this.$route.params.guid,this.$route.params.id1,this.$route.params.id2)
        }

        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {
                
                // non lavorati 1
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ1',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetwork.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetwork.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                // non lavorati 2
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ2',
                   userId :userId
                },auth)
                .then(res => {
                   
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetwork.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetwork.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }

                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                // non lavorati 3
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ3',
                   userId :userId
                },auth)
                .then(res => {

                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetwork.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetwork.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
            
                return error;
                }
                );

                
                // bannati from me and to me
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLBANNED1',
                   userId :userId
                },auth)
                .then(res => {

                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAllBanned.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAllBanned.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLBANNED2',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAllBanned.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAllBanned.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL1',
                   isRequestedVisura : this.isRequestedVisura,
                   userId :userId
                },auth)
                .then(res => {
                    
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAll.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAll.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                   
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
                /*
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL2',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            this.mynetworkAll.push(n);
                        }) 
                    }
                   
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL3',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            this.mynetworkAll.push(n);
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                return error;
                }
                );
                */

            }else{
                return null;
            }
    })
    
}
}

</script>
<style lang="scss">
    .alert-dismissible {
    padding-right: 0rem;
    }

    
.btn .badge {
    /* position: relative; */
    /* top: -15px; */
    /* right: -15px; */
    /* background-color: red;*/
    background-color: green;
    color: white;

}

</style>