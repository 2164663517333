<template>
  <div class="container" style="max-width: 98% !important;">
    
    
    
    <button type="button" class="btn btn-success" style="font-size: .8em;" @click="backToDashoboard()">{{searchevents(this.labels,'menu','backglobal')}} </button>
     <br>
    <!-- <button type="button" class="btn btn-success" style="font-size: .8em;" @click="backTochat()" >{{searchevents(labels,'menu','messagesarea')}}</button> -->
     <br>
    <h1>{{searchevents(this.labels,'agreements','Manageyourcontractswith')}} <span style="color: #0c294b;text-decoration: underline;">{{companyTo.companyName}}</span></h1>
    <br>
    <div class="container tablelist" style="max-width: 95%;">


   <hr>
      <template>        
             <div class="container" style="max-width: 95% !important;">

             
  
              <div class="row" style="width:100%; text-align:center;margin-top: 30px;">
                    <div class="col-sm">
                      </div>
                <div class="col-2" >
                  <button type="button" class="btn btn-dark" data-toggle="modal" data-target="#myModalDownloadContratcs" style="font-size: 1em; margin-top: 3px; min-height:50px;max-width: 260px;" >{{searchevents(this.labels,'agreements','DownloadContractTemplate')}}</button>
                 </div>
                <div class="col-9" style="margin-left: -60px;">
                  <button class="btn" style="color:#0c294b;" @click="createAgreementPre()"><i class="fa fa-plus-circle" aria-hidden="true"></i></button>
                    <span>{{searchevents(labels,'agreements','newcontractcreation')}}</span>
                   </div>
              </div>
                <hr> 
               <div class="row blu-header">
                    <div class="col-sm" style="width:16%; border: solid 1px #ffffff;" >{{searchevents(labels,'agreements','ContractIDAgree')}}</div>
                    <div class="col-sm" style="width:16%; border: solid 1px #ffffff;" >{{searchevents(labels,'agreements','TermsandConditionsAgree')}}</div>
                    <div class="col-sm" style="width:16%; border: solid 1px #ffffff;" >{{searchevents(labels,'agreements','Approvalby')}}<br><span style="color:#e5d237; font-weight:bold;">{{getregisteredCompany.companyName}}</span></div>
                    <div class="col-sm" style="width:16%; border: solid 1px #ffffff;" >{{searchevents(labels,'agreements','Approvalby')}}<br><span style="color:#e5d237; font-weight:bold;">{{companyTo.companyName}}</span></div>
                    <div class="col-sm" style="width:16%; border: solid 1px #ffffff;" >{{searchevents(labels,'agreements','Actions')}}</div>
                    <div class="col-sm" style="width:16%; border: solid 1px #ffffff;" >{{searchevents(labels,'companies','companyNotes')}}</div>
                    <div class="col-sm" style="width:4%; border: solid 1px #ffffff;" ></div>
               </div>

                 <template>
                    <div>
                        <div v-for="(row_p) in agreementsList " class="list-group-item" :key="row_p.id">
                    <div class="row" style="margin-top:4px; background-color: #90c2fd;"  >

                        <!-- prima colonna -->
                        <div style="width:16%; padding-top: 20px; border: solid 1px #ffffff;" class="col-sm"  >{{row_p.id}}</div>
                        
                        <!-- seconda colonna -->
                        <div style="width:16%; padding-top: 20px; border: solid 1px #ffffff;" class="col-sm"  >

                          <button style="margin-right:5px;  border:none; background-color: transparent;" v-if="row_p.termAndConditions!=''"  type="button" data-toggle="button" v-tooltip="{content: row_p.termAndConditionsHash, trigger: 'click', show: isOpen, placement: 'top', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                                <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#0c294b;width: 100%"></span>
                          </button>

                            <!-- se è stato caricato il contratto -->
                          <span v-if="row_p.termAndConditions!=''" style="cursor:pointer; font-weight:bold;" @click.prevent="downloadItem( remoteServer + '/docs/contratti_utenti/' + row_p.termAndConditions,row_p.termAndConditions)" >{{row_p.termAndConditions.substring(0, 15)}}...</span>

                        </div>

                        <!-- terza colonna -->
                        <div style="width:16%; padding-top: 20px; border: solid 1px #ffffff;" class="col-sm"  >

                          <!-- va messo prima di tutto -->
                          <button style="margin-right:5px; border:none; background-color: transparent;" v-if="row_p.approvedTo!='' && row_p.approvedTo!='NOT APPROVED' && row_p.idCompanyFrom == getregisteredCompany.id" type="button" data-toggle="button" v-tooltip="{content: row_p.approvedFromHash, trigger: 'click', show: isOpen, placement: 'top', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                                <span aria-hidden="true" style="font-size:18px;color:#0c294b;width: 100%"></span>
                          </button>
                          <button style="margin-right:5px;  border:none; background-color: transparent;" v-if="row_p.approvedFrom!='' && row_p.idCompanyTo == getregisteredCompany.id "  type="button" data-toggle="button" v-tooltip="{content: row_p.approvedToHash, trigger: 'click', show: isOpen, placement: 'top', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                                <span aria-hidden="true" style="font-size:18px;color:#0c294b;width: 100%"></span>
                          </button>
                          <!-- ------- -->

                          <span style="cursor:pointer; font-weight:bold;"  >
                            <span v-if="row_p.approvedFrom!='' && row_p.idCompanyFrom == getregisteredCompany.id " style="cursor:pointer; font-weight:bold;" @click.prevent="downloadItem( remoteServer + '/docs/contratti_utenti/' + row_p.approvedFrom,row_p.approvedFrom)" >{{row_p.approvedFrom.substring(0, 15)}}...</span>

                            <span v-if="row_p.termAndConditions!='' && row_p.approvedFrom!='' && row_p.approvedTo=='' && row_p.idCompanyTo == getregisteredCompany.id ">{{searchevents(labels,'agreements','NotApproved')}}</span>
                            <span v-if="row_p.approvedTo!='' && row_p.approvedTo!='NOT APPROVED' && row_p.approvedTo!='' && row_p.idCompanyTo == getregisteredCompany.id" @click.prevent="downloadItem( remoteServer + '/docs/contratti_utenti/' + row_p.approvedTo,row_p.approvedTo)">{{row_p.approvedTo.substring(0, 15)}}... </span>

                            <span v-if="row_p.approvedTo=='NOT APPROVED' && row_p.idCompanyTo == getregisteredCompany.id">{{searchevents(labels,'agreements','NotApproved')}}</span>
                          </span>
                        </div>

                        <!-- quarta colonna -->
                        <div style="width:16%; padding-top: 20px; border: solid 1px #ffffff;" class="col-sm"  >
                          
                          <!-- va messo prima di tutto -->
                          <button style="margin-right:5px; border:none; background-color: transparent;" v-if="row_p.approvedTo!='' && row_p.approvedTo!='NOT APPROVED' && row_p.idCompanyFrom == getregisteredCompany.id" type="button" data-toggle="button" v-tooltip="{content: row_p.approvedToHash, trigger: 'click', show: isOpen, placement: 'top', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                                <span aria-hidden="true" style="font-size:18px;color:#0c294b;width: 100%"></span>
                          </button>
                          <button style="margin-right:5px;  border:none; background-color: transparent;" v-if="row_p.approvedFrom!='' && row_p.idCompanyTo == getregisteredCompany.id "  type="button" data-toggle="button" v-tooltip="{content: row_p.approvedFromHash, trigger: 'click', show: isOpen, placement: 'top', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                                <span aria-hidden="true" style="font-size:18px;color:#0c294b;width: 100%"></span>
                          </button>
                          <!-- ------- -->

                          <span style="cursor:pointer; font-weight:bold;"  >
                            <span v-if="row_p.termAndConditions!='' && row_p.approvedFrom!='' && row_p.approvedTo=='' && row_p.idCompanyFrom == getregisteredCompany.id ">{{searchevents(labels,'agreements','NotApproved')}}</span>
                            <span v-if="row_p.approvedTo!='' && row_p.approvedTo!='NOT APPROVED' && row_p.idCompanyFrom == getregisteredCompany.id" @click.prevent="downloadItem( remoteServer + '/docs/contratti_utenti/' + row_p.approvedTo,row_p.approvedTo)">{{row_p.approvedTo.substring(0, 15)}}... </span>
                          
                            <span v-if="row_p.approvedFrom!='' && row_p.idCompanyTo == getregisteredCompany.id " style="cursor:pointer; font-weight:bold;" @click.prevent="downloadItem( remoteServer + '/docs/contratti_utenti/' + row_p.approvedFrom,row_p.approvedFrom)" >{{row_p.approvedFrom.substring(0, 15)}}...</span>

                            <span v-if="row_p.approvedTo=='NOT APPROVED' && row_p.idCompanyFrom == getregisteredCompany.id">{{searchevents(labels,'agreements','NotApproved')}}</span>
                          </span>
                        </div>

                        <!-- quinta colonna -->
                        <div style="width:16%; padding-top: 20px; border: solid 1px #ffffff;" class="col-sm"  >
                          <button v-if="row_p.termAndConditions==''" @click="currentidAgreements = row_p.id" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" >{{searchevents(labels,'agreements','UploadDraftContract')}} </button>
                          <button v-if="row_p.termAndConditions!='' && row_p.approvedFrom==''" @click="currentidAgreements = row_p.id" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#firstSignModal" style="font-size: .9em;" >{{searchevents(labels,'agreements','yourSign')}} </button>
                          
                          <button v-if="row_p.termAndConditions!='' && row_p.approvedFrom!='' && row_p.approvedTo=='' && row_p.idCompanyFrom == getregisteredCompany.id && (row_p.approvedTo!='' || row_p.approvedTo!=null)" @click="drawProposalPre(row_p.id,row_p.approvedFrom)" type="button" class="btn btn-pdf" style="font-size: .9em;" >{{searchevents(labels,'agreements','WithdrawProposal')}} </button>

                          <button v-if="row_p.termAndConditions!='' && row_p.approvedFrom!='' && row_p.approvedTo=='' && row_p.idCompanyTo == getregisteredCompany.id && (row_p.approvedTo!='' || row_p.approvedTo!=null) " @click="currentidAgreements = row_p.id; rejectedAction=null; approvalAction=null; " data-toggle="modal" data-target="#secondSignModal" type="button" class="btn btn-pdf" style="font-size: .9em;" >{{searchevents(labels,'agreements','SignorReject')}} </button>

                          <span v-if="row_p.approvedTo=='NOT APPROVED'">{{searchevents(labels,'agreements','NotApproved')}}</span>
                          <span v-if="row_p.approvedTo!='' && row_p.approvedTo!='NOT APPROVED' && row_p.approvedToHash!=''"><strong>{{searchevents(labels,'agreements','ContractSignedExecuted')}}</strong></span>
                        </div>

                        <!-- sesta colonna -->
                        <div style="width:16%; padding-top: 20px; border: solid 1px #ffffff;" class="col-sm"  >{{row_p.notes}}</div>

                        <!-- settima colonna -->
                        <div style="width:4%; border: solid 1px #ffffff; min-height:63px;" class="col-sm"  >
                          <button v-if="row_p.approvedTo=='' && row_p.approvedFrom=='' " class="btn" style="color:#0c294b;" @click="deleteAgreementPre(row_p.id)"><i class="fa fa-trash" aria-hidden="true" style="font-size: 30px; color: red;"></i></button>
                        </div>

                    </div>
                    </div>
                    </div>
                </template>
                <br>  
                <hr>
                <br>                  
               <div  class="row" style="width:100%; text-align:center;margin-bottom: 40px;">
                <div class="col-sm">
                  <span><i class="fa fa-info-circle" aria-hidden="true" style="font-size:24px;"></i></span>
                  <br>
                  <span  v-html="searchevents(this.labels,'agreements','newagreementtips')"></span>
                  <br>
                  <p  v-html="searchevents(this.labels,'agreements','newlink')"></p>
                </div>
                </div>
                <!-- <div v-if="this.officeRegion==1"> -->
                <hr>
                <br> 
                <div class="row" style="width:100%; text-align:center;margin-bottom: 40px;">
                <div class="col-sm" v-if="lang=='Ita'">
                  <span><i class="fa fa-info-circle" aria-hidden="true" style="font-size:24px;"></i></span>
                  <br>
                  <span v-html="searchevents(this.labels,'agreements','agreementtips')"></span>
                  <br>
                  <p v-html="searchevents(this.labels,'agreements','sacelink')"></p>
                </div>
                </div>
                <!-- </div> -->

             </div>
          

      </template>



    </div>

    <!-- MODALS --------------------------------------------------------------------- -->
    <div class="modal" id="myModalDownloadContratcs">
        <div class="modal-dialog" style="max-width: 40% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <!-- <h4 class="modal-title">Email LR</h4> -->
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              
              
              <div class="row">
                <div class="col-sm" style="text-align: -webkit-center;">
                <template>
                    <div>
                        <div v-for="(row) in contractTemplates " class="list-group-item" :key="row.id">
                        <div class="row" style="margin-bottom:20px; width:450px;"  >
                            <div style="text-align:left;" class="col-sm" v-if="lang=='Ita'" >{{row.labelIta}}</div>
                            <div style="text-align:left;" class="col-sm" v-if="lang=='Eng'" >{{row.labelEng}}</div>
                            <div style="text-align:left;" class="col-sm" v-if="lang=='Den'" >{{row.labelDen}}</div>

                            <div class="col-sm" >
                              <button @click.prevent="downloadItem( remoteServer + '/servicefiles/' + row.fileName,row.fileName)" >{{searchevents(labels,'menu','selecttoconfirm')}}</button>
                            </div>
                        </div>
                         </div>
                          </div>
                    </template>


                </div>
              </div>
              
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-success" data-dismiss="modal" style="font-size: 1em;" >{{searchevents(this.labels,'menu','closeglobal')}}</button>
            </div>
            
          </div>
        </div>
      </div>

      <div class="modal" id="docsModal">
        <div class="modal-dialog" style="max-width: 40% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <!-- <h4 class="modal-title">{{this.docDescriptionSelected}}</h4> -->
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              <form @submit.prevent="onSubmitNewDoc" >
                
                  <div class="col-sm" style="margin-top:20px;">
                    <template >
                      
                      <input type="file" @change="this.previewFile" ref="mynewdoc" class="form-control-file" id="newDocs" accept=".pdf">
                    </template>
                  </div>
              </form>
              
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-dark" data-dismiss="modal" @click="onSubmitNewDoc()">{{searchevents(this.labels,'agreements','uploadFileContract')}}</button>
            </div>
            
          </div>
        </div>
      </div> 


      <div class="modal" id="secondSignModal">
        <div class="modal-dialog" style="max-width: 40% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <!-- <h4 class="modal-title">{{this.docDescriptionSelected}}</h4> -->
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              <form @submit.prevent="onSubmitNewDoc" >
                
                  <div class="col-sm" style="margin-top:20px;">
                    <template >
                      <h2 v-if="rejectedAction==null && approvalAction==null">{{searchevents(this.labels,'agreements','Makeyourchoice')}}</h2>
                      <h2 v-if="rejectedAction==null && approvalAction!=null">{{searchevents(this.labels,'agreements','yourSign')}}</h2>

                      <p v-if="rejectedAction==null && approvalAction==null" v-html="searchevents(this.labels,'agreements','proposalisbindingandbyclicking')"></p>
                      <p v-if="rejectedAction==null && approvalAction!=null" v-html="searchevents(this.labels,'agreements','approvalconfirmation')"></p>
                      
                      <h2 v-if="rejectedAction!=null">{{searchevents(this.labels,'agreements','Reasonforrefusal')}}</h2>
                      <textarea rows="5" style="width: 100%;" v-if="rejectedAction!=null" v-model="rejectedText"></textarea>
                    </template>
                  </div>
              </form>
              
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button v-if="rejectedAction==null && approvalAction==null" type="button" class="btn btn-dark" @click="approvalAction='1'">{{searchevents(this.labels,'agreements','yourSign')}}</button>
              <button v-if="rejectedAction==null && approvalAction!=null" type="button" class="btn btn-dark" @click="onSubmitSecondtSign()">{{searchevents(this.labels,'agreements','yourSign')}}</button>

              <button v-if="rejectedAction==null && approvalAction==null" type="button" class="btn btn-dark" @click="rejectedAction='1'">{{searchevents(this.labels,'agreements','SignReject')}}</button>
              <button v-if="rejectedAction!=null && approvalAction==null" type="button" class="btn btn-dark" @click="onSubmitSecondtReject()">{{searchevents(this.labels,'agreements','SignReject')}}</button>

              <button ref="dismisssecondSignModal" type="button" class="btn btn-dark" data-dismiss="modal" style="display:none;" ></button>
            </div>
            
          </div>
        </div>
      </div>  

      <div class="modal" id="firstSignModal">
        <div class="modal-dialog" style="max-width: 40% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <!-- <h4 class="modal-title">{{this.docDescriptionSelected}}</h4> -->
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              <form @submit.prevent="onSubmitNewDoc" >
                
                  <div class="col-sm" style="margin-top:20px;">
                    <template >
                      <p v-html="searchevents(this.labels,'agreements','proposalisbindingforthesender')"></p>
                      
                    </template>
                  </div>
              </form>
              
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-dark" data-dismiss="modal" @click="onSubmitFirstSign()">{{searchevents(this.labels,'agreements','yourSign')}}</button>
            </div>
            
          </div>
        </div>
      </div>    

    <!-- <a class="nav-link" href="#" @click="getM()" >carica9</a> -->
  </div>
</template>

<script>

import router from '@/router';
import gAxios from 'axios';


export default {

  name: 'myagreements',
  data () {
    return {
      remoteServer : process.env.VUE_APP_CONN_NODEJS,
      companyTo : [],
      userAdminTo : [],
      userAdminFrom : [],
      contractTemplates : [],
      agreementsList : [],
      newDocs : null,
      currentidAgreements : null,
      selectedGuid : null,
      rejectedText : '',
      rejectedAction : null,
      approvalAction : null
    }
  },
  methods : {
    
    onSubmitSecondtSign(){
      //console.log('secondo step!!!!!')
      this.$refs.dismisssecondSignModal.click();
      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
     
          gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/agreements/signStepTwo',
              {
              nameCompanyFrom : this.getregisteredCompany.companyName,
              nameCompanyTo  : this.companyTo.companyName,
              idAgreements : this.currentidAgreements,
              userAdminFrom : this.userAdminFrom[0].name + ' ' + this.userAdminFrom[0].surname,
              userAdminTo : this.userAdminTo[0].name + ' ' + this.userAdminTo[0].surname,
              idCompanyFrom : this.getregisteredCompany.id,
              idCompanyTo  : this.companyTo.id,
              
              pecCompanyFrom : this.getregisteredCompany.pec,
              emailCompanyFrom : this.getregisteredCompany.emailAddress,
              pecCompanyTo : this.companyTo.pec,
              emailCompanyTo : this.companyTo.emailAddress,

              emailUserAdminFrom : this.userAdminFrom[0].email,
              emailUserAdminTo : this.userAdminTo[0].email,

              idUserAdminFrom : this.userAdminFrom[0].id,
              idUserAdminTo : this.userAdminTo[0].id,

              guid : this.getselectAgreementsGuid,

              
          },auth)
          .then(res => {
              //console.log(res);

              this.currentidAgreements = null;
    
              this.refreshAgreementsList();

              let alertText = this.searchevents(this.labels,'agreements','Congratsonyourdeal');
              alert(alertText);
              return res;

          })
          .catch(
          error => {
          
          return error;
          }
          );
    },
    onSubmitSecondtReject(){
      if(this.rejectedText==''){
        let alertText = this.searchevents(this.labels,'agreements','rejectedTextrequired');
        alert(alertText);
        //console.log(this.currentidAgreements)
        return false;
      }else{
        this.$refs.dismisssecondSignModal.click();

        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/agreements/rejectAgreement',
          {
              idAgreements : this.currentidAgreements,
              idUserAdminFrom : this.userAdminFrom[0].id,
              idUserAdminTo : this.userAdminTo[0].id,
              idCompanyFrom : this.getregisteredCompany.id,
              idCompanyTo  : this.companyTo.id,
              guid : this.getselectAgreementsGuid,
              rejectedText : this.rejectedText
              
          },auth)
          .then(res => {
              //console.log(res);
              
              this.refreshAgreementsList();
              return res;
          })
          .catch(
          error => {
          
          return error;
          }
          );

      }
    },
    drawProposalPre(id,approvedFrom){
      let result = this.searchevents(this.labels,'agreements','confirmwithdrawn');
       if (confirm(result)){
            this.drawProposal(id,approvedFrom);
          }
    },
    drawProposal(id,approvedFrom){
      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
//console.log('------------------------' + approvedFrom);
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/agreements/drawAgreement',
        {
            id : id,
            idUserAdminFrom : this.userAdminFrom[0].id,
            idUserAdminTo : this.userAdminTo[0].id,
            idCompanyFrom : this.getregisteredCompany.id,
            idCompanyTo  : this.companyTo.id,
            guid : this.getselectAgreementsGuid,
            approvedFrom : approvedFrom
            
        },auth)
        .then(res => {
            //console.log(res);
            
            this.refreshAgreementsList();
            return res;

        })
        .catch(
        error => {
        //console.log(error);
        return error;
        }
        );
    },
    onSubmitFirstSign(){
      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
     
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/agreements/signStepOne',
                {
                    nameCompanyFrom : this.getregisteredCompany.companyName,
                    nameCompanyTo  : this.companyTo.companyName,
                    idAgreements : this.currentidAgreements,
                    userAdminFrom : this.userAdminFrom[0].name + ' ' + this.userAdminFrom[0].surname,
                    userAdminTo : this.userAdminTo[0].name + ' ' + this.userAdminTo[0].surname,
                    idCompanyFrom : this.getregisteredCompany.id,
                    idCompanyTo  : this.companyTo.id,
                    
                    pecCompanyFrom : this.getregisteredCompany.pec,
                    emailCompanyFrom : this.getregisteredCompany.emailAddress,
                    pecCompanyTo : this.companyTo.pec,
                    emailCompanyTo : this.companyTo.emailAddress,

                    emailUserAdminFrom : this.userAdminFrom[0].email,
                    emailUserAdminTo : this.userAdminTo[0].email,

                    idUserAdminFrom : this.userAdminFrom[0].id,
                    idUserAdminTo : this.userAdminTo[0].id,

                    guid : this.getselectAgreementsGuid,

                    
                },auth)
                .then(res => {
                    //console.log(res);

                    this.currentidAgreements = null;
          
                    this.refreshAgreementsList();
                    return res;
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },
    onSubmitNewDoc(){
      
       let idCompanyFrom = this.getregisteredCompany.id;
       let idCompanyTo  = this.companyTo.id;
       let fileName = this.newDocs;
       let idAgreements = this.currentidAgreements;
       let termAndConditionsLang = '';

       //console.log(fileName)

       /*
       if(fileName!=null){
         //console.log(fileName.name)
         let trovato = 0;
         let fileNameName = fileName.name;
         if(fileNameName.includes('IT.pdf')){ trovato=1; termAndConditionsLang = 'IT'; }
         if(fileNameName.includes('EN.pdf')){ trovato=1; termAndConditionsLang = 'EN'; }
         if(fileNameName.includes('IT_EN.pdf')){ trovato=1; termAndConditionsLang = 'ITEN'; }

         if(trovato==0){
           alert('Sembra che il tuo file non sia coerente. Scarica uno dei nostri template.')
           return false;
         }
         
       }
       */

       const auth = {
        headers: {Authorization:'JWT ' + this.getToken, 'content-type': 'multipart/form-data' }
      }

      let fd = new FormData();
      fd.append('docs',fileName);
      fd.append('idCompanyFrom',idCompanyFrom);
      fd.append('idCompanyTo',idCompanyTo);
      fd.append('idAgreements',idAgreements);
      fd.append('termAndConditionsLang',termAndConditionsLang);
      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/agreements/loadcontract',
        fd,auth)
        .then(res => {

          //console.log('risposta comunque corretta: ' + res);

          this.newDocs = null;
          this.currentidAgreements = null;
          
          this.refreshAgreementsList();

          return res;
          //router.push('/dashboard');
        })
        .catch(
          error => {
          //console.log('sono in errore!!!!');
          // faccio qualcosa qui

            let alertText = this.searchevents(this.labels,'agreements','DisclaimerContractTemplate');
            alert(alertText);

            return error;
          }
          );

    },
    previewFile(){
      this.newDocs = this.$refs.mynewdoc.files[0];
      
    },
    refreshAgreementsList(){
      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/agreements/agreementlist',
                {
                    idCompanyFrom : this.getregisteredCompany.id,
                    idCompanyTo  : this.companyTo.id
                    
                },auth)
                .then(res => {
                    //console.log(res);
                    this.agreementsList = res.data.agreements;
                    return res;
                })
                .catch(
                error => {
                
                return error;
                }
                );

    },
    createAgreementPre(){
      let result = this.searchevents(this.labels,'agreements','confirmcreationdrawn');
       if (confirm(result)){
            this.createAgreement();
          }
        
    },
    createAgreement(){

      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
      
      /*
      console.log('new agreements - comp to')
      console.log(this.companyTo)
      console.log('com from')
      console.log(this.getregisteredCompany)
      console.log('admin from')
      console.log(this.userAdminFrom[0])
      console.log('admin to')
      console.log(this.userAdminTo[0])
      */

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/agreements/createAgreement',
        {
            idCompanyFrom : this.getregisteredCompany.id,
            idCompanyTo  : this.companyTo.id,
            companyFrom : this.getregisteredCompany.companyName,
            companyTo : this.companyTo.companyName,
            adminFrom : this.userAdminFrom[0].name+' '+this.userAdminFrom[0].surname,
            adminTo : this.userAdminTo[0].name+' '+this.userAdminTo[0].surname
            
        },auth)
        .then(res => {
            //console.log(res);
            this.refreshAgreementsList();
            
            return res;
        })
        .catch(
        error => {
        
        return error;
        }
        );
    },
    deleteAgreementPre(id){
      let result = this.searchevents(this.labels,'agreements','confirmdeletedrawn');
       if (confirm(result)){
            this.deleteAgreement(id);
          }
        
    },
    deleteAgreement(id){

      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/agreements/deleteAgreement',
        {
            id : id,
            
        },auth)
        .then(res => {
            //console.log(res);
            
            this.refreshAgreementsList();
            return res;
        })
        .catch(
        error => {
        
        return error;
        }
        );
    },
    downloadItem ( url, label ) {
      //console.log(url + '--------' + label);
      gAxios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(
          //console.error
          )
    },
    backToDashoboard(){
        router.push('/dashboard');
    },
    setMyCompanyIdTo(companyIdTo,action){
        this.$store.dispatch('setcompanyIdTo', companyIdTo );
        if(action=='NET'){
            router.push('/mynetwork');
        }

        if(action=='CONT'){
            router.push('/mycontacts');
        }
        
    },
    goTo(where){
        window.location.href = where;
    },
    modifyCompany(company){
        this.$store.dispatch('SetregisteredCompany', company );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updatecompany');
      
    },
    getM(){
        
    return null;
    },
    searchevents(events,filterValue,label){
    //events = events2;
      if(events){
        
        const filter = event => 
          event.tableName.includes(filterValue) &&
          event.fieldName.includes(label)
      
        var filteredArray = events.filter(filter);
        if(filteredArray.length >0)
        {
          var labelName = 'label'+this.lang;
          return filteredArray[0][labelName];
        }else{
          return '--';
        }
        
      }else{
        return null;
      }
      
    }
        
  },
  computed :{
    getregisteredCompany(){
      var rcomp = this.$store.getters.getregisteredCompany;
      if(rcomp){
        
        return rcomp;
      }else{ 
        return 0;
      }
    },
    lang(){
      var lng = this.$store.getters.lang;
        if(lng)
        {
          return lng;
        }else{
          return '';
        }
    },
    labels(){
      return this.$store.getters.labels;
    },
    dropM(){
      
      var menu = this.$store.getters.dropM;
      if(menu){
        //this.dropM1 = menu[11];
        return menu;
      }else{
        return null;
      }
      
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
    getUserId(){
        return this.$store.getters.getUserId;
    },
    getCompanyIdTo(){
        return this.$store.getters.getCompamyIdTo;
    },
    getselectAgreementsGuid(){
        return this.$store.getters.getselectAgreementsGuid;
    },
    getselectAgreementsComp1(){
        return this.$store.getters.getselectAgreementsComp1;
    },
    getselectAgreementsComp2(){
        return this.$store.getters.getselectAgreementsComp2;
    },
  },
  created() {
      
    
  },
  watch : {
      dropM: function () {
      // potrei anche leggere il nuovo e il vecchio valore (newdropMservLandlinePrefixId,olddropMservLandlinePrefixId)
      return null;
    },
      getM: function () {
          return null;
      }
  },
  components: {
    
  },
  mounted: function () {
      
     
    
    this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been rendered


            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompany',
            {
                id : this.getselectAgreementsComp2
                
            },auth)
            .then(res => {
              
                this.companyTo = res.data.company;

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/userLRByCompany',
                {
                    companyId : this.companyTo.id
                    
                },auth)
                .then(res => {
                    //console.log(res);
                    this.userAdminTo = res.data.users;
                    return res;
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/agreements/agreementlist',
                {
                    idCompanyFrom : this.getregisteredCompany.id,
                    idCompanyTo  : this.companyTo.id
                    
                },auth)
                .then(res => {
                    //console.log(res);
                    this.agreementsList = res.data.agreements;
                    return res;
                })
                .catch(
                error => {
                
                return error;
                }
                );



            })
            .catch(
            error => {
            
            return error;
            }
            );

            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/userLRByCompany',
            {
                companyId : this.getregisteredCompany.id
                
            },auth)
            .then(res => {
                //console.log(res);
                this.userAdminFrom = res.data.users;
            })
            .catch(
            error => {
            
            return error;
            }
            );

            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/agreements/agreementsdoc',
            {
                doctype : 'contracttemplate'
                
            },auth)
            .then(res => {
                //console.log(res);
                this.contractTemplates = res.data.docs;
            })
            .catch(
            error => {
            
            return error;
            }
            );

            

            

        
    })
    
}
}

</script>

<style lang="scss">

    .butt{
        width:300px; 
        display: initial; 
        background-color: antiquewhite;
    }
    .alert-dismissible {
    padding-right: 10rem;
    }
</style>
