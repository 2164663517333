<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/gobiz-logo-rectangle-blue.png" width="250em">
     <h4></h4>
      <hr>

       <div class="green-box"> 
        <router-link style="color:white;" v-bind:to="'lovegreen'">Yes, We love green</router-link>
        </div>
    <div v-if="this.lang=='Ita'" v-html="this.titlePage"></div>
    <div v-if="this.lang=='Eng'" v-html="this.titlePageEng"></div>
    <div v-if="this.lang=='Den'" v-html="this.titlePageDen"></div>
<!-- <body>  -->

  <div class="alert alert-light alert-dismissible fade show" style="margin-left: 12%; margin-right: 12%; height: fill-available" >
               <div class="row">
                <div class="col-sm">
                  <div class="card bg-light mt-6">
                     <div class="left"> 
                       <br>
                      <h4 v-if="this.lang=='Ita'">  <i class="fas fa-fw fa-info-circle  mr-2 align-self-center"></i>Le nostre attività sono sospese.</h4>
                      <h4 v-if="this.lang=='Den'">  <i class="fas fa-fw fa-info-circle  mr-2 align-self-center"></i>Vores aktiviteter er suspenderet.</h4>
                      <h4 v-if="this.lang=='Eng'">  <i class="fas fa-fw fa-info-circle  mr-2 align-self-center"></i>Our activities are suspended.</h4>
                       </div>
                       
                       <div class="card-body">
                        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        <hr>
                        <h5 v-if="this.lang=='Ita'">Per maggiori informazioni vi invitiamo a contattarci all'indirizzo  <a href="mailto:admin@gobiz.be" target="_blank" rel="noopener">admin@gobiz.be</a></h5>
                        <h5 v-if="this.lang=='Den'">For yderligere information, kontakt os venligst på følgende adresse: <a href="mailto:admin@gobiz.be" target="_blank" rel="noopener">admin@gobiz.be</a></h5>
                        <h5 v-if="this.lang=='Eng'">For further information please contact us at <a href="mailto:admin@gobiz.be" target="_blank" rel="noopener">admin@gobiz.be</a></h5>
                        <p class="mb-0"></p>
                     <hr>     
                 </div> 
                </div>
              </div>
           </div>
        </div> 
  
<!-- inizio pagina in italiano -->
<h4 v-if="this.lang=='Ita'">
 <div class="d-none d-xl-block">
 <section id="imgback">
  <div class="inner">
  
  <!--  box e immagini Visible only on xl -->
  <div class="boxtesto1">
	<span class="testo_blu">La nuova piattaforma per la ricerca di partner e fornitori:</span><br>
	<br>
	<span class="testo_bianco">sicura, semplice, economica</span>
		<br> 
     <br>
           <!-- ex html <a class="btn btn-success btnjoin" style="margin: 0 auto; width:95%; display: block;font-weight:bold;font-size: 1.6rem;" href="./register" role="button">Registrati ora!</a>  -->
  
           <div class="btn btn-success btnjoin" style="margin: 0 auto; width:95%; display: block;font-weight:bold;font-size: 1.6rem;"> 
           <router-link style="color:white;" v-bind:to="'register'">Registrati ora!</router-link>
           </div>   
           
  </div>
  <div class="boxtesto2">
	<span class="testo_giallo_small">Espandi il tuo Business all'estero supportato dalla nostra soluzione digitale progettata per facilitare la ricerca di partner di business certificati e attivare accordi commerciali. 

	</span><br><br>
  <span class="testo_bianco_small"> Evita gli intermediari tradizionali e supera le barriere legali, culturali e linguistiche.
	</span>
<br>
<br>
 </div>
    <h1></h1>
    <p></p>
  </div>

</section>
</div>

     
      <!--  box e immagini Visible only on md -->
      <div class="d-none d-md-block d-lg-block d-xl-none">
        <section id="imgback">
        <div class="inner">
          <div class="boxtesto_md4">
              <br>

              <span class="testo_blu_md">La nuova piattaforma per la ricerca di partner e fornitori.</span><br>
              <br>
              <span class="testo_bianco_md">Sicura, Semplice, Economica </span>

              <br>
              <br>
         
              <!-- ex html <a class="btn btn-success btnjoin" style="margin: 0 auto; width:95%; display: block;font-weight:bold;font-size: 1.3rem;" href="./register" role="button">Registrati ora!</a> -->
                    <div class="btn btn-success btnjoin" style="margin: 0 auto; width:95%; display: block;font-weight:bold;font-size: 1.3rem;"> 
                    <router-link style="color:white;" v-bind:to="'register'">Registrati ora!</router-link>
                   </div>        
          </div>
            
            <div class="boxtesto_md5">

              <span class="testo_giallo_mdxs">Espandi il tuo Business all'estero supportato dalla nostra soluzione digitale progettata per facilitare la ricerca di partner di business certificati e attivare accordi commerciali. 

                 </span><br><br>
                <span class="testo_bianco_mdxs">Evita gli intermediari tradizionali e supera le barriere legali – culturali e linguistiche.
                </span>
                 <br>
                <br>
            </div> <!-- --------- -->
     
          </div>
        <h1></h1>
        <p></p>
       </section>
      
      <!-- fine box e immagini Visible only on md and lg -->
           
    </div>

 

      <!-- box e immagini  Visible only on lower than md -->   
	<div class="d-md-none">
    <section id="imgback">
        <div class="inner">
          <div class="boxtesto_xs" >
            <br>

                <span class="testo_blu_xs">La nuova piattaforma per la ricerca dei partner e fornitori:</span><br>

                <br>
                <span class="testo_bianco_xs">sicura, semplice, economica  </span>
              <br> 
            <br>

                  <!-- ex html <a class="btn btn-success btnjoin" style="margin: 0 auto; width:95%; display: block;font-weight:bold;font-size: 0.9rem;" href="./register" role="button">Registrati ora!</a>  -->
                  <div class="btn btn-success btnjoin" style="margin: 0 auto; width:95%; display: block;font-weight:bold;font-size: 0.9rem;"> 
                    <router-link style="color:white;" v-bind:to="'register'">Registrati ora!</router-link>
                  </div>  
            
          </div>
            
          <div class="boxtesto_xs2">

                <span class="testo_giallo_xxs">Espandi il tuo Business all'estero supportato dalla nostra soluzione digitale progettata per facilitare la ricerca di partner di business certificati e attivare accordi commerciali. 

                </span>
            <br>
                  <span class="testo_bianco_xxs">Evita gli intermediari tradizionali e supera le barriere legali – culturali e linguistiche.
                  </span>
                <br>
              <br>
                  </div> 
          </div>
        <h1></h1>
        <p></p>
       </section>
      </div>
     
     
     <!-- fine parte variabile in base a risoluzione-->
     
<div id="intro"> 
<br>

<!-- <h1 style="text-align: center; margin-right: 5%; margin-left: 5%;" > No payment needed, search companies and publish your profile for free</h1> -->
<!-- <h1 style="text-align: center; margin-right: 5%; margin-left: 5%; font-size: 1.2em" >Complete registration required; No fees or payments needed.</h1> -->
<br>
<hr>
<hr>
<h1 style=" text-align: center;  float:center; margin-right: 15%; margin-left: 15%;" >La registrazione, la tua vetrina azienda, la ricerca partner e le interazioni sono gratuite!</h1>
<h1 style="text-align: center; margin-right: 10%; margin-left: 10%;font-size: 0.95em;"> Nessun canone o pagamento minimo richiesto per queste funzionalità.</h1>

<!--<h1 style="text-align: center; margin-right: 5%; margin-left: 5%;"> ricerca imprese e crea il tuo profilo gratis</h1>  -->
<br>
<br>
<h1><span class = "underline" style="text-align: center; margin-right: 10%; margin-left: 10%;text-decoration: underline red;">Scopri i nuovi servizi:</span></h1>
<h1><span class = "underlinenotfixed" style="text-align: center; margin-right: 10%; margin-left: 10%;font-size: 0.6em;">Ricerca imprese assistita e primo meeting;<br>Verifica impresa</span></h1>


            <div class="btn btn-success btnjoin" style="margin: 0 auto; width:26%; display: block;font-weight:bold;font-size: 1.1rem;"> 
            <router-link style="color:white;" v-bind:to="'overview'">Clicca qui per la lista completa dei servizi</router-link>
            </div>


<!-- <br>Consulenza su tematiche Import/export. -->
<hr>
<hr>
<span class="testo_centrale"><h1>I nostri servizi sono progettati per eliminare tutte le barriere, risparmiare tempo e costi ed espandere il tuo Business.</h1></span>

<span class="testo_centrale_small">
<h2>
GoBiz usa le tecnologie più innovative per garantire un accesso sicuro, veloce ed economico ai mercati internazionali.
<br>
Incontra produttori, artigiani, distributori, grossisti, rivenditori al dettaglio e operatori logistici adatti alle tue specifiche necessità. 
<br>
Trovare i tuoi partner commerciali non è mai stato così semplice!    
</h2> 
</span>             
</div>


<!--  ELIMINATA PER RIDONDANZA CON PROMO
	<br>
	<hr>
  <br>  
	<span class="testo_centrale">
    <h1>
    Registrati a GoBiz e inizia a far crescere i tuoi affari!
    </h1> 
  </span>                   


  <div class="lead">
                  <div class="btn btn-success btnjoin2" style="margin: 0 auto; display: block;font-weight:bold;"> 
                  <router-link style="color:white;" v-bind:to="'register'">Registrati ora!</router-link>
                  </div>  
  </div>
      <br>
      -->
      <hr>
      <hr>
      <br>  
      <br>

         <div>
                 
           <span class="testo_centrale_giallo">
           <h1>Rendi il tuo Business internazionale in soli 4 passi</h1>
           </span>
          </div>
             <span class="bignumbers" style="color: #e5d333;">
              <span style="color: #e6e4e5;">1</span>
            .</span>
        
        <div class="onlymargin">
		
                 <h1>Crea il profilo della tua impresa</h1>
                 <h4>GoBiz verifica ogni profilo d'impresa usando fonti certificate e affidabili.</h4>
                 <h4><strong>Lavoriamo costantemente per garantire l’affidabilità della nostra Community.</strong></h4>

        <br>
        </div>
         
         <div>
               <div>
                 <span class="bignumbers" style="color: #e6e4e5;">2</span><span class="bignumbers" style="color: #e5d333;">.</span>
               </div>
         
               <div class="onlymargin">
                 <h1>Cerca opportunità di crescita</h1>

                 <h4>GoBiz ti consente di avere risposte in tempo reale basate su dati certificati e approfittare di opportunità a livello nazionale e internazionale per far crescere i tuoi affari.</h4>
                 <h4><strong>Guarda oltre l'orizzonte.</strong></h4>
                <br>
               </div>
      
           <div>
             <span class="bignumbers"  style="color: #e6e4e5;">3</span><span class="bignumbers" style="color: #e5d333;">.</span>
           </div>
        
           <div class="onlymargin">
            
                <h1>Trova il miglior partner di business</h1>

                <h4>In base al tuo profilo e alle tue caratteristiche, l'algoritmo di GoBiz ti segnala le imprese più rilevanti in termini di affidabilità, valore e compatibilità.</h4>
                <h4><strong>Preparati ad avviare nuove Partnership!</strong></h4>
<br>
           </div>
          
             <span class="bignumbers"  style="color: #e6e4e5;">4</span><span class="bignumbers" style="color: #e5d333;">.</span>
          
        <div class="onlymargin">

        <h1> Negozia il tuo prossimo accordo ed espandi il tuo Business </h1>
        <h4>Una volta che entrambe le imprese hanno confermato reciproco interesse, GoBiz ti assiste in tutto il processo di negoziazione.
        <br>
        Strumenti di traduzione istantanea semplificano le interazioni con un interlocutore estero e la tecnologia Blockchain mette a tua disposizione un vero e proprio notaio digitale per dirimere eventuali dispute.<br>
        Quando la negoziazione è completa il tuo Business è pronto a crescere e per eventuali  problemi potrai sempre contare sul nostro supporto.</h4>
        </div>
<br>
<br>
<hr>
<br>
<br>
 
                 <div class="onlymargin" style="display: block;font-weight:bold;font-size: calc(1vw + 2.7vh);font-family: 'uniform_roundedbold', Arial, sans-serif;"><strong>GoBiz è il tuo nuovo partner per l'Internazionalizzazione!</strong></div>
<br>
<br>
<hr>

 </div>

    </h4>
       
    
        <!-- 
        <div style='margin-bottom:40px; margin-top:0px;padding:25px; background-color:#ffffff'>
        -->

<!-- fine pagina in italiano -->


<!-- inizio pagina in inglese -->

<h4  v-if="this.lang=='Eng'">
<div class="d-none d-xl-block">
  <section id="imgback">
  <div class="inner">
      <!--  box e immagini Visible only on xl -->
  
	
	<div class="boxtesto1">
	<span class="testo_blu">The new way to find international business partners:</span><br>
	<br>
	<span class="testo_bianco">secure, simple, affordable. </span>
    <br> 
     <br>
     <br>
     <!-- ex html <a class="btn btn-success btnjoin" style="margin: 0 auto; width:60%; display: block;font-weight:bold;" href="./register" role="button">Join now!</a>  -->
     <div class="btn btn-success btnjoin" style="margin: 0 auto; width:60%; display: block;font-weight:bold;"> 
           <router-link style="color:white;" v-bind:to="'register'">Join now!</router-link>
           </div>  
  </div>
    
  <div class="boxtesto2">

	<span class="testo_giallo_small">Expand your Business globally supported by a digital solution both designed to facilitate the search of certified and reputable business partners and to execute a trade agreement. 
  </span>
    <br><br>
    <span class="testo_bianco_small"> Bypass intermediaries and overcome legal, cultural and language barriers.
    </span>
     <br>
    <br>
 
  </div>
    <h1></h1>
    <p></p>
  </div>
</section>
</div>

 
     
<!-- prosecuzione inglese -->
      <!--  box e immagini Visible only on md -->
      <div class="d-none d-md-block d-lg-block d-xl-none">
        <section id="imgback">
      <div class="inner">
              <div class="boxtesto_md4">
              <br>
                <span class="testo_blu_md">The new way to find international business partners:</span><br>
              <br>
              <span class="testo_bianco_md">Secure, simple, affordable. </span>
              <br>
              <br>
             <!-- ex html  <a class="btn btn-success btnjoin" style="margin: 0 auto; width:50%; display: block;font-weight:bold;font-size: 1.4rem;" href="./register" role="button">Join now!</a> -->
             <div class="btn btn-success btnjoin" style="margin: 0 auto; width:50%; display: block;font-weight:bold;font-size: 1.4rem;"> 
             <router-link style="color:white;" v-bind:to="'register'">Join now!</router-link>
             </div>
        </div>  
               <div class="boxtesto_md5">

              <span class="testo_giallo_mdxs">Expand your Business globally supported by a digital solution both designed to facilitate the search of certified and reputable business partners and to execute a trade agreement. 

                 </span><br><br>
                <span class="testo_bianco_mdxs">Bypass intermediaries and overcome legal, cultural and language barriers.
                </span>
                 <br>
                <br>
                </div> <!-- --------- -->
     
      </div>
        <h1></h1>
        <p></p>
       </section>
      
      <!-- fine box e immagini Visible only on md and lg -->
           
    </div>

       <!-- box e immagini  Visible only on lower than md -->   
    <div class="d-md-none">
      <section id="imgback">
        <div class="inner">
          <div class="boxtesto_xs" >
            <br>
                <span class="testo_blu_xs">The new way to find international business partners:</span><br>
                <br>
                <span class="testo_bianco_xs">Secure, simple, affordable. </span>
              <br> 
            <br>
            <!-- ex html <a class="btn btn-success btnjoin" style="margin: 0 auto; width:60%; display: block;font-weight:bold;font-size: 1.1rem;" href="./register" role="button">Join now!</a>  -->
            <div class="btn btn-success btnjoin" style="margin: 0 auto; width:60%; display: block;font-weight:bold;font-size: 1.1rem;"> 
            <router-link style="color:white;" v-bind:to="'register'">Join now!</router-link>
            </div>
          </div>
            
            <div class="boxtesto_xs2">

                <span class="testo_giallo_xxs">Expand your Business globally supported by a digital solution both designed to facilitate the search of certified and reputable business partners and to execute a trade agreement. 

              </span>
            <br>
                  <span class="testo_bianco_xxs">Bypass intermediaries and overcome legal, cultural and language barriers.
                </span>
                <br>
              <br>
                  </div> 
          </div>
        <h1></h1>
        <p></p>
       </section>
      </div>
     
     
     <!-- fine parte variabile in base a risoluzione-->
     
      <div id="intro"> 

      
<hr>
<hr>
<h1 style="text-align: center; margin-right: 15%; margin-left: 15%;"> Registration, company showcase, partner search and interactions are free!</h1>
<h1 style="text-align: center; margin-right: 10%; margin-left: 10%;font-size: 0.95em;"> No fees or payment required for these services.</h1>
<br>


<br>
<h1><span class = "underline" style="text-align: center; margin-right: 10%; margin-left: 10%;text-decoration: underline red;">Discover new services:</span></h1>
<h1><span class = "underlinenotfixed" style="text-align: center; margin-right: 10%; margin-left: 10%;font-size: 0.6em;">Company search and first meeting setup;<br>Company check</span></h1>

            <div class="btn btn-success btnjoin" style="margin: 0 auto; width:26%; display: block;font-weight:bold;font-size: 1.1rem;"> 
            <router-link style="color:white;" v-bind:to="'overview'">Click here to discover our service list</router-link>
            </div>


<!-- <br>Import/export advisory. -->

<!-- <h1 style="text-align: center; margin-right: 5%; margin-left: 5%;" > No payment needed, search companies and publish your profile for free</h1> -->
<!-- <h1 style="text-align: center; margin-right: 5%; margin-left: 5%; font-size: 1.2em" >Complete registration required; No fees or payments needed.</h1> -->

<br>
<hr>
<hr>
        <span class="testo_centrale"><h1>Our services are designed to tear down barriers, save time and costs and expand your Business.</h1></span>
       <span class="testo_centrale_small"><h2>
        GoBiz uses the most innovative technology to guarantee secure, quick and affordable access to international markets.
       <br>
                 Find producers, manufacturers, distributors, wholesalers, retailers, logistic operators or packagers that perfectly suit your specific needs. 
        <br>
             The search for business partners is now easier and smarter than ever!    
        </h2> </span>             
           </div>
    <br>
    <!-- ELIMINATA PER RIDONDANZA CON PROMO
      <hr>
        <br>  
      <span class="testo_centrale">
          <h1>
           Join GoBiz and start expanding your Business
         </h1> 
         </span>                   
      

       <div class="lead">
          <div class="btn btn-success btnjoin2" style="margin: 0 auto; display: block;font-weight:bold;"> 
          <router-link style="color:white;" v-bind:to="'register'">Join now!</router-link>
          </div>
       </div>
    <br>
    -->
    <hr>
    <hr>
      <br>  
      <br>
         <div>
                 
               <span class="testo_centrale_giallo">
                 <h1>Take Your Business Global in 4 steps</h1>
               </span>
          </div>
      
           <span class="bignumbers" style="color: #e5d333;"><span style="color: #e6e4e5;">1</span>.</span>
        
		<div class="onlymargin">
		
                 <h1>Create your Company Profile</h1>
                 <p><span>GoBiz verifies every company profile by using certified and trustable sources.</span></p>
                 <p><strong>We love the truth.</strong></p>
        <br>
        </div>
         
         <div>
               <div>
                 <span class="bignumbers" style="color: #e6e4e5;">2</span><span class="bignumbers" style="color: #e5d333;">.</span>
               </div>
                    
          
         
               <div class="onlymargin">
                 <h1>Look for growth opportunities</h1>
                 <p>GoBiz allows you to have real-time-analyses based on certified data and take advantage of national and international opportunities to expand your Business.</p>
                 <p><strong>Look beyond what you see.</strong></p>
          <br>
               </div>
             
       
  

           <div>
             <span class="bignumbers"  style="color: #e6e4e5;">3</span><span class="bignumbers" style="color: #e5d333;">.</span>
           </div>
        
           <div class="onlymargin">
            
                 <h1>Find the best partner</h1>
                 <p>Based on your profile and business goals, the GoBiz algorithm recommends companies with the highest score in terms of trustability and compatibility.</p>
                 <p><strong>Are you ready for a match?</strong></p>
          <br>
           </div>
          
             <span class="bignumbers"  style="color: #e6e4e5;">4</span><span class="bignumbers" style="color: #e5d333;">.</span>
          
       <div class="onlymargin">
       <h1> Negotiate your partnership and Go Biz! </h1>
                 <p>Once the match is confirmed by the two parties, GoBiz assists you during the whole negotiation process.
         <br>
            The instant translation tool simplifies communications with a foreign partner and our blockchain-based functionalities act as a digital notary.<br />
                    When the negotiation is completed, your Business is ready to grow. 
              <br>If there are any issues, you can rely on our support at any time.</p>
            </div>
                    <br>
            <br>
          <hr>
          <br>
          <br>
           <div class="onlymargin">
                  <h1><p><strong>GoBiz is your new partner for Internationalization!</strong></p></h1>
          </div>
          <br>
          <br>
          <hr>


 </div>
      </h4>



 <!--  fine pagina in inglese -->

<!-- inizio pagina in danimarca -->
<h4  v-if="this.lang=='Den'"> 

 <div class="d-none d-xl-block">
  <section id="imgback">
  <div class="inner">
            <!--  box e immagini Visible only on xl -->
    
    
	<div class="boxtesto1">
	<span class="testo_blu">Den nye måde at finde internationale forretningspartnere på:</span><br>
	<br>
    <span class="testo_bianco">sikker, simpel, overkommelig pris</span>
      <br>
      <br>
     <!-- ex html <a class="btn btn-success btnjoin" style="margin: 0 auto; width:95%; display: block;font-weight:bold;" href="./register" role="button">Tilmeld dig nu!</a> -->
           <div class="btn btn-success btnjoin" style="margin: 0 auto; width:95%; display: block;font-weight:bold;"> 
           <router-link style="color:white;" v-bind:to="'register'">Tilmeld dig nu!</router-link>
           </div> 
    </div>
      <div class="boxtesto2">
      <span class="testo_giallo_small">Udvid din forretning globalt understøttet af en digital løsning, der både er designet til at lette søgningen efter certificerede og velrenommerede forretningspartnere, samt til at udføre en handelsaftale. 
      </span><br><br>
      <span class="testo_bianco_small">Undgå mellemmænd og overvind juridiske - kulturelle - sproglige barrierer.
      </span>
      <br>
      <br>
  
      </div>
    <h1></h1>
    <p></p>
  </div>
</section>
</div>

    
 
		<!--fine xl --------- -->
<!-- spostato su pagina vue 
<div class="green-box"><a href="https://info.gobiz.be/green/i-love-green-dk/" style="color:white;" target="_blank" rel="noopener">JA, VI ELSKER GRØN</a>
</div>
-->

      <!--  box e immagini Visible only on md -->
      <div class="d-none d-md-block d-lg-block d-xl-none">
        <section id="imgback">
        <div class="inner">
              <div class="boxtesto_md4">
              <br>
                <span class="testo_blu_md">Den nye måde at finde internationale forretningspartnere på:</span><br>
              <br>
              <span class="testo_bianco_md">sikker, simpel, overkommelig pris</span>
              <br>
              <br>
                  <!-- ex html <a class="btn btn-success btnjoin" style="margin: 0 auto; width:95%; display: block;font-weight:bold;font-size: 1.3rem;" href="./register" role="button">Tilmeld dig nu!</a> -->
                  <div class="btn btn-success btnjoin" style="margin: 0 auto; width:95%; display: block;font-weight:bold;font-size: 1.3rem;"> 
                  <router-link style="color:white;" v-bind:to="'register'">Tilmeld dig nu!</router-link>
                  </div> 
                            
              </div>
            
               <div class="boxtesto_md5">

              <span class="testo_giallo_mdxs">Udvid din forretning globalt understøttet af en digital løsning, der både er designet til at lette søgningen efter certificerede og velrenommerede forretningspartnere, samt til at udføre en handelsaftale. 

                 </span><br><br>
                <span class="testo_bianco_mdxs">Undgå mellemmænd og overvind juridiske - kulturelle - sproglige barrierer.
                </span>
                 <br>
                <br>
                </div> <!-- --------- -->
     
          </div>
        <h1></h1>
        <p></p>
       </section>
      
      <!-- fine box e immagini Visible only on md and lg -->
           
    </div>

 

      <!-- box e immagini  Visible only on lower than md -->   
    <div class="d-md-none">
      <section id="imgback">
        <div class="inner">
          <div class="boxtesto_xs" >
            <br>
                <span class="testo_blu_xs">Den nye måde at finde internationale forretningspartnere på:</span><br>
                <br>
                <span class="testo_bianco_xs">sikker, simpel, overkommelig pris</span>
            <br> 
            <br>
                  <!-- ex html <a class="btn btn-success btnjoin" style="margin: 0 auto; width:95%; display: block;font-weight:bold;font-size: 1.1rem;" href="./register" role="button">Tilmeld dig nu!</a> -->
                  <div class="btn btn-success btnjoin" style="margin: 0 auto; width:95%; display: block;font-weight:bold;font-size: 1.1rem;"> 
                  <router-link style="color:white;" v-bind:to="'register'">Tilmeld dig nu!</router-link>
                  </div>

            </div>
            
            <div class="boxtesto_xs2">

                <span class="testo_giallo_xxs">Udvid din forretning globalt understøttet af en digital løsning, der både er designet til at lette søgningen efter certificerede og velrenommerede forretningspartnere, samt til at udføre en handelsaftale. 

              </span>
            <br>
                  <span class="testo_bianco_xxs">Undgå mellemmænd og overvind juridiske - kulturelle - sproglige barrierer.

                </span>
                <br>
              <br>
                  </div> 
          </div>
        <h1></h1>
        <p></p>
       </section>
      </div>
     
     
     <!-- fine parte variabile in base a risoluzione-->
     
        <div id="intro"> 
         <br>
         <hr>
         <hr>
        <h1 style="text-align: center; margin-right: 15%; margin-left: 15%;">Registrering, din virksomheds fremvisning, partnersøgning og interaktioner er gratis!</h1>
        <h1 style="text-align: center; margin-right: 10%; margin-left: 10%;font-size: 0.95em;">Der kræves ingen gebyrer eller betalinger for disse tjenester.</h1>
        <!-- <h1 style="text-align: center; margin-right: 5%; margin-left: 5%;" > Ingen betaling nødvendig, søg virksomheder og udgiv din profil gratis</h1> -->
        <br>

        <br>
         
        <h1><span class = "underline" style="text-align: center; margin-right: 10%; margin-left: 10%;text-decoration: underline red;">Opdag nye tjenester:</span></h1>
        <h1><span class = "underlinenotfixed" style="text-align: center; margin-right: 10%; margin-left: 10%;font-size: 0.6em;">Firmasøgningsservice og indledende mødearrangement;<br>Virksomhedstjek</span></h1>
       
       
       
            <div class="btn btn-success btnjoin" style="margin: 0 auto; width:26%; display: block;font-weight:bold;font-size: 1.1rem;"> 
            <router-link style="color:white;" v-bind:to="'overview'">Klik her for at finde vores tjenester</router-link>
            </div>

        <!-- <br>Import/eksportrådgivning. -->

        <hr>
        <hr>
        <span class="testo_centrale"><h1>Vores tjenester er designet til at nedbryde barrierer, spare tid og omkostninger, samt udvide din forretning.</h1></span>
         <span class="testo_centrale_small"><h2>
          GoBiz bruger den mest innovative teknologi til at garantere en sikker og hurtig adgang til internationale markeder til en overkommelig pris.
          <br>
          Find producenter, fabrikanter, distributører, grossister, detailhandlere, logistikoperatører eller pakkere, der passer perfekt til dine specifikke behov.
          <br>
          Det er nu nemmere og smartere end nogensinde at søge efter forretningspartnere!   
        </h2> 
        </span>             
        </div>

<!-- ELIMINATA PER RIDONDANZA CON PROMO
      <br>
      <hr>
          <br>  
        <span class="testo_centrale">
          <h1>
            Tilslut dig Gobiz, og begynd at udvide din virksomhed
         </h1> 
         </span>                   
 
        <div class="lead">
                  <div class="btn btn-success btnjoin2" style="margin: 0 auto; display: block;font-weight:bold;"> 
                  <router-link style="color:white;" v-bind:to="'register'">Tilmeld dig nu!</router-link>
                  </div>
        </div>
      <br>
      -->
      <hr>
       <hr>
        <br>  
      <br>
         <div>
                 
               <span class="testo_centrale_giallo">
                 <h1>Gør din virksomhed global med 4 trin</h1>
               </span>
          </div>
      
           <span class="bignumbers" style="color: #e5d333;"><span style="color: #e6e4e5;">1</span>.</span>
          
      <div class="onlymargin">
      
                  <h1>Opret din virksomhedsprofil</h1>
                  <p><span>GoBiz verificerer enhver virksomhedsprofil ved hjælp af certificerede og pålidelige kilder.</span></p>
                  <p><strong>Vi elsker sandheden.</strong></p>
          <br>
        </div>
         
         <div>
               <div>
                 <span class="bignumbers" style="color: #e6e4e5;">2</span><span class="bignumbers" style="color: #e5d333;">.</span>
               </div>
                    
          
         
               <div class="onlymargin">
                 <h1>Se efter vækstmuligheder.</h1>
                 <p>GoBiz giver dig mulighed for at foretage realtidsanalyser baseret på certificerede data, samt udnytte nationale og internationale muligheder for at udvide din forretning.</p>
                 <p><strong>Udvid din horisont.</strong></p>
              <br>
               </div>
             
       
  

           <div>
             <span class="bignumbers"  style="color: #e6e4e5;">3</span><span class="bignumbers" style="color: #e5d333;">.</span>
           </div>
        
           <div class="onlymargin">
            
                 <h1>Find den bedste partner</h1>
                 <p>Baseret på din profil og dine forretningsmål, anbefaler GoBiz-algoritmen virksomheder med den højeste score med hensyn til pålidelighed og kompatibilitet.</p>
                 <p><strong>Er du klar til et match?</strong></p>
            <br>
           </div>
          
             <span class="bignumbers"  style="color: #e6e4e5;">4</span><span class="bignumbers" style="color: #e5d333;">.</span>
          
            <div class="onlymargin">
          <h1> Forhandl dit partnerskab med Go Biz!</h1>
          <p>Når matchet er bekræftet af de to parter, hjælper GoBiz dig under hele forhandlingsprocessen.
          <br>
          Værktøjet til øjeblikkelig oversættelse forenkler kommunikationen med en udenlandsk partner, og blockchain-teknologien fungerer som en digital notar.<br>
            Når forhandlingen er afsluttet, er din virksomhed klar til at vækste.  
            <br>Hvis der er problemer, kan du sætte din lid til vores support.</p>
          </div>
                  <br>
          <br>
          <hr>
          <br>
          <br>
          <div class="onlymargin">
                  <h1><p><strong>GoBiz er din nye partner til internationalisering!</strong></p></h1>
          </div>
          <br>
          <br>
          <hr>
           
          
 </div>
    </h4>
<!-- fine pagina danimarca -->
        
        <!-- inizio clienti e partner ita - eng  -den

        <h4  v-if="this.lang=='Ita'"> 
          <div id="clients">
           <br>
            <div class="onlymargin">
              <h3>Questi clienti hanno già scelto Gobiz per il loro business:</h3>
            </div>
          </div>
        </h4>
          
        <h4  v-if="this.lang=='Eng'"> 
         <div id="clients">
           <br>
            <div class="onlymargin">
              <h3>These companies have selected Gobiz for their business:</h3>
           </div>
          </div>
        </h4>
      
       <h4  v-if="this.lang=='Den'"> 

          <div id="clients">
           <br>
            <div class="onlymargin">
              <h3>Disse virksomheder har udvalgt Gobiz til deres forretning:</h3>
            </div>
          </div>
        </h4>

         -->
         
         <!-- .et_pb_row -->
         <div>
           <br>
           <h1>&nbsp;</h1>
           <h3></h3>
           <p>&nbsp;</p>
         </div>
     
             <div>
             <!-- <img src="https://assets.gobiz.be/imghome/arctur-logo.png" hspace="5%" vspace="2%" alt="" title="" srcset="" sizes="(max-width: 173px) 100vw, 173px" /> 
             <a href="http://biancalabufala.co.uk/" target="_blank" title="Bianca la Bufala" rel="noopener noreferrer"><img src="https://assets.gobiz.be/imghome/biancalabufala320.png" hspace="5%" vspace="2%" alt="" title="" srcset="" sizes="(max-width: 250px) 100vw, 250px" /></a>
            -->

             <!-- pagnani in standby
              <a href="https://www.pagnanitartufi.it/" target="_blank" title="Pagnani tartufi" rel="noopener noreferrer"><img src="https://assets.gobiz.be/imghome/pagnani.png"  hspace="5%" vspace="2%" alt="" title="" srcset="" sizes="(max-width: 250px) 100vw, 250px"  /></a>
             -->
              <!--
             <a href="https://www.damianodamiano.com/" target="_blank" title="Damiano e Damiamo" rel="noopener noreferrer"><img src="https://assets.gobiz.be/imghome/DamianoDamiano320v1.png"  hspace="5%" vspace="2%" alt="" title="" srcset="" sizes="(max-width: 250px) 100vw, 250px" /></a>
              -->
             <!-- <img src="https://assets.gobiz.be/imghome/polo-tecnologico-pordenone.png"  hspace="5%" vspace="2%" alt="" title="" srcset="" sizes="(max-width: 250px) 100vw, 250px" /> -->
             </div>

             <!-- 
          <h4  v-if="this.lang=='Ita'"> 
        <div id="clients2">
          <br>
          <div class="onlymargin">
          <h3>E tu cosa aspetti? Registrati subito!</h3>
          </div>
        </div>
          </h4>
          <h4  v-if="this.lang=='Eng'"> 
        <div id="clients2">
          <br>
          <div class="onlymargin">
          <h3>What are you waiting for? Register now!</h3>
          </div>
        </div>
          </h4>
          <h4  v-if="this.lang=='Den'"> 
        <div id="clients2">
          <br>
          <div class="onlymargin">
          <h3>Hvad venter du på? Registrer nu!</h3>
          </div>
        </div>
          </h4>

       
          <br>
          <hr>
          -->
          <h4  v-if="this.lang=='Ita'"> 

          <!--
         <div id="partners">
             <div class="onlymargin">
              <h3>Scegliamo solo i migliori fornitori e partner per garantirti la migliore esperienza possibile.</h3>
            </div>
          
         
         </div>
         -->
         <!-- .et_pb_row -->

          <!--
         <div>
           <br>
           <h1>&nbsp;</h1>
           <h3>I nostri partner e fornitori:</h3>
           <p>&nbsp;</p>
         </div>
       -->
          <!--
             <div>
             <a href="https://www.jimmyk.it/" target="_blank" title="Jimmyk" rel="noopener noreferrer"><img src="https://assets.gobiz.be/imghome/logo-jimmyk-1.png" hspace="5%" vspace="2%" alt="" title="" srcset="" sizes="(max-width: 250px) 100vw, 250px" /></a>
              <a href="https://aws.amazon.com" target="_blank" title="aws" rel="noopener noreferrer"> <img src="https://assets.gobiz.be/imghome/amazon-web-services-small.png"  hspace="5%" vspace="2%" alt="" width="235" height="101" title="" srcset="https://assets.gobiz.be/imghome/amazon-web-services-233-1.png 980w, https://assets.gobiz.be/imghome/kisspng-amazon-web480x206.png 480w" sizes="((min-width: 0px) and (max-width: 50px)) 240px, (min-width: 31px) 980px, 100vw" /></a>
             </div>
        -->
        
     
         <div>

              <h3>GoBiz fa parte di</h3>

          </div>
       
         
           <!-- .et_pb_column -->
      
           <div> 
            <img src="https://assets.gobiz.be/imghome/confcommercio.jpg" alt="" width="354" height="200" title="" srcset="https://assets.gobiz.be/imghome/confcommercio.jpg 1181w, https://assets.gobiz.be/imghome/confcommercio-980x554.jpg 980w, https://assets.gobiz.be/imghome/confcommercio-480x271.jpg 480w" sizes="((min-width: 0px) and (max-width: 480px)) 480px, ((min-width: 481px) and (max-width: 980px)) 980px, (min-width: 981px) 1181px, 100vw" />
           </div>
       <hr>
        <br>
        <br>
          <h5>Segui GoBiz su LinkedIn</h5>
          <a href="https://www.linkedin.com/company/gobiz-be/" target="_blank" title="Follow GoBiz on Linkedin" rel="noopener noreferrer"><img src="https://assets.gobiz.be/imgmail/linkedin.png" alt="Follow GoBiz on Linkedin" style="width: 78px; height: 22px; padding: 0px; margin: 0px;" width="78" height="22 " border="0"></a>
        <br>
        <br>
        <br>
       <hr>
       </h4>

        

        <h4  v-if="this.lang=='Eng'"> 
          <!--
         <div id="partners">
               
            <div class="onlymargin">
                 <h3>We only choose the best partners for your GoBiz experience.</h3>
            </div>
          
         
         </div>
         -->

         <!-- .et_pb_row -->

             <!--
         <div>
           <h1>&nbsp;</h1>
           <h3>Our Partners:</h3>
           <p>&nbsp;</p>
         </div>
              -->

              <!--
           <div>
           
              <a href="https://www.jimmyk.it/" target="_blank" title="Jimmyk" rel="noopener noreferrer"><img src="https://assets.gobiz.be/imghome/logo-jimmyk-1.png" hspace="5%" vspace="2%" alt="" title="" srcset="" sizes="(max-width: 250px) 100vw, 250px" /></a>
              <a href="https://aws.amazon.com" target="_blank" title="aws" rel="noopener noreferrer"> <img src="https://assets.gobiz.be/imghome/amazon-web-services-small.png"  hspace="5%" vspace="2%" alt="" width="235" height="101" title="" srcset="https://assets.gobiz.be/imghome/amazon-web-services-233-1.png 980w, https://assets.gobiz.be/imghome/kisspng-amazon-web480x206.png 480w" sizes="((min-width: 0px) and (max-width: 50px)) 240px, (min-width: 31px) 980px, 100vw" /></a>
             </div>
          -->
        
       

          <div>
                <h3>We are member of</h3>
            </div>
        
          
            <!-- .et_pb_column -->
            <div >
              <div>
                <img src="https://assets.gobiz.be/imghome/confcommercio.jpg" alt="" width="354" height="200" title="" srcset="https://assets.gobiz.be/imghome/confcommercio.jpg 1181w, https://assets.gobiz.be/imghome/confcommercio-980x554.jpg 980w, https://assets.gobiz.be/imghome/confcommercio-480x271.jpg 480w" sizes="((min-width: 0px) and (max-width: 480px)) 480px, ((min-width: 481px) and (max-width: 980px)) 980px, (min-width: 981px) 1181px, 100vw" /> 
              </div>
            </div>
       <hr>
        <br>
        <br>
          <h5>Follow GoBiz on Linkedin</h5>
          <a href="https://www.linkedin.com/company/gobiz-be/" target="_blank" title="Follow GoBiz on Linkedin" rel="noopener noreferrer"><img src="https://assets.gobiz.be/imgmail/linkedin.png" alt="Follow GoBiz on Linkedin" style="width: 78px; height: 22px; padding: 0px; margin: 0px;" width="78" height="22 " border="0"></a>
        <br>
        <br>
        <br>
       <hr>
       </h4>


        
      
       <h4  v-if="this.lang=='Den'"> 
         <!--  <div id="partners">
         
            <div class="onlymargin">
                 <h3>Vi vælger kun de bedste partnere til din GoBiz-oplevelse</h3>
            </div>
          
  
         </div>
        --> 
         <!-- .et_pb_row -->
        
        <!--
           <div>
           <h1>&nbsp;</h1>
           <h3>Vores Partnere:</h3>
           <p>&nbsp;</p>
         </div>
        -->

        <!--
             <div>
              <a href="https://www.jimmyk.it/" target="_blank" title="Jimmyk" rel="noopener noreferrer"><img src="https://assets.gobiz.be/imghome/logo-jimmyk-1.png" hspace="5%" vspace="2%" alt="" title="" srcset="" sizes="(max-width: 250px) 100vw, 250px" /></a>
              <a href="https://aws.amazon.com" target="_blank" title="aws" rel="noopener noreferrer"> <img src="https://assets.gobiz.be/imghome/amazon-web-services-small.png"  hspace="5%" vspace="2%" alt="" width="235" height="101" title="" srcset="https://assets.gobiz.be/imghome/amazon-web-services-233-1.png 980w, https://assets.gobiz.be/imghome/kisspng-amazon-web480x206.png 480w" sizes="((min-width: 0px) and (max-width: 50px)) 240px, (min-width: 31px) 980px, 100vw" />  </a>    
              </div>
            -->
      

          <div>
               <h3>Vi er medlem af</h3>
          </div>
       
         
           <!-- .et_pb_column -->
           <div >
           <img src="https://assets.gobiz.be/imghome/confcommercio.jpg" alt="" width="354" height="200" title="" srcset="https://assets.gobiz.be/imghome/confcommercio.jpg 1181w, https://assets.gobiz.be/imghome/confcommercio-980x554.jpg 980w, https://assets.gobiz.be/imghome/confcommercio-480x271.jpg 480w" sizes="((min-width: 0px) and (max-width: 480px)) 480px, ((min-width: 481px) and (max-width: 980px)) 980px, (min-width: 981px) 1181px, 100vw" />
          </div>

       <hr>
        <br>
        <br>
          <h5>Følg GoBiz på LinkedIn</h5>
          <a href="https://www.linkedin.com/company/gobiz-be/" target="_blank" title="Follow GoBiz on Linkedin" rel="noopener noreferrer"><img src="https://assets.gobiz.be/imgmail/linkedin.png" alt="Follow GoBiz on Linkedin" style="width: 78px; height: 22px; padding: 0px; margin: 0px;" width="78" height="22 " border="0"></a>
        <br>
        <br>
        <br>
       <hr>
     </h4>




<!-- fine div iniziale class=home -->
</div>
        
         <!--  ex g-axios retrieve system
         <div  v-if="this.lang=='Eng'" v-html="this.titlePageEng"> </div>
         <h4 v-if="this.lang=='Eng'"><div v-html="this.contentPageEng">  </div> </h4>

         <div  v-if="this.lang=='Den'" v-html="this.titlePageDen"> </div>
         <h4 v-if="this.lang=='Den'"><div v-html="this.contentPageDen">  </div> </h4>
          -->

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.green-box{position:fixed;;
top:40%;
right:0px;
writing-mode:vertical-rl;
text-orientation:mixed;
color:white;
font-size:14px;
font-weight:900;
text-transform:uppercase;
background:green;
padding:30px 12px;
font-family:'Dosis',Helvetica,Arial,Lucida,sans-serif;
z-index:999999}
@media (max-width:980px){
.green-box{right:12px;padding:30px 6px}}


#imgback {
  position: relative;
  height: 100vh;
  background: url('../assets/GoBiz_LandingPage-wall-persone.jpg') no-repeat ;
  background-size:cover;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 60px;
}

#imgback::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

.onlymargin {
   margin-left:7%;
  margin-right:7%;
	}


		
.btnjoin {
  width: 25%;
  background: #0c294b;
  text-decoration-color: #e3d234;
  box-sizing: border-box;
  color: #fff;
  letter-spacing: -0.5px;
  font-size: 120%;
}	
.btnjoin2 {
  width: 45%;
  max-width: 300px;
  background: #0c294b;
  text-decoration-color: #e3d234;
  box-sizing: border-box;
  color: #fff;
  letter-spacing: -0.5px;
  font-size: 120%;
}	
	
		
div.boxtesto1 {
  position: absolute;
  top:21%;
  bottom: 6%;
  left: 17.5%;
  width: 32.5%;
  background: #e3d234;
  text-decoration-color: #0c294b;
  padding-top:  2%;
  padding-right:  5%;
  padding-bottom: 320px;
  padding-left:  5%;
  box-sizing: border-box;
  /* height: stretch; */
  //height: -moz-available;          /* WebKit-based browsers will ignore this. */
  //height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  //height: fill-available;
  height: auto;
  margin: auto;
  float: left;
	}	
 
div.boxtesto2 {
  position: absolute;
  top: 21%;
  bottom: 6%;
  left: 50%;
  width: 32.5%;
  background: #0c294b;
  text-decoration-color: #e3d234;
  padding-top:  2%;
  padding-right: 2%;
  padding-bottom:  320px;
  padding-left:  2%;
  box-sizing: border-box;
  /* height: stretch; */
  //height: -moz-available;          /* WebKit-based browsers will ignore this. */
  //height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  //height: fill-available;
  height: auto;
  margin: auto;
  float: left;
	}	


  div.boxtesto_md4 {
  position: absolute;
  top:23%;
  bottom: 10%;
  left: 10%;
  width: 40%;
  background: #e3d234;
  text-decoration-color: #0c294b;
  padding-top: 20px;
  padding-right:  5%;
  padding-bottom: 345px;
  padding-left:  5%;
  box-sizing: border-box;
  /* height: stretch; */
  //height: -moz-available;          /* WebKit-based browsers will ignore this. */
  //height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  //height: fill-available;
   height: auto;
   margin: auto;
   float: left;
	}	
 
div.boxtesto_md5 {
  position: absolute;
  top: 23%;
  bottom: 10%;
  left: 50%;
  width: 40%;
  background: #0c294b;
  text-decoration-color: #e3d234;
  padding-top: 20px;
  padding-right: 5%;
  padding-bottom: 345px;
  padding-left: 5%;
  box-sizing: border-box;
  /* height: stretch; */
  //height: -moz-available;          /* WebKit-based browsers will ignore this. */
  //height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  //height: fill-available;
  height: auto;
  margin: auto;
   float: left;
	}	

div.boxtesto_xs {
  position: absolute;
  top:25%;
  bottom: 5%;
  left: 6%;
  width: 44%;
  background: #e3d234;
  text-decoration-color: #0c294b;
  padding-top: 20px;
  padding-right:  5%;
  padding-bottom: 330px;
  padding-left:  5%;
  box-sizing: border-box;
  /* height: stretch; */
  //height: -moz-available;          /* WebKit-based browsers will ignore this. */
  //height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  //height: fill-available;
  height: auto;
  margin: auto;
  float: left;
	}	
 
div.boxtesto_xs2 {
  position: absolute;
  top: 25%;
  bottom: 5%;
  left: 50%;
  width: 44%;
  background: #0c294b;
  text-decoration-color: #e3d234;
  padding-top: 20px;
  padding-right: 5%;
  padding-bottom: 330px;
  padding-left: 5%;
  box-sizing: border-box;
  /* height: stretch; */
  //height: -moz-available;          /* WebKit-based browsers will ignore this. */
  //height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  //height: fill-available;
  height: auto;
  margin: auto;
  float: left;
	}	


span.testo_blu {
  padding: 0px;
  color: #0C294B;
   font-size: 2.3rem;
  font-weight: bold;
  margin-left:3%;
  margin-right:3%;
  letter-spacing: -0.8px;
  }

 span.testo_bianco {
  padding: 0px;
  color: #fff;
  font-size: 2.3rem;
  text-align: center;
  font-weight: bold;
  letter-spacing: -0.8px;

}

 span.testo_bianco_small {
  padding: 0px;
  color: #fff;
  font-size: 1.6rem;
  text-align: center;

}

 span.testo_giallo_small {
  padding: 0px;
  color: #e3d234;
  font-size: 1.6rem;
 
   margin-left:3%;
  margin-right:3%;
  }

span.testo_blu_xs {
  padding: 0px;
  color: #0C294B;
  font-size: 1.1rem;
  font-weight: bold;
   margin-left:3%;
  margin-right:3%;
  }


   span.testo_bianco_xs {
  padding: 0px;
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
 font-weight:bold

}

 span.testo_bianco_xxs {
  padding: 0px;
  color: #fff;
  font-size: 0.7rem;
  text-align: center;
 font-weight:bold

}



 span.testo_giallo_xxs {
  padding: 0px;
  color: #e3d234;
 font-size: 0.7rem;
 font-weight:bold
 
  }



  span.testo_blu_md {
  padding: 0px;
  color: #0C294B;
  font-size: 1.7rem;
  font-weight: bold;
   margin-left:3%;
  margin-right:3%;
  }


   span.testo_bianco_md {
  padding: 0px;
  color: #fff;
  font-size: 1.7rem;
  text-align: center;
 font-weight:bold

}

 span.testo_bianco_mdxs {
  padding: 0px;
  color: #fff;
  font-size: 1rem;
  text-align: center;
 font-weight:bold

}



 span.testo_giallo_mdxs {
  padding: 0px;
  color: #e3d234;
  font-size: 1rem;
 font-weight:bold
 
  }

span.testo_centrale {
  padding: 40px;
  color: #0c294b;
  letter-spacing: -0.5px;
  font-size: 250%;
	margin: 0 auto; 
	display: block;
  margin-left:10%;
  margin-right:10%;
}
		
span.testo_centrale_giallo {
  padding: 40px;
  color: #e5d333;
  font-size: 250%;
  margin: 0 auto; 
	display: block;
}
span.testo_centrale_small {
  padding: 40px;
  color: #0c294b;
  letter-spacing: -0.5px;
  font-size: 130%;
  text-align: center;
  margin: 0 auto; display: block;
  margin-left:10%;
  margin-right:10%;
}
span.bignumbers {
  padding: 0px;
  font:  'Dosis',Helvetica,Arial,Lucida,sans-serif;
  font-family: 'Dosis',Helvetica,Arial,Lucida,sans-serif; 
  font-size: 400%;
  text-align: center;
}

	
#container1 {
background: #fff;
max-width:1920px;
margin:0 auto;
padding: 0px;
text-align: center;
}

.underline {
  position: relative;
  display: inline-block;
  height: 2px;
  //animation: blinking 0.1s infinite;
  -webkit-animation: blinking 2.2s infinite // backwards // alternate;
}

.underlinenotfixed {
  position: relative;
  display: inline-block;
    //animation: blinking 0.1s infinite;
  -webkit-animation: blinking 2.2s infinite // backwards // alternate;
}

body {
  margin: 0;
  padding: 0;
}

@-webkit-keyframes blinking {
  50%   { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes blinking {
  50%   { opacity: 1; }
  100% { opacity: 0; }
}

</style>

<script>
// @ is an alias to /src
//import StartHome from '@/components/StartHome.vue'
export default {

  name: 'home',
  data () {
    return {
      name : 'default',
      surname : '',
      email : '',
      password : '',
      titlePage : null,
      titlePageEng : null,
      titlePageDen : null,
      contentPageIta : null,
      contentPageEng : null,
      contentPageDen : null
    }
  },
  computed : {
    labels(){
      
      return this.$store.getters.labels;
    },
    lang(){
      return this.$store.getters.lang;
    },
  },
  components: {
    //StartHome
  },
  mounted: function(){
    this.$nextTick(function () {
     })
  },

// TAG Google Analytics rilevamento pagina

 // methods : {
 // login () {
 //    this.$gtag.event('home', { method: 'Google' })
 //   }
//  }
}

</script>
