<template>
  <div class="container" style="max-width: 98% !important;">
    
   <!-- welcome phrase anticipata --> 
      <h2 v-if="this.id!=null && getUserRole!='BOF'">{{searchevents(this.labels,'dashboard','welcome')}} {{this.userIdentity}} </h2>
      <br>

      <h2 v-if="this.id==null && getUserRole!='BOF'"><i class="fas fa-fw fa-info-circle  mr-2 align-self-center"></i>{{searchevents(this.labels,'menu','insertcompany')}}</h2>
      <h4 v-if="this.servCompaniesStateCompaniesState=='P' && getUserRole!='BOF'"><i class="fas fa-fw fa-info-circle mr-2 align-self-center"></i>{{searchevents(this.labels,'menu','companypending')}}</h4>
      
      
           <!-- scheda informativa per imprese italiane che devono completare la registrazione --> 
        <div v-if="this.servCompaniesStateCompaniesState=='R' && getUserRole!='BOF' && countryOfOrigin==1" class="alert alert-light alert-dismissible fade show" style="margin-left: 12%; margin-right: 12%; height: fill-available;">
               <div class="row">
                <div class="col-sm">
                  <div class="card bg-light mt-6">
                        <div class="card-body">
                        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        <hr>
                        <div style="margin-left: 5%; margin-right: 5%;" v-html="searchevents(this.labels,'menu','companyregistered')"></div>
                         <p class="mb-0"></p>
                     <hr>     
                 </div> 
                </div>
              </div>
           </div>
        </div> 
      
               <!-- scheda informativa per imprese estere che devono completare la registrazione --> 
        <div v-if="this.servCompaniesStateCompaniesState=='R' && getUserRole!='BOF' && countryOfOrigin!=1" class="alert alert-light alert-dismissible fade show" style="margin-left: 12%; margin-right: 12%; height: fill-available;">
               <div class="row">
                <div  class="col-sm">
                  <div class="card bg-light mt-6">
                        <div  class="card-body">
                        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        <hr>
                        <div style="margin-left: 5%; margin-right: 5%;" v-html="searchevents(this.labels,'menu','companyregisteredext')"></div>
                         <p class="mb-0"></p>
                     <hr>     
                 </div> 
                </div>
              </div>
           </div>
        </div> 

  <br>  
  
      <!-- scheda informativa per anagrafiche approvate --> 
        <div class="alert alert-light alert-dismissible fade show" style="margin-left: 12%; margin-right: 12%; height: fill-available;" v-if="getUserRole!='BOF' && ( (this.companySuccessfulBO=='A' && this.userSuccessfulBO=='A') || this.companySuccessfulBO=='C' ) "  >
               <div class="row">
                <div class="col-sm">
                  <div class="card bg-light mt-6">
                     <div class="left"> 
                       <br>
                      <h4>  <i class="fas fa-fw fa-info-circle  mr-2 align-self-center"></i>{{searchevents(this.labels,'menu','companycompleted')}}</h4>
                      </div>
                       
                       <div class="card-body">
                        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        <hr>
                        <h5 v-html="searchevents(this.labels,'textinfo','updatecompany')"></h5>
                        
                        <p class="mb-0"></p>
                     <hr>     
                 </div> 
                </div>
              </div>
           </div>
        </div> 
  <br>

      <!-- scheda per BO -->
      <div style="margin-bottom:50px" v-if="getUserRole=='BOF'">
        <div class="row" style="background-color:#0e82ff80; font-size: 1em;">
          <div class="col-sm" style="text-align:center;font-size: 1em;">Controllo blacklist effettuato</div>
          <div class="col-sm" style="text-align:center;font-size: 1em;">Contratto Sottoscritto</div>
          <div class="col-sm" style="text-align:center;font-size: 1em;">Email LR </div>
          <div class="col-sm" style="text-align:center;font-size: 1em;">PEC</div>
          <div class="col-sm" style="text-align:center;font-size: 1em;">Causa sospensione automatica</div>
          <div class="col-sm" style="text-align:center;font-size: 1em;">Esito verifica dati impresa</div>
          <div class="col-sm" style="text-align:center;font-size: 1em;">Esito verifica dati LR</div>
          <!-- <div class="col-sm" style="text-align:center;">Esito finale</div> -->
          <!-- <div class="col-sm" style="text-align:center;">Vetrina completata</div> -->
        </div>
        <div class="row" style="background-color:#97c9ff80; font-size: 1em;">
          <div class="col-sm" style="text-align:center;font-size: 1em;">
            <input v-if="this.blackListed=='Controllo blacklist effettuata'" class="form-control" style="max-width: 25px; display: initial;" type="checkbox" id="Controlloblacklisteffettuata" checked  :disabled='isNewInsert'> 
            <input v-if="this.blackListed!='Controllo blacklist effettuata'" class="form-control" style="max-width: 25px; display: initial;" type="checkbox" id="Controlloblacklisteffettuata" :disabled='isNewInsert'> 
          </div>
          <!-- flag contratto sottoscritto -->
          <div class="col-sm" style="text-align:center;font-size: 1em;"> 
    
            <input v-if="getregisteredCompany.contractSignedFlag=='N' || getregisteredCompany.contractSignedFlag==false" class="form-control" style="max-width: 25px; display: initial;" type="checkbox" id="userContractBOa"  :disabled='isNewInsert'> 
            <input v-if="getregisteredCompany.contractSignedFlag=='Y' || getregisteredCompany.contractSignedFlag==true" class="form-control" style="max-width: 25px; display: initial;" type="checkbox" id="userContractBOa" checked :disabled='isNewInsert'> 
   
          </div>
          <div class="col-sm" style="text-align:center;">
            <input class="form-control" style="max-width: 25px; display: initial;" type="checkbox" id="userEmailBOa"  v-model="userEmailBO" :disabled='isNewInsert'> 
          </div>
          <div class="col-sm" style="text-align:center;font-size: 1em;"> 
            <input class="form-control" style="max-width: 25px; display: initial;" type="checkbox" id="userContractBOa" v-model="companyguidContractFlag" :disabled='isNewInsert'> 
          </div>
          <div class="col-sm" style="text-align:center;font-size: 1em;">
            {{this.blackListed}}
           <!-- da sostiuire causale sospensione {{this.warningReasons}}  -->
          </div>
          <div class="col-sm" style="text-align:center;;font-size: 1em;">
            {{companySuccessfulBOTrad}}
          </div>
          <div class="col-sm" style="text-align:center;font-size: 1em;">
            {{userSuccessfulBOTrad}}
          </div>
          <!--
          <div class="col-sm" style="text-align:center;">
            {{companyUserSuccessfulBO}} 
          </div>
          -->
          <!--
          <div class="col-sm" style="text-align:center;">
            <input class="form-control" style="max-width: 25px; display: initial;" type="checkbox" id="companyGoBOa" value="companyGoBOa" v-model="companyGoBO" :disabled='isNewInsert' > 
          </div>
          -->
        </div>
      </div>

      <ul class="nav nav-tabs">
        <li class="nav-item" >
          <a class="nav-link active" data-toggle="tab" style="color: #0c294b; margin-right: 0px; margin-left: 0px;" href="#step1">{{searchevents(this.labels,'updatecompany','step1')}}</a>
        </li>
       <li class="nav-item" v-if="(this.companySuccessfulBO=='A' && this.userSuccessfulBO=='A') || this.companySuccessfulBO=='C'">
          <a class="nav-link" data-toggle="tab" href="#step2">{{searchevents(this.labels,'updatecompany','step2')}}</a>
        </li>
        
      </ul>

    <form ref="formMain" id="formMain" @submit.prevent="onSubmit" style="margin-top: 50px;">

    <div class="tab-content">
    <div class="tab-pane container active" id="step1" style="margin-bottom:0px;"> 
      
      <!-- PANNELLO 1 ################################################################### -->

     <!-- "velo" per blocco modifica reintrodotto con compatibilità per tutti i browser -->

    <!-- <div id="panelonecover" style="width: 100%;  height: -webkit-fill-available;height: -moz-available;height: fill-available;  z-index: 20;  position: absolute; top:10px; background-color:blue;" v-if="this.servCompaniesStateCompaniesState!='R' && getUserRole!='BOF' && this.id!=null"></div> -->
       <div v-if="this.servCompaniesStateCompaniesState!='R' && getUserRole!='BOF' && this.id!=null" class="alert alert-info alert-dismissible fade show overlayfullext" style="z-index: 20;">
          <div class="row">
            <div class="col-sm">
             <div class="card bg-light overlayfull">
              <div class="card-body">
                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                   <div  style="margin-left: 5%; margin-right: 5%;" v-html="searchevents(this.labels,'menu','companyregistered2')">
                   </div>
                </div> 
              </div>
             </div>
            </div> 
    </div>
    <!--  ELIMINATO - ID non trovato nel codice backend   <div id="panelonecover" style="width: 100%;  height: -webkit-fill-available;  z-index: 20;  position: absolute; top:10px; background-color:transparent;" v-if="this.servCompaniesStateCompaniesState!='R' && getUserRole!='BOF' && this.id!=null"></div> -->

    


        <!--   già commentato -->
      <!-- <h4 v-if="(this.servCompaniesStateCompaniesState=='C' || this.servCompaniesStateCompaniesState=='A') && getUserRole!='BOF'">{{searchevents(this.labels,'menu','companycompleted')}}</h4> -->
    
     <!--
      <h4 v-if="this.servCompaniesStateCompaniesState=='P' && getUserRole!='BOF'">{{searchevents(this.labels,'menu','companypending')}}</h4>
      
      <h4 v-if="this.servCompaniesStateCompaniesState=='R' && getUserRole!='BOF' && countryOfOrigin==1">
          <div v-html="searchevents(this.labels,'menu','companyregistered')"></div>
      </h4>
      <h4 v-if="this.servCompaniesStateCompaniesState=='R' && getUserRole!='BOF' && countryOfOrigin!=1">
          <div v-html="searchevents(this.labels,'menu','companyregisteredext')"></div>
      </h4>
      -->



    <div class="container tablelist" style="max-width: 95%;" v-if="this.servCompaniesStateCompaniesState=='NONESISTE'">
      <!-- legenda dei campi obbligatori tramite colori bordo -->
      <div class="row" style="font-size: 0.8em;  margin-left: 20px; padding: 5px;">
          <div class="col-sm" style="text-align: center;">
            Legenda
          </div>
      </div>
        <div class="row" style="font-size: 0.8em;  margin-left: 20px; padding: 5px;">
          <div class="col-sm">
            <div style="margin-right: 5px; margin-left: 0px; margin-top: 5px;"> <div style="margin-left: 0px; width: 400px;">{{searchevents(this.labels,'menu','predominantfields')}}</div> </div>
            <div style="background-color: burlywood; width: 20px; margin-right: 5px; margin-left: 0px; margin-top: 5px;"> <div style="margin-left: 25px; width: 400px;">{{searchevents(this.labels,'menu','obligatory')}}</div> </div>
            <div style="background-color: #d0d0d0; width: 20px; margin-right: 5px; margin-left: 0px; margin-top: 5px;"> <div style="margin-left: 25px; width: 150px;">{{searchevents(this.labels,'menu','noteditable')}}</div> </div>
            <div style="background-color: #d0d0d0; width: 20px; margin-right: 5px; margin-left: 0px; margin-top: 5px; border: solid 2px #b72323;"> <div style="margin-left: 25px; width: 650px;">{{searchevents(this.labels,'menu','boeditonly')}}</div> </div>
          </div>
          <div class="col-sm">
            <div style="margin-right: 5px; margin-left: 0px; margin-top: 5px;"> <div style="margin-left: 0px; width: 400px;">*{{searchevents(this.labels,'menu','mandatorydata')}}</div> </div>
            <div style="background-color: #ffffff; width: 20px; margin-right: 5px; margin-left: 0px; margin-top: 5px; border: solid 2px #43d22f;"> <div style="margin-left: 25px; width: 650px;">{{searchevents(this.labels,'menu','boeditonly')}}</div> </div>
          </div>
        </div>
    </div><br><br>

    <hr v-if="getUserRole!='BOF'">
      
      <div class="form-group"> <!-- DOCS -->
        <div class="container tablelist" v-if="this.id!=null">
          <div>
            <h3>{{searchevents(this.labels,'menu','companydocs')}}</h3> 
          </div>

              <div class="row">
                <div class="col">
                  <template >
                    
                    <table class="table tablelist" >
                      <!--
                      <thead>
                        <tr>
                          <th>{{searchevents(this.labels,'table','docview')}}</th>
                          <th>{{searchevents(this.labels,'table','docdescription')}}</th>
                          <th>{{searchevents(this.labels,'table','doctype')}}</th>
                          <th>{{searchevents(this.labels,'table','modifydoc')}}</th>
                          <th></th>
                          <th>{{searchevents(this.labels,'menu','delete')}}</th>
                          
                        </tr>
                      </thead>
                      -->
                      <tbody >
                        <tr v-for="row in docListStep1a" :key="row.id">
                          
                          <template v-if="(getUserRole!='BOF' && row.servDocTypeId!=8 ) ">
                          <td  >
                            <a v-if="row.servDocTypeId==1 || row.servDocTypeId==8 || row.servDocTypeId==9" class="nav-link" :href="remoteServer + '/docs/doc_imprese/' + row.name" target="_blank"  >{{row.description2}}</a>
                            <a v-if="row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==7 || row.servDocTypeId==10" class="nav-link" :href="remoteServer + '/docs/doc_persone/' + row.name" target="_blank"  >{{row.description2}}</a>
                            <a v-if="row.servDocTypeId==4 || row.servDocTypeId==5 || row.servDocTypeId==6 " class="nav-link" :href="remoteServer + '/docs/media/' + row.name" target="_blank"  >{{row.description2}}</a>
                          </td>
                          <td   >
                            {{viewDocTypeDesc(row.servDocTypeId)}}
                          </td>
                          <td   >
                            {{ row.description3 }}
                          </td>
                          <!--
                          <td  :key="row.servDocTypeId" class="noarrow" >
                            <select class="form-control " v-if="lang=='Ita'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                            <template v-for="servDocTypeId in dropCompaniesDocs " >
                                <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeIta']}}</option>
                            </template>
                            </select>
                            <select class="form-control " v-if="lang=='Eng'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                                <template v-for="servDocTypeId in dropCompaniesDocs ">
                                    <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeEng']}}</option>
                                </template>
                            </select>
                          </td>
                          -->

                          <!-- anagrafica -->
                          <td  >
                            <button v-if="servCompaniesStateCompaniesState!='A' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==8 )" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em" @click="setDocToModify(row.id, row.description, row.description2,row.description3, row.servDocTypeId)" ><span class="fa fa-file-pdf-o" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                            <div class="row">
                            <button v-if="row.servDocTypeId!=1 && row.servDocTypeId!=2 && row.servDocTypeId!=3 && row.servDocTypeId!=8 " type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.description3, row.servDocTypeId)" ><span class="fa fa-file-pdf-o" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                            </div>
                          </td>
                          
                          <td  >
                            <button v-if="servCompaniesStateCompaniesState!='A' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==8 )" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" :key="row.id" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span>
                            </button>
                            <button v-if="row.servDocTypeId!=1 && row.servDocTypeId!=2 && row.servDocTypeId!=3 && row.servDocTypeId!=8" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" :key="row.id" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                          </td>
                          <td  >
                            <!-- <button type="button" v-if="(servCompaniesStateCompaniesState=='R') && countryOfOrigin==1 && row.servDocTypeId==1" data-toggle="modal" data-target="#VisuraModal" class="buttonR" >i</button> -->
                          </td>
                          </template>

                          <template v-if="getUserRole=='BOF' ">
                          <td  >
                            <a v-if="row.servDocTypeId==1 || row.servDocTypeId==8 || row.servDocTypeId==9" class="nav-link" :href="remoteServer + '/docs/doc_imprese/' + row.name" target="_blank"  >{{row.description2}}</a>
                            <a v-if="row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==7 || row.servDocTypeId==10" class="nav-link" :href="remoteServer + '/docs/doc_persone/' + row.name" target="_blank"  >{{row.description2}}</a>
                            <a v-if="row.servDocTypeId==4 || row.servDocTypeId==5 || row.servDocTypeId==6 " class="nav-link" :href="remoteServer + '/docs/media/' + row.name" target="_blank"  >{{row.description2}}</a>
                          </td>
                          
                          <td   >
                            {{ row.description3 }} 
                          </td>
                          <td  :key="row.servDocTypeId" >
                            <select class="form-control" v-if="lang=='Ita'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                            <template v-for="servDocTypeId in dropCompaniesDocs " >
                                <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeIta']}}</option>
                            </template>
                            </select>
                            <select class="form-control" v-if="lang=='Eng'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                                <template v-for="servDocTypeId in dropCompaniesDocs ">
                                  <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeEng']}}</option>
                                </template>
                            </select>
                            <select class="form-control" v-if="lang=='Den'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                                <template v-for="servDocTypeId in dropCompaniesDocs ">
                                    <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeDen']}}</option>
                                </template>
                            </select>
                          </td>
                          <td  :key="row.updateddAt">
                            <!-- documenti azienda per BO -->
                            <button type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.description3, row.servDocTypeId)" ><span class="fa fa-file-pdf-o" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                            <div class="row">
                            <!-- <button v-if="row.servDocTypeId!=1 && row.servDocTypeId!=2 && row.servDocTypeId!=3 && row.servDocTypeId!=8 " type="button" class="btn btn-success" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.servDocTypeId)" >{{searchevents(labels,'table','modifydoc')}}</button> -->
                            </div>
                          </td>
                          
                          <td  :key="row.createdAt">
                            <button type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)"  ><span class="fa fa-trash"  style="font-size:25px;color: rgb(221, 29, 0);width: 100%;" ></span></button>
                            <!-- <button v-if="row.servDocTypeId!=1 && row.servDocTypeId!=2 && row.servDocTypeId!=3 && row.servDocTypeId!=8" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id)" :key="row.id" >{{searchevents(labels,'menu','delete')}}</button> -->
                          </td>
                          <td  :key="row.updateddAt">
                            <!-- <button v-if="countryOfOrigin==1 && row.servDocTypeId==1" data-toggle="modal" data-target="#VisuraModal" class="buttonR" >i</button> -->
                          </td>
                          </template>
                        </tr>
                      </tbody>


                    </table>
                    
                  </template>
                </div>
              </div>

        </div>
      </div> <!-- END DOCS -->
      <div class="form-group"> <!-- DOCS -->
        <div class="container tablelist" v-if="this.id!=null">
          <div>
            <h3>{{searchevents(this.labels,'menu','companydocsuser')}}</h3> 
          </div>

              <div class="row">
                <div class="col">
                  <template >
                    
                    <table class="table tablelist" >
                      <!--
                      <thead>
                        <tr>
                          <th>{{searchevents(this.labels,'table','docview')}}</th>
                          <th>{{searchevents(this.labels,'table','docdescription')}}</th>
                          <th>{{searchevents(this.labels,'table','doctype')}}</th>
                          <th>{{searchevents(this.labels,'table','modifydoc')}}</th>
                          <th>{{searchevents(this.labels,'menu','delete')}}</th>
                          
                        </tr>
                      </thead>
                      -->
                      <tbody >
                        <template v-if="this.countryOfResidence==1">
                        <tr v-for="row in docListStep1b" :key="row.id" >
                          <template v-if="(getUserRole!='BOF' && (row.servDocTypeId!='' && row.servDocTypeId==servDocTypeIdLR ) ) ">
                          <td  >
                            <a v-if="row.servDocTypeId==1 || row.servDocTypeId==8 || row.servDocTypeId==9" class="nav-link" :href="remoteServer + '/docs/doc_imprese/' + row.name" target="_blank"  >{{row.description2}}</a>
                            <a v-if="row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==7 || row.servDocTypeId==10" class="nav-link" :href="remoteServer + '/docs/doc_persone/' + row.name" target="_blank"  >{{row.description2}}</a>
                            <a v-if="row.servDocTypeId==4 || row.servDocTypeId==5 || row.servDocTypeId==6 " class="nav-link" :href="remoteServer + '/docs/media/' + row.name" target="_blank"  >{{row.description2}}</a>
                          </td>
                          <!--
                          <td   >
                            {{viewDocTypeDesc(row.servDocTypeId)}}
                          </td>
                          -->
                          <td  class="noarrow">
                            <select class="form-control" v-if="lang=='Ita'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                            <template v-for="servDocTypeId in dropCompaniesDocs ">
                                <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeIta']}}</option>
                            </template>
                            </select>
                            <select class="form-control" v-if="lang=='Eng'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                                <template v-for="servDocTypeId in dropCompaniesDocs ">
                                    <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeEng']}}</option>
                                </template>
                            </select>
                            <select class="form-control" v-if="lang=='Den'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                                <template v-for="servDocTypeId in dropCompaniesDocs ">
                                    <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeDen']}}</option>
                                </template>
                            </select>
                          </td>

                          <!--
                          <td   >
                            {{ row.description3 }}
                          </td>
                          
                          <td  :key="row.servDocTypeId" class="noarrow" >
                            <select class="form-control" v-if="lang=='Ita'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                            <template v-for="servDocTypeId in dropCompaniesDocs ">
                                <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeIta']}}</option>
                            </template>
                            </select>
                            <select class="form-control" v-if="lang=='Eng'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                                <template v-for="servDocTypeId in dropCompaniesDocs ">
                                    <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeEng']}}</option>
                                </template>
                            </select>
                          </td>
                          -->
                          <td  >
                            <button v-if="servCompaniesStateCompaniesState=='R' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==7 || row.servDocTypeId==9 || row.servDocTypeId==10)" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.description3, row.servDocTypeId)" ><span class="fa fa-file-pdf-o" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                            <button v-if="servCompaniesStateCompaniesState!='R' && getUserRole=='BOF'" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.servDocTypeId)" ><span class="fa fa-file-pdf-o" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                            
                          </td>
                          <td  >
                            <button v-if="servCompaniesStateCompaniesState=='R' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==7 || row.servDocTypeId==9 || row.servDocTypeId==10)" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" :key="row.id" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                            <button v-if="servCompaniesStateCompaniesState!='R' && getUserRole=='BOF'" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" :key="row.id" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                          </td>
                          </template>

                          <template v-if="getUserRole=='BOF' ">
                          <td  >
                            <a v-if="row.servDocTypeId==1 || row.servDocTypeId==8 || row.servDocTypeId==9" class="nav-link" :href="remoteServer + '/docs/doc_imprese/' + row.name" target="_blank"  >{{row.description2}}</a>
                            <a v-if="row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==7 || row.servDocTypeId==10" class="nav-link" :href="remoteServer + '/docs/doc_persone/' + row.name" target="_blank"  >{{row.description2}}</a>
                            <a v-if="row.servDocTypeId==4 || row.servDocTypeId==5 || row.servDocTypeId==6 " class="nav-link" :href="remoteServer + '/docs/media/' + row.name" target="_blank"  >{{row.description2}}</a>
                          </td>
                          <td  v-if="(getUserRole=='BOF')" :class="ritornaLaClasse(row.servDocTypeId)">
                            {{viewDocTypeDesc(row.servDocTypeId)}}
                          </td>
                          <td   class="noarrow">
                            <select class="form-control" v-if="lang=='Ita'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                            <template v-for="servDocTypeId in dropCompaniesDocs ">
                                <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeIta']}}</option>
                            </template>
                            </select>
                            <select class="form-control" v-if="lang=='Eng'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                                <template v-for="servDocTypeId in dropCompaniesDocs ">
                                    <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeEng']}}</option>
                                </template>
                            </select>
                            <select class="form-control" v-if="lang=='Den'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                                <template v-for="servDocTypeId in dropCompaniesDocs ">
                                    <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeDen']}}</option>
                                </template>
                            </select>
                          </td>
                          <td  >
                            <button v-if="servCompaniesStateCompaniesState=='R' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==7 || row.servDocTypeId==9 || row.servDocTypeId==10)" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.description3, row.servDocTypeId)" ><span class="fa fa-file-pdf-o" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                            <button v-if="servCompaniesStateCompaniesState!='R' && getUserRole=='BOF'" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.servDocTypeId)" ><span class="fa fa-file-pdf-o" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                            
                          </td>
                          <td  >
                            <button v-if="servCompaniesStateCompaniesState=='R' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==7 || row.servDocTypeId==9 || row.servDocTypeId==10)" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" :key="row.id" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                            <button v-if="servCompaniesStateCompaniesState!='R' && getUserRole=='BOF'" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" :key="row.id" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                          </td>
                          </template>
                        </tr>
                        </template>

                        <template v-if="this.countryOfResidence!=1">
                        <tr v-for="row in docListStep1bEng" :key="row.id" >
                          <template v-if="(getUserRole!='BOF' && (row.servDocTypeId!='' && row.servDocTypeId==servDocTypeIdLR ) ) ">
                          <td  >
                            <a v-if="row.servDocTypeId==1 || row.servDocTypeId==8 || row.servDocTypeId==9" class="nav-link" :href="remoteServer + '/docs/doc_imprese/' + row.name" target="_blank"  >{{row.description2}}</a>
                            <a v-if="row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==7 || row.servDocTypeId==10" class="nav-link" :href="remoteServer + '/docs/doc_persone/' + row.name" target="_blank"  >{{row.description2}}</a>
                            <a v-if="row.servDocTypeId==4 || row.servDocTypeId==5 || row.servDocTypeId==6 " class="nav-link" :href="remoteServer + '/docs/media/' + row.name" target="_blank"  >{{row.description2}}</a>
                          </td>
                          <td  v-if="(getUserRole=='BOF')" :class="ritornaLaClasse(row.servDocTypeId)">
                            {{viewDocTypeDesc(row.servDocTypeId)}}
                          </td>
                          <td   class="noarrow">
                            <select class="form-control" v-if="lang=='Ita'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                            <template v-for="servDocTypeId in dropCompaniesDocs ">
                                <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeIta']}}</option>
                            </template>
                            </select>
                            <select class="form-control" v-if="lang=='Eng'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                                <template v-for="servDocTypeId in dropCompaniesDocs ">
                                    <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeEng']}}</option>
                                </template>
                            </select>
                            <select class="form-control" v-if="lang=='Den'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                                <template v-for="servDocTypeId in dropCompaniesDocs ">
                                    <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeDen']}}</option>
                                </template>
                            </select>
                          </td>
                          <td  >
                            <button v-if="servCompaniesStateCompaniesState=='R' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==9 || row.servDocTypeId==10)" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.description3, row.servDocTypeId)" ><span class="fa fa-file-pdf-o" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                            <button v-if="servCompaniesStateCompaniesState!='R' && getUserRole=='BOF'" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.servDocTypeId)" ><span class="fa fa-file-pdf-o" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                            
                          </td>
                          <td  >
                            <button v-if="servCompaniesStateCompaniesState=='R' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==9 || row.servDocTypeId==10)" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" :key="row.id" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                            <button v-if="servCompaniesStateCompaniesState!='R' && getUserRole=='BOF'" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" :key="row.id" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                          </td>
                          </template>
                          <template v-if="getUserRole=='BOF' ">
                          <td  >
                            <a v-if="row.servDocTypeId==1 || row.servDocTypeId==8 || row.servDocTypeId==9" class="nav-link" :href="remoteServer + '/docs/doc_imprese/' + row.name" target="_blank"  >{{row.description2}}</a>
                            <a v-if="row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==7 || row.servDocTypeId==10" class="nav-link" :href="remoteServer + '/docs/doc_persone/' + row.name" target="_blank"  >{{row.description2}}</a>
                            <a v-if="row.servDocTypeId==4 || row.servDocTypeId==5 || row.servDocTypeId==6 " class="nav-link" :href="remoteServer + '/docs/media/' + row.name" target="_blank"  >{{row.description2}}</a>
                          </td>
                          <td  :key="row.description" :class="ritornaLaClasse(row.servDocTypeId)">
                            {{viewDocTypeDesc(row.servDocTypeId)}}
                          </td>
                          <td   class="noarrow">
                            <select class="form-control" v-if="lang=='Ita'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                            <template v-for="servDocTypeId in dropCompaniesDocs ">
                                <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeIta']}}</option>
                            </template>
                            </select>
                            <select class="form-control" v-if="lang=='Eng'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                                <template v-for="servDocTypeId in dropCompaniesDocs ">
                                    <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeEng']}}</option>
                                </template>
                            </select>
                            <select class="form-control" v-if="lang=='Den'" v-model="row.servDocTypeId" :disabled='isNewInsert'>
                                <template v-for="servDocTypeId in dropCompaniesDocs ">
                                    <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeDen']}}</option>
                                </template>
                            </select>
                          </td>
                          <td  >
                            <button v-if="servCompaniesStateCompaniesState=='R' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==9 || row.servDocTypeId==10)" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2,row.description3, row.servDocTypeId)" ><span class="fa fa-file-pdf-o" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                            <button v-if="servCompaniesStateCompaniesState!='R' && getUserRole=='BOF'" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.servDocTypeId)" ><span class="fa fa-file-pdf-o" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                            
                          </td>
                          <td  >
                            <button v-if="servCompaniesStateCompaniesState=='R' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==9 || row.servDocTypeId==10)" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" :key="row.id" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                            <button v-if="servCompaniesStateCompaniesState!='R' && getUserRole=='BOF'" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" :key="row.id" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                          </td>
                          </template>
                        </tr>
                        </template>
                      </tbody>


                    </table>
                    
                  </template>
                </div>
              </div>

        </div>
      </div> <!-- END DOCS -->



  <!-- INIZIO SEZIONE DEL BO - NO UTENTE -->
   <div v-if="getUserRole=='BOF'">
     <hr> 
      <div class="form-group">
        <h3>{{searchevents(this.labels,'companies','registerelegalrepresentativelabel')}}</h3><br>
          <div class="row">
              <div class="col-sm">
                  <label for="officeRegion" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','name')}}</label>
                  <input class="form-control" type="officeRegion" id="name" v-model="name" required :disabled='isNewInsert'>
              </div>
              <div class="col-sm">
                  <label for="officeDistrict" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','surname')}}</label>
                  <input class="form-control" type="officeDistrict" id="surname" v-model="surname" required :disabled='isNewInsert'>           
              </div>
              <div class="col-sm">
                  <label for="officeDistrict" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','sex')}}</label>
                  <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" v-model="sex" required>
                        <option value=""></option>
                        <option value="M">M</option>
                        <option value="F">F</option>
                </select>
              </div>
          </div>
      </div>
      <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="countryOfResidence" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','countryOfResidence')}}</label>
                <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" v-model="countryOfResidence" required :disabled='isDisabled'>
                    <template v-for="countryOfResidence in this.dropMofficeRegion ">
                        <option v-for="(value, key) in countryOfResidence" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                    </template>
                </select>
              </div>
              <div class="col-sm">
                <label for="birthPlace" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','birthPlace')}}</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="birthPlace" id="birthPlace" v-model="birthPlace" required>
              </div>
              <div class="col-sm">
                <label for="lastInspectionDate" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','birthDate')}}</label>
                <!-- stefano 09/03/2022
                <datepicker v-if="this.lang=='Ita'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="false" :language="birthDateIta" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required></datepicker>
                <datepicker v-if="this.lang=='Eng'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="false" :language="birthDateEng" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required></datepicker>
                <datepicker v-if="this.lang=='Den'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="false" :language="birthDateDen" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required></datepicker>
                -->

                <br>
                <input style="text-align: center; margin-left:25%;margin-right:20%" type="date" v-model="birthDate" required />  

                <input type="hidden" id="userIdLR" name="userIdLR" v-model="userIdLR">
                <input type="hidden" id="servCompaniesStateCompaniesState" name="servCompaniesStateCompaniesState" v-model="servCompaniesStateCompaniesState">
              </div>
         </div>
      </div>
      <!--
      <div class="form-group">
         <div class="row">
               <div class="col-sm" style="max-width: 35%;">
                  <label for="servMobilePrefixId">{{searchevents(this.labels,'table','doctype')}}</label>
                    <select class="form-control" v-if="this.countryOfResidence==1 && this.countryOfResidence!=''"> v-model="servDocTypeId" required>
                      <template v-for="servDocTypeId in dropCompaniesDocsC " >
                          <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeIta']}}</option>
                      </template>
                    </select>
                    <select class="form-control" v-if="this.countryOfResidence!=1 && this.countryOfResidence!=''"> v-model="servDocTypeId" disabled>
                        <template v-for="servDocTypeId in dropCompaniesDocsAllC ">
                            <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeEng']}}</option>
                        </template>
                    </select>
                  </div>
              <div class="col-sm">
                <label for="documentNumber">{{searchevents(this.labels,'users','documentNumber')}}</label>
                <input class="form-control" type="documentNumber" id="documentNumber" v-model="documentNumber" required>
              </div>
         </div>
      </div>
      -->
        <!-- inizio sezione anagrafica BO LEGALE RAPPRES ### -->
      <div class="form-group">
         <div class="row">
              <div class="col-sm" v-if="getUserRole=='BOF'">
                <label for="countryOfResidence" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','email')}}</label>
                 <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" id="emailLR" v-model="emailLR" required >
              </div>
              <div class="col-sm" v-if="this.countryOfResidence==1 && this.countryOfResidence!=''">
                <label for="pec" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','fiscalCode')}}</label>
                <label v-if="italianFCInorrect=='X' && getUserRole=='BOF'" for="emailAddress" class="fiscalCodeIncorrect">codice fiscale risultato NON corretto</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="fiscalCode" id="fiscalCode" @change="resetAtecoCode6()" :maxlength="16" :minlength="16" v-model="fiscalCode" required >
              </div>
              <div class="col-sm" v-if="this.countryOfResidence!=1 && this.countryOfResidence!=''">
                <label for="emailAddress" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','fiscalCodeExt')}}</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="fiscalCode" id="fiscalCode" v-model="fiscalCode" required>
              </div>
              <div class="col-sm" v-if="this.countryOfResidence==''">
                <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >----</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="fiscalCode" id="fiscalCode" readonly>
              </div>
         </div>
      </div>

      <div class="form-group">
         <div class="row">
           <div class="col-sm" style="max-width: 35%;">
                  <label for="servMobilePrefixId" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'table','doctype')}}</label>
                    <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" v-if="this.countryOfResidence==1 && this.countryOfResidence!=''" v-model="servDocTypeIdLR" required :disabled='isDisabled'>
                      <template v-for="servDocTypeId in dropCompaniesDocs " >
                          <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeIta']}}</option>
                      </template>
                    </select>
                    <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" v-if="this.countryOfResidence!=1 && this.countryOfResidence!=''" v-model="servDocTypeIdLR" required :disabled='isDisabled'>
                        <template v-for="servDocTypeId in dropCompaniesDocs ">
                            <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeEng']}}</option>
                        </template>
                    </select>
                    
                  </div>
                  <div class="col-sm">
                    <label for="documentNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','documentNumber')}}</label>
                    <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" type="documentNumber" id="documentNumber" v-model="documentNumber" required :disabled='isDisabled'>
                  </div>
         </div>
      </div>
      <!-- LINK DOCUMENTI RUBATI SMARRITI -->
                <div class="col-12" v-if="this.countryOfResidence==1 && this.countryOfResidence!=''">
                <a class="nav-link" style="padding: 0px;margin-left:8%" :href="searchevents(this.labels,'http','lostIDcardIT')" target="_blank"><h6><u>Link per Controllo Documenti rubati IT</u></h6> </a>
                </div>
                <div class="col-12" v-if="this.countryOfResidence!=1 || this.countryOfResidence==''">
                <a class="nav-link" style="padding: 0px;margin-left:8%" :href="searchevents(this.labels,'http','lostIDcardEU')" target="_blank"><h6><u>Link a PDF con link Controllo Documenti rubati EU</u></h6> </a>
                </div>
     <button  v-if="this.id!=null && getUserRole=='BOF'" type="submit" class="btn btn-success" style="margin-bottom:55px;margin-top:15px;font-size: 1.7em;">{{searchevents(this.labels,'controls','saveandcontinuebof')}}</button>
       
  <!-- PROSECUZIONE SEZIONE DEL BO - NO UTENTE -->
    <!-- inizio sezione cruscotto BO LEGALE RAPPRES ### -->
        <br>
       <hr>
       <h3><strong> Cruscotto azioni su Dati LEGALE RAPPRESENTANTE</strong></h3>
       <br>

      <div class="form-group" v-if="getUserRole=='BOF'">
         <div class="row">
             <div class="col-sm">
                <label style="margin-right:40px; " for="companyNumber" class="requiredModX">Esito verifica dati LR</label>
                  <select class="form-control" v-model="userSuccessfulBO" style="margin-left:160px;  width: 300px;" @change="actionUserBO('userSuccessfulBO')">
                      <option value="S">Non lavorato da BO</option>
                      <option value="P">In lavorazione</option>
                      <option value="J">Rifiutata</option>
                      <option value="A">Approvata</option>  
                  </select>
              </div>
              <div class="col-sm" >
                <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredlight']" >Invio nuova richiesta di approvazione email.</label>
                <br>
                <button  type="button" class="btn btn-danger" @click="sendEmailBO('LR-remember-bo','viia')" style="font-size: 1em;margin-bottom:55px;margin-top:3px; min-height:50px; max-width: 330px;">{{searchevents(this.labels,'bof','viiaLR')}}</button>
              </div>
         </div>
         <div class="row">
                         <div class="col-sm">
                <label  style="margin-right: 20px;" for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredlight']" >Variazione Email LR</label>
                <!-- <input class="form-control" type="checkbox" id="userEmailBO" value="userEmailBO" v-model="userEmailBO" @change="actionUserBO('userEmailBO')" > -->
                <br>
                <button type="button" class="btn btn-recyclebo" data-toggle="modal" data-target="#myModalEmailLR" style="font-size: 1em; margin-top: 3px; margin-right: 10px; min-height:50px;max-width: 260px;" >{{searchevents(this.labels,'bof','emaillrresent')}}</button>
              </div>
 
              <div class="col-sm" >
                <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredlight']" >Altre comunicazioni al LR  - invio a mail LR (usare lingua LR):</label><br>
                <!-- <button  type="button" class="btn btn-info" @click="sendEmailBO('email-richiesta-info-integrativa','viib2')" style="font-size: .9em;margin-bottom:0px;margin-top:15px">{{searchevents(this.labels,'bof','viib2')}}</button> -->
                <!-- <button  type="button" class="btn btn-danger" @click="sendEmailBO('email-rifiuto-attivazione','end2c')" style="font-size: .9em;margin-bottom:0px;margin-top:15px">{{searchevents(this.labels,'bof','end2c')}}</button> -->
                <button type="button" class="btn btn-recyclebo" style="font-size: 1em; margin-top: 3px; min-height:50px;max-width: 260px;" data-toggle="modal" data-target="#myModalInfoIntegrative" >{{searchevents(this.labels,'bof','viib2')}}</button>
                
              </div>
         </div>
         
       </div>


   </div>  
     <br>
 
<!--fine sezione solo BO NO UTENTE-->





      <!-- STE   DATI AZIENDA-->
 



 

      <hr>
      <div class="form-group ">
        <h3>{{searchevents(this.labels,'menu','companydataregistering')}}</h3><br>
    
        <div class="row">
          <div class="col-sm">
            <label for="companyName" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','companyNameFull')}}</label>
            <input class="form-control" type="companyName" id="companyName" v-model="companyName" :disabled='isNewInsert'>
          </div>
          <div class="col-sm" style="max-width: 250px;" >
              <label for="countryOfOrigin" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']"  >*{{searchevents(this.labels,'companies','countryOfOrigin')}}</label>
                <select class="form-control" name="countryOfOrigin" v-model="countryOfOrigin" required @change="prefillVatNumber()" :disabled='isNewInsert'>
                    <template v-for="countryOfOrigin in this.dropMofficeRegionRegister ">
                        <option v-for="(value, key) in countryOfOrigin" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                    </template>
                </select>
          </div>

          <!-- idTax = codice fiscale, mentre vatNumber = PIVA -->
          <!--
          <div class="col-sm" v-if="this.countryOfOrigin==1 && this.countryOfOrigin!=''">
            <label for="idTax" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'companies','idTax')}}</label>
            <input class="form-control" type="idTax" id="idTax" v-model="idTax" required>
          </div>
          -->
          
          <div class="col-sm" v-if="this.countryOfOrigin==2 && this.countryOfOrigin!=''">
            <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*Company Number</label>
            <input class="form-control" type="companyNumber" id="companyNumber" v-model="companyNumber" :disabled='isNewInsert'>
          </div>

          <div class="col-sm" v-if="this.countryOfOrigin==1 && this.countryOfOrigin!=''">
            <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*Codice Fiscale Impresa</label>
            <input class="form-control" type="companyNumber" id="companyNumber" :maxlength="16" :minlength="10" v-model="companyNumber" :disabled='isNewInsert'>
          </div>

          <div class="col-sm" v-if="this.countryOfOrigin==504 && this.countryOfOrigin!=''">
            <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*Firmanummer</label>
            <input class="form-control" type="companyNumber" id="companyNumber" v-model="companyNumber" :disabled='isNewInsert'>
          </div>
           <div class="col-sm" v-if="this.countryOfOrigin!=504 && this.countryOfOrigin!=1 && this.countryOfOrigin!=2">
            <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*Company Identification Number</label>
            <input class="form-control" type="companyNumber" id="companyNumber" v-model="companyNumber" :disabled='isNewInsert'>
          </div>

          <!--
          <div class="col-sm" >
            <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'companies','companyNumber')}}</label>  
                
            <div class="row">
                <div class="col-sm">
                  <input class="form-control" style="padding-left: 0px;" type="companyNumber" id="companyNumber" v-model="companyNumber" required>
                </div>
            </div>
          </div>
          -->

          <div class="col-sm" v-if="this.countryOfOrigin==''">
            <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >----</label>
            <input class="form-control" type="companyNumberxx" id="companyNumberxx" readonly>
          </div>


          <div class="col-sm" v-if="this.countryOfOrigin==1 || this.countryOfOrigin==2 || this.countryOfOrigin==504 || this.countryOfOrigin==''">
                <label for="servCompaniesCategoryId" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','servCompaniesCategoryId')}}</label>
                
                <select class="form-control" ref="servCompaniesCategoryId" v-if="this.countryOfOrigin==1 && this.countryOfOrigin!=''" v-model="servCompaniesCategoryId" :disabled='isNewInsert'>
                    <template v-for="servCompaniesCategoryId in this.dropsCompaniesCategoryIT " >
                        <option v-for="(value, key) in servCompaniesCategoryId" :value="value['id']" :key="key" >{{value['categoryIta']}}</option>
                    </template>
                </select>
                <select class="form-control" ref="servCompaniesCategoryId" v-if="this.countryOfOrigin==2 && this.countryOfOrigin!=''" v-model="servCompaniesCategoryId" :disabled='isNewInsert'>
                    <template v-for="servCompaniesCategoryId in this.dropsCompaniesCategoryEN ">
                        <option v-for="(value, key) in servCompaniesCategoryId" :value="value['id']" :key="key" >{{value['categoryEng']}}</option>
                    </template>
                </select>
                <select class="form-control" ref="servCompaniesCategoryId" v-if="this.countryOfOrigin==504 && this.countryOfOrigin!=''" v-model="servCompaniesCategoryId" :disabled='isNewInsert'>
                    <template v-for="servCompaniesCategoryId in this.dropsCompaniesCategoryDE ">
                        <option v-for="(value, key) in servCompaniesCategoryId" :value="value['id']" :key="key" >{{value['categoryDen']}}</option>
                    </template>
                </select>

              </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-sm" v-if="this.countryOfOrigin==1">
              <label for="pec" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','pec')}}</label>
              <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" type="email" name="pec" id="pec" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" v-model="pec" required :disabled='isDisabledMail'>
          </div>
          <div class="col-sm" v-if="this.countryOfOrigin!=1">
            <label for="emailAddress" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','emailAddress')}}</label>
            <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" name="emailAddress" id="emailAddress" v-model="emailAddress" required :disabled='isDisabledMail'>
          </div>
          <div class="col-sm">
            <!-- campo partita iva -->
            <label for="vatNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','vatNumber')}}</label>
            <div class="row">
              <div class="col-3">
                <select class="form-control " name="companyNumberCountry" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" v-model="companyNumberCountry" required style="width: auto;" >
                      <option value="">--</option>
                      <option value="AT">AT</option>
                      <option value="BE">BE</option>
                      <option value="BG">BG</option>
                      <option value="CY">CY</option>
                      <option value="CZ">CZ</option>
                      <option value="DE">DE</option>
                      <option value="DK">DK</option>
                      <option value="EE">EE</option>
                      <option value="EL">EL</option>
                      <option value="ES">ES</option>
                      <option value="FI">FI</option>
                      <option value="FR">FR</option>
                      <option value="GB">GB</option>
                      <option value="HR">HR</option>
                      <option value="HU">HU</option>
                      <option value="IE">IE</option>
                      <option value="IT">IT</option>
                      <option value="LT">LT</option>
                      <option value="LU">LU</option>
                      <option value="LV">LV</option>
                      <option value="MT">MT</option>
                      <option value="NL">NL</option>
                      <option value="NO">NO</option>
                      <option value="PL">PL</option>
                      <option value="PT">PT</option>
                      <option value="RO">RO</option>
                      <option value="SE">SE</option>
                      <option value="SI">SI</option>
                      <option value="SK">SK</option>     
                    </select>
              </div>
              <div class="col-sm">
                  <!-- da abilitare solo per aziende R -->
                  <input v-if="this.companyNumberCountry=='IT'" class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="vatNumber" id="vatNumber"  v-model="vatNumber" >
                  <input v-if="this.companyNumberCountry!='IT'" class="form-control "  v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="vatNumber" id="vatNumber" v-model="vatNumber" >
                  <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="hidden" id="companyNumberCountry" v-model="companyNumberCountry" :disabled='isNewInsert'>
              </div>
              <div class="col-sm" style="padding: 0px; max-width: 100px;">
                
                <button v-if="this.countryOfOrigin==1 && this.countryOfOrigin!=''" type="button" class="btn btn-success " style="font-size: small;" @click="sameCF()">{{searchevents(this.labels,'dialog','sameascompanyuniquetaxpayer')}} {{sameCFcountryOfOrigin()}}</button>
                <button v-if="this.countryOfOrigin==2 && this.countryOfOrigin!=''" type="button" class="btn btn-success " style="font-size: small;" @click="sameCF()">{{searchevents(this.labels,'dialog','sameascompanyuniquetaxpayer')}} {{sameCFcountryOfOrigin()}}</button>
                <button v-if="this.countryOfOrigin==504 && this.countryOfOrigin!=''" type="button" class="btn btn-success " style="font-size: small;" @click="sameCF()">{{searchevents(this.labels,'dialog','sameascompanyuniquetaxpayer')}} {{sameCFcountryOfOrigin()}}</button>
                <button v-if="this.countryOfOrigin!=1 && this.countryOfOrigin!=2 && this.countryOfOrigin!=504" type="button" class="btn btn-success " style="font-size: small;" @click="sameCF()">{{searchevents(this.labels,'dialog','sameascompanyuniquetaxpayer')}} {{sameCFcountryOfOrigin()}}</button>
                
                <!-- 
                  <button type="button" class="btn btn-success " style="font-size: small;" @click="sameCF()">{{searchevents(this.labels,'dialog','sameascompanyuniquetaxpayer')}} {{sameCFcountryOfOrigin()}}</button>
                  <button v-if="this.countryOfOrigin==2" type="button" class="btn btn-success " style="font-size: small;" @click="sameCF()">{{searchevents(this.labels,'companies','copyvatnumberEng')}}</button>
                  <button v-if="this.countryOfOrigin==504" type="button" class="btn btn-success " style="font-size: small;" @click="sameCF()">{{searchevents(this.labels,'companies','copyvatnumberDen')}}</button>
                -->
              </div>
            </div>
            
            
          </div>
          
        </div>
        <div class="row" style="margin-top:15px;">
              <div class="col-sm">
                <label for="servCompaniesEmployeesNumId" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','servCompaniesEmployeesNumId')}}</label>
                <select class="form-control " name="servCompaniesEmployeesNumId" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" v-model="servCompaniesEmployeesNumId" required >
                    <template v-for="servCompaniesEmployeesNumId in this.dropCompaniesEmployees ">
                        <option v-for="(value, key) in servCompaniesEmployeesNumId" :value="value['id']" :key="key" >{{value['employeesNumber']}}</option>
                    </template>
                </select>
              </div>
              <div class="col-sm">
                <label for="servCompaniesEmployeesYear" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'dialog','datacollectionyear')}}</label>
                <select class="form-control " name="servCompaniesEmployeesYear" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" v-model="servCompaniesEmployeesYear" required >
                    <!-- <template v-for="item in 15"> -->
                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                    <!-- </template> -->
                </select>
              </div>
              <div class="col-sm">
              </div>
            </div>
      </div>


      <!-- sede legale - in comune tra utente e BO -->  
      <hr>
      <div class="form-group">
        <h3>{{searchevents(this.labels,'companies','registeredofficelabel')}}</h3><br>
          <div class="row">
              <div class="col-sm">
                  <label for="officeRegion" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','officeRegion')}}</label>

                  <select class="form-control" name="officeRegion" v-model="officeRegion" required >
                      <template v-for="countryOfOrigin in this.dropMofficeRegion ">
                          <option v-for="(value, key) in countryOfOrigin" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                      </template>
                  </select>
              </div>
              <div class="col-sm">
                  <label for="officeDistrict" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','officeDistrict')}}</label>
                  <!--  old <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="officeDistrict" id="officeDistrict" v-model="officeDistrict" required> -->
                  <input  v-if="this.officeRegion!=1 && this.officeRegion!=2 && this.officeRegion!=651 && this.officeRegion!=652 && this.officeRegion!=653 && this.officeRegion!=654" class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="officeDistrict" id="officeDistrict" v-model="officeDistrict" required>
                  <select v-if="this.officeRegion==1" class="form-control" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="officeDistrict" id="officeDistrict" v-model="officeDistrict" required>   
                      <option value=""></option>
                      <option value="Abruzzo">Abruzzo</option>
                      <option value="Basilicata">Basilicata</option>
                      <option value="Calabria">Calabria</option>
                      <option value="Campania">Campania</option>
                      <option value="Emilia-Romagna">Emilia-Romagna</option>
                      <option value="Friuli-Venezia Giulia">Friuli-Venezia Giulia</option>
                      <option value="Lazio">Lazio</option>
                      <option value="Liguria">Liguria</option>
                      <option value="Lombardia">Lombardia</option>
                      <option value="Marche">Marche</option>
                      <option value="Molise">Molise</option>
                      <option value="Piemonte">Piemonte</option>
                      <option value="Puglia">Puglia</option>
                      <option value="Sardegna">Sardegna</option>
                      <option value="Sicilia">Sicilia</option>
                      <option value="Toscana">Toscana</option>
                      <option value="Trentino-Alto Adige/Südtirol">Trentino-Alto Adige/Südtirol</option>
                      <option value="Umbria">Umbria</option>
                      <option value="Valle d'Aosta/Vallée d'Aoste-Valle d'Aosta/Vallée d'Aoste">Valle d'Aosta/Vallée d'Aoste-Valle d'Aosta/Vallée d'Aoste</option>
                      <option value="Veneto">Veneto</option>
                    </select>
                    <select v-if="this.officeRegion==2" class="form-control" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="officeDistrict" id="officeDistrict" v-model="officeDistrict" required>   
                      <option value=""></option>
                      <option value="ENGLAND">England</option>
                      <option value="SCOTLAND">Scotland</option>
                      <option value="WALES">Wales</option>
                      <option value="NORTHERN IRELAND">Northern Ireland</option>
                    </select>
                     <select v-if="this.officeRegion==652" class="form-control" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="officeDistrict" id="officeDistrict" v-model="officeDistrict" required>   
                      <option value="ENGLAND">England</option>
                      </select>
                    <select v-if="this.officeRegion==651" class="form-control" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="officeDistrict" id="officeDistrict" v-model="officeDistrict" required>   
                      <option value="SCOTLAND">Scotland</option>
                      </select>
                    <select v-if="this.officeRegion==654" class="form-control" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="officeDistrict" id="officeDistrict" v-model="officeDistrict" required>   
                      <option value="WALES">Wales</option>
                       </select>
                        <select v-if="this.officeRegion==653" class="form-control" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="officeDistrict" id="officeDistrict" v-model="officeDistrict" required>   
                      <option value="NORTHERN IRELAND">Northern Ireland</option>
                       </select>

              </div>

          </div>
      </div>
      
      <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="officeCity" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','officeCity')}}</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="officeCity" id="officeCity" v-model="officeCity" required>
              </div>
              <div class="col-sm">
                <label for="officeAddress" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','officeAddress')}}</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="officeAddress" id="officeAddress" v-model="officeAddress" required>
              </div>
         </div>
      </div>

      <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="officeTown" >{{searchevents(this.labels,'companies','officeTown')}}</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="officeTown" id="officeTown" v-model="officeTown" >
              </div>
              <div class="col-sm">
                <label for="officePostcode" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','officePostcode')}}</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="officePostcode" id="officePostcode" v-model="officePostcode" required>
              </div>
         </div>
      </div>

      <div class="form-goup">
          <div class="row">
            <div class="col-sm">
              <label for="vatNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'norequiredMod']" >{{searchevents(this.labels,'companies','servLandlinePrefixId')}}</label>
              <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" v-model="servLandlinePrefixId" >
                  <template v-for="servLandlinePrefixId in this.dropMservLandlinePrefixId" >
                      <option v-for="(value, key) in servLandlinePrefixId" :value="value['id']" :key="key" >{{value['prefixName']}} {{value['prefix']}}</option>
                  </template>
              </select>
            </div>
            <div class="col-sm">
              <label for="landlinePhone" v-bind:class="[this.getUserRole!='BOF' ? '' : 'norequiredMod']" >{{searchevents(this.labels,'companies','landlinePhone')}}</label> 
              <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" @paste="isNumber($event)" @keypress="isNumber($event)" type="landlinePhone" id="landlinePhone" pattern=".{6,}" v-model="landlinePhone" >
            </div>
          
            <div class="col-sm">
              <label for="vatNumber" class="norequiredMod" style="padding-bottom:0px">{{searchevents(this.labels,'companies','servMobilePrefixId')}}</label>
              <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" v-model="servMobilePrefixId" >
                  <template v-for="servMobilePrefixId in this.dropMservLandlinePrefixId">
                      <option v-for="(value, key) in servMobilePrefixId" :value="value['id']" :key="key">{{value['prefixName']}} {{value['prefix']}}</option>
                  </template>
              </select>
            </div>
            <div class="col-sm">
              <label for="mobilePhone" class="norequiredMod" style="padding-bottom:0px">{{searchevents(this.labels,'companies','mobilePhone')}}</label>
              <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" @paste="isNumber($event)" @keypress="isNumber($event)" type="mobilePhone" id="mobilePhone" pattern=".{6,}" v-model="mobilePhone" >
            </div>
          </div>
      </div>

       <!-- tasti verifica PEC solo aziende italiane solo BO -->

      <div class="form-group" v-if="getUserRole=='BOF'">
         <div class="row">
              <div class="col-sm" v-if="this.countryOfOrigin==1 && this.countryOfOrigin!=''">
                <!-- <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >Imposta PEC confermata/non confermata</label> -->
                <!-- <input class="form-control" type="checkbox" id="userContractBOz" v-model="userContractBO" @change="actionUserBO('userContractBO')" > -->
                <button type="button" class="btn btn-dark" data-toggle="modal" data-target="#myModalPEC"  style="font-size: .9em; margin-top: 5px;" >{{searchevents(this.labels,'bof','pecresent')}}</button>
                <br>
                <br>
                <a class="nav-link" style="padding: 0px;" :href="searchevents(this.labels,'http','pecchecklink')" target="_blank"><h6><u>Link per Controllo PEC su DB INI PEC</u></h6> </a>
              </div>
              <div class="col-sm" v-if="this.countryOfOrigin==1 && this.countryOfOrigin!=''">
                <!-- <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >Inoltro nuova richiesta di approvazione PEC.</label> -->
                <button  type="button" class="btn btn-danger" data-toggle="modal" data-target="#myModalPEC2"  style="font-size: .9em;margin-bottom:15px;margin-top:5px">{{searchevents(this.labels,'bof','viia')}}</button>
              </div>
         </div>
      </div>

      <button ref="bosaveandconfirm"  v-if="this.id!=null && getUserRole=='BOF'" type="submit" class="btn btn-success" style="margin-bottom:55px;margin-top:15px;font-size: 1.7em;">{{searchevents(this.labels,'controls','saveandcontinuebof')}}</button>
     

      <!-- settore di attività ITA ################################################################################################      ----->
      <hr>
      <div class="form-group" v-if="this.countryOfOrigin==1">
        <h3>{{searchevents(this.labels,'companies','registeredsetcoractivitylabel')}} </h3><br>
         <div class="row yellow-light" style="margin-top: 20px;">
              <div class="col-sm">
                <label v-if="this.countryOfOrigin!='504'" for="atecoCode1" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','atecoCode1')}}
                         <button type="button" data-toggle="button" class="btn-yellow-tooltip" v-tooltip="{content: searchevents(labels,'tooltipval','AtecoExplained'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                         <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#0c294b;width: 100%"></span>
                         </button> 
                </label>

                <label v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2'" for="atecoCode1" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','naceCode1')}}</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="atecoCode1" id="atecoCode1" v-model="atecoCode1"  data-target="#myModal"  required readonly>
              </div>
              <!--
              <div class="col-sm">
                <label for="naceCode1" >NACE '07</label>
                <input class="form-control" type="naceCode1" id="naceCode1" v-model="naceCode1" >
              </div>
              -->
              <div class="col-sm" style="padding-top: 0px;">
                <label v-if="this.countryOfOrigin=='1' || this.countryOfOrigin=='2'" for="sicCode1" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescription')}} </label>
                <label v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2'" for="sicCode1" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescriptionnace')}} </label><br>
                <div v-if="this.lang=='Ita'">{{this.filteredItemsDett(atecoCode1)}}</div>
                <div v-if="this.lang=='Eng'">{{this.filteredItemsDettEng(atecoCode1)}}</div>
                <div v-if="this.lang=='Den'">{{this.filteredItemsDettDen(atecoCode1)}}</div>
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em;" data-toggle="modal" data-target="#myModal" @click="setSelectedAteco(atecoCode1,'atecoCode1')">{{searchevents(this.labels,'menu','selectateco')}}</button>
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em; margin-left: 10px;" @click="atecoCode1=''">{{searchevents(this.labels,'menu','deleteateco')}}</button>

              </div>
         </div>
         <div class="row blu-dark2" style="margin-top: 20px;">
              <div class="col-sm">
                <label v-if="this.countryOfOrigin=='1' || this.countryOfOrigin=='2'" for="atecoCode2">{{searchevents(this.labels,'companies','atecoCode2')}}</label>
                <label v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2'" for="atecoCode2">{{searchevents(this.labels,'companies','naceCode2')}}</label>
                <input class="form-control" type="atecoCode2" id="atecoCode2" v-model="atecoCode2" disabled>
              </div>
              <!--
              <div class="col-sm">
                <label for="naceCode2" >NACE '07</label>
                <input class="form-control" type="naceCode2" id="naceCode2" v-model="naceCode2" >
              </div>
              -->
              <div class="col-sm" style="padding-top: 0px;">
                <label v-if="this.countryOfOrigin=='1' || this.countryOfOrigin=='2'" for="sicCode2" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescription')}} </label>
                <label v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2'" for="sicCode2" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescriptionnace')}} </label><br>
                <div v-if="this.lang=='Ita'">{{this.filteredItemsDett(atecoCode2)}}</div>
                <div v-if="this.lang=='Eng'">{{this.filteredItemsDettEng(atecoCode2)}}</div>
                <div v-if="this.lang=='Den'">{{this.filteredItemsDettDen(atecoCode2)}}</div>
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em;" data-toggle="modal" data-target="#myModal" @click="setSelectedAteco(atecoCode2,'atecoCode2')">{{searchevents(this.labels,'menu','selectateco')}}</button>
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em; margin-left: 10px;" @click="atecoCode2=null">{{searchevents(this.labels,'menu','deleteateco')}}</button>
              </div>
         </div>
      </div>
      <div class="form-group" v-if="this.countryOfOrigin==1">
         <div class="row yellow-light" style="margin-top: 20px;">
              <div class="col-sm">
                <label v-if="this.countryOfOrigin=='1' || this.countryOfOrigin=='2'" for="atecoCode3">{{searchevents(this.labels,'companies','atecoCode3')}}</label>
                <label v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2'" for="atecoCode3">{{searchevents(this.labels,'companies','naceCode3')}}</label>
                <input class="form-control" type="atecoCode3" id="atecoCode3" v-model="atecoCode3" disabled>
              </div>
              <!--
              <div class="col-sm">
                <label for="naceCode3" >NACE '07</label>
                <input class="form-control" type="naceCode3" id="naceCode3" v-model="naceCode3" >
              </div>
              -->
              <div class="col-sm" style="padding-top: 0px;">
                <label v-if="this.countryOfOrigin=='1' || this.countryOfOrigin=='2'" for="sicCode3" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescription')}} </label>
                <label v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2'" for="sicCode3" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescriptionnace')}} </label><br>
                <div v-if="this.lang=='Ita'">{{this.filteredItemsDett(atecoCode3)}}</div>
                <div v-if="this.lang=='Eng'">{{this.filteredItemsDettEng(atecoCode3)}}</div>
                <div v-if="this.lang=='Den'">{{this.filteredItemsDettDen(atecoCode3)}}</div>
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em;" data-toggle="modal" data-target="#myModal" @click="setSelectedAteco(atecoCode3,'atecoCode3')">{{searchevents(this.labels,'menu','selectateco')}}</button>
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em; margin-left: 10px;" @click="atecoCode3=null">{{searchevents(this.labels,'menu','deleteateco')}}</button>
              </div>
         </div>
      </div>

      <!-- settore di attività DEN e altri paesi ################################################################################################      ----->
      <div class="form-group" v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2' && this.countryOfOrigin!=''">
        <h3>{{searchevents(this.labels,'companies','registeredsetcoractivitylabel')}} </h3><br>
         <div class="row yellow-light" style="margin-top: 20px;">
              <div class="col-sm">
                <label v-if="this.countryOfOrigin=='1' || this.countryOfOrigin=='2'" for="atecoCode1" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','atecoCode1')}}</label>
                <label v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2'" for="atecoCode1" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','atecoCodeDen1')}}</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="atecoCode1" id="atecoCode1" v-model="atecoCode1"  data-target="#myModal"  required readonly>
              </div>
              <!--
              <div class="col-sm">
                <label for="naceCode1" >NACE '07</label>
                <input class="form-control" type="naceCode1" id="naceCode1" v-model="naceCode1" >
              </div>
              -->
              <div class="col-sm" style="padding-top: 0px;">
                <label v-if="this.countryOfOrigin=='1' || this.countryOfOrigin=='2'"  for="sicCode1" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescription')}} </label>
                <label v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2'"  for="sicCode1" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescriptionnace')}} </label><br>
                <div v-if="this.lang=='Ita'">{{this.filteredItemsDett(atecoCode1)}}</div>
                <div v-if="this.lang=='Eng'">{{this.filteredItemsDettEng(atecoCode1)}}</div>
                <div v-if="this.lang=='Den'">{{this.filteredItemsDettDen(atecoCode1)}}</div>
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em;" data-toggle="modal" data-target="#myModal" @click="setSelectedAteco(atecoCode1,'atecoCode1')">{{searchevents(this.labels,'menu','selectatecoNace')}}</button>
              </div>
         </div>
         <div class="row blu-dark2" style="margin-top: 20px;">
              <div class="col-sm">
                <label v-if="this.countryOfOrigin=='1' || this.countryOfOrigin=='2'"  for="atecoCode2">{{searchevents(this.labels,'companies','atecoCode2')}}</label>
                <label v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2'"  for="atecoCode2">{{searchevents(this.labels,'companies','atecoCodeDen2')}}</label>
                <input class="form-control" type="atecoCode2" id="atecoCode2" v-model="atecoCode2" disabled>
              </div>
              <!--
              <div class="col-sm">
                <label for="naceCode2" >NACE '07</label>
                <input class="form-control" type="naceCode2" id="naceCode2" v-model="naceCode2" >
              </div>
              -->
              <div class="col-sm" style="padding-top: 0px;">
                <label v-if="this.countryOfOrigin=='1' || this.countryOfOrigin=='2'"  for="sicCode2" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescription')}} </label>
                <label v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2'"  for="sicCode2" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescriptionnace')}} </label><br>
                <div v-if="this.lang=='Ita'">{{this.filteredItemsDett(atecoCode2)}}</div>
                <div v-if="this.lang=='Eng'">{{this.filteredItemsDettEng(atecoCode2)}}</div>
                <div v-if="this.lang=='Den'">{{this.filteredItemsDettDen(atecoCode2)}}</div>
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em;" data-toggle="modal" data-target="#myModal" @click="setSelectedAteco(atecoCode2,'atecoCode2')">{{searchevents(this.labels,'menu','selectatecoNace')}}</button>
              </div>
         </div>
      </div>
        <!-- settore di attività DEN e altri paesi ###### terzo inserimento -->
      <div class="form-group" v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2' && this.countryOfOrigin!=''">
         <div class="row yellow-light" style="margin-top: 20px;">
              <div class="col-sm">
                <label v-if="this.countryOfOrigin=='1' || this.countryOfOrigin=='2'"  for="atecoCode3">{{searchevents(this.labels,'companies','atecoCode3')}}</label>
                <label v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2'"  for="atecoCode3">{{searchevents(this.labels,'companies','atecoCodeDen3')}}</label>
                <input class="form-control" type="atecoCode3" id="atecoCode3" v-model="atecoCode3" disabled>
              </div>
              <!--
              <div class="col-sm">
                <label for="naceCode3" >NACE '07</label>
                <input class="form-control" type="naceCode3" id="naceCode3" v-model="naceCode3" >
              </div>
              -->
              <div class="col-sm" style="padding-top: 0px;">
                <label v-if="this.countryOfOrigin=='1' || this.countryOfOrigin=='2'"  for="sicCode3" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescription')}} </label>
                <label v-if="this.countryOfOrigin!='1' && this.countryOfOrigin!='2'"  for="sicCode3" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescriptionnace')}} </label><br>
                <div v-if="this.lang=='Ita'">{{this.filteredItemsDett(atecoCode3)}}</div>
                <div v-if="this.lang=='Eng'">{{this.filteredItemsDettEng(atecoCode3)}}</div>
                <div v-if="this.lang=='Den'">{{this.filteredItemsDettDen(atecoCode3)}}</div>
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em;" data-toggle="modal" data-target="#myModal" @click="setSelectedAteco(atecoCode3,'atecoCode3')">{{searchevents(this.labels,'menu','selectatecoNace')}}</button>
              </div>
         </div>
      </div>

      <!-- settore di attività UK ################################################################################################ ----->
      
      <div class="form-group" v-if="this.countryOfOrigin==2">
        <h3>{{searchevents(this.labels,'companies','registeredsetcoractivitylabel')}}</h3><br>
         <div class="row yellow-light" style="margin-top: 20px;">
              <div class="col-sm">
                <label for="atecoCode1" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'companies','atecoCodeEng1')}}
                 <button type="button" data-toggle="button" class="btn-yellow-tooltip" v-tooltip="{content: searchevents(labels,'tooltipval','NaceExplainedForUK'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                         <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#0c294b;width: 100%"></span>
                 </button>
                </label>
                <input class="form-control" type="atecoCode1" id="atecoCode1" v-model="atecoCode1" disabled  required>
              </div>
              <div class="col-sm">
                <label for="sicCode1" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*SIC</label>
                <input class="form-control" type="sicCode1" id="sicCode1" v-model="sicCode1" disabled required>
              </div>
              <!--
              <div class="col-sm">
                <label for="naceCode1" >NACE '07</label>
                <input class="form-control" type="naceCode1" id="naceCode1" v-model="naceCode1" >
              </div>
              -->
              <div class="col-sm">
                <label for="sicCode1" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescriptionnace')}} 
                        <button type="button" data-toggle="button" class="btn-yellow-tooltip" v-tooltip="{content: searchevents(labels,'tooltipval','NaceExplainedForUK'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                         <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#0c294b;width: 100%"></span>
                         </button> 
                </label>
                  <br>
                <div v-if="this.lang=='Ita'">{{this.filteredItemsDett(atecoCode1)}}</div>
                <div v-if="this.lang=='Eng'">{{this.filteredItemsDettEng(atecoCode1)}}</div>
                <div v-if="this.lang=='Den'">{{this.filteredItemsDettDen(atecoCode1)}}</div>
              </div>
              <div class="col-sm">
                <label for="sicCode1" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescriptionsic')}} </label><br>
                {{this.filteredItemsSicbyAteco(sicCode1)}}
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em;" data-toggle="modal" data-target="#myModalUK" @click="setSelectedAteco(atecoCode1,'atecoCode1')">{{searchevents(this.labels,'menu','searchatecofronsic')}}</button>
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em; margin-left: 10px;" @click="atecoCode1=''; sicCode1=''">{{searchevents(this.labels,'menu','deleteateco')}}</button>
              </div>
         </div>
         <div class="row blu-dark2" style="margin-top: 20px;">
              <div class="col-sm">
                <label for="atecoCode2">{{searchevents(this.labels,'companies','atecoCodeEng2')}}</label>
                <input class="form-control" type="atecoCode2" id="atecoCode2" v-model="atecoCode2" disabled>
              </div>
              <div class="col-sm">
                <label for="sicCode2" >SIC</label>
                <input class="form-control" type="sicCode2" id="sicCode2" v-model="sicCode2"  disabled>
              </div>
              <!--
              <div class="col-sm">
                <label for="naceCode2" >NACE '07</label>
                <input class="form-control" type="naceCode2" id="naceCode2" v-model="naceCode2" >
              </div>
              -->
              <div class="col-sm">
                <label for="sicCode2" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescriptionnace')}} </label><br>
                <div v-if="this.lang=='Ita'">{{this.filteredItemsDett(atecoCode2)}}</div>
                <div v-if="this.lang=='Eng'">{{this.filteredItemsDettEng(atecoCode2)}}</div>
                <div v-if="this.lang=='Den'">{{this.filteredItemsDettDen(atecoCode2)}}</div>
              </div>
              <div class="col-sm">
                <label for="sicCode2" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescriptionsic')}} </label><br>
                {{this.filteredItemsSicbyAteco(sicCode2)}}
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em;" data-toggle="modal" data-target="#myModalUK" @click="setSelectedAteco(atecoCode2,'atecoCode2')">{{searchevents(this.labels,'menu','searchatecofronsic')}}</button>
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em; margin-left: 10px;" @click="atecoCode2=''; sicCode2=''">{{searchevents(this.labels,'menu','deleteateco')}}</button>
              </div>
         </div>
      </div>
      <div class="form-group" v-if="this.countryOfOrigin==2">
         <div class="row yellow-light" style="margin-top: 20px;">
              <div class="col-sm">
                <label for="atecoCode3">{{searchevents(this.labels,'companies','atecoCodeEng3')}}</label>
                <input class="form-control" type="atecoCode3" id="atecoCode3" v-model="atecoCode3" disabled>
              </div>
              <div class="col-sm">
                <label for="sicCode3" >SIC</label>
                <input class="form-control" type="sicCode3" id="sicCode3" v-model="sicCode3" disabled >
              </div>
              <!--
              <div class="col-sm">
                <label for="naceCode3" >NACE '07</label>
                <input class="form-control" type="naceCode3" id="naceCode3" v-model="naceCode3" >
              </div>
              -->
              <div class="col-sm">
                <label for="sicCode3" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescriptionnace')}} </label><br>
                <div v-if="this.lang=='Ita'">{{this.filteredItemsDett(atecoCode3)}}</div>
                <div v-if="this.lang=='Eng'">{{this.filteredItemsDettEng(atecoCode3)}}</div>
                <div v-if="this.lang=='Den'">{{this.filteredItemsDettDen(atecoCode3)}}</div>
              </div>
              <div class="col-sm">
                <label for="sicCode3" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'menu','documentdescriptionsic')}} </label><br>
                {{this.filteredItemsSicbyAteco(sicCode3)}}
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em;" data-toggle="modal" data-target="#myModalUK" @click="setSelectedAteco(atecoCode3,'atecoCode3')">{{searchevents(this.labels,'menu','searchatecofronsic')}}</button>
                <button type="button" class="btn-primary" style="margin-top: 35px;font-size: 1em; margin-left: 10px;" @click="atecoCode3=''; sicCode3=''">{{searchevents(this.labels,'menu','deleteateco')}}</button>
              </div>
         </div>
      </div>
      
       <!-- fine settore di attività UK ### -->


      <!-- inizio cruscotto BO IMPRESA ### -->

      <div v-if="getUserRole=='BOF'">
       <br>
       <hr>
       <h3><strong> Cruscotto azioni su Dati IMPRESA</strong></h3>
       <br>
      </div>

      <div class="row" v-if="getUserRole=='BOF'">
        
              <div class="col-sm">
                <label  style="margin-right:40px;" for="companyNumber" class="requiredModX">Esito verifica dati impresa</label> 
                  <select class="form-control" v-model="companySuccessfulBO" style="margin-left:170px; width: 300px;" @change="actionCompanyBO('companySuccessfulBO')">
                      <option value="R">Registrata non sottoposta a BO</option>
                      <option value="S">Non lavorato da BO</option>
                      <option value="P">In lavorazione</option>
                      <option value="H">Sospesa</option>
                      <option value="J">Rifiutata</option>
                      <option value="A">Approvata</option>  
                  </select>
              </div>
              <div class="col-sm" >
                <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredlight']" >Altre comunicazioni all'impresa -  invio a mail LR  (usare lingua LR):</label><br>
                <!-- <button  type="button" class="btn btn-info"    @click="sendEmailBO('email-richiesta-info-integrativa','viib2')" style="font-size: .9em;margin-bottom:0px;margin-top:15px">{{searchevents(this.labels,'bof','viib2')}}</button> -->
                <button type="button" class="btn btn-recyclebo" style="font-size: 1em; margin-top: 3px; min-height:40px;" data-toggle="modal" data-target="#myModalInfoIntegrative" >{{searchevents(this.labels,'bof','viib2')}}</button>
                <br>
                <button type="button" class="btn btn-recyclebo" style="font-size: 1em; margin-top: 3px; min-height:50px;max-width: 260px;" @click="this.confermaApprovazioneLR" >Mail LR Validata </button>
              </div>
         </div>
         <br>
     
      <!--
      <hr v-if="this.id!=null && getUserRole!='BOF'">
      <button  v-if="this.id!=null && getUserRole!='BOF'" type="submit" class="btn btn-dark" style="margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'controls','saveandcontinue')}}</button>
      -->
     <!-- fine cruscotto BO IMPRESA ### -->
      

      <!-- INIZIO SEZIONE UTENTE - LR ALLA FINE - NO BO -->
    <div v-if="getUserRole!='BOF'">
      <div class="form-group">
        <hr>
        <h3>{{searchevents(this.labels,'companies','registerelegalrepresentativelabel')}}</h3><br>
          <div class="row">
              <div class="col-sm">
                  <label for="officeRegion" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','name')}}</label>
                  <input class="form-control" type="officeRegion" id="name" v-model="name" required :disabled='isNewInsert'>
              </div>
              <div class="col-sm">
                  <label for="officeDistrict" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','surname')}}</label>
                  <input class="form-control" type="officeDistrict" id="surname" v-model="surname" required :disabled='isNewInsert'>
              </div>
              <!-- abilitato finchè l'azienda è R e non è registrata - solo per aziende R -->
              <div v-if="this.id!=null && getUserRole!='BOF' && this.servCompaniesStateCompaniesState!='A' && this.servCompaniesStateCompaniesState!='C'" class="col-sm">
                  <label for="officeDistrict" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','sex')}}</label>
                  <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" v-model="sex" required>
                        <option value=""></option>
                        <option value="M">M</option>
                        <option value="F">F</option>
                </select>
                </div>
              <!-- bloccato perchè l'azienda è registrata - sbloccato solo per BO -->
                <div v-if="this.id!=null && getUserRole!='BOF' && (this.servCompaniesStateCompaniesState=='A' || this.servCompaniesStateCompaniesState=='C')" class="col-sm">
                  <label for="officeDistrict" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','sex')}}</label>
                  <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" v-model="sex" required :disabled='isNewInsert'>
                        <option value=""></option>
                        <option value="M">M</option>
                        <option value="F">F</option>
                </select>
              </div>
          </div>
      </div>
      
      <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="countryOfResidence" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','countryOfResidence')}}</label>
                <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" v-model="countryOfResidence" required :disabled='isDisabled'>
                    <template v-for="countryOfResidence in this.dropMofficeRegion ">
                        <option v-for="(value, key) in countryOfResidence" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                    </template>
                </select>
              </div>
                   <!-- abilitato finchè l'azienda è R e non è registrata - solo per aziende R -->
              <div v-if="this.id!=null && getUserRole!='BOF' && this.servCompaniesStateCompaniesState!='A' && this.servCompaniesStateCompaniesState!='C'" class="col-sm">
                <label for="birthPlace" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','birthPlace')}}</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="birthPlace" id="birthPlace" v-model="birthPlace" required>
              </div>
              <!-- bloccato per aziende registrate -->
               <div v-if="this.id!=null && getUserRole!='BOF' && (this.servCompaniesStateCompaniesState=='A' || this.servCompaniesStateCompaniesState=='C')" class="col-sm">
                <label for="birthPlace" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','birthPlace')}}</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="birthPlace" id="birthPlace" v-model="birthPlace" required :disabled='isDisabled'>
              </div>

               <!-- data entry abilitato finchè l'azienda è R e non è registrata - solo per aziende R -->
              <div v-if="this.id!=null && getUserRole!='BOF' && this.servCompaniesStateCompaniesState!='A' && this.servCompaniesStateCompaniesState!='C'" class="col-sm">
                <label for="lastInspectionDate" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','birthDate')}}</label>
                
                <!-- datepicker in configurazione alternativa eliminato per bug data entry in formato non US
                <datepicker :placeholder="searchevents(this.labels,'dialog','dateplaceholder')" format="dd/MM/yyyy" style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Ita'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="true" :language="birthDateIta" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required></datepicker>
                <datepicker :placeholder="searchevents(this.labels,'dialog','dateplaceholder')" format="dd/MM/yyyy" style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Eng'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="true" :language="birthDateEng" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required></datepicker>
                <datepicker :placeholder="searchevents(this.labels,'dialog','dateplaceholder')" format="dd/MM/yyyy" style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Den'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="true" :language="birthDateDen" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required></datepicker>
                 -->

                <!-- datepicker originale -->
               
                <!-- solo per aziende R v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='R'  )"  <datepicker style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Eng'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="false" :language="birthDateEng" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required readonly :disabled='isDisabled'></datepicker> -->

                <!-- stefano 09/03/2022
                <datepicker style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Itax'" :format="customFormatter" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="true" :language="birthDateIta" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" required></datepicker>
                
                <datepicker style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Eng'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="true" :language="birthDateEng" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required></datepicker>
                <datepicker style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Den'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="false" :language="birthDateDen" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required></datepicker>
                -->

                <br>
                <input style="text-align: center; margin-left:25%;margin-right:20%" type="date" v-model="birthDate" required /> 
                
                
                <input type="hidden" id="userIdLR" name="userIdLR" v-model="userIdLR">
                <input type="hidden" id="servCompaniesStateCompaniesState" name="servCompaniesStateCompaniesState" v-model="servCompaniesStateCompaniesState">
              </div>
                
                <!-- data entry bloccato perchè l'azienda è registrata - sbloccato solo per BO :disabled='isDisabled'-->
               <div v-if="this.id!=null && getUserRole!='BOF' && (this.servCompaniesStateCompaniesState=='A' || this.servCompaniesStateCompaniesState=='C')" class="col-sm">
                <label for="lastInspectionDate" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','birthDate')}}</label>
                
                 <!-- datepicker in configurazione alternativa eliminato per bug data entry in formato non US
                <datepicker :placeholder="searchevents(this.labels,'dialog','dateplaceholder')" format="dd/MM/yyyy" style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Ita'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="true" :language="birthDateIta" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required  :disabled='isDisabled'></datepicker>
                <datepicker :placeholder="searchevents(this.labels,'dialog','dateplaceholder')" format="dd/MM/yyyy" style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Eng'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="true" :language="birthDateEng" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required  :disabled='isDisabled'></datepicker>
                <datepicker :placeholder="searchevents(this.labels,'dialog','dateplaceholder')" format="dd/MM/yyyy" style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Den'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="true" :language="birthDateDen" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required  :disabled='isDisabled'></datepicker>
                -->
                <!-- datepicker alternativo <input type="date" class="form-control" v-model="birthdate">   -->                       
                
                <!-- datepicker originale -->
                <!-- solo per aziende R v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='R'  )"  <datepicker style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Eng'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="false" :language="birthDateEng" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required readonly :disabled='isDisabled'></datepicker> -->

                <!-- stefano 09/03/2022
                <datepicker style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Ita'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="false" :language="birthDateIta" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required :disabled='isDisabled'></datepicker>
                
                <datepicker style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Eng'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="false" :language="birthDateEng" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required :disabled='isDisabled'></datepicker>
                <datepicker style="text-align: center; margin-left:25%;margin-right:20%" v-if="this.lang=='Den'" v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" :value="this.birthDate" :typeable="false" :language="birthDateDen" v-model="birthDate" name="birthDate" ref="birthDate" id="birthDate" @closed="VerifyBirthDate" required :disabled='isDisabled'></datepicker>
                -->

                <br>
                <input style="text-align: center; margin-left:25%;margin-right:20%" type="date" v-model="birthDate" required /> 
                
                <input type="hidden" id="userIdLR" name="userIdLR" v-model="userIdLR">
                <input type="hidden" id="servCompaniesStateCompaniesState" name="servCompaniesStateCompaniesState" v-model="servCompaniesStateCompaniesState">
              </div>


         </div>
      </div>
      <!--
      <div class="form-group">
         <div class="row">
               <div class="col-sm" style="max-width: 35%;">
                  <label for="servMobilePrefixId">{{searchevents(this.labels,'table','doctype')}}</label>
                    <select class="form-control" v-if="this.countryOfResidence==1 && this.countryOfResidence!=''"> v-model="servDocTypeId" required>
                      <template v-for="servDocTypeId in dropCompaniesDocsC " >
                          <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeIta']}}</option>
                      </template>
                    </select>
                    <select class="form-control" v-if="this.countryOfResidence!=1 && this.countryOfResidence!=''"> v-model="servDocTypeId" disabled>
                        <template v-for="servDocTypeId in dropCompaniesDocsAllC ">
                            <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeEng']}}</option>
                        </template>
                    </select>
                  </div>
              <div class="col-sm">
                <label for="documentNumber">{{searchevents(this.labels,'users','documentNumber')}}</label>
                <input class="form-control" type="documentNumber" id="documentNumber" v-model="documentNumber" required>
              </div>
         </div>
      </div>
      -->
      
      <!-- PROSECUZIONE SEZIONE UTENTE - LR ALLA FINE - NO BO -->

      <div class="form-group">
              <!-- spostato qui campo email BO da verificare se mantenere -->
        <div class="row">
                <div class="col-sm" v-if="getUserRole=='BOF'">
                <label for="countryOfResidence" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','email')}}</label>
                 <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" id="emailLR" v-model="emailLR" required :disabled='isDisabled'>
              </div>
          </div>
         <div class="row">
              <!-- inserito qui nuovo campo email utente non modificabile -->
              <div class="col-sm" v-if="getUserRole!='BOF'">
                <label for="countryOfResidence" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','email')}}</label>
                 <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" id="emailLR" v-model="emailLR" required readonly :disabled='isDisabled'>
              </div> 
         

             <!-- CODICE FISCALE APERTO campo da aprire solo fino al passaggio al backoffice-->
              <!-- abilitato finchè l'azienda è R e non è registrata - solo per aziende R REGOLA DIVERSA DAI PRECEDENTI 
              <div class="col-sm" v-if="this.countryOfResidence==1 && this.countryOfResidence!='' && this.id!=null && getUserRole!='BOF' && this.servCompaniesStateCompaniesState!='A' && this.servCompaniesStateCompaniesState!='C'">
               <label for="pec" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','fiscalCode')}}</label>
               <label v-if="italianFCInorrect=='X' && getUserRole=='BOF'" for="emailAddress" class="fiscalCodeIncorrect">codice fiscale risultato NON corretto</label>
               <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="fiscalCode" id="fiscalCode" @change="resetAtecoCode6()" :maxlength="16" :minlength="16" v-model="fiscalCode" required >
              </div>
              <div class="col-sm" v-if="this.countryOfResidence!=1 && this.countryOfResidence!='' && this.id!=null && getUserRole!='BOF' && this.servCompaniesStateCompaniesState!='A' && this.servCompaniesStateCompaniesState!='C'">
               <label for="emailAddress" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','fiscalCodeExt')}}</label>
              <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="fiscalCode" id="fiscalCode" v-model="fiscalCode" required>
              </div>
              -->
              

               <!-- bloccato perchè l'azienda è registrata - sbloccato solo per BO :disabled='isDisabled'-->
              <div class="col-sm" v-if="this.countryOfResidence==1 && this.countryOfResidence!='' && this.id!=null && getUserRole!='BOF' && (this.servCompaniesStateCompaniesState=='A' || this.servCompaniesStateCompaniesState=='C' || this.servCompaniesStateCompaniesState=='R')">
               <label for="pec" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','fiscalCode')}}</label>
               <label v-if="italianFCInorrect=='X' && getUserRole=='BOF'" for="emailAddress" class="fiscalCodeIncorrect">codice fiscale risultato NON corretto</label>
              <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="fiscalCode" id="fiscalCode" @change="resetAtecoCode6()" :maxlength="16" :minlength="16" v-model="fiscalCode" required :disabled='isDisabled'>
              </div>
              <div class="col-sm" v-if="this.countryOfResidence!=1 && this.countryOfResidence!='' && this.id!=null && getUserRole!='BOF' && (this.servCompaniesStateCompaniesState=='A' || this.servCompaniesStateCompaniesState=='C' || this.servCompaniesStateCompaniesState=='R')">
               <label for="emailAddress" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','fiscalCodeExt')}}</label>
              <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="fiscalCode" id="fiscalCode" v-model="fiscalCode" required :disabled='isDisabled'>
              </div>

             <!--  FISCAL CODE nel caso di paese di residenza non ancora definito durante la registrazione-->
                <div class="col-sm" v-if="this.countryOfResidence==''">
                <label for="companyNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >----</label>
                <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" type="fiscalCode" id="fiscalCode" readonly>
              </div>
              
         </div>
      </div>


      <!-- documento bloccato caso aziende passate dal BO e approvate-->
      <div class="form-group">
         <div class="row">
           <div class="col-sm" style="max-width: 35%;" v-if="getUserRole!='BOF' && (this.servCompaniesStateCompaniesState=='A' || this.servCompaniesStateCompaniesState=='C')">
                  <label for="servMobilePrefixId" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'table','doctype')}}</label>
                    <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" v-if="this.countryOfResidence==1 && this.countryOfResidence!=''" v-model="servDocTypeIdLR" required :disabled='isDisabled'>
                      <template v-for="servDocTypeId in dropCompaniesDocs " >
                          <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeIta']}}</option>
                      </template>
                    </select>
                    <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" v-if="this.countryOfResidence!=1 && this.countryOfResidence!=''" v-model="servDocTypeIdLR" required :disabled='isDisabled'>
                        <template v-for="servDocTypeId in dropCompaniesDocs ">
                            <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeEng']}}</option>
                        </template>
                    </select>
                    
                  </div>
                  <div class="col-sm" v-if="getUserRole!='BOF' && (this.servCompaniesStateCompaniesState=='A' || this.servCompaniesStateCompaniesState=='C')">
                    <label for="documentNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','documentNumber')}}</label>
                    <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" type="documentNumber" id="documentNumber" v-model="documentNumber" required :disabled='isDisabled'>
                  </div>
         </div>
      </div>
  <!-- documento sbloccato caso aziende non approvate-->
      <div class="form-group">
         <div class="row">
           <div class="col-sm" style="max-width: 35%;" v-if="getUserRole!='BOF' && (this.servCompaniesStateCompaniesState=='R')">
                  <label for="servMobilePrefixId" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'table','doctype')}}</label>
                    <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" v-if="this.countryOfResidence==1 && this.countryOfResidence!=''" v-model="servDocTypeIdLR" required >
                      <template v-for="servDocTypeId in dropCompaniesDocs " >
                          <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeIta']}}</option>
                      </template>
                    </select>
                    <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" v-if="this.countryOfResidence!=1 && this.countryOfResidence!=''" v-model="servDocTypeIdLR" required >
                        <template v-for="servDocTypeId in dropCompaniesDocs">
                            <option v-for="(value, key) in servDocTypeId" :value="value['id']" :key="key" >{{value['docTypeEng']}}</option>
                        </template>
                    </select>
                    
                  </div>
                  <div class="col-sm" v-if="getUserRole!='BOF' && (this.servCompaniesStateCompaniesState=='R')">
                    <label for="documentNumber" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','documentNumber')}}</label>
                    <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" type="documentNumber" id="documentNumber" v-model="documentNumber" required>
                  </div>
         </div>
      </div>
<!-- recupero telefono mobile da sistemare  !!!!
            <div class="form-group">
         <div class="row">
           <div class="col-sm" style="max-width: 35%;">
                  <label for="servMobilePrefixId" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','servMobilePrefixId')}}</label>
                    <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" v-if="this.countryOfResidence==1 && this.countryOfResidence!=''" v-model="servMobilePrefixId" required :disabled='isDisabled'>
                        <template v-for="servMobilePrefixId in dropMservLandlinePrefixId">
                            <option v-for="(value, key) in servMobilePrefixId" :value="value['id']" :key="key" >{{value['prefixName']}} {{value['prefix']}} </option>
                        </template>
                    </select>
                    <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" v-if="this.countryOfResidence!=1 && this.countryOfResidence!=''" v-model="servMobilePrefixId" required :disabled='isDisabled'>
                        <template v-for="servMobilePrefixId in dropMservLandlinePrefixId">
                            <option v-for="(value, key) in servMobilePrefixId" :value="value['id']" :key="key" >{{value['prefixName']}} {{value['prefix']}} </option>
                        </template>
                    </select>
                    
                  </div>
                  <div class="col-sm">
                    <label for="servMobilePrefixId" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >*{{searchevents(this.labels,'users','mobile')}}</label>
                    <input class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'onlybo']" type="mobile" id="mobile" v-model="mobile" required :disabled='isDisabled'>
                  </div>
         </div>
      </div>
   -->

     <!-- PROSECUZIONE SEZIONE UTENTE - LR ALLA FINE - NO BO -->


     <!-- SEZIONE ACCETTAZIONE CONDIZIONI PER REGISTRAZIONE NUOVA IMPRESA -->
      
      
      <div class="form-group" v-if="this.id==null">
        <br><br>
        <h3>{{searchevents(this.labels,'disclaimer','disclaimerHeader')}}</h3>
        <br>
        <div class="row">
              <div class="col-sm" style="max-width: 20%;">
                <input style="font-size:0.8em;margin-left:45%;" class="form-control" type="checkbox" id="disclaimer02" value="disclaimer02" @click="verifyPassword()" v-model="disclaimer02" required> 
              </div>
              <div class="col-sm" style="text-align: left;margin-top:3px;font-size:1.1em;">
                <div v-if="this.lang=='Ita'">Confermo di avere letto e di accettare le <b> <a :href="'https://assets.gobiz.be/documents/TermsConditions_IT.pdf' " target="_blank" > Condizioni generali del servizio GoBiz</a></b>.*</div>
                <div v-if="this.lang=='Eng'">I confirm that I have read and accept the <b> <a :href="'https://assets.gobiz.be/documents/TermsConditions_EN.pdf' " target="_blank" > GoBiz general terms and conditions</a></b>.*</div>
                <div v-if="this.lang=='Den'">Jeg bekræfter, at jeg har læst og accepterer <b> <a :href="'https://assets.gobiz.be/documents/TermsConditions_DK.pdf' " target="_blank" > GoBizs generelle servicevilkår</a></b>. (på italiensk og engelsk)*</div>
                </div>
        </div>
        <!-- Eliminazione vessatorie aggiungi impresa
        <div class="row">
              <div class="col-sm" style="max-width: 20%;">
                <input style="font-size:0.8em;margin-top:10px;margin-left:45%;" class="form-control" type="checkbox" id="disclaimer03" value="disclaimer03" @click="verifyPassword()" v-model="disclaimer03" required>
              </div>
              <div class="col-sm" style="text-align: left;margin-top:12px;font-size:1.1em;">
                <label for="disclaimer03" v-html="searchevents(this.labels,'disclaimer','disclaimer03')"></label>
              </div>
        </div>
        --> 
        <div class="row">
              <div class="col-sm" style="max-width: 20%;">
                <input style="font-size:0.8em;margin-top:8px;margin-left:45%;" class="form-control" type="checkbox" id="disclaimer04" value="disclaimer04" @click="verifyPassword()" v-model="disclaimer04" required> 
              </div>
              <div class="col-sm" style="text-align: left;font-size:1.1em;">
                <div style="margin-top:10px;"  v-if="this.lang=='Ita'">Dichiaro di avere preso visione dell'<b> <a :href="'https://assets.gobiz.be/documents/Privacy&Cookie_IT.pdf' " target="_blank" > Informativa Privacy del servizio GoBiz</a></b>.*</div>
                <div style="margin-top:10px;"  v-if="this.lang=='Eng'">I declare that I have read the <b><a :href="'https://assets.gobiz.be/documents/Privacy&Cookie_EN.pdf' " target="_blank" >Privacy Policy of the GoBiz service</a></b>.*</div>
                <div style="margin-top:10px;"  v-if="this.lang=='Den'">Jeg erklærer, at jeg har læst <b><a :href="'https://assets.gobiz.be/documents/Privacy&Cookie_DK.pdf' " target="_blank" >GoBiz-tjenestens fortrolighedspolitik</a></b>. (på italiensk og engelsk)*</div>
               
              </div>
        </div>
        <div class="row">
              <div class="col-sm" style="max-width: 20%;">
                <input style="font-size:0.8em;margin-top:10px;margin-left:45%;" class="form-control" type="checkbox" id="disclaimer05" value="disclaimer05" @click="verifyPassword()" v-model="disclaimer05" > 
              </div>
              <div class="col-sm" style="text-align: left;margin-top:12px;font-size:1.1em;">
                <label for="disclaimer05" v-html="searchevents(this.labels,'disclaimer','disclaimer05')"></label>
              </div>
        </div>
      </div>
      <!-- FINE NUOVA IMPRESA -->
  

      

      <!-- ################### legale rappresentante -->

      <!--
      <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="countryOfResidence" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >{{searchevents(this.labels,'companies','servCompaniesStateCompaniesState')}}</label>
                <select class="form-control" v-model="servCompaniesStateCompaniesState" required>
                    <template v-for="servCompaniesStateCompaniesState in this.dropComanyState ">
                        <option v-for="(value, key) in servCompaniesStateCompaniesState" :value="value['companiesState']" :key="key" >{{value['companiesStateIta']}}</option>
                    </template>
                </select>
              </div>
         </div>
      </div>
      -->

      
      <!-- 
      <div class="form-group">
         <div class="row" style="background-color: #e7f2ff; margin-top: 20px;">
              <div class="col-sm">
                <label for="atecoCode4">{{searchevents(this.labels,'companies','atecoCode4')}}</label>
                <input class="form-control" type="atecoCode4" id="atecoCode4" v-model="atecoCode4" >
              </div>
              <div class="col-sm">
                {{this.filteredItemsDett(atecoCode4)}}
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 35px;" data-toggle="modal" data-target="#myModal" @click="setSelectedAteco(atecoCode4,'atecoCode4')">{{searchevents(this.labels,'menu','selectateco')}}</button>
              </div>
         </div>
         
      </div>
      <div class="form-group">
         <div class="row" style="background-color: azure; margin-top: 20px;">
              <div class="col-sm">
                <label for="atecoCode5">{{searchevents(this.labels,'companies','atecoCode5')}}</label>
                <input class="form-control" type="atecoCode5" id="atecoCode5" v-model="atecoCode5" > {{this.filteredItemsDett(atecoCode5)}}
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 55px;" data-toggle="modal" data-target="#myModal" @click="setSelectedAteco(atecoCode5,'atecoCode5')">{{searchevents(this.labels,'menu','selectateco')}}</button>
              </div>
              <div class="col-sm">
                <label for="atecoCode6">{{searchevents(this.labels,'companies','atecoCode6')}}</label>
                <input class="form-control" type="atecoCode6" id="atecoCode6" v-model="atecoCode6" > {{this.filteredItemsDett(atecoCode6)}}
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 55px;" data-toggle="modal" data-target="#myModal" @click="setSelectedAteco(atecoCode6,'atecoCode6')">{{searchevents(this.labels,'menu','selectateco')}}</button>
              </div>
              <div class="col-sm">
                <label for="atecoCode7">{{searchevents(this.labels,'companies','atecoCode7')}}</label>
                <input class="form-control" type="atecoCode7" id="atecoCode7" v-model="atecoCode7" > {{this.filteredItemsDett(atecoCode7)}}
              </div>
              <div class="col-sm">
                <button type="button" class="btn-primary" style="margin-top: 55px;" data-toggle="modal" data-target="#myModal" @click="setSelectedAteco(atecoCode7,'atecoCode7')">{{searchevents(this.labels,'menu','selectateco')}}</button>
              </div>
         </div>
      </div>
      -->
        <br>
   </div>  
 

<!--fine sezione solo utente NO BO -->


      
      <div class="row">
        <div class="col-6" style="text-align:center;" v-if="this.id==null && getUserRole!='BOF' ">
          <button  v-if="this.id==null && getUserRole!='BOF'" type="submit" class="btn btn-dark" style="margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'companies','insertandcontinuenew')}}</button>
        </div>

        <div class="col-6" style="text-align:center;" v-if="this.id!=null && getUserRole!='BOF' && this.verificaCampiPerSottoporreCompagnia()!='OK'">
          <button id="snc" ref="snc" v-if="this.id!=null && getUserRole!='BOF' && this.servCompaniesStateCompaniesState!='A' && this.servCompaniesStateCompaniesState!='C'" type="submit" class="btn btn-dark" style="margin-bottom:0px;margin-top:15px">{{searchevents(this.labels,'controls','saveandcontinue')}}</button>
          <button  v-if="this.id==null && getUserRole!='BOF' && this.servCompaniesStateCompaniesState!='A' && this.servCompaniesStateCompaniesState!='C'" type="submit" class="btn btn-dark" style="margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'controls','insertandcontinue')}}</button>
        </div>

        <div class="col-6" style="text-align:center;" v-if="this.id!=null && getUserRole!='BOF' && this.verificaCampiPerSottoporreCompagnia()=='OK'">
          <button id="snc" ref="snc"  v-if="this.id!=null && getUserRole!='BOF' && this.servCompaniesStateCompaniesState!='A' && this.servCompaniesStateCompaniesState!='C'" type="submit" class="btn btn-success" style="margin-bottom:0px;margin-top:15px">{{searchevents(this.labels,'controls','saveandcontinue')}}</button>
          <button  v-if="this.id==null && getUserRole!='BOF' " type="submit" class="btn btn-dark" style="margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'controls','insertandcontinue')}}</button>
        </div>

         <div class="col-6" style="text-align:center;" v-if="this.id!=null && getUserRole!='BOF' && this.verificaCampiPerSottoporreCompagnia()=='OK' && this.servCompaniesStateCompaniesState!='A' && this.servCompaniesStateCompaniesState!='C'">
          <button  v-if="this.id!=null && getUserRole!='BOF' && this.verificaCampiPerSottoporreCompagnia()=='OK'" type="button" @click="onClickSubmitCompnayGoBiz()" class="btn btn-dark" style="margin-bottom:0px;margin-top:15px">{{searchevents(this.labels,'controls','submitcompany')}}</button>
         </div>

         <div class="col-6" style="text-align:center;" v-if="this.id!=null && getUserRole!='BOF' && this.verificaCampiPerSottoporreCompagnia()!='OK' && this.servCompaniesStateCompaniesState!='A' && this.servCompaniesStateCompaniesState!='C'">
          <button  v-if="this.id!=null && getUserRole!='BOF' && this.verificaCampiPerSottoporreCompagnia()!='OK'" type="button" class="btn " style="margin-bottom:0px;margin-top:15px; background-color: gray; border-color:#0c294b" disabled>{{searchevents(this.labels,'controls','submitcompany')}}</button>
          <div>{{searchevents(this.labels,'dialog','allrequiredfieldsandfiles')}}</div>
         </div>
      </div>
         <br>
         <br>
         <br>
    </div>
    
    <!-- PANNELLO 2 ############################################################################################################################################################## -->
    <div class="tab-pane container fade" id="step2" style="margin-bottom:0px;" v-if="(this.companySuccessfulBO=='A' && this.userSuccessfulBO=='A') || this.companySuccessfulBO=='C'" >
      
      <button type="button" class="btn btn-bigdark" @click="setSelectedCompanyIdDetail(id,'companies','N','N')" style="font-size: .9em; margin-top: 50px; min-height:60px; min-width: 180px " >{{searchevents(this.labels,'menu','mypublicbusinesscard')}}</button>

      <hr>

       <!-- upload vetrina -->

      <div class="form-group"> <!-- DOCS -->
        <div class="container tablelist" v-if="this.id!=null">
          <div>
            <h3>{{searchevents(this.labels,'menu','companydocs')}}</h3> 
          </div>

              <div class="row">
                        
                        <div class="col-3 rowDoc" v-for="(row) in docListStep2" :key="row.id">
                          
                            
                          <div class="row">
                          <div class="col-sm" style="min-height:50px;">

                            {{viewDocTypeDesc2(row.servDocTypeId)}}
                            </div>
                          </div>
                           <div class="row">
                          <div class="col-sm" style="min-height:80px;">
                               
                              <!-- <a v-if="row.name!=null && row.name!='' && row.servDocTypeId==1" :href="remoteServer + /docs/ + row.name" v-text="row.description2" @click.prevent="downloadItem( remoteServer + /docs/ + row.name ,row.description2)" /> -->
                              
                              <a v-if="row.name!=null && row.name!='' && (row.servDocTypeId==1 || row.servDocTypeId==8 || row.servDocTypeId==9 || row.servDocTypeId==11)"  :href="remoteServer + '/docs/doc_imprese/' + row.name" v-text="row.description2" @click.prevent="downloadItem( remoteServer + '/docs/doc_imprese/' + row.name ,row.description2)" />
                              <a v-if="row.name!=null && row.name!='' && (row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==7 || row.servDocTypeId==10 )" :href="remoteServer + '/docs/doc_persone/' + row.name" v-text="row.description2" @click.prevent="downloadItem( remoteServer + '/docs/doc_persone/' + row.name ,row.description2)" />
                              <!-- <a v-if="row.name!=null && row.name!='' && (row.servDocTypeId==4 || row.servDocTypeId==5 || row.servDocTypeId==6)"  :href="remoteServer + '/docs/media/' + row.name" v-text="row.description2" /> -->

                              <!-- <a v-if="row.name!=null && row.name!='' && row.servDocTypeId==1" class="nav-link" :href="remoteServer + /docs/ + row.name" target="_blank"  >{{row.description2}}</a> -->
                              <a target="_blank" v-if="row.name!=null && row.name!='' && (row.servDocTypeId==4 || row.servDocTypeId==5 || row.servDocTypeId==6)" :href="remoteServer + '/docs/media/' + row.name" ><img v-if="row.name!=null && row.name!='' && row.servDocTypeId!=1 && row.servDocTypeId!=5" :src="remoteServer + '/docs/media/' + row.name" style="max-width:40%;" /></a>
                              <video v-if="row.servDocTypeId==5" controls style="max-width: 40%;"> <source v-bind:src="remoteServer +  '/docs/media/' + row.name" type="video/mp4"> </video>

                            </div>
                          </div>
                          <div class="row">
                          <div class="col-sm" style="min-height:50px;">
                            {{ row.description3 }}
                          </div>
                          </div>
                           <!-- bottoni caricamento doc -->
                          <div class="row">
                            <div class="col-sm">
                              <button v-if="servCompaniesStateCompaniesState=='R' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==11)" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.description3, row.servDocTypeId)" ><span class="far fa-file-alt" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                              <button v-if=" row.servDocTypeId!=2 && row.servDocTypeId!=3" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.description3, row.servDocTypeId)" ><span class="far fa-file-alt" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                              
                            </div>
                            <div class="col-sm">
                              <button v-if="servCompaniesStateCompaniesState=='R' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3 || row.servDocTypeId==11)" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" :key="row.id" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                              <button v-if=" row.servDocTypeId!=2 && row.servDocTypeId!=3" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                            </div>
                          </div>
                      
                        
                        </div>

                        <div class="col-2 rowDoc" v-for="(row) in docListStep3" :key="row.id">
                          
                            
                          <div class="row" >
                          <div class="col-sm" style="min-height:50px;">
                            
                            
                            {{viewDocTypeDesc2(row.servDocTypeId)}}
                            </div>
                          </div>
                           <div class="row">
                          <div class="col-sm" style="min-height:100px;">
                              <a v-if="row.name!=null && row.name!='' && row.servDocTypeId==1" class="nav-link" :href="remoteServer + '/docs/doc_imprese/' + row.name" target="_blank"  >{{row.description2}}</a> 
                              <a target="_blank" v-if="row.name!=null && row.name!='' && (row.servDocTypeId==4 || row.servDocTypeId==5 || row.servDocTypeId==6)" :href="remoteServer + '/docs/media/' + row.name" ><img v-if="row.name!=null && row.name!='' && row.servDocTypeId!=1" :src="remoteServer + '/docs/media/' + row.name" style="max-width:40%;" /></a>
                            </div>
                          </div>
                          <div class="row">
                          <div class="col-sm" style="min-height:50px;">
                            {{ row.description3 }} 
                          </div>
                          </div>
                          <!-- seconda riga bottoni -->
                          <div class="row">
                            <div class="col-sm">
                              <button v-if="servCompaniesStateCompaniesState=='R' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3)" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.description3, row.servDocTypeId)" ><span class="far fa-file-alt" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                              <button v-if="row.servDocTypeId!=11 && row.servDocTypeId!=2 && row.servDocTypeId!=3" type="button" class="btn btn-pdf" data-toggle="modal" data-target="#docsModal" style="font-size: .9em;" @click="setDocToModify(row.id, row.description, row.description2, row.description3, row.servDocTypeId)" ><span class="far fa-file-alt" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
                              
                            </div>
                            <div class="col">
                              <button v-if="servCompaniesStateCompaniesState=='R' && (row.servDocTypeId==1 || row.servDocTypeId==2 || row.servDocTypeId==3)" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" :key="row.id" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                              <button v-if="row.servDocTypeId!=11 && row.servDocTypeId!=2 && row.servDocTypeId!=3" type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteDoc(row.id, row.description)" ><span class="fa fa-trash"  style="font-size:25px;color:#dd1d00;width: 100%;" ></span></button>
                            </div>
                          </div>
                      
                        
                        </div>

                
              </div>

        </div>
      </div> <!-- END DOCS -->

      <!-- attività descrittive per company business card -->
      
      <div class="row">
        <div class="col-12">

        <!-- 1#################################### DESCRIZIONE ATTIVITA' ######################################## -->
         <hr>
        <div class="row"> 
        <div class="col-12" style="text-align:center;font-weight:bold"><h3>{{searchevents(this.labels,'companies','companyActivityDescription')}}</h3></div>
        </div>
        <br>

        <!-- Input evidenziato per aziende italiane -->
        <!-- modifica lingua input -->
       <!-- <div v-if="this.countryOfOrigin=='1'" class="row"> -->
        <div v-if="this.lang=='Ita'" class="row">  
          <div class="col-4" style="text-align:center;">
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" ><b><mark><u>->ITA</u></mark></b></label>
            <textarea class="form-control" rows="2" id="companyActivityDescription" v-model="companyActivityDescription" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >ENG</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityDescription2" v-model="companyActivityDescription2" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >DEN</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityDescription3" v-model="companyActivityDescription3" ></textarea>
          </div>
        </div>
          <!-- Input evidenziato per aziende inglesi -->
        <!-- <div v-if="this.countryOfOrigin=='2'" class="row">  -->
          
        <div v-if="this.lang=='Eng'" class="row">  
          <div class="col-4" style="text-align:center;">
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" ><b><mark><u>->ENG</u></mark></b></label>
            <textarea class="form-control" rows="2" id="companyActivityDescription2" v-model="companyActivityDescription2" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >ITA</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityDescription" v-model="companyActivityDescription" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >DEN</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityDescription3" v-model="companyActivityDescription3" ></textarea>
          </div>
        </div>    
            <!-- Input evidenziato per aziende danesi -->
        <!-- <div v-if="this.countryOfOrigin=='504'" class="row"> -->
        <div v-if="this.lang=='Den'" class="row"> 
          <div class="col-4" style="text-align:center;">
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" ><b><mark><u>->DEN</u></mark></b></label>
            <textarea class="form-control" rows="2" id="companyActivityDescription3" v-model="companyActivityDescription3" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >ENG</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityDescription2" v-model="companyActivityDescription2" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >ITA</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityDescription" v-model="companyActivityDescription" ></textarea>
          </div>

          <!-- spostato al centro
          <div class="col-3" style="text-align:center;">
            <button type="button" data-toggle="button" class="btn-yellow-tooltip" v-tooltip="{content: searchevents(labels,'tooltipval','translationforbusinessdata'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
               <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#0c294b;width: 100%"></span>
               </button>
            <h6>{{searchevents(this.labels,'menu','usetranslation')}}</h6>
            <button v-if="this.countryOfOrigin=='1'" type="button" class="btn btn-success" style="font-size: .8em; margin-top: 50px;"  @click="translateMe(1)" >{{searchevents(labels,'menu','translateitatoengdan')}}</button>
            <button v-if="this.countryOfOrigin=='2'" type="button" class="btn btn-success" style="font-size: .8em; margin-top: 50px;"  @click="translateMe(1)" >{{searchevents(labels,'menu','translateengtoitadan')}}</button>
            <button v-if="this.countryOfOrigin=='504'" type="button" class="btn btn-success" style="font-size: .8em; margin-top: 50px;"  @click="translateMe(1)" >{{searchevents(labels,'menu','translatedantoengita')}}</button>
          </div>
          -->
        </div>
          
          <!-- fine Input evidenziato per aziende -->


        <div class="col-4" style="text-align:center; margin-top: 1%;"> 
              <button type="button" data-toggle="button" class="btn-yellow-tooltip" v-tooltip="{content: searchevents(labels,'tooltipval','translationforbusinessdata'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
               <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#0c294b;width: 100%"></span>
               </button>
            <h6>{{searchevents(this.labels,'menu','usetranslation')}}</h6>
            <!-- traduzione per paese registrazione
            <button v-if="this.countryOfOrigin=='1'" type="button" class="btn btn-success" style="font-size: .8em;"  @click="translateMe(1)" >{{searchevents(labels,'menu','translateitatoengdan')}}</button>
            <button v-if="this.countryOfOrigin=='2'" type="button" class="btn btn-success" style="font-size: .8em;"  @click="translateMe(1)" >{{searchevents(labels,'menu','translateengtoitadan')}}</button>
            <button v-if="this.countryOfOrigin=='504'" type="button" class="btn btn-success" style="font-size: .8em;"  @click="translateMe(1)" >{{searchevents(labels,'menu','translatedantoengita')}}</button>
            -->
             <!-- traduzione per lingua di visualizzazione -->
            <button v-if="this.lang=='Ita'" type="button" class="btn btn-success" style="font-size: .8em;"  @click="translateMe(1)" >{{searchevents(labels,'menu','translateitatoengdan')}}</button>
            <button v-if="this.lang=='Eng'" type="button" class="btn btn-success" style="font-size: .8em;"  @click="translateMe(1)" >{{searchevents(labels,'menu','translateengtoitadan')}}</button>
            <button v-if="this.lang=='Den'" type="button" class="btn btn-success" style="font-size: .8em;"  @click="translateMe(1)" >{{searchevents(labels,'menu','translatedantoengita')}}</button>
            
           
           
           
        </div>
              <div class="row"> 
              <div class="col-12" style="text-align:center; height: 5px;">  </div> 
              </div>
            <hr>
 
        <!-- 2################################ PRINCIPALI PRODOTTI INTESTAZ ###################################################### -->
          <div class="row"> 
         <!--  <div class="col-12" style="text-align:center; height: 50px;"></div> -->
          <div class="col-12" style="text-align:center; margin-top: 0.5%">  </div> 
          </div>
        <div class="row"> 
          <div class="col-12" style="text-align:center;font-weight:bold"><h3>{{searchevents(this.labels,'companies','companyActivityProducts')}}</h3></div>
        </div>
        <br>
        <!-- #################################### PRINCIPALI PRODOTTO/SERVIZI ######################################## -->
        <!-- Input evidenziato per aziende italiane -->
        <!-- modifica lingua input -->
       <!-- <div v-if="this.countryOfOrigin=='1'" class="row"> -->
        <div v-if="this.lang=='Ita'" class="row">  
           <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityProducts" >{{searchevents(this.labels,'companies','companyActivityProducts')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" ><b><mark><u>->ITA</u></mark></b></label>
            <textarea class="form-control" rows="2" id="companyActivityProducts" v-model="companyActivityProducts" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityProducts2" >{{searchevents(this.labels,'companies','companyActivityProducts2')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >ENG</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityProducts2" v-model="companyActivityProducts2" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityProducts3" >{{searchevents(this.labels,'companies','companyActivityProducts3')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >DEN</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityProducts3" v-model="companyActivityProducts3" ></textarea>
          </div>
        </div>
        
        
          <!-- Input evidenziato per aziende inglesi -->
        <!-- <div v-if="this.countryOfOrigin=='2'" class="row">  -->
       <div v-if="this.lang=='Eng'" class="row">  
            <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityProducts2" >{{searchevents(this.labels,'companies','companyActivityProducts2')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" ><b><mark><u>->ENG</u></mark></b></label>
            <textarea class="form-control" rows="2" id="companyActivityProducts2" v-model="companyActivityProducts2" ></textarea>
            </div>
            <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityProducts" >{{searchevents(this.labels,'companies','companyActivityProducts')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >ITA</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityProducts" v-model="companyActivityProducts" ></textarea>
            </div>
          <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityProducts3" >{{searchevents(this.labels,'companies','companyActivityProducts3')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >DEN</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityProducts3" v-model="companyActivityProducts3" ></textarea>
          </div>
        </div>

        <!-- Input evidenziato per aziende danesi -->
        <!-- <div v-if="this.countryOfOrigin=='504'" class="row"> -->
       <div v-if="this.lang=='Den'" class="row"> 
          <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityProducts3" >{{searchevents(this.labels,'companies','companyActivityProducts3')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" ><b><mark><u>->DEN</u></mark></b></label>
            <textarea class="form-control" rows="2" id="companyActivityProducts3" v-model="companyActivityProducts3" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityProducts2" >{{searchevents(this.labels,'companies','companyActivityProducts2')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >ENG</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityProducts2" v-model="companyActivityProducts2" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityProducts" >{{searchevents(this.labels,'companies','companyActivityProducts')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >ITA</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityProducts" v-model="companyActivityProducts" ></textarea>
          </div>

        </div>

          <!-- fine Input evidenziato per aziende -->

        <div class="col-4" style="text-align:center; margin-top: 1%;">
         <button type="button" data-toggle="button" class="btn-yellow-tooltip" v-tooltip="{content: searchevents(labels,'tooltipval','translationforbusinessdata'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
               <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#0c294b;width: 100%"></span>
               </button>
            <h6>{{searchevents(this.labels,'menu','usetranslation')}}</h6>
            
            
            <!-- traduzione per paese registrazione
            <button v-if="this.countryOfOrigin=='1'" type="button" class="btn btn-success" style="font-size: .8em; "  @click="translateMe(2)" >{{searchevents(labels,'menu','translateitatoengdan')}}</button>
            <button v-if="this.countryOfOrigin=='2'" type="button" class="btn btn-success" style="font-size: .8em; "  @click="translateMe(2)" >{{searchevents(labels,'menu','translateengtoitadan')}}</button>
            <button v-if="this.countryOfOrigin=='504'" type="button" class="btn btn-success" style="font-size: .8em; "  @click="translateMe(2)" >{{searchevents(labels,'menu','translatedantoengita')}}</button>
            -->
            <button v-if="this.lang=='Ita'" type="button" class="btn btn-success" style="font-size: .8em; "  @click="translateMe(2)" >{{searchevents(labels,'menu','translateitatoengdan')}}</button>
            <button v-if="this.lang=='Eng'" type="button" class="btn btn-success" style="font-size: .8em; "  @click="translateMe(2)" >{{searchevents(labels,'menu','translateengtoitadan')}}</button>
            <button v-if="this.lang=='Den'" type="button" class="btn btn-success" style="font-size: .8em; "  @click="translateMe(2)" >{{searchevents(labels,'menu','translatedantoengita')}}</button>
          
                  
          </div>
              <div class="row"> 
              <div class="col-12" style="text-align:center; height: 5px;">  </div> 
              </div>
          <hr>
        <!-- #################################################################################################### -->
        <div class="row"> 
          <div class="col-12" style="text-align:center; margin-top: 0.5%">  </div> 
        </div>
        <div class="row"> 
          <div class="col-12" style="text-align:center;font-weight:bold"><h3>{{searchevents(this.labels,'companies','companyActivityCertifications')}}</h3></div>
        </div>
        <!-- 3#################################### CERTIFICAZIONI DI QUALITA' E PREMIRICEVUTI ######################################## -->
        
        <!-- Input evidenziato per aziende italiane -->
        <!-- modifica lingua input -->
       <!-- <div v-if="this.countryOfOrigin=='1'" class="row"> -->
        <div v-if="this.lang=='Ita'" class="row"> 
         <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityCertifications" >{{searchevents(this.labels,'companies','companyActivityCertifications')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" ><b><mark><u>->ITA</u></mark></b></label>
            <textarea class="form-control" rows="2" id="companyActivityCertifications" v-model="companyActivityCertifications" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityCertifications2" >{{searchevents(this.labels,'companies','companyActivityCertifications2')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >ENG</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityCertifications2" v-model="companyActivityCertifications2" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityCertifications3" >{{searchevents(this.labels,'companies','companyActivityCertifications3')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >DEN</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityCertifications3" v-model="companyActivityCertifications3" ></textarea>
          </div>
           
        </div>

          <!-- Input evidenziato per aziende inglesi -->
        <!-- <div v-if="this.countryOfOrigin=='2'" class="row">  -->
        <div v-if="this.lang=='Eng'" class="row">  
          <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityCertifications2" >{{searchevents(this.labels,'companies','companyActivityCertifications2')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" ><b><mark><u>->ENG</u></mark></b></label>
            <textarea class="form-control" rows="2" id="companyActivityCertifications2" v-model="companyActivityCertifications2" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">     
            <!-- <label for="companyActivityCertifications" >{{searchevents(this.labels,'companies','companyActivityCertifications')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >ITA</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityCertifications" v-model="companyActivityCertifications" ></textarea>
          </div>
         <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityCertifications3" >{{searchevents(this.labels,'companies','companyActivityCertifications3')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >DEN</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityCertifications3" v-model="companyActivityCertifications3" ></textarea>
          </div>
           
        </div>


        <!-- Input evidenziato per aziende danesi -->
        <!-- <div v-if="this.countryOfOrigin=='504'" class="row"> -->
       <div v-if="this.lang=='Den'" class="row"> 
          <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityCertifications3" >{{searchevents(this.labels,'companies','companyActivityCertifications3')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" ><b><mark><u>->DEN</u></mark></b></label>
            <textarea class="form-control" rows="2" id="companyActivityCertifications3" v-model="companyActivityCertifications3" ></textarea>
          </div>   
          <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityCertifications2" >{{searchevents(this.labels,'companies','companyActivityCertifications2')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >ENG</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityCertifications2" v-model="companyActivityCertifications2" ></textarea>
          </div>
          <div class="col-4" style="text-align:center;">
            <!-- <label for="companyActivityCertifications" >{{searchevents(this.labels,'companies','companyActivityCertifications')}}</label> -->
            <label for="companyActivityDescription" v-bind:class="[this.getUserRole!='BOF' ? '' : 'requiredMod']" >ITA</label>
            <textarea style="background: #f0f0f0" class="form-control" rows="2" id="companyActivityCertifications" v-model="companyActivityCertifications" ></textarea>
          </div>
          
        </div>


         <!-- fine Input evidenziato per aziende -->



            <div class="col-4" style="text-align:center; margin-top: 1%;">
              <button type="button" data-toggle="button" class="btn-yellow-tooltip" v-tooltip="{content: searchevents(labels,'tooltipval','translationforbusinessdata'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
               <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#0c294b;width: 100%"></span>
               </button>
            <h6>{{searchevents(this.labels,'menu','usetranslation')}}</h6>
            
            
             <!-- traduzione per paese registrazione
            <button v-if="this.countryOfOrigin=='1'" type="button" class="btn btn-success" style="font-size: .8em; "  @click="translateMe(3)" >{{searchevents(labels,'menu','translateitatoengdan')}}</button>
            <button v-if="this.countryOfOrigin=='2'" type="button" class="btn btn-success" style="font-size: .8em; "  @click="translateMe(3)" >{{searchevents(labels,'menu','translateengtoitadan')}}</button>
            <button v-if="this.countryOfOrigin=='504'" type="button" class="btn btn-success" style="font-size: .8em; "  @click="translateMe(3)" >{{searchevents(labels,'menu','translatedantoengita')}}</button>
            -->
            <button v-if="this.lang=='Ita'" type="button" class="btn btn-success" style="font-size: .8em; "  @click="translateMe(3)" >{{searchevents(labels,'menu','translateitatoengdan')}}</button>
            <button v-if="this.lang=='Eng'" type="button" class="btn btn-success" style="font-size: .8em; "  @click="translateMe(3)" >{{searchevents(labels,'menu','translateengtoitadan')}}</button>
            <button v-if="this.lang=='Den'" type="button" class="btn btn-success" style="font-size: .8em; "  @click="translateMe(3)" >{{searchevents(labels,'menu','translatedantoengita')}}</button>
           
          </div>
        <!-- #################################################################################################### -->     


        </div> <!-- chiusura col -->


      </div>

      
      <hr>


      <div class="row">
        <div class="col-12">

        <div class="row">
          <div class="col-12">
          <div class="row"> 
          <div class="col-12" style="text-align:center;font-weight:bold"><h3>{{searchevents(this.labels,'companies','companyNotesusr')}}</h3></div>
         </div>
          <div class="form-group">
            <!-- <label for="companyNotes" >{{searchevents(this.labels,'companies','companyNotes')}}</label> -->
            <textarea class="form-control" rows="2" id="companyNotes" v-model="companyNotes" ></textarea>
          </div>
          </div>

        </div> <!-- row -->


        </div> <!-- chiusura col -->


      </div>

      <hr>

      <div class="form-goup ">
        <h3>{{searchevents(this.labels,'companies','registeredoperationallabel')}} </h3><br>
        <div class="row">
             <div class="col-4">
                <label for="operationalCountry" >{{searchevents(this.labels,'companies','operationalCountry')}}</label>
                <select class="form-control" v-model="operationalCountry"  >
                    <template v-for="operationalCountry in this.dropMofficeRegion ">
                        <option v-for="(value, key) in operationalCountry" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                    </template>
                </select>
              </div>
              <div class="col-sm">
                  <label for="operationalRegion" >{{searchevents(this.labels,'companies','operationalRegion')}}</label>
                  <input class="form-control" type="operationalRegion" id="operationalRegion" v-model="operationalRegion" >
              </div>
              <div class="col-sm">
                <label for="operationalCity" >{{searchevents(this.labels,'companies','operationalCity')}}</label>
                <input class="form-control" type="operationalCity" id="operationalCity" v-model="operationalCity" >
              </div>
              
          </div>
      </div>

      <div class="form-group ">
         <div class="row">
              <div class="col-sm">
                  <label for="operationalDistrict" >{{searchevents(this.labels,'companies','operationalDistrict')}}</label>
                  <input class="form-control" type="operationalDistrict" id="operationalDistrict" v-model="operationalDistrict" >
              </div>
              <div class="col-2">
                <label for="operationalTown" >{{searchevents(this.labels,'companies','operationalTown')}}</label>
                <input class="form-control" type="operationalTown" id="operationalTown" v-model="operationalTown" >
              </div>
              <div class="col-2">
                <label for="operationalPostcode" >{{searchevents(this.labels,'companies','operationalPostcode')}}</label>
                <input class="form-control" type="operationalPostcode" id="operationalPostcode" v-model="operationalPostcode" >
              </div>
              
              <div class="col-4">
                <label for="operationalAddress" >{{searchevents(this.labels,'companies','operationalAddress')}}</label>
                <input class="form-control" type="operationalAddress" id="operationalAddress" v-model="operationalAddress" >
              </div>
         </div>
      </div>

      <hr>
      <div class="form-group">
        <div class="container tablelist" style="background-color: aliceblue;" v-if="this.id!=null">
          <div>
            <h3>{{searchevents(this.labels,'menu','companycountrylist')}}</h3> 
          </div>
          
              <template  >
                
                <table class="table tablelist" >
                  <thead>
                    <tr>
                      <th>{{searchevents(this.labels,'table','countryname')}}</th>
                      <th>{{searchevents(this.labels,'table','countrypriority')}}</th>
                      <th>{{searchevents(this.labels,'menu','delete')}}</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="row in countryList" :key="row.id">
                      <td  :key="row.serv_countries_list.countryName">
                        {{ row.serv_countries_list.countryDescription }} 
                      </td>
                       <td  :key="row.id" v-if="row.priorityOrder==1 && lang=='Ita'">Alta</td>
                       <td  :key="row.id" v-if="row.priorityOrder==1 && lang=='Eng'">High</td>
                       <td  :key="row.id" v-if="row.priorityOrder==1 && lang=='Den'">Høj</td>

                       <td  :key="row.id" v-if="row.priorityOrder==2 && lang=='Ita'">Media</td>
                       <td  :key="row.id" v-if="row.priorityOrder==2 && lang=='Eng'">Average</td>
                       <td  :key="row.id" v-if="row.priorityOrder==2 && lang=='Den'">Medier</td>

                       <td  :key="row.id" v-if="row.priorityOrder==3 && lang=='Ita'">Bassa</td>
                       <td  :key="row.id" v-if="row.priorityOrder==3 && lang=='Eng'">Low</td>
                       <td  :key="row.id" v-if="row.priorityOrder==3 && lang=='Den'">Lav</td>

                      <td  :key="row.createdAt">
                        <button type="button" class="btn btn-danger" style="font-size: .8em;" data-dismiss="modal" @click="onClickDeleteCcb(row.id)" :key="row.id" >{{searchevents(labels,'menu','delete')}}</button>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
                
              </template>
          
          
            <div class="row">
              <div class="col-sm">
                <label for="officeCountry">{{searchevents(this.labels,'companies','officeCcbCountry')}}</label>
                    <select class="form-control" v-model="newCcbCountry" >
                        <template v-for="officeCountry in this.dropMofficeRegion ">
                            <option v-for="(value, key) in officeCountry" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                        </template>
                    </select>
              </div>
              <div class="col-sm">
                <label for="sicSector">{{searchevents(this.labels,'menu','priorityglobal')}}</label>
                <!-- <input class="form-control" type="newCcbPriorityOrder" id="newCcbPriorityOrder" v-model="newCcbPriorityOrder" > -->
                  <select class="form-control" v-model="newCcbPriorityOrder" >
                    <option disabled value="0"></option>

                    <option value="1" v-if="lang=='Ita'" >Alta</option>
                    <option value="1" v-if="lang=='Eng'" >High</option>
                    <option value="1" v-if="lang=='Den'" >Høj</option>

                    <option value="2" v-if="lang=='Ita'" >Media</option>
                    <option value="2" v-if="lang=='Eng'" >Average</option>
                    <option value="2" v-if="lang=='Den'" >Medier</option>

                    <option value="3" v-if="lang=='Ita'" >Bassa</option>
                    <option value="3" v-if="lang=='Eng'" >Low</option>
                    <option value="3" v-if="lang=='Den'" >Lav</option>

                    
                  </select>
              </div>

              <div class="col-sm">
                <button type="button" @click="addCcb()" class="btn btn-success" style="font-size: .9em; margin-top: 35px;" >{{searchevents(this.labels,'menu','add')}}</button>
              </div>
            </div>
        </div>
      </div>

      <!--
      <hr>
      <div class="form-group">
        <h3>{{searchevents(this.labels,'companies','onlyforitaliancompanies')}} </h3><br>
         <div class="row">
             
              <div class="col-sm">
                <label for="initialCamComm" >{{searchevents(this.labels,'companies','initialCamComm')}}</label>
                <select class="form-control" v-model="initialCamComm" >
                    <template v-for="initialCamComm in this.dropsinitialCamComm ">
                        <option v-for="(value, key) in initialCamComm" :value="value['id']" :key="key" >{{value['abbreviation']}}</option>
                    </template>
                </select>
              </div>
         </div>
      </div>
      -->

      <br>
      <!--
      <hr>
      <h3>{{searchevents(this.labels,'menu','notobligatorydataforcompany')}}</h3>
      -->
      <!-- ############################################################### -->


      <!-- hidden ###################################################################################################################### -->
      <input type="hidden" id="id" name="id" v-model="id">
      <input type="hidden" id="deleted" name="deleted" v-model="deleted">
      
      <!-- ############################################################################################################################# -->
       <div class="form-group ">
         <div class="row">
              <div class="col-sm">
                <label for="incorporationDate" >{{searchevents(this.labels,'companies','incorporationDate')}}</label>
                <!-- stefano 09/03/2022
                <datepicker :value="this.incorporationDate" v-model="incorporationDate" name="incorporationDate" id="incorporationDate" ></datepicker>
                -->
                <br>
                <input style="text-align: center; margin-left:25%;margin-right:20%" type="date" v-model="incorporationDate" />
              </div>
              
              <div class="col-sm">
                <label for="servCompaniesTurnoverId" >{{searchevents(this.labels,'companies','servCompaniesTurnoverId')}}</label>
                <select class="form-control" v-model="servCompaniesTurnoverId" >
                    <template v-for="servCompaniesTurnoverId in this.dropCompaniesTurnover ">
                        <option v-for="(value, key) in servCompaniesTurnoverId" :value="value['id']" :key="key" >{{value['turnover']}}</option>
                    </template>
                </select>
              </div>

              <div class="col-sm">
                <label for="servCompaniesTurnoverYear" >{{searchevents(this.labels,'companies','year')}}</label>
                <select class="form-control " v-bind:class="[this.getUserRole!='BOF' ? '' : 'greenrequired']" v-model="servCompaniesTurnoverYear"  >
                    <!-- <template v-for="item in 15"> -->
                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                    <!-- </template> -->
                </select>
              </div>
              
         </div>
      </div>

       <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="webSite">{{searchevents(this.labels,'companies','webSite')}}</label>
                <input class="form-control" type="webSite" id="webSite" v-model="webSite" >
              </div>
              <div class="col-sm">
                <label for="linkedin">{{searchevents(this.labels,'companies','linkedin')}}</label>
                <input class="form-control" type="linkedin" id="linkedin" v-model="linkedin" >
              </div>
         </div>
      </div>
      <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="twitter">{{searchevents(this.labels,'companies','twitter')}}</label>
                <input class="form-control" type="twitter" id="twitter" v-model="twitter" >
              </div>
              <div class="col-sm">
                <label for="facebook">{{searchevents(this.labels,'companies','facebook')}}</label>
                <input class="form-control" type="facebook" id="facebook" v-model="facebook" >
              </div>
         </div>
      </div>

      <hr v-if="getUserRole=='BOF'">
      <div class="form-group" v-if="getUserRole=='BOF'">
         <div class="row">
           <!--
              <div class="col-sm">
                <label for="servCompaniesBusinessSectorId">{{searchevents(this.labels,'companies','servCompaniesBusinessSectorId')}}</label>
                <select class="form-control" v-if="this.lang=='Ita'" v-model="servCompaniesBusinessSectorId" >
                    <template v-for="servCompaniesBusinessSectorId in this.dropCompaniesBusinessSector ">
                        <option v-for="(value, key) in servCompaniesBusinessSectorId" :value="value['id']" :key="key" >{{value['sectorIta']}}</option>
                    </template>
                </select>
                <select class="form-control" v-if="this.lang=='Eng'" v-model="servCompaniesBusinessSectorId" >
                    <template v-for="servCompaniesBusinessSectorId in this.dropCompaniesBusinessSector ">
                        <option v-for="(value, key) in servCompaniesBusinessSectorId" :value="value['id']" :key="key" >{{value['sectorEng']}}</option>
                    </template>
                </select>
                
              </div>
            -->
              <div class="col-sm">
                <label for="dissolutionDate">{{searchevents(this.labels,'companies','dissolutionDate')}}</label>
                <!-- stefano 09/03/2022
                <datepicker :value="this.dissolutionDate" v-model="dissolutionDate" name="dissolutionDate" id="dissolutionDate"></datepicker>
                -->

                <br>
                <input style="text-align: center; margin-left:25%;margin-right:20%" type="date" v-model="dissolutionDate"  />   

              </div>
              
         </div>
      </div>

      <hr>
      <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="partOfGroup">{{searchevents(this.labels,'companies','partOfGroup')}}</label>
                <input class="form-control" type="checkbox" id="partOfGroup" value="partOfGroup" v-model="partOfGroup"> 
              </div>
              <div class="col-sm">
                <label for="groupName">{{searchevents(this.labels,'companies','groupName')}}</label>
                <input class="form-control" type="groupName" id="groupName" v-model="groupName" >
              </div>
              <div class="col-sm">
                <label for="groupIdTax">{{searchevents(this.labels,'companies','groupIdTax')}}</label>
                <input class="form-control" type="groupIdTax" id="groupIdTax" v-model="groupIdTax" >
              </div>
         </div>
      </div>

      <!--
      <hr>
      <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="sicCode1">{{searchevents(this.labels,'companies','sicCode1')}}</label>
                <input class="form-control" type="sicCode1" id="sicCode1" v-model="sicCode1" >
              </div>
              <div class="col-sm">
                <label for="sicCode2">{{searchevents(this.labels,'companies','sicCode2')}}</label>
                <input class="form-control" type="sicCode2" id="sicCode2" v-model="sicCode2" >
              </div>
              <div class="col-sm">
                <label for="sicCode3">{{searchevents(this.labels,'companies','sicCode3')}}</label>
                <input class="form-control" type="sicCode3" id="sicCode3" v-model="sicCode3" >
              </div>
              <div class="col-sm">
                <label for="sicCode4">{{searchevents(this.labels,'companies','sicCode4')}}</label>
                <input class="form-control" type="sicCode4" id="sicCode4" v-model="sicCode4" >
              </div>
         </div>
      </div>

      <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="naceMacro">{{searchevents(this.labels,'companies','naceMacro')}}</label>
                <input class="form-control" type="naceMacro" id="naceMacro" v-model="naceMacro" >
              </div>
              <div class="col-sm">
                <label for="naceSector">{{searchevents(this.labels,'companies','naceSector')}}</label>
                <input class="form-control" type="naceSector" id="naceSector" v-model="naceSector" >
              </div>
         </div>
      </div>

      <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="sicMacro">{{searchevents(this.labels,'companies','sicMacro')}}</label>
                <input class="form-control" type="sicMacro" id="sicMacro" v-model="sicMacro" >
              </div>
              <div class="col-sm">
                <label for="sicSector">{{searchevents(this.labels,'companies','sicSector')}}</label>
                <input class="form-control" type="sicSector" id="sicSector" v-model="sicSector" >
              </div>
         </div>
      </div>

      <hr>
      <div class="form-group">
         <div class="row">
              <div class="col-sm">
                <label for="inspectionDate">{{searchevents(this.labels,'companies','inspectionDate')}}</label>
                <datepicker :value="this.inspectionDate" v-model="inspectionDate" name="inspectionDate" id="inspectionDate"></datepicker>
              </div>
              <div class="col-sm">
                <label for="lastInspectionDate">{{searchevents(this.labels,'companies','lastInspectionDate')}}</label>
                <datepicker :value="this.lastInspectionDate" v-model="lastInspectionDate" name="lastInspectionDate" id="lastInspectionDate"></datepicker>
              </div>
         </div>
      </div>
      
      
      <hr>
      <div class="row" v-if="getUserRole=='BOF'">
              <div class="col-sm">
                <button   type="button" class="btn btn-success" @click="sendEmailBO('email-avvenuta-validazione','end1a')" style="font-size: .9em;margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'bof','end1a')}}</button>
              </div>
              <div class="col-sm">
                <button  type="button" class="btn btn-info" @click="sendEmailBO('email-richiesta-info-integrativa','viib1')" style="font-size: .9em;margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'bof','viib1')}}</button>
                </div>
              <div class="col-sm">
                <button  type="button" class="btn btn-info" @click="sendEmailBO('email-richiesta-info-integrativa','viib2')" style="font-size: .9em;margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'bof','viib2')}}</button>
                </div>
              <div class="col-sm">
                <button  type="button" class="btn btn-warning" @click="sendEmailBO('email-sollecito-pec','viia')" style="font-size: .9em;margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'bof','viia')}}</button>
                </div>
              <div class="col-sm">
                <button  type="button" class="btn btn-danger" @click="sendEmailBO('email-rifiuto-attivazione','end2a')" style="font-size: .9em;margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'bof','end2a')}}</button>
                </div>
              <div class="col-sm">
                <button  type="button" class="btn btn-danger" @click="sendEmailBO('email-rifiuto-attivazione','end2b')" style="font-size: .9em;margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'bof','end2b')}}</button>
                </div>
              <div class="col-sm">
                <button  type="button" class="btn btn-danger" @click="sendEmailBO('email-rifiuto-attivazione','end2c')" style="font-size: .9em;margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'bof','end2c')}}</button>
              </div>
      </div>
      -->
      <hr>
      
      <div class="col-12" style="text-align:center; margin-bottom: 100px;" v-if="this.id!=null && getUserRole!='BOF'">
          <button id="snc" ref="snc"  v-if="this.id!=null && getUserRole!='BOF' && (this.servCompaniesStateCompaniesState=='A' || this.servCompaniesStateCompaniesState=='C')" type="submit" class="btn btn-dark" style="margin-bottom:0px;margin-top:15px">{{searchevents(this.labels,'menu','onlysave')}}</button>
          
        </div>
      <!--
      <button  v-if="this.id!=null && getUserRole!='BOF' && this.servCompaniesStateCompaniesState!='C'" type="submit" class="btn btn-dark" style="margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'controls','saveandcontinue')}}</button>
      <button  v-if="this.id==null && getUserRole!='BOF' && this.servCompaniesStateCompaniesState!='C'" type="submit" class="btn btn-dark" style="margin-bottom:55px;margin-top:15px">{{searchevents(this.labels,'controls','insertandcontinue')}}</button>
      -->
      
    </div> <!-- chiusura secondo pannello -->

    <div class="modal" id="myModalAlert" ref="myModalAlert" role="dialog" >
      asa sasasasa sa
    </div>

    <template v-if="poupvisibile" >
      <div class="alert alert-warning" role="alert" style="margin-top:5px;">
        {{this.msg}}
      </div>
    </template>
<!-- salva e conferma azioni -->
   <button  v-if="this.id!=null && getUserRole=='BOF'" type="submit" class="btn btn-success" style="margin-bottom:55px;margin-top:15px;font-size: 1.7em;">{{searchevents(this.labels,'controls','saveandcontinuebof')}}</button>

<!-- Comunicazione al cliente ESITO BACKOFFICE  BO -->
    <div v-if="this.id!=null && getUserRole=='BOF'">
       <hr>
       <h3><strong>Comunicazione al cliente esito verifiche BO</strong></h3>
       <div v-if="this.companySuccessfulBO!='A' && this.companySuccessfulBO!='J'">
           <button type="button" class="btn btn-secondary btn-lg" style="height: 60px; font-size: 1.0em;margin-bottom:5px;margin-top:15px;" disabled>Nessuna comunicazione attualmente Possibile - Pratica da lavorare</button>
           <h6><strong>Verificare i Dati LR e Impresa per abilitare il bottone di approvazione/rifiuto</strong></h6>
      </div>
           <button v-if="this.companySuccessfulBO=='A'" type="button" class="btn btn-okbo" @click="sendEmailBO('email-avvenuta-validazione','end1a')" style="height: 60px; font-size: 1.0em;margin-bottom:5px;margin-top:15px;">{{searchevents(this.labels,'bof','end1a')}}</button>
          <!-- <button  type="button" class="btn btn-danger"  @click="sendEmailBO('email-rifiuto-attivazione','end2a')" style="font-size: .9em;margin-bottom:0px;margin-top:15px">{{searchevents(this.labels,'bof','end2a')}}</button> -->
           <button v-if="this.companySuccessfulBO=='J'" type="button" class="btn btn-danger"  @click="sendEmailBO('email-rifiuto-attivazione','end2b')" style="height: 60px; font-size: 1.0em;margin-bottom:5px;margin-top:15px;">{{searchevents(this.labels,'bof','end2b')}}</button>
       
     </div>

 <!-- ALTRE AZIONI BACKOFFICE  BO -->
      <hr v-if="getUserRole=='BOF'">
       <div class="form-group"  v-if="getUserRole=='BOF'">
         <h3><strong>Altre azioni di Backoffice</strong></h3>
         <br>
         <div class="row">
              <div class="col-5">
                <label style="margin-left:45%" for="facebook">{{searchevents(this.labels,'companies','updatedAt')}}</label>
               <div  style="margin-left:46%"> 
                 
                 <datepicker :value="this.updatedAt" v-model="updatedAt" name="updatedAt" id="updatedAt" :disabled='isNewInsert'></datepicker> 
                
                <!--
                 <br>
                 <input style="text-align: center; margin-left:25%;margin-right:20%" type="date" v-model="updatedAt" :disabled='isNewInsert' required />  
                 -->
                 
                 </div>
              </div>
              <div class="col-5">
                <label for="lastAction">{{searchevents(this.labels,'companies','lastAction')}}</label>
                <textarea class="form-control" rows="2" id="lastAction" v-model="lastAction" ></textarea>
              </div>
          </div>
          <br>
          <div class="row">
            
              <!--  insert CSS2-01-02 --> 

             <div class="col-5" style=";margin-left:33%;" >
               <label  style="margin-left:22%; display:none;" for="facebook">Data ultima azione</label>

               <div  style="margin-left:22%">  
                 <!-- <datepicker :value="this.lastActionDate" v-model="lastActionDate" name="lastActionDate" id="lastActionDate" ></datepicker> -->

               </div>
               
               <!--  div sopra aggiunto da stefano -->
               <!--  data ultima azione ELIMINATA DA STEFANO -->
                 <!--   <div class="col-5" style=";margin-left:33%;" > -->
              
               <!--   <label  style="margin-left:22%" for="facebook">Data ultima azione</label>  -->
               <!--  <div  style="margin-left:22%"> 
               <datepicker :value="this.lastActionDate" v-model="lastActionDate" name="lastActionDate" id="lastActionDate" ></datepicker> -->
               <!-- </div> -->
           
               <br>
                <!-- nuovo tasto ok visura approvata -->     
            
              <button type="button" class="btn btn-okbo" @click="confirmVisuraUpdated()" style="margin-bottom: 5px; margin-top: 5px; font-size: 1.0em; margin-left:90px;">Conferma aggiornamento visura</button>
              <br> 
              <br> 
             <!-- salva e conferma azioni -->
             <button  v-if="this.id!=null && getUserRole=='BOF'" type="submit" class="btn btn-success" style="margin-left:60px;margin-bottom:55px;margin-top:15px;font-size: 1.7em;">{{searchevents(this.labels,'controls','saveandcontinuebof')}}</button>
             <!-- <button type="button" class="btn btn-danger" data-dismiss="modal" @click="CalcolaCF()">CF</button> -->
              </div>
          </div>
           <!-- nuovo campo note da finire -->  
          <!-- NUOVO CAMPO NOTE NOTE2-->
          <hr>
          <!-- inizio GESTIONE STRAORDINARIA CONTRATTO (RICICLI) -->
           <div class="form-group"  v-if="getUserRole=='BOF'">
           <h3><strong>Gestione ricicli contrattuali</strong></h3>
           <br>
           </div>
          <div class="row" v-if="getUserRole=='BOF'">
              <div class="col-sm" >
                <button type="button" class="btn btn-recyclebo" style="font-size: 1em; margin-top: 3px; min-height:50px;max-width: 260px;" data-toggle="modal" data-target="#myModalSottoscrizioneContratto" >Genera Nuovo Contratto</button>
              </div>
              <div class="col-sm" >
                <button type="button" class="btn btn-recyclebo" style="font-size: 1em; margin-top: 3px; min-height:50px;max-width: 260px;" @click="this.confermaContrattoPre" >Conferma avvenuta sottoscrizione nuovo Contratto</button>
              </div>
           <br> <br>
         </div>
         <hr v-if="getUserRole=='BOF'">
   <!-- fine GESTIONE STRAORDINARIA CONTRATTO (RICICLI) -->
           <div class="row">

              <div style="text-align: center;margin-left:16%;margin-right:18%;max-width:1200px" class="col">
                <label for="lastAction2">STORICO INTERAZIONI CON IL CLIENTE</label>
                <textarea class="form-control" rows="9" id="boNotes" v-model="boNotes" ></textarea>

              </div>
             </div>
              <!-- salva e conferma azioni -->
           <button  v-if="this.id!=null && getUserRole=='BOF'" type="submit" class="btn btn-success" style="margin-right:30px;margin-bottom:55px;margin-top:15px;font-size: 1.7em;">{{searchevents(this.labels,'controls','saveandcontinuebof')}}</button>
         
           <br>
           <br>
          
          <br>
      </div>
            
    </div> <!-- chiusura tab content -->
    </form>
    <!-- FINE ALTRE AZIONI BACKOFFICE  BO -->
   
    <div class="container">
      <!-- Button to Open the Modal 
      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myModal">
        Open modal
      </button>-->

      <!-- The Modal -->

      <div class="modal" id="VisuraModal">
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Visura</h4>
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              {{searchevents(this.labels,'dialog','insertordinaryvisura')}}
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-dark" data-dismiss="modal" >Chiudi</button>
            </div>
            
          </div>
        </div>
      </div>

      <div class="modal" id="docsModal">
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <!-- <h4 class="modal-title">{{this.docDescriptionSelected}}</h4> -->
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              <form @submit.prevent="onSubmitNewDoc" >
                
                  <div class="col-sm">
                    
                   {{searchevents(this.labels,'menu','documentname')}} -  {{this.docDescriptionSelected2}}
                  </div>
                  <div class="col-sm">
                   <!-- {{searchevents(this.labels,'menu','documentdescription')}} -->
                   <textarea class="form-control" rows="1" id="docDescriptionSelected3" v-model="docDescriptionSelected3" style="visibility: hidden;" ></textarea>
                  </div>
                  <div class="col-sm" style="margin-top:20px;">
                    <label for="sicSector">{{searchevents(this.labels,'menu','newcompanydocname')}} </label> <br>
                     <label for="sicSector"><strong>{{viewDocTypeDesc(this.docTypeSelected)}}</strong></label>
                    <input v-if="this.docTypeSelected==8 || this.docTypeSelected==9" class="form-control" style="visibility: hidden;" type="newDocName" id="newDocName" v-model="docDescriptionSelected" >
                    <input v-if="this.docTypeSelected!=8 && this.docTypeSelected!=9" class="form-control" style="visibility: hidden;" type="newDocName" id="newDocName" v-model="docDescriptionSelected" :disabled='isNewInsert'>
                    <input class="form-control" type="hidden" id="docId" v-model="this.docIdSelected">
                  </div>
                  <div class="col-sm" style="margin-top:20px;">
                    <template v-if="getUserRole!='BOF'">
                      <label for="sicSector">{{searchevents(this.labels,'dialog','menufile')}}</label>
                      <input v-if="this.docTypeSelected==1 || this.docTypeSelected==2 || this.docTypeSelected==3 || this.docTypeSelected==7 || this.docTypeSelected==8 || this.docTypeSelected==9 || this.docTypeSelected==10 || this.docTypeSelected==11" type="file" @change="this.previewFile" ref="mynewdoc" class="form-control-file" id="newDocs" accept=".pdf">
                      <input v-if="this.docTypeSelected==4 || this.docTypeSelected==6" type="file" @change="this.previewFile" ref="mynewdoc" class="form-control-file" id="newDocs" accept=".jpg">
                      <input v-if="this.docTypeSelected==5" type="file" @change="this.previewFile" ref="mynewdoc" class="form-control-file" id="newDocs" accept=".mp4">
                    </template>
                    <template v-if="getUserRole=='BOF'">  <!-- tipi tipo file accettati in modale da BO -->
                      <label for="sicSector">{{searchevents(this.labels,'dialog','menufile')}} BO</label>
                      <input  type="file" @change="this.previewFile" ref="mynewdoc" class="form-control-file" id="newDocs" accept=".pdf">
                    </template>
                  </div>
              </form>
              
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-dark" data-dismiss="modal" @click="onSubmitNewDoc()">{{searchevents(this.labels,'menu','uploadconfirm')}}</button>
            </div>
            
          </div>
        </div>
      </div>

      <div class="modal" id="myModal">
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title" v-if="this.lang=='Ita'">Codice</h4>
              <h4 class="modal-title" v-if="this.lang!='Ita'">Code</h4>
              <button type="button" class="close" data-dismiss="modal" @click="setSelectedAteco('','')">&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              
              <div>
                <span>{{searchevents(this.labels,'menu','selectatecocode')}}</span> <input type="text" v-model="atecoSearch">
              </div>
              <template v-if="this.lang=='Ita'">
                <select size="10" style="width: 100%;" v-model="selectedAtecoList" >
                     <option v-for="item in filteredItems" :key="item.id" :value="item.ATTIVIT_CON_PUNTO_3LIVELLI">{{item.ATTIVIT_CON_PUNTO_3LIVELLI}} - {{item.Descrizione}}</option>
              </select>
              </template>

          <template v-if="lang === 'Eng'">
          <select size="10" style="width: 100%;" v-model="selectedAtecoList">
<!-- vue 2
     <template v-for="item in filteredItemsEng"  >
                    <option :key="item.id" :value="item.ATTIVIT_CON_PUNTO_3LIVELLI">{{item.ATTIVIT_CON_PUNTO_3LIVELLI}} - {{item.Descrizione_eng}}</option>
  -->                
            <option v-for="item in filteredItemsEng" :key="item.id" :value="item.ATTIVIT_CON_PUNTO_3LIVELLI">
            {{ item.ATTIVIT_CON_PUNTO_3LIVELLI }} - {{ item.Descrizione_eng }}
             </option>

            </select>
            </template>

              <template v-if="this.lang=='Den'">
                <select size="10" style="width: 100%;" v-model="selectedAtecoList" >
                     <option v-for="item in filteredItemsDen"  :key="item.id" :value="item.ATTIVIT_CON_PUNTO_3LIVELLI">{{item.ATTIVIT_CON_PUNTO_3LIVELLI}} - {{item.Descrizione_den}}</option>
                  </select>
              </template>
              
            </div>
            
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-dark" data-dismiss="modal" @click="setSelectedAtecoApply()">{{searchevents(this.labels,'menu','selecttoconfirm')}}</button>
            </div>
            
          </div>
        </div>
      </div>

      <div class="modal" id="myModalUK">
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title" >SIC CODE</h4>
              <button type="button" class="close" data-dismiss="modal" @click="setSelectedAteco('','')">&times;</button>
            </div>
            
            <!-- Modal body SIC-->
            <div class="modal-body">
              
              <div>
                <span>{{searchevents(this.labels,'menu','searchatecofronsic')}}</span> <input type="text" v-model="atecoSearch">
              </div>
              
              <select size="10" style="width: 100%;" v-model="selectSicCodeList" >
                     <option v-for="item in filteredItemsSic" :key="item.SIC_Company_house" :value="item">{{item.SIC_Code}} - {{item.Company_house_SIC_Des}}</option>
              </select>
              
              
            </div>
            
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-dark" data-dismiss="modal" @click="setSelectedAtecoApply()">{{searchevents(this.labels,'menu','selecttoconfirm')}}</button>
            </div>
            
          </div>
        </div>
      </div>

      <div class="modal" id="myModalPEC" >
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">PEC</h4>
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              
              <div>
                <h3>{{searchevents(this.labels,'bof','pecresentmodal')}}</h3>
              </div>
              <div class="row">
                <div class="col-sm">
                    <label for="pec">{{searchevents(this.labels,'companies','pec')}}</label>
                    <input class="form-control" type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" id="pecM" v-model="pec" >
                </div>
              </div>
              
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-success" data-dismiss="modal" @click="actionUserBOPec()">Invia PEC</button>
            </div>
            
          </div>
        </div>
      </div>

      <div class="modal" id="myModalPEC2" >
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">PEC</h4>
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              
              <div>
                <h3>{{searchevents(this.labels,'bof','pecresentmodal')}}</h3>
              </div>
              <div class="row">
                <div class="col-sm">
                    <label for="pec">{{searchevents(this.labels,'companies','pec')}}</label>
                    <input class="form-control" type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" id="pecM" v-model="pec" >
                </div>
              </div>
              
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-success" data-dismiss="modal" @click="actionUserBOPec2()">Invia PEC</button>
            </div>
            
          </div>
        </div>
      </div>

      <div class="modal" id="sevedConfirmation" ref="sevedConfirmation">
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title"></h4>
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              
              <div>
                <h3>{{this.msg}}</h3>
              </div>
              
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-success" data-dismiss="modal" >{{searchevents(this.labels,'menu','closeglobal')}}</button>
            </div>
            
          </div>
        </div>
      </div>

      <div class="modal" id="myModalSottoscrizioneContratto">
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Elenca i campi modificati</h4>
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              
              <div class="row">
                <div class="col-sm">
                    <textarea class="form-control" rows="2" id="campiModificatiSottoscrizioneContratto" v-model="campiModificatiSottoscrizioneContratto" ></textarea>
                </div>
              </div>
              
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-success" data-dismiss="modal" @click="this.generaNuovoContrattoPre" >Invio</button>
            </div>
            
          </div>
        </div>
      </div>

      <div class="modal" id="myModalInfoIntegrative">
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Specificare il contenuto delle informazioni integrative da inviare alla Società</h4>
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              
              <div>
                <h3>Testo:</h3>
              </div>
              <div class="row">
                <div class="col-sm">
                    <textarea class="form-control" rows="2" id="infoIntegrative" v-model="infoIntegrative" ></textarea>
                </div>
              </div>
              
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-success" data-dismiss="modal" @click="sendEmailBO('email-richiesta-info-integrativa','viib2')">Invia Email LR</button>
            </div>
            
          </div>
        </div>
      </div>

      <div class="modal" id="myModalEmailLR">
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Email LR</h4>
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              
              <div>
                <h3>{{searchevents(this.labels,'bof','pemaillrresentmodal')}}</h3>
              </div>
              <div class="row">
                <div class="col-sm">
                    <label for="emailLR">{{searchevents(this.labels,'users','email')}}</label>
                    <input class="form-control" type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" id="emailLRM" v-model="emailLR" >
                </div>
              </div>
              
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-success" data-dismiss="modal" @click="actionUserBOLR()">Invia Email LR</button>
            </div>
            
          </div>
        </div>
      </div>

     
    </div>


  </div>
</template>


<script>
import gAxios from 'axios';
import Datepicker from 'vuejs-datepicker';

import router from '@/router';
import CodiceFiscale from 'codice-fiscale-js';


//import https from 'https';
import moment from 'moment';

export default {
  name: 'UpdateCompany',
  components : {
    Datepicker,
    
  },
  data () {
    return {
      birthDateIta : { language: 'Italian', months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'], monthsAbbr: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'], days: ['Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa', 'Do'], rtl: false, ymd: false, yearSuffix: '' },
      birthDateEng : { language: 'English', months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'], monthsAbbr: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'], rtl: false, ymd: false, yearSuffix: '' },
      birthDateDen : { language: 'Denmark', months: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'], monthsAbbr: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'], days: ['Ma', 'Ti', 'On', 'To', 'Fr', 'Sa', 'Sø'], rtl: false, ymd: false, yearSuffix: '' },
      dateFormat : 'dd-MM-yyyy',
      id	: null,
      companyName	: '',
      pec	: '',
      emailAddress	: '',
      officeRegion	: '',
      officeDistrict	: '',
      officeCity	: '',
      officeTown	: '',
      officePostcode	: '',
      officeAddress	: '',
      vatNumber	: '',
      idTax	: '',
      companyNumber	: '',
      companyNumberCountry : null,
      operationalCountry	: '',
      operationalRegion	: '',
      operationalDistrict	: '',
      operationalCity	: '',
      operationalTown	: '',
      operationalPostcode	: '',
      operationalAddress	: '',
      reaNumber	: '',
      landlinePhone	: '',
      mobilePhone	: '',
      companyActivityDescription	: '',
      companyActivityProducts	: '',
      companyActivityCertifications	: '',
      companyActivityDescription2	: '',
      companyActivityProducts2	: '',
      companyActivityCertifications2	: '',
      companyActivityDescription3	: '',
      companyActivityProducts3	: '',
      companyActivityCertifications3	: '',
      companyNotes : '',
      boNotes : '',
      incorporationDate	: '',
      dissolutionDate	: '',
      partOfGroup	: '',
      groupName	: '',
      groupIdTax	: '',
      atecoCode1	: '',
      atecoCode2	: '',
      atecoCode3	: '',
      atecoCode4	: '',
      atecoCode5	: '',
      atecoCode6	: '',
      atecoCode7	: '',
      italianFCInorrect : '',
      naceCode1	: '',
      naceCode2	: '',
      naceCode3	: '',
      sicCode1	: '',
      sicCode2	: '',
      sicCode3	: '',
      sicCode4	: '',
      naceMacro	: '',
      naceSector	: '',
      sicMacro	: '',
      sicSector	: '',
      inspectionDate	: '',
      lastInspectionDate	: '',
      webSite	: '',
      linkedin	: '',
      twitter	: '',
      facebook	: '',
      lastAction : '',
      lastActionDate : '',
      deleted	: '0',
      blackListed : '',
      createdAt	: '',
      updatedAt	: '',
      companyguidContract : '',
      companyguidContractFlag : null,
      servMobilePrefixId	: '',
      servLandlinePrefixId	: '',
      servCompaniesStateCompaniesState	: 'P',
      servCompaniesCategoryId	: '',
      servCompaniesEmployeesNumId	: '',
      servCompaniesEmployeesYear : '',
      servCompaniesTurnoverId	: '',
      servCompaniesTurnoverYear	: '',
      servCompaniesBusinessSectorId	: '',
      countryOfOrigin	: '',
      officeCountry	: '',
      initialCamComm	: '',
      thisLang : 'Ita',
      dropMservLandlinePrefixId : null,
      dropMofficeRegion : null,
      dropMofficeRegionRegister : null,
      dropComanyState : null,
      dropCompaniesEmployees : null,
      dropCompaniesEmployeesIT : null,
      dropCompaniesEmployeesEN : null,
      dropCompaniesTurnover : null,
      dropCompaniesTurnoverIT : null,
      dropCompaniesTurnoverEN : null,
      dropCompaniesBusinessSector : null,
      dropCompaniesBusinessSectorIT : null,
      dropCompaniesBusinessSectorEN : null,
      dropsCompaniesCategory : null,
      dropsCompaniesCategoryIT : null,
      dropsCompaniesCategoryEN : null,
      dropsCompaniesCategoryDE : null,
      dropsinitialCamComm : null,
      dropsinitialCamCommIT : null,
      dropsinitialCamCommEN : null,
      postResponse : '---',
      registeredCompany : null,
      selectedAteco : null,
      selectedAtecoList : null,
      selectSicCodeList : null,
      atecoList : [],
      sicList : [],
      atecoSearch: '',
      sicSearch : '',
      docList : [],
      dropCompaniesDocs : null,
      dropCompaniesDocsIT : null,
      dropCompaniesDocsAll : null,
      selectedDocsList : null,
      confirmDeleteDoc : 'Confermi la cancellazione? / Confirm the cancellation?',
      confirmSubmitCompany : 'Hai controllato di avere inserito tutti i campi richiesti per l\'approvazione? / Have you checked that you have entered all the fields required for approval?',
      newcompanyDocId : null,
      newDocs : null,
      newDocName : null,
      newservDocTypeId : null,
      countryList : [],
      newCcbCountry : null,
      newCcbPriorityOrder : null,
      poupvisibile : false,
      msg : '',
      docIdSelected : null,
      docDescriptionSelected : null,
      docDescriptionSelected2 : null,
      docDescriptionSelected3 : null,
      docDescriptionDatabaseSelected : null,
      docTypeSelected : null,
      mycompaniesDetails : [],
      mycompaniesDetailsDocs : [
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''}],
      mycompaniesDetailsCountries: [
      {"cnam1":''}],
      companyTypeSelected : null,
      remoteServer : process.env.VUE_APP_CONN_NODEJS,
      selectedCompanyId : null,
      selectedGuid : '-1',
      selectedCompanyTable : null,
      newmessage : null,
      name : null,
      surname : null,
      sex : null,
      countryOfResidence : null,
      birthPlace : null,
      birthDate : null,
      documentNumber : null,
      servDocTypeIdLR : '',
      userIdLR : null,
      emailLR : null,
      fiscalCode : null,

      userEmailBO : null,
      companyReadyBO : null,
      userContractBO : null,
      companySuccessfulBO : null,
      companySuccessfulBOTrad : null,
      userSuccessfulBO : null,
      userSuccessfulBOTrad : null,
      companyGoBO : null,
      companyUserSuccessfulBO : 'NON APPROVATA',

      infoIntegrative : '',
      campiModificatiSottoscrizioneContratto : '',

      userEmailBOx: true,
      myusers : [],
      magic_flag : false,
      step1DocNumber : 0,

      disclaimer01: null,
      disclaimer02: null,
      disclaimer03: null,
      disclaimer04: null,
      disclaimer05: null,
      birthDate2 : '1980-03-23',
      generaNuovoContrattoAction : false
      
    }
  },
  methods : {  
    generaNuovoContrattoPre(){
      this.generaNuovoContrattoAction = true;
      this.$refs.bosaveandconfirm.click();
      
    },
    confermaApprovazioneLR(){
      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/updateCompanyLR',
                {
                   userID : this.userIdLR,
                },auth)
                .then(res => {
                //console.log(res);
                this.userEmailBO = true;
                alert('Mail LR Validata');
                return res;

      });
    },
    confermaContrattoPre(){

      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/updateCompanyContract',
                {
                   companyID : this.id,
                   
                },auth)
                .then(res => {
                //console.log(res);
                this.getregisteredCompany.contractSignedFlag='Y';
                alert('Contratto confermato');
                return res;

      });
    },
    generaNuovoContratto(){

      var name = this.$refs.servCompaniesCategoryId.options[this.$refs.servCompaniesCategoryId.selectedIndex].text;
      let servCompaniesCategoryText = name;
      //console.log('-------XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX---->' + name );

      //console.log('asasassasasasasasa');
      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/contractsignedmod',
                {
                   userId : this.userIdLR,
                   companyId: this.id,
                   servCompaniesCategoryText: servCompaniesCategoryText,
                   campiModificatiSottoscrizioneContratto : this.campiModificatiSottoscrizioneContratto,
                   actionTodo : 'NOCOMPANY'
                },auth)
                .then(res => {
                //console.log(res);
                return res;

      });
    },
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[a-zA-Z\\'\s]+$/.test(char)) return true;
      else e.preventDefault();
    },
     isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    downloadItem ( url, label ) {
      //console.log(url + '--------' + label);
      gAxios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(
          //console.error
          )
    },
     translateMe(operation){

        let texToTranslate = '';
        //let langFromPre = this.countryOfOrigin;
        let langFromPre = this.lang;
        let langFrom = '';
        if(langFromPre == 'Ita'){ langFrom = 'Ita'; }
        if(langFromPre == 'Eng'){ langFrom = 'Eng'; }
        if(langFromPre == 'Den'){ langFrom = 'Den'; }
        //let langFrom = this.lang;
        let langTo1 = '';
        let langTo2 = '';
        let textTranslated = '';

        if(operation==1){ 
          if(langFromPre == 'Ita'){  texToTranslate = this.companyActivityDescription; }
          if(langFromPre == 'Eng'){  texToTranslate = this.companyActivityDescription2; }
          if(langFromPre == 'Den'){  texToTranslate = this.companyActivityDescription3; }
        }
        if(operation==2){ 
          if(langFromPre == 'Ita'){  texToTranslate = this.companyActivityProducts; }
          if(langFromPre == 'Eng'){  texToTranslate = this.companyActivityProducts2; }
          if(langFromPre == 'Den'){  texToTranslate = this.companyActivityProducts3; }
        }
        if(operation==3){ 
          if(langFromPre == 'Ita'){  texToTranslate = this.companyActivityCertifications; }
          if(langFromPre == 'Eng'){  texToTranslate = this.companyActivityCertifications2; }
          if(langFromPre == 'Den'){  texToTranslate = this.companyActivityCertifications3; }
        }
        
        if(langFrom == 'Ita'){ langFrom = 'it'; langTo1 = 'en'; langTo2 = 'da';}
        if(langFrom == 'Eng'){ langFrom = 'en'; langTo1 = 'it'; langTo2 = 'da';}
        if(langFrom == 'Den'){ langFrom = 'da'; langTo1 = 'it'; langTo2 = 'en';}

        // E' SEMPRE LEGGE QUESTA REGOLA, ANCHE PER LA BUSINESS CARD #############################################################################
        //                          la gerarchia è sempre:
        // 1. propria lingua
        // 2. se propria lingua NON Eng, allora 2 = Eng, se Eng allora Ita
        // 3. sempre Den
        // #######################################################################################################################################
        
        gAxios.get('https://translate.googleapis.com/translate_a/single?client=gtx&sl='+langFrom+'&tl='+langTo1+'&dt=t&q='+texToTranslate,
                {
                   
                })
                .then(res => {
                    let tempTextTranslated = res.data[0];
                    textTranslated = '';
                    tempTextTranslated.forEach(element => {
                        textTranslated += element[0]+' ';
                        
                    });
                    
                    //if(operation==1){ this.companyActivityDescription2 = textTranslated; }
                    //if(operation==2){ this.companyActivityProducts2 = textTranslated; }
                    //if(operation==3){ this.companyActivityCertifications2 = textTranslated; }

                     if(operation==1){ 
                      if(langFromPre == 'Ita'){  this.companyActivityDescription2 = textTranslated; }
                      if(langFromPre == 'Eng'){  this.companyActivityDescription = textTranslated; }
                      if(langFromPre == 'Den'){  this.companyActivityDescription = textTranslated; }
                    }
                    if(operation==2){ 
                      if(langFromPre == 'Ita'){  this.companyActivityProducts2 = textTranslated; }
                      if(langFromPre == 'Eng'){  this.companyActivityProducts = textTranslated; }
                      if(langFromPre == 'Den'){  this.companyActivityProducts = textTranslated; }
                    }
                    if(operation==3){ 
                      if(langFromPre == 'Ita'){  this.companyActivityCertifications2 = textTranslated; }
                      if(langFromPre == 'Eng'){  this.companyActivityCertifications = textTranslated; }
                      if(langFromPre == 'Den'){  this.companyActivityCertifications = textTranslated; }
                    }

                    //this.newmessage = textTranslated;
                })
                .catch(
                error => {
                
                return error;
                }
                );
        
        gAxios.get('https://translate.googleapis.com/translate_a/single?client=gtx&sl='+langFrom+'&tl='+langTo2+'&dt=t&q='+texToTranslate,
                {
                   
                })
                .then(res => {
                    let tempTextTranslated = res.data[0];
                    textTranslated = '';
                    tempTextTranslated.forEach(element => {
                        textTranslated += element[0]+' ';
                        
                    });
                    
                    //if(operation==1){ this.companyActivityDescription3 = textTranslated; }
                    //if(operation==2){ this.companyActivityProducts3 = textTranslated; }
                    //if(operation==3){ this.companyActivityCertifications3 = textTranslated; }
                    //this.newmessage = textTranslated;

                    if(operation==1){ 
                      if(langFromPre == 'Ita'){  this.companyActivityDescription3 = textTranslated; }
                      if(langFromPre == 'Eng'){  this.companyActivityDescription3 = textTranslated; }
                      if(langFromPre == 'Den'){  this.companyActivityDescription2 = textTranslated; }
                    }
                    if(operation==2){ 
                      if(langFromPre == 'Ita'){  this.companyActivityProducts3 = textTranslated; }
                      if(langFromPre == 'Eng'){  this.companyActivityProducts3 = textTranslated; }
                      if(langFromPre == 'Den'){  this.companyActivityProducts2 = textTranslated; }
                    }
                    if(operation==3){ 
                      if(langFromPre == 'Ita'){  this.companyActivityCertifications3 = textTranslated; }
                      if(langFromPre == 'Eng'){  this.companyActivityCertifications3 = textTranslated; }
                      if(langFromPre == 'Den'){  this.companyActivityCertifications2 = textTranslated; }
                    }
                })
                .catch(
                error => {
                
                return error;
                }
                );

      return operation;
    },
    doNothing(){
      return null;
    },
    returnYear(dt){
      if(dt){
       return dt.substring(0,4);
      }else{
        return '';
      }
    },
    resetAtecoCode6(){
      this.atecoCode6 = null;
    },
    sameCF(){
      this.vatNumber = this.companyNumber;
    },
    sameCFcountryOfOrigin(){
      let buttonText = '';
      if(this.countryOfOrigin == '1'){ buttonText = 'Codice fiscale'; }
      if(this.countryOfOrigin == '2'){ buttonText = 'Company Number'; }
      if(this.countryOfOrigin == '504'){ buttonText = 'Firmanummer'; }
      return buttonText;
    },
    VerifyBirthDate(){
      //console.log('------->' + this.birthDate2);
      //let prebd1 = this.birthDate.toString();
      //let prebd = prebd1.split('-');
      let bd = new Date(this.birthDate);
        let now = new Date();

        //console.log( (now - bd) / (1000 * 3600 * 24) / 365);
        //console.log(this.magic_flag);
        if( ((now - bd) / (1000 * 3600 * 24) / 365)<18 ){
          this.birthDate = null;
          /*
          if(this.lang=='Ita'){

            alert('La registrazione è consentita solo ad utenti maggiorenni');
          }else{
            alert('Registration is only permitted to people aged 18 or over');
          }
          */

          this.msg = this.searchevents(this.labels,'dialog','registrationonlypermitted18');
          
          alert(this.msg);
          
          setTimeout(() => {  this.ClearDirthDate(); }, 3000);
        }else{
          //this.birthDate = bd;
        }

    },
    ClearDirthDate(){
      this.birthDate = '00/00/0000';
      this.$refs['birthDate'].value = null;
      
    },
    verificaCampiPerSottoporreCompagnia(){
      let risposta = 'KO';

      if(this.companyName!='' && 
      this.companyName!='' && 
      this.countryOfOrigin!='' && 
      this.companyNumber!='' && 
      this.servCompaniesCategoryId!='' && 
      this.vatNumber!='' && 
      this.officeRegion!='' && 
      this.officeDistrict!='' && 
      this.officeCity!='' && 
      this.officeAddress!='' && 
      this.officePostcode!='' && 
      this.servLandlinePrefixId!='' && 
      //this.landlinePhone!='' && 
      this.atecoCode1!='' && 
      this.officeRegion!='' && 
      this.officeDistrict!='' && 
      this.sex!='' && 
      this.countryOfResidence!='' && 
      this.birthPlace!='' && 
      this.birthDate!='' && 
      this.emailLR!='' && 
      this.fiscalCode &&
      this.step1DocNumber >= 2 &&
      this.servCompaniesStateCompaniesState != 'A' && 
      this.servCompaniesStateCompaniesState != 'C'
      ){
        risposta = 'OK';
      }
      return risposta
    },
    actionUserBOLR(){
      // aggiungo un controllo preliminare per verificare che il nuovo indirizzo email non sia già in uso sul sistema
      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/checkUser',
                {
                   email : this.emailLR,
                },auth)
                .then(res => {
                //console.log(res);
                if(res.data.message=='OK'){
                  //console.log('OK');
                  
                  this.userEmailBO = false;
                  this.lastAction = 'Aggiornamento Dati Mail';

                  this.userSuccessfulBO = 'P';

                  this.onSubmit();
                  // aggiunta stefano 04/02/2021
                  this.actionUserBO('userEmailBO');
                  // ***************************
                  
                  this.actionUserBO('userSuccessfulBO');
                  this.sendEmailBO('email-conferma-contratto-mod','viia');
                  
                }else{
                  alert('Operazione non consentita, la username inserita risulta già registrata si prega di selezionarne una diversa');
                }

      });

      
    },
    actionUserBOPec(){
      this.userContractBO = false;
      this.lastAction = 'Aggiornamento Dati Mail';
      

      this.onSubmit();
       this.actionUserBO('userContractPecBO');
       
      this.sendEmailBO('email-sollecito-pec','viia');
    },
    actionUserBOPec2(){
      this.userContractBO = false;
      this.lastAction = 'Aggiornamento Dati Mail';
     
      this.onSubmit();
       this.actionUserBO('userContractPecBO');
       
      this.sendEmailBO('email-cambio-pec','viia');
    },
    prefillVatNumber(){
      
      if(this.countryOfOrigin=='1'){
        this.companyNumberCountry = 'IT';
      }
      if(this.countryOfOrigin=='2'){
        this.companyNumberCountry = 'GB';
      }
      if(this.countryOfOrigin=='504'){
        this.companyNumberCountry = 'DK';
      }
    },
    actionUserBO(action){

            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
      let actionResult = '';
      if(action == 'userEmailBO'){actionResult = this.userEmailBO; }
      if(action == 'userSuccessfulBO'){actionResult = this.userSuccessfulBO; }
      if(action == 'userContractBO'){actionResult = this.userContractBO; }
      if(action == 'userContractPecBO'){actionResult = this.userContractBO; }
      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/actionUserBO',
                {
                   userId : this.userIdLR,
                   action : action,
                   actionResult : actionResult
                },auth)
                .then(res => {
                  
                  if(this.userSuccessfulBO == 'A'){ this.userSuccessfulBOTrad = 'Approvata'; if(this.companySuccessfulBO=='A' && action != 'userContractPecBO'){ this.sendEmailBO('LR-ok-bo','viia'); this.lastAction = 'LR approvato'; } }
                  if(this.userSuccessfulBO == 'P'){ this.userSuccessfulBOTrad = 'In lavorazione';}
                  if(this.userSuccessfulBO == 'J'){ this.userSuccessfulBOTrad = 'Rifiutata'; if(this.companySuccessfulBO=='A' && action != 'userContractPecBO'){ this.sendEmailBO('LR-ko-bo','viia'); this.lastAction = 'LR rifiutato'; } }
                  if(this.userSuccessfulBO == 'S'){ this.userSuccessfulBOTrad = 'Non lavorato da BO';}

                  if(res.data.messages=='OK' && action != 'userSuccessfulBO'){
                    alert('Aggiornamento effettuato con successo!!');
                  }
                    return res;
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },
    actionCompanyBO(action){

      const auth = {
          headers: {Authorization:'JWT ' + this.getToken} 
      }

      let actionResult = '';
      
      if(this.companySuccessfulBO==true){actionResult = true; }else{ actionResult = true; }
      if(action == 'companySuccessfulBO'){actionResult = this.companySuccessfulBO; }
      if(action == 'userContractBO'){actionResult = this.userContractBO; }
      

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/actionCompanyBO',
                {
                   companyId : this.id,
                   action : action,
                   actionResult : actionResult
                },auth)
                .then(res => {

                  if(this.companySuccessfulBO=='A'){this.companySuccessfulBOTrad = 'Approvata'; }
                  if(this.companySuccessfulBO=='J'){this.companySuccessfulBOTrad = 'Rifiutata'; }
                  if(this.companySuccessfulBO=='H'){this.companySuccessfulBOTrad = 'Sospesa';  this.sendEmailBO('account-suspended','viia'); }
                  if(this.companySuccessfulBO=='P'){this.companySuccessfulBOTrad = 'In lavorazione'; }
                  if(this.companySuccessfulBO=='S'){this.companySuccessfulBOTrad = 'Non lavorato da BO'; }
                  if(this.companySuccessfulBO=='R'){this.companySuccessfulBOTrad = 'Registrata non sottoposta a BO'; }

                  if(res.data.messages=='OK' && this.getUserRole=='BOF' ){
                    alert('E\' stato modificato lo stato della compagnia');
                  }
                    return res;
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },
    CalcolaCF(formData){
      
      // #######################################################################################################################################
      
      let bd = new Date(this.birthDate);
      let dataCorretta = 'N';
      let sexR = 'X';
      let birthdayR = null;
      let birthplaceR = '';

       try {

          var cf = new CodiceFiscale(this.fiscalCode.toUpperCase());

          sexR = cf.gender;
          birthdayR = new Date(cf.birthday);
          birthplaceR = cf.birthplace.nome;

          birthdayR.setHours(0,0,0,0);
          bd.setHours(0,0,0,0);

          //console.log(cf);
          if( (birthdayR.getTime() - bd.getTime() )==0 ){
            dataCorretta = 'Y';
          }else{
            dataCorretta = 'N';
          }


        } catch (e) {
          //console.log('errore'+this.fiscalCode);
          dataCorretta = 'N';
        }

      // fuori try-catch
      // se il codice fiscale NON è conforme #################################################################################################
      
      //console.log(birthdayR+'--'+bd);
      //console.log(this.birthPlace.toUpperCase()+'--'+birthplaceR);
      //console.log(dataCorretta);
      //console.log(this.sex+'--'+sexR);
      //console.log(this.countryOfResidence);

      if((this.birthPlace.toUpperCase()!=birthplaceR || dataCorretta=='N' || this.sex!=sexR) && this.countryOfResidence==1){
        
        if(this.countryOfResidence==1){
            if(this.lang=='Ita'){
              if (confirm('Il codice fiscale non è corretto vuoi proseguire ugualmente?')){
                  this.atecoCode6 = 'X';
                  //this.updateCompany(formData);
                  this.onSubmit();
                }else{
                  this.companySuccessfulBO = 'R';
                }
              }
              
              if(this.lang=='Eng'){
                if (confirm('National Insurance Number: wrong value do you still want to move on?')){
                    this.atecoCode6 = 'X';
                    //this.updateCompany(formData);
                    this.onSubmit();
                  }else{
                  this.companySuccessfulBO = 'R';
                }
              }

              if(this.lang=='Den'){
                if (confirm('CPR-nummer: forkert værdi, ønsker du stadig at fortsætte?')){
                    this.atecoCode6 = 'X';
                    //this.updateCompany(formData);
                    this.onSubmit();
                  }else{
                  this.companySuccessfulBO = 'R';
                }
              }
          }else{
            
            this.atecoCode6 = 'X';
            this.onSubmit();
          }

        

      }else{
        // se invece E' conforme #########################################################################################
        this.atecoCode6 = 'OK';
        this.onSubmit();
        //this.updateCompany(formData);
      }

      return formData;
      // ########################################################################################################################################

      /*
      gAxios.get('https://apis.woptima.com/cf/'+this.fiscalCode,
                {
                   companyId : this.id
                })
                .then(res => {
                 
                  let birthdayR = new Date(res.data.birthday);

                  let sexR = res.data.gender;
                  let birthplaceR = res.data.birthplace;
                  
                  let bd = new Date(this.birthDate);
                  let dataCorretta = 'N';
                  
                  if( (birthdayR - bd )==0 ){
                    dataCorretta = 'Y';
                  }else{
                    dataCorretta = 'N';
                  }

                  
                  //this.updateCompany(formData);
                  
                  // se il codice fiscale NON è conforme #################################################################################################
                  if((this.birthPlace.toUpperCase()!=birthplaceR || dataCorretta=='N' || this.sex!=sexR) && this.countryOfResidence==1){
                    
                    let msg = this.searchevents(this.labels,'dialog','wrongcfvaluegoon');

                    if(this.lang=='Ita'){
                      if (confirm(msg)){
                          // YO!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                          //this.atecoCode6 = 'X';
                          this.onSubmit();
                        }else{
                          // nothing ##################################
                          //this.atecoCode6 = '';
                          //this.onSubmit();
                        }
                    }else{
                      if (confirm(msg)){
                          this.atecoCode6 = 'X';
                          this.onSubmit();
                        }else{
                          // nothing ##################################
                          //this.atecoCode6 = '';
                          //this.onSubmit();
                        }
                    }

                    

                  }else{
                    // se invece E' conforme #########################################################################################
                    
                    this.updateCompany(formData);
                  }
                  
                  //return res;
                     
                })
                .catch(
                error => {
                

                if(this.countryOfResidence==1){
                  if(this.lang=='Ita'){
                    if (confirm('Il cf non è corretto vuoi proseguire ugualmente?')){
                        this.atecoCode6 = 'X';
                        //this.updateCompany(formData);
                        this.onSubmit();
                      }
                    }
                    
                    if(this.lang=='Eng'){
                      if (confirm('National Insurance Number: wrong value do you still want to move on?')){
                          this.atecoCode6 = 'X';
                          //this.updateCompany(formData);
                          this.onSubmit();
                        }
                    }

                    if(this.lang=='Den'){
                      if (confirm('CPR-nummer: forkert værdi, ønsker du stadig at fortsætte?')){
                          this.atecoCode6 = 'X';
                          //this.updateCompany(formData);
                          this.onSubmit();
                        }
                    }
                }else{
                  
                  this.atecoCode6 = 'X';
                  this.onSubmit();
                }
                
                

                return error;
                }
                );
                
      */

      
      /*
        gAxios({ method: "GET", "url": 'https://apis.woptima.com/cf/FBBSFN71C15H199C' }).then(result => {
        console.log('resutl'+result);
      }, error => {
        console.error(error);
      });
     
 */
      
        /*
        this.$http.get('https://apis.woptima.com/cf/FBBSFN71C15H199C').then(response => {
            //this.html = response.data;
            console.log(response.data);
        });

        const testURL = 'https://apis.woptima.com/cf/FBBSFN71C15H199C';
        const myInit = {
          method: 'HEAD',
          mode: 'no-cors',
        };

        const myRequest = new Request(testURL, myInit);

        fetch(myRequest).then(function(response) {
          return response;
        }).then(function(response) {
          console.log(response);
        }).catch(function(e){
          console.log(e);
        });
  
 /*
      this.$http.get('https://apis.woptima.com/cf/FBBSFN71C15H199C').then(function(response){
        
            console.log(response);
        // use self instead of this
        });
      

     
      gAxios.get('https://apis.woptima.com/cf/FBBSFN71C15H199C',
                {
                  mode: 'no-cors'
                })
                .then(res => {
                  
                    console.log('resutl'+res);
                    
                })
                .catch(
                error => {
                console.log('resutlerrr'+error);
                return error;
                }
                );
            */    
                
    },
    getAllUserByMe(){

            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
      

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/userLRByCompany',
                {
                   companyId : this.id,
                   userLRId : this.getUserId
                },auth)
                .then(res => {
                    
                    let users = res.data.users;
                    this.myusers = users;
                    
                    users.forEach(u => {
                        
                        if(u.idUsersParent == '-1'){
                          this.name = u.name;
                          this.surname = u.surname;
                          this.sex = u.sex;
                          this.countryOfResidence = u.countryOfResidence;
                          this.birthPlace = u.birthPlace;
                          this.birthDate = u.birthDate;
                          //console.log('---------->' + this.birthDate)
                          
                          this.documentNumber = u.documentNumber;
                          this.servDocTypeIdLR = u.servDocTypeId;
                          this.userIdLR = u.id;
                          this.emailLR = u.email;
                          this.fiscalCode = u.fiscalCode;
                          this.guidIdentity = u.guidIdentity;
                          if(u.guidIdentityFlag=='Y'){this.userEmailBO = true;}else{this.userEmailBO = false;}
                          if(u.guidContractFlag=='Y'){this.userContractBO = true;}else{this.userContractBO = false;}

                          if(u.servUsersStateUsersState == 'A'){this.userSuccessfulBO = 'A'; this.userSuccessfulBOTrad = 'Approvata';}
                          if(u.servUsersStateUsersState == 'P'){this.userSuccessfulBO = 'P'; this.userSuccessfulBOTrad = 'In lavorazione';}
                          if(u.servUsersStateUsersState == 'J'){this.userSuccessfulBO = 'J'; this.userSuccessfulBOTrad = 'Rifiutata';}
                          if(u.servUsersStateUsersState == 'S'){this.userSuccessfulBO = 'S'; this.userSuccessfulBOTrad = 'Non lavorato da BO';}

                          if(u.servUsersStateUsersState == 'A' && this.servCompaniesStateCompaniesState == 'A'){this.companyUserSuccessfulBO = 'APPROVATA';}
                          
                          //if(this.userSuccessfulBO!='A'){
                            
                          //}
                        }
                    });
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },

    sendEmailBO(emailType,type){
      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }

      if(emailType=='email-rifiuto-attivazione' && (this.companySuccessfulBO!='J' ))
      {
        alert('Operazione non possibile');
        return true;
      }
      if(emailType=='email-avvenuta-validazione' && (this.companySuccessfulBO!='A' || this.userSuccessfulBO!='A'))
      {
        alert('Operazione non possibile');
        return true;
      }

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/sendEmailBO',
      {
          tipoEmail : emailType,
          emailTo : this.emailLR,
          name : this.name,
          surname : this.surname,
          companyName : this.companyName,
          guidIdentity : this.guidIdentity,
          guidContract : this.guidContract,
          infoIntegrative : this.infoIntegrative,
          countryOfResidence : this.countryOfResidence,
          type : type,
          pecAddress: this.pec,
          companyId : this.id
          
      },auth)
      .catch(
      error => {
        this.infoIntegrative = '';
        return error;
      }
      );

      if(emailType!='a5'){
        this.msg = this.searchevents(this.labels,'modify','emailsentsuccessfully');
        alert(this.msg);
      }
      
      //this.poupvisibile = true;
      this.infoIntegrative = '';
      //setTimeout(this.closeAlert,3000);

    },
    setSelectedCompanyIdDetail(companyId,table,addNetwork,sendMessage){

        this.$store.dispatch('setCompanyIdBusinessCard',companyId );
        this.$store.dispatch('setCompanyTableBusinessCard',table );

        this.$store.dispatch('setCompanyNetworkBusinessCard',addNetwork );
        this.$store.dispatch('setCompanyMessageBusinessCard',sendMessage );
        
        /*
        this.selectedCompanyTable = table;
        
        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                {
                   companyId : companyId,
                   action : table
                },auth)
                .then(res => {
                    
                    this.mycompaniesDetails = res.data.company[0];

                    if(table=='companies'){
                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_doc'
                        },auth)
                        .then(res1 => {
                            
                            // docs here
                            
                            if(res1.data.company.length>0)
                            {
                                this.mycompaniesDetailsDocs = res1.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );

                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_countries'
                        },auth)
                        .then(res2 => {
                            
                            // docs here
                            
                            
                            if(res2.data.company.length>0)
                            {
                                this.mycompaniesDetailsCountries = res2.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );
                    }
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
        
          */

    },
    filteredItemsDett(code) {

       const search = code;

      if (!search) return '';
      
      let aa = this.atecoList.filter(c => 
        c.ATTIVIT_CON_PUNTO_3LIVELLI == search
        );


      if(aa.length>0){ return aa[0].Descrizione; }else{ return ''; }
      
    },
    filteredItemsDettEng(code) {

       const search = code;

      if (!search) return '';
      
      let aa = this.atecoList.filter(c => 
        c.ATTIVIT_CON_PUNTO_3LIVELLI == search 
        );


      if(aa.length>0){ return aa[0].Descrizione_eng; }else{ return ''; }
      
    },
    filteredItemsDettDen(code) {

       const search = code;

      if (!search) return '';
      
      let aa = this.atecoList.filter(c => 
        c.ATTIVIT_CON_PUNTO_3LIVELLI == search
        );


      if(aa.length>0){ return aa[0].Descrizione_den; }else{ return ''; }
      
    },
    ritornaLaClasse(type){
      if(type!=0){
        return 'requiredMod';
      }else{
        return 'requiredMod';
      }
      
      /*
      if(type==1 || type==2 || type==3){
        return 'requiredMod';
      }else{
        return '';
      }
      */
    },
    setDocToModify(docId,description, description2,description3,type){
      this.docIdSelected = docId;
      this.docDescriptionSelected = description;
      
      this.docDescriptionSelected2 = description2;
      this.docDescriptionSelected3 = description3;

      this.docTypeSelected = type;
      this.$refs.mynewdoc.value=null;
    },
    closeAlert(){
      this.poupvisibile = false;
    },
    setSelectedAteco(ateco,num){
      this.selectedAteco = num;
      this.atecoSearch = ateco;
      
    },
    openDoc(docName){
      return docName;
    },
    setSelectedAtecoApply(){
      //console.log(this.selectedAtecoList);
      //console.log(this.selectSicCodeList.NACE_ATECO);
      //console.log(this.selectedAteco + '<<<<<<<<<<<<<<<<<<<<<<' + this.selectSicCodeList + 'xxxx');
      if(this.selectSicCodeList!=null){
        if(this.selectedAteco == 'atecoCode1'){ this.atecoCode1 = this.selectSicCodeList.NACE_ATECO; this.sicCode1 = this.selectSicCodeList.SIC_Code; }
        if(this.selectedAteco == 'atecoCode2'){ this.atecoCode2 = this.selectSicCodeList.NACE_ATECO; this.sicCode2 = this.selectSicCodeList.SIC_Code; }
        if(this.selectedAteco == 'atecoCode3'){ this.atecoCode3 = this.selectSicCodeList.NACE_ATECO; this.sicCode3 = this.selectSicCodeList.SIC_Code; }
      }

      if(this.selectedAtecoList!=null){
        if(this.selectedAteco == 'atecoCode1'){ this.atecoCode1 = this.selectedAtecoList; this.sicCode1 = this.selectSicCodeList.SIC_Code; }
        if(this.selectedAteco == 'atecoCode2'){ this.atecoCode2 = this.selectedAtecoList; this.sicCode2 = this.selectSicCodeList.SIC_Code; }
        if(this.selectedAteco == 'atecoCode3'){ this.atecoCode3 = this.selectedAtecoList; this.sicCode3 = this.selectSicCodeList.SIC_Code; }
      }
      
      //if(this.selectedAteco == 'atecoCode4'){ this.atecoCode4 = this.selectedAtecoList; this.sicCode4 = this.selectedAtecoList; }
      //if(this.selectedAteco == 'atecoCode5'){ this.atecoCode5 = this.selectedAtecoList; this.sicCode5 = this.selectedAtecoList; }
      //if(this.selectedAteco == 'atecoCode6'){ this.atecoCode6 = this.selectedAtecoList; }
      //if(this.selectedAteco == 'atecoCode7'){ this.atecoCode7 = this.selectedAtecoList; }
    },
    formatPicker: function(){
      var self = this;
      var d = new Date(self.trackedDate);
      self.formattedDate =  d.getUTCDate() +"/"+ (d.getUTCMonth()+1) +"/"+ d.getUTCFullYear();
     
    },
    loadCompany(num){
      this.$store.dispatch('loadCompany', num );
    },
    onClickDeleteCcb(ccb){
      this.confirmDeleteDoc = this.searchevents(this.labels,'dialog','deleteconfirmation');
      if (confirm(this.confirmDeleteDoc))
        this.deleteCcb(ccb)
    },
    onClickSubmitCompnayGoBiz(){
      let messageConfirm = '';
      if(this.lang=='Ita'){
        messageConfirm = 'Hai verificato la correttezza dei dati forniti? In caso di invio i dati non saranno più modificabili sino a quando il Profilo non sarà stato Approvato';
      }else if (this.lang=='Eng') {
        messageConfirm = 'Have you checked the accuracy of the data provided? Please note that once the data have been sent, the data will no longer be editable until the profile has been approved.';
      } else if (this.lang=='Den') {
        messageConfirm = 'Har du tjekket nøjagtigheden af de angivne oplysninger? Bemærk venligst, at når oplysningerne er indsendt, kan de ikke længere ændres, før profilen er godkendt.';
      } 

      if (confirm(messageConfirm)){
         
         this.companySuccessfulBO = 'S';
         // setto qui la variabile ma eseguo il comando actionCompanyBO all'interno della onSubmit perché in questo modo vengono eseguiti anche altri controlli
         //this.actionCompanyBO('companySuccessfulBO');
         //this.$refs['formMain'].submit();
         
         //document.getElementById("snc").click();
         this.$refs['snc'].click();
         //this.onSubmit();
      }
       
    },
    deleteCcb(ccb){
      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }
     
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/CCBJoin',
      {
          id : ccb,
          actionToDo : "DEL"
          
      },auth)
      .then(res => {
          
          this.getCountryList();
          return res;
      })
      .catch(
      error => {
        
      return error;
      }
      );
    },
    onClickDeleteDoc(companyId, description){
      this.confirmDeleteDoc = this.searchevents(this.labels,'dialog','deleteconfirmation');
      if (confirm(this.confirmDeleteDoc))
        this.deleteDoc(companyId, description)
    },
    deleteDoc(companyId, description){
      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }
      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/companydocs/delcompanydocs',
      {
          companyDocsId : companyId
          
      },auth)
      .then(res => {
          
          if(description=='Visura sostitutiva/ Alternate certificate (PDF)'){
            //this.lastAction = 'Aggiornamento visura cancellato';
            //this.onSubmit();
          }
          this.getDocsList();
          return res;
      })
      .catch(
      error => {
        
      return error;
      }
      );
    },
    confirmVisuraUpdated(){
      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }
      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/confirmvisuraupdated',
      {
          companyId : this.id
          
      },auth)
      .then(res => {
          //console.log(res);
          const current = new Date();
          const agoraMonth = current.getMonth()+1;
          const agoraDay = current.getDate();
          const agoraDate = current.getFullYear()+'-'+(agoraMonth)+'-'+agoraDay;
          this.lastActionDate = agoraDate;
          this.lastAction = 'Aggiornamento visura effettuato';

          alert('Occorre cliccare Salva e conferma azioni ');

          return res;
      })
      .catch(
      error => {
      //console.log(error);
      return error;
      }
      );
    },
    getCountryList(){
      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }
      
      if(this.id!= null && this.id!='')
      {
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/CCBJoin',
        {
            companyId : this.id,
            actionToDo : "ALL"
            
        },auth)
        .then(res => {
            
            let matrix = res.data.ccb;
            this.countryList = matrix;
            
        })
        .catch(
        error => {
      
        return error;
        }
        );
      }
    },
    getDocsList(){
      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }

      if(this.id!= null && this.id!='')
      {
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/companydocs/getcompanydocs',
        {
            companyId : this.id
            
        },auth)
        .then(res => {
            
            let matrix = res.data.docs;
            this.docList = matrix;
            this.step1DocNumber = 0;

            matrix.forEach(u => {
                if(u.servDocTypeId!= 4 && u.servDocTypeId!= 5 && u.servDocTypeId!= 6 && u.servDocTypeId!= 8 && u.servDocTypeId!= 9 && u.name!='' && u.name!=null){
                  this.step1DocNumber = this.step1DocNumber + 1;
                }     
            });
            
            
        })
        .catch(
        error => {
        
        return error;
        }
        );
      }

    },
    getAtecoList(){
      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/atecolist/atecocode',
      {
          myCode : "",
          CodeToSearch : "",
          actionSearch : "match_conv_sic3",
          single : "N"
      },auth)
      .then(res => {
          
         
          let matrix = res.data.matrix;
          this.atecoList = matrix;
          
      })
      .catch(
      error => {
      
      return error;
      }
      );

    },
    getSicList(){
      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/atecolist/atecocode',
      {
          myCode : "",
          CodeToSearch : "",
          actionSearch : "match_nace_to_sic",
          single : "N"
      },auth)
      .then(res => {
          
          
          let matrix = res.data.matrix;
          this.sicList = matrix;
          
      })
      .catch(
      error => {
      
      return error;
      }
      );

    },
    previewFile(){
      this.newDocs = this.$refs.mynewdoc.files[0];
      this.docDescriptionSelected2 = this.$refs.mynewdoc.files[0].name;
      
    },
    getregisteredCompanyCall(){
      var rcomp = this.$store.getters.getregisteredCompany;
      if(rcomp){
        
        return rcomp;
      }else{
        
        return 0;
      }
    },
    addCcb(){
      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }
      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/CCBJoin',
        {
         
          id : '',
          companyId : this.id,
          servCountryListId : this.newCcbCountry,
          priorityOrder : this.newCcbPriorityOrder,
          actionToDo : 'ADD',

          returnSecureToken : true
          
        },auth)
        .then(res => {
          
          this.newCcbCountry = null;
          this.newCcbPriorityOrder = null;
          this.getCountryList();
          return res;
          //router.push('/dashboard');
        })
        .catch(
          error => {
          
          // faccio qualcosa qui
            return error;
          }
          );

    },
    onSubmitNewDoc(){
      
       let companyId = this.id;
       let fileName = this.newDocs;
       let servDocTypeId = this.newservDocTypeId;
       let docDescription = this.docDescriptionSelected;
       let docDescription2 = this.docDescriptionSelected2;
       let docDescription3 = this.docDescriptionSelected3;
       let docId = this.docIdSelected;
       const auth = {
        headers: {Authorization:'JWT ' + this.getToken, 'content-type': 'multipart/form-data' }
      }

      let fd = new FormData();
      fd.append('docs',fileName);
      fd.append('docId',docId);
      fd.append('companyId',companyId);
      fd.append('description',docDescription);
      fd.append('description2',docDescription2);
      fd.append('description3',docDescription3);
      fd.append('servDocTypeId',servDocTypeId);
      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/companydocs/addcompanydocs',
        fd,auth)
        .then(res => {
          
          if(docDescription=='Visura sostitutiva/ Alternate certificate (PDF)' && fileName!=''){
            this.lastAction = 'Aggiornamento visura';
            this.onSubmit();
          }

          this.newDocs = null;
          this.newservDocTypeId = null;
          this.newDocName = null;
          this.getDocsList();

         
            // eseguo sempre un controllo per verificare se aggiornare lo stato dell'azienda
            /*
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/checkCompanyStatus',
            {
                companyIdx : companyId
            },auth)
            .then(res1 => {  
                return res1; 
            })
            .catch(
            error => {
              return error;
            }
            );
            */

          return res;
          //router.push('/dashboard');
        })
        .catch(
          error => {
          
          // faccio qualcosa qui
            return error;
          }
          );

    },
    filteredItemsSicbyAteco(code) {
      
       const search = code;

      if (!search) return '';
      
      let aa = this.sicList.filter(c => 
        c.SIC_Code.toLowerCase().indexOf(search) > -1  
        );

      //let bb = this.sicList.filter(c => 
      //c.SIC_3_liv.toLowerCase().indexOf(search) > -1 
      //);

      if(aa.length>0){ return aa[0].Company_house_SIC_Des; }else{ return ''; }
      //if(aa.length>0){ return aa; }else{ return bb; }
      
    },
    onSubmit(){
      
      this.VerifyBirthDate();

      if(this.atecoCode1==null || this.atecoCode1==''){

        if(this.lang=='Ita'){
            alert('Settore di Attività è obbligatorio');
            this.companySuccessfulBO = 'R';
            return 'ko';
          }

        if(this.lang=='Eng'){
            alert('Activity Sector is required');
            this.companySuccessfulBO = 'R';
            return 'ko';
          }

          if(this.lang=='Den'){
            alert('Aktivitetssektor er påkrævet');
            this.companySuccessfulBO = 'R';
            return 'ko';
          }

        /*
        if(this.countryOfOrigin!='504' ){
          if(this.lang=='Ita'){
            alert('Settore di Attività è obbligatorio');
            return 'ko';
          }else{
            alert('Activity Sector is required');
            return 'ko';
          }
        }else{
          if(this.lang=='Ita'){
            alert('Il codice nace è richiesto');
            return 'ko';
          }else{
            alert('Nace code is required');
            return 'ko';
          }
        }
        */
        

      }

      //console.log(this.birthDate + '<<<<<<<<<<<<<<')

      if(this.birthDate==null || this.birthDate=='' || this.birthDate=='0000-00-00' || this.birthDate=='00/00/0000'){
        if(this.lang=='Ita'){
          alert('Data di nascita è richiesta');
          this.companySuccessfulBO = 'R';
          return 'ko';
        }
        
        if(this.lang=='Eng'){
          alert('Birth date is required');
          this.companySuccessfulBO = 'R';
          return 'ko';
        }

        if(this.lang=='Den'){
          alert('Fødselsdato er påkrævet');
          this.companySuccessfulBO = 'R';
          return 'ko';
        }
      }

      if(this.vatNumber==null || this.vatNumber==''){
        if(this.lang=='Ita'){
          alert('Codice Fiscale Impresa è richiesto');
          this.companySuccessfulBO = 'R';
          return 'ko';
        }
        
        if(this.lang=='Eng'){
          alert('Company Unique Taxpayer Reference is required');
          this.companySuccessfulBO = 'R';
          return 'ko';
        }

        if(this.lang=='Den'){
          alert('Firmanummer er Påkrævet');
          this.companySuccessfulBO = 'R';
          return 'ko';
        }

      }

      if(this.countryOfOrigin==1){
        // verifico PEC
        //if(this.pec.includes('pec') || this.pec.includes('PEC')){
          // nothing
        //}else{
        //  alert('Inserire un indirizzo PEC corretto.');
        //  return false;
        //}
      }

      if(this.companyName==null || this.companyName==''){
        if(this.lang=='Ita'){
          alert('Ragione Sociale è richiesta');
          this.companySuccessfulBO = 'R';
          return 'ko';
        }
        
        if(this.lang=='Eng'){
          alert('Company Name is required');
          this.companySuccessfulBO = 'R';
          return 'ko';
        }

        if(this.lang=='Den'){
          alert('Firmanavn er påkrævet');
          this.companySuccessfulBO = 'R';
          return 'ko';
        }
      }

      if((this.servCompaniesCategoryId==null || this.servCompaniesCategoryId=='') && (this.countryOfOrigin=='1' || this.countryOfOrigin=='2' || this.countryOfOrigin=='504')){
        if(this.lang=='Ita'){
          alert('Forma Giuridica è richiesta');
          this.companySuccessfulBO = 'R';
          return 'ko';
        }
        
        if(this.lang=='Eng'){
          alert('Legal form is required');
          this.companySuccessfulBO = 'R';
          return 'ko';
        }

        if(this.lang=='Den'){
          alert('Juridisk form er påkrævet');
          this.companySuccessfulBO = 'R';
          return 'ko';
        }
      }

      if(this.partOfGroup==true){ this.partOfGroup = 'Y'; }else{this.partOfGroup = 'N'; }

      const formData = {
        id : this.id,
        companyName : this.companyName,
        pec : this.pec,
        emailAddress : this.emailAddress,
        officeRegion : this.officeRegion,
        officeDistrict : this.officeDistrict,
        officeCity : this.officeCity,
        officeTown : this.officeTown,
        officePostcode : this.officePostcode,
        officeAddress : this.officeAddress,
        vatNumber : this.companyNumberCountry+'-'+this.vatNumber,
        idTax : this.idTax,
        companyNumber : this.companyNumber,
        operationalCountry : this.operationalCountry,
        operationalRegion : this.operationalRegion,
        operationalDistrict : this.operationalDistrict,
        operationalCity : this.operationalCity,
        operationalTown : this.operationalTown,
        operationalPostcode : this.operationalPostcode,
        operationalAddress : this.operationalAddress,
        reaNumber : this.reaNumber,
        landlinePhone : this.landlinePhone,
        mobilePhone : this.mobilePhone,
        companyActivityDescription : this.companyActivityDescription,
        companyActivityProducts : this.companyActivityProducts,
        companyActivityCertifications : this.companyActivityCertifications,
        companyActivityDescription2 : this.companyActivityDescription2,
        companyActivityProducts2 : this.companyActivityProducts2,
        companyActivityCertifications2 : this.companyActivityCertifications2,
        companyActivityDescription3 : this.companyActivityDescription3,
        companyActivityProducts3 : this.companyActivityProducts3,
        companyActivityCertifications3 : this.companyActivityCertifications3,
        companyNotes :this.companyNotes,
        boNotes : this.boNotes,
        incorporationDate : this.incorporationDate,
        dissolutionDate : this.dissolutionDate,
        partOfGroup : this.partOfGroup,
        groupName : this.groupName,
        groupIdTax : this.groupIdTax,
        atecoCode1 : this.atecoCode1,
        atecoCode2 : this.atecoCode2,
        atecoCode3 : this.atecoCode3,
        atecoCode4 : this.atecoCode4,
        atecoCode5 : this.atecoCode5,
        atecoCode6 : this.atecoCode6,
        atecoCode7 : this.atecoCode7,
        naceCode1 : this.naceCode1,
        naceCode2 : this.naceCode2,
        naceCode3 : this.naceCode3,
        sicCode1 : this.sicCode1,
        sicCode2 : this.sicCode2,
        sicCode3 : this.sicCode3,
        sicCode4 : this.sicCode4,
        naceMacro : this.naceMacro,
        naceSector : this.naceSector,
        sicMacro : this.sicMacro,
        sicSector : this.sicSector,
        inspectionDate : this.inspectionDate,
        lastInspectionDate : this.lastInspectionDate,
        webSite : this.webSite,
        linkedin : this.linkedin,
        twitter : this.twitter,
        facebook : this.facebook,
        lastAction : this.lastAction,
        lastActionDate : this.lastActionDate,
        deleted : this.deleted,
        createdAt : this.createdAt,
        updatedAt : this.updatedAt,
        servMobilePrefixId : this.servMobilePrefixId,
        servLandlinePrefixId : this.servLandlinePrefixId,
        servCompaniesStateCompaniesState : this.servCompaniesStateCompaniesState,
        servCompaniesCategoryId : this.servCompaniesCategoryId,
        servCompaniesEmployeesNumId : this.servCompaniesEmployeesNumId,
        servCompaniesEmployeesYear : this.servCompaniesEmployeesYear,
        servCompaniesTurnoverYear : this.servCompaniesTurnoverYear,
        servCompaniesTurnoverId : this.servCompaniesTurnoverId,
        servCompaniesBusinessSectorId : this.servCompaniesBusinessSectorId,
        countryOfOrigin : this.countryOfOrigin,
        officeCountry : this.officeCountry,
        initialCamComm : this.initialCamComm
        
      }
      
     //this.$store.dispatch('updateCompany', formData );
     
     if(this.atecoCode6=='' || this.atecoCode6==null){
       this.CalcolaCF(formData);
     }else{
       //this.CalcolaCF(formData);

       if(this.companySuccessfulBO == 'S'){
          this.actionCompanyBO('companySuccessfulBO');
       }
        
       this.updateCompany(formData);
     }
     
     
    },
    updateCompany(formData){
      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }
      
      // decido qui se si tratta di un'UPDATE oppure di una INSERT
      if(this.id==null){
        
        let currentUserId = this.getUserId;
        let currentUserIdParent = this.getIdUsersParent;
        let usersRoleCompanyRole = 'COL';
        let usersRoleCompanyRoleUser = 'ADM'; // padre se non idPArente = -1
        if(currentUserIdParent == -1){ usersRoleCompanyRole = 'ADM' }
        
        if(formData.partOfGroup==true){ formData.partOfGroup = 'Y'; }else{formData.partOfGroup = 'N'; }

        let disclaimerContact = 'N';
        if(this.disclaimer05==true){ disclaimerContact = 'Y'; }

        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/verifycompany2',
                {
                   id : '-1',
                   countryOfOrigin : formData.countryOfOrigin,
                   companyNumber : formData.companyNumber
                },auth)
        .then(res => {

          let myResponse = res.data.message;
          //console.log(res);
          if(myResponse=='OK'){

            // eseguo la INSERT
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/insertCompany/',
            {
              companyName : formData.companyName,
              pec : formData.pec,
              emailAddress : formData.emailAddress,
              officeRegion : formData.officeRegion,
              officeDistrict : formData.officeDistrict,
              officeCity : formData.officeCity,
              officeTown : formData.officeTown,
              officePostcode : formData.officePostcode,
              officeAddress : formData.officeAddress,
              vatNumber : formData.vatNumber,
              idTax : formData.idTax,
              companyNumber : formData.companyNumber,
              operationalCountry : formData.operationalCountry,
              operationalRegion : formData.operationalRegion,
              operationalDistrict : formData.operationalDistrict,
              operationalCity : formData.operationalCity,
              operationalTown : formData.operationalTown,
              operationalPostcode : formData.operationalPostcode,
              operationalAddress : formData.operationalAddress,
              reaNumber : formData.reaNumber,
              landlinePhone : formData.landlinePhone,
              mobilePhone : formData.mobilePhone,
              companyActivityDescription : formData.companyActivityDescription,
              companyActivityProducts : formData.companyActivityProducts,
              companyActivityCertifications : formData.companyActivityCertifications,
              companyActivityDescription2 : formData.companyActivityDescription2,
              companyActivityProducts2 : formData.companyActivityProducts2,
              companyActivityCertifications2 : formData.companyActivityCertifications2,
              companyActivityDescription3 : formData.companyActivityDescription3,
              companyActivityProducts3 : formData.companyActivityProducts3,
              companyActivityCertifications3 : formData.companyActivityCertifications3,
              companyNotes : formData.companyNotes,
              boNotes : formData.boNotes,
              incorporationDate : formData.incorporationDate,
              dissolutionDate : formData.dissolutionDate,
              partOfGroup : formData.partOfGroup,
              groupName : formData.groupName,
              groupIdTax : formData.groupIdTax,
              atecoCode1 : formData.atecoCode1,
              atecoCode2 : formData.atecoCode2,
              atecoCode3 : formData.atecoCode3,
              atecoCode4 : formData.atecoCode4,
              atecoCode5 : formData.atecoCode5,
              atecoCode6 : formData.atecoCode6,
              atecoCode7 : formData.atecoCode7,
              naceCode1 : formData.naceCode1,
              naceCode2 : formData.naceCode2,
              naceCode3 : formData.naceCode3,
              sicCode1 : formData.sicCode1,
              sicCode2 : formData.sicCode2,
              sicCode3 : formData.sicCode3,
              sicCode4 : formData.sicCode4,
              naceMacro : formData.naceMacro,
              naceSector : formData.naceSector,
              sicMacro : formData.sicMacro,
              sicSector : formData.sicSector,
              inspectionDate : formData.inspectionDate,
              lastInspectionDate : formData.lastInspectionDate,
              webSite : formData.webSite,
              linkedin : formData.linkedin,
              twitter : formData.twitter,
              facebook : formData.facebook,
              lastAction : formData.lastAction,
              lastActionDate : formData.lastActionDate,
              deleted : formData.deleted,
              servMobilePrefixId : formData.servMobilePrefixId,
              servLandlinePrefixId : formData.servLandlinePrefixId,
              servCompaniesStateCompaniesState : 'R',
              servCompaniesCategoryId : formData.servCompaniesCategoryId,
              servCompaniesEmployeesNumId : formData.servCompaniesEmployeesNumId,
              servCompaniesEmployeesYear : formData.servCompaniesEmployeesYear,
              servCompaniesTurnoverYear : formData.servCompaniesTurnoverYear,
              servCompaniesTurnoverId : formData.servCompaniesTurnoverId,
              servCompaniesBusinessSectorId : formData.servCompaniesBusinessSectorId,
              countryOfOrigin : formData.countryOfOrigin,
              officeCountry : formData.officeCountry,
              initialCamComm : formData.initialCamComm,

              idUsersParent : currentUserIdParent,
              idUser : currentUserId,
              usersRoleCompanyRole : usersRoleCompanyRole,
              usersRoleCompanyRoleUser : usersRoleCompanyRoleUser,
              disclaimerContact: disclaimerContact,
              countryOfResidence: this.countryOfResidence,
              user_name: this.name,
              user_surname: this.surname,
              user_fiscalcode: this.fiscalCode,
              user_email: this.emailLR,

              returnSecureToken : true
              
            },auth)
            .then(res => {
              if(res.data.messages == 'Success Operation')
              {
                
                this.msg = this.searchevents(this.labels,'modify','operationsuccess');
                //this.poupvisibile = true;
                //setTimeout(this.closeAlert,3000);
                alert(this.msg);
              } 
            
            router.push('/dashboard');
              return res;
              
            })
            .catch(
              error => {
              this.msg = this.searchevents(this.labels,'modify','operationfailed');
              //this.poupvisibile = true;
              //setTimeout(this.closeAlert,3000);
              alert(this.msg);

              // faccio qualcosa qui
                return error;
              }
              );
          }else{
            // operazione non consentita
            let messageReturn = this.searchevents(this.labels,'dialog','opnotpermittedalreaypresent');
            alert(messageReturn);
          }
          
          
        })
        .catch(
          error => {
          
          // faccio qualcosa qui
          
            return error;
          }
          );


        
        

      }else{
        
        // si tratta di una UPDATE ###############################################################
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/updateCompany/'+formData.id,
        {
          companyName : formData.companyName,
          pec : formData.pec,
          emailAddress : formData.emailAddress,
          officeRegion : formData.officeRegion,
          officeDistrict : formData.officeDistrict,
          officeCity : formData.officeCity,
          officeTown : formData.officeTown,
          officePostcode : formData.officePostcode,
          officeAddress : formData.officeAddress,
          vatNumber : formData.vatNumber,
          idTax : formData.idTax,
          companyNumber : formData.companyNumber,
          operationalCountry : formData.operationalCountry,
          operationalRegion : formData.operationalRegion,
          operationalDistrict : formData.operationalDistrict,
          operationalCity : formData.operationalCity,
          operationalTown : formData.operationalTown,
          operationalPostcode : formData.operationalPostcode,
          operationalAddress : formData.operationalAddress,
          reaNumber : formData.reaNumber,
          landlinePhone : formData.landlinePhone,
          mobilePhone : formData.mobilePhone,
          companyActivityDescription : formData.companyActivityDescription,
          companyActivityProducts : formData.companyActivityProducts,
          companyActivityCertifications : formData.companyActivityCertifications,
          companyActivityDescription2 : formData.companyActivityDescription2,
          companyActivityProducts2 : formData.companyActivityProducts2,
          companyActivityCertifications2 : formData.companyActivityCertifications2,
          companyActivityDescription3 : formData.companyActivityDescription3,
          companyActivityProducts3 : formData.companyActivityProducts3,
          companyActivityCertifications3 : formData.companyActivityCertifications3,
          companyNotes : formData.companyNotes,
          boNotes : formData.boNotes,
          incorporationDate : formData.incorporationDate,
          dissolutionDate : formData.dissolutionDate,
          partOfGroup : formData.partOfGroup,
          groupName : formData.groupName,
          groupIdTax : formData.groupIdTax,
          atecoCode1 : formData.atecoCode1,
          atecoCode2 : formData.atecoCode2,
          atecoCode3 : formData.atecoCode3,
          atecoCode4 : formData.atecoCode4,
          atecoCode5 : formData.atecoCode5,
          atecoCode6 : formData.atecoCode6,
          atecoCode7 : formData.atecoCode7,
          naceCode1 : formData.naceCode1,
          naceCode2 : formData.naceCode2,
          naceCode3 : formData.naceCode3,
          sicCode1 : formData.sicCode1,
          sicCode2 : formData.sicCode2,
          sicCode3 : formData.sicCode3,
          sicCode4 : formData.sicCode4,
          naceMacro : formData.naceMacro,
          naceSector : formData.naceSector,
          sicMacro : formData.sicMacro,
          sicSector : formData.sicSector,
          inspectionDate : formData.inspectionDate,
          lastInspectionDate : formData.lastInspectionDate,
          webSite : formData.webSite,
          linkedin : formData.linkedin,
          twitter : formData.twitter,
          facebook : formData.facebook,
          lastAction : formData.lastAction,
          lastActionDate : formData.lastActionDate,
          deleted : formData.deleted,
          servMobilePrefixId : formData.servMobilePrefixId,
          servLandlinePrefixId : formData.servLandlinePrefixId,
          servCompaniesStateCompaniesState : formData.servCompaniesStateCompaniesState,
          servCompaniesCategoryId : formData.servCompaniesCategoryId,
          servCompaniesEmployeesNumId : formData.servCompaniesEmployeesNumId,
          servCompaniesEmployeesYear : formData.servCompaniesEmployeesYear,
          servCompaniesTurnoverYear : formData.servCompaniesTurnoverYear,
          servCompaniesTurnoverId : formData.servCompaniesTurnoverId,
          servCompaniesBusinessSectorId : formData.servCompaniesBusinessSectorId,
          countryOfOrigin : formData.countryOfOrigin,
          officeCountry : formData.officeCountry,
          initialCamComm : formData.initialCamComm,
          returnSecureToken : true
          
        },auth)
        .then(res => {
          
          if(res.data.messages == 'Success Operation')
          {
            // aggiorno anche l'utente LR

            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/updateUserShort',
                {
                   id : this.userIdLR,
                   name : this.name,
                   surname : this.surname,
                   sex : this.sex,
                   countryOfResidence : this.countryOfResidence,
                   birthPlace : this.birthPlace,
                   birthDate : this.birthDate,
                   documentNumber : this.documentNumber,
                   servDocTypeIdLR : this.servDocTypeIdLR,
                   userIdLR : this.userIdLR,
                   email : this.emailLR,
                   fiscalCode : this.fiscalCode
                },auth)
                .then(res => {

                    if(this.companySuccessfulBO != 'S'){
                      this.msg = this.searchevents(this.labels,'modify','operationsuccess');
                    }else{


                      if(this.getUserRole=='BOF'){
                        this.msg = this.searchevents(this.labels,'modify','operationsuccess');
                      }else{
                        this.sendEmailBO('a5','viia');
                        
                        this.msg = this.searchevents(this.labels,'dialog','afteractivateyorprofile');
                      }
                      
                    }
                    
                    // se necessario rinnovo il contratto della company
                    if(this.generaNuovoContrattoAction==true){
                      this.generaNuovoContratto();
                      this.generaNuovoContrattoAction = false;
                    }

                    //this.poupvisibile = true;
                    //setTimeout(this.closeAlert,3000);
                    //console.log(this.$refs.myModalAlert);
                    //this.$refs.myModalAlert.modal('show');
                    //setTimeout(this.$refs.myModalAlert.close(),3000);
                    alert(this.msg);

                    //if(this.companySuccessfulBO=='S'){
                      router.push('/dashboard');
                    //}
                    return res;
                })
                .catch(
                error => {
                
                return error;
                }
                );

            
          }

            // eseguo sempre un controllo per verificare se aggiornare lo stato dell'azienda
            /*
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/checkCompanyStatus',
            {
                companyId : formData.id
            },auth)
            .then(res1 => {  
                return res1; 
            })
            .catch(
            error => {
              return error;
            }
            );
            */

           
          return res;
          //router.push('/dashboard');
        })
        .catch(
          error => {
          this.msg = this.searchevents(this.labels,'modify','operationfailed');
          //this.poupvisibile = true;
          //setTimeout(this.closeAlert,3000);
          alert(this.msg);
          // faccio qualcosa qui
            return error;
          }
          );
        }
      
    },
    viewDocTypeDesc(type){
      // ex this.countryOfResidence
      let response = '';
      if(type==1){
        if(this.lang=='Ita'){ response = 'Visura'; }
        if(this.lang=='Eng'){ response = 'Company Report / Statement with Directors'; }
        if(this.lang=='Den'){ response = 'Virksomhedsraport'; }
      }
      if(type==2){
        if(this.lang=='Ita'){ response = 'Passaporto'; }
        if(this.lang=='Eng'){ response = 'Passport'; }
        if(this.lang=='Den'){ response = 'Pas'; }
      }
      if(type==3){
        if(this.lang=='Ita'){ response = 'Carta identità'; }
        if(this.lang=='Eng'){ response = 'ID Card'; }
        if(this.lang=='Den'){ response = 'Identitetskort'; }
      }
      if(type==4){
        if(this.lang=='Ita'){ response = 'Logo (JPG)'; }
        if(this.lang=='Eng'){ response = 'Logo (JPG)'; }
        if(this.lang=='Den'){ response = 'Logo (JPG)'; }
      }
      if(type==5){
        if(this.lang=='Ita'){ response = 'Video (MPG)'; }
        if(this.lang=='Eng'){ response = 'Video (MPG)'; }
        if(this.lang=='Den'){ response = 'Video (MPG)'; }
      }
      if(type==6){
        if(this.lang=='Ita'){ response = 'Immagine (JPG)'; }
        if(this.lang=='Eng'){ response = 'Image (JPG)'; }
        if(this.lang=='Den'){ response = 'Billede (JPG)'; }
      }
      if(type==7){
        if(this.lang=='Ita'){ response = 'Carta identità cartacea (PDF)'; }
        if(this.lang=='Eng'){ response = 'ID Card (PDF)'; }
        if(this.lang=='Den'){ response = 'Identitetskort (PDF)'; }
      }
      if(type==8){
        if(this.lang=='Ita'){ response = 'Altro società (JPG)'; }
        if(this.lang=='Eng'){ response = 'Other company (JPG)'; }
        if(this.lang=='Den'){ response = 'Andet selskab (JPG)'; }
      }
      if(type==9){
        if(this.lang=='Ita'){ response = 'Altro (PDF)'; }
        if(this.lang=='Eng'){ response = 'Other (PDF)'; }
        if(this.lang=='Den'){ response = 'Andet (PDF)'; }
      }
      if(type==10){
        if(this.lang=='Ita'){ response = 'Patente (PDF)'; }
        if(this.lang=='Eng'){ response = 'Driving License (PDF)'; }
        if(this.lang=='Den'){ response = 'Kørekort (PDF)'; }
      }
      if(type==11){
        if(this.lang=='Ita'){ response = 'Catalogo (PDF)'; }
        if(this.lang=='Eng'){ response = 'Catalog (PDF)'; }
        if(this.lang=='Den'){ response = 'Katalog (PDF)'; }
      }
      

      return response;
    },
    viewDocTypeDesc2(type){
      let response = '';
      //console.log(this.lang+'---'+type)
      if(type==1){
        if(this.lang=='Ita'){ response = 'Visura sostitutiva (PDF)'; }
        if(this.lang=='Eng'){ response = 'Updated Company Registration Report (PDF)'; }
        if(this.lang=='Den'){ response = 'Opdateret Virksomhedsrapport (PDF)'; }
      }
      if(type==2){
        if(this.lang=='Ita'){ response = 'Passaporto (PDF)'; }
        if(this.lang=='Eng'){ response = 'Passport (PDF)'; }
        if(this.lang=='Den'){ response = 'Pas (PDF)'; }
      }
      if(type==3){
        if(this.lang=='Ita'){ response = 'Carta identità (PDF)'; }
        if(this.lang=='Eng'){ response = 'ID Card (PDF)'; }
        if(this.lang=='Den'){ response = 'Identitetskort (PDF)'; }
      }
      if(type==4){
        if(this.lang=='Ita'){ response = 'Logo (JPG)'; }
        if(this.lang=='Eng'){ response = 'Logo (JPG)'; }
        if(this.lang=='Den'){ response = 'Logo (JPG)'; }
      }
      if(type==5){
        if(this.lang=='Ita'){ response = 'Video (MPG)'; }
        if(this.lang=='Eng'){ response = 'Video (MPG)'; }
        if(this.lang=='Den'){ response = 'Video (MPG)'; }
      }
      if(type==6){
        if(this.lang=='Ita'){ response = 'Immagine (JPG)'; }
        if(this.lang=='Eng'){ response = 'Image (JPG)'; }
        if(this.lang=='Den'){ response = 'Billede (JPG)'; }
      }
      if(type==7){
        if(this.lang=='Ita'){ response = 'Carta identità cartacea (PDF)'; }
        if(this.lang=='Eng'){ response = 'ID Card (PDF)'; }
        if(this.lang=='Den'){ response = 'Identitetskort (PDF)'; }
      }
      if(type==8){
        if(this.lang=='Ita'){ response = 'Altro società (JPG)'; }
        if(this.lang=='Eng'){ response = 'Other company (JPG)'; }
        if(this.lang=='Den'){ response = 'Andet selskab (JPG)'; }
      }
      if(type==9){
        if(this.lang=='Ita'){ response = 'Altro (PDF)'; }
        if(this.lang=='Eng'){ response = 'Other (PDF)'; }
        if(this.lang=='Den'){ response = 'Andet (PDF)'; }
      }
      if(type==10){
        if(this.lang=='Ita'){ response = 'Patente (PDF)'; }
        if(this.lang=='Eng'){ response = 'Driving License (PDF)'; }
        if(this.lang=='Den'){ response = 'Kørekort (PDF)'; }
      }
      if(type==11){
        if(this.lang=='Ita'){ response = 'Catalogo (PDF)'; }
        if(this.lang=='Eng'){ response = 'Catalog (PDF)'; }
        if(this.lang=='Den'){ response = 'Katalog (PDF)'; }
      }
      
      //console.log(response+'<<<<<<<<')
      return response;
    },
    searchevents(events,filterValue,label){
        if(events){
          const filter = event => 
            event.tableName.includes(filterValue) &&
            event.fieldName.includes(label)
        
          var filteredArray = events.filter(filter);
          if(filteredArray.length >0)
          {
            if(this.lang)
            {
              var labelName = 'label'+this.lang;
              return filteredArray[0][labelName];
            }else{
              return '--';
            }
            
          }else{
            return '--';
          }
        }else{
          return null;
        }
      },
    returnMenu(num){
      var menu = this.dropM;
      if(menu)
      {
        return menu[num];
      }else{
        return null;
      }
    }
  },
  computed :{
    years () {
      //const year = new Date().getFullYear();
      return Array.from({length: 35 }, (value, index) => 1998 + index)
    },
    isDisabled: function(){
      if(this.getUserRole=='BOF'){
        return false;
      }else{
        return true;
      }
    },
    isDisabledMail: function(){
      if(this.getUserRole=='BOF'){
        return false;
      }else{
        if(this.id=='' || this.id==null){
          return false;
        }else{
          return true;
        }
        
      }
    },
    isNewInsert: function(){
      if(this.id!= null && this.id!='' && this.getUserRole!='BOF'){
        return true;
      }else{
        return false;
      }
    },
    lang(){
      var lng = this.$store.getters.lang;
        if(lng)
        {
          return lng;
        }else{
          return '';
        }
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
    getUserRole(){
      return this.$store.getters.getUserRole;
    },
    getUserId(){
       // return null;
       return this.$store.getters.getUserId;
    },
    userIdentity(){
      return this.$store.getters.getUserNameSurname;
    },
    getIdUsersParent(){
       // return null;
       return this.$store.getters.getIdUsersParent;
    },
    labels(){
      return this.$store.getters.labels;
    },
    dropM(){
      
      var menu = this.$store.getters.dropM;
      if(menu){
        //this.dropM1 = menu[11];
        return menu;
      }else{
        return null;
      }
      
    },
    getregisteredCompany(){
      var rcomp = this.$store.getters.getregisteredCompany;
      if(rcomp){
        
        return rcomp;
      }else{ 
        return 0;
      }
    },
    filteredItems() {
      
      if(!this.atecoSearch || this.atecoSearch==''){ return ''; }
      
       const search = this.atecoSearch.toLowerCase().trim();

      if (!search) return this.atecoList;

      let aa = '';
      let bb = '';
      
      if(this.countryOfOrigin=='1'){

        aa = this.atecoList.filter(c => 
          c.APPL_REGISTR.indexOf('IT') > -1 && c.ATTIVIT_CON_PUNTO_3LIVELLI.toLowerCase().indexOf(search) > -1
        );

        bb = this.atecoList.filter(c => 
          c.Descrizione.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('IT') > -1
        );
      }

      if(this.countryOfOrigin=='2'){

        aa = this.atecoList.filter(c => 
          c.APPL_REGISTR.indexOf('IT') > -1 && c.ATTIVIT_CON_PUNTO_3LIVELLI.toLowerCase().indexOf(search) > -1
        );

        bb = this.atecoList.filter(c => 
          c.Descrizione.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('IT') > -1
        );
      }

      if(this.countryOfOrigin!='1' && this.countryOfOrigin!='2'){

        aa = this.atecoList.filter(c => 
         c.APPL_REGISTR.indexOf('DK') > -1 && c.ATTIVIT_CON_PUNTO_3LIVELLI.toLowerCase().indexOf(search) > -1 
        );

        bb = this.atecoList.filter(c => 
          c.Descrizione.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('DK') > -1
        );
      }
      
      

      if(aa.length>0){ return aa; }else{ return bb; }
      
    },
    filteredItemsEng() {
      if(!this.atecoSearch || this.atecoSearch==''){ return ''; }
      
       const search = this.atecoSearch.toLowerCase().trim();

      if (!search) return this.atecoList;
      
      let aa = '';
      let bb = '';
      
      if(this.countryOfOrigin=='1'){

        aa = this.atecoList.filter(c => 
        c.ATTIVIT_CON_PUNTO_3LIVELLI.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('IT') > -1
        );

        bb = this.atecoList.filter(c => 
          c.Descrizione_eng.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('IT') > -1
        );
      }

      if(this.countryOfOrigin=='2'){

        aa = this.atecoList.filter(c => 
        c.ATTIVIT_CON_PUNTO_3LIVELLI.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('IT') > -1
        );

        bb = this.atecoList.filter(c => 
          c.Descrizione_eng.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('IT') > -1
        );
      }

      if(this.countryOfOrigin!='1' && this.countryOfOrigin!='2'){

        aa = this.atecoList.filter(c => 
        c.ATTIVIT_CON_PUNTO_3LIVELLI.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('DK') > -1
        );

        bb = this.atecoList.filter(c => 
          c.Descrizione_eng.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('DK') > -1
        );
      }

      if(aa.length>0){ return aa; }else{ return bb; }
      
    },
    filteredItemsDen() {
      if(!this.atecoSearch || this.atecoSearch==''){ return ''; }
      
       const search = this.atecoSearch.toLowerCase().trim();

      if (!search) return this.atecoList;
      
      let aa = '';
      let bb = '';
      
      if(this.countryOfOrigin=='1'){

        aa = this.atecoList.filter(c => 
        c.ATTIVIT_CON_PUNTO_3LIVELLI.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('IT') > -1
        );

        bb = this.atecoList.filter(c => 
          c.Descrizione_den.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('IT') > -1
        );
      }

      if(this.countryOfOrigin=='2'){

        aa = this.atecoList.filter(c => 
        c.ATTIVIT_CON_PUNTO_3LIVELLI.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('IT') > -1
        );

        bb = this.atecoList.filter(c => 
          c.Descrizione_den.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('IT') > -1
        );
      }

      if(this.countryOfOrigin!='1' && this.countryOfOrigin!='2'){

        aa = this.atecoList.filter(c => 
        c.ATTIVIT_CON_PUNTO_3LIVELLI.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('DK') > -1
        );

        bb = this.atecoList.filter(c => 
          c.Descrizione_den.toLowerCase().indexOf(search) > -1 && c.APPL_REGISTR.indexOf('DK') > -1
        );
      }
      
      if(aa.length>0){ return aa; }else{ return bb; }
      
    },
    filteredItemsSic() {
      if(!this.atecoSearch || this.atecoSearch==''){ return ''; }
      
       const search = this.atecoSearch.toLowerCase().trim();

      if (!search) return this.sicList;
      
      //let aa = '';
      let aa = this.sicList.filter(c => 
        c.SIC_Code.toLowerCase().indexOf(search) > -1 
      );

     
      let bb = this.sicList.filter(c => 
      c.Company_house_SIC_Des.toLowerCase().indexOf(search) > -1 
      );
      //let bb = '';

      if(aa.length>0){ return aa; }else{ return bb; }
      
    },
    docListStep1a: function() {
      var vm = this;
        let newdoc = [];
        
        vm.docList.forEach(u => {
           
            if(u.description=="Visura (PDF)" || u.description=="Altro società / Other company (JPG)" ){
              newdoc.push(u);
            }
         
        });
        //newdoc2.push(newdoc);
        
				return newdoc;
        
       //return vm.dropCompaniesDocs;
			
    },
    docListStep1b: function() {
      var vm = this;
        let newdoc = [];
        
        vm.docList.forEach(u => {
           
            if(u.description=="Carta identità / Identity card (PDF)" || u.description=="Patente / Driving license (JPG)" || u.description=="Passaporto / Passport (PDF)" || u.description=="Altro / Other (JPG)" ){
              newdoc.push(u);
            }
         
        });
        //newdoc2.push(newdoc);
        
				return newdoc;
        
       //return vm.dropCompaniesDocs;
			
    },
    docListStep1bEng: function() {
      var vm = this;
        let newdoc = [];
        
        vm.docList.forEach(u => {
           
            if(u.description=="Carta identità / Identity card (PDF)" || u.description=="Patente / Driving license (JPG)" || u.description=="Passaporto / Passport (PDF)" || u.description=="Altro / Other (JPG)" ){
              newdoc.push(u);
            }
         
        });
        //newdoc2.push(newdoc);
        
				return newdoc;
        
       //return vm.dropCompaniesDocs;
			
    },
    docListStep2: function() {
      var vm = this;
        let newdoc = [];
        
        vm.docList.forEach(u => {
           
            if(u.description=="Visura sostitutiva/ Alternate certificate (PDF)" ||  u.description=="Logo (JPG)" || u.description=="Video (MP4)" || u.description=="Catalogo (PDF)"){
              newdoc.push(u);
            }
         
        });
        //newdoc2.push(newdoc);
        
				return newdoc;
        
       //return vm.dropCompaniesDocs;
			
    },
    docListStep3: function() {
      var vm = this;
        let newdoc = [];
        
        vm.docList.forEach(u => {
           
            if(u.description!="Visura sostitutiva/ Alternate certificate (PDF)" && u.description!="Logo (JPG)" && u.description!="Video (MP4)" && u.description!="Visura (PDF)" && u.description!="Carta identità / Identity card (PDF)" && u.description!="Codice fiscale / Fiscal code (PDF)" && u.description!="Patente / Driving license (JPG)" && u.description!="Passaporto / Passport (PDF)" && u.description!="Altro / Other (JPG)" && u.description!="Altro società / Other company (JPG)" && u.description!="Carta identità cartacea / Old Identity card (PDF)" && u.description!="Catalogo (PDF)"){
              newdoc.push(u);
            }
         
        });
        //newdoc2.push(newdoc);
        
				return newdoc;
        
       //return vm.dropCompaniesDocs;
			
    },
    dropCompaniesDocsAIT: function() {
      var vm = this;
        let newdoc = [];
        let newdoc2 = [];
        
        vm.docList.forEach(u => {
          
          u.forEach(u2 => {
           
            if(u2.docTypeIta=="Patente" || u2.docTypeIta=="Passaporto" || u2.docTypeIta=="Carta identità elettronica" ){
              newdoc.push(u2);
            }
          });
          
        });
        newdoc2.push(newdoc);
        
				return newdoc2;
        
       //return vm.dropCompaniesDocs;
			
    },
    dropCompaniesDocsA: function() {
      var vm = this;
        let newdoc = [];
        let newdoc2 = [];
        
        vm.dropCompaniesDocsAll.forEach(u => {
          
          u.forEach(u2 => {
           
            if(u2.docTypeIta=="Patente" || u2.docTypeIta=="Passaporto" ){
              newdoc.push(u2);
            }
          });
          
        });
        newdoc2.push(newdoc);
        
				return newdoc2;
        
       //return vm.dropCompaniesDocs;
			
    },
    dropCompaniesDocsALL: function() {
      var vm = this;
        let newdoc = [];
        let newdoc2 = [];
        
        vm.dropCompaniesDocsAll.forEach(u => {
          
          u.forEach(u2 => {
           
            if(u2.docTypeIta!="Patente" && u2.docTypeIta!="Passaporto"  && u2.docTypeIta!="Carta identità elettronica" && u2.docTypeIta!="Carta identità cartacea" ){
              newdoc.push(u2);
            }
          });
          
        });
        newdoc2.push(newdoc);
        
				return newdoc2;
        
       //return vm.dropCompaniesDocs;
			
    },
    dropCompaniesDocsC: function() {
      var vm = this;
        let newdoc = [];
        let newdoc2 = [];
        
        vm.dropCompaniesDocs.forEach(u => {
          
          u.forEach(u2 => {
            
            if(u2.docTypeIta=="Codice fiscale" || u2.docTypeIta=="Carta identità elettronica" ){
              newdoc.push(u2);
            }
          });
          
        });
        newdoc2.push(newdoc);
        
				return newdoc2;
        
       //return vm.dropCompaniesDocs;
			
    },
    dropCompaniesDocsAllC: function() {
      var vm = this;
        let newdoc = [];
        let newdoc2 = [];
        
        vm.dropCompaniesDocs.forEach(u => {
          
          u.forEach(u2 => {
           
            if(u2.docTypeIta=="Codice fiscale" || u2.docTypeIta=="Carta identità elettronica" ){
              newdoc.push(u2);
            }
          });
          
        });
        newdoc2.push(newdoc);
        
				return newdoc2;
        
       //return vm.dropCompaniesDocs;
			
		}
  },
  created(){
    if(this.dropMservLandlinePrefixId===null && this.dropM!=null){
      this.dropMservLandlinePrefixId = this.dropM.serv_mobile_prefixes;
    }
    if(this.dropMofficeRegion===null && this.dropM!=null){
      this.dropMofficeRegion = this.dropM.serv_countries_lists_all;
    }
    if(this.dropMofficeRegionRegister===null && this.dropM!=null){
      this.dropMofficeRegionRegister = this.dropM.serv_countries_lists;
    }

    
    if(this.dropComanyState===null && this.dropM!=null){
      this.dropComanyState = this.dropM.serv_companies_states;
    }
    
    if(this.dropCompaniesEmployees===null && this.dropM!=null){
      this.dropCompaniesEmployees = this.dropM.serv_companies_employees_nums;
    }
    if(this.dropCompaniesTurnover===null && this.dropM!=null){
      this.dropCompaniesTurnover = this.dropM.serv_companies_turnovers;
    }
    if(this.dropCompaniesBusinessSector===null && this.dropM!=null){
      this.dropCompaniesBusinessSector = this.dropM.serv_companies_business_sectors;
    }
    if(this.dropsCompaniesCategoryIT===null && this.dropM!=null){
      this.dropsCompaniesCategoryIT = this.dropM.serv_companies_categories_it;
      
    }
    if(this.dropsCompaniesCategoryEN===null && this.dropM!=null){
      this.dropsCompaniesCategoryEN = this.dropM.serv_companies_categories_uk;
    }
    if(this.dropsCompaniesCategoryDE===null && this.dropM!=null){
      this.dropsCompaniesCategoryDE = this.dropM.serv_companies_categories_de;
    }
    if(this.dropsinitialCamComm===null && this.dropM!=null){
      this.dropsinitialCamComm = this.dropM.serv_companies_prov_cameraComms;
    }
    if(this.dropCompaniesDocs===null && this.dropM!=null){
      this.dropCompaniesDocs = this.dropM.serv_doc_types;
    }
    
    if(this.id!='' && this.id!=null){
      //console.log('ok');
    }else{
      //console.log('NOOOOOOOOOO');
      //this.getAllUserByMe();
    }
    
  },
  watch : {
    /*
    birthDate: function(){
        let bd = new Date(this.birthDate);
        let now = new Date();

        console.log( (now - bd) / (1000 * 3600 * 24) / 365);
        console.log(this.magic_flag);
        if( ((now - bd) / (1000 * 3600 * 24) / 365)<18 ){
          this.birthDate = null;
          alert('Data non valida / Invalid date');
        }
        

      },*/
      dropM: function () {
      // potrei anche leggere il nuovo e il vecchio valore (newdropMservLandlinePrefixId,olddropMservLandlinePrefixId)
      this.dropMservLandlinePrefixId = this.dropM.serv_mobile_prefixes;
      this.dropMofficeRegion = this.dropM.serv_countries_lists_all;
      this.dropMofficeRegionRegister = this.dropM.serv_countries_lists;
      
      this.dropComanyState = this.dropM.serv_companies_states;
      this.dropCompaniesEmployees = this.dropM.serv_companies_employees_nums;
      this.dropCompaniesTurnover = this.dropM.serv_companies_turnovers;
      this.dropCompaniesBusinessSector = this.dropM.serv_companies_business_sectors;
      this.dropsCompaniesCategoryIT = this.dropM.serv_companies_categories_it;
      this.dropsCompaniesCategoryEN = this.dropM.serv_companies_categories_uk;
      this.dropsCompaniesCategoryDE = this.dropM.serv_companies_categories_de;
      this.dropsinitialCamComm = this.dropM.serv_companies_prov_cameraComms;
      this.dropCompaniesDocs = this.dropM.serv_doc_types;
      
    },
      getregisteredCompany: function(){
      this.registeredCompany = this.getregisteredCompany;
      this.id = this.getregisteredCompany.id;
      this.companyName = this.getregisteredCompany.companyName;
      this.pec = this.getregisteredCompany.pec;
      this.emailAddress = this.getregisteredCompany.emailAddress;
      this.officeRegion = this.getregisteredCompany.officeRegion;
      this.officeDistrict = this.getregisteredCompany.officeDistrict;
      this.officeCity = this.getregisteredCompany.officeCity;
      this.officeTown = this.getregisteredCompany.officeTown;
      this.officePostcode = this.getregisteredCompany.officePostcode;
      this.officeAddress = this.getregisteredCompany.officeAddress;
      this.vatNumber = this.getregisteredCompany.vatNumber;
      this.idTax = this.getregisteredCompany.idTax,
      this.companyNumber = this.getregisteredCompany.companyNumber;

      let splitComanyNumber = this.vatNumber.split("-");
      if(splitComanyNumber.length>1){
        this.vatNumber = splitComanyNumber[1];
        this.companyNumberCountry = splitComanyNumber[0];
      }
      

      this.operationalCountry = this.getregisteredCompany.operationalCountry;
      this.operationalRegion = this.getregisteredCompany.operationalRegion;
      this.operationalDistrict = this.getregisteredCompany.operationalDistrict;
      this.operationalCity = this.getregisteredCompany.operationalCity;
      this.operationalTown = this.getregisteredCompany.operationalTown;
      this.operationalPostcode = this.getregisteredCompany.operationalPostcode;
      this.operationalAddress = this.getregisteredCompany.operationalAddress;
      this.reaNumber = this.getregisteredCompany.reaNumber;
      this.landlinePhone = this.getregisteredCompany.landlinePhone;
      this.mobilePhone = this.getregisteredCompany.mobilePhone;
      this.companyActivityDescription = this.getregisteredCompany.companyActivityDescription;
      this.companyActivityProducts = this.getregisteredCompany.companyActivityProducts;
      this.companyActivityCertifications = this.getregisteredCompany.companyActivityCertifications;
      this.companyActivityDescription2 = this.getregisteredCompany.companyActivityDescription2;
      this.companyActivityProducts2 = this.getregisteredCompany.companyActivityProducts2;
      this.companyActivityCertifications2 = this.getregisteredCompany.companyActivityCertifications2;
      this.companyActivityDescription3 = this.getregisteredCompany.companyActivityDescription3;
      this.companyActivityProducts3 = this.getregisteredCompany.companyActivityProducts3;
      this.companyActivityCertifications3 = this.getregisteredCompany.companyActivityCertifications3;
      this.companyNotes = this.getregisteredCompany.companyNotes;
      this.boNotes = this.getregisteredCompany.boNotes;
      this.incorporationDate = this.getregisteredCompany.incorporationDate;
      this.dissolutionDate = this.getregisteredCompany.dissolutionDate;
      if(this.getregisteredCompany.partOfGroup=='Y'){this.partOfGroup = true; }else{ this.partOfGroup = false; }
      //this.partOfGroup = this.getregisteredCompany.partOfGroup;
      this.groupName = this.getregisteredCompany.groupName;
      this.groupIdTax = this.getregisteredCompany.groupIdTax;
      this.atecoCode1 = this.getregisteredCompany.atecoCode1;
      this.atecoCode2 = this.getregisteredCompany.atecoCode2;
      this.atecoCode3 = this.getregisteredCompany.atecoCode3;
      this.atecoCode4 = this.getregisteredCompany.atecoCode4;
      this.atecoCode5 = this.getregisteredCompany.atecoCode5;
      this.atecoCode6 = this.getregisteredCompany.atecoCode6;
      this.atecoCode7 = this.getregisteredCompany.atecoCode7;
      // hijack ste
      this.italianFCInorrect = this.atecoCode6;

      this.naceCode1 = this.getregisteredCompany.naceCode1,
      this.naceCode2 = this.getregisteredCompany.naceCode2,
      this.naceCode3 = this.getregisteredCompany.naceCode3,
      this.sicCode1 = this.getregisteredCompany.sicCode1;
      this.sicCode2 = this.getregisteredCompany.sicCode2;
      this.sicCode3 = this.getregisteredCompany.sicCode3;
      this.sicCode4 = this.getregisteredCompany.sicCode4;
      this.naceMacro = this.getregisteredCompany.naceMacro;
      this.naceSector = this.getregisteredCompany.naceSector;
      this.sicMacro = this.getregisteredCompany.sicMacro;
      this.sicSector = this.getregisteredCompany.sicSector;
      this.inspectionDate = this.getregisteredCompany.inspectionDate;
      this.lastInspectionDate = this.getregisteredCompany.lastInspectionDate;
      this.webSite = this.getregisteredCompany.webSite;
      this.linkedin = this.getregisteredCompany.linkedin;
      this.twitter = this.getregisteredCompany.twitter;
      this.facebook = this.getregisteredCompany.facebook;
      this.companyguidContract = this.getregisteredCompany.guidContractFlag;
      if(this.companyguidContract=='Y'){
        this.companyguidContractFlag = true;
      }else{
        this.companyguidContractFlag = false;
      }
      this.lastAction = this.getregisteredCompany.lastAction;
      this.lastActionDate = this.getregisteredCompany.lastActionDate;
      this.blackListed = this.getregisteredCompany.blackListed;
      this.deleted = this.getregisteredCompany.deleted;
      this.createdAt = this.getregisteredCompany.createdAt;
      this.updatedAt = this.getregisteredCompany.updatedAt;
      this.servMobilePrefixId = this.getregisteredCompany.servMobilePrefixId;
      this.servLandlinePrefixId = this.getregisteredCompany.servLandlinePrefixId;
      this.servCompaniesStateCompaniesState = this.getregisteredCompany.servCompaniesStateCompaniesState;
      this.servCompaniesCategoryId = this.getregisteredCompany.servCompaniesCategoryId;
      this.servCompaniesEmployeesNumId = this.getregisteredCompany.servCompaniesEmployeesNumId;
      this.servCompaniesEmployeesYear = this.getregisteredCompany.servCompaniesEmployeesYear;
      this.servCompaniesTurnoverYear = this.getregisteredCompany.servCompaniesTurnoverYear;
      this.servCompaniesTurnoverId = this.getregisteredCompany.servCompaniesTurnoverId;
      this.servCompaniesBusinessSectorId = this.getregisteredCompany.servCompaniesBusinessSectorId;
      this.countryOfOrigin = this.getregisteredCompany.countryOfOrigin;
      this.officeCountry = this.getregisteredCompany.officeCountry;
      this.initialCamComm = this.getregisteredCompany.initialCamComm;
      this.incorporationDate2 = this.getregisteredCompany.incorporationDate;

      this.companyGoBO = false;
      
      if(this.servCompaniesStateCompaniesState=='R'){this.companySuccessfulBOTrad = 'Registrata non sottoposta a BO'; this.companySuccessfulBO = 'R'; this.companyReadyBO = false; }else{this.companyReadyBO = true; }
      if(this.servCompaniesStateCompaniesState=='A'){this.companySuccessfulBOTrad = 'Approvata'; this.companySuccessfulBO = 'A';}
      if(this.servCompaniesStateCompaniesState=='C'){this.companyGoBO = true; this.companySuccessfulBO = 'C';}
      if(this.servCompaniesStateCompaniesState=='J'){this.companySuccessfulBOTrad = 'Rifiutata';  this.companySuccessfulBO = 'J';}
      if(this.servCompaniesStateCompaniesState=='H'){this.companySuccessfulBOTrad = 'Sospesa';  this.companySuccessfulBO = 'H';}
      if(this.servCompaniesStateCompaniesState=='P'){this.companySuccessfulBOTrad = 'In lavorazione'; this.companySuccessfulBO = 'P'; }
      if(this.servCompaniesStateCompaniesState=='S'){this.companySuccessfulBOTrad = 'Non lavorato da BO'; this.companySuccessfulBO = 'S';}

      
    }
  },
  mounted(){
    this.$nextTick(function () {
      var reg = this.$store.getters.getregisteredCompany;
      if(reg!=null){ this.registeredCompany = reg; }
      
      
      if(this.registeredCompany && this.registeredCompany.id == null){
        router.push('/mycompany');
        
      }else{
        this.id = this.getregisteredCompany.id;
        this.companyName = this.getregisteredCompany.companyName;
        this.pec = this.getregisteredCompany.pec;
        this.emailAddress = this.getregisteredCompany.emailAddress;
        this.officeRegion = this.getregisteredCompany.officeRegion;
        this.officeDistrict = this.getregisteredCompany.officeDistrict;
        this.officeCity = this.getregisteredCompany.officeCity;
        this.officeTown = this.getregisteredCompany.officeTown;
        this.officePostcode = this.getregisteredCompany.officePostcode;
        this.officeAddress = this.getregisteredCompany.officeAddress;
        this.vatNumber = this.getregisteredCompany.vatNumber;
        this.idTax = this.getregisteredCompany.idTax,
        this.companyNumber = this.getregisteredCompany.companyNumber;

        if(this.vatNumber!='' && this.vatNumber!=null){
          let splitComanyNumber = this.vatNumber.split("-");
          if(splitComanyNumber.length>1){
            this.vatNumber = splitComanyNumber[1];
            this.companyNumberCountry = splitComanyNumber[0];
          }
        }
        
        
        this.operationalCountry = this.getregisteredCompany.operationalCountry;
        this.operationalRegion = this.getregisteredCompany.operationalRegion;
        this.operationalDistrict = this.getregisteredCompany.operationalDistrict;
        this.operationalCity = this.getregisteredCompany.operationalCity;
        this.operationalTown = this.getregisteredCompany.operationalTown;
        this.operationalPostcode = this.getregisteredCompany.operationalPostcode;
        this.operationalAddress = this.getregisteredCompany.operationalAddress;
        this.reaNumber = this.getregisteredCompany.reaNumber;
        this.landlinePhone = this.getregisteredCompany.landlinePhone;
        this.mobilePhone = this.getregisteredCompany.mobilePhone;
        this.companyActivityDescription = this.getregisteredCompany.companyActivityDescription;
        this.companyActivityProducts = this.getregisteredCompany.companyActivityProducts;
        this.companyActivityCertifications = this.getregisteredCompany.companyActivityCertifications;
        this.companyActivityDescription2 = this.getregisteredCompany.companyActivityDescription2;
        this.companyActivityProducts2 = this.getregisteredCompany.companyActivityProducts2;
        this.companyActivityCertifications2 = this.getregisteredCompany.companyActivityCertifications2;
        this.companyActivityDescription3 = this.getregisteredCompany.companyActivityDescription3;
        this.companyActivityProducts3 = this.getregisteredCompany.companyActivityProducts3;
        this.companyActivityCertifications3 = this.getregisteredCompany.companyActivityCertifications3;
        this.companyNotes = this.getregisteredCompany.companyNotes;
        this.boNotes = this.getregisteredCompany.boNotes;
        this.incorporationDate = this.getregisteredCompany.incorporationDate;
        this.dissolutionDate = this.getregisteredCompany.dissolutionDate;
        if(this.getregisteredCompany.partOfGroup=='Y'){this.partOfGroup = true; }else{ this.partOfGroup = false; }
        //this.partOfGroup = this.getregisteredCompany.partOfGroup;
        this.groupName = this.getregisteredCompany.groupName;
        this.groupIdTax = this.getregisteredCompany.groupIdTax;
        this.atecoCode1 = this.getregisteredCompany.atecoCode1;
        this.atecoCode2 = this.getregisteredCompany.atecoCode2;
        this.atecoCode3 = this.getregisteredCompany.atecoCode3;
        this.atecoCode4 = this.getregisteredCompany.atecoCode4;
        this.atecoCode5 = this.getregisteredCompany.atecoCode5;
        this.atecoCode6 = this.getregisteredCompany.atecoCode6;
        this.italianFCInorrect = this.atecoCode6;
     
        this.atecoCode7 = this.getregisteredCompany.atecoCode7;
        this.naceCode1 = this.getregisteredCompany.naceCode1,
        this.naceCode2 = this.getregisteredCompany.naceCode2,
        this.naceCode3 = this.getregisteredCompany.naceCode3,
        this.sicCode1 = this.getregisteredCompany.sicCode1;
        this.sicCode2 = this.getregisteredCompany.sicCode2;
        this.sicCode3 = this.getregisteredCompany.sicCode3;
        this.sicCode4 = this.getregisteredCompany.sicCode4;
        this.naceMacro = this.getregisteredCompany.naceMacro;
        this.naceSector = this.getregisteredCompany.naceSector;
        this.sicMacro = this.getregisteredCompany.sicMacro;
        this.sicSector = this.getregisteredCompany.sicSector;
        this.inspectionDate = this.getregisteredCompany.inspectionDate;
        this.lastInspectionDate = this.getregisteredCompany.lastInspectionDate;
        this.webSite = this.getregisteredCompany.webSite;
        this.linkedin = this.getregisteredCompany.linkedin;
        this.twitter = this.getregisteredCompany.twitter;
        this.facebook = this.getregisteredCompany.facebook;
        this.companyguidContract = this.getregisteredCompany.guidContractFlag;
        if(this.companyguidContract=='Y'){
          this.companyguidContractFlag = true;
        }else{
          this.companyguidContractFlag = false;
        }
        this.lastAction = this.getregisteredCompany.lastAction;
        this.lastActionDate = this.getregisteredCompany.lastActionDate;
        this.blackListed = this.getregisteredCompany.blackListed;
        this.deleted = this.getregisteredCompany.deleted;
        this.createdAt = this.getregisteredCompany.createdAt;
        this.updatedAt = this.getregisteredCompany.updatedAt;
        this.servMobilePrefixId = this.getregisteredCompany.servMobilePrefixId;
        this.servLandlinePrefixId = this.getregisteredCompany.servLandlinePrefixId;
        this.servCompaniesStateCompaniesState = this.getregisteredCompany.servCompaniesStateCompaniesState;
        this.servCompaniesCategoryId = this.getregisteredCompany.servCompaniesCategoryId;
        this.servCompaniesEmployeesNumId = this.getregisteredCompany.servCompaniesEmployeesNumId;
        this.servCompaniesEmployeesYear = this.getregisteredCompany.servCompaniesEmployeesYear;
        this.servCompaniesTurnoverYear = this.getregisteredCompany.servCompaniesTurnoverYear;
        this.servCompaniesTurnoverId = this.getregisteredCompany.servCompaniesTurnoverId;
        this.servCompaniesBusinessSectorId = this.getregisteredCompany.servCompaniesBusinessSectorId;
        this.countryOfOrigin = this.getregisteredCompany.countryOfOrigin;
        this.officeCountry = this.getregisteredCompany.officeCountry;
        this.initialCamComm = this.getregisteredCompany.initialCamComm;
        this.incorporationDate2 = this.getregisteredCompany.incorporationDate;

        this.companyGoBO = false;
        
        if(this.servCompaniesStateCompaniesState=='R'){this.companySuccessfulBOTrad = 'Registrata non sottoposta a BO'; this.companySuccessfulBO = 'R'; this.companyReadyBO = false; }else{this.companyReadyBO = true; }
        if(this.servCompaniesStateCompaniesState=='A'){this.companySuccessfulBOTrad = 'Approvata'; this.companySuccessfulBO = 'A';}
        if(this.servCompaniesStateCompaniesState=='C'){this.companyGoBO = true; this.companySuccessfulBO = 'C';}
        if(this.servCompaniesStateCompaniesState=='H'){this.companySuccessfulBOTrad = 'Sospesa';  this.companySuccessfulBO = 'H';}
        if(this.servCompaniesStateCompaniesState=='J'){this.companySuccessfulBOTrad = 'Rifiutata';  this.companySuccessfulBO = 'J';}
        if(this.servCompaniesStateCompaniesState=='P'){this.companySuccessfulBOTrad = 'In lavorazione'; this.companySuccessfulBO = 'P'; }
        if(this.servCompaniesStateCompaniesState=='S'){this.companySuccessfulBOTrad = 'Non lavorato da BO'; this.companySuccessfulBO = 'S';}
        
        if(this.officeRegion=='' || this.officeRegion==null){
          this.officeRegion = this.countryOfOrigin;
        }


        if(this.countryOfOrigin==1){
          if(this.servLandlinePrefixId=='' || this.servLandlinePrefixId==null ||  this.servLandlinePrefixId==1){
            this.servLandlinePrefixId = 4;
          }
          if(this.servMobilePrefixId=='' || this.servMobilePrefixId==null || this.servMobilePrefixId==1){
            this.servMobilePrefixId = 4;
          }
        }

        if(this.countryOfOrigin==2){
          if(this.servLandlinePrefixId=='' || this.servLandlinePrefixId==null || this.servLandlinePrefixId==1){
            this.servLandlinePrefixId = 2;
          }
          if(this.servMobilePrefixId=='' || this.servMobilePrefixId==null || this.servMobilePrefixId==1){
            this.servMobilePrefixId = 2;
          }
        }

        if(this.countryOfOrigin==504){
          if(this.servLandlinePrefixId=='' || this.servLandlinePrefixId==null || this.servLandlinePrefixId==1){
            this.servLandlinePrefixId = 59;
          }
          if(this.servMobilePrefixId=='' || this.servMobilePrefixId==null || this.servMobilePrefixId==1){
            this.servMobilePrefixId = 59;
          }
        }


        if(this.companyNumberCountry==null || this.companyNumberCountry==''){
          if(this.countryOfOrigin=='1'){
            this.companyNumberCountry = 'IT';
          }
          if(this.countryOfOrigin=='2'){
            this.companyNumberCountry = 'GB';
          }
          if(this.countryOfOrigin=='504'){
            this.companyNumberCountry = 'DK';
          }
        }
        

        this.getAtecoList();
        this.getSicList();
        this.getDocsList();
        this.getCountryList();

        this.getAllUserByMe();
        
        
      }
    });

    
  },
  beforeUpdate(){
    
  },
  updated(){
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.overlayfull {
  position:relative;
  margin-left: -1%;
  height:700px;
  width:98%;
  background: transparent;
  background-color: transparent;
}
.overlayfullext {
  position:absolute;
  margin-left: 1%;
  top:168%;
  height:730px;
  width:93%;
  background-color: #0c294b;
}
.count{
  font-size: 7em;
}
.btn{
  font-size: 2em;
}
hr{
  border-top: 2px solid #2c3e50;
}
.form-group{
  margin-top: 20px;
}
.norequiredMod{
  padding: 5px;
}
.requiredMod{
  background-color: burlywood; 
  padding: 5px;
}

.requiredlight{
  background-color: rgb(247, 240, 232); 
  padding: 5px;
}
.requiredModX{
  background-color:#e5d237;
  padding: 5px;
}
.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8,
.col-9, .col-10, .col-11, .col-12{
  text-align: left;
}

.nav-tabs .nav-link {
    width: 250px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #e6d235;
}

.nav-tabs .nav-link {
    background-color: #e4e4e4;
    color: black;
}

.buttonR {
  
  border-radius: 60%;
  background-color: blue;
  color: white;
  width: 40px;
    height: 40px;
    font-size: 1.5em;
  }

.btn-danger {
    color: #dd1d00;
    background-color: #fff;
    border-color: #dd1d00;
}

.btn-danger:hover {
    color: #dd1d00;
    background-color: #dd1d001e;
    border-color: #dd1d00;
}

  .row {
    margin-left: 0px;
}

.onlybo{
  /* border: 2px solid #b72323; */
  border: 1px solid #ced4da;
}

.greenrequired{
  border: 1px solid #ced4da;
}

.noarrow select::-ms-expand {
  display: none;
}

.noarrow select{
    -webkit-appearance: none;
    appearance: none;
}

.fiscalCodeIncorrect{
  margin-left: 8px;
    background-color: red;
    padding: 5px;
}

.rowDoc{
  padding: 10px;
    background-color: aliceblue;
    border-bottom-style: solid;
    margin-bottom: 10px;
    border-bottom-width: 1px;
    margin-top: 10px;
    width: 100%;
}

.mark, mark {
    padding: .2em;
    background-color: #e5d237;
}
.alert-dismissible {
    padding-right: 0rem;
}

</style>
