<template>
  <div class="container" style="margin-top:80px;">
    
      <h1>{{message}}</h1>
      
      <div class="form-group">
        
        <div class="row">
          <div class="col">
              
              <h3 for="disclaimer04" >{{message2}}</h3>
          </div>
        </div>
          
      </div>

      
  </div>
</template>


<script>

//import router from '@/router';
import gAxios from 'axios';

export default {
  name: 'ConfirmRegistration',
  data () {
    return {
      message : null,
      message2 : null
    }
  },
  methods : {
    searchevents(events,filterValue,label){
        if(events){
          const filter = event => 
            event.tableName.includes(filterValue) &&
            event.fieldName.includes(label)
        
          var filteredArray = events.filter(filter);
          if(filteredArray.length >0)
          {
            if(this.lang)
            {
              var labelName = 'label'+this.lang;
              return filteredArray[0][labelName];
            }else{
              return '--';
            }
            
          }else{
            return '--';
          }
        }else{
          return null;
        }
      },
  },
  computed :{
    lang(){
      return this.$store.getters.lang;
    },
    labels(){
      return this.$store.getters.labels;
    },
    FistStepRegister(){
        return this.$store.getters.FistStepRegister;
    },
    getConfirmedEmail(){
        return this.$store.getters.getEmailConfirmation;
    }
    
  },
  created(){
    
    if(this.$route.params.guid!='' ){
        // ritorna risposta positiva o negativa
        
        let paramSplit = this.$route.params.act.split('@');
        // console.log(this.$route.params.act + '----' + this.$route.params.guid);
        // console.log(paramSplit);
        if(paramSplit[0]=='LRNC')
        {
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/contractsignedmodCompany',
            {
                guidIdentity : this.$route.params.guid,
                companyId : paramSplit[1]
            })
            .then(res => {
                
                return res;
            })
            .catch(
                error => {
                return error;
                }
            );
        }else{

          gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/confirmemail',
            {
                guidIdentity : this.$route.params.guid,
                guidContract : this.$route.params.guid,
                action : this.$route.params.act
            })
            .then(res => {
                if(res.data.act=='LR' || res.data.act=='CONTR'){
                    
                    res.data.user.password = 'fammipassare';
                    this.$store.dispatch('login', res.data.user );
                }else{
                    //console.log(res.data.user);
                    //this.$store.dispatch('confirmEmailAct', res.data.message );
                    res.data.user.password = 'nonfammipassare';
                    this.$store.dispatch('login', res.data.user );
                }
                
                //console.log(this.$route.params.guid);
                //console.log(this.$route.params.act);

                //console.log(res);
                return res;
            })
            .catch(
                error => {
                this.$store.dispatch('confirmEmailAct', error );
                //console.log(error);
                return error;
                }
            );

        }
        
        
            
        

        
        
        
    }else{
        this.message = this.getConfirmedEmail;
    }


  },
  watch : {
     
  },
  mounted(){
    this.message = this.getConfirmedEmail;
  },
  beforeUpdate(){
    
  },
  updated(){
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.count{
  font-size: 7em;
}
.btn{
  font-size: 3em;
}
hr{
  border-top: 2px solid #2c3e50;
}
</style>
