<template >



<div id="tudo">
    <!-- bottone indietro -->
    <button v-if="this.selectedCompanyId==null" type="button" class="btn btn-success" style="font-size: .8em;" @click="backToDashoboard()">{{searchevents(this.labels,'menu','backglobal')}} </button>
   <hr>
  
   
 <div v-if="this.typeOfMatching=='SMT'" class="col-sm" ><h1>Advanced {{searchevents(labels,'menu','matchmaking')}}</h1></div>
 <div v-if="this.typeOfMatching=='ADV'" class="col-sm" ><h1>Smart {{searchevents(labels,'menu','matchmaking')}}</h1></div>
 <hr>


  <div class="container" style="max-width: 98% !important;" v-if="typeOfMatchingMM()"> 
    
     <!-- scheda informativa per MM smart --> 
     <!-- ripristinare se si elimina OLD <div class="alert alert-light alert-dismissible fade show" style="margin-left: 10%; margin-right: 10%; max-height: 600px; height: 370px;" v-if="this.typeOfMatching=='ADV' "  >   -->

        <div class="alert alert-light alert-dismissible fade show" style="margin-left: 12%; margin-right: 12%; height: fill-available;" v-if="this.typeOfMatching=='ADV' "  >
               <div class="row">
                <div class="col-sm">
                  <div class="card bg-light mt-6">
                     <div class="left"> 
                       <br>
                      <h4>  <i class="fas fa-fw fa-info-circle fa-lg mr-2 align-self-center"></i></h4><h4 v-html="searchevents(this.labels,'textinfo','titlemmsmart')"></h4>
                      </div>
                       
                       <div class="card-body">
                        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        <hr>
                        <h5 style="font-weight: bold"  v-html="searchevents(this.labels,'textinfo','mmsmart')"></h5>
                        <!-- <h5><strong>OLD (ex tooltip):</strong></h5>
                        <h5>{{searchevents(labels,'dialog','mmalgoritmogobiz')}}</h5> -->
                     
                     <hr>     
                 </div> 
                </div>
              </div>
           </div>
        </div> 
  <br>

    <!-- scheda informativa per MM advanced --> 
      <!-- ripristinare se si elimina OLD  <div class="alert alert-light alert-dismissible fade show" style="margin-left: 10%; margin-right: 10%; max-height: 600px; height: 330px;" v-if="this.typeOfMatching=='SMT' "  >   -->

        <div class="alert alert-light alert-dismissible fade show" style="margin-left: 12%; margin-right: 12%; height: fill-available;" v-if="this.typeOfMatching=='SMT' "  >
         
       
               <div class="row">
                <div class="col-sm">
                  <div class="card bg-light mt-6">
                     <div class="left"> 
                       <br>
                      <h4>  <i class="fas fa-fw fa-info-circle fa-lg mr-2 align-self-center"></i></h4><h4 v-html="searchevents(this.labels,'textinfo','titlemmadvanced')"></h4>
                      </div>
                       
                       <div class="card-body">
                        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        <hr>
                        <h5 style="font-weight: bold"  v-html="searchevents(this.labels,'textinfo','mmadvanced')"></h5>
                                                
                       <!--  <h5><strong>OLD (ex tooltip):</strong></h5>
                        <h5>{{searchevents(labels,'dialog','mmalgoritmostudiatrends')}}</h5>  -->
                        
                     <hr>     
                 </div> 
                </div>
              </div>
           </div>
        </div> 
  <!-- LOADER spinner--> 
    <div class="text-center" style="margin-left:25%;margin-right:52%;" > 
        <button v-if="this.loading" class="btn btn-success" style="max-width: 25%;border-color:#e5d237; background-color: #0c294b; position: fixed; top: 45%; z-index: 20; font-size: inherit; opacity: 1;" disabled>
      <span class="spinner-border spinner-border-sm"></span>
     <h6 style="color:#ffffff" v-html="searchevents(this.labels,'dialog','spinnerloader')"></h6>
    </button>
    </div>


        <div class="container tablelist" style="max-width: 90%; max-height: 175px;" >

                 
               <!-- dicci cosa vuoi fare -->
                <hr>  
                <br>
                <h3><i class="fas fa-mouse" aria-hidden="true"></i><strong>  {{searchevents(labels,'matchmaking','telwhatyouwant')}}</strong></h3>
                <template >        
                    <table class="table tablelist"  >
                    <thead>
                     <!--
                    <tr>
                        <td>
                            <div :class="'row blu-header'">
                                <div class="col-sm-3" >{{searchevents(this.labels,'companies','companyName')}}</div>
                                <div class="col-sm-3" >{{searchevents(this.labels,'menu','buysellersearch')}}</div>
                                <div class="col-sm-3" >{{searchevents(this.labels,'menu','atecocodetosearch')}}</div>
                                <div class="col-sm-3" >{{searchevents(this.labels,'menu','atecocodefirstleveltosearch')}}</div>
                                
                            </div>
                        </td>
                        
                    </tr>
                    -->
                    </thead>
                    <tbody>
                        <tr><td >
                    <template>
                      <div>
                        <div v-for="(row, index) in mycompanies" :key="row.id">
                                 <div  :class="{'row yellow-light': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">
                                    <div class="col-sm-2">
                                        </div>
                                    <div class="col-sm-2" >{{setMyCompanyName(row.companyName)}}</div>
                                    <div class="col-sm-4">
                                        <select class="form-control" style="margin-left:3%;text-align: center;color:#0c294b;font-size: 1.1rem;text-decoration: underline;"  v-model="buyseller" >
                                            <option value="BUY" >{{searchevents(labels,'matchmaking','searchseller')}}</option>
                                            <option value="SEL" >{{searchevents(labels,'matchmaking','searchbuy')}}</option>
                                        </select>
                                    </div>
                                    <!-- 
                                    <div class="col-sm-3">
                                        <input class="form-control" type="atecocodetosearch" id="atecocodetosearch" :value="row.atecoCode1" readonly> 
                                    </div>
                                    <div class="col-sm-3">
                                        {{getAtecoCodeDescription()}} {{setMyCompany(row.id)}}
                                    </div>
                                    -->
                                </div>
                                  </div>
                                    </div>
                        </template>
                        </td>
                        </tr>
                    </tbody>
                </table>
                        
                </template>
                <hr>
        </div>

        <!-- MM Advanced descrizione e input -->
        <!-- MAX INTEREST LIST RESULT ################################################################################### -->
        <div class="container tablelist" style="max-width: 90%;" v-if="this.typeOfMatching=='SMT'">
                      <h2><i class="far fa-keyboard" aria-hidden="true"></i><strong>  {{searchevents(this.labels,'menu','maxinterestsectors')}}</strong></h2>
                      <h5>{{searchevents(this.labels,'dialog','sectorsitismostlikely')}}</h5>
                      <template>
                          
                                  
                    <table class="table tablelist" >
                    <thead>
                    <tr>
                        <td>
                            <div :class="'row blu-header'">
                                    
                                    <div class="col-sm" >{{searchevents(this.labels,'matchmaking','maxinterestnacecode')}}</div>
                                    <div class="col-sm" >{{searchevents(this.labels,'matchmaking','maxinterestproduct')}}</div>
                                    <div class="col-sm" >{{searchevents(this.labels,'dialog','helpcompatibility')}}</div>
                                    
                                    <div class="col-sm" ><i class="far fa-keyboard" aria-hidden="true"></i> {{searchevents(this.labels,'dialog','setyoursearchcriteria')}}</div>
                                    <!-- <div class="col-sm" >{{searchevents(this.labels,'matchmaking','searchkey2')}}</div> -->

                                    <!-- <div class="col-sm" >{{searchevents(this.labels,'matchmaking','maxinterestdetails')}}</div> -->
                                    <div class="col-sm" >{{searchevents(this.labels,'menu','matchmakingsearch')}} </div>
                                </div>
                        </td>
                        
                    </tr>
                    </thead>
                    <tbody>
                        <tr><td >
                        <!-- <template v-for="row0 in MMComanies" > -->

                            <!-- WARNING -->
                <template>
                      <div>

                        <!-- alternativa per vue 3   <template v-for="(row, index) in MMComanies"  data-toggle="collapse" :href="'#collapseMM'+index" class="list-group-item" :key="`${row.id}-${index}`"> -->
                        <!-- Ok ma collapse non voluto <div v-for="(row, index) in MMComanies"  data-toggle="collapse" :href="'#collapseMM'+index" class="list-group-item" :key="row.id">    -->   <!--    :key="row.id" -->
                        <div v-for="(row, index) in MMComanies"  :href="'#collapseMM'+index" class="list-group-item" :key="row.id">     <!--    :key="row.id" -->   
                            <div  :class="{'row yellow-light': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">
                                    <div class="col-sm" >{{row.nace1}} </div>

                                    <div v-if="buyseller=='BUY' && lang=='Eng'" class="col-sm" >{{row.INDUSE}}</div>
                                    <div v-if="buyseller=='BUY' && lang=='Ita'" class="col-sm" >{{row.des_ita}}</div>
                                    <div v-if="buyseller=='BUY' && lang=='Den'" class="col-sm" >{{row.des_den}}</div>
                                    
                                    <div v-if="buyseller=='SEL' && lang=='Eng'" class="col-sm" >{{row.PROD_NA}}</div>
                                    <div v-if="buyseller=='SEL' && lang=='Ita'" class="col-sm" >{{row.des_ita}}</div>
                                    <div v-if="buyseller=='SEL' && lang=='Den'" class="col-sm" >{{row.des_den}}</div>

                                    <div v-if="buyseller=='SEL'" class="col-sm" >{{row.INDUSE_PERC}} %</div>
                                    <div v-if="buyseller=='BUY'" class="col-sm" >{{row.PROD_PERC}} %</div>
 
                                    <div class="col-sm">
                                       <input class="form-control" type="companyName" id="companyName" v-model="arraynaceCodeToSearcKey1[index]" >
                                    </div>
                                    <!--
                                    <div class="col-sm">
                                       zzzzzzzzzzzzzzzz <input class="form-control" type="companyName" id="companyName" v-model="arraynaceCodeToSearcKey2[index]" >
                                    </div>
                                    -->
                                    
                                    <!-- 
                                    <div class="col-sm" >
                                        
                                        <button type="button" class="btn btn-success" style="font-size: .8em;" ><a data-toggle="collapse" :href="'#collapseMM'+index" class="list-group-item">dettagli</a></button>
                                    </div>
                                    -->

                                    <div class="col-sm">
                                        <button type="button" class="btn btn-bigdark" style="font-size: .9em;"  @click="searchGlobalPre(row.nace1,index)" > <i class="far fa-play-circle fa-1x mr-2 align-self-center"></i>{{searchevents(labels,'menu','matchmakingsearch')}}</button>
                                        <!--
                                        <template v-if="!arraynaceCodeToSearcKey1[index] || !arraynaceCodeToSearcKey2[index]">
                                            {{searchevents(labels,'matchmaking','selectallserachingfield')}}
                                        </template>
                                        -->
                                    </div>
                                </div>
                                 <!-- WARNING -->
                                <!-- da verificare la rimozione di :key index per passaggio a vue3
                                <div :id="'collapseMM'+index" class="panel-collapse collapse">
                                    <div class="col-sm-12" >
                                        {{row}}
                                     
                                    </div>
                                      --> 
                                     <!-- WARNING -->
                                  
                                     <div :key="index" :id="'collapseMM'+index" class="panel-collapse collapse">
                                    <div class="col-sm-12" >
                                        {{row}}
                                        
                                    </div>
                                
                                </div>


                                </div>
                                 </div> 
                            </template>
                            
                        <!-- </template> -->
                        <!-- Tot: {{MMComaniesTot}} -->
                        </td>
                        </tr>
                    </tbody>
                </table>
                              
                               
                </template>

        </div> <!-- termine container -->

        <!-- MM smart descrizione e input -->
        <!-- Code to search ################################################################################### -->
        <div class="container tablelist" style="margin-bottom: 0px; max-width: 90%; height: fit-content;" v-if="this.typeOfMatching=='ADV'">

<!-- old mm con altezza fissa
        <div class="container tablelist" style="max-width: 95%; max-height: 175px;" v-if="this.typeOfMatching=='ADV'">
-->

                <h2><i class="far fa-keyboard" aria-hidden="true"></i><strong>  {{searchevents(labels,'menu','setyoursearch')}}</strong></h2>
                <h5>{{searchevents(this.labels,'dialog','insertoneormorewords')}}</h5>
                <template>        
                    <table class="table tablelist" style="background-color:unset !important;" >
                    <thead>
                    <tr >
                        <td>
                            <div style="max-width: 99%;margin-left:0.5%" :class="'row blu-header'">
                                <div class="col-sm-4" ></div>
                                <!-- <div class="col-sm" >{{searchevents(this.labels,'menu','atecocodetosearch')}}</div> -->
                                <div class="col-sm" >{{searchevents(this.labels,'matchmaking','searchkey1')}}</div>
                                <!-- <div class="col-sm" >{{searchevents(this.labels,'matchmaking','searchkey2')}}</div> -->
                                <div class="col-sm" >{{searchevents(this.labels,'menu','atecocodetosearch')}}</div>
                                <div class="col-sm" ></div>
                            </div>
                        </td>
                        
                    </tr>
                    </thead>
                    <tbody>
                        <tr><td >
                            <div class="row" style="background-color: #f6f6e2;">
                              <!--
                                <div class="col-sm">
                                    <button type="button" class="btn-primary" data-toggle="modal" data-target="#myModal" @click="setSelectedAteco(naceCodeToSearc,null)">{{searchevents(this.labels,'menu','selectateco')}}</button>
                                </div>
                              -->
                                <div class="col-sm-4">
                                   <!--  {{naceCodeToSearc}} -->
                                </div>
                                <div class="col-sm">
                                   <input class="form-control" type="companyName" id="companyName" v-model="naceCodeToSearcKey1" >
                                </div>
                                 <div class="col-sm">
                                    <button type="button" class="btn btn-bigdark" style="font-size: .9em;"  @click="searchGlobal()" ><i class="far fa-play-circle fa-1x mr-2 align-self-center"></i> {{searchevents(labels,'menu','matchmakingsearch')}}</button>
                                    <!--
                                    <button type="button" class="btn btn-success" style="font-size: .8em;" v-if="naceCodeToSearc!=null && naceCodeToSearcKey1!=null && naceCodeToSearcKey2!=null && naceCodeToSearc!='' && naceCodeToSearcKey1!='' && naceCodeToSearcKey2!=''" @click="searchGlobal()" >{{searchevents(labels,'menu','matchmakingsearch')}}</button>
                                    
                                    <template v-if="naceCodeToSearc==null || naceCodeToSearcKey1==null || naceCodeToSearcKey2==null || naceCodeToSearc=='' || naceCodeToSearcKey1=='' || naceCodeToSearcKey2==''">
                                        {{searchevents(this.labels,'matchmaking','selectallserachingfield')}}
                                    </template>
                                    -->
                                </div>

                                <div class="col-sm">
                                   <!--  {{naceCodeToSearcText}}  -->
                                </div>
                                <!-- 
                                <div class="col-sm">
                                   wwwwwwww <input class="form-control" type="companyName" id="companyName" v-model="naceCodeToSearcKey2" >
                                </div>
                                -->
                             
                            </div>

                                       

                            <!-- fine MM SMART -->
                        

                               
                        </td></tr>
                    </tbody>
                </table>
                        
                </template>

        
        </div> <!-- termine container -->
        
        <hr>

        <!-- risultati Mm e descrizione -->
        <!-- GOBIZ ################################################################################### -->
        <div class="container tablelist" id="mm" style="max-width: 90%;">
                <h2><i class="fas fa-poll-h" aria-hidden="true"></i><strong>  {{searchevents(this.labels,'matchmaking','gobiz')}}</strong></h2>
                <h5 style="margin-left:10%;margin-right:10%" v-html="searchevents(this.labels,'dialog','notyetregisteredgobiz')"></h5>
                
                 <div v-if="this.typeOfMatching=='SMT' && this.advMMSuggest!='' " >
                 <br>
                 <hr>
                 <div class="row d-flex justify-content-center" style="margin-left: 22%; margin-right: 22%;background-color: white; font-size: 1.3rem;" v-html="searchevents(this.labels,'dialog',this.advMMSuggest)" >
                </div>
                 <hr>
                </div>
                 <!-- filtro paese su MM  -->
                <div class="row " style="margin-top: 25px; background-color: white;" >
                    <div class="col-sm">
                        <h4>{{searchevents(this.labels,'matchmaking','othersearchparameters')}}</h4>
                    </div>
                </div>

                  
             

               <div class="row d-flex justify-content-center" style="background-color: white" >
                  
                                    <select style="min-width: 360px;width:30%;max-width:750px;text-align: center;" class="form-control " v-model="countrycontrolsearch"  >
                                            <option value="">{{searchevents(this.labels,'matchmaking','filterallcountries')}}</option>
                                            <option value="ITALY">{{searchevents(this.labels,'matchmaking','id1italy')}}</option>
                                            <option value="UNITED KINGDOM">{{searchevents(this.labels,'matchmaking','id2uk')}}</option>
                                            <option value="Denmark">{{searchevents(this.labels,'matchmaking','id504dk')}}</option>
                                    </select>
                
                </div>

                <br>
               <div class="row d-flex justify-content-center" style="background-color: white" >
                                    <!-- <input class="form-control" style="width: 160px;" type="regioncontrolsearch" id="regioncontrolsearch" v-model="regioncontrolsearch"> -->
                                    <input  v-if="this.countrycontrolsearch!='ITALY' && this.countrycontrolsearch!='UNITED KINGDOM' && this.countrycontrolsearch!='Denmark' && this.countrycontrolsearch!=''" class="form-control" style="width: 160px;"  type="regioncontrolsearch" id="regioncontrolsearch" v-model="regioncontrolsearch" > 
                                
                                    <select v-if="this.countrycontrolsearch=='ITALY'" class="form-control" type="regioncontrolsearch" id="regioncontrolsearch" style="min-width: 360px;width:30%;max-width:750px;text-align: center;" v-model="regioncontrolsearch" >   
                                        <option value="" >{{searchevents(this.labels,'matchmaking','searchregion')}}</option>
                                        <option value="Abruzzo">Abruzzo</option>
                                        <option value="Basilicata">Basilicata</option>
                                        <option value="Calabria">Calabria</option>
                                        <option value="Campania">Campania</option>
                                        <option value="Emilia-Romagna">Emilia-Romagna</option>
                                        <option value="Friuli-Venezia Giulia">Friuli-Venezia Giulia</option>
                                        <option value="Lazio">Lazio</option>
                                        <option value="Liguria">Liguria</option>
                                        <option value="Lombardia">Lombardia</option>
                                        <option value="Marche">Marche</option>
                                        <option value="Molise">Molise</option>
                                        <option value="Piemonte">Piemonte</option>
                                        <option value="Puglia">Puglia</option>
                                        <option value="Sardegna">Sardegna</option>
                                        <option value="Sicilia">Sicilia</option>
                                        <option value="Toscana">Toscana</option>
                                        <option value="Trentino-Alto Adige/Südtirol">Trentino-Alto Adige/Südtirol</option>
                                        <option value="Umbria">Umbria</option>
                                        <option value="Valle d'Aosta/Vallée d'Aoste-Valle d'Aosta/Vallée d'Aoste">Valle d'Aosta/Vallée d'Aoste-Valle d'Aosta/Vallée d'Aoste</option>
                                        <option value="Veneto">Veneto</option>
                                    </select>
                                        <select v-if="this.countrycontrolsearch=='UNITED KINGDOM'" class="form-control" type="regioncontrolsearch" id="regioncontrolsearch" style="min-width: 360px;width:30%;max-width:750px;text-align: center;" v-model="regioncontrolsearch" >   
                                        <option value="">{{searchevents(this.labels,'matchmaking','searchregion')}}</option>
                                        <option value="UNITED KINGDOM">United Kingdom</option>
                                        <option value="ENGLAND">England</option>
                                        <option value="SCOTLAND">Scotland</option>
                                        <option value="WALES">Wales</option>
                                        <option value="NORTHERN IRELAND">Northern Ireland</option>
                                    </select>
                </div>       
                           
                <br>
                
                <template>        
                    <table class="table tablelist" >
                    <thead>
                    <tr>
                        <td>
                            <div :class="'row blu-header'">
                                    <div class="col-sm" style="cursor:pointer;" @click="chageFilterArray('db')" >{{searchevents(labels,'matchmaking','companyDB')}} <i v-if="this.filterDatabase==true" class="fas fa-arrow-down fa-lg mr-2 align-self-center"></i></div>
                                    <div class="col-sm" style="cursor:pointer;" @click="chageFilterArray('a1')">{{searchevents(labels,'companies','companyName')}} <i v-if="this.filterRagSoc==true" class="fas fa-arrow-down fa-lg mr-2 align-self-center"></i></div>
                                    <div class="col-sm" style="cursor:pointer;" @click="chageFilterArray('a2')">{{searchevents(labels,'companies','countryOfOrigin')}} <i v-if="this.filterPeaseReg==true" class="fas fa-arrow-down fa-lg mr-2 align-self-center"></i></div>
                                    <div class="col-sm" style="cursor:pointer;" v-if="lang=='Ita'" @click="chageFilterArray('a3')">{{searchevents(labels,'matchmaking','ateco1')}} <i v-if="this.filterSettorea3==true" class="fas fa-arrow-down fa-lg mr-2 align-self-center"></i></div>
                                    <div class="col-sm" style="cursor:pointer;" v-if="lang=='Eng'" @click="chageFilterArray('a31')">{{searchevents(labels,'matchmaking','ateco1')}} <i v-if="this.filterSettorea31==true" class="fas fa-arrow-down fa-lg mr-2 align-self-center"></i></div>
                                    <div class="col-sm" style="cursor:pointer;" v-if="lang=='Den'" @click="chageFilterArray('a32')">{{searchevents(labels,'matchmaking','ateco1')}} <i v-if="this.filterSettorea32==true" class="fas fa-arrow-down fa-lg mr-2 align-self-center"></i></div>
                                    <!-- <div class="col-sm" >{{searchevents(labels,'matchmaking','mainactivity')}}</div> -->
                                    <div class="col-sm" >{{searchevents(labels,'matchmaking','companydetails')}}</div>
                                    <div class="col-sm" >{{searchevents(labels,'matchmaking','missingwords')}}</div>
                                    <div class="col-sm" >{{searchevents(labels,'matchmaking','matchingscore')}} <i v-if="this.filterDatabase==false && this.filterRagSoc==false && this.filterPeaseReg==false && this.filterSettorea3==false && this.filterSettorea31==false && this.filterSettorea32==false && goBizList.length>0" class="fas fa-arrow-down fa-lg mr-2 align-self-center"></i></div>
                                    <div class="col-sm" >{{searchevents(labels,'menu','addtomynetwork')}}</div>
                                    <!-- <div class="col-sm" >{{searchevents(labels,'matchmaking','contact')}}</div> -->
                                </div>
                        </td>
                        
                    </tr>
                    </thead>
                    <tbody>
                        <tr><td >
                        <!-- risultati del MATCHMAKING -->
                        <!-- <template v-for="row0 in MMComanies" > -->
                             <!-- WARNING -->
                              <!--era  :key="row.id" dentro il div-->
                    <template>
                      <div>
                        <div v-for="(row, index) in orderedBy(goBizList) " class="list-group-item" :key="row.id">                                                                                                                 
                                  <div  :class="{'row yellow-light': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">
                                    <div class="col-sm" >{{returnDB(row.db)}}</div>
                                    <div class="col-sm" >{{row.a1}}</div>
                                    <div class="col-sm" >{{row.a2}}</div>
                                    <div class="col-sm" v-if="lang=='Ita'" >{{row.a3}}</div>
                                    <div class="col-sm" v-if="lang=='Eng'" >{{row.a31}}</div>
                                    <div class="col-sm" v-if="lang=='Den'" >{{row.a32}}</div>
                                
                                    
                                    <!-- deleted 29/12/2020 <div class="col-sm" >{{row.a4}}</div> -->
                                    
                                    <div class="col-sm">
                                       <!-- <a data-toggle="collapse" :href="'#gobizMM'+index" class="list-group-item">{{searchevents(labels,'matchmaking','companydetails')}}</a> -->
                                        <button v-if="row.db=='companies'" type="button" class="btn btn-primary"  style="font-size: 0.8em;" @click="setSelectedCompanyIdDetail(row.id,row.db,row.score,'Y','Y' )">{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                        
                                        <button v-if="row.db=='company_infocamere' && ( (row.Email!='' && row.MM==1) || (row.Email=='' && row.MM==1 && row.MM_mail==1) )" type="button" class="btn btn-primary" style="font-size: 0.8em;" @click="setSelectedCompanyIdDetail(row.id,row.db,row.score,'Y','Y' )">{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                        <button v-if="row.db=='company_infocamere' && row.Email=='' && (row.MM!=1 || row.MM_mail!=1) " type="button" class="btn btn-primary" style="font-size: 0.8em;" @click="setSelectedCompanyIdDetail(row.id,row.db,row.score,'Y','N' )">{{searchevents(labels,'matchmaking','companydetails')}}</button>

                                        <button v-if="row.db=='company_house' && ( (row.Email!='' && row.MM==1) || (row.Email=='' && row.MM==1 && row.MM_mail==1) )" type="button" class="btn btn-primary" style="font-size: 0.8em;" @click="setSelectedCompanyIdDetail(row.id,row.db,row.score,'Y','Y' )">{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                        <button v-if="row.db=='company_house' && row.Email=='' && (row.MM!=1 || row.MM_mail!=1) " type="button" class="btn btn-primary" style="font-size: 0.8em;" @click="setSelectedCompanyIdDetail(row.id,row.db,row.score,'Y','N' )">{{searchevents(labels,'matchmaking','companydetails')}}</button>

                                        <button v-if="row.db=='company_denmark' && ( (row.obligatoriskEmail!='' && row.MM==1) || (row.obligatoriskEmail=='' && row.MM==1 && row.MM_mail==1) )" type="button" class="btn btn-primary" style="font-size: 0.8em;" @click="setSelectedCompanyIdDetail(row.id,row.db,row.score,'Y','Y' )">{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                        <button v-if="row.db=='company_denmark' && row.obligatoriskEmail=='' && (row.MM!=1 || row.MM_mail!=1) " type="button" class="btn btn-primary" style="font-size: 0.8em;" @click="setSelectedCompanyIdDetail(row.id,row.db,row.score,'Y','N' )">{{searchevents(labels,'matchmaking','companydetails')}}</button>


                                    </div>
                                    <div class="col-sm" >{{row.words}}</div>
                                    <div class="col-sm" >{{row.score}} </div>

                                    <div class="col-sm" >
                                        <button type="button" class="btn btn-success" style="font-size: .8em;" @click="addToMyNetwork(row.id,row.db,row.score,'ADD','','N')" >{{searchevents(labels,'menu','addtomynetwork')}}</button>
                                    </div>
                                    
                                    
                                    <!--
                                    <div class="col-sm" v-if="row.db=='companies'" >
                                        <button type="button" class="btn btn-success" style="font-size: .8em;" @click="addToMyNetwork(row.id,row.db,row.score,'CONTACT',row.emailToContact,'N')" >{{searchevents(labels,'matchmaking','contact')}}</button>
                                    </div>
                                    

                                    <div class="col-sm" v-if="row.db=='company_infocamere' && row.Email!=''   ">
                                        <button type="button" class="btn btn-success" style="font-size: .8em;" @click="addToMyNetwork(row.id,row.db,row.score,'INVITE',row.email,'N')" >{{searchevents(labels,'matchmaking','invite')}}</button>
                                    </div>
                                    <div class="col-sm" v-if="row.db=='company_infocamere' && row.Email==''  ">
                                        {{searchevents(labels,'matchmaking','notcontactable')}}
                                    </div>

                                    <div class="col-sm" v-if="row.db=='company_house' ">
                                        <button type="button" class="btn btn-success" style="font-size: .8em;" @click="addToMyNetwork(row.id,row.db,row.score,'INVITE','','N')" >{{searchevents(labels,'matchmaking','invite')}}</button>
                                    </div>

                                    <div class="col-sm" v-if="row.db=='company_denmark' && row.obligatoriskEmail!=''">
                                        <button type="button" class="btn btn-success" style="font-size: .8em;" @click="addToMyNetwork(row.id,row.db,row.score,'INVITE','','N')" >{{searchevents(labels,'matchmaking','invite')}}</button>
                                    </div>
                                     <div class="col-sm" v-if="row.db=='company_denmark' && row.obligatoriskEmail==''">
                                        {{searchevents(labels,'matchmaking','notcontactable')}}
                                    </div>
                                    -->

                                </div>
                                 <!-- WARNING -->
                                 <!-- da verificare la rimozione di :key index per passaggio a vue3
                                <div  class="panel-collapse collapse">
                                    <div class="col-sm-12" >
                                        {{row}}
                                        <button type="button" class="btn btn-primary"  style="font-size: 0.8em;" data-toggle="modal" data-target="#myBusinessCard" >{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                    </div>
                                </div>
                                -->
                                <!-- WARNING -->
                                    
                                    <div :id="'gobizMM'+index" :key="row.companyName" class="panel-collapse collapse">
                                    <div class="col-sm-12" >
                                        {{row}}
                                        <button type="button" class="btn btn-primary"  style="font-size: 0.8em;" data-toggle="modal" data-target="#myBusinessCard" >{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                    </div>
                                </div>
                                

                                    </div>
                                </div>  
                            </template>
                            
                        </td>
                        </tr>
                    </tbody>
                </table>
                
                </template>

        </div> <!-- termine container -->


        <div class="modal" id="myModal">
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">{{selectedAteco}}</h4>
              <button type="button" class="close" data-dismiss="modal" @click="setSelectedAteco('','')">&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              
              <div>
                <span>{{searchevents(this.labels,'menu','selectatecocode')}}</span> <input type="text" v-model="atecoSearchNew">
              </div>

              <select size="10" style="width: 100%;" v-model="selectedAtecoList" v-if="lang=='Ita'">
                                   <!--era  :key="row.id" dentro il div
                                    <template v-for="item in filteredItems"  >
                                <option :key="item.ATTIVIT_CON_PUNTO_3LIVELLI" :value="{ ATTIVIT_CON_PUNTO_3LIVELLI: item.ATTIVIT_CON_PUNTO_3LIVELLI, Descrizione: item.Descrizione }" >{{item.ATTIVIT_CON_PUNTO_3LIVELLI}} - {{item.Descrizione}}</option>
                                   -->
                    <template>
                    <div>
                        <div v-for="item in filteredItems"  :key="item.ATTIVIT_CON_PUNTO_3LIVELLI">                                                                                                                 
                       <option :value="{ ATTIVIT_CON_PUNTO_3LIVELLI: item.ATTIVIT_CON_PUNTO_3LIVELLI, Descrizione: item.Descrizione }" >{{item.ATTIVIT_CON_PUNTO_3LIVELLI}} - {{item.Descrizione}}</option>
                       </div>
                    </div>
                  </template>

              </select>
              <select size="10" style="width: 100%;" v-model="selectedAtecoList" v-if="lang=='Eng'">
                 <template>
                    <div>
                        <div v-for="item in filteredItems" :key="item.ATTIVIT_CON_PUNTO_3LIVELLI" >
                  <option  :value="{ ATTIVIT_CON_PUNTO_3LIVELLI: item.ATTIVIT_CON_PUNTO_3LIVELLI, Descrizione: item.Descrizione_eng }">{{item.ATTIVIT_CON_PUNTO_3LIVELLI}} - {{item.Descrizione_eng}}</option>
                   </div>
                    </div>
                </template>
              </select>
              
            </div>
            
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal" @click="setNaceToSearch(selectedAtecoList.ATTIVIT_CON_PUNTO_3LIVELLI,selectedAtecoList.Descrizione)">{{searchevents(this.labels,'menu','selecttoconfirm')}}</button>
              
            </div>
            
          </div>
        </div>
      </div>

      

    </div>  <!-- chiusura my companies -->


 <!-- <button type="button" @click="sendEmail('','','action','emailAddressTo','newMessage')">Invia email</button> -->

</div> <!-- chiusura tudo -->
</template>


<script>

import router from '@/router';
import gAxios from 'axios';
import _ from 'lodash'; 

export default {
  name: 'Login',
  data () {
    return {
      mycompanies : [],
      MMComanies : [],
      MMComaniesTot : null,
      atecocodetosearch : null,
      atecodescriptiontosearch : null,
      atecodescriptiontosearchIta : null,
      atecodescriptiontosearchEng : null,

      naceCodeToSearc : null,
      naceCodeToSearcText : null,
      naceCodeToSearcKey1 : null,
      naceCodeToSearcKey2 : null,

      arraynaceCodeToSearc : [],
      arraynaceCodeToSearcText : [],
      arraynaceCodeToSearcKey1 : [],
      arraynaceCodeToSearcKey2 : [],

      buyseller : 'BUY',
      atecoCodeSearchDef : null,
      atecoCodeSearchDescriptionDef : null,
      selectedAteco : null,
      atecoSearchNew : null,
      atecoList : [],
      selectedAtecoList : null,
      goBizList : [],
      companyHouseList : [],
      infocamereList : [],

      typeOfMatching : null,
      selectedGuid : null,
      selectedCompanyId : null,
      selectedCompanyName : null,
      selectedCompanyEmailFrom : null,

      mycompaniesDetails : [],
      mycompaniesDetailsDocs : [
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''}],
      mycompaniesDetailsCountries: [
      {"cnam1":''}],
      companyTypeSelected : null,
      remoteServer : process.env.VUE_APP_CONN_NODEJS,
      selectedCompanyTable : null,
      newmessage : null,
      MyCompanyId : null,
      MyCountryOfOrigin : null,
      globalScore : '0',
      myCompanyName : '',
      loading : false,
      
      filterDatabase : false,
      filterRagSoc : false,
      filterPeaseReg : false,
      filterSettorea3 : false,
      filterSettorea31 : false,
      filterSettorea32 : false,
      filterAll : ['score'],
      countrycontrolsearch : '',
      regioncontrolsearch : '',
      advMMSuggest : '',
      ReturnGobiz : null,
      ReturnInfocamere : null,
      ReturnCompanyHouse : null,
      ReturnDenmark : null,
    }
  },
  computed : {
    auth(){
      return this.$store.getters.isAuth;
    },
    lang(){
      return this.$store.getters.lang;
    },
    labels(){
      return this.$store.getters.labels;
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
    getUserId(){
        return this.$store.getters.getUserId;
    },
    getCompanyIdTo(){
        return this.$store.getters.getCompamyIdTo;
    },
    userIdentity(){
      return this.$store.getters.getUserNameSurname;
    },
    filteredItems() {

      if(!this.atecoSearchNew || this.atecoSearchNew==''){ return ''; }
      
       const search = this.atecoSearchNew.toLowerCase().trim();

      if (!search) return this.atecoList;
      
      // return item.name.startsWith(input);
      let aa = null;
      let bb = null;

      if(this.lang=='Ita'){
        aa = this.atecoList.filter(c => 
         c.ATTIVIT_CON_PUNTO_3LIVELLI.toLowerCase().indexOf(search) > -1 
        );

        bb = this.atecoList.filter(c => 
        c.Descrizione.toLowerCase().indexOf(search) > -1 
        );
      }
      if(this.lang=='Eng'){
        aa = this.atecoList.filter(c => 
         c.ATTIVIT_CON_PUNTO_3LIVELLI.toLowerCase().indexOf(search) > -1 
        );

        bb = this.atecoList.filter(c => 
        c.Descrizione_eng.toLowerCase().indexOf(search) > -1 
        );
      }
      

      if(aa.length>0){ return aa; }else{ return bb; }
      
      
    },
    getregisteredCompany(){
      var rcomp = this.$store.getters.getregisteredCompany;
      if(rcomp){
        
        return rcomp;
      }else{ 
        return 0;
      }
    },
  },
  methods : {
    checkReturnsForReports(key,searchType){
        //alert(''+this.ReturnGobiz+'|'+ this.ReturnInfocamere+'|'+this.ReturnCompanyHouse+'|'+this.ReturnDenmark+'<<');
        //console.log(''+this.ReturnGobiz+'|'+ this.ReturnInfocamere+'|'+this.ReturnCompanyHouse+'|'+this.ReturnDenmark+'<<')
        if(this.ReturnGobiz!=-1 && this.ReturnInfocamere!=-1 && this.ReturnCompanyHouse!=-1 && this.ReturnDenmark!=-1 ){
            // faccio qualcosa
            if(this.ReturnGobiz==0 && this.ReturnInfocamere==0 && this.ReturnCompanyHouse==0 && this.ReturnDenmark==0){
                // si tratta di una ricerca nulla
                //console.log('RICERCA TERMINATA CON NESSUN RISULTATO' + key+'--'+searchType);
                //alert('RICERCA TERMINATA CON NESSUN RISULTATO' + key+'--'+searchType);
                const auth = {
                    headers: {Authorization:'JWT ' + this.getToken} 
                }

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reportUpdate',
                    {
                    reportType : 21,
                    key : key,
                    searchType : searchType
                    
                    },auth)
                    .then(res => {
                        // ok ha scritto
                        //alert('ok');
                        return res;
                    
                    })
                    .catch(
                    error => {
                    this.logout();
                    
                    return error;
                    })

            }else{
                //console.log('terminato')
                //alert('terminato');
               
                //console.log(''+this.ReturnGobiz+'|'+ this.ReturnInfocamere+'|'+this.ReturnCompanyHouse+'|'+this.ReturnDenmark+'<<')
            }
        }



    },
    countrycontrolsearchChange(){
        this.regioncontrolsearch = '';
        //console.log('cambio!!!');
    },
    // TAG Google Analytics rilevamento pagina
//    track () {
//      this.$gtag.query('event', 'screen_view', {
//      app_name: 'matchmaking',
//      screen_name: 'matchmaking',
 //    }),
 //     this.$gtag.pageview("/matchmaking")
 //   }, 
    chageFilterArray(arr){

        if(arr=='db'){
            if(this.filterDatabase==true){this.filterDatabase=false; }else{this.filterDatabase=true; }
        }
        if(arr=='a1'){
            if(this.filterRagSoc==true){this.filterRagSoc=false;}else{this.filterRagSoc=true;}
        }
        if(arr=='a2'){
            if(this.filterPeaseReg==true){this.filterPeaseReg=false;}else{this.filterPeaseReg=true;}
        }

        if(arr=='a3'){
            if(this.filterSettorea3==true){this.filterSettorea3=false;}else{this.filterSettorea3=true;}
        }
        if(arr=='a31'){
            if(this.filterSettorea31==true){this.filterSettorea31=false;}else{this.filterSettorea31=true;}
        }
        if(arr=='a32'){
            if(this.filterSettorea32==true){this.filterSettorea32=false;}else{this.filterSettorea32=true;}
        }

        //console.log('changed'+ arr);

    },
    backToDashoboard(){
        router.push('/dashboard');
    },
    setMyCompanyName(name){
        this.myCompanyName = name;
    },
    sendRequestVisura(companyIdfromCard, guid, companyTable){
        this.newmessage = 'Request for an Updated Version of the Company Report';
        
        this.sendNewMessageBusinessCard(companyIdfromCard,guid, companyTable);
    },
    sendNewMessageBusinessCard(companyIdfromCard,guid, companyTable){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {
                let firstMessage = 'N';
                if(guid=='' || guid==null){ 
                  firstMessage = 'Y'; 
                  guid = this.generateUUID();
                }
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    companyIdFrom : this.MyCompanyId,
                    companyIdTo : companyIdfromCard,
                    companyTable : companyTable,
                    guid : guid,
                    firstMessage: firstMessage,
                    blockchainsHash : '',
                    message : this.newmessage,
                    messageTranslated : '',
                    chatId : '',
                    viewed: '0',
                    actionToDo : 'ADD'
                },auth)
                .then(res => {
                    this.sendEmail(companyIdfromCard,companyTable,'SEND','',this.newmessage,'Y');
                    //this.setSelectedCompanyIdDetail(companyIdfromCard,companyTable,'0');
                    this.newmessage = null;
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
                
            }else{
                return null;
            }
    },
    addToMyNetwork(companyId,companyTable,score,action,emailAddressTo, messageYN){

        
            const auth = {
            headers: {Authorization:'JWT ' + this.getToken} 
            }

            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/isInMyNetwork',
                {
                   companyId : companyId,
                   MyCompanyId: this.MyCompanyId,
                   table : companyTable
                },auth)
                .then(res => {
                    
                    
                    let response = res.data.message;
                    //console.log(response);
                    //alert(response);
                    if(response!='OK'){
                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/NetworkJoinByMe',
                        {
                            userId : this.getUserId,
                            companyId : companyId,
                            companyTable : companyTable,
                            score : score,
                            myCompany : this.MyCompanyId,
                            actionToDo : action
                        },auth)
                        .then(res => {
                            // in base alla action passata, eseguo delle operazioni: aggiungo alla chat
                            
                            this.sendEmail(companyId,companyTable,'ADD','','Qualcuno ti ha aggiunto al proprio network / Someone added you to their network','N');

                            if(action=='CONTACT'){
                                this.setSelectedCompanyId(companyId,companyTable,'-1',null,null);
                            }else{
                                if(this.lang=='Ita'){ alert('Operazione completata con successo'); }
                                if(this.lang=='Eng'){ alert('Successful operation!'); }
                                if(this.lang=='Den'){ alert('Succesfuld operation'); }
                            }

                            //if(messageYN=='Y'){
                                
                            //}

                            return res+emailAddressTo+messageYN;
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );
                    }else{
                        if(this.lang=='Ita'){ alert('Impresa già presente nel tuo network'); }
                        if(this.lang=='Eng'){ alert('This Company is already in your Network'); }
                        if(this.lang=='Den'){ alert('Denne virksomhed er allerede i dit Netværk'); }
                    }
                    
                })
                .catch(
                error => {
                //console.log(error);
                return error;
                }
                );
            
            
        
        
    },
    businessCardAction(action, companyId, guid, companyTable, score){

      if(action=='addtomyfavorite'){
        // in ogni caso aggiungo ai preferiti, il server controlla se è già stata aggiunta
        this.addToMyNetwork(companyId,companyTable,score,'ADD','','Y');
      }

      if(action=='sendmessage'){
        // in ogni caso aggiungo ai preferiti, il server controlla se è già stata aggiunta
        this.addToMyNetwork(companyId,companyTable,score,'ADD','','N');

        // quest'operazione è possibile solo nelle pagine: area messaggi, network, match making
        router.push('/mychats');
      }

      if(action=='reqvisura'){
        // quest'operazione è possibile solo nelle pagine: area messaggi, network, match making
        this.sendRequestVisura(companyId,guid,companyTable);
      }

      //alert(action+'--'+companyId);
      return action+guid;
    },
    doNothing(){
      return null;
    },
    returnYear(dt){
      if(dt){
       return dt.substring(0,4);
      }else{
        return '';
      }
    },
    setSelectedCompanyId(companyId,table,guid,id1,id2){
        
        let selectedCompanyId = companyId;
        if(id1!=null && id1!=''){ selectedCompanyId = id1; }
        if(id2!=null && id2!=''){ selectedCompanyId = id2; }
        if(guid==null){guid = '-1';}
        
        // esco e vado alla chat di questa azienda
        router.push( { name: 'mychats', params: { idCompany: selectedCompanyId, table: table, guid: guid, id1: id1, id2:id2 } } );
    },
    isInMyNetwork(companyId,table){
        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/isInMyNetwork',
                {
                   companyId : companyId,
                   MyCompanyId: this.MyCompanyId,
                   table : table
                },auth)
                .then(res => {
                    
                    
                    let response = res.data.message;
                    
                    if(response=='OK'){
                        return 'N';
                    }else{
                        return 'Y';
                    }
                    
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },
    canAddToNetwork(companyId,table){

        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/isInMyNetwork',
                {
                   companyId : companyId,
                   MyCompanyId: this.MyCompanyId,
                   table : table
                },auth)
                .then(res => {
                    
                    
                    let response = res.data.message;
                    //console.log(response);
                    //alert(response);
                    if(response=='OK'){
                        //console.log('quind?');
                        return 'OK';
                    }else{
                        return 'KO';
                    }
                    
                })
                .catch(
                error => {
                //console.log(error);
                return error;
                }
                );

    },
    setSelectedCompanyIdDetail(companyId,table,score,addNetwork,sendMessage){
        
        
        this.$store.dispatch('setCompanyIdBusinessCard',companyId );
        this.$store.dispatch('setCompanyTableBusinessCard',table );
        this.$store.dispatch('setCompanyScoreBusinessCard',score );

        let canSendMessage = sendMessage;
        
        //this.$store.dispatch('setCompanyMessageBusinessCard',sendMessage );
       
        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/isInMyNetwork',
                {
                   companyId : companyId,
                   MyCompanyId: this.MyCompanyId,
                   table : table
                },auth)
                .then(res => {
                    
                    
                    let response = res.data.message;
                    //console.log(response+'kokokokoko');
                    //alert(response);
                    if(response=='OK'){
                        
                        this.$store.dispatch('setCompanyNetworkBusinessCard','N' );
                    }else{
                        
                        this.$store.dispatch('setCompanyNetworkBusinessCard','Y' );
                    }
                    
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
        
        // banned or not 
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/bannedornot',
                {
                    companyIdFrom : this.MyCompanyId,
                    companyIdTo : companyId,
                    companyTable : table,
                   
                },auth)
                .then(res => {
                  //console.log(res);
                   if(res.data.message=='OK'){
                       canSendMessage = 'Y';
                     this.$store.dispatch('setCompanyMessageBusinessCard',canSendMessage );
                   }else{
                       canSendMessage = 'N';
                     this.$store.dispatch('setCompanyMessageBusinessCard',canSendMessage );
                   }
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
        
        /*
        this.selectedCompanyTable = table;

        if(score!='0'){ this.globalScore = score; }
        
        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                {
                   companyId : companyId,
                   action : table,
                   myuserId : this.getUserId
                },auth)
                .then(res => {
                    
                    this.mycompaniesDetails = res.data.company[0];

                    if(table=='companies'){
                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_doc'
                        },auth)
                        .then(res1 => {
                            
                            // docs here
                           
                            if(res1.data.company.length>0)
                            {
                                this.mycompaniesDetailsDocs = res1.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );

                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_countries'
                        },auth)
                        .then(res2 => {
                            
                            // docs here
                            
                            
                            if(res2.data.company.length>0)
                            {
                                this.mycompaniesDetailsCountries = res2.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );

                    }
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
        
            */

    },
    /*
    setSelectedCompanyIdDetail(companyId,table){
        this.selectedCompanyTable = table;
        
        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                {
                   companyId : companyId,
                   action : table
                },auth)
                .then(res => {
                    
                    this.mycompaniesDetails = res.data.company[0];

                    if(table=='companies'){
                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_doc'
                        },auth)
                        .then(res1 => {
                            
                            // docs here
                            
                            if(res1.data.company.length>0)
                            {
                                this.mycompaniesDetailsDocs = res1.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );
                    }
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
        


    },
    */
    renderEmail(id,db,score,action){

            return '' + id+db+score+action;
        
    },
    setMyCompany(companyId,companyName, emailAdressFrom){
        this.selectedCompanyId = companyId;
        this.selectedCompanyName = companyName;
        this.selectedCompanyEmailFrom = emailAdressFrom;
    },
    typeOfMatchingMM(){
        
        if(this.typeOfMatching != this.$route.params.mm){ 
            this.goBizList = []; 
            this.naceCodeToSearc = null;
            this.naceCodeToSearcText = null;
            this.naceCodeToSearcKey1 = null;
            this.naceCodeToSearcKey2 = null;
        }
        this.typeOfMatching = this.$route.params.mm;
        //this.goBizList = [];
        return true;
    },
    searchGlobalPre(atecocode, index){

        if(this.arraynaceCodeToSearcKey1[index]==null || this.arraynaceCodeToSearcKey1[index].length<4 || this.arraynaceCodeToSearcKey1[index]=='' || this.arraynaceCodeToSearcKey1[index]==null){
          let alertMessage = '';
          if(this.lang=='Ita'){ alertMessage = this.searchevents(this.labels,'dialog','insertonesearchcriteria'); }
          if(this.lang=='Eng'){ alertMessage = this.searchevents(this.labels,'dialog','insertonesearchcriteria'); }
          if(this.lang=='Den'){ alertMessage = this.searchevents(this.labels,'dialog','insertonesearchcriteria'); }

          alert(alertMessage);
          return false;
        }

        this.naceCodeToSearc = atecocode;
        this.naceCodeToSearcKey1 = this.arraynaceCodeToSearcKey1[index];
        this.naceCodeToSearcKey2 = this.arraynaceCodeToSearcKey2[index];
        this.searchGlobalAdv();
    },
    returnDB(db){
        let dbText = '';
        if(db == 'company_infocamere'){dbText = this.searchevents(this.labels,'companies','companyinfocamere');  }
        if(db == 'company_house'){ dbText = this.searchevents(this.labels,'companies','companycompanyhouse');  }
        if(db == 'companies'){ dbText = 'GoBiz'; }
        if(db == 'company_denmark'){ dbText = this.searchevents(this.labels,'companies','companycvr'); }
        
        return dbText;
    },
    generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        
        return newGuid;
    },
    sendEmail(companyIdTo, companyTable, action,emailAddressTo,newMessage,printMessage){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

        if(companyTable == 'company_infocamere')
        {
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/sendemail',
                {
                    userId : userId,
                    emailAddressTo : emailAddressTo,
                    emailAddressFrom : this.selectedCompanyEmailFrom,
                    companyFrom : this.selectedCompanyName,
                    message : newMessage,
                    companyTable : companyTable,
                    action: action
                },auth)
                .then(res => {
                    
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );

            if(printMessage=='Y'){
                    if(this.lang=='Ita'){ alert('Operazione completata con successo!'); }
                    if(this.lang=='Eng'){ alert('Operation completed succesfully!'); }
                    if(this.lang=='Den'){ alert('Operation afsluttet med succes!'); } 
                }
        }
        

        if(companyTable=='companies'){
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyAll',
                        {
                            id : companyIdTo,
                            table : companyTable,
                            what : ''
                        },auth)
                        .then(res => {
                            
                            let emailTo = '';
                            res.data.network.forEach(em =>{
                                if(emailTo==''){ emailTo = em.email; }else{
                                    emailTo = emailTo + ',' + em.email;
                                }
                            });
                            
                            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/sendemail',
                            {
                                userId : userId,
                                emailAddressTo : emailTo,
                                emailAddressFrom : this.selectedCompanyEmailFrom,
                                companyFrom : this.selectedCompanyName,
                                message : newMessage,
                                action: action
                            },auth)
                            .then(res => {
                                
                                return res;
                            })
                            .catch(
                                error => {
                                
                                return error;
                                }
                            );
                        })
                        .catch(
                            error => {
                            
                            return error;
                            }
                        );

                if(printMessage=='Y'){
                    if(this.lang=='Ita'){ alert('Operazione completata con successo!'); }
                    if(this.lang=='Eng'){ alert('Operation completed succesfully!'); }
                    if(this.lang=='Den'){ alert('Operation afsluttet med succes!'); } 
                }
                this.newmessage = null;
        }

    },
    sendNewMessage(companyIdTo,companyTable,action,emailAddressTo){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

        let newMessage = '';
        if(action=='CONTACT'){ newMessage = 'messaggio di contatto';}
        if(action=='INVITE'){ newMessage = 'messaggio di invito';}
        if(action=='ADD'){ newMessage = 'Qualcuno ti ha aggiunto al proprio network / Someone added you to their network';}
        let guid = this.generateUUID();
        this.selectedGuid = guid;
        let firstMessage = 'Y';   
                
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    companyIdFrom : this.selectedCompanyId,
                    companyIdTo : companyIdTo,
                    companyTable : companyTable,
                    guid : this.selectedGuid,
                    firstMessage: firstMessage,
                    blockchainsHash : '',
                    message : newMessage,
                    messageTranslated : '',
                    chatId : '',
                    viewed: '0',
                    actionToDo : 'ADD'
                },auth)
                .then(res => {
                    this.sendEmail(companyIdTo,companyTable,action,emailAddressTo,newMessage,'N');
                    
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
                this.newmessage = null;
        
            
    },
    /*
    addToMyNetwork(companyId,companyTable,score,action,emailAddressTo){
        const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
        }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/NetworkJoinByMe',
        {
            userId : this.getUserId,
            companyId : companyId,
            companyTable : companyTable,
            score : score,
            actionToDo : action
        },auth)
        .then(res => {
            
            this.sendNewMessage(companyId,companyTable,action,emailAddressTo);

            this.searchGlobal();
            return res;
        })
        .catch(
        error => {
        
        return error;
        }
        );
    },
    */
    getAtecoList(){
      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }
    
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/atecolist/atecocode',
      {
          myCode : "",
          CodeToSearch : "",
          actionSearch : "match_conv_sic3",
          single : "N"
      },auth)
      .then(res => {
          
          let matrix = res.data.matrix;
          this.atecoList = matrix;
          
      })
      .catch(
      error => {
      
      return error;
      }
      );
    },
    setSelectedAteco(ateco,num){
      this.selectedAteco = num;
      this.atecoSearchNew = ateco;
      
    },
    setNaceToSearch(naceCode, naceText){
        this.naceCodeToSearc = naceCode;
        this.naceCodeToSearcText = naceText;
        
    },
    getAtecoCodeDescription(){
        if(this.lang=='Ita'){
            return this.atecodescriptiontosearchIta;
        }else{
            return this.atecodescriptiontosearchEng;
        }
        
    },
    searchAtecoDescription(atecoCode){
       
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            // ####################################################################################
            if(atecoCode!=null)
            {
                let atecoCodeConverted = '';
                atecoCodeConverted = atecoCode.charAt(0) + atecoCode.charAt(1);
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/atecolist/atecocode',
                {
                   actionSearch : 'match_eurostat_to_nace',
                   CodeToSearch : atecoCodeConverted
                },auth)
                .then(res => {
                    
                    this.atecodescriptiontosearch = res.data.matrix[0].des_eng;
                    this.atecodescriptiontosearchEng = res.data.matrix[0].des_eng;
                    this.atecodescriptiontosearchIta = res.data.matrix[0].des_ita;
                    this.matrixMatch();
                })
                .catch(
                error => {
                
                return error;
                }
                );
            }else{
                return null;
            }

    },
    orderByScore(arr) {
      // Set slice() to avoid to generate an infinite loop!
      return arr.slice().sort(function(a, b) {
        return b.score - a.score;
      })
    },
    insertFilterIfNotPresent(wht){
        let trovato = false;
        let contatore = 0;
        
        this.filterAll.forEach(mtx =>{
            //console.log(mtx+'--'+wht);
            if(mtx==wht){ trovato = true; }
            contatore = contatore+1;
        });

        // dopo tutto
        if(trovato==false){
            this.filterAll.push(wht);
        }
    },
    deleteFilterIfPresent(wht){
        let trovato = false;
        let contatore = 0;
        let trovatoDove = -1;
        
        this.filterAll.forEach(mtx =>{
            //console.log(mtx+'--'+wht);
            if(mtx==wht){ trovato = true; trovatoDove = contatore; }
            contatore = contatore+1;
        });

        // dopo tutto
        if(trovato==true){
            this.filterAll.splice(trovatoDove,1 );
        }
    },
    filterIsPresent(wht){
        let risposta = 'false';

        this.filterAll.forEach(mtx =>{
            if(mtx==wht){ risposta = 'true'; }
        });
        //console.log(risposta+':risposta');
        return risposta;
    },
    boBizListOrderedByFilters: function () {
        //console.log(this.countrycontrolsearch);
        return this.goBizList;
        /*
        return _.orderBy(this.goBizList.filter(comp => {
            return comp.a2.toLowerCase().includes(this.countrycontrolsearch.toLowerCase());
        }));
        */

    },
    ritornamiArray(orderDirection){
        let mynewgobizlist = [];
        //console.log(this.countrycontrolsearch + ':this.countrycontrolsearch');
        //console.log(this.regioncontrolsearch + ':this.regioncontrolsearch');
        //console.log(this.goBizList );
        
        mynewgobizlist = _.orderBy(this.goBizList.filter(comp => { 
            if(comp.a2!=null && comp.b7!=null){
                return comp.a2.toLowerCase().includes(this.countrycontrolsearch.toLowerCase()) && comp.b7.toLowerCase().includes(this.regioncontrolsearch.toLowerCase()) ; 
            }else{
                return comp;
            }
            
            }));
        //mynewgobizlist = _.orderBy(this.goBizList.filter(comp => { return comp.b7.toLowerCase().includes(this.regioncontrolsearch.toLowerCase()); }));
        
        //console.log(mynewgobizlist + 'azaazaza');
        return _.orderBy(mynewgobizlist, this.filterAll, orderDirection);
    },
    orderedBy(){
        //console.log(this.filterAll+ ' :all');
        let filterArray = [];
        let orderDirection = 'asc';
        
        if(this.filterDatabase==true ){ filterArray.push('db'); this.insertFilterIfNotPresent('db'); }else{ this.deleteFilterIfPresent('db'); }
        if(this.filterRagSoc==true  ){ filterArray.push('a1'); this.insertFilterIfNotPresent('a1'); }else{ this.deleteFilterIfPresent('a1'); }
        if(this.filterPeaseReg==true){ filterArray.push('a2'); this.insertFilterIfNotPresent('a2'); }else{ this.deleteFilterIfPresent('a2'); }

        if(this.lang=='Ita' && this.filterSettorea3==true){ filterArray.push('a3'); this.insertFilterIfNotPresent('a3'); }else{ this.deleteFilterIfPresent('a3'); }
        if(this.lang=='Eng' && this.filterSettorea31==true){ filterArray.push('a31'); this.insertFilterIfNotPresent('a31'); }else{ this.deleteFilterIfPresent('a31'); }
        if(this.lang=='Den' && this.filterSettorea32==true){ filterArray.push('a32'); this.insertFilterIfNotPresent('a32'); }else{ this.deleteFilterIfPresent('a32'); }

        if(filterArray.length==0){ filterArray.push('score'); this.insertFilterIfNotPresent('score'); orderDirection = 'desc'; }else{ this.deleteFilterIfPresent('score'); }
        //console.log(filterArray + ':internal');
        //let myarr = this.goBizList;
        //let mynewarr = _.orderBy(myarr, filterArray, orderDirection);
        
        
        //return mynewarr;
        return this.ritornamiArray(orderDirection);
    },
    searchGlobal(){

      this.ReturnGobiz = -1;
      this.ReturnInfocamere = -1;
      this.ReturnCompanyHouse = -1;
      this.ReturnDenmark = -1;

        if(this.naceCodeToSearcKey1==null || this.naceCodeToSearcKey1.length<4 || this.naceCodeToSearcKey1=='' || this.naceCodeToSearcKey1==null){
          let alertMessage = '';
          if(this.lang=='Ita'){ alertMessage = 'Inserire almeno un criterio di ricerca (minimo 4 caratteri)'; }
          if(this.lang=='Eng'){ alertMessage = 'Enter at least one search criterion (at least 4 characters long)'; }
          if(this.lang=='Den'){ alertMessage = 'Indtast mindst ét søgekriterium (mindst 4 tegn)'; }

          alert(alertMessage);
          return false;
        }
        
        this.loading = true;

        // ATECO ricercante
        let myAteco = this.getregisteredCompany.atecoCode1;
        //console.log(myAteco.substring(0, 5));
        //console.log(myAteco);
        this.naceCodeToSearc = myAteco.substring(0, 5);

        this.goBizList = null;
        this.goBizList = [];
        
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

        // GoBizList #######################################################################àà
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
        {
            atecoCode : this.naceCodeToSearc,
            atecoCodeKey1 : this.naceCodeToSearcKey1,
            atecoCodeKey2 : this.naceCodeToSearcKey2,
            companiesIdFrom: this.getregisteredCompany.id,
            actionLevel : 'A',
            action : 'gobiz',
            userId : this.getUserId
        },auth)
        .then(res => {
            
            this.ReturnGobiz = 0;
            //this.goBizList = res.data.matrix;
            res.data.matrix.forEach(mtx =>{
                let trovata = 0;
                this.goBizList.forEach(gb =>{
                    this.ReturnGobiz = 1;
                    if(gb.id == mtx.id && mtx.db=='companies'){ 
                      trovata = 1; 
                      //console.log('---A------->'+mtx.score);
                      //gb.score = gb.score + mtx.score;
                      gb.score = mtx.score;
                      //console.log('---A1------->'+mtx.score);
                      let arraySearchString = mtx.words.split(" ");
                      let newWords = '';
                      arraySearchString.forEach(function(entry) {

                                if(gb.words.includes(entry)){
                                    newWords = newWords + entry + ' ';
                                }
                            });
                      gb.words = newWords;

                      }
                });
                if(trovata==0 && mtx.db=='companies'){
                   this.goBizList.push(mtx);
                   //console.log('---Ax------->'+mtx.score+'--'+mtx.companyName);
                }

                
                
            });

            this.checkReturnsForReports(this.naceCodeToSearcKey1,'smart');
            
            // secondo livelo @@@@@@@@@@@@@
            /*
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
            {
                atecoCode : this.naceCodeToSearc,
                atecoCodeKey1 : this.naceCodeToSearcKey1,
                atecoCodeKey2 : this.naceCodeToSearcKey2,
                actionLevel : 'B',
                action : 'gobiz',
                userId : this.getUserId
            },auth)
            .then(res => {
                
                //this.goBizList = res.data.matrix;
                res.data.matrix.forEach(mtx1 =>{
                    let trovata = 0;
                    this.goBizList.forEach(gb =>{
                        if(gb.id == mtx1.id && mtx1.db=='companies'){ 
                            trovata = 1; 
                            //console.log('---B------->'+mtx1.score);
                            gb.score = gb.score + mtx1.score;
                            //console.log('---B1------->'+gb.score);
                            let arraySearchString = mtx1.words.split(" ");
                            let newWords = '';
                            arraySearchString.forEach(function(entry) {

                                      if(gb.words.includes(entry)){
                                          newWords = newWords + entry + ' ';
                                      }
                                  });
                            gb.words = newWords;

                      }
                    });
                    if(trovata==0 && mtx1.db=='companies'){
                    this.goBizList.push(mtx1);
                    //console.log('---Bx------->'+mtx1.score+'--'+mtx1.companyName);
                    }
                    
                });
                
                // terzo livelo @@@@@@@@@@@@@
                /*
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
                {
                    atecoCode : this.naceCodeToSearc,
                    atecoCodeKey1 : this.naceCodeToSearcKey1,
                    atecoCodeKey2 : this.naceCodeToSearcKey2,
                    actionLevel : 'C',
                    action : 'gobiz',
                    userId : this.getUserId
                },auth)
                .then(res => {
                    
                    //this.goBizList = res.data.matrix;
                    res.data.matrix.forEach(mtx2 =>{
                        let trovata = 0;
                        this.goBizList.forEach(gb =>{
                            if(gb.id == mtx2.id){ trovata = 1; }
                        });
                        if(trovata==0){
                        this.goBizList.push(mtx2);
                        }
                        
                    });
                    
                }).catch(
                error => {
                
                return error;
                }
                ); // chiusura terzo livello
                */
            /*
            }).catch(
            error => {
            this.loading = false;
            return error;
            }
            ); // chiusura secondo livello
            */


        })
        .catch(
        error => {
        this.loading = false;
        return error;
        }
        ); // chiusura primo livello


        // CompanyHouse #######################################################################àà
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
        {
            atecoCode : this.naceCodeToSearc,
            atecoCodeKey1 : this.naceCodeToSearcKey1,
            atecoCodeKey2 : this.naceCodeToSearcKey2,
            companiesIdFrom: this.getregisteredCompany.id,
            actionLevel : 'A',
            action : 'companyhouse',
            userId : this.getUserId
        },auth)
        .then(res => {

            this.ReturnCompanyHouse = 0;

            res.data.matrix.forEach(Hmtx =>{
                let trovata = 0;
                 
                this.goBizList.forEach(gb =>{
                    this.ReturnCompanyHouse = 1;
                    if(gb.id == Hmtx.id && Hmtx.db=='company_house'){ 
                      trovata = 1; 
                      //console.log('---01------->');
                      //gb.score = gb.score + Hmtx.score;
                      gb.score = Hmtx.score;
                      let arraySearchString = Hmtx.words.split(" ");
                      let newWords = '';
                      arraySearchString.forEach(function(entry) {

                                if(gb.words.includes(entry)){
                                    newWords = newWords + entry + ' ';
                                }
                            });
                      gb.words = newWords;

                      }
                });
                if(trovata==0 && Hmtx.db=='company_house'){
                   this.goBizList.push(Hmtx);
                }

                
                
            });

            this.checkReturnsForReports(this.naceCodeToSearcKey1,'smart');
            
            // secondo livelo @@@@@@@@@@@@@
            /*
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
            {
                atecoCode : this.naceCodeToSearc,
                atecoCodeKey1 : this.naceCodeToSearcKey1,
                atecoCodeKey2 : this.naceCodeToSearcKey2,
                actionLevel : 'B',
                action : 'companyhouse',
                userId : this.getUserId
            },auth)
            .then(res => {
                
                //this.goBizList = res.data.matrix;
                res.data.matrix.forEach(Hmtx1 =>{
                    let trovata = 0;

                    this.goBizList.forEach(gb =>{
                        if(gb.id == Hmtx1.id && Hmtx1.db=='company_house'){ 
                          trovata = 1; 
                          //console.log('---02------->');
                          gb.score = gb.score + Hmtx1.score;
                          let arraySearchString = Hmtx1.words.split(" ");
                          let newWords = '';
                          
                          arraySearchString.forEach(function(entry) {

                                    if(gb.words.includes(entry)){
                                        newWords = newWords + entry + ' ';
                                    }
                                });
                          gb.words = newWords;

                          }
                    });
                    if(trovata==0 && Hmtx1.db=='company_house'){
                    this.goBizList.push(Hmtx1);
                    }
                    
                });
                
                // terzo livelo @@@@@@@@@@@@@
                /*
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
                {
                    atecoCode : this.naceCodeToSearc,
                    atecoCodeKey1 : this.naceCodeToSearcKey1,
                    atecoCodeKey2 : this.naceCodeToSearcKey2,
                    actionLevel : 'C',
                    action : 'companyhouse',
                    userId : this.getUserId
                },auth)
                .then(res => {
                    
                    //this.goBizList = res.data.matrix;
                    res.data.matrix.forEach(Hmtx2 =>{
                        let trovata = 0;
                        this.goBizList.forEach(gb =>{
                            if(gb.id == Hmtx2.id){ trovata = 1; }
                        });
                        if(trovata==0){
                        this.goBizList.push(Hmtx2);
                        }
                        
                    });
                    
                }).catch(
                error => {
                
                return error;
                }
                ); // chiusura terzo livello
                */
            /*
            }).catch(
            error => {
            this.loading = false;
            return error;
            }
            ); // chiusura secondo livello
            */


        })
        .catch(
        error => {
        //console.log(error);
        this.loading = false;
        return error;
        }
        ); // chiusura primo livello


        // InfoCamere #######################################################################àà
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
        {
            atecoCode : this.naceCodeToSearc,
            atecoCodeKey1 : this.naceCodeToSearcKey1,
            atecoCodeKey2 : this.naceCodeToSearcKey2,
            companiesIdFrom: this.getregisteredCompany.id,
            actionLevel : 'A',
            action : 'infocamere',
            userId : this.getUserId
        },auth)
        .then(res => {
            
            this.ReturnInfocamere = 0;

            //this.goBizList = res.data.matrix;
            res.data.matrix.forEach(Imtx =>{
                let trovata = 0;
                this.goBizList.forEach(gb =>{
                    this.ReturnInfocamere = 1;
                    if(gb.id == Imtx.id && Imtx.db=='company_infocamere'){ 
                      trovata = 1; 
                      //console.log('---03------->');
                      //gb.score = gb.score + Imtx.score;
                      gb.score = Imtx.score;
                      let arraySearchString = Imtx.words.split(" ");
                      let newWords = '';
                      arraySearchString.forEach(function(entry) {

                                if(gb.words.includes(entry)){
                                    newWords = newWords + entry + ' ';
                                }
                            });
                      gb.words = newWords;

                      }
                });
                if(trovata==0 && Imtx.db=='company_infocamere'){
                   this.goBizList.push(Imtx);
                }

                
                
            });
            
            this.checkReturnsForReports(this.naceCodeToSearcKey1,'smart');

            // secondo livelo @@@@@@@@@@@@@
            /*
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
            {
                atecoCode : this.naceCodeToSearc,
                atecoCodeKey1 : this.naceCodeToSearcKey1,
                atecoCodeKey2 : this.naceCodeToSearcKey2,
                actionLevel : 'B',
                action : 'infocamere',
                userId : this.getUserId
            },auth)
            .then(res => {
                
                //this.goBizList = res.data.matrix;
                res.data.matrix.forEach(Imtx1 =>{
                    let trovata = 0;
                    this.goBizList.forEach(gb =>{
                        if(gb.id == Imtx1.id && Imtx1.db=='company_infocamere'){ 
                          trovata = 1; 
                          //console.log('---04------->');
                          gb.score = gb.score + Imtx1.score;
                          let arraySearchString = Imtx1.words.split(" ");
                          let newWords = '';
                          arraySearchString.forEach(function(entry) {

                                    if(gb.words.includes(entry)){
                                        newWords = newWords + entry + ' ';
                                    }
                                });
                          gb.words = newWords;

                          }
                    });
                    if(trovata==0 && Imtx1.db=='company_infocamere'){
                    this.goBizList.push(Imtx1);
                    }
                    
                });
                
                // terzo livelo @@@@@@@@@@@@@
                /*
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
                {
                    atecoCode : this.naceCodeToSearc,
                    atecoCodeKey1 : this.naceCodeToSearcKey1,
                    atecoCodeKey2 : this.naceCodeToSearcKey2,
                    actionLevel : 'C',
                    action : 'infocamere',
                    userId : this.getUserId
                },auth)
                .then(res => {
                    
                    //this.goBizList = res.data.matrix;
                    res.data.matrix.forEach(Imtx2 =>{
                        let trovata = 0;
                        this.goBizList.forEach(gb =>{
                            if(gb.id == Imtx2.id){ trovata = 1; }
                        });
                        if(trovata==0){
                        this.goBizList.push(Imtx2);
                        }
                        
                    });
                    
                }).catch(
                error => {
                
                return error;
                }
                ); // chiusura terzo livello
                */
            /*
            }).catch(
            error => {
            this.loading = false;
            return error;
            }
            ); // chiusura secondo livello
            */


        })
        .catch(
        error => {
        this.loading = false;
        return error;
        }
        ); // chiusura primo livello


        // Denmark #######################################################################àà
        //console.log('quiiiiii' + this.naceCodeToSearc);
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
        {
            atecoCode : this.naceCodeToSearc,
            atecoCodeKey1 : this.naceCodeToSearcKey1,
            atecoCodeKey2 : this.naceCodeToSearcKey2,
            companiesIdFrom: this.getregisteredCompany.id,
            actionLevel : 'A',
            action : 'denmark',
            userId : this.getUserId
        },auth)
        .then(res => {
            
            this.ReturnDenmark = 0;

            //this.goBizList = res.data.matrix;
            res.data.matrix.forEach(Imtx =>{
                let trovata = 0;
                this.goBizList.forEach(gb =>{
                    this.ReturnDenmark = 1;
                    if(gb.id == Imtx.id && Imtx.db=='company_denmark'){ 
                      trovata = 1; 
                      //console.log('---05------->');
                      //console.log(gb);
                      //console.log(Imtx);
                      //gb.score = gb.score + Imtx.score;
                      gb.score = Imtx.score;
                          let arraySearchString = Imtx.words.split(" ");
                          let newWords = '';
                          arraySearchString.forEach(function(entry) {

                                    if(gb.words.includes(entry)){
                                        newWords = newWords + entry + ' ';
                                    }
                                });
                          gb.words = newWords;

                      }
                });
                if(trovata==0 && Imtx.db=='company_denmark'){
                   this.goBizList.push(Imtx);
                }

                
                
            });

            this.checkReturnsForReports(this.naceCodeToSearcKey1,'smart');
            
            // secondo livelo @@@@@@@@@@@@@
            /*
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
            {
                atecoCode : this.naceCodeToSearc,
                atecoCodeKey1 : this.naceCodeToSearcKey1,
                atecoCodeKey2 : this.naceCodeToSearcKey2,
                actionLevel : 'B',
                action : 'denmark',
                userId : this.getUserId
            },auth)
            .then(res => {
                
                //this.goBizList = res.data.matrix;
                res.data.matrix.forEach(Imtx1 =>{
                    let trovata = 0;
                    this.goBizList.forEach(gb =>{
                        if(gb.id == Imtx1.id && Imtx1.db=='company_denmark'){ 
                            trovata = 1; 
                            //console.log('---06------->');
                            gb.score = gb.score + Imtx1.score;
                            let arraySearchString = Imtx1.words.split(" ");
                            let newWords = '';
                            arraySearchString.forEach(function(entry) {

                                      if(gb.words.includes(entry)){
                                          newWords = newWords + entry + ' ';
                                      }
                                  });
                            gb.words = newWords;

                            }
                    });
                    if(trovata==0 && Imtx1.db=='company_denmark'){
                    this.goBizList.push(Imtx1);
                    }
                    
                });
                
                // terzo livelo @@@@@@@@@@@@@
                /*
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
                {
                    atecoCode : this.naceCodeToSearc,
                    atecoCodeKey1 : this.naceCodeToSearcKey1,
                    atecoCodeKey2 : this.naceCodeToSearcKey2,
                    actionLevel : 'C',
                    action : 'infocamere',
                    userId : this.getUserId
                },auth)
                .then(res => {
                    
                    //this.goBizList = res.data.matrix;
                    res.data.matrix.forEach(Imtx2 =>{
                        let trovata = 0;
                        this.goBizList.forEach(gb =>{
                            if(gb.id == Imtx2.id){ trovata = 1; }
                        });
                        if(trovata==0){
                        this.goBizList.push(Imtx2);
                        }
                        
                    });
                    
                }).catch(
                error => {
                
                return error;
                }
                ); // chiusura terzo livello
                */
            /*
            }).catch(
            error => {
            this.loading = false;
            return error;
            }
            ); // chiusura secondo livello
            */


        })
        .catch(
        error => {
        this.loading = false;
        return error;
        }
        ); // chiusura primo livello

        // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ò
        //this.loading = false;
        setTimeout(() => {
            this.loading = false;
          }, 35000); // 3 sec delay  --> cambiato a 20 sec

    },
    searchGlobalAdv(){

        this.advMMSuggest = '';
        this.loading = true;
        

        this.goBizList = null;
        this.goBizList = [];

        this.ReturnGobiz = -1;
        this.ReturnInfocamere = -1;
        this.ReturnCompanyHouse = -1;
        this.ReturnDenmark = -1;
        
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }


        // prima chiamata per la comparison

        //console.log(this.naceCodeToSearc);
        //console.log(this.buyseller);
        //console.log(this.getregisteredCompany.countryOfOrigin);
        let operationTable = 'export_table';
        let operationColumn = '';
        //let comparisonResponse1 = '';
        //let comparisonResponse2 = '';

        if(this.buyseller=='SEL'){
            if(this.getregisteredCompany.countryOfOrigin==1){ operationColumn = 'IT_BUY'; }
            if(this.getregisteredCompany.countryOfOrigin==2){ operationColumn = 'UK_BUY'; }
            if(this.getregisteredCompany.countryOfOrigin==504){ operationColumn = 'DK_BUY'; }
        }
        if(this.buyseller=='BUY'){
            if(this.getregisteredCompany.countryOfOrigin==1){ operationColumn = 'IT_SELL'; }
            if(this.getregisteredCompany.countryOfOrigin==2){ operationColumn = 'UK_SELL'; }
            if(this.getregisteredCompany.countryOfOrigin==504){ operationColumn = 'DK_SELL'; }
        }

        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmcomparison',
        {
            atecoCode : this.naceCodeToSearc,
            operationTable : operationTable,
            operationColumn : operationColumn
        },auth)
        .then(resp => {
            //console.log('>>>>>>>>>>>>>>>>>>>' + resp.data.response);
            //alert('NEW >>>>>>>>>>>>>>>>>>>' + resp.data.response);
            
            operationTable = 'trade_map_table';

            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmcomparison',
            {
                atecoCode : this.naceCodeToSearc,
                operationTable : operationTable,
                operationColumn : operationColumn
            },auth)
            .then(resp1 => {
                //console.log('export_table:'+resp.data.response + ' ----trade_map_table:' + resp1.data.response );
                //alert('export_table:'+resp.data.response + ' ----trade_map_table:' + resp1.data.response);

                if(resp.data.response==resp1.data.response && resp.data.response!='ND' && resp1.data.response!='ND'){ 
                   if(resp.data.response=='IT'){
                       this.advMMSuggest = 'avdmmsameIT';  
                   }
                   if(resp.data.response=='UK'){
                       this.advMMSuggest = 'avdmmsameUK';  
                   }
                   if(resp.data.response=='DK'){
                       this.advMMSuggest = 'avdmmsameDK';  
                   }
                   
                }

                if(resp.data.response!=resp1.data.response && (resp.data.response=='ND' || resp1.data.response=='ND') ){ 
                    
                    if(resp.data.response!='ND' ){
                        if(resp.data.response=='IT'){
                       this.advMMSuggest = 'avdmmnotsameIT';  
                        }
                        if(resp.data.response=='UK'){
                            this.advMMSuggest = 'avdmmnotsameUK';  
                        }
                        if(resp.data.response=='DK'){
                            this.advMMSuggest = 'avdmmnotsameDK';  
                        }
                    }

                    if(resp1.data.response!='ND'){
                        if(resp1.data.response=='IT'){
                       this.advMMSuggest = 'avdmmnotsameIT';  
                        }
                        if(resp1.data.response=='UK'){
                            this.advMMSuggest = 'avdmmnotsameUK';  
                        }
                        if(resp1.data.response=='DK'){
                            this.advMMSuggest = 'avdmmnotsameDK';  
                        }
                    }
                    
                }

                if(resp.data.response!=resp1.data.response && resp.data.response!='ND' && resp1.data.response!='ND'){ 
                    
                    if(resp.data.response=='IT' && resp1.data.response=='UK' ){
                       this.advMMSuggest = 'avdmmdifferentUKIT';  
                    }
                    if(resp.data.response=='IT' && resp1.data.response=='DK' ){
                       this.advMMSuggest = 'avdmmdifferentDKIT';  
                    }
                    if(resp.data.response=='UK' && resp1.data.response=='IT' ){
                       this.advMMSuggest = 'avdmmdifferentITUK';  
                    }
                    if(resp.data.response=='UK' && resp1.data.response=='DK' ){
                       this.advMMSuggest = 'avdmmdifferentDKUK';  
                    }
                    if(resp.data.response=='DK' && resp1.data.response=='IT' ){
                       this.advMMSuggest = 'avdmmdifferentITDK';  
                    }
                    if(resp.data.response=='DK' && resp1.data.response=='UK' ){
                       this.advMMSuggest = 'avdmmdifferentUKDK';  
                    }

                    
                }

                if(resp.data.response=='ND' && resp1.data.response=='ND'){ 
                    this.advMMSuggest = 'avdmmonend';  
                }
                //console.log( this.advMMSuggest);
            })
            .catch(
            error => {
                return error;
            }
            );

        })
        .catch(
        error => {
            return error;
        }
        );


        // GoBizList #######################################################################àà
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
        {
            atecoCode : this.naceCodeToSearc,
            atecoCodeKey1 : this.naceCodeToSearcKey1,
            atecoCodeKey2 : this.naceCodeToSearcKey2,
            companiesIdFrom: this.getregisteredCompany.id,
            actionLevel : 'A',
            action : 'gobizadv',
            userId : this.getUserId
        },auth)
        .then(res => {
            
            this.ReturnGobiz = 0;

            //this.goBizList = res.data.matrix;
            res.data.matrix.forEach(mtx =>{
                 this.ReturnGobiz = 1;
                let trovata = 0;
                this.goBizList.forEach(gb =>{
                    if(gb.id == mtx.id && mtx.db=='companies'){ 
                          trovata = 1; 
                          
                          //gb.score = gb.score + mtx.score;
                          gb.score = mtx.score;
                          let arraySearchString = mtx.words.split(" ");
                          let newWords = '';
                          arraySearchString.forEach(function(entry) {

                                    if(gb.words.includes(entry)){
                                        newWords = newWords + entry + ' ';
                                    }
                                });
                          gb.words = newWords;

                          }
                });
                if(trovata==0 && mtx.db=='companies'){
                   this.goBizList.push(mtx);
                }
                
            });

            this.checkReturnsForReports(this.naceCodeToSearcKey1,'ADV');
            
            // secondo livelo @@@@@@@@@@@@@
            /*
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
            {
                atecoCode : this.naceCodeToSearc,
                atecoCodeKey1 : this.naceCodeToSearcKey1,
                atecoCodeKey2 : this.naceCodeToSearcKey2,
                actionLevel : 'B',
                action : 'gobizadv',
                userId : this.getUserId
            },auth)
            .then(res => {
                
                //this.goBizList = res.data.matrix;
                res.data.matrix.forEach(mtx1 =>{
                    let trovata = 0;
                    this.goBizList.forEach(gb =>{
                        if(gb.id == mtx1.id && mtx1.db=='companies'){ 
                          trovata = 1; 
                          
                          gb.score = gb.score + mtx1.score;
                          let arraySearchString = mtx1.words.split(" ");
                          let newWords = '';
                          arraySearchString.forEach(function(entry) {

                                    if(gb.words.includes(entry)){
                                        newWords = newWords + entry + ' ';
                                    }
                                });
                          gb.words = newWords;

                          }
                    });
                    if(trovata==0 && mtx1.db=='companies'){
                    this.goBizList.push(mtx1);
                    }
                    
                });
                
                // terzo livelo @@@@@@@@@@@@@
                /*
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
                {
                    atecoCode : this.naceCodeToSearc,
                    atecoCodeKey1 : this.naceCodeToSearcKey1,
                    atecoCodeKey2 : this.naceCodeToSearcKey2,
                    actionLevel : 'C',
                    action : 'gobiz',
                    userId : this.getUserId
                },auth)
                .then(res => {
                    
                    //this.goBizList = res.data.matrix;
                    res.data.matrix.forEach(mtx2 =>{
                        let trovata = 0;
                        this.goBizList.forEach(gb =>{
                            if(gb.id == mtx2.id){ trovata = 1; }
                        });
                        if(trovata==0){
                        this.goBizList.push(mtx2);
                        }
                        
                    });
                    
                }).catch(
                error => {
                
                return error;
                }
                ); // chiusura terzo livello
                */
            /*
            }).catch(
            error => {
            this.loading = false;
            return error;
            }
            ); // chiusura secondo livello
            */


        })
        .catch(
        error => {
        this.loading = false;
        return error;
        }
        ); // chiusura primo livello


        // CompanyHouse #######################################################################àà
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
        {
            atecoCode : this.naceCodeToSearc,
            atecoCodeKey1 : this.naceCodeToSearcKey1,
            atecoCodeKey2 : this.naceCodeToSearcKey2,
            companiesIdFrom: this.getregisteredCompany.id,
            actionLevel : 'A',
            action : 'companyhouseadv',
            userId : this.getUserId
        },auth)
        .then(res => {
            
            this.ReturnCompanyHouse = 0;

            res.data.matrix.forEach(Hmtx =>{

                this.ReturnCompanyHouse = 1;
                let trovata = 0;
                this.goBizList.forEach(gb =>{
                    if(gb.id == Hmtx.id && Hmtx.db=='company_house'){ 
                          trovata = 1; 
                          
                          //gb.score = gb.score + Hmtx.score;
                          gb.score = Hmtx.score;
                          let arraySearchString = Hmtx.words.split(" ");
                          let newWords = '';
                          arraySearchString.forEach(function(entry) {

                                    if(gb.words.includes(entry)){
                                        newWords = newWords + entry + ' ';
                                    }
                                });
                          gb.words = newWords;

                          }
                });
                if(trovata==0 && Hmtx.db=='company_house'){
                   this.goBizList.push(Hmtx);
                }
                
            });
            
            this.checkReturnsForReports(this.naceCodeToSearcKey1,'ADV');

            // secondo livelo @@@@@@@@@@@@@
            /*
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
            {
                atecoCode : this.naceCodeToSearc,
                atecoCodeKey1 : this.naceCodeToSearcKey1,
                atecoCodeKey2 : this.naceCodeToSearcKey2,
                actionLevel : 'B',
                action : 'companyhouseadv',
                userId : this.getUserId
            },auth)
            .then(res => {
                
                //this.goBizList = res.data.matrix;
                res.data.matrix.forEach(Hmtx1 =>{
                    let trovata = 0;
                    this.goBizList.forEach(gb =>{
                        if(gb.id == Hmtx1.id && Hmtx1.db=='company_house'){ 
                          trovata = 1; 
                          
                          gb.score = gb.score + Hmtx1.score;
                          let arraySearchString = Hmtx1.words.split(" ");
                          let newWords = '';
                          arraySearchString.forEach(function(entry) {

                                    if(gb.words.includes(entry)){
                                        newWords = newWords + entry + ' ';
                                    }
                                });
                          gb.words = newWords;

                          }
                    });
                    if(trovata==0 && Hmtx1.db=='company_house'){
                    this.goBizList.push(Hmtx1);
                    }
                    
                });
                
                // terzo livelo @@@@@@@@@@@@@
                /*
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
                {
                    atecoCode : this.naceCodeToSearc,
                    atecoCodeKey1 : this.naceCodeToSearcKey1,
                    atecoCodeKey2 : this.naceCodeToSearcKey2,
                    actionLevel : 'C',
                    action : 'companyhouse',
                    userId : this.getUserId
                },auth)
                .then(res => {
                    
                    //this.goBizList = res.data.matrix;
                    res.data.matrix.forEach(Hmtx2 =>{
                        let trovata = 0;
                        this.goBizList.forEach(gb =>{
                            if(gb.id == Hmtx2.id){ trovata = 1; }
                        });
                        if(trovata==0){
                        this.goBizList.push(Hmtx2);
                        }
                        
                    });
                    
                }).catch(
                error => {
                
                return error;
                }
                ); // chiusura terzo livello
                */
            /*
            }).catch(
            error => {
            this.loading = false;
            return error;
            }
            ); // chiusura secondo livello
            */


        })
        .catch(
        error => {
        //console.log(error);
        this.loading = false;
        return error;
        }
        ); // chiusura primo livello


        // InfoCamere #######################################################################àà
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
        {
            atecoCode : this.naceCodeToSearc,
            atecoCodeKey1 : this.naceCodeToSearcKey1,
            atecoCodeKey2 : this.naceCodeToSearcKey2,
            companiesIdFrom: this.getregisteredCompany.id,
            actionLevel : 'A',
            action : 'infocamereadv',
            userId : this.getUserId
        },auth)
        .then(res => {
            
            this.ReturnInfocamere = 0;

            //this.goBizList = res.data.matrix;
            res.data.matrix.forEach(Imtx =>{

                this.ReturnInfocamere = 1;
                let trovata = 0;
                this.goBizList.forEach(gb =>{
                    if(gb.id == Imtx.id && Imtx.db=='company_infocamere'){ 
                          trovata = 1; 
                          
                          //gb.score = gb.score + Imtx.score;
                          gb.score = Imtx.score;
                          let arraySearchString = Imtx.words.split(" ");
                          let newWords = '';
                          arraySearchString.forEach(function(entry) {

                                    if(gb.words.includes(entry)){
                                        newWords = newWords + entry + ' ';
                                    }
                                });
                          gb.words = newWords;

                          }
                });
                if(trovata==0 && Imtx.db=='company_infocamere'){
                   this.goBizList.push(Imtx);
                }
                
            });

            this.checkReturnsForReports(this.naceCodeToSearcKey1,'ADV');
            
            // secondo livelo @@@@@@@@@@@@@
            /*
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
            {
                atecoCode : this.naceCodeToSearc,
                atecoCodeKey1 : this.naceCodeToSearcKey1,
                atecoCodeKey2 : this.naceCodeToSearcKey2,
                actionLevel : 'B',
                action : 'infocamereadv',
                userId : this.getUserId
            },auth)
            .then(res => {
                
                //this.goBizList = res.data.matrix;
                res.data.matrix.forEach(Imtx1 =>{
                    let trovata = 0;
                    this.goBizList.forEach(gb =>{
                        if(gb.id == Imtx1.id && Imtx1.db=='company_infocamere'){ 
                          trovata = 1; 
                          
                          gb.score = gb.score + Imtx1.score;
                          let arraySearchString = Imtx1.words.split(" ");
                          let newWords = '';
                          arraySearchString.forEach(function(entry) {

                                    if(gb.words.includes(entry)){
                                        newWords = newWords + entry + ' ';
                                    }
                                });
                          gb.words = newWords;

                          }
                    });
                    if(trovata==0 && Imtx1.db=='company_infocamere'){
                    this.goBizList.push(Imtx1);
                    }
                    
                });
                
                // terzo livelo @@@@@@@@@@@@@
                /*
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
                {
                    atecoCode : this.naceCodeToSearc,
                    atecoCodeKey1 : this.naceCodeToSearcKey1,
                    atecoCodeKey2 : this.naceCodeToSearcKey2,
                    actionLevel : 'C',
                    action : 'infocamere',
                    userId : this.getUserId
                },auth)
                .then(res => {
                    
                    //this.goBizList = res.data.matrix;
                    res.data.matrix.forEach(Imtx2 =>{
                        let trovata = 0;
                        this.goBizList.forEach(gb =>{
                            if(gb.id == Imtx2.id){ trovata = 1; }
                        });
                        if(trovata==0){
                        this.goBizList.push(Imtx2);
                        }
                        
                    });
                    
                }).catch(
                error => {
                
                return error;
                }
                ); // chiusura terzo livello
                */
            /*
            }).catch(
            error => {
            this.loading = false;
            return error;
            }
            ); // chiusura secondo livello
            */


        })
        .catch(
        error => {
        this.loading = false;
        return error;
        }
        ); // chiusura primo livello


        // Denmark #######################################################################àà
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
        {
            atecoCode : this.naceCodeToSearc,
            atecoCodeKey1 : this.naceCodeToSearcKey1,
            atecoCodeKey2 : this.naceCodeToSearcKey2,
            companiesIdFrom: this.getregisteredCompany.id,
            actionLevel : 'A',
            action : 'denmarkadv',
            userId : this.getUserId
        },auth)
        .then(res => {
            
            this.ReturnDenmark = 0;

            //this.goBizList = res.data.matrix;
            res.data.matrix.forEach(Imtx =>{

                this.ReturnDenmark = 1;
                let trovata = 0;
                this.goBizList.forEach(gb =>{
                    if(gb.id == Imtx.id && Imtx.db=='company_denmark'){ 
                          trovata = 1; 
                          
                          //gb.score = gb.score + Imtx.score;
                          gb.score = Imtx.score;
                          let arraySearchString = Imtx.words.split(" ");
                          let newWords = '';
                          arraySearchString.forEach(function(entry) {

                                    if(gb.words.includes(entry)){
                                        newWords = newWords + entry + ' ';
                                    }
                                });
                          gb.words = newWords;

                          }
                });
                if(trovata==0 && Imtx.db=='company_denmark'){
                   this.goBizList.push(Imtx);
                }
                
            });

            this.checkReturnsForReports(this.naceCodeToSearcKey1,'ADV');
            
            // secondo livelo @@@@@@@@@@@@@
            /*
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
            {
                atecoCode : this.naceCodeToSearc,
                atecoCodeKey1 : this.naceCodeToSearcKey1,
                atecoCodeKey2 : this.naceCodeToSearcKey2,
                actionLevel : 'B',
                action : 'denmarkadv',
                userId : this.getUserId
            },auth)
            .then(res => {
                
                //this.goBizList = res.data.matrix;
                res.data.matrix.forEach(Imtx1 =>{
                    let trovata = 0;
                    this.goBizList.forEach(gb =>{
                        if(gb.id == Imtx1.id && Imtx1.db=='company_denmark'){ 
                          trovata = 1; 
                          
                          gb.score = gb.score + Imtx1.score;
                          let arraySearchString = Imtx1.words.split(" ");
                          let newWords = '';
                          arraySearchString.forEach(function(entry) {

                                    if(gb.words.includes(entry)){
                                        newWords = newWords + entry + ' ';
                                    }
                                });
                          gb.words = newWords;

                          }
                    });
                    if(trovata==0 && Imtx1.db=='company_denmark'){
                    this.goBizList.push(Imtx1);
                    }
                    
                });
                
                // terzo livelo @@@@@@@@@@@@@
                /*
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmoutput',
                {
                    atecoCode : this.naceCodeToSearc,
                    atecoCodeKey1 : this.naceCodeToSearcKey1,
                    atecoCodeKey2 : this.naceCodeToSearcKey2,
                    actionLevel : 'C',
                    action : 'infocamere',
                    userId : this.getUserId
                },auth)
                .then(res => {
                    
                    //this.goBizList = res.data.matrix;
                    res.data.matrix.forEach(Imtx2 =>{
                        let trovata = 0;
                        this.goBizList.forEach(gb =>{
                            if(gb.id == Imtx2.id){ trovata = 1; }
                        });
                        if(trovata==0){
                        this.goBizList.push(Imtx2);
                        }
                        
                    });
                    
                }).catch(
                error => {
                
                return error;
                }
                ); // chiusura terzo livello
                */
            /*
            }).catch(
            error => {
            this.loading = false;
            return error;
            }
            ); // chiusura secondo livello
            */


        })
        .catch(
        error => {
        this.loading = false;
        return error;
        }
        ); // chiusura primo livello

        //this.loading = false;
        setTimeout(() => {
            this.loading = false;
          }, 35000); // 3 sec delay  - cambiato a 20 sec

    },
    matrixMatch(){
        
        this.MMComanies = null;
        this.MMComanies = [];
    
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
        

        //console.log('xxxxxxxxxxx'+this.MyCountryOfOrigin)
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmsearch',
        {
            myNaceDes : this.atecodescriptiontosearch,
            actionSearch : this.buyseller,
            tableToSearchTo : "",
            CodeToSearch : "",
            MyCountryOfOrigin : this.MyCountryOfOrigin
        },auth)
        .then(res => {
            //console.log(res);
            let contatoreM = 0;
            if(res.data.matrix.length>0){
                res.data.matrix.forEach(mtx =>{
                
                //console.log(mtx.INDUSE);
                if(mtx.INDUSE!=undefined){
                    if(contatoreM<10){
                    this.MMComanies.push(mtx);
                   
                    }
                    if(contatoreM==10){
                        this.MMComaniesTot = mtx;
                    }
                    contatoreM++;
                }
                
                
            });
            }
            
        })
        .catch(
        error => {
        //console.log(error);
        return error;
        }
        );
    },
    setMyCompanyIdTo(companyIdTo,action){
        this.$store.dispatch('setcompanyIdTo', companyIdTo );
        if(action=='NET'){
            router.push('/mynetwork');
        }

        if(action=='CONT'){
            router.push('/mycontacts');
        }
        
    },
    modifyCompany(company){
        this.$store.dispatch('SetregisteredCompany', company );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updatecompany');
        
    },
    searchevents(events,filterValue,label){
        if(events){
          const filter = event => 
            event.tableName.includes(filterValue) &&
            event.fieldName.includes(label)
        
          var filteredArray = events.filter(filter);
          if(filteredArray.length >0)
          {
            if(this.lang)
            {
              var labelName = 'label'+this.lang;
              return filteredArray[0][labelName];
            }else{
              return '--';
            }
            
          }else{
            return '--';
          }
        }else{
          return null;
        }
      }

  },
  created(){
    
  },
  mounted: function(){
    this.$nextTick(function () {

        // Code that will run only after the
        // entire view has been rendered
        
        // DEVE ESSERE STATA SELEZIONATA IN PRECEDENZA
        //this.$store.dispatch('ResetregisteredCompany' );
        var reg = this.$store.getters.getregisteredCompany;
        if(reg!=null){ 
            this.mycompanies.push(reg); 
            this.MyCompanyId = reg.id;
            this.MyCountryOfOrigin = reg.countryOfOrigin;
            
            //this.atecodescriptiontosearch = this.mycompanies[0].atecoCode1;
            //this.atecodescriptiontosearch = this.mycompanies[0].atecoCode1;
            this.atecocodetosearch = this.mycompanies[0].atecoCode1;
            this.searchAtecoDescription(this.mycompanies[0].atecoCode1);
        }
        this.matrixMatch();

        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            // ####################################################################################
            if(userId!=null && this.atecocodetosearch=='pippo loppo')
            {
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/allUsersMe',
                {
                   userId : userId
                },auth)
                .then(res => {
                    
                    
                    let users = res.data.users;
                    users.forEach(u => {
                        let company = u.companies;
                        company.forEach(comp => {
                            
                            // prima di aggiungere la company verifico che non sia già presente
                            let trovata = 0;
                            this.mycompanies.forEach(myC => {
                                if(myC.id == comp.id){ trovata++; }
                                this.atecocodetosearch = comp.atecoCode1;
                                this.searchAtecoDescription(comp.atecoCode1);
                            })
                            if(trovata==0){ this.mycompanies.push(comp); }
                        });
                    });
                    
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
            }else{
                return null;
            }

            // #########################################################################################
            if(this.atecocodetosearch=='pippo loppo')
            {
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/matchmaking/mmsearch',
                {
                   tableToSearchTo : 'companies',
                   CodeToSearch : '',
                   companyNumber : ''
                },auth)
                .then(res => {
                    
                    
                    let users = res.data.users;
                    users.forEach(u => {
                        let company = u.companies;
                        company.forEach(comp => {
                            
                            // prima di aggiungere la company verifico che non sia già presente
                            let trovata = 0;
                            this.mycompanies.forEach(myC => {
                                if(myC.id == comp.id){ trovata++; }
                            })
                            if(trovata==0){ this.mycompanies.push(comp); }
                        });
                    });
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
            }else{
                return null;
            }

            

    })
    this.getAtecoList();
    
  },
  watch : {
     countrycontrolsearch: function(){
         this.regioncontrolsearch = '';
        //console.log('cambio!!!');
     },
     buyseller: function(){
         
         this.matrixMatch();
     },
     goBizList: function(){
        
         //window.scrollTo(0,el.scrollHeight);  //or .scrollHeight

         //document.getElementById('mm').scrollIntoView();
     },
     getregisteredCompany: function(){
      this.registeredCompany = this.getregisteredCompany;
      this.id = this.getregisteredCompany.id;
      this.companyName = this.getregisteredCompany.companyName;
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.count{
  font-size: 7em;
}
.btn{
  font-size: 3em;
}

.blu-dark{
    background-color: #f0f6fc;
    min-height: 50px;
}

.blu-header{
    background-color: #0c294b;
    min-height: 50px;
    color: #ffffff;
    //font-size: 1.2em;
}

.table td, .table th, .table {
    border: 0px;
    padding: 1px;
}
.tablelist {
    margin-bottom: 60px;
    /* margin: 5px; */
    padding: 5px;
    border: #cccccc 0px solid;
}
.alert-dismissible {
padding-right: 0rem;
}
</style>
