<template>
  <div class="container">
    
    <!--
    <div class="row">
      <div class="col">
        <template v-if="poupvisibile">
          <div class="alert alert-warning" role="alert">
            {{msg}}
          </div>
        </template>
        <form @submit.prevent="onSubmit">
          <div class="form-group">
            <img alt="Vue logo" src="../../assets/gobiz-logo-rectangle-blue.png" width="250em" style="width: 160px;">
          </div>
          <div class="form-group">
            <h4>{{searchevents(this.labels,'login','header')}}</h4>
            <hr>
          </div>
          <div class="form-group">
            
            <input class="form-control logininput" type="email" id="email" v-model="email" :placeholder="searchevents(this.labels,'login','emaillogin')">
          </div>
          <div class="form-group">
            
            <input class="form-control logininput" type="password" id="password" v-model="password" :placeholder="searchevents(this.labels,'login','password')">
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-dark loginbutton">{{searchevents(this.labels,'login','accesslogin')}}</button>
          </div>
          <div class="form-group">
            {{searchevents(this.labels,'menu','rememberpassword')}}
            
            <a ><router-link v-if="!auth" :to="{ name : 'remember'}">{{searchevents(this.labels,'menu','rememberherepassword')}}</router-link></a>
          </div>
        </form>
      </div>
    </div>
    <hr style="margin-top:80px;">
    -->

    <div class="row" style="margin-bottom:20px;">
        <div class="col">
            <img alt="Vue logo" src="../../assets/gobiz-logo-rectangle-blue.png" width="250em" style="width: 160px;">
          </div>
    </div>
    <div class="row" style="margin-bottom:100px;">
        
      <div class="col">

        <template v-if="poupvisibile2">
          <div class="alert alert-warning" role="alert">
            {{msg}}
          </div>
        </template>

        <form @submit.prevent="onSubmitR">
          <h2>{{searchevents(this.labels,'login','remember')}}</h2>
          <hr>
          <div class="form-group">
            <!-- <label for="email" style="width: 100%;">{{searchevents(this.labels,'login','emaillogin')}}</label> -->
            <input class="form-control logininput" type="email" id="emailR" v-model="emailR" :placeholder="searchevents(this.labels,'login','emaillogin')" required>
          </div>
          
          <button type="submit" class="btn btn-dark loginbutton">{{searchevents(this.labels,'login','rememnberaccess')}}</button>
        </form>
      </div>
    </div>

  </div>
</template>


<script>

//import PopUpRisposta from '@/components/PopUpRisposta.vue';
import gAxios from 'axios';

export default {
  name: 'Login',
  data () {
    return {
      email : '',
      emailR : '',
      password : '',
      poupvisibile : false,
      poupvisibile2 : false,
      msg : ''
    }
  },
  components : {
    
  },
  computed : {
    lang(){
      return this.$store.getters.lang;
    },
    labels(){
      return this.$store.getters.labels;
    }
  },
  methods : {
    onSubmit(){
      const formData = {
        email : this.email,
        password : this.password
      }
      
      this.$store.dispatch('login', {email: formData.email, password: formData.password})
      
      setTimeout(this.vefifyUserLogged,1000);

    },
    onSubmitR(){
      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/rememberPassword',
                {
                   email : this.emailR
                })
                .then(res => {
                    
                    if(res.data.messages=='OK')
                    {
                      this.poupvisibile2 = true;
                      this.msg = this.searchevents(this.labels,'modify','emailsentsuccessfully');
                      
                      setTimeout(this.closeAlert,3000);
                    }else{
                      this.poupvisibile2 = true;
                      this.msg = this.searchevents(this.labels,'login','emailnotpresent') + ' ' + res.data.messages.response;
                      //console.log(res);
                      setTimeout(this.closeAlert,3000);
                    }

                    this.emailR = '';
                })
                .catch(
                error => {
                  this.poupvisibile2 = true;
                  this.msg = this.searchevents(this.labels,'login','emailnotpresent');
                      
                  setTimeout(this.closeAlert,3000);

                return error;
                }
                );
      

    },
    vefifyUserLogged(){
      this.$store.dispatch('notIsLogged').then(()=>{
        this.poupvisibile = true;
        this.msg = this.searchevents(this.labels,'login','accessdenied');
        
        
        setTimeout(this.closeAlert,3000);
      }).catch(()=>{
        this.poupvisibile = false;
        
      })
      
    },
    closeAlert(){
      this.poupvisibile = false;
      this.poupvisibile2 = false;
    },
    searchevents(events,filterValue,label){
        if(events){
          const filter = event => 
            event.tableName.includes(filterValue) &&
            event.fieldName.includes(label)
        
          var filteredArray = events.filter(filter);
          if(filteredArray.length >0)
          {
            if(this.lang)
            {
              var labelName = 'label'+this.lang;
              return filteredArray[0][labelName];
            }else{
              return '--';
            }
            
          }else{
            return '--';
          }
        }else{
          return null;
        }
      }

    }
 }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.count{
  font-size: 7em;
}
.btn{
  font-size: 3em;
}
.logininput{
    width: 70%;
    min-height: 90px;
    display: inline-block;
    background-color: #ffffff;
    border: 1px solid #0c294b; 
    color: #0c294b;
    font-size: 1.3em;
}
.loginbutton{
    width: 70%;
    min-height: 90px;
    display: inline-block;
    background-color: #0c294b;
    color: #e6d235;
}
</style>