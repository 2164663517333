<template>
  <div id="app">
    <navigator></navigator>
    <router-view/>
    <footers>llll</footers>
    </div>


</template>


<!-- social login
  <div class="container">
    <div class="social-auth">
      <a :href="getGoogleUrl(from)" class="google-auth">
        <img :src="GoogleLogo" alt="Google Logo" />
        <span>Google</span>
      </a>
    </div>
  </div>
  -->

<script>
// import GoogleLogo from './assets/google.svg';
// import { getGoogleUrl } from './helpers/getGoogleUrl';
// const from = '/';
import gAxios from 'axios';
import * as ApiService from '@/helpers/api.service'
import Navigator from '@/views/Navigator.vue'
import Footers from '@/views/Footer.vue'

import VTooltip from 'v-tooltip';
import Vue from 'vue';
//import VueMeta from 'vue-meta'

// if (location.hash) {
//  location.replace(location.hash.replace('#', ''))
// }


//Vue.use(VueMeta, {
  // optional pluginOptions
 // refreshOnceOnNavigation: true
// })

Vue.use(VTooltip);
VTooltip.options.defaultTemplate = '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner"></div></div>';
VTooltip.options.defaultArrowSelector = '.tooltip-vue-arrow, .tooltip-vue__arrow';
VTooltip.options.defaultInnerSelector = '.tooltip-vue-inner, .tooltip-vue__inner';


ApiService.init();

export default {
  name : 'app',
      metaInfo: {
    title: 'Gobiz - Be Smart Go Global | Expand your Business globally',
    titleTemplate: '%s - Gobiz.be | Your Digital Export Manager',
    htmlAttrs: {
      lang: 'en'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'Gobiz - Be Smart Go Global | Your Digital Export Manager ', content: 'Gobiz.be | La nuova piattaforma per la ricerca di partner commerciali e fornitori - The new way to find international business partners.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=0.8' },
   // { name: 'viewport', content: 'width=device-width, initial-scale=1'},
      ]
  },
  components : {
    Navigator,
    Footers,
  },
  created(){
    this.$store.dispatch('autoLogin');
    this.loadLabels();
    this.loadDropMenu();
  },
  methods : {
    loadLabels(){
      gAxios.get(process.env.VUE_APP_CONN_NODEJS+'/languagelabel')
      .then(res => {
        this.$store.dispatch('loadLangLabels',res.data.langLabels);
      })
      .catch(
        error => {error}
      );
    },
    loadDropMenu(){
      gAxios.get(process.env.VUE_APP_CONN_NODEJS+'/dropdownmenulabel')
      .then(res => {
        this.$store.dispatch('loadDropMenu',res.data);
      })
      .catch(
        error => {error}
      );
    }
  },
 // metaInfo: {
 //     title: 'Gobiz - Be Smart Go Global',
 //     titleTemplate: 'Gobiz Site',
 //     htmlAttrs: {
 //       lang: 'en',
  //      amp: true
  //    }
 //   },
  
}

 

</script>

<style lang="scss">
#app {
  font-family: 'blogger_sansregular', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #0c294b;
}

#nav {
  padding: 30px;
  margin-top: 50px;
  a {
    font-weight: bold;
    color: #ffffff;
//colore testo sotto icona selezionata
    &.router-link-exact-active {
    color: #e5d237;}
     //&.router-link-active {
    //  color: #ffffff;  }
  }
}

//colore testo bianco per menu info - non va!
.colormenuinfo
a {
    font-weight: bold;
    color: #ffffff;
    &.router-link-exact-active {
       color: #ffffff;}
       }

// .dropdown-item:hover {
   //  background-color: #ffffff;
// }

.dropdown-menu
 {
    background-color: #0c294b;
     color: #ffffff;
 }

.dropdown-menu a:hover 
 {
     background-color:#e5d237;
  }

.tablelist{
  margin-bottom: 60px;
    /* margin: 5px; */
    padding: 5px;
    border: #cccccc 1px solid;
}

.count{
  font-size: 7em;
}

.yellow-light{
    background-color: #f6f6e2;
    min-height: 50px;
}
.blu-dark2{
    background-color: #e7f2ff;
    min-height: 50px;
}


.btn-bigdark{
  
    min-height: 28px;
    display: inline-block;
    background-color: #0c294b;
    color: #e6d235;
    
}

.btn-bigdark:hover {
box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
color: #0c294b;
background-color: #e5d237;
border-color: #0c294b;
}
.blu-ligthadvisory{
    background-color: #ffffff;
    min-height: 50px;
}
.blu-ligth{
    background-color: #fefef3;
    min-height: 50px;
}
.blu-dark{
    background-color: #f0f6fc;
    min-height: 50px;
}
.blu-header{
    background-color: #0c294b;
    min-height: 50px;
    color: #ffffff;
    font-size: 1.1rem;
}
.table td, .table th, .table {
    border: 0px;
    padding: 1px;
}
.tablelist {
    margin-bottom: 60px;
    /* margin: 5px; */
    padding: 5px;
    border: #cccccc 0px solid;
}
.buttNavGreen{
  border-color: #0c294b;
  background-color: #e6d235;
  color: #0c294b;
  }

.table {
  width: 100%;
  margin-bottom: #e6d235;
  color: #0c294b;
  background-color: #f6f6e2; // Reset for nesting within parents with `background-color`.
 }


.footer {
   position: fixed;;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: #0c294b;
   color: #e5d237;
   text-align: center;
}


.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  color:#0C294B;
  }

.h1,h1 {
font-family: 'uniform_roundedbold', Arial, sans-serif;
}

.h2,h2 {
font-family: 'blogger_sansregular', Arial, sans-serif;
font-weight:bold;
}

.h3,h3 {
font-family: 'blogger_sansregular', Arial, sans-serif;
}

.h4,h4 {
font-family: 'uniform_roundedregular', Arial, sans-serif;
}

.h5,h5 {
font-family: 'blogger_sansregular', Arial, sans-serif;
font-weight:normal;
font-style:normal;
}

.bg-lightVAR{
  background-color: #0c294b;
}
.btn-info{
color: #0c294b;
background-color: #ffffff;
border-color: #0c294b;
}

.btn-info:hover {
    color: #0c294b;
    background-color: #e5d237;
    border-color: #0c294b;
}

.nav-link { 
            color: #0c294b;
            //color: #087fe7; 
        } 
  
.nav-item>a:hover { 
            color: #0c294b; 
        } 

.btn-okbo{
color: #fff;
background-color: #2d8908;
border-color: #0c294b;
}
.btn-okbo:hover {
    color: #2d8908;
    background-color: #fff;
    border-color: #0c294b;
}

.btn-okbo2{
color: #0c294b;
background-color: #aed139;
border-color: #0c294b;
}
.btn-okbo2:hover {
    color: #aed139;
    background-color: #0c294b;
    border-color: #0c294b;
}


.btn-recyclebo{
color: #fff;
background-color: #d5680e;
border-color: #0c294b;
}
.btn-recyclebo:hover {
    color: #d5680e;
    background-color: #fff;
    border-color: #0c294b;
}

.btn-success{
color: #0c294b;
background-color: #fff;
border-color: #0c294b;
}
.btn-success:hover {
    color: #0c294b;
    background-color: #e5d237;
    border-color: #0c294b;
}

.btn{
  font-size: 3em;
  line-height: 1.08;
  border-radius: .3rem;
  font-weight: 600;
  padding: .2rem 1.1rem;
  //font-size: 1.5em;
}

.btn-dark {
    border-color: #343a40;
    background-color: #0c294b;
    color: #e6d235;
}

.btn-dark:hover {
box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
color: #0c294b;
background-color: #e5d237;
border-color: #0c294b
}
.loginbutton:hover {
box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);

color: #0c294b;
background-color: #e5d237;
border-color: #0c294b;
}

.loginbutton{
    width: 70%;
    min-height: 90px;
    display: inline-block;
    background-color: #0c294b;
    color: #e6d235;
}

.bigbutton{
    width: 70%;
    min-height: 60px;
    display: inline-block;
    background-color: #0c294b;
    color: #e6d235;
}

.bigbutton:hover {
box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);

color: #0c294b;
background-color: #e5d237;
border-color: #0c294b;
}

.btn-pdf{
color: #ffffff;
background-color: #0c294b;
border-color: #0c294b;
}


.btn-vetrina{
    color: #0c294b;
    background-color: #0c294b;
    border-color: #e5d237;
    min-width: 100px;
    min-height: 80px;
}

.tabella-vetrina td, .tabella-vetrina th{
    background-color: #fefef3;
     border: none;
}



.btn-vetrina:hover {
    color: #0c294b;
    background-color: #e5d237;
    border-color: #0c294b;
    min-width: 100px;
    min-height: 80px;
}

.btn-vetrina-gri{
color:#ccd3da;
background-color: grey;
border-color: #0c294b;
min-width: 100px;
min-height: 80px;
}

.btn-vetrina-inverso{
color: #e5d237;
background-color: #e5d237;
border-color: #0c294b;
min-width: 100px;
min-height: 80px;
}
.btn-vetrina-inverso:hover {
    color: #e5d237;
    background-color: #0c294b;
    border-color: #e5d237;
    min-width: 100px;
    min-height: 80px;
}

.btn-tooltip {
 color: #e5d237;
    background-color: #0c294b;
    border-color: #0c294b;
       
} 
.btn-tooltip:hover {
 color: #e5d237;
    background-color: #0c294b;
    border-color: #fff;
       
} 

.btn-tooltip-info {
  color: #e5d237;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
       
} 

.btn-tooltip-info:hover {
 color: #e5d237;
    background-color: #0c294b;
    border-color: #fff; 

} 

.btn-yellow-tooltip {
 color: #e5d237;
 background-color: rgba(0,0,0,.03);
 border-bottom: 1px solid rgba(0,0,0,.125);
       
} 
.btn-yellow-tooltip:hover {
 color: #e5d237;
    background-color: #e5d237;
    border-color: #fff;
       
} 


.btn-primary {
color: #0c294b;
background-color: #f5eb96;
border-color: #0c294b;
font-size: 0.8em;
}

.btn-primary:hover {
    color: #0c294b;
    background-color: #e5d237;
    border-color: #0c294b;
    
}  



.btn-warning{
color: #0c294b;
background-color: #fff;
border-color: #0c294b;
}

.btn-warning:hover {
    color: #fff;
    background-color: #e53737;
    border-color: #0c294b;
}



@font-face {
    font-family: 'uniform_roundedregular';
    src: url('/font/uniform_rounded-webfont.woff2') format('woff2'),
         url('/font/uniform_rounded-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'uniform_roundedbold';
    src: url('/font/uniform_rounded_bold-webfont.woff2') format('woff2'),
         url('/font/uniform_rounded_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'blogger_sansregular';
    src: url('/font/blogger_sans-webfont.woff2') format('woff2'),
         url('/font/blogger_sans-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'blogger_sansmedium';
    src: url('/font/blogger_sans-medium-webfont.woff2') format('woff2'),
         url('/font/blogger_sans-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    //font-size: 4em;

}

        .top-left { 
            top: 0; 
            left: 0; 
        } 
          
        .top-center { 
            top: 0; 
            left: 50%; 
            transform: translateX(-50%); 
        } 
          
        .top-right { 
            top: 0; 
            right: 0; 
        } 
          
        .mid-left { 
            top: 50%; 
            left: 0; 
            transform: translateY(-50%); 
        } 
          
        .mid-center { 
            top: 50%; 
            left: 50%; 
            transform: translateX(-50%) translateY(-50%); 
        } 
 
        .mid-right { 
            top: 50%; 
            right: 0; 
            transform: translateY(-50%); 
        } 
          
        .bottom-left { 
            bottom: 0; 
            left: 0; 
        } 
          
        .bottom-center { 
            bottom: 0; 
            left: 50%; 
            transform: translateX(-50%); 
        } 
          
        .bottom-right { 
            bottom: 0; 
            right: 0; 
        } 
         
         .minmarginleft {
           margin-left: 2px;
                   }

         .minpadding {
          padding: .2rem 0.5rem;
          /*  padding: .2rem 1.1rem; */      
          }

.container {
   max-width: none !important;
   min-width: 620px !important;
  
}
 /*  .card { */
   /*  word-wrap: break-word; */
  /*  margin: 4%; */
  /* } */

.tooltip-vue {
  display: block !important;
  z-index: 10000;
}

.tooltip-vue .tooltip-vue-inner {
  background: #e6d235;
  color: #0c294b;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip-vue .tooltip-vue-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: #0c294b;
  z-index: 1;
}

.tooltip-vue[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip-vue[x-placement^="top"] .tooltip-vue-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip-vue[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip-vue[x-placement^="bottom"] .tooltip-vue-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip-vue[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip-vue[x-placement^="right"] .tooltip-vue-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip-vue[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip-vue[x-placement^="left"] .tooltip-vue-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip-vue.popover .popover-inner {
  background: #f9f9f9;
  color: #0c294b;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(#0c294b, .1);
}

.tooltip-vue.popover .popover-arrow {
  border-color: #0c294b;
}

.tooltip-vue[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip-vue[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}

.custom-google-button {
  background-color: #0c294b;
  color: #22f10c;
  border: none;
  padding: 3px 3px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}




  @media (max-width: 991px) {
    .navbar {
        overflow: auto;
        max-height: 85vh;
        align-items: flex-start;
    }
}


</style>
