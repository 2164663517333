<template>
  <div class="container">
    <form @submit.prevent="onSubmit">
      <h2 v-if="this.id==null">{{searchevents(this.labels,'menu','Advisoringdata')}} </h2>
      <h6 v-if="this.id==null">{{searchevents(this.labels,'menu','companyrequiredfields')}}</h6>
      <br>
     

       <!-- PAGINA AL MOMENTO NON IN USO -->

     
      
      <!-- hidden ###################################################################################################################### -->
      <input type="hidden" id="id" name="id" v-model="id">
      <input type="hidden" id="deleted" name="deleted" v-model="deleted">
      <input type="hidden" id="idUsersParent" name="idUsersParent" v-model="idUsersParent">
      <input type="hidden" id="isAdminVerified" name="isAdminVerified" v-model="isAdminVerified">
      <input type="hidden" id="servUsersStateUsersState" name="servUsersStateUsersState" v-model="servUsersStateUsersState">
      <input type="hidden" id="usersRoleRole" name="usersRoleRole" v-model="usersRoleRole">
      
      <!-- ############################################################################################################################# -->

      <div>
          
           <div class="row">
            
            <div v-for="(value, key) in userToCompaniesList" :key="key" class="col-sm-12" >
                <!-- SOCIETA' COLLEGATE -->
                <!-- <div class="col-sm-4" style="display: inline-grid;"> {{ value.companyName }} || {{ value.usersRoleCompanyRole }} </div> -->
                <!-- <div class="col-sm-4" style="display: inline-grid;"> <a class="nav-link" href="#" @click="addDeleteUserTocompanies(value.userId,value.companyId,value.usersRoleCompanyRole,'DEL')" >{{searchevents(labels,'menu','delete')}}</a> </div> -->
            </div>
           </div>
      </div>

        <div class="form-group" style="margin-right:15%;margin-left:15%;">
            <div class="row">
              <div class="col">
                  <div  > {{searchevents(this.labels,'menu','usertocompaniesrole')}}* </div>
              </div>
            </div>

           <div class="row" style="margin-right:10%;margin-left:10%;">
                <div class="col" v-if="this.getIdUsersParent=='-1' && idUsersParent!='-1'">
                    <select id="newCompany"  v-model="newCompany" style="width: 100%;" >
                        <option v-for="(value, key) in mycompanies" :value="value['companyId']" :key="key">{{value.companyName}}</option>
                    </select>
                </div>
                <div class="col" v-if="this.getIdUsersParent=='-1' && idUsersParent=='-1'">
                    <select id="newCompany"  v-model="newCompany" style="width: 100%;" disabled>
                        <option v-for="(value, key) in mycompanies" :value="value['companyId']" :key="key">{{value.companyName}}</option>
                    </select>
                </div>
                <div class="col" v-if="this.getIdUsersParent!='-1'">
                    <select id="newCompany"  v-model="newCompany" style="width: 100%;" disabled>
                       <option v-for="(value, key) in mycompanies" :value="value['companyId']" :key="key">{{value.companyName}}</option>
                    </select>
                </div>
                <br>
                <!-- <div>
                    <a class="nav-link" href="#" @click="addDeleteUserTocompanies('','','','ADD')" >{{searchevents(labels,'menu','add')}}</a>
                </div>
                -->

           </div>
        </div>

      <div class="form-group" style="margin-top:20px;">
 
      </div>


      <div class="form-group" style="margin-top:20px;margin-right:15%;margin-left:15%;">
        <div class="row" style="margin-right:10%;margin-left:10%;">
               <div class="col">
                <label>{{searchevents(this.labels,'menu','producths6')}}*</label>
                 <select class="form-control">
                                            <option value=""></option>
                                            <option value="10121">10121	Cavalli, vivi, riproduttori di razza pura</option>
                                            <option value="10129">10129	Cavalli, vivi (escl. riproduttori di razza pura)</option>
                                            <option value="10130">10130	Asini, vivi</option>
                                            <option value="10190">10190	Muli e bardotti, vivi</option>
                                            <option value="10221">10221	Bovini, vivi, riproduttori di razza pura</option>
                                            <option value="10229">10229	Bovini, vivi (escl. riproduttori di razza pura)</option>
                 </select>
               
                <!-- codice per recupero da tabella 
                <select class="form-control" v-model="servMobilePrefixId" required >
                    <template v-for="servMobilePrefixId in this.dropMservLandlinePrefixId">
                        <option v-for="(value, key) in servMobilePrefixId" :value="value['id']" :key="key">{{value['prefix']}} - {{value['prefixName']}}</option>
                    </template>
                </select>
               --> 

              </div>

                           <div class="row d-flex justify-content-center" style="background-color: white" >
                  
                                   
                
                </div>

              
        </div>
        <div class="row" style="margin-top:20px;margin-right:10%;margin-left:10%;">
              <div class="col">
                <label for="mobile">{{searchevents(this.labels,'menu','advisordata1')}} 1</label>
                  <!-- <input class="form-control" type="mobile" id="mobile" pattern=".{6,}" v-model="mobile" required > -->
                  <select class="form-control">
                                            <option value=""></option>
                                            <option value="Austria">Austria</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Croazia">Croazia</option>
                                            <option value="Danimarca">Danimarca</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Francia">Francia</option>
                                            <option value="Germania">Germania</option>
                                            <option value="Grecia">Grecia</option>
                                            <option value="Spagna">Spagna</option>
                                            <option value="Svezia">Svezia</option>
                                            <option value="Portogallo">Portogallo</option>
                  </select>
                 
                 <!-- codice per recupero da tabella 
                 <select class="form-control" v-model="countryOfResidence" @change="mobilePrefix()" required>
                    <template v-for="countryOfResidence in this.dropMofficeRegion ">
                        <option v-for="(value, key) in countryOfResidence" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                    </template>
                </select>
                -->
                
              </div>

              <div class="col">
                <label for="acceptNotifications">{{searchevents(this.labels,'menu','advisordata2')}}</label>
                <input class="form-control" type="checkbox" id="acceptNotifications" value="acceptNotifications" v-model="acceptNotifications"> 
              </div>
        </div>
        <br>
     

               <div class="row" style="margin-top:20px;margin-right:10%;margin-left:10%;">
              <div class="col">
                <label for="mobile">{{searchevents(this.labels,'menu','advisordata1')}} 2</label>
                  <!-- <input class="form-control" type="mobile" id="mobile" pattern=".{6,}" v-model="mobile" required > -->
                  <select class="form-control">
                                            <option value=""></option>
                                            <option value="Austria">Austria</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Croazia">Croazia</option>
                                            <option value="Danimarca">Danimarca</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Francia">Francia</option>
                                            <option value="Germania">Germania</option>
                                            <option value="Grecia">Grecia</option>
                                            <option value="Spagna">Spagna</option>
                                            <option value="Svezia">Svezia</option>
                                            <option value="Portogallo">Portogallo</option>
                  </select>
                 
                 <!-- codice per recupero da tabella 
                 <select class="form-control" v-model="countryOfResidence" @change="mobilePrefix()" required>
                    <template v-for="countryOfResidence in this.dropMofficeRegion ">
                        <option v-for="(value, key) in countryOfResidence" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                    </template>
                </select>
                -->
                
              </div>

              <div class="col">
                <label for="acceptNotifications">{{searchevents(this.labels,'menu','advisordata2')}}</label>
                <input class="form-control" type="checkbox" id="acceptNotifications" value="acceptNotifications" v-model="acceptNotifications"> 
              </div>
        </div>
        <br>
           <hr>
      </div>

            
   
              <div style="display: inline-block;margin-top:20px;" >
                <label for="isAdmin" v-html="searchevents(this.labels,'users','clickToSeeAdvisoring')"></label>
              </div>
              <br>
      <button type="submit" class="btn btn-dark" style="font-size: 1.3em; width: 200px; margin-right:15px; margin-top: 20px; margin-bottom:75px;height:60px">{{searchevents(this.labels,'menu','confirmandsend')}}</button>
      <button type="button" class="btn btn-success" style="font-size: 1.3em; width: 200px; margin-left:15px; margin-top: 20px; margin-bottom:75px;height:60px" @click="backToDashboard()">{{searchevents(this.labels,'menu','backnosave')}}</button>
      <div v-html="searchevents(this.labels,'dialog','contactusforadvisoring')"> </div>

      <br>
      <br>
      <br>
    </form>
  </div>
</template>


<script>

import gAxios from 'axios';
import router from '@/router';

export default {
 name: 'UpdateUser',
  data () {
    return {
      remoteServer : process.env.VUE_APP_CONN_NODEJS,
      id: null,
      name : '',
      surname : '',
      email : '',
     emailOld : '',
      password : '',
      repeatpassword : '',
      fiscalCode : '',
      deleted : null,
      servMobilePrefixId : '',
      mobile : '',
      acceptNotifications : null,
      isAdmin : null,
      isAdminVerified : null,
      idUsersParent : null,
      usersRoleRole : null,
      servUsersStateUsersState: null,
      registeredUser: [],
      dropMservLandlinePrefixId: null,
     userToCompaniesList : [],
     mycompanies : [],
      newCompany : null,
       acceptContractViewed: false,
       acceptContract: false,
       newRole : 'COL',
      
      
    }
  },
  methods : {
    backToDashboard(){
        router.push('/dashboard');
    },
    enableContractConfirmation(){
      this.acceptContractViewed = true;
      
    },
    onSubmit(){
      if(this.isNotAdmin===true){
        this.isAdmin = 'N';
      }

      if(this.password != this.repeatpassword){
        if(this.lang=='Ita'){
          alert('Le password inserite non coincidono');
        }
        if(this.lang=='Eng'){
          alert('Passwords do not match');
        }
        if(this.lang=='Den'){
          alert('Adgangskoderne er ikke ens');
        }

        return false;
      }

      if(this.acceptContract==false && this.id==null){
         if(this.lang=='Ita'){
            alert('Occorre accettare il contratto');
          }
          if(this.lang=='Eng'){
            alert('The contract must be accepted');
          }
          if(this.lang=='Den'){
            alert('Kontrakten skal accepteres');
          }

        return false;
      }

      if(this.servMobilePrefixId=='1'){
          if(this.lang=='Ita'){
            alert('Occorre selezionare un Prefisso Mobile');
          }
          if(this.lang=='Eng'){
            alert('A Mobile Prefix must be selected');
          }
          if(this.lang=='Den'){
            alert('Der skal vælges et mobilpræfiks');
          }

          return false;
        }

      if(this.newCompany=='' || this.newCompany==null || this.newCompany==-1 || this.newRole=='' || this.newRole==null || this.newRole==-1){
        //alert('Selezionare un ruolo presso un\'azienda / Please select a company role');

        if(this.lang=='Ita'){
          alert('Selezionare un\'Impresa ');
        }
        if(this.lang=='Eng'){
          alert('Please select Company ');
        }
        if(this.lang=='Den'){
          alert('Vælg firma');
        }
        

        return false;
      }


      const formData = {
        id: this.id,
        name : this.name,
        surname : this.surname,
        email : this.email,
        emailOld: this.emailOld,
        password : this.password,
        fiscalCode : this.fiscalCode,
        deleted : this.deleted,
        servMobilePrefixId : this.servMobilePrefixId,
        mobile : this.mobile,
        acceptNotifications : this.acceptNotifications,
        isAdmin : this.isAdmin,
        isAdminVerified : this.isAdminVerified,
        idUsersParent : this.idUsersParent,
        usersRoleRole : this.usersRoleRole,
        servUsersStateUsersState: this.servUsersStateUsersState

      }
      
      
      if(this.isAdmin==true){
        this.updateUser(formData);
      }else{

        if(this.emailOld==this.email && this.emailOld!=''){
          // non è cambiata
          
          this.updateUser(formData);
        }else{
          gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/checkUser',
                {
                   email : this.email,
                   fiscalCode: null
                })
                .then(res => {
                    
                    if(res.data.message=='OK')
                    {
                      // se la risposta è OK, allora controllo il codice fiscale
                      
                      this.updateUser(formData);
                    }else{
                      // ko ---------------------------------------------------
                      
                      let msg = this.searchevents(this.labels,'dialog','opnotpermittedemailpresent');
                      alert(msg);
                    }
                })
                .catch(
                error => {
                //console.log(error);
                return error;
                }
                );
        }
        

      }
      
    },
    allCompanies(){
        const auth = {
            headers: {Authorization:'JWT ' + this.getToken} 
        }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/allCompaniesMe',
                {
                   userId : this.getUserId,
                   userIdParent : '-1'
                },auth)
                .then(res => {
                    
                    let users = res.data.companieslist;
                    
                    users.forEach(u => {
                         
                         let trovata = 0;
                            this.mycompanies.forEach(myC => {
                                if(myC.id == u.companyId){ trovata++; }
                            })
                            if(trovata==0){ this.mycompanies.push(u); }
                        /*
                        let company = u.companies;
                        company.forEach(comp => {
                            
                            // prima di aggiungere la company verifico che non sia già presente
                            let trovata = 0;
                            this.mycompanies.forEach(myC => {
                                if(myC.id == comp.id){ trovata++; }
                            })
                            if(trovata==0){ this.mycompanies.push(comp); }
                        });*/
                    });
                    
                    
                })
                .catch(
                error => {
              
                return error;
                }
                );
    },
    addDeleteUserTocompanies(userId,companyId,usersRoleCompanyRole,action){
        const auth = {
            headers: {Authorization:'JWT ' + this.getToken} 
        }
        

        if(action=='ADD'){
            
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/usertocompany',
                {
                    userId : this.id,
                    companyId : this.newCompany,
                    usersRoleCompanyRole : this.newRole,
                    action : 'ADD',

                    returnSecureToken : true
                
                },auth)
                .then(res => {
                this.callUserToCompanies();
                return res;
                //router.push('/dashboard');
                })
                .catch(
                error => {
                
                // faccio qualcosa qui
                this.callUserToCompanies();
                return error;
                }
                );
        }

        if(action=='DEL'){
            
            if(this.idUsersParent!='-1'){
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/usertocompany',
                {
                    userId : this.id,
                    companyId : companyId,
                    usersRoleCompanyRole : usersRoleCompanyRole,
                    action : 'DEL',

                    returnSecureToken : true
                
                },auth)
                .then(res => {
                this.callUserToCompanies();
                return res;
                
                //router.push('/dashboard');
                })
                .catch(
                error => {
                
                // faccio qualcosa qui
                    this.callUserToCompanies();
                    return error;
                }
                );
            }else{
                alert('Non permesso/Not permitted');
                if(this.lang=='Ita'){
                alert('Non permesso');
                }
                if(this.lang=='Eng'){
                  alert('Not permitted');
                }
                if(this.lang=='Den'){
                  alert('Ikke tilladt');
                }
            }
            
        }

        
    },
    callUserToCompanies(){
        const auth = {
            headers: {Authorization:'JWT ' + this.getToken} 
        }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/usertocompany',
        {
            userId : this.id,
            companyId : '',
            usersRoleCompanyRole : '',
            action : 'ALL',

            returnSecureToken : true
          
        },auth)
        .then(res => {
          
          this.userToCompaniesList = res.data.usertocompanies;
          this.newCompany = this.userToCompaniesList[0].companyId;
          this.newRole = this.userToCompaniesList[0].usersRoleCompanyRole;
          return res;
          //router.push('/dashboard');
        })
        .catch(
          error => {
          
          // faccio qualcosa qui
            return error;
          }
          );
    },
    updateUser(formData){
        const auth = {
            headers: {Authorization:'JWT ' + this.getToken} 
        }

        let thisIsAdmin = 'N';
        let thisIsAdminVerified = 'N';
        let thisAcceptNotifications = 'N';
        if(formData.isAdmin!=true && formData.isAdmin!='Y'){ formData.isAdmin = 'N'; thisIsAdmin = 'N'; }else{ thisIsAdmin = true; }
        if(formData.acceptNotifications!=true && formData.acceptNotifications!='Y'){ formData.acceptNotifications = 'N'; thisAcceptNotifications = 'N'; }else{ thisAcceptNotifications = true; }
        if(formData.isAdminVerified!=true && formData.isAdminVerified!='Y'){ formData.isAdminVerified = 'N'; thisIsAdminVerified = 'N'; }else{ thisIsAdminVerified = true; }
    

        // decido qui se si tratta di un'UPDATE oppure di una INSERT
      if(this.id==null){
        
        let newUserIdParent = -1;
        let currentUserId = this.getUserId;
        let currentUserIdParent = this.getIdUsersParent;
        let usersRoleCompanyRole = 'COL';
        let usersRoleCompanyRoleUser = 'ADM'; // padre se non idPArente = -1
        if(currentUserIdParent == -1){ usersRoleCompanyRole = 'ADM'; newUserIdParent = currentUserId; }else{ newUserIdParent = currentUserIdParent; }
        
        

        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/insertUser',
        {
            name : formData.name,
            surname : formData.surname,
            email : formData.email,
            password : formData.password,
            fiscalCode : formData.fiscalCode,
            deleted : false,
            servMobilePrefixId : formData.servMobilePrefixId,
            mobile : formData.mobile,
            acceptNotifications : thisAcceptNotifications,
            isAdmin : thisIsAdmin,
            isAdminVerified : 'N',
            idUsersParent : newUserIdParent,
            usersRoleRole : 'USR',
            servUsersStateUsersState: 'S',
            companyIdUtC : this.newCompany,
            usersRoleCompanyRoleIdUtC : this.newRole,

            returnSecureToken : true
          
        },auth)
        .then(res => {

          if(this.lang=='Ita'){
          alert('Operazione completata con successo');
          }
          if(this.lang=='Eng'){
            alert('Successful operation');
          }
          if(this.lang=='Den'){
            alert('Succesfuld operation');
          }

          router.push('/myuser');
          return res+usersRoleCompanyRole+usersRoleCompanyRoleUser;
          
        })
        .catch(
          error => {

          if(this.lang=='Ita'){
          alert('Errore durante la creazione dell\'utente');
          }
          if(this.lang=='Eng'){
            alert('Error creating user');
          }
          if(this.lang=='Den'){
            alert('Fejl ved oprettelse af bruger');
          }
          //console.log(error);
          // faccio qualcosa qui
            return error;
          }
          );
        

      }else{
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/updateUser/'+this.id,
        {
            name : this.name,
            surname : this.surname,
            email : this.email,
            password : this.password,
            fiscalCode : this.fiscalCode,
            deleted : this.deleted,
            servMobilePrefixId : this.servMobilePrefixId,
            mobile : this.mobile,
            acceptNotifications : thisAcceptNotifications,
            isAdmin : thisIsAdmin,
            isAdminVerified : thisIsAdminVerified,
            idUsersParent : this.idUsersParent,
            usersRoleRole : this.usersRoleRole,
            servUsersStateUsersState: this.servUsersStateUsersState,
            returnSecureToken : true,
            companyIdUtC : this.newCompany,
            usersRoleCompanyRoleIdUtC : this.newRole
          
        },auth)
        .then(res => {
          
          if(this.lang=='Ita'){
          alert('Operazione completata con successo');
          }
          if(this.lang=='Eng'){
            alert('Successful operation');
          }
          if(this.lang=='Den'){
            alert('Succesfuld operation');
          }

          //return res;
          router.push('/myuser');
          return res;
        })
        .catch(
          error => {
          
          if(this.lang=='Ita'){
          alert('Errore durante la creazione dell\'utente');
          }
          if(this.lang=='Eng'){
            alert('Error creating user');
          }
          if(this.lang=='Den'){
            alert('Fejl ved oprettelse af bruger');
          }

          // faccio qualcosa qui
            return error;
          }
          );
        }

    },
    searchevents(events,filterValue,label){
        if(events){
          const filter = event => 
            event.tableName.includes(filterValue) &&
            event.fieldName.includes(label)
        
          var filteredArray = events.filter(filter);
          if(filteredArray.length >0)
          {
            if(this.lang)
            {
              var labelName = 'label'+this.lang;
              return filteredArray[0][labelName];
            }else{
              return '--';
            }
            
          }else{
            return '--';
          }
        }else{
          return null;
        }
      },
      returnMenu(num){
        var menu = this.dropM;
        if(menu)
        {
          return menu[num];
        }else{
          return null;
        }
      }
  },
  computed :{
    lang(){
      var lng = this.$store.getters.lang;
        if(lng)
        {
          return lng;
        }else{
          return '';
        }
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
    getUserId(){
       // return null;
       return this.$store.getters.getUserId;
    },
    getIdUsersParent(){
       // return null;
       return this.$store.getters.getIdUsersParent;
    },
    labels(){
      return this.$store.getters.labels;
    },
    dropM(){
      
      var menu = this.$store.getters.dropM;
      if(menu){
        //this.dropM1 = menu[11];
        return menu;
      }else{
        return null;
      }
      
    },
    getregisteredUser(){
      var rcomp = this.$store.getters.getregisteredUser;
      if(rcomp){
        
        return rcomp;
      }else{ 
        return 0;
      }
    }
  },
  created(){
    if(this.dropMservLandlinePrefixId===null && this.dropM!=null){
      this.dropMservLandlinePrefixId = this.dropM.serv_mobile_prefixes;
    }
    
  },
  watch : {
      dropM: function () {
      // potrei anche leggere il nuovo e il vecchio valore (newdropMservLandlinePrefixId,olddropMservLandlinePrefixId)
      this.dropMservLandlinePrefixId = this.dropM.serv_mobile_prefixes;
      
    },
    getregisteredUser: function(){
        this.registeredUser = this.getregisteredUser;
      
        this.id = this.getregisteredUser.id;
        this.name = this.getregisteredUser.name;
        this.surname = this.getregisteredUser.surname;
        this.email = this.getregisteredUser.email;
        this.emailOld = this.getregisteredUser.email;
        this.password = this.getregisteredUser.password;
        this.fiscalCode = this.getregisteredUser.fiscalCode;
        this.deleted = this.getregisteredUser.deleted;
        this.servMobilePrefixId = this.getregisteredUser.servMobilePrefixId;
        this.mobile = this.getregisteredUser.mobile;
        this.acceptNotifications = this.getregisteredUser.acceptNotifications;
        this.isAdmin = this.getregisteredUser.isAdmin;
        this.isAdminVerified = this.getregisteredUser.isAdminVerified;
        this.idUsersParent = this.getregisteredUser.idUsersParent;
        this.usersRoleRole = this.getregisteredUser.usersRoleRole;
        this.servUsersStateUsersState = this.getregisteredUser.servUsersStateUsersState;
        
    }
  },
  mounted(){
    this.$nextTick(function () {
      var reg = this.$store.getters.getregisteredUser;
      if(reg!=null){ this.registeredUser = reg; }
      
      
      if(this.registeredUser && this.registeredUser.id == null){
        //router.push('/myuser');
        this.allCompanies();
      }else{
        let thisIsAdmin = null;
        let thisIsAdminVerified = null;
        let thisAcceptNotifications = null;
        if(this.getregisteredUser.isAdmin=='Y'){ thisIsAdmin = true; }else{ thisIsAdmin = false; }
        if(this.getregisteredUser.isAdminVerified=='Y'){ thisIsAdminVerified = true; }else{ thisIsAdminVerified = false; }
        if(this.getregisteredUser.acceptNotifications=='Y'){ thisAcceptNotifications = true; }else{ thisAcceptNotifications = false; }

        this.id = this.getregisteredUser.id;
        this.name = this.getregisteredUser.name;
        this.surname = this.getregisteredUser.surname;
        this.email = this.getregisteredUser.email;
        this.emailOld = this.getregisteredUser.email;
        this.password = '';
        this.fiscalCode = this.getregisteredUser.fiscalCode;
        this.deleted = this.getregisteredUser.deleted;
        this.servMobilePrefixId = this.getregisteredUser.servMobilePrefixId;
        this.mobile = this.getregisteredUser.mobile;
        this.acceptNotifications = thisAcceptNotifications;
        this.isAdmin =thisIsAdmin;
        this.isAdminVerified = thisIsAdminVerified;
        this.idUsersParent = this.getregisteredUser.idUsersParent;
        this.usersRoleRole = this.getregisteredUser.usersRoleRole;
        this.servUsersStateUsersState = this.getregisteredUser.servUsersStateUsersState;
        this.newCompany = this.getregisteredUser.usersRoleRole;
        this.callUserToCompanies();
        this.allCompanies();
      }
    });
  },
  beforeUpdate(){
    
  },
  updated(){
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.count{
  font-size: 7em;
}
.btn{
  font-size: 3em;
}
hr{
  border-top: 2px solid #2c3e50;
}
</style>
