import Vue from 'vue'
import Vuex from 'vuex'


//import axios from 'axios';
import gAxios from 'axios';
import * as ApiService from '@/helpers/api.service'
import router from '@/router';


Vue.use(Vuex)

const DetectedLang = navigator.language;
var AppliedLang = 'Ita';
if(DetectedLang==='it-IT'){ AppliedLang = 'Ita'; }
if(DetectedLang==='en-GB'){ AppliedLang = 'Eng'; }
if(DetectedLang==='da-DK'){ AppliedLang = 'Den'; }
// navigator.language

export default new Vuex.Store({
  state: {
    idToken : null,
    idUser : null,
    user : null,
    name : null,
    surname : null,
    idUsersParent : null,
    usersRoleRole : null,
    lang : AppliedLang,
    langLabels : null,
    dropM : null,
    firstRegisterStep : 0,
    confirmedEmail : '',
    registeredCompany : [],
    registeredUser : [],
    updatedCertificate : [],
    companyRegisteredGoBiz : [],
    requestseevisura : [],
    okrequestseevisura : [],
    korequestseevisura : [],
    totalmessages : [],
    totalrequestedvisura : [],
    companyIdTo : null,
    permittedUser : 'Y',
    companyIdBusinessCard: null,
    companyTableBusinessCard: null,
    companyScoreBusinessCard: null,
    companyNetworkBusinessCard: null,
    companyMessageBusinessCard: null,
    servUsersStateUsersState : null,
    companyNumberToNotInvite : null,
    tableToNotInvite : null,
    companyCountryOfOriginInvite : null,
    needNetworkRefresh : 'N',
    selectAgreementsGuid : null,
    selectAgreementsComp1 : null,
    selectAgreementsComp2 : null
  },
  mutations: {
    authUser(state,userData){
      state.idToken = userData.token;
      state.idUser = userData.idUser;
      state.name = userData.name;
      state.surname = userData.surname;
      state.idUsersParent = userData.idUsersParent;
      state.usersRoleRole = userData.usersRoleRole;
      state.servUsersStateUsersState = userData.servUsersStateUsersState;
    },
    storeUserInternal(state,user){
      state.user = user;
      
    },
    clearAuthData(state){
      state.idToken = null;
      state.idUser = null;
      state.name = null;
      state.user = null;
      state.surname = null;
      state.idUsersParent = null;
      state.registeredCompany = null,
      state.registeredUser = null,
      state.companyIdTo = null
      
    },
    SetselectAgreementsGuid(state,comp1){
      state.selectAgreementsGuid = comp1;
    },
    SetselectAgreementsComp01(state,comp1){
      state.selectAgreementsComp1 = comp1;
    },
    SetselectAgreementsComp02(state,comp2){
      state.selectAgreementsComp2 = comp2;
    },
    setneedNetworkRefresh(state,act){
      state.needNetworkRefresh = act;
    },
    setcompanyNetworkBusinessCard(state,act){
      state.companyNetworkBusinessCard = act;
    },
    setcompanyMessageBusinessCard(state,act){
      //console.log(act+'cccccccccccccccccccccccccccccccc');
      state.companyMessageBusinessCard = act;
    },
    setcompanyIdBusinessCard(state,act){
      state.companyIdBusinessCard = act;
    },
    setcompanyTableBusinessCard(state,act){
      state.companyTableBusinessCard = act;
    },
    setcompanyScoreBusinessCard(state,act){
      state.companyScoreBusinessCard = act;
    },
    setPermittedUser(state,act){
      state.permittedUser = act;
    },
    confirmEmail(state,act){
      state.confirmedEmail = act;
    },
    changeLangGlobal(state,lang){
      state.lang = lang;
    },
    loadLangLabelsGlobal(state,lang){
      state.langLabels = lang;
    },
    loadDropMenuGlobal(state,menu){
      state.dropM = menu;
    },
    RegisteredCompnay(state,company){
      state.registeredCompany = company;
    },
    FirstStepRegister(state){
      state.firstRegisterStep = 1;
    },
    ResetregisteredCompanyGlobal(state){
      state.registeredCompany = null;
    },
    ConfirmNotInvitingCommit(state,companyNumber){
      //console.log(companyNumber+'<<<<');
      state.companyNumberToNotInvite = companyNumber;
    },
    ConfirmNotInvitingCommitTab(state,tab){
      //console.log(tab+'<<TAB<<');
      state.tableToNotInvite = tab;
    },
    ConfirmNotInvitingCoo(state, coo){
      state.companyCountryOfOriginInvite = coo;
    },
    RegistercompanyIdToGlobal(state,companyIdTo){
      state.companyIdTo = companyIdTo;
    },
    SetregisteredCompanyGlobal(state,company){
      state.registeredCompany = company;
      
    },
    ResetregisteredUserGlobal(state){
      state.registeredUser = null;
    },
    SetregisteredUserGlobal(state,user){
      state.registeredUser = user;
      
    },
    SetupdatedCertificate(state,company){
      state.updatedCertificate = company;
    },
    SetcompanyRegisteredGoBiz(state,company){
      state.companyRegisteredGoBiz = company;
    },
    Setrequestseevisura(state,company){
      state.requestseevisura = company;
    },
    Setokrequestseevisura(state,company){
      state.okrequestseevisura = company;
    },
    Setkorequestseevisura(state,company){
      state.korequestseevisura = company;
    },
    Settotalmessages(state,company){
      state.totalmessages = company;
    },
    Settotalrequestedvisura(state,company){
      state.totalrequestedvisura = company;
    },
    VoidCommit(){

    }
  },
  actions: {
    setSetselectAgreementsGuid({commit},req){
      commit('SetselectAgreementsGuid', req);
    },
    setSetselectAgreementsComp1({commit},req){
      commit('SetselectAgreementsComp01', req);
    },
    setSetselectAgreementsComp2({commit},req){
      commit('SetselectAgreementsComp02', req);
    },
    setNeedNetworkRefresh({commit},req){
      commit('setneedNetworkRefresh', req);
    },
    setCompanyNetworkBusinessCard({commit},req){
      commit('setcompanyNetworkBusinessCard',req);
    },
    setCompanyMessageBusinessCard({commit},req){
      commit('setcompanyMessageBusinessCard',req);
    },
    setCompanyIdBusinessCard({commit},req){
      commit('setcompanyIdBusinessCard',req);
    },
    setCompanyTableBusinessCard({commit},req){
      commit('setcompanyTableBusinessCard',req);
    },
    setCompanyScoreBusinessCard({commit},req){
      commit('setcompanyScoreBusinessCard',req);
    },
    setupdatedCertificate({commit},req){
      commit('SetupdatedCertificate',req);
    },
    setcompanyRegisteredGoBiz({commit},req){
      commit('SetcompanyRegisteredGoBiz',req);
    },
    setrequestseevisura({commit},req){
      commit('Setrequestseevisura',req);
    },
    setokrequestseevisura({commit},req){
      commit('Setokrequestseevisura',req);
    },
    setkorequestseevisura({commit},req){
      commit('Setkorequestseevisura',req);
    },
    settotalmessages({commit},req){
      commit('Settotalmessages',req);
    },
    settotalrequestedvisura({commit},req){
      commit('Settotalrequestedvisura',req);
    },
    loadLangLabels({commit},req){
      commit('loadLangLabelsGlobal',req);
      
    },
    loadDropMenu({commit},req){
      commit('loadDropMenuGlobal',req);
      
    },
    changeLang({commit},req){
      commit('changeLangGlobal',req);
    },
    confirmEmailAct({commit},act){
      commit('confirmEmail',act);
      
      router.push('/confirmregistration').catch(err => {
        return err;
      })
    },
    setPermittedUserAction({commit},req){
      commit('setPermittedUser',req);
    },
    setcompanyIdTo({commit},req){
      commit('RegistercompanyIdToGlobal',req);
    },
    setLogout({dispatch},expireTime){
      /*
      setTimeout(() => {
        dispatch('logout');
      },expireTime * 1000);
      */
      return dispatch+expireTime;
    },
    logout({commit}){
      commit('clearAuthData');
      localStorage.removeItem('token');
      localStorage.removeItem('expireDate');
      localStorage.removeItem('idUser');

      //router.push('/login');
      router.push('/').catch(err => {
        return err;
      })
    },
    ResetregisteredUser({commit}){
      commit('ResetregisteredUserGlobal');
    },
    SetregisteredUser({commit},req){
      commit('SetregisteredUserGlobal',req);
    },
    ResetregisteredCompany({commit}){
      commit('ResetregisteredCompanyGlobal');
    },
    SetregisteredCompany({commit},req){
      commit('SetregisteredCompanyGlobal',req);
    },
    ConfirmNotInviting({commit},req){
      commit('ConfirmNotInvitingCommit',req);

      //setTimeout(() => {
        router.push('/confirmnotinviting/AskConfirm/0/0').catch(err => {
          //console.log(err);
          return err;
        })
      //}, 2000);

      
      
    },
    ConfirmNotInvitingTab({commit},req){
      commit('ConfirmNotInvitingCommitTab',req);
      //console.log(req+'req');
    },
    ConfirmNotInvitingCoo({commit},req){
      commit('ConfirmNotInvitingCoo',req);
      //console.log(req+'req');
    },
    loadCompany({commit},num){
      //commit('VoidCommetit');
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompany/',
      {
        id:num
      })
      .then(res => {
        commit('RegisteredCompnay',res.data.company);
        return res;
        //router.push('/dashboard');
      })
      .catch(
        error => {
          commit('RegisteredCompnay',null);
          return error;
        }
        );
    },
    updateCompany({commit},formData){
      
      commit('RegisteredCompnay',formData);
      //return formData;
      const auth = {
        headers: {Authorization:'JWT ' + localStorage.getItem('token')} 
      }
      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/updateCompany/'+formData.id,
      {
        companyName : formData.companyName,
        pec : formData.pec,
        emailAddress : formData.emailAddress,
        officeRegion : formData.officeRegion,
        officeDistrict : formData.officeDistrict,
        officeCity : formData.officeCity,
        officeTown : formData.officeTown,
        officePostcode : formData.officePostcode,
        officeAddress : formData.officeAddress,
        vatNumber : formData.vatNumber,
        idTax : formData.idTax,
        companyNumber : formData.companyNumber,
        operationalCountry : formData.operationalCountry,
        operationalRegion : formData.operationalRegion,
        operationalDistrict : formData.operationalDistrict,
        operationalCity : formData.operationalCity,
        operationalTown : formData.operationalTown,
        operationalPostcode : formData.operationalPostcode,
        operationalAddress : formData.operationalAddress,
        reaNumber : formData.reaNumber,
        landlinePhone : formData.landlinePhone,
        mobilePhone : formData.mobilePhone,
        companyActivityDescription : formData.companyActivityDescription,
        companyActivityProducts : formData.companyActivityProducts,
        companyActivityCertifications : formData.companyActivityCertifications,
        incorporationDate : formData.incorporationDate,
        dissolutionDate : formData.dissolutionDate,
        partOfGroup : formData.partOfGroup,
        groupName : formData.groupName,
        groupIdTax : formData.groupIdTax,
        atecoCode1 : formData.atecoCode1,
        atecoCode2 : formData.atecoCode2,
        atecoCode3 : formData.atecoCode3,
        atecoCode4 : formData.atecoCode4,
        atecoCode5 : formData.atecoCode5,
        atecoCode6 : formData.atecoCode6,
        atecoCode7 : formData.atecoCode7,
        sicCode1 : formData.sicCode1,
        sicCode2 : formData.sicCode2,
        sicCode3 : formData.sicCode3,
        sicCode4 : formData.sicCode4,
        naceMacro : formData.naceMacro,
        naceSector : formData.naceSector,
        sicMacro : formData.sicMacro,
        sicSector : formData.sicSector,
        inspectionDate : formData.inspectionDate,
        lastInspectionDate : formData.lastInspectionDate,
        webSite : formData.webSite,
        linkedin : formData.linkedin,
        twitter : formData.twitter,
        facebook : formData.facebook,
        deleted : formData.deleted,
        servMobilePrefixId : formData.servMobilePrefixId,
        servLandlinePrefixId : formData.servLandlinePrefixId,
        servCompaniesStateCompaniesState : formData.servCompaniesStateCompaniesState,
        servCompaniesCategoryId : formData.servCompaniesCategoryId,
        servCompaniesEmployeesNumId : formData.servCompaniesEmployeesNumId,
        servCompaniesTurnoverId : formData.servCompaniesTurnoverId,
        servCompaniesBusinessSectorId : formData.servCompaniesBusinessSectorId,
        countryOfOrigin : formData.countryOfOrigin,
        officeCountry : formData.officeCountry,
        initialCamComm : formData.initialCamComm,
        returnSecureToken : true
        
      },auth)
      .then(res => {
        
        return res;
        //router.push('/dashboard');
      })
      .catch(
        error => {
         
          return error;
        }
        );
        
    },
    register({commit},formData){
      
      ApiService.post(process.env.VUE_APP_CONN_NODEJS+'/auth/register',
      {
        name : formData.name,
        email : formData.email,
        password : formData.password,
        surname : formData.surname,
        fiscalCode : formData.fiscalCode,
        mobile : formData.mobile,
        idUsersParent : "-1",
        isAdmin : formData.isAdmin,
        isAdminVerified : "N",
        acceptNotifications : "Y",
        deleted : "0",
        usersRoleRole : "USR",
        servMobilePrefixId : formData.servMobilePrefixId,
        servUsersStateUsersState : "S",
        
        a_name : formData.a_name,
        a_email : formData.a_email,
        a_password : formData.a_password,
        a_surname : formData.a_surname,
        a_fiscalCode :formData.a_fiscalCode,
        a_mobile : formData.a_mobile,
        a_idUsersParent : formData.a_idUsersParent,
        a_isAdmin : formData.a_isAdmin,
        a_isAdminVerified : "N",
        a_acceptNotifications : "Y",
        a_deleted : "0",
        a_usersRoleRole : "USR",
        a_servMobilePrefixId : formData.a_servMobilePrefixId,
        a_servUsersStateUsersState : "S",
        
        companyName : formData.companyName,
        emailAddress : formData.emailAddress,
        countryOfOrigin : formData.countryOfOrigin,
        servCompaniesCategoryId : formData.servCompaniesCategoryId,
        servCompaniesCategoryText : formData.servCompaniesCategoryText,
        companyNumber : formData.companyNumber,
        countryOfResidence : formData.countryOfResidence,
        servDocTypeId : formData.servDocTypeId,
        documentNumber : formData.documentNumber,

        vatNumber : formData.vatNumber,
        pec : formData.pec,
        officeRegion : formData.officeRegion,
        officeDistrict : formData.officeDistrict,
        officeCity : formData.officeCity,
        officeAddress : formData.officeAddress,
        idTax : formData.idTax,
        landlinePhone : formData.landlinePhone,
        servLandlinePrefixId : formData.servLandlinePrefixId,
        disclaimerContact : formData.disclaimerContact,
        usersRoleCompanyRole : "ADM",

        returnSecureToken : true
        
      })
      .then(res => {
        /*
        commit('authUser',{
          token : res.data.token,
          idUser : res.data.user.id,
          name :  res.data.user.name,
          surname :  res.data.user.surname,
          idUsersParent :  res.data.user.idUsersParent
        });

        //res.data.password = 
        setTimeout(() => {
          dispatch('login',formData);
        }, 3000);
        
        commit('RegisteredCompnay',res.data.company);
        commit('storeUserInternal',formData);
        dispatch('storeUser',formData);
        */
        //const now = new Date();
        //const expireDate = new Date(now.getTime() + (16*60*60*1000));
        //dispatch('setLogout',expireDate);
        
        //router.push('/updatecompany');
        //console.log(res.data);
        this.state.idUser = res.data.user.id;
        commit('RegisteredCompnay',res.data.company);
        commit('FirstStepRegister');
        router.push('/thankregistration');
      })
      .catch(
        error => {
          //console.log(error);
          return error;
        });
    },
    login({commit},formData){
      
      ApiService.post(process.env.VUE_APP_CONN_NODEJS+'/auth/login',
      {
        email : formData.email,
        password : formData.password,
        returnSecureToken : true
      })
      .then(res => {
        
        commit('authUser',{
          //token : res.data.idToken,
          token : res.data.token,
          idUser : res.data.id,
          name : res.data.name,
          surname : res.data.surname,
          idUsersParent : res.data.idUsersParent,
          usersRoleRole : res.data.usersRoleRole,
          servUsersStateUsersState : res.data.servUsersStateUsersState
        });
        
        commit('storeUserInternal',res.data);

        const now = new Date();
        let expireDate = new Date(now.getTime() + (1*60*60*1000));
        expireDate = res.data.expiresIn;
        //dispatch('setLogout',expireDate);
        //------------------localstorage
        localStorage.setItem('token',res.data.token);
        localStorage.setItem('expireDate',expireDate);
        localStorage.setItem('idUser', res.data.id);
    
        //--------------------------
        router.push('/dashboard');
      })
      .catch(
        error => {error}
        );
    },
    storeUser({state},userData){
      if(!this.state.idToken){
        return;
      }
      gAxios.post('users.json' + '?auth=' + state.idToken,userData)
      .then(res =>{res} )
      .catch(error => {error});
    },
    notIsLogged(){
      if(this.state.idToken){
        return true;
      }
        return false;
      
    },
    autoLogin({commit,state}){
      const token = state.idToken;
  
      if(!token)
        return 
      const expireDate = localStorage.getItem('expireDate');
      const now = new Date();
      if(now >= expireDate)
        return
      const idUser = localStorage.getItem('idUser');
      commit('authUser',{
        token : token,
        idUser : idUser
      });
    },
    searchevents(events,filterValue,label){
      let result = events;
      const filter = event => 
          event.name.includes(filterValue) ||
          event.state.includes(label)
      
      return result.filter(filter)
    }
  },
  getters : {
    getneedNetworkRefresh(state){
      return state.needNetworkRefresh;
    },
    user(state){
      return state.user;
    },
    isAuth(state){
      return state.idToken !== null;
    },
    lang(state){
      return state.lang;
    },
    labels(state){
      return state.langLabels;
    },
    dropM(state){
      return state.dropM;
    },
    getregisteredCompany(state){
      return state.registeredCompany;
    },
    getregisteredUser(state){
      return state.registeredUser;
    },
    getUserToken(state){
      return state.idToken;
    },
    getUserId(state){
      return state.idUser;
    },
    getIdUsersParent(state){
      return state.idUsersParent;
    },
    getUserNameSurname(state){
      return state.name+' '+state.surname;
    },
    getCompamyIdTo(state){
      return state.companyIdTo;
    },
    FistStepRegister(state){
      return state.firstRegisterStep;
    },
    getEmailConfirmation(state){
      return state.confirmedEmail;
    },
    getUserRole(state){
      return state.usersRoleRole;
    },
    getPermittedUser(state){
      return state.permittedUser;
    },
    getupdatedCertificate(state){
      return state.updatedCertificate;
    },
    getcompanyRegisteredGoBiz(state){
      return state.companyRegisteredGoBiz;
    },
    getrequestseevisura(state){
      return state.requestseevisura;
    },
    getokrequestseevisura(state){
      return state.okrequestseevisura;
    },
    getkorequestseevisura(state){
      return state.korequestseevisura;
    },

    gettotalmessages(state){
      return state.totalmessages;
    },
    gettotalrequestedvisura(state){
      return state.totalrequestedvisura;
    },
    getcompanyIdBusinessCard(state){
      return state.companyIdBusinessCard;
    },
    getcompanyTableBusinessCard(state){
      return state.companyTableBusinessCard;
    },
    getcompanyScoreBusinessCard(state){
      return state.companyScoreBusinessCard;
    },
    getcompanyNetworkBusinessCard(state){
      return state.companyNetworkBusinessCard;
    },
    getXcompanyMessageBusinessCard(state){
      return state.companyMessageBusinessCard;
    },
    getcompanyNumberToNotInvite(state){
      return state.companyNumberToNotInvite;
    },
    gettableToNotInvite(state){
      return state.tableToNotInvite;
    },
    getcompanyCountryOfOriginInvite(state){
      return state.companyCountryOfOriginInvite;
    },
    getselectAgreementsGuid(state){
      return state.selectAgreementsGuid;
    },
    getselectAgreementsComp1(state){
      return state.selectAgreementsComp1;
    },
    getselectAgreementsComp2(state){
      return state.selectAgreementsComp2;
    }
  }
})
