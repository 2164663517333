<template>
<!-- le vetrine sono qui -->
<!--
  <div id="nav" >
    <router-link :to="{ name : 'home'}">Home</router-link> |
    <router-link v-if="!auth" :to="{ name : 'register'}">Register</router-link> |
    <router-link v-if="!auth" :to="{ name : 'login'}">Login</router-link> |
    <router-link v-if="auth" :to="{ name : 'dashboard'}">Dashboard</router-link> |
    <a href="#" @click="logout" v-if="auth">Logout</a> |
    <router-link :to="{ name : 'counter'}">Counter</router-link> |
    <router-link :to="{ name : 'newpost'}">New Post</router-link> |
    <router-link :to="{ name : 'listpost'}">List Post</router-link> | 
  </div>
  -->

<div id="nav" >
  <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-lightVAR ">
      <a class="navbar-brand" href="#">
        <img src="../assets/gobiz-logo-rectangle-yellow.png" height="60" class="d-inline-block align-top" alt="GoBiz Start">
      </a>
        <button class="navbar-toggler bordo-hamburger" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon hamburger-giallo"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ">
          <li class="nav-item active">
            <div><a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"><router-link style="padding: 5px;" :to="{ name : 'home'}"><span class="fa fa-home" style="font-size:35px;" ></span></router-link></a></div>
            <div>
              <a class=nav-link data-toggle="collapse" data-target=".navbar-collapse.show"><router-link :to="{ name : 'home'}">{{searchevents(this.labels,'menu','home')}}</router-link></a>
            </div>
          </li>
          <li class="nav-item" v-if="!auth">
            <div><a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"><router-link style="padding: 5px;" :to="{ name : 'register'}"><span class="fas fa-door-open" style="font-size:35px;" ></span></router-link></a></div>
            <div>
              <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"><router-link v-if="!auth" :to="{ name : 'register'}">{{searchevents(this.labels,'menu','register')}}</router-link></a>
            </div>
          </li>
          <li class="nav-item" v-if="!auth">
            <div><a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"><router-link style="padding: 5px;" :to="{ name : 'login'}"><span class="fa fa-sign-in-alt" style="font-size:35px;" ></span></router-link></a></div>
            <div>
              <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"><router-link v-if="!auth" :to="{ name : 'login'}">{{searchevents(this.labels,'menu','login')}}</router-link></a>
            </div>
          </li>
          <li class="nav-item" v-if="auth">
            <div><a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"><router-link style="padding: 5px;" :to="{ name : 'dashboard'}"><span class="fa fa-th-large" style="font-size:35px;" ></span></router-link></a></div>
            <div>
              <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"><router-link v-if="auth" :to="{ name : 'dashboard'}">{{searchevents(this.labels,'menu','dashboard')}}</router-link></a>
            </div>
          </li>

          <!--
          <li class="nav-item" v-if="auth">
            <div><span class="fa fa-dashboard" style="font-size:35px;color:#2c3e50" ></span></div>
            <div>
              <a class="nav-link"><router-link v-if="auth" :to="{ name : 'thankregistration'}">thankregistration</router-link></a>
            </div>
          </li>
          -->
          
          
          <li class="nav-item dropdown" v-if="auth">
            
            
            <div>
              <a class="dropdown-toggle nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  >
                <div><span class="fa fa-user-check" style="font-size:35px; margin-top: 5px; margin-bottom: 4px;" ></span></div>
                {{this.userIdentity}}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                
                 <!-- DA RIATTIVARE GUIDA E AGGIUNGI IMPRESA !!!! -->
                <!-- <a class="dropdown-item" v-if="this.getUser.guidContractFlag=='Y'"><router-link class="dropdown-item"  :to="{ name : 'mycompany'}">{{searchevents(this.labels,'menu','mycompanies')}}</router-link></a> -->
                <!-- aperta gestione utenti anche a ruoli non ancora approvati - eliminato this.getUser.guidIdentityFlag=='Y' dalla prima voce -->
                <a class="dropdown-item" v-if="this.getIdUsersParent=='-1'"><router-link class="dropdown-item" :to="{ name : 'myusers'}">{{searchevents(this.labels,'menu','myusermanagm')}}</router-link></a>
                <a class="dropdown-item" v-if="this.getUser.guidIdentityFlag=='Y' && this.getIdUsersParent=='-1' && this.getUserRole!='BOF' && this.getUserRole!='SAD' "><router-link class="dropdown-item" :to="{ name : 'updateuser'}">{{searchevents(this.labels,'menu','insertuser')}}</router-link></a> 
                <a class="dropdown-item" v-if="this.getIdUsersParent!='-1' && this.getUserRole!='BOF' "><router-link class="dropdown-item" :to="{ name : 'myusers'}">{{searchevents(this.labels,'menu','myprofile')}}</router-link></a>
                <!-- <a class="dropdown-item" v-if="getUserRole=='USR' && this.getIdUsersParent=='-1' && this.getUser.servUsersStateUsersState=='A' " style="padding: .5rem 3rem;" @click="addNewCompany()" >{{searchevents(labels,'menu','insertcompany')}}</a> -->

                <a class="dropdown-item" href="#" v-if="getUserRole=='SAD' "  @click="sadreport" style="padding: .5rem 3rem;" >{{searchevents(this.labels,'menu','sadreport')}}</a> 
                
                <!-- 
                <a class="dropdown-item" v-if="this.getUser.guidContractFlag=='Y' && this.getPermittedUser=='Y' && this.getUserRole!='BOF'"><router-link class="dropdown-item" :to="{ name : 'mynetwork' }">{{searchevents(labels,'menu','mynetwork')}}</router-link></a>
                <a class="dropdown-item" v-if="this.getUser.guidContractFlag=='Y' && this.getPermittedUser=='Y' && this.getUserRole!='BOF'"><router-link class="dropdown-item" :to="{ name : 'matchmaking', params: {mm : 'ADV'} }">{{searchevents(labels,'nav','matchmakingadv')}}</router-link></a>
                <a class="dropdown-item" v-if="this.getUser.guidContractFlag=='Y' && this.getPermittedUser=='Y' && this.getUserRole!='BOF'"><router-link class="dropdown-item" :to="{ name : 'matchmaking', params: {mm : 'SMT'} }">{{searchevents(labels,'nav','matchmakingsmart')}}</router-link></a>
                -->
                <!-- <a class="dropdown-item"><router-link class="dropdown-item"  :to="{ name : 'mycontacts'}">{{searchevents(this.labels,'menu','mycontacts')}}</router-link></a> -->
                <!-- <a class="dropdown-item" href="#">{{this.getToken}}</a> -->
                <!-- <a class="dropdown-item" href="#" @click="this.usId">User identity</a> -->
                
                <!-- aggiunta mail 27 bis per backoffice
                <a v-if="auth && this.getUserRole=='BOF'" class="nav-link colormenuinfo" style="text-decoration: none;"><router-link :to="{ name : 'addmailbo'}">Aggiungi email</router-link></a>
                -->
                <a class="dropdown-item" href="#" style="padding: .5rem 3rem;" @click="logout" v-if="auth">{{searchevents(this.labels,'menu','logout')}} </a>
              </div>
            </div>
          </li>
      <!-- nuova classe pagine di contenuti con pagine html -->

          <li class="nav-item dropdown" >
            <div>
              <a class="dropdown-toggle nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  >
               <div><span class="fa fa-cube" style="font-size:35px; margin-top: 5px; margin-bottom: 4px;" ></span></div>
                {{searchevents(this.labels,'menu','ourservices')}}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="nav-link colormenuinfo" style="text-decoration: none;"><router-link :to="{ name : 'overview'}">{{searchevents(this.labels,'menu','servoverview')}}</router-link></a>
                <a class="nav-link colormenuinfo" style="text-decoration: none;"><router-link :to="{ name : 'ourusers'}">{{searchevents(this.labels,'menu','ourusers')}}</router-link></a>
                
                <!-- da arricchire asap giugno 2023
                <a class="nav-link colormenuinfo" style="text-decoration: none;"><router-link :to="{ name : 'ourdata'}">{{searchevents(this.labels,'menu','ourdata')}}</router-link></a>
                <a class="nav-link colormenuinfo" style="text-decoration: none;"><router-link :to="{ name : 'markets'}">{{searchevents(this.labels,'menu','markets')}}</router-link></a>
                 -->

                 
                <!-- <a class="nav-link colormenuinfo" style="text-decoration: none;"><router-link :to="{ name : 'confirmnotinviting'}">confirmnotinviting</router-link></a> -->
                <!-- <a class="nav-link" style="text-decoration: none;"><router-link :to="{ name : 'dashboard'}">{{searchevents(this.labels,'menu','dashboard')}}</router-link></a> -->
              </div>
            </div>
          </li>


      
          <li class="nav-item dropdown" >
            <div>
              <a class="dropdown-toggle nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  >
               <div><span class="fa fa-info-circle" style="font-size:35px; margin-top: 5px; margin-bottom: 4px;" ></span></div>
                {{searchevents(this.labels,'menu','infopages')}}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="nav-link colormenuinfo" style="text-decoration: none;"><router-link :to="{ name : 'aboutus'}">{{searchevents(this.labels,'menu','aboutus')}}</router-link></a>
                <a class="nav-link colormenuinfo" style="text-decoration: none;"><router-link :to="{ name : 'help'}">{{searchevents(this.labels,'menu','help')}}</router-link></a>
                <a class="nav-link colormenuinfo" style="text-decoration: none;"><router-link :to="{ name : 'faq'}">{{searchevents(this.labels,'menu','faqbutton')}}</router-link></a>
                <a class="nav-link colormenuinfo" style="text-decoration: none;"><router-link :to="{ name : 'support'}">{{searchevents(this.labels,'dialog','helpabouttheplaftorm')}}</router-link></a>
               
                <!-- <a class="nav-link colormenuinfo" style="text-decoration: none;"><router-link :to="{ name : 'confirmnotinviting'}">confirmnotinviting</router-link></a> -->
                <!-- <a class="nav-link" style="text-decoration: none;"><router-link :to="{ name : 'dashboard'}">{{searchevents(this.labels,'menu','dashboard')}}</router-link></a> -->
              </div>
            </div>
          </li>


          <li class="nav-item dropdown" >
            <div>
              <a class="dropdown-toggle nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  >
               <div><span class="fa fa-language" style="font-size:35px; margin-top: 5px; margin-bottom: 4px; margin-left: 2px;" ></span></div>
                {{this.lang}}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="nav-link" href="#" @click="changeLang('Ita')" >IT</a>
                <a class="nav-link" href="#" @click="changeLang('Eng')" >EN</a>
                <a class="nav-link" href="#" @click="changeLang('Den')" >DA</a>
              </div>
            </div>
          </li>

          <li class="nav-item dropdown" v-if="auth && this.getUserRole!='BOF'" style="margin-left: 20px;">
            <div>
              <a class="dropdown-toggle nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  >
                <div><span class="fa fa-bell" style="font-size:35px;color:#e6d235; margin-top: 5px; margin-bottom: 4px;" ><span class="badge" v-if="returnBellCount>0">{{returnBellCount}}+</span></span></div>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                
                <!-- inizio qui BELL -->
                <li class="liBellH" v-if="returnBellupdatedCertificate!=''" >{{searchevents(this.labels,'dialog','updatedversioncompanyreport')}}</li>
                <span v-for="comp in returnBellupdatedCertificate" :key="comp.id">
                  <li class="liBell" >{{comp.CompNam}} 
                    <button type="button" class="btn btn-success" style="font-size: .8em;" @click="markViewed(comp.id)" >{{searchevents(labels,'bell','justviewed')}}</button>
                  </li>
                </span>
                <hr v-if="returnBellupdatedCertificate!=''">

                <li class="liBellH" v-if="returnBellcompanyRegisteredGoBiz!=''" >{{searchevents(this.labels,'dialog','acceptedinvitation')}}</li>
                <span v-for="comp in returnBellcompanyRegisteredGoBiz" :key="comp.id">
                  <li class="liBell" >{{comp.CompNam}} 
                    <button type="button" class="btn btn-success" style="font-size: .8em;" @click="markViewed(comp.id)" >{{searchevents(labels,'bell','justviewed')}}</button>
                  </li>
                </span>
                <hr v-if="returnBellcompanyRegisteredGoBiz!=''">

                <li class="liBellH" v-if="returnBellrequestseevisura!=''" >{{searchevents(this.labels,'bell','requestseevisura')}} </li>
                <span v-for="comp in returnBellrequestseevisura" :key="comp.id">
                  <li class="liBell">{{comp.CompNam}} -> {{comp.CompNamTo}}
                    <button type="button" class="btn btn-success" style="font-size: .8em;" @click="onClickConfirmAcceptReject(comp,'Y')" >{{searchevents(labels,'bell','accept')}}</button>
                    <button type="button" class="btn btn-danger" style="font-size: .8em; margin-left: 10px;" @click="onClickConfirmAcceptReject(comp,'N')" >{{searchevents(labels,'bell','reject')}}</button>
                  </li>
                </span>
                <hr v-if="returnBellrequestseevisura!=''">

                <li class="liBellH" v-if="returnBellokrequestseevisura!=''" >{{searchevents(this.labels,'dialog','bacceptedyourrequest')}} </li>
                <span v-for="comp in returnBellokrequestseevisura" :key="comp.id">
                  <li class="liBell" >{{comp.CompNam}} 
                    <button type="button" class="btn btn-success" style="font-size: .8em;" @click="markViewed(comp.id)" >{{searchevents(labels,'bell','justviewed')}}</button>
                  </li>
                </span>
                <hr v-if="returnBellokrequestseevisura!=''">

                <li class="liBellH" v-if="returnBellkorequestseevisura!=''" >{{searchevents(this.labels,'dialog','notshareditscompanyreport')}}</li>
                <span v-for="comp in returnBellkorequestseevisura" :key="comp.id">
                  <li class="liBell" >{{comp.CompNam}} 
                    <button type="button" class="btn btn-success" style="font-size: .8em;" @click="markViewed(comp.id)" >{{searchevents(labels,'bell','justviewed')}}</button>
                  </li>
                </span>
                <hr v-if="returnBellkorequestseevisura!=''">
                
              </div>
            </div>
          </li>

          <!--
          <li class="nav-item" v-if="this.lang==='Ita'">
            <div><span class="fa fa-language" style="font-size:35px;color:#2c3e50" ></span></div>
            <div>
              <a class="nav-link" href="#" @click="changeLang('Eng')">IT</a>
            </div>
          </li>
          <li class="nav-item" v-if="this.lang==='Eng'">
            <div><span class="fa fa-language" style="font-size:35px;color:#2c3e50" ></span></div>
            <div>
              <a class="nav-link" href="#" @click="changeLang('Ita')" >EN</a>
            </div>
          </li>
          <li class="nav-item" v-if="this.lang==='Den'">
            <div><span class="fa fa-language" style="font-size:35px;color:#2c3e50" ></span></div>
            <div>
              <a class="nav-link" href="#" @click="changeLang('Den')" >EN</a>
            </div>
          </li>
          --> 
          
          <li class="nav-item" v-if="this.companyName!=''" style="margin-left: 30px; margin-top: 3%;  ">
            <div><h3 style="color:#fff"><b>{{this.companyName}}</b></h3></div>
            
          </li>

        </ul>
      </div>
</nav>

<!-- MODALS inizio delle due vetrine -------------------------------------------------------------------------------------------- -->
<div class="modal" id="myBusinessCardGobiz" ref="myBusinessCardGobiz" style="top:100px; padding-bottom: 200px;" >
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header vetrina registrate-->
            <div class="modal-header">
              <div style="float:left;"><span class="fa fa-check-square fa-2x mr-2 align-self-center" style="color:#72bb53;margin-left: 10px;" ></span></div>
              <div style="float: left; line-height: 16px; margin-left: 10px; margin-top: 5px;" v-html="searchevents(this.labels,'businessCard','CertifiedCompany') "></div>
              <div style="width: 70%; text-align: center;">
                <h3 class="modal-title">{{this.mycompaniesDetails.a1}}</h3> {{this.amBannedOrNot(this.mycompaniesDetails.thisId, this.mycompaniesDetails.companyTable)}} 
                <div >
                 <h5> {{this.mycompaniesDetails.bb6}} - {{this.mycompaniesDetails.b8}} </h5>
                </div>
              </div>
              <!--
              <div style="float: left; line-height: 16px; margin-left: 10px; margin-top: 5px;" >
                <button type="button" class="btn btn-success" style="font-size: .9em; min-width: 180px;" @click="businessCardAction('addtomyfavorite',mycompaniesDetails.thisId,mycompaniesDetails.guid)"><span class="fa fa-star-o" style="font-size:15px;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'businessCard','addtomyfavorite')}}</span></button> 
                <button type="button" class="btn btn-warning" style="font-size: .9em; min-width: 180px; margin-top: 5px;" data-dismiss="modal" @click="businessCardAction('sendmessage',mycompaniesDetails.thisId,mycompaniesDetails.guid)"><span class="fa fa-envelope-o" style="font-size:15px;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'businessCard','sendmessage')}}</span></button> 
              </div>
              -->
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body vetrina registrate -->
            <div class="modal-body">
              <div class="row">
                <div class="col" style="text-align: center;">
                   <img v-if="this.mycompaniesDetailsDocs[4].name!=''" style="max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[4].name" /> 
                </div>
              </div>
              <div class="row" style="margin-top:10px;">
                <div class="col-6">
                  <h3><strong>{{searchevents(this.labels,'businessCard','businessoverview')}}</strong></h3>
                  <div style="margin-left:30px" > 
                    <h5><li>{{searchevents(this.labels,'companies','companyActivityDescription')}}</li></h5>
                    <p v-if="lang=='Ita'">{{this.mycompaniesDetails.a2}}</p>
                    <p v-if="lang=='Eng'">{{this.mycompaniesDetails.a22}}</p>
                    <p v-if="lang=='Den'">{{this.mycompaniesDetails.a23}}</p>
                  </div>
                  
                  <div style="margin-left:30px" v-if="this.mycompaniesDetails.companyTable=='companies'">
                    <h5><li>{{searchevents(this.labels,'companies','companyActivityProducts')}}</li></h5>
                    <p v-if="lang=='Ita'">{{this.mycompaniesDetails.a3}}</p>
                    <p v-if="lang=='Eng'">{{this.mycompaniesDetails.a32}}</p>
                    <p v-if="lang=='Den'">{{this.mycompaniesDetails.a33}}</p>
                  </div>
                  <div style="margin-left:30px" v-if="this.mycompaniesDetails.companyTable=='companies'">
                    <h5><li>{{searchevents(this.labels,'companies','companyActivityCertifications')}}</li></h5>
                    <p v-if="lang=='Ita'">{{this.mycompaniesDetails.a4}}</p>
                    <p v-if="lang=='Eng'">{{this.mycompaniesDetails.a42}}</p>
                    <p v-if="lang=='Den'">{{this.mycompaniesDetails.a43}}</p>
                  </div>
                </div>
                
                <div class="col-6">
                  <h3><strong>{{searchevents(this.labels,'businessCard','companydatamain')}}</strong></h3>
                  <div class="row"> <!-- ROW -->
                      <div style="margin-left:20px" class="col-5">
                          <li>{{searchevents(this.labels,'businessCard','b1')}}</li>
                      </div>
                      <div class="col-5">
                         {{this.mycompaniesDetails.bb6}}
                      </div> 
                    </div> <!-- --------- -->
                    <div class="row"> <!-- ROW -->
                        <div div style="margin-left:20px" class="col-5">
                            <li>{{searchevents(this.labels,'businessCard','b2')}}</li>
                        </div>
                        <div class="col-5">
                            {{this.mycompaniesDetails.b2}}
                        </div> 
                    </div> <!-- --------- -->
                    <div class="row"> <!-- ROW -->
                        <div div style="margin-left:20px" class="col-5">
                           <li>{{searchevents(this.labels,'businessCard','b3')}}</li>
                        </div>
                        <div class="col-5" v-if="this.mycompaniesDetails.b3!=''">
                            {{this.mycompaniesDetails.b3}}
                        </div>
                        <div class="col-5" v-if="this.mycompaniesDetails.bb3!=''">
                            {{this.mycompaniesDetails.bb3}}
                        </div> 
                        <div class="col-5" v-if="this.mycompaniesDetails.bbb3!=''">
                            {{this.mycompaniesDetails.bbb3}}
                        </div> 
                    </div> <!-- --------- -->
                    <div class="row"> <!-- ROW -->
                        <div div style="margin-left:20px" class="col-5">
                            <li>{{searchevents(this.labels,'businessCard','b12')}} / {{searchevents(this.labels,'businessCard','by12')}}</li>
                        </div>
                            
                        <div class="col-5">
                            {{this.mycompaniesDetails.b12}} / {{this.mycompaniesDetails.by12}}
                        </div>
                            
                        
                    </div> <!-- --------- -->
                    <!--
                    <div class="row"> 
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'businessCard','cat1')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.cat1}}
                        </div> 
                    </div>
                    -->

                    <div class="row"> <!-- ROW -->
                        <div style="margin-left:20px" class="col-5">
                            <li>{{searchevents(this.labels,'businessCard','f1')}} / {{searchevents(this.labels,'businessCard','by12')}}</li>
                        </div>
                            
                        <div class="col-5">
                             {{this.mycompaniesDetails.f1}} / {{this.mycompaniesDetails.fy1}}
                        </div>
                           
                    </div> <!-- --------- -->
                    <div class="row"> <!-- ROW -->
                        <div style="margin-left:20px" class="col-5">
                            <li>{{searchevents(this.labels,'businessCard','inc1')}}</li>
                        </div>
                        <div class="col-5">
                            {{returnYear(this.mycompaniesDetails.inc1)}}
                        </div> 
                    </div> <!-- --------- -->

                           <!--AGGIUNTA URI REGISTRATE CVR e COMP HOUSE--------- -->

                   <div class="row"> <!-- ROW -->
                        <div v-if="this.mycompaniesDetails.bb6==='Denmark' || this.mycompaniesDetails.cofo1==2"  style="margin-left:20px" class="col-5">
                            <li>Company Link (URI)</li>
                        </div>
                       
                         <div v-if="this.mycompaniesDetails.cofo1==2"  class="col-5" >
                          <a class="nav-link" style="padding: 0px;" :href="searchevents(this.labels,'http','uricompanyhouse') + this.mycompaniesDetails.b2" target="_blank">
                           <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.b2}} @ Company House </h6> </a>
                         </div>
                         <div v-if="this.mycompaniesDetails.bb6==='Denmark'" class="col-5">
                            <a class="nav-link" style="padding: 0px;" :href="searchevents(this.labels,'http','uricvr') + this.mycompaniesDetails.b2 + '&q=visenhed&language=en-gb'" target="_blank">
                            <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.b2}} @ CVR </h6> </a>
                         </div>

                       
                           <!--AGGIUNTA URI REGISTRATE OPEN CORPORATES --------- -->

                        <div v-if="this.mycompaniesDetails.bb6==='Denmark' || this.mycompaniesDetails.cofo1==2" style="margin-left:20px" class="col-5">
                            <li>Open Corporates link</li>
                        </div>
                         
                            <br>
                            <div v-if="this.mycompaniesDetails.bb6==='Denmark'" class="col-5">
                            <a class="nav-link" style="padding: 0px;" :href="searchevents(this.labels,'http','uriopencorporates') + 'dk/' + this.mycompaniesDetails.b2" target="_blank">
                            <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.b2}} @ OpenCorporates DK</h6> </a>
                            </div>
                           <div v-if="this.mycompaniesDetails.cofo1==2" class="col-5">
                            <a class="nav-link" style="padding: 0px;" :href="searchevents(this.labels,'http','uriopencorporates') + 'gb/' + this.mycompaniesDetails.b2" target="_blank">
                            <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.b2}} @ OpenCorporates UK </h6> </a>
                            </div>

                        <!--AGGIUNTA URI VAT CHECK REGISTRATE DANIMARCA--------- -->  
                        
                         <div v-if="this.mycompaniesDetails.bb6==='Denmark'"  style="margin-left:20px" class="col-5">
                            <li>{{searchevents(this.labels,'dialog','vatcheckitdialog')}} DK</li>
                         </div>
                       
                          <div v-if="this.mycompaniesDetails.bb6==='Denmark'"  class="col-5">
                            <a class="nav-link" style="padding: 0px;" :href="searchevents(this.labels,'http','vatcheckdk')" target="_blank">
                            <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.b2}} @ VAT CHECK</h6> </a>
                          </div>

                          
                           <!--AGGIUNTA VAT REGISTRATE Non ITALIAne--------- --> 
                         <div v-if="this.mycompaniesDetails.bb6!=='Italy'"  style="margin-left:20px" class="col-5">
                            <li>{{searchevents(this.labels,'businessCard','b4')}}</li>
                         </div>
                           <!--AGGIUNTA URI VAT CHECK REGISTRATE ITALIA--------- --> 
                         <div v-if="this.mycompaniesDetails.bb6==='Italy'"  style="margin-left:20px" class="col-5">
                            <li>{{searchevents(this.labels,'dialog','vatcheckitdialog')}} IT</li>
                         </div>
                        <!-- partita iva non italiane -->
                          <div v-if="this.mycompaniesDetails.bb6!=='Italy'"  class="col-5">
                           <h6>{{this.mycompaniesDetails.b4}}</h6>
                          </div>
                           <!-- link dinamico - da valutare se mantenere check P.iva o lasciarlo solo se lingua visual ITAliano -->
                          <div v-if="this.mycompaniesDetails.bb6==='Italy'"  class="col-5">
                            <a class="nav-link" style="padding: 0px;" :href="searchevents(this.labels,'http','vatcheckit')" target="_blank">
                            <h6 style="text-decoration:underline;color:#0275d8"> CHECK {{this.mycompaniesDetails.b4}} </h6> </a>
                          </div>
                        
                     </div>   

                    <div class="row" >  <!-- ROW -->
                      <div class="col-12">
                        <hr>
                      </div>
                                        
                    </div> 
                    <!-- SIC E ATECO IMPRESE REGISTRATE --------- -->

                  <div class="row" v-if="this.mycompaniesDetails.cofo1==2">  <!-- ROW -->
                    <div class="col-6">
                      SIC#1
                    </div>
                    <div class="col-6">
                      <div >{{this.mycompaniesDetails.nts1}}</div>
                    </div> 
                  </div> <!-- --------- -->
                  <div class="row" v-if="this.mycompaniesDetails.cofo1==2">  <!-- ROW -->
                      <div class="col-12">
                        <hr>
                      </div>
                    </div>
                  <div class="row" v-if="this.mycompaniesDetails.cofo1==2">  <!-- ROW -->
                    <div class="col-6">
                      SIC#2
                    </div>
                    <div class="col-6">
                      <div >{{this.mycompaniesDetails.nts2}}</div>
                    </div> 
                  </div> <!-- --------- -->
                  <div class="row" v-if="this.mycompaniesDetails.cofo1==2">  <!-- ROW -->
                      <div class="col-12">
                        <hr>
                      </div>
                    </div>
                  <div class="row" v-if="this.mycompaniesDetails.cofo1==2">  <!-- ROW -->
                    <div class="col-6">
                      SIC#3
                    </div>
                    <div class="col-6">
                      <div >{{this.mycompaniesDetails.nts3}}</div>
                    </div> 
                  </div> 
                  <!-- ---------fine SIC e INIZIO ATECO NACE -->
                  <div class="row" v-if="this.mycompaniesDetails.cofo1==2">  <!-- ROW -->
                      <div class="col-12">
                        <hr>
                      </div>
                    </div>
                  <div class="row" style="margin-top: 20px;">  <!-- ROW -->
                    <div class="col-6"> 
                      <div v-if="lang=='Ita'">ATECO#1</div><br>
                      <div v-if="lang!='Ita'">NACE#1</div><br>
                    </div>
                    <div class="col-6">
                      <div v-if="lang=='Ita'">{{this.mycompaniesDetails.at1}}</div>
                      <div v-if="lang=='Eng'">{{this.mycompaniesDetails.at11}}</div>
                      <div v-if="lang=='Den'">{{this.mycompaniesDetails.at111}}</div>
                    </div>
                  </div> <!-- --------- -->
                  <div class="row" >  <!-- ROW -->
                      <div class="col-12">
                        <hr>
                      </div>
                    </div>
                  <div class="row" >  <!-- ROW -->
                    <div class="col-6">
                      <div v-if="lang=='Ita'">ATECO#2</div><br>
                      <div v-if="lang!='Ita'">NACE#2</div><br>
                    </div>
                    <div class="col-6">
                      <div v-if="lang=='Ita'">{{this.mycompaniesDetails.at2}}</div>
                      <div v-if="lang=='Eng'">{{this.mycompaniesDetails.at22}}</div>
                      <div v-if="lang=='Den'">{{this.mycompaniesDetails.at222}}</div>
                    </div>
                  </div> <!-- --------- -->
                  <div class="row" >  <!-- ROW -->
                      <div class="col-12">
                        <hr>
                      </div>
                    </div>
                  <div class="row" >  <!-- ROW -->
                    <div class="col-6">
                      <div v-if="lang=='Ita'">ATECO#3</div><br>
                      <div v-if="lang!='Ita'">NACE#3</div><br>
                    </div>
                    <div class="col-6">
                      <div v-if="lang=='Ita'">{{this.mycompaniesDetails.at3}}</div>
                      <div v-if="lang=='Eng'">{{this.mycompaniesDetails.at33}}</div>
                      <div v-if="lang=='Den'">{{this.mycompaniesDetails.at333}}</div>
                    </div>
                  </div> <!-- --------- -->

                </div>

              </div>

              <div class="row" style="margin-top:10px;" v-if="this.mycompaniesDetails.cofo1==100">
                <div class="col-4">
                  <div class="row">
                    <div class="col-6">
                      SIC#1 <br>
                      <div >{{this.mycompaniesDetails.at1}}</div>
                    </div>
                  </div>
                 
                </div>
                <div class="col-4">
                  <div class="row">
                    <div class="col-6">
                      SIC#2 <br>
                      <div >{{this.mycompaniesDetails.at2}}</div>
                    </div>
                    
                  </div>
                </div>
                <div class="col-4">
                  <div class="row">
                    <div class="col-6">
                      SIC#3 <br>
                      <div >{{this.mycompaniesDetails.at3}}</div>
                    </div>
                   
                  </div>
                </div>

              </div>

              <div class="row" style="margin-top:10px;" v-if="this.mycompaniesDetails.cofo1==100">
                <div class="col-4">
                  <div class="row">
                    <div class="col-12">
                      <div v-if="mycompaniesDetails.companyTable=='company_infocamere'">ATECO#1</div><br>
                      <div v-if="mycompaniesDetails.companyTable!='company_infocamere'">NACE#1</div><br>

                      <div v-if="lang=='Ita'">{{this.mycompaniesDetails.at1}}</div>
                      <div v-if="lang=='Eng'">{{this.mycompaniesDetails.at11}}</div>
                      <div v-if="lang=='Den'">{{this.mycompaniesDetails.at111}}</div>
                    </div>
                  </div>
                 
                </div>
                <div class="col-4">
                  <div class="row">
                    <div class="col-12">
                      <div v-if="mycompaniesDetails.companyTable=='company_infocamere'">ATECO#2</div><br>
                      <div v-if="mycompaniesDetails.companyTable!='company_infocamere'">NACE#2</div><br>

                      <div v-if="lang=='Ita'">{{this.mycompaniesDetails.at2}}</div>
                      <div v-if="lang=='Eng'">{{this.mycompaniesDetails.at22}}</div>
                      <div v-if="lang=='Den'">{{this.mycompaniesDetails.at222}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="row">
                    <div class="col-12">
                      <div v-if="mycompaniesDetails.companyTable=='company_infocamere'">ATECO#3</div><br>
                      <div v-if="mycompaniesDetails.companyTable!='company_infocamere'">NACE#3</div><br>

                      <div v-if="lang=='Ita'">{{this.mycompaniesDetails.at3}}</div>
                      <div v-if="lang=='Eng'">{{this.mycompaniesDetails.at33}}</div>
                      <div v-if="lang=='Den'">{{this.mycompaniesDetails.at333}}</div>
                    </div>
                  </div>
                </div>

              </div>
                    <!-- inizio ulteriori informazioni vetrina registrate-->
                <hr>
                  <div class="row" style="margin-top:40px; text-align: center;">
                    <div class="col" style="text-align: center;">
                      <h3><strong>{{searchevents(this.labels,'businessCard','furtherinformation')}}</strong></h3>
                    </div>
                  </div>
              <br>
                  <div  style="margin-left:5px"  class="row">
                    <div class="col-6" >
                      <h5><strong><i class="fa fa-briefcase" aria-hidden="true"></i> {{searchevents(this.labels,'companies','registeredofficelabel')}}</strong></h5>
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                            <li>{{searchevents(this.labels,'companies','officeRegion')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.bb6}}
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                            <li>{{searchevents(this.labels,'companies','officeDistrict')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.b7}}
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                            
                                <li>{{searchevents(this.labels,'companies','officeCity')}}</li>
                              </div>
                              <div class="col-3">
                                {{this.mycompaniesDetails.b8}}
                              </div>
                            
                        <div class="col-3">
                            
                                <li>{{searchevents(this.labels,'companies','officeTown')}}</li>
                              </div>
                              <div class="col-3">
                                {{this.mycompaniesDetails.b9}}
                              
                        </div> 
                      </div> <!-- --------- -->


                      
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                            
                          <li>{{searchevents(this.labels,'companies','officeAddress')}}</li>
                        </div>
                        <div class="col-3">
                          {{this.mycompaniesDetails.b11}}
                              
                        </div>
                        <div class="col-3">
                            
                          <li>{{searchevents(this.labels,'companies','officePostcode')}}</li>
                        </div>
                        <div class="col-3">
                          {{this.mycompaniesDetails.b10}}
                             
                        </div> 
                      </div> <!-- --------- -->


                    </div>


                    <div class="col-6">
                      <h5><strong><i class="fa fa-id-card" aria-hidden="true"></i> {{searchevents(this.labels,'businessCard','contacts')}}</strong></h5>
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','webSite')}}</li>
                        </div>
                       <!-- link web cliccabile per vetrina registrate --------- -->
                        <div class="col-6">
                            <a class="nav-link" style="padding: 0px;"  :href="getFormattedURL(this.mycompaniesDetails.s1)" rel="external nofollow" target="_blank">
                            <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.s1}} </h6> </a>
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','linkedin')}}</li>
                        </div>
                        <div class="col-6">
                          <a class="nav-link" style="padding: 0px;"  :href="getFormattedURL(this.mycompaniesDetails.s2)" rel="external nofollow" target="_blank">
                           <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.s2}} </h6> </a>
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','twitter')}}</li>
                        </div>
                        <div class="col-6">
                          <a class="nav-link" style="padding: 0px;"  :href="getFormattedURL(this.mycompaniesDetails.s3)" rel="external nofollow" target="_blank">
                           <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.s3}} </h6> </a>
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','facebook')}}</li>
                        </div>
                        <div class="col-6">
                          <a class="nav-link" style="padding: 0px;"  :href="getFormattedURL(this.mycompaniesDetails.s4)" rel="external nofollow" target="_blank">
                           <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.s4}}  </h6> </a>
                        </div> 
                      </div> <!-- --------- -->
                    </div>
                  </div>

                  <!-- sedi operative registrate-->
                  <div v-if="this.mycompaniesDetails.companyTable=='companies'" class="row" style="margin-left:5px;margin-top:20px">
                    <div class="col-6">
                      <h5><strong><i class="fa fa-briefcase" aria-hidden="true"></i> {{searchevents(this.labels,'companies','registeredoperationallabel')}}</strong></h5>
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','operationalCountry')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.operationalCountry}}
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                            <li>{{searchevents(this.labels,'companies','operationalRegion')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.op2}}
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                              <li>{{searchevents(this.labels,'companies','operationalCity')}}</li>
                              </div>
                              <div class="col-3">
                                {{this.mycompaniesDetails.operationalCity}}
                              </div>
                            
                        <div class="col-3">
                                <li>{{searchevents(this.labels,'companies','operationalTown')}}</li>
                              </div>
                              <div class="col-3">
                                {{this.mycompaniesDetails.op4}}
                              
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                          <li>{{searchevents(this.labels,'companies','operationalAddress')}}</li>
                        </div>
                        <div class="col-3">
                          {{this.mycompaniesDetails.op6}}
                              
                        </div>
                        <div class="col-3">
                            
                          <li>{{searchevents(this.labels,'companies','operationalPostcode')}}</li>
                        </div>
                        <div class="col-3">
                          {{this.mycompaniesDetails.op5}}
                             
                        </div> 
                      </div> <!-- --------- -->


                    </div>


                    <div class="col-6">
                      <h5><strong><i class="fa fa-users" aria-hidden="true"></i> {{searchevents(this.labels,'businessCard','corporategroup')}}</strong></h5>
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','partOfGroup')}}</li>
                        </div>
                        <div class="col-6">
                           <i v-if="this.mycompaniesDetails.partOfGroup=='N' && this.lang=='Ita'" >No</i>
                           <i v-if="this.mycompaniesDetails.partOfGroup=='Y' && this.lang=='Ita'" >Sì</i>

                           <i v-if="this.mycompaniesDetails.partOfGroup=='N' && this.lang=='Eng'" >No</i>
                           <i v-if="this.mycompaniesDetails.partOfGroup=='Y' && this.lang=='Eng'" >Yes</i>

                           <i v-if="this.mycompaniesDetails.partOfGroup=='N' && this.lang=='Den'" >Nej</i>
                           <i v-if="this.mycompaniesDetails.partOfGroup=='Y' && this.lang=='Den'" >Ja</i>
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','groupName')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.groupName}}
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','groupIdTax')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.groupIdTax}}
                        </div> 
                      </div> <!-- --------- -->
                      
                    </div>
                  </div>

                  <div v-if="this.mycompaniesDetails.companyTable=='companies'" class="row" style="margin-top:20px;  margin-left:10px; ">
                    <div class="col-6">
                      <h5><strong><i class="fa fa-globe" aria-hidden="true"></i> {{searchevents(this.labels,'menu','companycountrylist')}}</strong></h5>
                      
                      <div class="row" v-for="row in this.mycompaniesDetailsCountries" :key="row.id" > <!-- ROW -->
                        <div class="col-12">
                            <li>{{row.cnam1}}</li>
                        </div>
                        
                      </div> <!-- --------- -->

                    </div>


                    <div class="col-6">
                      <h5><strong> {{searchevents(this.labels,'companies','companyNotes')}}</strong></h5>
                      
                      <div class="row" > <!-- ROW -->
                        <div class="col-12">
                            {{this.mycompaniesDetails.companyNotes}}
                        </div>
                        
                      </div> <!-- --------- -->

                    </div>
                  </div>
<hr>
                  <div v-if="this.mycompaniesDetails.companyTable=='companies'" class="row" style="margin-top:40px; text-align: center;">
                    <div class="col" style="text-align: center;">
                      <h3><strong>{{searchevents(this.labels,'businessCard','productslist')}}</strong></h3>
                    </div>
                  </div>

    <!-- documenti generati dal backend
    0 Visura (PDF)
    1 Visura sostitutiva/Alternate certificate (PDF)
    2 Passaporto/Passport (PDF)
    3 Carta identità/Identity card (PDF)
    4 Carta identità cartacea/Old Identity card (PDF)
    5 Logo (JPG)
    6 Video (MP4)
    7 Catalogo (PDF)
    8 Immagine promozionale/Promotional image 10 (JPG)
    9 Immagine promozionale/Promotional image 9 (JPG)
    10 Immagine promozionale/Promotional image 8 (JPG)
    11 Immagine promozionale/Promotional image 7 (JPG)
    12 Immagine promozionale/Promotional image 6 (JPG)
    13 Immagine promozionale/Promotional image 5 (JPG)
    14 Immagine promozionale/Promotional image 4 (JPG)
    15 Immagine promozionale/Promotional image 3 (JPG)
    16 Immagine promozionale/Promotional image 2 (JPG)
    17 Immagine promozionale/Promotional image 1 (JPG)
    18 Altro società/Other company (JPG)
    19 Altro/Other (JPG)
    20 Patente/Driving license (JPG)
    -->

                        <div v-if="this.mycompaniesDetails.companyTable=='companies'" class="row" style="margin-top:40px; text-align: center; margin-left: 10px; margin-right: 10px;" >
                        <div class="col-4" v-if="this.mycompaniesDetailsDocs[6].name!=''" > <!-- video --> 
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <video v-if="this.mycompaniesDetailsDocs[6].name!=''" width="320" height="240" controls> <source v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[6].name" type="video/mp4"> </video> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[6].description3}}
                            </div></div>
                        </div>
                        <div class="col-2" v-if="this.mycompaniesDetailsDocs[7].name!=''" > <!-- catalogo --> 
                            <div class="row" style="text-align:center;">
                              <div class="col" style="text-align:center;" >
                               <button v-if="this.mycompaniesDetailsDocs[7].name!=''" @click.prevent="downloadItem( remoteServer + '/docs/doc_imprese/' + mycompaniesDetailsDocs[7].name , mycompaniesDetailsDocs[7].description)"  >{{this.mycompaniesDetailsDocs[7].description}}</button>
                              </div></div>
                              
                        </div>
                        <div class="col-2" v-if="this.mycompaniesDetailsDocs[8].name!=''" > <!-- immagine --> 
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[8].name!=''" @click.prevent="downloadItemIMG( remoteServer + '/docs/media/' + mycompaniesDetailsDocs[8].name , mycompaniesDetailsDocs[8].description)"  style="cursor:pointer; max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[8].name" /> 
                              </div></div>
                              <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                              {{this.mycompaniesDetailsDocs[8].description3}}
                              </div></div>
                        </div>
                        
                        <div class="col-2" v-if="this.mycompaniesDetailsDocs[9].name!=''" > <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[9].name!=''" @click.prevent="downloadItemIMG( remoteServer + '/docs/media/' + mycompaniesDetailsDocs[9].name , mycompaniesDetailsDocs[9].description)" style="cursor:pointer; max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[9].name" /> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[9].description3}}
                            </div></div>
                        </div>
                        <div class="col-2" v-if="this.mycompaniesDetailsDocs[10].name!=''" > <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[10].name!=''" @click.prevent="downloadItemIMG( remoteServer + '/docs/media/' + mycompaniesDetailsDocs[10].name , mycompaniesDetailsDocs[10].description)" style="cursor:pointer; max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[10].name" /> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[10].description3}}
                            </div></div>
                        </div>
                        <div class="col-2" v-if="this.mycompaniesDetailsDocs[11].name!=''"  > <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[11].name!=''" @click.prevent="downloadItemIMG( remoteServer + '/docs/media/' + mycompaniesDetailsDocs[11].name , mycompaniesDetailsDocs[11].description)" style="cursor:pointer; max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[11].name" /> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[11].description3}}
                            </div></div>
                        </div>
                        <div class="col-2" v-if="this.mycompaniesDetailsDocs[12].name!=''" > <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[12].name!=''" @click.prevent="downloadItemIMG( remoteServer + '/docs/media/' + mycompaniesDetailsDocs[12].name , mycompaniesDetailsDocs[12].description)" style="cursor:pointer; max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[12].name" /> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[12].description3}}
                            </div></div>
                        </div>
                        <div class="col-2" v-if="this.mycompaniesDetailsDocs[13].name!=''"> <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[13].name!=''" @click.prevent="downloadItemIMG( remoteServer + '/docs/media/' + mycompaniesDetailsDocs[13].name , mycompaniesDetailsDocs[13].description)" style="cursor:pointer; max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[13].name" /> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[13].description3}}
                            </div></div>
                        </div>
                        <div class="col-2" v-if="this.mycompaniesDetailsDocs[14].name!=''" > <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[14].name!=''" @click.prevent="downloadItemIMG( remoteServer + '/docs/media/' + mycompaniesDetailsDocs[14].name , mycompaniesDetailsDocs[14].description)" style="cursor:pointer; max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[14].name" /> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[14].description3}}
                            </div></div>
                        </div>
                        <div class="col-2" v-if="this.mycompaniesDetailsDocs[15].name!=''" > <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[15].name!=''" @click.prevent="downloadItemIMG( remoteServer + '/docs/media/' + mycompaniesDetailsDocs[15].name , mycompaniesDetailsDocs[15].description)" style="cursor:pointer; max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[15].name" /> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[15].description3}}
                            </div></div>
                        </div>
                        <div class="col-2" v-if="this.mycompaniesDetailsDocs[16].name!=''" > <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[16].name!=''" @click.prevent="downloadItemIMG( remoteServer + '/docs/media/' + mycompaniesDetailsDocs[16].name , mycompaniesDetailsDocs[16].description)" style="cursor:pointer; max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[16].name" /> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[16].description3}}
                            </div></div>
                        </div>
                        <div class="col-2" v-if="this.mycompaniesDetailsDocs[17].name!=''" > <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[17].name!=''" @click.prevent="downloadItemIMG( remoteServer + '/docs/media/' + mycompaniesDetailsDocs[17].name , mycompaniesDetailsDocs[17].description)" style="cursor:pointer; max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[17].name" /> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[17].description3}}
                            </div></div>
                        </div>
                        
                        

                  </div>
                  
<hr>
            </div>
<br>
<br>

     <!-- TASTI e VETRINA  - aziende REGISTRATE GOBIZ -->
     <!-- Prima parte dei BOTTONI IINIZIA QUI -->
  <h3><strong>{{searchevents(this.labels,'businessCard','networking')}}</strong></h3>
  <table class="table tabella-vetrina">
  <thead>
    <tr>
      <th scope="col"></th>
       <!-- col 2 --> 
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <!-- inizio RIGA 1-->
    <tr>
      <th scope="row"></th>
      <!-- R1 C1 --> 
      <td style="max-width: 80px; text-align: center;">
        <!-- aggiungi ai favoriti --> 
         <button v-if="this.getcompanyNetworkBusinessCard=='Y'" type="button" class="btn btn-vetrina" style="margin-top:10px;font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px;" @click="businessCardAction('addtomyfavorite',mycompaniesDetails.thisId,mycompaniesDetails.guid, mycompaniesDetails.companyTable, getcompanyScoreBusinessCard, mycompaniesDetails.emailContact)"><span class="fa fa-star-o" style="font-size:1.1em;color:#fff;width: 100%;" >{{searchevents(this.labels,'businessCard','addtomyfavorite')}}</span></button>
         <button v-if="this.getcompanyNetworkBusinessCard!='Y'" type="button" class="btn btn-vetrina-gri" style="margin-top:10px;font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px;" disabled><span class="fa fa-star-o" style="font-size:1.1em;width: 100%;" > {{searchevents(this.labels,'businessCard','addtomyfavorite')}}</span></button>
      </td>
     <!-- R1 C2 -->
     <td style="max-width: 80px; text-align: center;"> 
      <!-- invia messaggio --> 
      <button v-if="this.getXcompanyMessageBusinessCard=='Y' && mycompaniesDetails.servCompaniesStateCompaniesState!='H' " type="button" class="btn btn-vetrina" style= "margin-top:10px;font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px; " data-dismiss="modal" @click="businessCardAction('sendmessage',mycompaniesDetails.thisId,mycompaniesDetails.guid, mycompaniesDetails.companyTable, getcompanyScoreBusinessCard, mycompaniesDetails.emailContact)"><span class="fa fa-envelope-o" style="font-size:1.1em;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'businessCard','sendmessage')}}</span></button>
      <button v-if="this.getXcompanyMessageBusinessCard!='Y' || mycompaniesDetails.servCompaniesStateCompaniesState=='H'" type="button" class="btn btn-vetrina-gri" style="margin-top:10px;font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px;  " data-dismiss="modal" disabled><span class="fa fa-envelope-o" style="font-size:1.1em;width: 100%;" > {{searchevents(this.labels,'businessCard','sendmessage')}}</span></button>
      </td>
     <!-- R1 C3 -->


       <!-- richiedi accesso visura disabilitato se azienda gobiz e visura non richiesta-->
     <td style="max-width: 80px; text-align: center;" >
     
      <div  v-if="this.mycompaniesDetails.canviewvisura!=null && this.mycompaniesDetails.cofo1==1 ">
            <!-- richiedi permesso visura -->
            <button type="button" class="btn btn-vetrina-gri" style="margin-top:10px;font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px; " disabled>
              <span class="fa fa-info-circle" style="font-size:15px;width: 100%;" > {{searchevents(this.labels,'businessCard','requestseevesura')}}</span></button> 
             <br>{{searchevents(this.labels,'businessCard','requestseevesuralabel')}} 
      </div>
      <!--  richiedi accesso visura se azienda gobiz-->
       
      <div  v-if="this.mycompaniesDetails.canviewvisura==null && this.mycompaniesDetails.cofo1==1 && this.getXcompanyMessageBusinessCard=='Y'">
        <!-- richiedi permesso visura -->
        <button type="button" data-dismiss="modal" class="btn btn-vetrina" 
        style="margin-top:10px;font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px;" 
        @click="businessCardAction('requestseevesura',mycompaniesDetails.thisId,mycompaniesDetails.guid, mycompaniesDetails.companyTable, getcompanyScoreBusinessCard, mycompaniesDetails.emailContact)">
        <span class="fa fa-info-circle" style="font-size:1.1em;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'businessCard','requestseevesura')}}</span></button> 
        <div style="margin-left:15%;max-width:70%;">  {{searchevents(this.labels,'businessCard','requestseevesuralabel')}} </div>
       </div>
        </td>
       
       <!--fine  -->
       <!-- fine R1 C3 -->

     <!-- R1 C4 -->
      <td></td>
    </tr>
 
    <!-- inizio RIGA 2-->
    <tr>
     <th scope="row"></th>
       <!-- R2 C1 -->
      <td>
      </td>
     <!--R2 C2 -->
      <td>
      </td>
      <!-- R2 C3 -->
      <td>
      </td>
      <!-- R2 C4 -->
      <td>
      </td>
    
    </tr>

     <!-- inizio RIGA 3-->
    <tr>
      <th scope="row"></th>
      <!-- R3 C1 -->
      <td style="max-width: 100px;" >
       </td>
      <!-- R3 C2 -->
      <td>
      </td>
      <!-- R3 C3 -->
      <td>
     
      </td>
      <!-- R3 C4 -->
      <td>
      </td>
    </tr>

 <!-- inizio RIGA 4-->
   <tr>
      <th scope="row"></th>
       <!-- R4 C1 -->
      <td>
      
      </td>
     <!--R4 C2 -->
      <td>
        
      </td>

      <!-- R4 C3 -->
      <td>
       
      </td>

       <!-- R4 C4 -->
      <td>

      </td>
    
    </tr>
   
 <!-- inizio RIGA 5-->
 <tr>
      <th scope="row"></th>
     <!-- R5 C1 --> 
      <td style="max-width: 100px; text-align: center;"> 
        <div v-if="this.mycompaniesDetails.cofo1!=1 || (this.mycompaniesDetails.canviewvisura!=null && this.mycompaniesDetails.cofo1==1)">
        <!-- tasto scarica visura certificata caso 1--> 
                      <button v-if="this.mycompaniesDetails.cofo1==1" type="button" class="btn btn-vetrina" style="margin-top:10px;font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px; " @click="reportDownloadVisura(mycompaniesDetails.thisId)" ><a class="nav-link" style="padding: 0px;" :href="remoteServer + '/docs/doc_imprese/' + this.mycompaniesDetailsDocs[0].name" target="_blank"  ><span class="fa fa-info-circle" style="font-size:1.1em;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'businessCard','downloadvisuraext')}} </span></a></button> 
                      <button v-if="this.mycompaniesDetails.cofo1!=1" type="button" class="btn btn-vetrina" style="margin-top:10px;font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px; " @click="reportDownloadVisura(mycompaniesDetails.thisId)" ><a class="nav-link" style="padding: 0px;" :href="remoteServer + '/docs/doc_imprese/' + this.mycompaniesDetailsDocs[0].name" target="_blank"  ><span class="fa fa-info-circle" style="font-size:1.1em;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'businessCard','downloadvisuraext')}} </span></a></button> 
                    <div  v-if="this.mycompaniesDetails.cofo1==1"> 
                      <!-- descrizione scarica visura certificata con flag alla fine--> 
                      {{searchevents(this.labels,'businessCard','downdownloadvisura')}} <br> {{returnHumanDate(this.mycompaniesDetailsDocs[0].updatedAt)}} <span class="fa fa-check-square" style="font-size:1.1em;color: #0c294b;;width: 100%; display: inline;" ></span>
                    </div>
                    <div v-if="this.mycompaniesDetails.cofo1!=1">
                      {{searchevents(this.labels,'businessCard','downdownloadvisura')}} <br> {{returnHumanDate(this.mycompaniesDetailsDocs[0].updatedAt)}} <span class="fa fa-check-square" style="font-size:1.1em;color: #0c294b; width: 100%; display: inline;" ></span>
                    </div>
        </div>

      <!-- tasto scarica visura certificata caso 2-->
          <div  v-if="this.mycompaniesDetails.cofo1==1 && this.mycompaniesDetails.canviewvisura==null">
            <button v-if="this.mycompaniesDetails.cofo1==1" type="button" class="btn btn-vetrina-gri" style="margin-top:10px;font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px;" disabled ><a class="nav-link" style="padding: 0px;" target="_blank"  ><span class="fa fa-info-circle" style="font-size:1.1em;width: 100%;color:#ccd3da" > {{searchevents(this.labels,'businessCard','downloadvisuraext')}} </span></a></button>
            <button v-if="this.mycompaniesDetails.cofo1!=1" type="button" class="btn btn-vetrina-gri" style="margin-top:10px;font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px;" disabled ><a class="nav-link" style="padding: 0px;" target="_blank"  ><span class="fa fa-info-circle" style="font-size:1.1em;width: 100%;color:#ccd3da" > {{searchevents(this.labels,'businessCard','downloadvisuraext')}} </span></a></button> 
        <!-- testo scarica visura certificata con flag - eliminato margin-left: 15% -->
            <div v-if="this.mycompaniesDetails.cofo1==1">
            <span class="fa fa-check-square" style="font-size:1.1em;color:#0c294b;width: 100%;display: inline;" ></span>  {{searchevents(this.labels,'businessCard','downdownloadvisura')}} <br> {{returnHumanDate(this.mycompaniesDetailsDocs[0].updatedAt)}} 
            </div>
      
            <div v-if="this.mycompaniesDetails.cofo1!=1">
            <span class="fa fa-check-square" style="font-size:1.1em;color:#0c294b;width: 100%;display: inline;" ></span> {{searchevents(this.labels,'businessCard','downdownloadvisura')}} <br> {{returnHumanDate(this.mycompaniesDetailsDocs[0].updatedAt)}}
            </div>

          </div>
          
      </td>

      <!--fine R5 C1 -->

    


     <!-- R5 C2 -->

      <!-- GOBIZ ITALIANE -->
      <td style="max-width: 100px; text-align: center;">
                 <div  v-if="this.mycompaniesDetails.canviewvisura!=null && this.mycompaniesDetails.newrequest==null && this.mycompaniesDetails.cofo1==1 && this.getXcompanyMessageBusinessCard=='Y' ">
                  <!-- richiedi visura aggiornata bottone attivo -->
                 <button type="button" class="btn btn-vetrina" style="margin-top:10px; font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px;" @click="businessCardAction('reqvisura',mycompaniesDetails.thisId,mycompaniesDetails.guid, mycompaniesDetails.companyTable, getcompanyScoreBusinessCard, mycompaniesDetails.emailContact)"><span class="fa fa-info-circle" style="font-size:1.1em;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'businessCard','sendglobal')}}</span></button> 
                 <br>
                  <!-- descrizione richiedi visura più recente#1-->
                 <div  style="margin-left:12%;max-width:75%;"> {{searchevents(this.labels,'businessCard','downsendglobal')}} </div>
                 </div>

                <div v-if="( ((this.mycompaniesDetails.canviewvisura==null || this.mycompaniesDetails.newrequest!=null)) || (this.getXcompanyMessageBusinessCard=='N' )) && this.mycompaniesDetails.cofo1==1  ">
                  <!-- richiedi visura aggiornata bottone grigio disattivato-->
                   <button type="button" class="btn btn-vetrina-gri" style="margin-top:10px;font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px; " disabled><span class="fa fa-info-circle" style="font-size:1.1em;width: 100%;" > {{searchevents(this.labels,'businessCard','sendglobal')}}</span></button> 
                    <br>
                    <!-- descrizione richiedi visura più recente#2-->
                     <div style="margin-left:12%;max-width:75%;"> {{searchevents(this.labels,'businessCard','downsendglobal')}} </div>
                   
                </div>
          <!-- FINE GOBIZ ITALIANE -->

         <!-- GOBIZ STRANIERE --> 
                <div  v-if="this.mycompaniesDetails.cofo1!=1 && this.getXcompanyMessageBusinessCard=='Y' ">
                  <!-- richiedi visura aggiornata -->
                     <button type="button" class="btn btn-vetrina" style="margin-top:10px;font-size: 1.1em; min-width: 130px; max-width: 160px; min-height: 50px; max-height: 80px; " @click="businessCardAction('reqvisura',mycompaniesDetails.thisId,mycompaniesDetails.guid, mycompaniesDetails.companyTable, getcompanyScoreBusinessCard, mycompaniesDetails.emailContact)"><span class="fa fa-info-circle" style="font-size:1.1em;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'businessCard','sendglobal')}}</span></button> 
                     <br>
                      <!-- descrizione richiedi visura più recente#2-->
                   <div style="margin-left:12%;max-width:75%;">   {{searchevents(this.labels,'businessCard','downsendglobal')}} </div>
                </div>
        <!-- FINE GOBIZ STRANIERE --> 
      
      </td>
      
       <!-- R5 C3 -->
      <td>
      </td>
      <!-- R5 C3 -->
      <td></td>
     <!-- R5 C4 -->
      <td></td>
    </tr>

<tr>
   <!-- R6 non usata -->
      <th scope="row"></th>
       <!-- R6 C1 -->
      <td>
           
      </td>
      
       <!-- R6 C2 -->
      <td>
        </td>
      <!-- R6 C3 -->
      <td></td>
     <!-- R6 C4 -->
      <td></td>
</tr>

<!-- Prima parte dei BOTTONI FINISCE QUI -->

  </tbody>
</table>
            
            <!-- Modal footer registrate PARTE ELIMINATA-->
            <div class="modal-footer"  style="height: 80px">
   
            </div>
            
          </div>
        </div>
      </div> <!-- fine modal Gobiz -->






<!-- documenti generati dal backend
    0 Visura (PDF)
    1 Visura sostitutiva/Alternate certificate (PDF)
    2 Passaporto/Passport (PDF)
    3 Carta identità/Identity card (PDF)
    4 Carta identità cartacea/Old Identity card (PDF)
    5 Logo (JPG)
    6 Video (MP4)
    7 Catalogo (PDF)
    8 Immagine promozionale/Promotional image 10 (JPG)
    9 Immagine promozionale/Promotional image 9 (JPG)
    10 Immagine promozionale/Promotional image 8 (JPG)
    11 Immagine promozionale/Promotional image 7 (JPG)
    12 Immagine promozionale/Promotional image 6 (JPG)
    13 Immagine promozionale/Promotional image 5 (JPG)
    14 Immagine promozionale/Promotional image 4 (JPG)
    15 Immagine promozionale/Promotional image 3 (JPG)
    16 Immagine promozionale/Promotional image 2 (JPG)
    17 Immagine promozionale/Promotional image 1 (JPG)
    18 Altro società/Other company (JPG)
    19 Altro/Other (JPG)
    20 Patente/Driving license (JPG)
    -->

<!-- Modale non registrate -->
<!-- EXT non registrate---- -->
<div class="modal" id="myBusinessCardExt" ref="myBusinessCardExt" style="top:100px; padding-bottom: 200px;"  >
        <div class="modal-dialog" style="max-width: 75% !important;">
          <div class="modal-content">
          
            <!-- Modal Header vetrina non registrate-->
            <div class="modal-header">
              <div style="float:left;"><span class="fa fa-check-square fa-2x mr-2 align-self-center" style="color:#f8ab19;margin-left: 10px;" ></span></div>
                          
              <!-- <div style="float: left; line-height: 16px; margin-left: 10px; margin-top: 5px;" v-html="mycompaniesDetails.dbtext"></div> -->
              <div style="float: left; line-height: 16px; margin-left: 10px; margin-top: 5px; width: 150px;" v-if="mycompaniesDetails.companyTable=='company_house'" v-html="searchevents(this.labels,'companies','companycompanyhouse')"></div>
              <div style="float: left; line-height: 16px; margin-left: 10px; margin-top: 5px; width: 150px;" v-if="mycompaniesDetails.companyTable=='company_infocamere'" v-html="searchevents(this.labels,'companies','companyinfocamere')"></div>
              <div style="float: left; line-height: 16px; margin-left: 10px; margin-top: 5px; width: 150px;" v-if="mycompaniesDetails.companyTable=='company_denmark'" v-html="searchevents(this.labels,'companies','companycvr')"></div>
              <div style="width: 70%; text-align: center;">
                <h3 class="modal-title">{{this.mycompaniesDetails.a1}}</h3> 
                <div >
               <h5>{{this.mycompaniesDetails.b6}} / {{this.mycompaniesDetails.b8}} </h5>
                </div>
              </div>
              <button type="button" class="close" data-dismiss="modal" >&times;</button>
            </div>
            
            <!-- Modal body non registrate-->
            <div class="modal-body">
              <!--
              <div class="row">
                <div class="col" style="text-align: center;">
                   <img v-if="this.mycompaniesDetailsDocs[4].name!=''" style="max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[4].name" /> 
                </div>
              </div>
              -->
              <div class="row" style="margin-top:10px;">
                <div class="col-6">
                  <h3><strong>{{searchevents(this.labels,'businessCard','businessoverview')}}</strong></h3>
                  <div style="margin-left:30px" >
                    <h5><li>{{searchevents(this.labels,'companies','companyActivityDescription')}}</li></h5>
                    <p >{{this.mycompaniesDetails.a2}}</p>
                    
                        <!-- aggiunta traduzione descrizione attività non registrate-->
                        <div class="col-5">

                          <button type="button" class="btn btn-success minpadding" 
                              style="font-size: 0.95em; float: left; height: 25px; width: 135%;"
                                @click="translateGoogleNew(mycompaniesDetails.a2)" >{{searchevents(labels,'businessCard','translateActivityDescription')}}
                        </button>
                       </div>
                       <br>
                       <br>
                       <p style="float: left;width: 90%;" v-html="translateActivityDescription"></p>

                  </div>
                </div>
                
                   
               <!-- vetrina non registrate - es nazione -->

                <div class="col-6">
                  <h3><strong>{{searchevents(this.labels,'businessCard','companydatamain')}}</strong></h3>
                  <div class="row"> <!-- ROW -->
                      <div style="margin-left:20px" class="col-5">
                          <li>{{searchevents(this.labels,'businessCard','b1')}}</li>
                      </div>
                      <div class="col-5">
                        {{this.mycompaniesDetails.b6}}
                      </div> 
                    </div> <!-- --------- -->
                    <div class="row"> <!-- ROW -->
                        <div style="margin-left:20px" class="col-5">
                            <li>{{searchevents(this.labels,'businessCard','b2')}}</li>
                        </div>
                        <div class="col-5">
                            {{this.mycompaniesDetails.b2}}
                        </div> 
                    </div> 
                    <!-- --------- -->
                    <div class="row"> <!-- ROW -->
                        <div style="margin-left:20px" class="col-5">
                            <li>{{searchevents(this.labels,'businessCard','b3')}}</li>
                        </div>
                        <div class="col-5">
                            {{this.mycompaniesDetails.b3}}
                        </div> 
                    </div> <!-- --------- -->
                    <div class="row"> <!-- ROW -->
                        <div style="margin-left:20px" class="col-5">
                            <li>{{searchevents(this.labels,'businessCard','b12')}} / {{searchevents(this.labels,'businessCard','by12')}}</li>
                        </div>
                      
                        
                          <div  class="col-5">
                            {{this.mycompaniesDetails.b12}} /  {{this.mycompaniesDetails.by12}}
                        </div>
                      
                        
                    </div> 
                    <!-- --------- -->
                    <!--
                    <div class="row"> 
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'businessCard','cat1')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.cat1}}
                        </div> 
                    </div>  -->
                    <div class="row"> <!-- ROW -->
                        <div style="margin-left:20px" class="col-5">
                            <li>{{searchevents(this.labels,'businessCard','f1')}} / {{searchevents(this.labels,'businessCard','by12')}}</li>
                        </div>
                       
                        <div class="col-5">
                            {{this.mycompaniesDetails.f1}} / {{this.mycompaniesDetails.fy1}}
                        </div>
                        
                    </div> 
                    <!-- vetrina non registrate - es anno di costituzione -->
                    <!-- --------- -->
                    <div class="row"> <!-- ROW -->
                        <div style="margin-left:20px"  class="col-5">
                            <li>{{searchevents(this.labels,'businessCard','inc1')}}</li>
                        </div>
                        <div class="col-5">
                            {{returnYear(this.mycompaniesDetails.inc1)}}
                        </div> 
                    </div> <!-- --------- -->

                <!-- AGGIUNTA URI non registrate-->
                      <div class="row"> <!-- ROW -->
                        <div v-if="this.mycompaniesDetails.b6==='Denmark' || this.mycompaniesDetails.cofo1==2"  style="margin-left:20px" class="col-5">
                            <li>Company Link (URI)</li>
                        </div>
                       
                         <div v-if="this.mycompaniesDetails.cofo1==2"   class="col-5" >
                          <a class="nav-link" style="padding: 0px;" :href="'http://business.data.gov.uk/id/company/' + this.mycompaniesDetails.b2" target="_blank">
                           <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.b2}} @ Company House </h6> </a>
                         </div>
                         <div v-if="this.mycompaniesDetails.b6==='Denmark'"  class="col-5">
                            <a class="nav-link" style="padding: 0px;" :href="'https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=' + this.mycompaniesDetails.b2 + '&q=visenhed&language=en-gb'" target="_blank">
                            <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.b2}} @ CVR </h6> </a>
                         </div>
                         
                        

                        <!-- AGGIUNTA URI OPENCORPORATES non registrate-->
                        <div v-if="this.mycompaniesDetails.b6==='Denmark' || this.mycompaniesDetails.cofo1==2" style="margin-left:20px" class="col-5">
                            <li>Open Corporates link</li>
                        </div>
                                 <br>
                            <div v-if="this.mycompaniesDetails.b6==='Denmark'"  class="col-5">
                            <a class="nav-link" style="padding: 0px;" :href="'https://opencorporates.com/companies/dk/' + this.mycompaniesDetails.b2" target="_blank">
                            <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.b2}} @ OpenCorporates DK</h6> </a>
                            </div>
                           <div v-if="this.mycompaniesDetails.cofo1==2"  class="col-5">
                            <a class="nav-link" style="padding: 0px;" :href="'https://opencorporates.com/companies/gb/' + this.mycompaniesDetails.b2" target="_blank">
                            <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.b2}} @ OpenCorporates UK </h6> </a>
                            </div>

                             <!-- AGGIUNTA VAT CHECK non registrate DK-->
                        
                         <div v-if="this.mycompaniesDetails.b6==='Denmark'"  style="margin-left:20px" class="col-5">
                            <li>VAT check DK</li>
                         </div>
                       
                          <div v-if="this.mycompaniesDetails.b6==='Denmark'"  class="col-5">
                            <a class="nav-link" style="padding: 0px;" :href="searchevents(this.labels,'http','vatcheckdk')" target="_blank">
                            <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.b4}} @ VAT CHECK</h6> </a>
                          </div>
                        
                           <!--AGGIUNTA URI VAT CHECK non registrate ITALIA--------- -->  
                        
                         <div v-if="this.mycompaniesDetails.b6==='Italia'"  style="margin-left:20px" class="col-5">
                            <li>{{searchevents(this.labels,'dialog','vatcheckitdialog')}} IT</li>
                         </div>
                        <!--da valutare se mantenere check P.iva o lasciarlo solo se lingua visual ITAliano -->
                          <div v-if="this.mycompaniesDetails.b6==='Italia'" class="col-5">
                            <a class="nav-link" style="padding: 0px;" :href="searchevents(this.labels,'http','vatcheckit')" target="_blank">
                            <h6 style="text-decoration:underline;color:#0275d8"> CHECK {{this.mycompaniesDetails.b4}} </h6> </a>
                          </div>

                    </div> <!-- --------- -->

     <!-- integrazione per ricerca google -->               
                          
                    <hr>
                    <h6 v-html="searchevents(this.labels,'dialog','companylike')"></h6>
    <tbody>
    <tr>
      <th scope="row"> </th>
      <!-- R1 C1 matchmaking --> 
      <td> 

      <div v-if="this.getXcompanyMessageBusinessCard=='Y' && this.mycompaniesDetails.MM=='1'">
      <button type="button" class="btn btn-vetrina"  style="margin-top:10px;font-size: 1.05em; min-width: 120px; min-height: 50px; max-height: 140px;max-width: 65%" data-dismiss="modal" @click="businessCardAction('invite',mycompaniesDetails.thisId,mycompaniesDetails.guid, mycompaniesDetails.companyTable, getcompanyScoreBusinessCard, mycompaniesDetails.emailContact)"><span class="fa fa-envelope-o" style="font-size:1.05em;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'matchmaking','invite')}}</span></button>
      <br>             
       <a class="btn btn-primary" style="margin-top:10px;font-size: 1.05em; min-width: 120px; min-height: 50px; max-height: 140px;max-width: 65%" :href="'https://www.google.com/search?q='+this.mycompaniesDetails.a1+' '+this.mycompaniesDetails.b8" target="_blank"><span class="fa fa-search" style="font-size:1.05em;color:#0c294b;width: 100%;vertical-align: middle;"> <span style="font-weight:normal"> {{searchevents(this.labels,'dialog','companysearchgoogle')}}</span></span></a>
       <!-- <a href="https://www.google.com" class="btn btn-vetrina" role="button" style="margin-top:10px;font-size: 1.05em; min-width: 140px; min-height: 50px; max-height: 140px;" target="_blank" rel="noopener"><span span class="fa fa-search" style="font-size:1.05em;color:#ffffff;width: 100%;" >{{searchevents(this.labels,'dialog','companysearchgoogle')}}</span></a> -->
      </div>
      
      <button v-if="this.getXcompanyMessageBusinessCard!='Y' " type="button" class="btn btn-vetrina-gri"  style="margin-top:10px;font-size: 1.05em; min-width: 140px; min-height: 50px; max-height: 140px;" data-dismiss="modal" disabled><span class="fa fa-envelope-o" style="font-size: 1.05em;width: 100%;" > {{searchevents(this.labels,'matchmaking','invite')}}</span></button>
      </td>
     <!-- R1 C2 -->

     <!-- R1 C3 -->
   
     </tr>
 
    </tbody>

 
                  <div class="row" >  <!-- ROW -->
                    <div class="col-12">
                      <hr>
                    </div>
                    
                  </div> <!-- --------- -->


                  <div class="row" v-if="this.mycompaniesDetails.cofo1!=1">  <!-- ROW -->
                    <div class="col-6">
                      SIC#1
                    </div>
                    <div class="col-5">
                      <div >{{this.mycompaniesDetails.ats1}}</div>
                    </div> 
                  </div> <!-- --------- -->
                  <div class="row" v-if="this.mycompaniesDetails.cofo1==2">  <!-- ROW -->
                      <div class="col-12">
                        <hr>
                      </div>
                  </div>
                  <div class="row" v-if="this.mycompaniesDetails.cofo1!=1">  <!-- ROW -->
                    <div class="col-6">
                      SIC#2
                    </div>
                    <div class="col-5">
                      <div >{{this.mycompaniesDetails.ats2}}</div>
                    </div> 
                  </div> <!-- --------- -->
                  <div class="row" v-if="this.mycompaniesDetails.cofo1==2">  <!-- ROW -->
                      <div class="col-12">
                        <hr>
                      </div>
                    </div>
                  <div class="row" v-if="this.mycompaniesDetails.cofo1!=1">  <!-- ROW -->
                    <div class="col-6">
                      SIC#3
                    </div>
                    <div class="col-5">
                      <div >{{this.mycompaniesDetails.ats3}}</div>
                    </div> 
                  </div> <!-- --------- -->
                  <div class="row" v-if="this.mycompaniesDetails.cofo1==2">  <!-- ROW -->
                      <div class="col-12">
                        <hr>
                      </div>
                    </div>
                  <div class="row" v-if="this.mycompaniesDetails.cofo1==1 || this.mycompaniesDetails.cofo1==2">  <!-- ROW -->
                    <div class="col-6">
                      <div v-if="lang=='Ita'">ATECO#1</div><br>
                      <div v-if="lang!='Ita'">NACE#1</div><br>
                    </div>
                    <div class="col-5">
                      <div v-if="lang=='Ita'">{{this.mycompaniesDetails.at1}}</div>
                      <div v-if="lang=='Eng'">{{this.mycompaniesDetails.at11}}</div>
                      <div v-if="lang=='Den'">{{this.mycompaniesDetails.at111}}</div>
                    </div>
                  </div> <!-- --------- -->
                  <div class="row" >  <!-- ROW -->
                      <div class="col-12">
                        <hr>
                      </div>
                    </div>
                  <div class="row" v-if="this.mycompaniesDetails.cofo1==1 || this.mycompaniesDetails.cofo1==2">  <!-- ROW -->
                    <div class="col-6">
                      <div v-if="lang=='Ita'">ATECO#2</div><br>
                      <div v-if="lang!='Ita'">NACE#2</div><br>
                    </div>
                    <div class="col-5">
                      <div v-if="lang=='Ita'">{{this.mycompaniesDetails.at2}}</div>
                      <div v-if="lang=='Eng'">{{this.mycompaniesDetails.at22}}</div>
                      <div v-if="lang=='Den'">{{this.mycompaniesDetails.at222}}</div>
                    </div>
                  </div> <!-- --------- -->
                  <div class="row" >  <!-- ROW -->
                      <div class="col-12">
                        <hr>
                      </div>
                    </div>
                  <div class="row" v-if="this.mycompaniesDetails.cofo1==1 || this.mycompaniesDetails.cofo1==2">  <!-- ROW -->
                    <div class="col-6">
                      <div v-if="lang=='Ita'">ATECO#3</div><br>
                      <div v-if="lang!='Ita'">NACE#3</div><br>
                    </div>
                    <div class="col-5">
                      <div v-if="lang=='Ita'">{{this.mycompaniesDetails.at3}}</div>
                      <div v-if="lang=='Eng'">{{this.mycompaniesDetails.at33}}</div>
                      <div v-if="lang=='Den'">{{this.mycompaniesDetails.at333}}</div>
                    </div>
                  </div> <!-- --------- -->


                </div>

              </div>

              <div class="row" style="margin-top:10px;" v-if="this.mycompaniesDetails.cofo1==100">
                <div class="col-4">
                  <div class="row">
                    <div class="col-6">
                      SIC#1 <br>
                      <div >{{this.mycompaniesDetails.at1}}</div>
                    </div>
                  </div>
                 
                </div>
                <div class="col-4">
                  <div class="row">
                    <div class="col-6">
                      SIC#2 <br>
                      <div >{{this.mycompaniesDetails.at2}}</div>
                    </div>
                    
                  </div>
                </div>
                <div class="col-4">
                  <div class="row">
                    <div class="col-6">
                      SIC#3 <br>
                      <div >{{this.mycompaniesDetails.at3}}</div>
                    </div>
                   
                  </div>
                </div>

              </div>

              <div class="row" style="margin-top:10px;" v-if="this.mycompaniesDetails.cofo1==100">
                <div class="col-4">
                  <div class="row">
                    <div class="col-12">
                      <div v-if="lang=='Ita'">ATECO#1</div><br>
                      <div v-if="lang!='Ita'">NACE#1</div><br>

                      <div v-if="lang=='Ita'">{{this.mycompaniesDetails.at1}}</div>
                      <div v-if="lang=='Eng'">{{this.mycompaniesDetails.at11}}</div>
                      <div v-if="lang=='Den'">{{this.mycompaniesDetails.at111}}</div>
                    </div>
                  </div>
                 
                </div>
                <div class="col-4">
                  <div class="row">
                    <div class="col-12">
                      <div v-if="lang=='Ita'">ATECO#2</div><br>
                      <div v-if="lang!='Ita'">NACE#2</div><br>

                      <div v-if="lang=='Ita'">{{this.mycompaniesDetails.at2}}</div>
                      <div v-if="lang=='Eng'">{{this.mycompaniesDetails.at22}}</div>
                      <div v-if="lang=='Den'">{{this.mycompaniesDetails.at222}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="row">
                    <div class="col-12">
                      <div v-if="lang=='Ita'">ATECO#3</div><br>
                      <div v-if="lang!='Ita'">NACE#3</div><br>

                      <div v-if="lang=='Ita'">{{this.mycompaniesDetails.at3}}</div>
                      <div v-if="lang=='Eng'">{{this.mycompaniesDetails.at33}}</div>
                      <div v-if="lang=='Den'">{{this.mycompaniesDetails.at333}}</div>
                    </div>
                  </div>
                </div>

              </div>
               
                  <div v-if="this.mycompaniesDetails.companyTable=='companies'" class="row" style="margin-top:40px; text-align: center;">
                    <div class="col" style="text-align: center;">
                      <h3>{{searchevents(this.labels,'businessCard','productslist')}}</h3>
                    </div>
                  </div>

                  
                  <div v-if="this.mycompaniesDetails.companyTable=='companies'" class="row" style="margin-top:40px; text-align: center; margin-left: 10px; margin-right: 10px;" >
                        <div class="col-4"  > <!-- video -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <video v-if="this.mycompaniesDetailsDocs[5].name!=''" width="320" height="240" controls> <source v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[5].name" type="video/mp4"> </video> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[5].description3}}
                            </div></div>
                        </div>
                        <div class="col-2"  > <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[6].name!=''" style="max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[6].name" /> 
                              </div></div>
                              <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                              {{this.mycompaniesDetailsDocs[6].description3}}
                              </div></div>
                        </div>
                        <div class="col-2"  > <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[7].name!=''" style="max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[7].name" />  
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[7].description3}}
                            </div></div>
                        </div>
                        <div class="col-2" > <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[8].name!=''" style="max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[8].name" /> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[8].description3}}
                            </div></div>
                        </div>
                        <div class="col-2" > <!-- immagine -->
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                               <img v-if="this.mycompaniesDetailsDocs[9].name!=''" style="max-width: 200px;" v-bind:src="remoteServer +  '/docs/media/' + this.mycompaniesDetailsDocs[9].name" /> 
                            </div></div>
                            <div class="row" style="text-align:center;"><div class="col" style="text-align:center;">
                            {{this.mycompaniesDetailsDocs[9].description3}}
                            </div></div>
                        </div>
                        
                        
                    </div>


                       <!-- inizio ulteriori informazioni vetrina non registrate -->
                    <hr>
                    <div class="row" style="margin-top:40px; text-align: center;">
                    <div class="col" style="text-align: center;">
                      <h3><strong>{{searchevents(this.labels,'businessCard','furtherinformation')}}</strong></h3>
                    </div>
                  </div>

                  <div style="margin-left:5px" class="row">
                    <div class="col-6" >
                      <h5><strong><i class="fa fa-briefcase" aria-hidden="true"></i> {{searchevents(this.labels,'companies','registeredofficelabel')}}</strong></h5>
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                            <li>{{searchevents(this.labels,'companies','officeRegion')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.b6}}
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                            <li>{{searchevents(this.labels,'companies','officeDistrict')}}</li>
                        </div>
                        <div class="col-6"> 
                           {{this.mycompaniesDetails.b7}}
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                            
                                <li>{{searchevents(this.labels,'companies','officeCity')}}</li>
                              </div>
                              <div class="col-3">
                                {{this.mycompaniesDetails.b8}}
                              </div>
                            
                        <div class="col-3">
                            
                                <li>{{searchevents(this.labels,'companies','officeTown')}}</li>
                              </div>
                              <div class="col-3">
                                {{this.mycompaniesDetails.b9}}
                              
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                            
                          <li>{{searchevents(this.labels,'companies','officeAddress')}}</li>
                        </div>
                        <div class="col-3">
                          {{this.mycompaniesDetails.b11}}
                              
                        </div>
                        <div class="col-3">
                            
                          <li>{{searchevents(this.labels,'companies','officePostcode')}}</li>
                        </div>
                        <div class="col-3">
                          {{this.mycompaniesDetails.b10}}
                             
                        </div> 
                      </div> <!-- --------- -->


                    </div>


                    <div class="col-6">
                       <h5><strong><i class="fa fa-id-card" aria-hidden="true"></i> {{searchevents(this.labels,'businessCard','contacts')}}</strong></h5>
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','webSite')}}</li>
                        </div>
                         <!-- link web cliccabile per vetrina non registrate --------- -->
                        <div class="col-6">
                           <!--  <a class="nav-link" style="padding: 0px;" :href="searchevents(this.labels,'http','httpsprefix') + this.mycompaniesDetails.s1" target="_blank"> -->
                             <a class="nav-link" style="padding: 0px;" :href="searchevents(this.labels,'http','httpprefix') + this.mycompaniesDetails.s1" rel="external nofollow" target="_blank">
                            <h6 style="text-decoration:underline;color:#0275d8"> {{this.mycompaniesDetails.s1}} </h6> </a>
                        </div> 
                      </div> 
                                    
                    </div>
                  </div>

                  <!-- sedi operative non registrate--> 
                  <div v-if="this.mycompaniesDetails.companyTable=='companies'" class="row" style="margin-top:20px; margin-left:5px;">
                    <div class="col-6">
                      <h2><i class="fa fa-briefcase" aria-hidden="true"></i> {{searchevents(this.labels,'companies','registeredoperationallabel')}}</h2>
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','operationalCountry')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.operationalCountry}}
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','operationalRegion')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.op2}}
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                            
                                <li>{{searchevents(this.labels,'companies','operationalCity')}}</li>
                              </div>
                              <div class="col-3">
                                {{this.mycompaniesDetails.operationalCity}}
                              </div>
                            
                        <div class="col-3">
                            
                                <li>{{searchevents(this.labels,'companies','operationalTown')}}</li>
                              </div>
                              <div class="col-3">
                                {{this.mycompaniesDetails.op4}}
                              
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-3">
                            
                          <li>{{searchevents(this.labels,'companies','operationalAddress')}}</li>
                        </div>
                        <div class="col-3">
                          {{this.mycompaniesDetails.op6}}
                              
                        </div>
                        <div class="col-3">
                            
                          <li>{{searchevents(this.labels,'companies','operationalPostcode')}}</li>
                        </div>
                        <div class="col-3">
                          {{this.mycompaniesDetails.op5}}
                             
                        </div> 
                      </div> <!-- --------- -->


                    </div>


                    <div class="col-6">
                      <h2><i class="fa fa-users" aria-hidden="true"></i> {{searchevents(this.labels,'businessCard','corporategroup')}}</h2>
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','partOfGroup')}}</li>
                        </div>
                        <div class="col-6"> {{this.lang}} ---
                           <i v-if="this.mycompaniesDetails.partOfGroup=='N' && this.lang=='Ita'" >No</i>
                           <i v-if="this.mycompaniesDetails.partOfGroup=='Y' && this.lang=='Ita'" >Sì</i>

                           <i v-if="this.mycompaniesDetails.partOfGroup=='N' && this.lang=='Eng'" >No</i>
                           <i v-if="this.mycompaniesDetails.partOfGroup=='Y' && this.lang=='Eng'" >Yes</i>

                           <i v-if="this.mycompaniesDetails.partOfGroup=='N' && this.lang=='Den'" >Nej</i>
                           <i v-if="this.mycompaniesDetails.partOfGroup=='Y' && this.lang=='Den'" >Ja</i>
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','groupName')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.groupName}}
                        </div> 
                      </div> <!-- --------- -->
                      <div class="row"> <!-- ROW -->
                        <div class="col-6">
                            <li>{{searchevents(this.labels,'companies','groupIdTax')}}</li>
                        </div>
                        <div class="col-6">
                            {{this.mycompaniesDetails.groupIdTax}}
                        </div> 
                      </div> <!-- --------- -->
                      
                    </div>
                  </div>

                  <div v-if="this.mycompaniesDetails.companyTable=='companies'" class="row" style="margin-top:20px;">
                    <div class="col-6">
                      <h2><i class="fa fa-globe" aria-hidden="true"></i> {{searchevents(this.labels,'menu','companycountrylist')}}</h2>
                      
                      <div class="row" v-for="row in this.mycompaniesDetailsCountries" :key="row.id" > <!-- ROW -->
                        <div class="col-12">
                            <li>{{row.cnam1}}</li>
                        </div>
                        
                      </div> <!-- --------- -->

                    </div>


                    <div class="col-6">
                      <h2> {{searchevents(this.labels,'companies','companyNotes')}}</h2>
                      
                      <div class="row" > <!-- ROW -->
                        <div class="col-12">
                            {{this.mycompaniesDetails.companyNotes}}
                        </div>
                        
                      </div> <!-- --------- -->

                    </div>
                  </div>
               <hr>   

            </div>
     
       <br>
             
 
            <!-- SECONDA PARTE BOTTONI - RIPARTONO DA QUI aggiunta pre footer - NETWORKING E TASTI aziende non registrate -->
 
 <h3><strong>Networking</strong></h3>
 <br>
  <table class="table tabella-vetrina">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row"> </th>
      <!-- R1 C1 matchmaking --> 
      <td> 
      <button v-if="this.getXcompanyMessageBusinessCard=='Y' && this.mycompaniesDetails.MM=='1'" type="button" class="btn btn-vetrina"  style="margin-top:10px;font-size: 1.05em; min-width: 140px; max-width: 200px; min-height: 80px; max-height: 140px;" data-dismiss="modal" @click="businessCardAction('invite',mycompaniesDetails.thisId,mycompaniesDetails.guid, mycompaniesDetails.companyTable, getcompanyScoreBusinessCard, mycompaniesDetails.emailContact)"><span class="fa fa-envelope-o" style="font-size:1.05em;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'matchmaking','invite')}}</span></button>
     <!-- modificato per inviare sempre email di invito a tutti 15/02/2021 -->
     <!-- <button v-if="this.getXcompanyMessageBusinessCard=='Y' && (this.mycompaniesDetails.emailContact!='' || this.mycompaniesDetails.dbtext=='Company House')" type="button" class="btn btn-warning" style="font-size: .9em; min-width: 180px; margin-right:20px;" data-dismiss="modal" @click="businessCardAction('invite',mycompaniesDetails.thisId,mycompaniesDetails.guid, mycompaniesDetails.companyTable, getcompanyScoreBusinessCard, mycompaniesDetails.emailContact)"><span class="fa fa-envelope-o" style="font-size:15px;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'matchmaking','invite')}}</span></button> -->
     <!-- <button v-if="this.getXcompanyMessageBusinessCard=='Y' && this.mycompaniesDetails.emailContact=='' && this.mycompaniesDetails.dbtext!='Company House' " type="button" class="btn btn-warning" style="background-color: grey; font-size: .9em; min-width: 180px; margin-right:20px;" data-dismiss="modal" disabled><span class="fa fa-envelope-o" style="font-size:15px;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'matchmaking','invite')}}</span></button> -->
      <button v-if="this.getXcompanyMessageBusinessCard!='Y' " type="button" class="btn btn-vetrina-gri"  style="margin-top:10px;font-size: 1.05em; min-width: 140px; max-width: 200px; min-height: 80px; max-height: 140px;" data-dismiss="modal" disabled><span class="fa fa-envelope-o" style="font-size: 1.05em;width: 100%;" > {{searchevents(this.labels,'matchmaking','invite')}}</span></button>
      </td>
     <!-- R1 C2 -->
      <td> 
        <!-- non registrate aggiungi al network  -->
        <button v-if="this.getcompanyNetworkBusinessCard=='Y'" type="button" class="btn btn-vetrina"  style="margin-top:10px;font-size: 1.05em; min-width: 140px; max-width: 200px; min-height: 80px; max-height: 140px;margin-right:10px;" @click="businessCardAction('addtomyfavorite',mycompaniesDetails.thisId,mycompaniesDetails.guid, mycompaniesDetails.companyTable, getcompanyScoreBusinessCard, mycompaniesDetails.emailContact)"><span class="fa fa-star-o" style="font-size: 1.05em;color:#ffffff;width: 100%;" > {{searchevents(this.labels,'businessCard','addtomyfavorite')}}</span></button>
        <button v-if="this.getcompanyNetworkBusinessCard!='Y'" type="button" class="btn btn-vetrina-gri"  style="margin-top:10px;font-size: 1.05em; min-width: 140px; max-width: 200px; min-height: 80px; max-height: 140px;margin-right:10px;" disabled><span class="fa fa-star-o" style="font-size: 1.05em;width: 100%;" > {{searchevents(this.labels,'businessCard','addtomyfavorite')}}</span></button>       
      </td>
     <!-- R1 C3 -->
      <td> 
      <!-- scarica visura -->
      <button type="button" class="btn btn-vetrina-gri" style="margin-top:10px;font-size: 1.05em; min-width: 140px; max-width: 200px; min-height: 80px; max-height: 140px;" disabled><span class="fa fa-info-circle" style="font-size: 1.05em; width: 100%;" > {{searchevents(this.labels,'businessCard','downloadvisura')}} </span></button>       
      <br>
      <!-- {{searchevents(this.labels,'businessCard','downdownloadvisura')}} -->
      </td>
     
      <td></td>
     </tr>
 
  </tbody>
</table>
           
            <!-- Modal footer non registrate NON PIU' usata-->

            <div class="modal-footer" style="height: 100px">
               <div class="row" style="width:90%;" >          
              </div>
            </div>
             <!-- SECONDA PARTE BOTTONI FINISCE QUI -->

          </div>
        </div>
      </div> <!-- fine modal Ext -->

      <!-- modale COOKIES -->
      <div class="modal" id="myCookieModal" style="top:20% !important;" >
        <div class="modal-dialog" style="max-width: 50% !important;">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title"><i class="fas fa-cookie fa-1x mr-2 align-self-center"></i>
                 {{searchevents(this.labels,'dialog','cookietitle')}} 
                </h4>
              
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
              
              <div style="text-align: center; margin-left:10%;margin-right:10%">
                <span v-html="searchevents(this.labels,'dialog','cookieacceptancetext')"></span>
                <h6 v-if="this.lang=='Ita'"> <a style="text-decoration:underline;color:#005298" :href="remoteServer + '/servicefiles/Privacy&Cookie_IT.pdf' " target="_blank"> {{searchevents(this.labels,'dialog','clickhere')}}</a>  {{searchevents(this.labels,'dialog','seeprivacy')}}</h6>
                <h6 v-if="this.lang=='Eng'"> <a style="text-decoration:underline;color:#005298" :href="remoteServer + '/servicefiles/Privacy&Cookie_EN.pdf' " target="_blank"> {{searchevents(this.labels,'dialog','clickhere')}}</a>  {{searchevents(this.labels,'dialog','seeprivacy')}}</h6>
                <h6 v-if="this.lang=='Den'"> <a style="text-decoration:underline;color:#005298" :href="remoteServer + '/servicefiles/Privacy&Cookie_DK.pdf' " target="_blank"> {{searchevents(this.labels,'dialog','clickhere')}}</a>  {{searchevents(this.labels,'dialog','seeprivacy')}}</h6>
               <span v-html="searchevents(this.labels,'dialog','cookieacceptancetext2')"></span>
              <!-- target= '_blank' -->
              </div>
              <div v-if="personalizeCookieOpenClose==true">
                <hr>
                <div class="row">
                  <div class="col-4"  v-html="searchevents(this.labels,'dialog','cookieacceptanalytics')">
                  </div>
                  <div class="col-4">
                    <label class="switch">
                      <input type="checkbox" v-model="acceptGoogleCookies">
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div class="col-4">
                    {{searchevents(this.labels,'dialog','cookieaccepttypesoptionals')}} 
                  </div>
                </div>
              </div>
              
            </div>
            
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-success" style="font-size: 1.7em" @click="customizeCookies()">{{searchevents(this.labels,'dialog','cookiecustomizebtn')}}</button>
               <div v-if="personalizeCookieOpenClose==true">
              <button type="button" class="btn btn-bigdark" style="font-size: 1.7em" data-dismiss="modal" @click="acceptCookies()">{{searchevents(this.labels,'dialog','cookieacceptancebtn')}}</button>
             </div>
             <div v-if="personalizeCookieOpenClose==false">
              <button type="button" class="btn btn-bigdark" style="font-size: 1.7em" data-dismiss="modal" @click="acceptAllCookies()">{{searchevents(this.labels,'dialog','cookieacceptallbtn')}}</button>
              </div>              
            </div>
            
          </div>
        </div>
      </div>
      <!-- fine modal ecookies -->


      <button id="openModalExt" ref="openModalExt" type="button" class="btn btn-success " style="visibility: hidden; font-size: 0.8em;" data-toggle="modal" data-target="#myBusinessCardExt" >YO</button>
      <button id="openModalGobiz" ref="openModalGobiz" type="button" class="btn btn-success " style="visibility: hidden; font-size: 0.8em;" data-toggle="modal" data-target="#myBusinessCardGobiz" >YO</button>

      <button id="openModalCookiesGobiz" ref="openModalCookiesGobiz" type="button" class="btn btn-success " style="visibility: hidden; font-size: 0.8em;" data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#myCookieModal" >YO</button>

</div>

</template>

<script>

import router from '@/router';
import gAxios from 'axios';

//import { setOptions } from 'vue-gtag';
import {bootstrap} from 'vue-gtag';

export default {
  name: 'navigator',
  data (){
    return {
      labelsLocal : this.$langLabelsGlobal,
      filterValue : 'menu',
      loginL : '--',
      remoteServer : process.env.VUE_APP_CONN_NODEJS,
      userStored : [],
      updatedCertificate : [],
      companyRegisteredGoBiz : [],
      registeredCompany : null,
      MyCompanyId : null,
      companyName	: '',
      confirmAccept : 'Confermi la accettazione? / Confirm to accept?',
      confirmReject : 'Confermi la negazione? / Confirm to reject?',
      selectedCompanyTable : null,
      mycompaniesDetails : [],
      // array parte da 0 che è la visura pdf - 21 elementi gruppo massimo da backend
      mycompaniesDetailsDocs : [
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''}],
      mycompaniesDetailsCountries: [
      {"cnam1":''}],
      newmessage : null,
      amBanned : false,
      translateActivityDescription: '',
      acceptGoogleCookies : true,
      personalizeCookieOpenClose : false

      }
    
  },

  computed : {
    auth(){
      return this.$store.getters.isAuth;
    },
    lang(){
      return this.$store.getters.lang;
    },
    labels(){
      return this.$store.getters.labels;
    },
    getUserRole(){
      return this.$store.getters.getUserRole;
    },
    userIdentity(){
      return this.$store.getters.getUserNameSurname;
    },
    getUser(){
      return this.$store.getters.user;
    },
    getUserId(){
        return this.$store.getters.getUserId;
    },
    getPermittedUser(){
      return this.$store.getters.getPermittedUser;
    },
    getupdatedCertificate(){
      return this.$store.getters.getupdatedCertificate;
    },
    getcompanyRegisteredGoBiz(){
      return this.$store.getters.getcompanyRegisteredGoBiz;
    },

    getrequestseevisura(){
      return this.$store.getters.getrequestseevisura;
    },
    getokrequestseevisura(){
      return this.$store.getters.getokrequestseevisura;
    },
    getkorequestseevisura(){
      return this.$store.getters.getkorequestseevisura;
    },

    getregisteredCompany(){
      var rcomp = this.$store.getters.getregisteredCompany;
      if(rcomp){
        
        return rcomp;
      }else{ 
        return 0;
      }
    },
    getIdUsersParent(){
       // return null;
       return this.$store.getters.getIdUsersParent;
    },
    getcompanyIdBusinessCard(){
        return this.$store.getters.getcompanyIdBusinessCard;
    },
    getcompanyTableBusinessCard(){
      return this.$store.getters.getcompanyTableBusinessCard;
    },
    getcompanyScoreBusinessCard(){
      return this.$store.getters.getcompanyScoreBusinessCard;
    },
    getcompanyNetworkBusinessCard(){
        return this.$store.getters.getcompanyNetworkBusinessCard;
    },
    getXcompanyMessageBusinessCard(){
      
        return this.$store.getters.getXcompanyMessageBusinessCard;
    },
    getToken(){
      // return null;
      return this.$store.getters.getUserToken;
    },
    returnBellCount(){
      let bellNumber = 0;
            
      if(this.getupdatedCertificate.company !== undefined && this.getcompanyRegisteredGoBiz.company !== undefined && this.getrequestseevisura.company !== undefined && this.getokrequestseevisura.company !== undefined && this.getkorequestseevisura.company !== undefined){
        let updatedCertificate = this.getupdatedCertificate.company.length;
        let companyRegisteredGoBiz = this.getcompanyRegisteredGoBiz.company.length;

        let requestseevisura = this.getrequestseevisura.company.length;
        let okrequestseevisura = this.getokrequestseevisura.company.length;
        let korequestseevisura = this.getkorequestseevisura.company.length;
        
        bellNumber = updatedCertificate+companyRegisteredGoBiz+requestseevisura+okrequestseevisura+korequestseevisura;
      }
      
      return bellNumber;
    },
    returnBellupdatedCertificate(){
      let updatedCertificate = this.getupdatedCertificate.company;
      return updatedCertificate;
    },
    returnBellcompanyRegisteredGoBiz(){
      let companyRegisteredGoBiz = this.getcompanyRegisteredGoBiz.company;
      return companyRegisteredGoBiz;
    },
    returnBellrequestseevisura(){
      let requestseevisura = this.getrequestseevisura.company;
      return requestseevisura;
    },
    returnBellokrequestseevisura(){
      let okrequestseevisura = this.getokrequestseevisura.company;
      return okrequestseevisura;
    },
    returnBellkorequestseevisura(){
      let korequestseevisura = this.getkorequestseevisura.company;
      return korequestseevisura;
    },
  },
  methods : {
  getFormattedURL(url) {
    if (!/^https?:\/\//i.test(url)) {
      url = "http://" + url;
    }
    // Aggiungi i due punti dopo "http://" o "https://"
    url = url.replace(/^(https?):\/([^/]+)/i, "$1://$2");
    
    return url;
  },
    //getFormattedURL(url) {
    //if (!/^https?:\/\//i.test(url)) {
     // url = "http://" + url;
    //}
    // Aggiungi due punti dopo "https://" o "http://" se non sono presenti
    //url = url.replace(/^(https?:\/\/)([^/]+)/, (match, protocol, domain) => {
    //  return protocol + domain.replace(/^([a-z]+)([^a-z])/i, "$1:$2");
    //});
    
    //return url;
  //},
    downloadItemIMG ( url, label ) {
      // console.log(url + '--------' + label);
      gAxios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'image/jpeg' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(
          //console.error
          )
    },
    downloadItem ( url, label ) {
      // console.log(url + '--------' + label);
      gAxios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(
          //console.error
          )
    },
    reportDownloadVisura(companies_id_to){
      //console.log(companies_id_to+'<<<<<<<<<<<<<<<<<<<<<<<<')
      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/reportdownloadvisura',
                {
                    users_id_from : this.getUserId,
                    companies_id_from : this.MyCompanyId,
                    companies_id_to : companies_id_to
                },auth)
                
                .catch(
                    error => {
                    //console.log(error)
                    return error;
                    }
                );
    },
     customizeCookies(){
      if(this.personalizeCookieOpenClose==true){
        this.personalizeCookieOpenClose=false;
      }else{
        this.personalizeCookieOpenClose=true;
      }
    },
    acceptAllCookies(){
      //this.acceptGoogleCookies=true;
      this.acceptCookies();
    },
    acceptCookies(){
      if(this.acceptGoogleCookies==true){
        //Google analytics
        //imposto l'ID corretto - analytics ATTENZIONE cambiare ID PROD con // superato -->id: "UA-213188542-1"
        //this.$gtag.config({ id: 'UA-213188542-2' });
        // 
        //USARE  G-E600WMEGX5  per la produzione! oppure G-R217XPWD40 PER test
        //this.$gtag.config({ id: 'G-R217XPWD40'});
        //this.$gtag.config({ id: 'G-E600WMEGX5'});
       
        bootstrap().then(gtag => {
                    localStorage.setItem('coockieAccepted', true);
                    localStorage.setItem('GDPR:accepted', true);
                    window.location.href = "https://www.gobiz.be";
                    location.reload();
                    return gtag;
                })
//da verificare luglio 2023

      }else{
        // imposto un ID non utilizzabile, quindi disabilito il tracciamento
        
       bootstrap().then(gtag => {
                    localStorage.setItem('coockieAccepted', false);
                    localStorage.setItem('GDPR:accepted', false);
                    window.location.href = "https://www.gobiz.be";
                    location.reload();
                    return gtag;
                })
        
      }
      //console.log(setOptions.id);
      // scrivo che sono stati accettati i cookies
      
      //console.log(localStorage.getItem('GDPR:accepted'));
      //console.log('yooo');
    },
    resetTranslatedActivityDescription(){
      //console.log('aperta!!!!!!');
       this.translateActivityDescription = '';
    },
     //aggiunto traduttore
    translateGoogleNew(text){

        let texToTranslate = text;
        let langFrom = this.lang;
        let langTo = '';
        let textTranslated = '';
        if(langFrom == 'Ita'){ langFrom = 'auto'; langTo = 'it';}
        if(langFrom == 'Eng'){ langFrom = 'auto'; langTo = 'en'; }
        if(langFrom == 'Den'){ langFrom = 'auto'; langTo = 'da'; }
        
        gAxios.get('https://translate.googleapis.com/translate_a/single?client=gtx&sl='+langFrom+'&tl='+langTo+'&dt=t&q='+texToTranslate,
                {
                   
                })
                .then(res => {
                    let tempTextTranslated = res.data[0];
                    tempTextTranslated.forEach(element => {
                        textTranslated += element[0]+' ';
                        
                    });
                    //console.log(textTranslated);
                    this.translateActivityDescription = textTranslated;
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },

    returnHumanDate(dt){
      if(dt!=null){
        let newDate = '';
        newDate = dt.substring(0,10);
        newDate = newDate.split('-');
        if(this.lang=='Ita'){
          return newDate[2]+'/'+newDate[1]+'/'+newDate[0];
        }else{
          return newDate[1]+'/'+newDate[2]+'/'+newDate[0];
        }
        
      }
    },
    returnHumanDateEng(dt){
      if(dt!=null){
        let newDate = '';
        newDate = dt.substring(0,10);
        newDate = newDate.split('-');
        return newDate[1]+'c/'+newDate[2]+'/'+newDate[0];
      }
    },
    generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        
        return newGuid;
    },
    sendRequestSeeVisura(companyIdfromCard, guid, table){
        this.newmessage = 'Request to access to the Company Report';
        
        this.sendNewMessageBusinessCard(companyIdfromCard,guid, table, 'seevisura');
    },
    sendRequestVisura(companyIdfromCard, guid, table){
        this.newmessage = 'Request for an Updated Version of the Company Report';
        
        this.sendNewMessageBusinessCard(companyIdfromCard,guid, table, 'requestVisura');
    },
    amBannedOrNot(companyIdTo,table){
      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
      if(this.getToken!=undefined && this.getToken!=null){
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/bannedornot',
                {
                    companyIdFrom : this.MyCompanyId,
                    companyIdTo : companyIdTo,
                    companyTable : table,
                   
                },auth)
                .then(res => {
                  //console.log(res);
                   if(res.data.message=='OK'){
                     this.amBanned = false;
                   }else{
                     this.amBanned = true;
                   }
                })
                .catch(
                    error => {
                    //console.log(this.getToken);
                    return error;
                    }
                );
      }
      
    },
    sendNewMessageBusinessCard(companyIdfromCard,guid, table, actionFrom){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {
                let firstMessage = 'N';
                if(guid=='' || guid==null){ 
                  firstMessage = 'Y'; 
                  guid = this.generateUUID();
                }

                //console.log('actionFrom-------->'+actionFrom);
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    companyIdFrom : this.MyCompanyId,
                    companyIdTo : companyIdfromCard,
                    companyTable : table,
                    guid : guid,
                    firstMessage: firstMessage,
                    blockchainsHash : '',
                    message : this.newmessage,
                    messageTranslated : '',
                    chatId : '',
                    viewed: '0',
                    actionToDo : 'ADD'
                },auth)
                .then(res => {
                  if(actionFrom!='invite' && actionFrom!='seevisura' && actionFrom!='requestVisura'){
                    this.sendEmail(companyIdfromCard,'companies','SEND','',this.newmessage,'Y');
                    //console.log('cosaaaaaaaaaaaaaaaaaa')
                  }
                    
                    //this.setSelectedCompanyIdDetail(companyIdfromCard,'companies');
                    this.newmessage = null;
                    
                    if(actionFrom=='seevisura' || actionFrom=='requestVisura'){
                      if(this.lang=='Ita'){ alert('Richiesta inviata correttamente, ti invieremo una notifica non appena la Visura richiesta sarà disponibile!'); }
                      if(this.lang=='Eng'){ alert('Rquest sent, we will send you a notification as soon as the Company Report is available!'); }
                      if(this.lang=='Den'){ alert('Anmodningen er blevet sendt, vi sender en meddelelse, så snart den ønskede certificering er tilgængelig!'); }
                    }else{
                      if(this.lang=='Ita'){ alert('Operazione completata con successo'); }
                      if(this.lang=='Eng'){ alert('Successful operation!'); }
                      if(this.lang=='Den'){ alert('Succesfuld operation'); }
                    }
                    

                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
                
            }else{
              
                return null;
            }
    },
    addToMyNetwork(companyId,companyTable,score,action,emailAddressTo, messageYN){
        const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
        }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/NetworkJoinByMe',
        {
            userId : this.getUserId,
            companyId : companyId,
            companyTable : companyTable,
            score : score,
            myCompany : this.MyCompanyId,
            actionToDo : action
        },auth)
        .then(res => {
            // in base alla action passata, eseguo delle operazioni: aggiungo alla chat
            
            if(action=='INVITE'){
              this.newmessage = 'Qualcuno ti chiede di iscriverti a GoBiz / Someone asks you to sign up for GoBiz';

              this.sendEmailInvite(companyId,companyTable,'INVITE',emailAddressTo,this.newmessage,'N');
              this.sendNewMessageBusinessCard(companyId,null,companyTable,'invite');
            }else{
              
              this.sendEmail(companyId,'companies','ADD','','Qualcuno ti ha aggiunto al proprio network / Someone added you to their network','N');
            }

            
            
            if(messageYN=='Y' && action!='INVITE'){
                if(this.lang=='Ita'){ alert('Operazione completata con successo'); }
                if(this.lang=='Eng'){ alert('Successful operation!'); }
                if(this.lang=='Den'){ alert('Succesfuld operation'); }
            }

           
            //console.log('>'+this.$router.currentRoute.path.replace('/','') +'<' );
            let myRoutePath = this.$router.currentRoute.path.replace('/','');
            //console.log('>'+myRoutePath+'<' );
            //console.log(myRoutePath.includes('mynetwork') );
            if(myRoutePath.includes('mynetwork') ){
              this.$store.dispatch('setNeedNetworkRefresh','Y' );
              //this.getMyNetwork();
              //console.log('DISPACCIO!!!!');
              //router.push('/mynetwork');
              //this.$router.go(this.$router.currentRoute);
            }
            //this.getMyNetwork();

            return res+emailAddressTo+messageYN;
        })
        .catch(
        error => {
        
        return error;
        }
        );
    },
    setSelectedCompanyId(companyId,table,guid,id1,id2){
        
        let selectedCompanyId = companyId;
        if(id1!=null && id1!=''){ selectedCompanyId = id1; }
        if(id2!=null && id2!=''){ selectedCompanyId = id2; }
        if(guid==null){guid = '-1';}
        
        // esco e vado alla chat di questa azienda
        router.push( { name: 'mychats', params: { idCompany: selectedCompanyId, table: table, guid: guid, id1: id1, id2:id2 } } );
    },
    businessCardAction(action, companyId, guid, table, score, email){
      
      if(action=='addtomyfavorite'){
        // in ogni caso aggiungo ai preferiti, il server controlla se è già stata aggiunta
        
        this.addToMyNetwork(companyId,table,score,'ADD','','Y');
        
      }

      if(action=='sendmessage'){
        // in ogni caso aggiungo ai preferiti, il server controlla se è già stata aggiunta
        this.addToMyNetwork(companyId,table,score,'ADD','','N');

        // quest'operazione è possibile solo nelle pagine: area messaggi, network, match making
        //router.push('/mychats');
        this.setSelectedCompanyId(companyId,table,guid,null,null);
      }

      if(action=='reqvisura'){
        // quest'operazione è possibile solo nelle pagine: area messaggi, network, match making
        //console.log('ssssasassasasasasasasa');
        this.addToMyNetwork(companyId,table,score,'ADD','','N');
        this.sendRequestVisura(companyId,guid,table);
      }

      if(action=='requestseevesura'){
        // quest'operazione è possibile solo nelle pagine: area messaggi, network, match making
        this.addToMyNetwork(companyId,table,score,'ADD','','N');
        this.sendRequestSeeVisura(companyId,guid, table);
      }

      if(action=='invite'){
        
        this.addToMyNetwork(companyId,table,score,'INVITE',email,'Y')
      }

      //alert(action+'--'+companyId);
      return action+guid;
    },
    returnYear(dt){
        if(dt!=null){
        return dt.substring(0,4);
        }else{
          return '';
        }
      },
    setSelectedCompanyIdDetailFooter(companyId,table){
        
        this.translateActivityDescription = '';
        this.selectedCompanyTable = table;
        
        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                {
                   companyId : companyId,
                   action : table,
                   myuserId : this.getUserId,
                   myCompanyId : this.MyCompanyId
                },auth)
                .then(res => {
                    
                    this.mycompaniesDetails = res.data.company[0];
                    

                    if(table=='companies'){
                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_doc'
                        },auth)
                        .then(res1 => {
                            
                            // docs here
                           
                            if(res1.data.company.length>0)
                            {
                                this.mycompaniesDetailsDocs = res1.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );

                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_countries'
                        },auth)
                        .then(res2 => {
                            
                            // docs here
                            
                            
                            if(res2.data.company.length>0)
                            {
                                this.mycompaniesDetailsCountries = res2.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );

                    }
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

      
      //this.$ref['myBusinessCardExt'].modal('show');
      //document.getElementById('myBusinessCardExt').modal('show');
      if(table=='companies'){
        document.getElementById("openModalGobiz").click();
      }else{
        document.getElementById("openModalExt").click();
      }
      
      
    
      // azzero le variabili 
      this.$store.dispatch('setCompanyIdBusinessCard',null );
      this.$store.dispatch('setCompanyTableBusinessCard',null );

      //this.$store.dispatch('setCompanyNetworkBusinessCard',null );
      //this.$store.dispatch('setCompanyMessageBusinessCard',null );

    },
    onClickConfirmAcceptReject(comp,action){

      if(this.lang=='Ita'){
        this.confirmAccept = 'Confermi l\'operazione?';
        this.confirmReject = 'Confermi l\'operazione?';
      }
      if(this.lang=='Eng'){
        this.confirmAccept = 'Do you confirm the operation?';
        this.confirmReject = 'Do you confirm the operation?';
      }
      if(this.lang=='Den'){
        this.confirmAccept = 'Bekræfte operation?';
        this.confirmReject = 'Bekræfte operation?';
      }


      let confirmMessage = '';
      if(action=='Y'){
        confirmMessage = this.confirmAccept;
      }else{
        confirmMessage = this.confirmReject;
      }

      
      if (confirm(confirmMessage)){
        this.accetpReject(comp,action);
      }

    },
    sendEmail(companyIdTo, companyTable, action,emailAddressTo,newMessage){
        const userId = this.getUser.id;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
            //console.log('----------'+companyIdTo+'xxxxxxxxxxxx---'+this.registeredCompany)
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyAll',
                        {
                            id : companyIdTo,
                            table : companyTable,
                            what : ''
                        },auth)
                        .then(res => {
                            
                            let emailTo = '';
                            res.data.network.forEach(em =>{
                                if(emailTo==''){ emailTo = em.email; }else{
                                    emailTo = emailTo + ',' + em.email;
                                }
                            });
                            //console.log(companyTable+'xxxxxx'+action);
                            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/sendemail',
                            {
                                userId : userId,
                                emailAddressTo : emailTo,
                                emailAddressFrom : this.selectedCompanyEmailFrom,
                                companyFrom : companyIdTo,
                                message : newMessage,
                                companyTable : companyTable,
                                action: action
                            },auth)
                            .then(res => {
                                
                                return res;
                            })
                            .catch(
                                error => {
                                
                                return error;
                                }
                            );
                        })
                        .catch(
                            error => {
                            
                            return error;
                            }
                        );

    },
    sendEmailInvite(companyIdTo, companyTable, action,emailAddressTo,newMessage){
        const userId = this.getUser.id;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
            // da verificare bene prima del rilascio in produzione
            if(emailAddressTo==''){
              // l'indirizzo email del BO
              //emailAddressTo = 'stefano.fabbri@kconsulting.it';
              emailAddressTo = 'admin@gobiz.be';
            }
            
           
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/sendemail',
            {
                userId : userId,
                emailAddressTo : emailAddressTo,
                emailAddressFrom : this.selectedCompanyEmailFrom,
                companyFrom : this.registeredCompany.id,
                message : newMessage,
                companyTable : companyTable,
                companyIdTo : companyIdTo,
                action: action
            },auth)
            .then(res => {
                
                return res;
            })
            .catch(
                error => {
                
                return error;
                }
            );
                      

    },
    accetpReject(comp,myResponse){
      const auth = {
            headers: {Authorization:'JWT ' + this.getToken} 
        }

        // setto l'azienda con la quale voglio operare
        this.MyCompanyId = comp.companyIdTo;

        const userId = this.getUser.id;
        let message = '';
        if(myResponse=='Y'){
          message = 'Company Report has been shared';
        }else{
          message = 'Company Report has not been shared';
        }

        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    userIdTo : '',
                    companyIdFrom : comp.companyIdTo,
                    companyIdTo : comp.companyIdFrom,
                    message : message,
                    messageTranslated : '',
                    guid : comp.guid,
                    firstMessage : comp.firstMessage,
                    companyTable : comp.companyTable,
                    blockchainsHash : '',
                    actionToDo : 'ADD'
                },auth)
                .then(res => {
                  
                    this.addToMyNetwork(comp.companyIdFrom,comp.companyTable,'0','ADD','','N');
                    this.sendEmail(comp.companyIdFrom, comp.companyTable, 'SEND',0,message);
                    this.markViewed(comp.id);
                    //this.bellCals();
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
        return 'ok';

    },
    markViewed(chatId){
      const auth = {
            headers: {Authorization:'JWT ' + this.getToken} 
        }
        const userId = this.getUser.id;
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    chatId : chatId,
                    userId : userId,
                    actionToDo : 'VIEWEDID'
                },auth)
                .then(res => {
                    this.bellCals();
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
        return 'ok';
    },
    bellCals(){
      const auth = {
          headers: {Authorization:'JWT ' + this.getToken} 
        }

          const userId = this.getUser.id;

          gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
            {
                userId : userId,
                companyId : '-1',
                action : 'REQUESTEDVISURABYME'
            },auth)
            .then(res2 => {
                // prima di aggiungere la company verifico che non sia già presente
                
                this.$store.dispatch('setupdatedCertificate',res2.data );
                  
            })
            .catch(
            error => {
            return error;
            }
            );

          
          gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
            {
                userId : userId,
                companyId : '-1',
                action : 'REQUESTEDREGISTRATIONABYME'
            },auth)
            .then(res3 => {
                // prima di aggiungere la company verifico che non sia già presente
                    
                this.$store.dispatch('setcompanyRegisteredGoBiz',res3.data );
                
                  
            })
            .catch(
            error => {
          
            return error;
            }
            );


            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
            {
                userId : userId,
                companyId : '-1',
                action : 'ASKTOSEEVISURABYME'
            },auth)
            .then(res4 => {
                // prima di aggiungere la company verifico che non sia già presente
                //console.log(res4);
                this.$store.dispatch('setrequestseevisura',res4.data );
                
                  
            })
            .catch(
            error => {
            
            return error;
            }
            );


            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
            {
                userId : userId,
                companyId : '-1',
                action : 'OKASKTOSEEVISURABYME'
            },auth)
            .then(res5 => {
                // prima di aggiungere la company verifico che non sia già presente
                    
                this.$store.dispatch('setokrequestseevisura',res5.data );
                
                  
            })
            .catch(
            error => {
              
            return error;
            }
            );


            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
            {
                userId : userId,
                companyId : '-1',
                action : 'KOASKTOSEEVISURABYME'
            },auth)
            .then(res6 => {
                // prima di aggiungere la company verifico che non sia già presente
                    
                this.$store.dispatch('setkorequestseevisura',res6.data );
                
                  
            })
            .catch(
            error => {
          
            return error;
            }
            );


    },
    usId(){
      // di tanto in tanto richiamo le credenziali per verificare se sono ancora loggato

      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }
      
      if(this.getToken!=null)
      {
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/me',
        {
          void : 'void',
          returnSecureToken : true
          
        },auth)
        .then(res => {
          
          this.$store.commit('authUser',{
            idUser : res.data.user.id,
            name : res.data.user.name,
            surname : res.data.user.surname,
            idUsersParent : res.data.user.idUsersParent
          });

          setTimeout(this.usId, 1000);
          return res;
          //router.push('/dashboard');
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          }
          );


      }else{
        // nothing
        this.logout();
      }
    },
    logout(){
      this.$store.dispatch('logout');
      
    },
    sadreport(){
      router.push('/report');
    },
    changeLang(req){
      
      this.$store.dispatch('changeLang',req);

      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }
      
      if(this.getToken!=null)
      {
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/changeLang',
        {
          userId : this.getUserId,
          lang : req
          
        },auth)
        .then(res => {
          
          return res;
          
        })
        .catch(
          error => {
          this.logout();
          
          return error;
        }
        );
      }

    },
    addNewCompany(){
        this.$store.dispatch('SetregisteredCompany', null );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updatecompany');
        
    },
    searchevents(events,filterValue,label){
    //events = events2;
      if(events){
        
        const filter = event => 
          event.tableName.includes(filterValue) &&
          event.fieldName.includes(label)
      
        var filteredArray = events.filter(filter);
        if(filteredArray.length >0)
        {
          var labelName = 'label'+this.lang;
          return filteredArray[0][labelName];
        }else{
          return '--';
        }
        
      }else{
        return null;
      }
      
      
      
    }
  },
  created(){
    this.userStored = this.getUser;

    
    if(localStorage.getItem('coockieAccepted')=='false'){
      this.acceptGoogleCookies = false;
    }else{
      this.acceptGoogleCookies = true;
    }
    
    //console.log(this.acceptGoogleCookies + '--' + localStorage.getItem('coockieAccepted') );
    
  },mounted(){

    //console.log(this.mycompaniesDetailsDocs);
      
    //$(this.$refs.myBusinessCardExt).on("show.bs.modal", this.resetTranslatedActivityDescription);

    // metto una funzionae che richiama i dati di login ogni 60 secondi (?)
    this.usId();
    this.userStored = this.getUser;
    
    this.updatedCertificate = this.getupdatedCertificate;

    this.$nextTick(function () {
      var reg = this.$store.getters.getregisteredCompany;
      if(reg!=null){ this.registeredCompany = reg; }
      
      
      if(this.registeredCompany && this.registeredCompany.id != null){
        this.companyName = this.getregisteredCompany.companyName;
        this.MyCompanyId = this.getregisteredCompany.id;
      }
      

    });
  },watch : {

    getcompanyIdBusinessCard: function () {
      
      if(this.getcompanyIdBusinessCard!=null){
        this.setSelectedCompanyIdDetailFooter(this.getcompanyIdBusinessCard,this.getcompanyTableBusinessCard);
      }
      
    },
    getregisteredCompany: function(){
      this.registeredCompany = this.getregisteredCompany;
      this.id = this.getregisteredCompany.id;
      this.companyName = this.getregisteredCompany.companyName;
      this.MyCompanyId = this.getregisteredCompany.id;
    },
    getupdatedCertificate: function(){
      this.updatedCertificate = this.getupdatedCertificate;
    },
    getcompanyRegisteredGoBiz: function(){
      this.companyRegisteredGoBiz = this.getcompanyRegisteredGoBiz;
    },
    auth: function(){
      if(this.auth){
        this.bellCals();
      }
    }
  }
}

window.addEventListener("load", function(event) {
  //console.log(this.$gtag);
  if (localStorage.getItem("coockieAccepted") === null) {
        //console.log('Non ci sono');
        document.getElementById("openModalCookiesGobiz").click();
      }else{
        //console.log('Ci sono');
        // non faccio niente
      }

    return event;
  });

</script>



<style scoped lang="scss">

#myCookieModal .switch {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 26px;
}

#myCookieModal .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

#myCookieModal .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

#myCookieModal .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: #e6d235;
  -webkit-transition: .4s;
  transition: .4s;
}

#myCookieModal input:checked + .slider {
  background-color: #0c294b;
}

#myCookieModal input:focus + .slider {
  box-shadow: 0 0 1px #0c294b;
}

#myCookieModal input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
#myCookieModal .slider.round {
  border-radius: 34px;
}

#myCookieModal .slider.round:before {
  border-radius: 50%;
}



.badge{
  background-color: red;
    /* max-width: 10px; */
    /* max-height: 25px; */
    border-radius: .85rem;
    font-size: 35%;
    /* top: -10px; */
    position: absolute;
    color: white;
}

.fa-bell{
  color: #a3b9bd !important;
}

//menu notifiche
.liBell{
  padding: 10px;
    width: max-content;
    background-color: lightgray;
    //    background-color: #0c294b;
}

.liBellH{
  padding: 10px;
  font-weight: bold;
}
.hamburger-giallo.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(230,210,53, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  // background-image: percorso locale da inserire
}
.bordo-hamburger.navbar-toggler {
  border-color: #e6d235;
  color: #e6d235;
  } 
 

 .nav-link {
  display: block;
  padding: .0rem 1rem;
  padding-top: 0.5rem;
     }

.col-6 {
   
    text-align: left;
}
     
.col-5 {
   
    text-align: left;
}



// per i colori delle icone (badge home vai sopra dove c'è scritto class="nav-item dropdown" v-if="auth && .....
// .navbar-brand{
    //color: $navbar-light-brand-color;
   // color: red;
    // @include hover-focus {
     // color: $navbar-light-brand-hover-color;
//} 
//} 
</style>

