<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/gobiz-logo-rectangle-blue.png" width="250em">
    
    <hr>
    <div  v-if="this.lang=='Ita'" v-html="this.titlePage"> </div>
    <h4 v-if="this.lang=='Ita'"><div v-html="this.contentPage">  </div> </h4>

    <div  v-if="this.lang=='Eng'" v-html="this.titlePageEng"> </div>
    <h4 v-if="this.lang=='Eng'"><div v-html="this.contentPageEng">  </div> </h4>

    <div  v-if="this.lang=='Den'" v-html="this.titlePageDen"> </div>
    <h4 v-if="this.lang=='Den'"><div v-html="this.contentPageDen">  </div> </h4>
    
  </div>
</template>

<script>
// @ is an alias to /src
import gAxios from 'axios';

export default {
  name: 'home',
  data () {
    return {
      name : 'stefano',
      surname : '',
      email : '',
      password : '',
      titlePage : null,
      titlePageEng : null,
      titlePageDen : null,
      contentPage : null,
      contentPageEng : null,
      contentPageDen : null
    }
  },
  computed : {
    labels(){
      
      return this.$store.getters.labels;
    },
    lang(){
      return this.$store.getters.lang;
    },
  },
  components: {
    
  },
  mounted: function(){
    this.$nextTick(function () {
        
        // ITA
        gAxios.get('./pages/support_ita.txt',
        {
            
        })
        .then(res => {
          
          this.titlePage = '';
          this.contentPage = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

        // ENG
        gAxios.get('./pages/support_eng.txt',
        {
            
        })
        .then(res => {
          this.titlePageEng = '';
          this.contentPageEng = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

        // DEN
        gAxios.get('./pages/support_den.txt',
        {
            
        })
        .then(res => {
          this.titlePageDen = '';
          this.contentPageDen = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );
            
    })
  }
}

</script>