<template>
  <div class="container" style="max-width: 98% !important;">
    <template v-if="auth">
         <!-- <h1>{{searchevents(this.labels,'dashboard','loggedheader')}}</h1> -->
         <h2>{{searchevents(this.labels,'dashboard','welcome')}} {{this.userIdentity}}</h2>
         <hr>
    </template>
    <template v-else>
          <h2 style="color:red;">{{searchevents(this.labels,'dashboard','loggedheader')}}</h2>
    </template>



            
                
        
         
   <div class="container border tablelist " style="max-width: 95%;margin-top: -10px;">
        <div  v-if="getUserRole=='USR'" v-html="searchevents(this.labels,'menu','mycompanies')"></div>
        <!-- <div v-if="getUserRole=='USR'" v-html="searchevents(this.labels,'menu','mycompanieslight')"></div> -->
        <div style="float:center; text-align: center;" :class="'row '" v-if="getUserRole=='USR' && this.getIdUsersParent=='-1' && this.getUser.servUsersStateUsersState=='A' ">
               
                <!-- <router-link class="nav-link buttNavGreen" :to="{ name : 'updatecompany'}">{{searchevents(this.labels,'menu','insertcompany')}}</router-link> 
                tasto aggiungi impresa spostato nella header
               <button type="button" class="btn btn-success" style="font-size: .8em;" @click="addNewCompany()" >{{searchevents(labels,'menu','insertcompany')}}</button> 
               -->

        </div>
          
        <template>  
          <!-- no lista pratiche per Superadmin -->
          <!-- <div class="table-responsive-lg">     -->      
              <table  v-if="getUserRole=='USR'|| getUserRole=='BOF'" class="table tablelist shadow p-3 mb-5 bg-white rounded" >
              <thead>
              <tr>
                <td>
                  <div v-if="getUserRole=='USR'|| getUserRole=='BOF'" :class="'row blu-header'" style="margin-right: 0;margin-left: 0;">
                      
                      <div class="col-sm" v-if="getUserRole=='USR'" >{{searchevents(this.labels,'table','yourcompanynamelabel')}}</div>
                      <div class="col-sm" v-if="getUserRole=='USR'" >{{searchevents(this.labels,'users','servUsersStateUsersState')}}</div>
                       
                       <!-- inizio sezione BO --> 
                      <div class="col-sm" v-if="getUserRole=='BOF'" >{{searchevents(this.labels,'table','companynamelabel')}}</div>
                      <div class="col-sm" v-if="getUserRole=='BOF'" >{{searchevents(this.labels,'users','servUsersStateUsersState')}}</div>
                      <div class="col-sm" v-if="getUserRole=='BOF'" >Ultima Azione / Data</div>
                      <div class="col-sm" v-if="getUserRole=='BOF'" >Contratto Sottoscritto</div>
                      <div class="col-sm" v-if="getUserRole=='BOF'" >LR - Azienda</div>
                      <div class="col-sm" v-if="getUserRole=='BOF'" >{{searchevents(this.labels,'companies','updatedAt')}}</div>
                      
                      <!-- <div class="col-sm" v-if="getUserRole=='BOF'">PEC</div> -->
                      <div class="col-sm" v-if="getUserRole=='BOF'">{{searchevents(this.labels,'companies','countryOfOrigin')}}</div>
                      <!-- doppio <div class="col-sm" v-if="getUserRole=='BOF'">{{searchevents(this.labels,'menu','editcompany')}} </div> -->
                      
                      <!-- inizio utente std no bo -->
                      <div v-if="getUserRole=='USR'" class="col-sm" >{{searchevents(this.labels,'menu','mynetwork')}}
                         <!-- bottoni informativi con tooltip -->
                        <br>
                        <button class="btn-tooltip" v-tooltip="{content: searchevents(labels,'tooltipval','mynetwork'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                        <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#fff;width: 100%"></span>
                        </button>

                      </div>
                      
                      <div v-if="getUserRole=='USR'" class="col-sm" >{{searchevents(this.labels,'menu','messagesarea')}}</div>

                      <div  class="col-sm" >{{searchevents(this.labels,'menu','editcompany')}} 
                        <br>
                        <button v-if="getUserRole=='USR'"  class="btn-tooltip" v-tooltip="{content: searchevents(labels,'tooltipval','modifyprofile'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                        <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#fff;width: 100%"></span>
                        </button>
                      </div>



                      <!-- eliminata colonna VISURE
                        <div v-if="getUserRole=='USR'" class="col-sm" >{{searchevents(this.labels,'menu','requestsupdatecertificate')}} 
                       
                      </div>
                      -->
                                                               
                      <div v-if="getUserRole=='USR'" class="col-sm" >{{searchevents(labels,'nav','matchmakingsmart')}}
                         <button class="btn-tooltip" v-tooltip="{content: searchevents(labels,'tooltipval','mmsmart'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                         <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#fff;width: 100%"></span>
                        </button>
                      </div>

                      <div v-if="getUserRole=='USR'" class="col-sm" >{{searchevents(labels,'nav','matchmakingadv')}}
                        <button class="btn-tooltip" v-tooltip="{content: searchevents(labels,'tooltipval','mmadvanced'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                        <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#fff;width: 100%"></span>
                        </button>
                      </div>
                      
                  </div>
                </td>
                  
              </tr>
              </thead>
              <tbody>
                  <tr><td :key="keyReload">

                      <template>
                      <div>
                        <div v-for="(row, index) in orderedMyCompanies"  class="list-group-item" :key="row.id">
                            <div  :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }" style="margin-right: 0;margin-left: 0;">

                              <div class="col-sm"  >{{row.companyName}}</div>

                                <div class="col-sm" v-if="getUserRole=='USR'">
                                  {{returnCompaniesState(row.servCompaniesStateCompaniesState)}}
                                  <!--
                                  <select class="form-control" v-model="row.servCompaniesStateCompaniesState" disabled v-if="lang=='Ita'"> 
                                      <template v-for="companyState in dropCompaniesState " >
                                          <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateIta']}}</option> 
                                      </template>
                                  </select>
                                  <select class="form-control" v-model="row.servCompaniesStateCompaniesState" disabled v-if="lang=='Eng'"> 
                                      <template v-for="companyState in dropCompaniesState " >
                                          <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateEng']}}</option> 
                                      </template>
                                  </select> 
                                  -->
                                </div>

                                <div class="col-sm" v-if="getUserRole=='BOF'">
                                {{returnCompaniesStateBO(row.servCompaniesStateCompaniesState)}}
                                </div>


                                <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                  {{row.lastAction}} 
                                </div>

                                <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                  <span v-if="row.contractSignedFlag=='Y'">SI'</span>
                                  <span v-if="row.contractSignedFlag!='Y'">NO</span>
                                </div>

                                <div class="col-sm" v-if="getUserRole=='BOF'"> 

                                  {{row.atecoCode4}}-{{row.atecoCode5}} 

                                </div>
                                <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                  {{returnHumanDate(row.updatedAt)}}
                                </div>

                                
                                
                                <!-- <div class="col-sm" v-if="getUserRole=='BOF'">{{row.guidContractFlag}} {{row}}</div> -->
                                
                                <div class="col-sm" v-if="getUserRole=='BOF'">
                                  <select class="form-control" style="background-color: transparent; border: transparent;" v-model="row.countryOfOrigin" disabled> 
                                      <template v-for="countryOfOrigin in dropMofficeRegion ">
                                          <option v-for="(value, key) in countryOfOrigin" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                                      </template>
                                  </select>
                                </div>
                                <!-- rimossi tooltip v-tooltip.bottom="'spiegaz messaggi'" -->

                                <!-- inizio bottoni utente no bo -->

                                <div class="col-sm" v-if="getUserRole=='USR'">
                                    <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A' " class="btn btn-info position-absolute mid-center" style="font-size: .8em;" @click="setSelectedCompany(row)" >{{searchevents(labels,'menu','mynetwork')}}</button>
                                </div>
                              
                                <div class="col-sm" v-if="getUserRole=='USR'">

                                    <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A' " class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="setSelectedCompanyMessage(row,'message')" >{{searchevents(labels,'menu','messagesarea')}}<span class="badge minmarginleft" v-if="checkNewMessages(row.id)>0">{{checkNewMessages(row.id)}} </span></button>
                                   <button type="button" v-if="row.servCompaniesStateCompaniesState=='R'"  class="btn btn-dark position-absolute mid-center" style="font-size: .8em"  @click="setSelectedCompanyMessage(row,'message')" >{{searchevents(labels,'menu','messagesarea')}}<span class="badge minmarginleft" v-if="checkNewMessages(row.id)>0">{{checkNewMessages(row.id)}} </span></button>
                             
                                  </div> 
                                
                                <div class="col-sm" >
                                    <!-- completa profilo per utente  v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='R')" -->
                                    <button v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='R'  )" type="button" class="btn btn-dark position-absolute mid-center" style="font-size: .8em" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'menu','editcompanyuser')}}</button>
                                    <button v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A')" type="button" class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;padding: .2rem 0.25rem" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'menu','editcompanyc')}}</button>
                                    <!-- lavora la pratica bo -->
                                    <button v-if="getUserRole=='BOF'" type="button" class="btn btn-success" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'bof','editcompany')}}</button>
                                </div>


                               <!-- eliminata colonna VISURE
                                <div class="col-sm" v-if="getUserRole=='USR'">
                                 <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="setSelectedCompanyMessage(row,'requestvisura')" >{{searchevents(labels,'menu','visurarequest')}}<span class="badge minmarginleft" v-if="checkNewVisuraRequest(row.id)>0">{{checkNewVisuraRequest(row.id)}} </span></button>
                                </div>
                                -->

                                <div class="col-sm" v-if="getUserRole=='USR'">
                                    <button v-if="getUserRole=='USR' &&  row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: .8em;" @click="mmCompany(row,'M1')" :key="row.id" >{{searchevents(labels,'menu','onlygoaction')}}</button>
                                </div>

                                <div class="col-sm" v-if="getUserRole=='USR'">
                                    <button v-if="getUserRole=='USR' &&  row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: .8em;" @click="mmCompany(row,'M2')" :key="row.id" >{{searchevents(labels,'menu','onlygoaction')}}</button>
                                </div>
                              
                        </div>
                      </div> 
                      </div> 
                      </template>


                      <!-- imprese non lavorate -->
                      <template>
                      <div>
                        <div v-for="(row, index) in orderedMyCompaniesStatusNotWorked"  class="list-group-item" :key="row.id">
                            <div  :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }" style="margin-right: 0;margin-left: 0;">

                            <div class="col-sm"  >{{row.companyName}}</div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                {{returnCompaniesState(row.servCompaniesStateCompaniesState)}}
                                 <!--
                                 <select class="form-control" v-model="row.servCompaniesStateCompaniesState" disabled v-if="lang=='Ita'"> 
                                    <template v-for="companyState in dropCompaniesState " >
                                         <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateIta']}}</option> 
                                    </template>
                                </select>
                                <select class="form-control" v-model="row.servCompaniesStateCompaniesState" disabled v-if="lang=='Eng'"> 
                                    <template v-for="companyState in dropCompaniesState " >
                                         <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateEng']}}</option> 
                                    </template>
                                </select> 
                                -->
                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'">
                               {{returnCompaniesStateBO(row.servCompaniesStateCompaniesState)}}
                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                {{row.lastAction}} 

                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                <span v-if="row.contractSignedFlag=='Y'">SI'</span>
                                <span v-if="row.contractSignedFlag!='Y'">NO</span>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                {{row.atecoCode4}}-{{row.atecoCode5}}
                              </div>
                              <div class="col-sm" v-if="getUserRole=='BOF' "> 
                                {{returnHumanDate(row.updatedAt)}}
                              </div>
                              
                              <!-- <div class="col-sm" v-if="getUserRole=='BOF'">{{row.guidContractFlag}} {{row}}</div> -->
                              
                              <div class="col-sm" v-if="getUserRole=='BOF'">
                                <select class="form-control" style="background-color: transparent; border: transparent;" v-model="row.countryOfOrigin" disabled> 
                                    <template v-for="countryOfOrigin in dropMofficeRegion ">
                                        <option v-for="(value, key) in countryOfOrigin" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                                    </template>
                                </select>
                              </div>
                              <!-- rimossi tooltip v-tooltip.bottom="'spiegaz messaggi'" -->
                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A' " class="btn btn-info position-absolute mid-center" style="font-size: .8em;" @click="setSelectedCompany(row)" >{{searchevents(labels,'menu','mynetwork')}}</button>
                              </div> 

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A' " class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="setSelectedCompanyMessage(row,'message')" >{{searchevents(labels,'menu','messagesarea')}}<span class="badge minmarginleft" v-if="checkNewMessages(row.id)>0">{{checkNewMessages(row.id)}} </span></button>
                              </div> 

                              <div class="col-sm" >
                                  <button v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='R'  )" type="button" class="btn btn-dark position-absolute mid-center" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'menu','editcompanyuser')}}</button>
                                  <button v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A')" type="button" class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'menu','editcompanyc')}}</button>
                                  <button v-if="getUserRole=='BOF'" type="button" class="btn btn-success" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'bof','editcompany')}}</button>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="setSelectedCompanyMessage(row,'requestvisura')" >{{searchevents(labels,'menu','visurarequest')}}<span class="badge minmarginleft" v-if="checkNewVisuraRequest(row.id)>0">{{checkNewVisuraRequest(row.id)}} </span></button>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button v-if="getUserRole=='USR' &&  row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: .8em;" @click="mmCompany(row,'M1')" :key="row.id" >{{searchevents(labels,'menu','onlygoaction')}}</button>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button v-if="getUserRole=='USR' &&  row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: .8em;" @click="mmCompany(row,'M2')" :key="row.id" >{{searchevents(labels,'menu','onlygoaction')}}</button>
                              </div>
                              
                      </div>
                      </div>
                      </div>
                      </template>

                      <!-- a sequire le companies già lavorate -->
                      <template>
                      <div>
                        <div v-for="(row, index) in orderedMyCompaniesStatusWorked"  class="list-group-item" :key="row.id">
                          <div  :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">

                            <div class="col-sm"  >{{row.companyName}}</div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                {{returnCompaniesState(row.servCompaniesStateCompaniesState)}}
                                 <!--
                                 <select class="form-control" v-model="row.servCompaniesStateCompaniesState" disabled v-if="lang=='Ita'"> 
                                    <template v-for="companyState in dropCompaniesState " >
                                         <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateIta']}}</option> 
                                    </template>
                                </select>
                                <select class="form-control" v-model="row.servCompaniesStateCompaniesState" disabled v-if="lang=='Eng'"> 
                                    <template v-for="companyState in dropCompaniesState " >
                                         <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateEng']}}</option> 
                                    </template>
                                </select> 
                                -->
                              </div>
                              <div class="col-sm" v-if="getUserRole=='BOF'">
                               {{returnCompaniesStateBO(row.servCompaniesStateCompaniesState)}}
                              </div>


                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                {{row.lastAction}} 
                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                <span v-if="row.contractSignedFlag=='Y'">SI'</span>
                                <span v-if="row.contractSignedFlag!='Y'">NO</span>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                {{row.atecoCode4}}-{{row.atecoCode5}}
                              </div>
                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                {{returnHumanDate(row.updatedAt)}}
                              </div>
                              
                              <!-- <div class="col-sm" v-if="getUserRole=='BOF'">{{row.guidContractFlag}} {{row}}</div> -->
                              
                              <div class="col-sm" v-if="getUserRole=='BOF'">
                                <select class="form-control" style="background-color: transparent; border: transparent;" v-model="row.countryOfOrigin" disabled> 
                                    <template v-for="countryOfOrigin in dropMofficeRegion ">
                                        <option v-for="(value, key) in countryOfOrigin" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                                    </template>
                                </select>
                              </div>
                              <!-- rimossi tooltip v-tooltip.bottom="'spiegaz messaggi'" -->
                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A' " class="btn btn-info position-absolute mid-center" style="font-size: .8em;" @click="setSelectedCompany(row)" >{{searchevents(labels,'menu','mynetwork')}}</button>
                              </div> 

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A' " class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="setSelectedCompanyMessage(row,'message')" >{{searchevents(labels,'menu','messagesarea')}}<span class="badge minmarginleft" v-if="checkNewMessages(row.id)>0">{{checkNewMessages(row.id)}} </span></button>
                              </div> 

                              <div class="col-sm" >
                                  <button v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='R'  )" type="button" class="btn btn-dark position-absolute mid-center" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'menu','editcompanyuser')}}</button>
                                  <button v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A')" type="button" class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'menu','editcompanyc')}}</button>
                                  <button v-if="getUserRole=='BOF'" type="button" class="btn btn-success" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'bof','editcompany')}}</button>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="setSelectedCompanyMessage(row,'requestvisura')" >{{searchevents(labels,'menu','visurarequest')}}<span class="badge minmarginleft" v-if="checkNewVisuraRequest(row.id)>0">{{checkNewVisuraRequest(row.id)}} </span></button>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button v-if="getUserRole=='USR' &&  row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: .8em;" @click="mmCompany(row,'M1')" :key="row.id" >{{searchevents(labels,'menu','onlygoaction')}}</button>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button v-if="getUserRole=='USR' &&  row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: .8em;" @click="mmCompany(row,'M2')" :key="row.id" >{{searchevents(labels,'menu','onlygoaction')}}</button>
                              </div>
                              
                      </div>
                      </div>
                      </div>
                      </template>
                  </td>
                  </tr>
              </tbody>
          </table>
        <!-- </div> -->
<!-- fine sezione dashboard -->

<!-- nuova sezione con tasti per servizi on demand -->
<!-- intestazioni di colonna -->
    <hr>
   <div class="container tablelist" style="max-width: 95%;margin-top: -10px;">
        <div v-if="getUserRole=='USR'" v-html="searchevents(this.labels,'menu','myadvisoring')">
           </div>
        <div style="float:center; text-align: center;" :class="'row '" v-if="getUserRole=='USR' && this.getIdUsersParent=='-1' && this.getUser.servUsersStateUsersState=='A' ">
            </div>
        </div> 
          <table v-if="getUserRole=='USR'" class="table tablelist shadow p-3 mb-5 bg-white rounded" style="margin-top: -65px; ">
              <thead>
              <tr>
                <td>
                  <div v-if="getUserRole=='USR'" :class="'row blu-header'" style="margin-right: 0;margin-left: 0;">
                      
                      <!-- intestazione colonna 2 tasto accedi 1 -->
                      <div class="col-sm" >{{searchevents(this.labels,'menu','service1')}}</div>
                      
                      <div  class="col-sm" >{{searchevents(this.labels,'menu','service2')}} 
                        <br>
                       <!-- tooltip info button 
                       <button v-if="getUserRole=='USR'"  class="btn-tooltip" v-tooltip="{content: searchevents(labels,'tooltipval','modifyprofile'), trigger: 'click', show: isOpen, placement: 'bottom', delay: {  show: 0,  hide: 10,  }, autoHide: true }" >
                        <span class="fa fa-info-circle" aria-hidden="true" style="font-size:18px;color:#fff;width: 100%"></span>
                        </button>
                        -->
                      </div>
                    
                           <!-- servizio per tutti 3   DA ABILITARE - temporaneamente disabilitato             
                      <div v-if="getUserRole=='USR'" class="col-sm" >{{searchevents(labels,'menu','service3')}}
                       </div>
                       -->
                  </div>
                </td>
               </tr>
              </thead>
              <!-- righe risultati e bottoni -->
              <tbody>
                  <tr><td :key="keyReload">
                      
                    <div :class="{'row blu-ligthadvisory': index % 2 === 0, 'row blu-ligth': index % 2 !== 0 }" style="margin-right: 0;margin-left: 0;">
                            
                        <!-- servizio per tutti 1 -->
                        <div class="col-sm" >
                            <button v-if="getUserRole=='USR'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: 1.0em" @click="gotoadvisory1(row)"> {{searchevents(labels,'menu','serviceactv')}}</button> 
                        </div>

                        <!-- servizio per tutti 2 -->
                        <div class="col-sm" v-if="getUserRole=='USR'">
                          <button v-if="getUserRole=='USR'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: 1.0em;" @click="gotoadvisory2(row)" >{{searchevents(labels,'menu','serviceactv')}}</button> 
                          
                        </div>
                          
                          <!-- servizio per tutti 3   DA ABILITARE - temporaneamente disabilitato
                          <div class="col-sm" v-if="getUserRole=='USR'">
                            <button v-if="getUserRole=='USR'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: .8em;" @click="gotoadvisory3(row)" >{{searchevents(labels,'menu','serviceactv')}}</button> 
                          
                          </div>
                          -->
                    </div>


                      <!-- imprese non lavorate -->
                      <template>
                      <div>
                        <div v-for="(row, index) in orderedMyCompaniesStatusNotWorked"  class="list-group-item" :key="row.id">
                          <div :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">

                            <div class="col-sm"  >{{row.companyName}}</div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                {{returnCompaniesState(row.servCompaniesStateCompaniesState)}}
                                 <!--
                                 <select class="form-control" v-model="row.servCompaniesStateCompaniesState" disabled v-if="lang=='Ita'"> 
                                    <template v-for="companyState in dropCompaniesState " >
                                         <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateIta']}}</option> 
                                    </template>
                                </select>
                                <select class="form-control" v-model="row.servCompaniesStateCompaniesState" disabled v-if="lang=='Eng'"> 
                                    <template v-for="companyState in dropCompaniesState " >
                                         <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateEng']}}</option> 
                                    </template>
                                </select> 
                                -->
                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'">
                               {{returnCompaniesStateBO(row.servCompaniesStateCompaniesState)}}
                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                {{row.lastAction}} 

                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                <span v-if="row.contractSignedFlag=='Y'">SI'</span>
                                <span v-if="row.contractSignedFlag!='Y'">NO</span>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                {{row.atecoCode4}}-{{row.atecoCode5}}
                              </div>
                              <div class="col-sm" v-if="getUserRole=='BOF' "> 
                                {{returnHumanDate(row.updatedAt)}}
                              </div>
                              
                              <!-- <div class="col-sm" v-if="getUserRole=='BOF'">{{row.guidContractFlag}} {{row}}</div> -->
                              
                              <div class="col-sm" v-if="getUserRole=='BOF'">
                                <select class="form-control" style="background-color: transparent; border: transparent;" v-model="row.countryOfOrigin" disabled> 
                                    <template v-for="countryOfOrigin in dropMofficeRegion ">
                                        <option v-for="(value, key) in countryOfOrigin" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                                    </template>
                                </select>
                              </div>
                              <!-- rimossi tooltip v-tooltip.bottom="'spiegaz messaggi'" -->
                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A' " class="btn btn-info position-absolute mid-center" style="font-size: .8em;" @click="setSelectedCompany(row)" >{{searchevents(labels,'menu','mynetwork')}}</button>
                              </div> 

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A' " class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="setSelectedCompanyMessage(row,'message')" >{{searchevents(labels,'menu','messagesarea')}}<span class="badge minmarginleft" v-if="checkNewMessages(row.id)>0">{{checkNewMessages(row.id)}} </span></button>
                              </div> 

                              <div class="col-sm" >
                                  <button v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='R'  )" type="button" class="btn btn-dark position-absolute mid-center" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'menu','editcompanyuser')}}</button>
                                  <button v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A')" type="button" class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'menu','editcompanyc')}}</button>
                                  <button v-if="getUserRole=='BOF'" type="button" class="btn btn-success" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'bof','editcompany')}}</button>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="setSelectedCompanyMessage(row,'requestvisura')" >{{searchevents(labels,'menu','visurarequest')}}<span class="badge minmarginleft" v-if="checkNewVisuraRequest(row.id)>0">{{checkNewVisuraRequest(row.id)}} </span></button>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button v-if="getUserRole=='USR' &&  row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: .8em;" @click="mmCompany(row,'M1')" :key="row.id" >{{searchevents(labels,'menu','onlygoaction')}}</button>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button v-if="getUserRole=='USR' &&  row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: .8em;" @click="mmCompany(row,'M2')" :key="row.id" >{{searchevents(labels,'menu','onlygoaction')}}</button>
                              </div>
                              
                      </div>
                       </div>
                        </div>
                      </template>

                      <!-- a sequire le companies già lavorate -->
                      <template>
                      <div>
                        <div v-for="(row, index) in orderedMyCompaniesStatusWorked"  class="list-group-item" :key="row.id">
                         <div  :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">

                            <div class="col-sm"  >{{row.companyName}}</div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                {{returnCompaniesState(row.servCompaniesStateCompaniesState)}}
                                 <!--
                                 <select class="form-control" v-model="row.servCompaniesStateCompaniesState" disabled v-if="lang=='Ita'"> 
                                    <template v-for="companyState in dropCompaniesState " >
                                         <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateIta']}}</option> 
                                    </template>
                                </select>
                                <select class="form-control" v-model="row.servCompaniesStateCompaniesState" disabled v-if="lang=='Eng'"> 
                                    <template v-for="companyState in dropCompaniesState " >
                                         <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateEng']}}</option> 
                                    </template>
                                </select> 
                                -->
                              </div>
                              <div class="col-sm" v-if="getUserRole=='BOF'">
                               {{returnCompaniesStateBO(row.servCompaniesStateCompaniesState)}}
                              </div>


                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                {{row.lastAction}} 
                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                <span v-if="row.contractSignedFlag=='Y'">SI'</span>
                                <span v-if="row.contractSignedFlag!='Y'">NO</span>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                {{row.atecoCode4}}-{{row.atecoCode5}}
                              </div>
                              <div class="col-sm" v-if="getUserRole=='BOF'"> 
                                {{returnHumanDate(row.updatedAt)}}
                              </div>
                              
                              <!-- <div class="col-sm" v-if="getUserRole=='BOF'">{{row.guidContractFlag}} {{row}}</div> -->
                              
                              <div class="col-sm" v-if="getUserRole=='BOF'">
                                <select class="form-control" style="background-color: transparent; border: transparent;" v-model="row.countryOfOrigin" disabled> 
                                    <template v-for="countryOfOrigin in dropMofficeRegion ">
                                        <option v-for="(value, key) in countryOfOrigin" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                                    </template>
                                </select>
                              </div>
                              <!-- rimossi tooltip v-tooltip.bottom="'spiegaz messaggi'" -->
                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A' " class="btn btn-info position-absolute mid-center" style="font-size: .8em;" @click="setSelectedCompany(row)" >{{searchevents(labels,'menu','mynetwork')}}</button>
                              </div> 

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A' " class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="setSelectedCompanyMessage(row,'message')" >{{searchevents(labels,'menu','messagesarea')}}<span class="badge minmarginleft" v-if="checkNewMessages(row.id)>0">{{checkNewMessages(row.id)}} </span></button>
                              </div> 

                              <div class="col-sm" >
                                  <button v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='R'  )" type="button" class="btn btn-dark position-absolute mid-center" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'menu','editcompanyuser')}}</button>
                                  <button v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A')" type="button" class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'menu','editcompanyc')}}</button>
                                  <button v-if="getUserRole=='BOF'" type="button" class="btn btn-success" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'bof','editcompany')}}</button>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                <button type="button" v-if="row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" class="btn btn-success position-absolute mid-center minpadding" style="font-size: .8em;" @click="setSelectedCompanyMessage(row,'requestvisura')" >{{searchevents(labels,'menu','visurarequest')}}<span class="badge minmarginleft" v-if="checkNewVisuraRequest(row.id)>0">{{checkNewVisuraRequest(row.id)}} </span></button>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button v-if="getUserRole=='USR' &&  row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: .8em;" @click="mmCompany(row,'M1')" :key="row.id" >{{searchevents(labels,'menu','onlygoaction')}}</button>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button v-if="getUserRole=='USR' &&  row.servCompaniesStateCompaniesState=='C' || row.servCompaniesStateCompaniesState=='A'" type="button" class="btn btn-success position-absolute mid-center" style="font-size: .8em;" @click="mmCompany(row,'M2')" :key="row.id" >{{searchevents(labels,'menu','onlygoaction')}}</button>
                              </div>
                              
                      </div>
                       </div>
                        </div>
                      </template>
                          
                  </td>
                  </tr>
              </tbody>
          </table>
        <!-- </div> -->


<br>
<br>
        <!-- le tue news colonna singola OLD 
       <div v-if="getUserRole!='BOF'&& this.getIdUsersParent=='-1'" class="container tablelist" style="max-width: 90%;">
         <hr>
         <h2><strong>{{searchevents(this.labels,'menu','mynews')}}</strong></h2>
         <br>
           <div class="col-sm" style="margin-left:29%; float:center; text-align: center; max-width:45%">
             <VueRssFeed :feedUrl="searchevents(this.labels,'http','rssitacommodities')" :name="Sole24oreCommodities" :limit="limit"/>   
          </div>
        </div>
           -->
 
           <!-- le tue news NEW doppia colonna con aziende selezionate per te
           <br>
           <br>
            -->
   
           <div v-if="getUserRole=='USR' && this.getIdUsersParent=='-1'" class="container tablelist" style="max-width: 90%;margin-top: -45px">
         <hr>
         
         <table class="table table-bordered"  >
              <thead>
              <tr>
                <td>
                  <div class="row">
                       <div class="col-sm" style="float:center; text-align: center;" >
                        <h2><strong>{{searchevents(this.labels,'menu','mynews')}}</strong></h2>
                        </div>
                    </div>
                </td>
                </tr>
              <tr>
                 <td>
                  <div class="row">
                     <!-- modificata con quella sotto per demo uniscrow  <div class="col-sm" style="margin-left:5%; float:center; text-align: center; max-width:45%"> -->
                     <div class="col-sm" style="margin-left:28%; float:center; text-align: center; max-width:45%">
                       <VueRssFeed :feedUrl="searchevents(this.labels,'http','rssitacommodities')" :name="Sole24oreCommodities" :limit="limit"/>   
                      </div>
                    </div>
                </td>
              </tr>
              </thead>
              </table>
              
      </div>
      <!-- fine box e immagini Visible only on md and lg -->
          
       
      </template>  

        <!-- inizio dashboard superadmin -->
              <div v-if="getUserRole=='SAD'">
              <h2>Dashboard</h2>
              <hr>
              <button  style="margin-left:0%; float:center; text-align: center; font-size: 1.3em;" type="button" class="btn btn-success"><router-link v-bind:to="'report'">Accedi alla dashboard</router-link></button> 
              <br>
              </div>
                       <!-- fine  dashboard  superadmin -->

          <!-- RICERCA PER BO E SUPER ADMIN ############################################################################################################################### -->
          <template v-if="(this.getUserRole=='BOF' || this.getUserRole=='SAD')">
       <hr>
          <div class="row" v-if="(this.getUserRole=='BOF' || this.getUserRole=='SAD')" style="margin-bottom: 30px;">
             <div class="col-3">
             </div>
             <div class="col-6">
          <h2 v-if="(this.getUserRole=='BOF' || this.getUserRole=='SAD')"><strong>Ricerca su DB esterni e Aggiunta indirizzo email non presente</strong></h2>
            </div>
            </div>
            <div class="row" v-if="(this.getUserRole=='BOF' || this.getUserRole=='SAD')">
                <!-- <router-link class="nav-link buttNavGreen" :to="{ name : 'updatecompany'}">{{searchevents(this.labels,'menu','insertcompany')}}</router-link> -->
                <button  style="margin-left:44%; float:center; text-align: center; font-size: 1.3em;" type="button" class="btn btn-success"><router-link v-bind:to="'addmailbo'">Aggiungi email</router-link></button> 
            </div>
            <br>
            <hr>

              <h2>Ricerca libera</h2>
              <div class="row" style="margin-bottom: 30px;">
                <div class="col">
                  <label  >Stato</label>
                    <select class="form-control" v-model="searchStatus" style="-webkit-appearance: menulist;" > 
                      <template v-for="companyState in dropCompaniesStateBO " >
                            <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateDesc']}}</option> 
                      </template>
                  </select>
                </div>
                <div class="col">
                  <label  >Ragione sociale</label>
                  <input class="form-control" type="searchName" id="searchName" v-model="searchName" >
                </div>
                <div class="col">
                  
                  <button type="button" class="btn btn-success" style="font-size: .9em; margin-top: 35px;" @click="companiesSearch()" >Avvia ricerca</button>
                </div>
              </div>    
              <table class="table tablelist"  >
              <thead>
              <tr>
                <td>
                  <div :class="'row blu-header'">
                      <div class="col-sm" >{{searchevents(this.labels,'users','servUsersStateUsersState')}}</div>
                      <div class="col-sm" >{{searchevents(this.labels,'table','companynamelabel')}}</div>
                      <div class="col-sm" >{{searchevents(this.labels,'companies','countryOfOrigin')}}</div>

                      <div class="col-sm" v-if="getUserRole=='BOF'|| getUserRole=='SAD'" >Ultima Azione / Data</div>
                      <div class="col-sm" v-if="getUserRole=='BOF'|| getUserRole=='SAD'" >LR - Azienda</div>
                      <div class="col-sm" >{{searchevents(this.labels,'companies','updatedAt')}}</div>
                      
                      <!-- <div class="col-sm" >PEC</div> -->

                      <div class="col-sm" >{{searchevents(this.labels,'menu','editcompany')}}</div>
                      <div v-if="getUserRole=='USR'" class="col-sm" >{{searchevents(this.labels,'menu','mynetwork')}}</div>
                  </div>
                </td>
                  
              </tr>
              </thead>
              <tbody>
                  <tr><td >
                <template>
                      <div>
                        <div v-for="(row, index) in orderedMyCompaniesSearch"  class="list-group-item" :key="row.id">
                            <div :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">
                              <div class="col-sm" >

                                 <select class="form-control" v-model="row.servCompaniesStateCompaniesState" disabled v-if="lang=='Ita'"> 
                                    <template v-for="companyState in dropCompaniesState " >
                                         <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateIta']}}</option> 
                                    </template>
                                </select>
                                <select class="form-control" v-model="row.servCompaniesStateCompaniesState" disabled v-if="lang=='Eng'"> 
                                    <template v-for="companyState in dropCompaniesState " >
                                         <option v-for="(value, key) in companyState" :value="value['companiesState']" :key="key" >{{value['companiesStateEng']}}</option> 
                                    </template>
                                </select> 
                              </div>

                              <div class="col-sm" > {{row.companyName}}</div>

                              

                              <div class="col-sm" > 
                                <select class="form-control" v-model="row.countryOfOrigin" disabled>
                                    <template v-for="countryOfOrigin in dropMofficeRegion ">
                                        <option v-for="(value, key) in countryOfOrigin" :value="value['id']" :key="key" >{{value['countryDescription']}}</option>
                                    </template>
                                </select>
                              </div>

                              <div class="col-sm" v-if="getUserRole=='BOF'|| getUserRole=='SAD'"> 
                                {{row.lastAction}} <br>{{row.lastActionDate}}
                              </div>
                              <div class="col-sm" v-if="getUserRole=='BOF'|| getUserRole=='SAD'"> 
                                {{row.atecoCode4}}-{{row.atecoCode5}}
                              </div>
                              <div class="col-sm" > 
                                {{row.updatedAt}} 
                              </div>
                              
                              <!-- <div class="col-sm" >{{row.guidContractFlag}} </div> -->
                              
                              <div class="col-sm" >
                                  <button v-if="getUserRole=='USR' && (row.servCompaniesStateCompaniesState=='R' || row.servCompaniesStateCompaniesState=='A' )" type="button" class="btn btn-success" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'menu','editcompany')}}</button>
                                  
                                  <!-- lavorazione pratica bo da ricerca -->
                                  <button v-if="getUserRole=='BOF' || getUserRole=='SAD'" type="button" class="btn btn-success" style="font-size: .8em;" @click="modifyCompany(row)" :key="row.id" >{{searchevents(labels,'bof','editcompany')}}</button>
                              </div>
                              <div class="col-sm" v-if="getUserRole=='USR'">
                                  <button type="button" v-if="row.servCompaniesStateCompaniesState=='R' || row.servCompaniesStateCompaniesState=='A' " class="btn btn-info" style="font-size: .8em;" @click="setSelectedCompany(row)" >{{searchevents(labels,'menu','selectglobalcompany')}}</button>
                              </div> 
                      </div>
                      </div>
                      </div>
                      </template>
                      
                  </td>
                  </tr>
              </tbody>
          </table>
          
        <!-- le tue news colonna singola OLD 
       <div v-if="getUserRole!='BOF'&& this.getIdUsersParent=='-1'" class="container tablelist" style="max-width: 90%;">
         <hr>
         <h2><strong>{{searchevents(this.labels,'menu','mynews')}}</strong></h2>
         <br>
           <div class="col-sm" style="margin-left:29%; float:center; text-align: center; max-width:45%">
             <VueRssFeed :feedUrl="searchevents(this.labels,'http','rssitacommodities')" :name="Sole24oreCommodities" :limit="limit"/>   
          </div>
        </div>
           -->
 
           <!-- le tue news NEW doppia colonna con aziende selezionate per te
           <br>
           <br>
            -->
   
           <div v-if="getUserRole=='USR' && this.getIdUsersParent=='-1'" class="container tablelist" style="max-width: 90%;margin-top: -45px">
         <hr>
         
         <table class="table table-bordered"  >
              <thead>
              <tr>
                <td>
                  <div class="row">
                        <!-- modificata con quella sotto per demo uniscrow
                      <div class="col-sm" style="margin-left:5%; float:center; text-align: center; max-width:45%" ><h2><strong>{{searchevents(this.labels,'menu','mynews')}}</strong></h2></div>
                        -->
                      <div class="col-sm" style="float:center; text-align: center;" ><h2><strong>{{searchevents(this.labels,'menu','mynews')}}</strong></h2></div>
                    
                     
                     <!-- eliminata per demo uniscrow  <div class="col-sm" style="margin-left:5%; float:center; text-align: center; max-width:45%" ><h2><strong>{{searchevents(this.labels,'table','selectedforyou')}}</strong></h2></div> -->
                  </div>
                </td>
                </tr>
              <tr>
                 <td>
                  <div class="row">
                     <!-- modificata con quella sotto per demo uniscrow  <div class="col-sm" style="margin-left:5%; float:center; text-align: center; max-width:45%"> -->
                     <div class="col-sm" style="margin-left:28%; float:center; text-align: center; max-width:45%">
                       <VueRssFeed :feedUrl="searchevents(this.labels,'http','rssitacommodities')" :name="Sole24oreCommodities" :limit="limit"/>   
                      </div>
                        <!-- eliminata per demo uniscrow  <div class="col-sm" style="margin-left:5%;margin-right:2%; float:center; text-align: center; max-width:45%">
                          <div :class="'row blu-header'">
                                    <div class="col-sm" >{{searchevents(labels,'companies','countryOfOrigin')}}</div>
                                    <div class="col-sm" >{{searchevents(labels,'companies','companyName')}}</div>
                                    <div class="col-sm" >{{searchevents(labels,'matchmaking','ateco1')}}</div>
                        -->
                                    <!-- <div class="col-sm" >{{searchevents(labels,'matchmaking','mainactivity')}}</div> -->
                                   
                               <!-- eliminata per demo uniscrow    
                                    <div class="col-sm" >{{searchevents(labels,'matchmaking','companydetails')}}</div>
                              -->
                    <!-- eliminata per demo uniscrow       
                              </div> 
                     -->          
                                


                                <!-- <template v-for="(row, index) in orderByScore(goBizList)" class="list-group-item">
                                <div :key="row.id" :class="{'row yellow-light': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">
                                    <div class="col-sm" >{{returnDB(row.db)}} </div>
                                    <div class="col-sm" >{{row.a1}}</div>
                                    <div class="col-sm" >{{row.a2}}</div>
                                    <div class="col-sm" v-if="lang=='Ita'" >{{row.a3}}</div>
                                    <div class="col-sm" v-if="lang=='Eng'" >{{row.a31}}</div>
                                    <div class="col-sm" v-if="lang=='Den'" >{{row.a32}}</div>
                                 </div>   
                                </template> 
                                -->
                  <!-- eliminata per demo uniscrow
                       </div>
                  -->

                   </div>
                </td>
              </tr>
              </thead>
              </table>
              
      </div>
      <!-- fine box e immagini Visible only on md and lg -->
           
    
         <!-- DUE SEZIONI - PER USO FUTURO
         <table class="table table-bordered"  >
              <thead>
              <tr>
                <td>
                  <div class="row">
                      <div class="col-6" ><h2><strong>{{searchevents(this.labels,'menu','mynews')}}</strong></h2></div>
                      <div class="col-6" >{{searchevents(this.labels,'table','companynamelabel')}}</div>
                  </div>
                </td>
                  
              </tr>
              </thead>
              </table>
              
          -->
      </template>  



    </div>  


   <div class="container tablelist" style="max-width: 95%;" v-if="this.getUserRole=='USR'">
  
    </div>  <!-- added to network -->


    
  </div>
</template>


<script>

import router from '@/router';
import gAxios from 'axios';
import _ from 'lodash'; 
import VueRssFeed from '../../../node_modules/vue-rss-feed/src/VueRssFeed.vue';
// import VueRssParser from "vue-rss-parser";

export default {
  components : {
  VueRssFeed,
  // VueRssParser,
  },


  name: 'Login',
  data () {
    return {
      feedUrl: "",
      name: "",
      limit: 5,
      mycompanies : [],
      mycompaniesSearch : [],
      searchStatus : null,
      searchName : null,
      dropCompaniesState : [],
      companyVisuraRequestById : [],
      companyMessagesById : [],
      messagesReceived : null,
      addedToNetwork : [],
      visuraRequest : null,
      dropMofficeRegion : null,
      companyCompleteStopGo : '',
      remoteServer : process.env.VUE_APP_CONN_NODEJS,
      mycompaniesDetails : [],
      mycompaniesDetailsDocs : [
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''}],
      mycompaniesDetailsCountries: [
      {"cnam1":''}],
      companyTypeSelected : null,
      selectedCompanyTable : null,
      keyReload : 0,
      serverNode: process.env.VUE_APP_CONN_NODEJS 
    }
  },
  computed : {
    getIdUsersParent(){
       // return null;
       return this.$store.getters.getIdUsersParent;
    },
    sortedItems: function() {
      return true;
      //return myNewComp.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
      //console.log(this.mycompanies);
      //return this.mycompanies;
    },
    gettotalmessages(){
      return this.$store.getters.gettotalmessages;
    },
    gettotalrequestedvisura(){
      return this.$store.getters.gettotalrequestedvisura;
    },

    sortCompaniesByUpdatedAt: function(comp){
      return comp.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
      );
    },
    orderedMyCompaniesStatusNotWorked: function () {
      //da valutare se estendere a superadmin
      if(this.getUserRole=='BOF'){
       
       let search = 'S';
         
        return _.orderBy(this.mycompanies.filter(comp => {
          return comp.servCompaniesStateCompaniesState.toLowerCase().includes(search.toLowerCase());
        }), 'updatedAt');

      }else{
        return null;
      }
      
    },
    orderedMyCompaniesStatusWorked: function () {

      let search = 'A';
      let search2 = 'P';
      let search3 = 'H';
      
      let returnAll = [];
      let compReturn = [];
      let compReturn2 = [];
      let compReturn3 = [];
      

      compReturn = _.orderBy(this.mycompanies.filter(comp => {
        return comp.servCompaniesStateCompaniesState.toLowerCase().includes(search.toLowerCase());
      }), 'updatedAt');

      compReturn2 = _.orderBy(this.mycompanies.filter(comp => {
        return comp.servCompaniesStateCompaniesState.toLowerCase().includes(search2.toLowerCase());
      }), 'updatedAt');

      compReturn3 = _.orderBy(this.mycompanies.filter(comp => {
        return comp.servCompaniesStateCompaniesState.toLowerCase().includes(search3.toLowerCase());
      }), 'updatedAt');

      
      compReturn.forEach(u => {
        returnAll.push(u);
        //console.log(u);
      });

      compReturn2.forEach(u2 => {
        returnAll.push(u2);
        //console.log(u2);
      });

      compReturn3.forEach(u3 => {
        returnAll.push(u3);
        //console.log(u3);
      });

      
      if(this.getUserRole=='BOF'){
        return returnAll;
      }else{
        return null;
      }
      
    },
    orderedMyCompanies: function () {
      
      if(this.getUserRole=='USR'){
        return _.orderBy(this.mycompanies, 'companyName');
      }else{
        return null;
      }
      
    },
    orderedMyCompaniesSearch: function () {
      
      return _.orderBy(this.mycompaniesSearch, 'companyName')
    },
    auth(){
      return this.$store.getters.isAuth;
    },
    lang(){
      var lng = this.$store.getters.lang;
        if(lng)
        {
          return lng;
        }else{
          return '';
        }
    },
    labels(){
      return this.$store.getters.labels;
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
    getUserId(){
        return this.$store.getters.getUserId;
    },
    getCompanyIdTo(){
        return this.$store.getters.getCompamyIdTo;
    },
    getUserRole(){
      return this.$store.getters.getUserRole;
    },
    getUser(){
      return this.$store.getters.user;
    },
    userIdentity(){
      return this.$store.getters.getUserNameSurname;
    },
    dropCompaniesStateBO: function() {
        let newdoc = [];
        let newdoc1 = [];
        
        this.dropCompaniesState[0].forEach(u => {
          // aggiunte le appena registrate nella ricerca
          //if(u.companiesState!='R' && u.companiesState!='C'){
            if(u.companiesState!='C'){
            newdoc1.push(u);
          }
        });
        
        newdoc.push(newdoc1);
        
				return newdoc;
			
    },
    dropM(){
      var menu = this.$store.getters.dropM;
      if(menu){
        //this.dropM1 = menu[11];
        return menu;
      }else{
        return null;
      }
      
    }
  },
  methods : {
    ciccio(){
      this.keyReload = 11;
    },
    returnHumanDate(dt){
      if(dt!=null){
        let newDate = '';
        newDate = dt.substring(0,10);
        newDate = newDate.split('-');
        return newDate[2]+'/'+newDate[1]+'/'+newDate[0];
      }
    },
    returnCompaniesState(state){
      let response = '';
      let dropCompaniesState = this.dropCompaniesState;
      
      dropCompaniesState[0].forEach((value) => {
        if(value['companiesState']==state){
          if(this.lang=='Ita'){ response = value['companiesStateIta']; }
          if(this.lang=='Eng'){ response = value['companiesStateEng']; }
          if(this.lang=='Den'){ response = value['companiesStateDen']; }
        }
      });

      return response;
    },
    returnCompaniesStateBO(state){
      let response = '';
      let dropCompaniesState = this.dropCompaniesState;
      
      dropCompaniesState[0].forEach((value) => {
        if(value['companiesState']==state){
          if(this.lang=='Ita'){ response = value['companiesStateDesc']; }
          if(this.lang=='Eng'){ response = value['companiesStateDesc']; }
          if(this.lang=='Den'){ response = value['companiesStateDesc']; }
        }
      });

      return response;
    },
    sendRequestVisura(companyId, guid){
        this.newmessage = 'Request for an Updated Version of the Company Report';
        this.sendNewMessage(companyId, guid);
    },
    /*
    sendNewMessage(companyId, guid){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

                let firstMessage = 'N';
                if(guid==''){ 
                  firstMessage = 'Y'; 
                  guid = this.generateUUID();
                }
                

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    companyIdFrom : getCompanyIdToAll.id,
                    companyIdTo : this.selectedCompanyId,
                    companyTable : this.mynetworkTable,
                    guid : this.selectedGuid,
                    firstMessage: firstMessage,
                    blockchainsHash : '',
                    message : this.newmessage,
                    messageTranslated : '',
                    chatId : '',
                    viewed: '0',
                    actionToDo : 'ADD'
                },auth)
                .then(res => {
                    this.sendEmail(this.selectedCompanyId,this.mynetworkTable,'SEND','',this.newmessage);
                    this.setSelectedCompanyId(this.selectedCompanyId,this.mynetworkTable,this.selectedGuid,null,null);
                    
                    this.newmessage = null;
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
                
    },
    */
    generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        
        return newGuid;
    },
    businessCardAction(action, companyId, guid){

      //const auth = {
      //  headers: {Authorization:'JWT ' + this.getToken} 
      //  }
      
      if(action=='addtomyfavorite'){
        // in ogni caso aggiungo ai preferiti, il server controlla se è già stata aggiunta
        this.addToMyNetwork(companyId,'companies','0','ADD','');
      }

      if(action=='sendmessage'){
        // in ogni caso aggiungo ai preferiti, il server controlla se è già stata aggiunta
        //this.addToMyNetwork(companyId,'companies','0','ADD','');

        // quest'operazione è possibile solo nelle pagine: area messaggi, network, match making
        //router.push('/mychats');
      }

      if(action=='reqvisura'){
        // quest'operazione è possibile solo nelle pagine: area messaggi, network, match making
        //this.sendRequestVisura(companyId, guid);
      }

      //alert(action+'--'+companyId);
      return action+guid;
    },
    doNothing(){
      return null;
    },
    returnYear(dt){
      if(dt){
       return dt.substring(0,4);
      }else{
        return '';
      }
    },
    setSelectedCompanyIdDetail(companyId,table){
        this.selectedCompanyTable = table;
        
        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                {
                   companyId : companyId,
                   action : table,
                   myuserId : this.getUserId
                },auth)
                .then(res => {
                    
                    this.mycompaniesDetails = res.data.company[0];

                    if(table=='companies'){
                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_doc'
                        },auth)
                        .then(res1 => {
                            
                            // docs here
                           
                            if(res1.data.company.length>0)
                            {
                                this.mycompaniesDetailsDocs = res1.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );

                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_countries'
                        },auth)
                        .then(res2 => {
                            
                            // docs here
                            
                            
                            if(res2.data.company.length>0)
                            {
                                this.mycompaniesDetailsCountries = res2.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );

                    }
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
        


    },
    bellCals(){
      const auth = {
          headers: {Authorization:'JWT ' + this.getToken} 
        }

          const userId = this.getUserId;

          gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
            {
                userId : userId,
                companyId : '-1',
                action : 'REQUESTEDVISURABYME'
            },auth)
            .then(res2 => {
                // prima di aggiungere la company verifico che non sia già presente
                
                this.$store.dispatch('setupdatedCertificate',res2.data );
                  
            })
            .catch(
            error => {
            return error;
            }
            );

          
          gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
            {
                userId : userId,
                companyId : '-1',
                action : 'REQUESTEDREGISTRATIONABYME'
            },auth)
            .then(res3 => {
                // prima di aggiungere la company verifico che non sia già presente
                    
                
                this.$store.dispatch('setcompanyRegisteredGoBiz',res3.data );
                
                  
            })
            .catch(
            error => {
              
            return error;
            }
            );


            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
            {
                userId : userId,
                companyId : '-1',
                action : 'ASKTOSEEVISURABYME'
            },auth)
            .then(res4 => {
                // prima di aggiungere la company verifico che non sia già presente
                    
                
                this.$store.dispatch('setrequestseevisura',res4.data );
                
                  
            })
            .catch(
            error => {
              
            return error;
            }
            );


            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
            {
                userId : userId,
                companyId : '-1',
                action : 'OKASKTOSEEVISURABYME'
            },auth)
            .then(res5 => {
                // prima di aggiungere la company verifico che non sia già presente
                    
                
                this.$store.dispatch('setokrequestseevisura',res5.data );
                
                  
            })
            .catch(
            error => {
              
            return error;
            }
            );


            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
            {
                userId : userId,
                companyId : '-1',
                action : 'KOASKTOSEEVISURABYME'
            },auth)
            .then(res6 => {
                // prima di aggiungere la company verifico che non sia già presente
                    
                
                this.$store.dispatch('setkorequestseevisura',res6.data );
                
                  
            })
            .catch(
            error => {
              
            return error;
            }
            );


    },
    checkNewVisuraRequest(companyId){

      let response = '';
      let visureList = this.gettotalrequestedvisura;
      visureList.forEach((value, index) => {
          
          
          if(index == companyId){
            if(this.lang=='Ita'){
              //response =  'Ci sono '+value+' richieste di aggiornamento della visura';
              response = value;
            }

            if(this.lang=='Eng'){
              //response =  'You have '+value+' requests of refresh your visura.';
              response = value;
            }
          }
        });

      return response;
        
      
    },
    checkNewMessages(companyId){

      let response = '';
      let messagesList = this.gettotalmessages;

      messagesList.forEach((value, index) => {
          
          if(companyId==89){
            //console.log(index);
          }
          if(companyId==88){
            //console.log(index);
          }
          if(index == companyId){
            if(this.lang=='Ita'){
              //response =  'Ci sono '+value+' richieste di aggiornamento della visura';
              response = value;
            }

            if(this.lang=='Eng'){
              //response =  'You have '+value+' requests of refresh your visura.';
              response = value;
            }

            if(this.lang=='Den'){
              //response =  'You have '+value+' requests of refresh your visura.';
              response = value;
            }

            response = value;
          }
        });

      return response;
        
      
    },
    companiesSearch(){
      const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/freeSearch',
                {
                   searchStatus : this.searchStatus,
                   searchName : this.searchName
                },auth)
                .then(res => {
                    
                    this.mycompaniesSearch = res.data.company;
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },
    getuserLRByCompany(companyId){

            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/userLRByCompany',
                {
                   companyId : companyId
                },auth)
                .then(res => {
                    
                    let users = res.data.users;
                    this.myusers = users;
                    let contractFlag = 'xxx';
                    
                    users.forEach(u => {
                         
                        contractFlag = u.guidContractFlag;
                    });
                   
                    return contractFlag;
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },
    sendEmail(companyIdTo, companyTable, action,emailAddressTo,newMessage){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
        

        if(companyTable=='companies'){
            
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyAll',
                        {
                            id : companyIdTo,
                            table : companyTable,
                            what : ''
                        },auth)
                        .then(res => {
                            
                            
                            let emailTo = '';
                            res.data.network.forEach(em =>{
                                if(emailTo==''){ emailTo = em.email; }else{
                                    emailTo = emailTo + ',' + em.email;
                                }
                            });
                            
                            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/sendemail',
                            {
                                userId : userId,
                                emailAddressTo : emailTo,
                                emailAddressFrom : this.selectedCompanyEmailFrom,
                                companyFrom : this.selectedCompanyName,
                                message : newMessage,
                                action: action
                            },auth)
                            .then(res => {
                                    
                                if(this.lang=='Ita'){ alert('Operazione completata con successo!'); }
                                if(this.lang=='Eng'){ alert('Operation completed succesfully!'); }
                                if(this.lang=='Den'){ alert('Operation afsluttet med succes!'); } 

                                return res;
                            })
                            .catch(
                                error => {
                                
                                return error;
                                }
                            );
                        })
                        .catch(
                            error => {
                            
                            return error;
                            }
                        );

            
                this.newmessage = null;
        }

    },
    gotoadvisory1(){
      //this.$store.dispatch('SetregisteredCompany', company );
      //this.addToMyNetwork(542,'companies','0','ADD','','N');
      router.push('/MyOndemandA');   
      
    },
    gotoadvisory2(){
      //this.$store.dispatch('SetregisteredCompany', company );
      //this.addToMyNetwork(542,'companies','0','ADD','','N');
      router.push('/MyOndemandB');   
      
    },
    gotoadvisory3(){
      //this.$store.dispatch('SetregisteredCompany', company );
      //this.addToMyNetwork(542,'companies','0','ADD','','N');
      //router.push('/MyAdvisoring');   
      router.push('/MyOndemandC')
    },
    addToMyNetwork(companyId,companyTable,score,action,emailAddressTo){
        const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
        }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/NetworkJoinByMe',
        {
            userId : this.getUserId,
            companyId : companyId,
            companyTable : companyTable,
            score : score,
            actionToDo : action
        },auth)
        .then(res => {
            // in base alla action passata, eseguo delle operazioni: aggiungo alla chat
            
            this.sendEmail(companyId,'companies','ADD','','Qualcuno ti ha aggiunto al proprio network / Someone added you to their network');
            if(this.lang=='Ita'){ alert('Operazione completata con successo!'); }
            if(this.lang=='Eng'){ alert('Operation completed succesfully!'); }
            if(this.lang=='Den'){ alert('Operation afsluttet med succes!'); }

            return res+emailAddressTo;
        })
        .catch(
        error => {
        
        return error;
        }
        );
    },
    goToNetwork(){
      router.push('/mynetwork');
    },
    getAllMessageToRead(){

      const userId = this.getUserId;
      const auth = {
          headers: {Authorization:'JWT ' + this.getToken} 
      }
            
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
        {
            userId : userId,
            action : 'MESSAGES'
        },auth)
        .then(res => {
            
            this.messagesReceived = res.data.numero;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
        {
            userId : userId,
            //userId : '13',
            action : 'ADDEDTONETWORK'
        },auth)
        .then(res => {
            
            this.addedToNetwork = res.data.company;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
        {
            userId : userId,
            companyId : '-1',
            action : 'REQUESTEDVISURA'
        },auth)
        .then(res => {
            
            this.visuraRequest = res.data.numero;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

           
    },
    setMyCompanyIdTo(companyIdTo,action){
        this.$store.dispatch('setcompanyIdTo', companyIdTo );
        if(action=='NET'){
            router.push('/mynetwork');
        }

        if(action=='CONT'){
            router.push('/mycontacts');
        }
        
    },
    setSelectedCompany(company){
      this.$store.dispatch('SetregisteredCompany', company );
      router.push('/mynetwork');
    },
    setSelectedCompanyMessage(company,who){
      this.$store.dispatch('SetregisteredCompany', company );
      if(who=='requestvisura'){
        router.push( { name: 'mychats', params: { mm: 'visura' } } );
      }else{
        router.push('/mychats');
      }
      
    },
    modifyCompany(company){
        this.$store.dispatch('SetregisteredCompany', company );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updatecompany');
        
    },
    addNewCompany(){
        this.$store.dispatch('SetregisteredCompany', null );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updatecompany');
        
    },
    mmCompany(company,action){
        this.$store.dispatch('SetregisteredCompany', company );
        //setTimeout(router.push('/updatecompany'),3000);
        if(action=='M1'){
          router.push( { name: 'matchmaking', params: { mm: 'ADV' } } );
        }
        if(action=='M2'){
          router.push( { name: 'matchmaking', params: { mm: 'SMT' } } );
        }
        
        
    },
    searchevents(events,filterValue,label){
        if(events){
          const filter = event => 
            event.tableName.includes(filterValue) &&
            event.fieldName.includes(label)
        
          var filteredArray = events.filter(filter);
          if(filteredArray.length >0)
          {
            if(this.lang)
            {
              var labelName = 'label'+this.lang;
              return filteredArray[0][labelName];
            }else{
              return '--';
            }
            
          }else{
            return '--';
          }
        }else{
          return null;
        }
      },
      

  },
  created(){
    
    if(this.dropCompaniesState.length===0 && this.dropM!=null){
      this.dropCompaniesState = this.dropM.serv_companies_states;
    }
    if(this.dropMofficeRegion===null && this.dropM!=null){
      this.dropMofficeRegion = this.dropM.serv_countries_lists;
    }
    
  },
  mounted: function(){
    this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been rendered
        this.bellCals();
        this.$store.dispatch('ResetregisteredCompany' );

        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
            if(userId!=null)
            {
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/allUsersMe',
                {
                   userId : userId,
                   userRole : this.getUserRole
                },auth)
                .then(res => {
                    
                    let userCompleted = '0';
                    let companyCompleted = '0';

                    let users = res.data.users;
                    
                    users.forEach(u => {
                        let company = u.companies;

                        company.forEach(comp => {

                              //console.log(comp);
                              userCompleted = '0';
                              companyCompleted = '0';
                              if(u.idUsersParent == '-1' && u.servUsersStateUsersState == 'A'){ userCompleted = '1';  }
                              if(comp.servCompaniesStateCompaniesState == 'A'){ companyCompleted = '1'; }
                              comp.atecoCode7 = userCompleted+'-'+companyCompleted;
                              if(this.getUserRole=='BOF' && userCompleted=='1' && companyCompleted=='1' && comp.lastAction!='Aggiornamento visura' && comp.lastAction!='Verifica stato' && comp.lastAction!='Aggiornamento Dati Mail'){
                                // do nothing
                                //console.log('loppo');
                                //console.log(comp);
                              }else{
                                
                                

                                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
                                {
                                    userId : userId,
                                    companyId : comp.id,
                                    action : 'REQUESTEDVISURA'
                                },auth)
                                .then(res => {
                                    
                                    // prima di aggiungere la company verifico che non sia già presente
                                let trovata = 0;
                                this.mycompanies.forEach(myC => {
                                  //console.log(myC);
                                    if(myC.id == comp.id){ 
                                      trovata++; 
                                      
                                      }
                                    
                                      })
                                      if(trovata==0){ 
                                          this.mycompanies.push(comp); 
                                          //console.log('?????????'+comp.id);
                                          if(comp.servCompaniesStateCompaniesState=='R' && this.companyCompleteStopGo!='stop'){
                                            this.companyCompleteStopGo = 'go';
                                          }else{
                                            this.companyCompleteStopGo = 'stop';
                                          }
                                          this.companyVisuraRequestById[comp.id] = res.data.numero;
                                           this.$store.dispatch('settotalrequestedvisura',this.companyVisuraRequestById );
                                      }
                                   
                                    
                                })
                                .catch(
                                error => {
                                return error;
                                }
                                );


                                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
                                {
                                    userId : userId,
                                    companyId : comp.id,
                                    action : 'MESSAGES'
                                },auth)
                                .then(res1 => {
                                    // prima di aggiungere la company verifico che non sia già presente
                                    //console.log(res1);
                                    this.mycompanies.forEach(myC1 => {
                                        
                                    this.companyMessagesById[comp.id] = res1.data.numero;
                                    this.messagesReceived = res1.data.numero;
                                    this.$store.dispatch('settotalmessages',this.companyMessagesById );
                                    //console.log('>>>>>>>>>>>>>'+comp.id+'xxxxx'+res1.data.numero+'DDDD'+res1.data.numero);
                                    this.keyReload++;
                                    
                                    return myC1;
                                })
                                      
                                      
                                    
                                })
                                .catch(
                                error => {
                                return error;
                                }
                                );


                                
                                

                                
                              }
                              
                              
                            
                        });
                        

                    });
                    
                   this.sortedItems;
                    //this.mycompanies = this.sortCompaniesByUpdatedAt();
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
            }else{
                return null;
            }
        this.getAllMessageToRead();
        
    })
  },
  watch : {
   gettotalmessages: function(){
     //console.log('qui!!!!!!!!!!!!!!');
     //this.orderedMyCompanies;
    //this.mycompanies.push('sddsd');
    //this.keyReload = 1;

   },
    dropM: function () {
      this.dropCompaniesState = this.dropM.serv_companies_states;
      this.dropMofficeRegion = this.dropM.serv_countries_lists;
    },
    mycompanies: function () {
      //if(this.mycompanies.length==1 && this.mycompanies[0].servCompaniesStateCompaniesState=='R' && this.getUserRole!='BOF'){
      //                          this.$store.dispatch('setPermittedUserAction','N' );
                                //this.modifyCompany(this.mycompanies[0]);
      //                        }
      if(this.companyCompleteStopGo == 'go' && this.getUserRole=='USR'){
        //this.$store.dispatch('setPermittedUserAction','N' );
        //this.modifyCompany(this.mycompanies[0]);
      }
    },
    
  },
  updated() {
        
    },
     
  // TAG Google Analytics rilevamento pagina
  //login () {
  //   this.$gtag.event('Login', { method: 'Google' })
  //  },
    
  //track() {
  //    this.$gtag.pageview("/dashboard"),
  //    this.$gtag.screenview({
  //      app_name: 'Login',
  //      screen_name: 'Dashboard',
  //    })
    
 //   },

  
  //track () {
  //    this.$gtag.query('event', 'screen_view', {
   //     app_name: 'Login',
   //     screen_name: 'Dashboard_access',
   //   })
  //  }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.count{
  font-size: 7em;
}
.btn{
  font-size: 3em;
}

.btn .badge {
    /* position: relative; */
    /* top: -15px; */
    /* right: -15px; */
    /* background-color: red;*/
    background-color: green;
    color: white;

}



select::-ms-expand {	display: none; }
select{
    -webkit-appearance: none;
    appearance: none;
}
@-moz-document url-prefix(){
	.ui-select{border: 1px solid #CCC; border-radius: 4px; box-sizing: border-box; position: relative; overflow: hidden;}
  .ui-select select { width: 110%; background-position: right 30px center !important; border: none !important;}
}

.modal-body .col-6{
  text-align: left;
}


</style>
