<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/gobiz-logo-rectangle-blue.png" width="250em">
    
    <hr>
    
    <div  v-if="this.lang=='Ita'"> 
    <h5 style="text-align: left; margin-left: 10%; margin-right:10%">
    <strong>I nostri utenti "Tipo":</strong> <br> 
 <br>  <br>
# Responsabili Acquisti e Sales Manager <br> <br> 

# Gestori di aziende di distribuzione di piccola dimensione che cercano un partner estero<br><br> 

# Piccoli produttori agrari e di prodotti artigianali (Es Passata di pomodori, Pasta, Taralli, Conserve) <br><br> 

# Proprietari di piccole catene di punti vendita retail.<br><br> 

# Export manager per la ricerca di partner industriali<br><br>

# Ingegneri di produzione che hanno necessità di specifici macchinari o servizi per ottimizzare la produzione<br><br>

# Proprietari di piccole aziende interessati a individuare i competitor su base nazionale ed Europea<br><br>

<br> 



    </h5>
    </div>
    
    <div  v-if="this.lang=='Eng'"> 
    <h5 style="text-align: left; margin-left: 10%; margin-right:10%">
<strong>Our users are:</strong> <br>
  <br> <br>
# Purchasing Managers and Sales Managers <br> <br>

# Managers of small distribution companies looking for a foreign partner<br><br>

# Small agricultural producers and handicraft products (e.g. tomato puree, pasta, taralli, preserves) <br><br>

# Owners of small retail chains.<br><br>

# Export manager for the search for industrial partners<br><br>

# Production engineers who need specific machinery or services to optimize production<br><br>

# Small business owners interested in identifying competitors on a national and European basis<br><br>

<br>
      </h5> 
   </div>
    
    <div  v-if="this.lang=='Den'"> 
    <h5 style="text-align: left; margin-left: 10%; margin-right:10%">
<strong>Vores brugere er:</strong> <br>
  <br> <br>
# Indkøbschefer og salgschefer <br> <br>

# Ledere af små distributionsselskaber på udkig efter en udenlandsk partner<br><br>

# Små landbrugsproducenter og håndværksprodukter (f.eks. tomatpuré, pasta, taralli, konserves) <br><br>

# Ejere af små detailkæder.<br><br>

# Eksportansvarlig for søgningen efter industrielle partnere<br><br>

# Produktionsingeniører, der har brug for specifikke maskiner eller tjenester for at optimere produktionen<br><br>

# Små virksomhedsejere interesseret i at identificere konkurrenter på nationalt og europæisk grundlag<br><br>

<br>
 
  </h5>
    </div>

    <br>

    <!-- axios disabilitato
    <div  v-if="this.lang=='Ita'" v-html="this.titlePage"> </div>
    <h4 v-if="this.lang=='Ita'"><div v-html="this.contentPage">  </div> </h4>
    
    <div  v-if="this.lang=='Eng'" v-html="this.titlePageEng"> </div>
    <h4 v-if="this.lang=='Eng'"><div v-html="this.contentPageEng">  </div> </h4>

    <div  v-if="this.lang=='Den'" v-html="this.titlePageDen"> </div>
    <h4 v-if="this.lang=='Den'"><div v-html="this.contentPageDen">  </div> </h4>
    -->
  </div>
</template>

<script>
// @ is an alias to /src
import gAxios from 'axios';

export default {
  name: 'help',
  data () {
    return {
      name : 'stefano',
      surname : '',
      email : '',
      password : '',
      titlePage : null,
      titlePageEng : null,
      titlePageDen : null,
      contentPage : null,
      contentPageEng : null,
      contentPageDen : null
    }
  },
  computed : {
    labels(){
      
      return this.$store.getters.labels;
    },
    lang(){
      return this.$store.getters.lang;
    },
  },
  components: {
    
  },
  mounted: function(){
    this.$nextTick(function () {
        
        // ITA
        gAxios.get('./pages/new_ita.txt',
        {
            
        })
        .then(res => {
          
          this.titlePage = '';
          this.contentPage = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

        // ENG
        gAxios.get('./pages/new_eng.txt',
        {
            
        })
        .then(res => {
          this.titlePageEng = '';
          this.contentPageEng = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );

        // DEN
        gAxios.get('./pages/new_den.txt',
        {
            
        })
        .then(res => {
          this.titlePageDen = '';
          this.contentPageDen = res.data;
            return res;
            
        })
        .catch(
        error => {
        
        return error;
        }
        );
            
    })
  }
}



</script>






<style>

.full-width-image {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none; /* Per evitare che l'immagine interferisca con l'interazione dell'utente */
}

</style>
