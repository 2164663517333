<template>
  <div class="container" style="max-width: 98% !important;">
    
    <div class="text-center">
    <button v-if="this.selectedCompanyId!=null" type="button" class="btn btn-success" style="font-size: .8em;" @click="setSelectedCompanyId(null,null,null,null,null)" >{{searchevents(labels,'menu','backglobal')}}</button>
    <button v-if="this.selectedCompanyId==null" type="button" class="btn btn-success" style="font-size: .8em;" @click="backToMyCompanies()">{{searchevents(this.labels,'menu','backglobal')}} </button>
    </div>
    <hr>
      <h1>{{searchevents(this.labels,'menu','mynetwork')}}</h1>
      
    <hr>

            <div class="alert alert-light alert-dismissible fade show" style="margin-left: 14%; margin-right: 14%; height: fill-available;">
               <div class="row">
                <div class="col-sm">
                  <div class="card bg-light mt-6">
                      <div class="card-body">
                        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        <div  style="font-weight: bold">
                        <i class="fas fa-fw fa-lg fa-info-circle mr-2 align-self-center" style="font-size:25px;width: 100%;margin-left: 10px;margin-right: 10px;color: #0C294B;"></i>
                        <h5 v-html="searchevents(this.labels,'textinfo','mynetworkdesc')"></h5>
                        </div>
                        <!-- <h5><strong>OLD (ex tooltip):</strong></h5>
                        <h5>{{searchevents(labels,'dialog','mmalgoritmogobiz')}}</h5> -->
                       </div> 
                </div>
              </div>
           </div>
        </div> 
  <br>
        <!--
        <div class="container tablelist" style="max-width: 95%;">
            <div class="row" style="font-size: 0.8em;  margin-left: 20px; padding: 5px;">
                <div style="background-color: #07ff31; width: 20px; margin-right: 5px; margin-left: 0px;"></div>
                <div> {{searchevents(this.labels,'menu','emailtoread')}}  </div>
                <div style="background-color: #ffc107; width: 20px; margin-right: 5px; margin-left: 10px;"></div>
                <div> {{searchevents(this.labels,'menu','emailpendingextragobiz')}}  </div>
                <div style="background-color: #07baff; width: 20px; margin-right: 5px; margin-left: 10px;"></div>
                <div> {{searchevents(this.labels,'menu','emailfirstmessage')}}  </div>
                <div style="background-color: #ff07f7; width: 20px; margin-right: 5px; margin-left: 10px;"></div>
                <div> {{searchevents(this.labels,'menu','emailsentwaitforanswer')}}  </div>
            </div>
        </div>
        -->

    
    <!-- 1# All added me to their network ######################################################################## -->
    <div v-if="this.selectedCompanyId==null" class="container tablelist" style="max-width: 95%;">
            <h2><strong>{{searchevents(this.labels,'dashboard','addedtonetwork')}}</strong></h2>
            <template>        
              <table class="table tablelist" >
              <thead>
              <tr>
                <td>
                <!-- <td class="align-middle"> -->
                  <div :class="'row blu-header'">
                      

                      <div class="col-sm" >{{searchevents(labels,'companies','companyName')}}</div>
                     
                      <div class="col-sm" >{{searchevents(labels,'companies','countryOfOrigin')}}</div>
                      <div class="col-sm" >{{searchevents(labels,'matchmaking','ateco1')}}</div>
                       <div class="col-sm" >{{searchevents(labels,'table','companydetails')}}</div>
                      
                      <!-- <div class="col-sm" >{{searchevents(labels,'matchmaking','mainactivity')}}</div> -->

                      <div class="col-sm" >{{searchevents(labels,'menu','addtomynetwork')}} </div>
                      <!-- <div class="col-sm" >{{searchevents(labels,'table','companynamelabel')}}</div> -->
                      <div class="col-sm" >{{searchevents(this.labels,'menu','removeglobal')}}</div>
                  </div>
                </td>
                  
              </tr>
              </thead>
              <tbody>
                  <tr> <td>
                       <template>
                        <div>
                        <div v-for="(row, index) in addedToNetwork"  class="list-group-item" :key="row.companyName">
                          <div  :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">
                             
                               <div class="col-sm" >{{row.companyName}}</div>
                               <div class="col-sm" >{{row.countryName}}</div>

                               <div class="col-sm" v-if="lang=='Ita'" > {{row.aa3}}{{row.ab3}}</div>
                               <div class="col-sm" v-if="lang=='Eng'" > {{row.aa31}}{{row.ab31}}</div>
                               <div class="col-sm" v-if="lang=='Den'" > {{row.aa32}}{{row.ab32}}</div>

                              <div class="col-sm">
                                <button type="button" class="btn btn-primary" @click="setSelectedCompanyIdDetail(row.id,row.companyTable,'Y','N')">{{searchevents(labels,'matchmaking','companydetails')}}</button>
                              </div>

                              <!-- div class="col-sm" > {{row.a4}} </div> -->
                              
                              <div class="col-sm">
                                  <button type="button" ref="diotallevi" class="btn btn-success" style="font-size: .8em;" @click="addToMyNetwork(row.addId,'companies',row.score,'ADD','')" >{{searchevents(labels,'menu','addtomynetwork')}}</button>
                              </div> 

                              <div class="col-sm">
                                 <button type="button" class="btn btn-danger" style="font-size: .8em;" @click="onClickDeleteNetwork(row.id,row.companyTable)"  >{{searchevents(labels,'menu','delete')}}</button>
                              </div>
                            <!-- <div class="col-sm" style="background-color:#e5d237;" > {{row.MyCompany}}</div> -->
                      </div>
                        </div>
                          </div>
                      </template>
                      
                  </td>
                  </tr>
              </tbody>
          </table>
          
          </template>
    </div> <!-- termine container -->
<hr>
    <!-- 2# Selected not worked ########################################################################################### -->
    <div v-if="this.selectedCompanyId==null" class="container tablelist" style="max-width: 95%;">
            <h2>{{searchevents(this.labels,'menu','mynetworkselectednoactions')}}</h2>

            <template>        
                    <table class="table tablelist" >
                    <thead>
                    <tr>
                      <td>
                        <div :class="'row blu-header'">
                            
                            <div class="col-sm" >{{searchevents(labels,'matchmaking','companyDB')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'companies','companyName')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'companies','countryOfOrigin')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'matchmaking','ateco1')}}</div>
                            <!-- <div class="col-sm" >{{searchevents(labels,'matchmaking','mainactivity')}}</div> -->
                            <div class="col-sm" >{{searchevents(labels,'table','companydetails')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'matchmaking','matchingscore')}}</div>
                            <div class="col-sm" >{{searchevents(this.labels,'menu','workglobal')}}</div>
                            <div class="col-sm" >{{searchevents(this.labels,'menu','removeglobal')}}</div>

                        </div>
                      </td>
                        
                    </tr>
                    </thead>
                    <tbody>
                        <tr><td >
                        <!-- <template v-for="row0 in MMComanies" > -->
                        <template>
                        <div>
                        <div v-for="(row, index) in mynetwork"  class="list-group-item" :key="row.companyName" >
                                <div :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">
                                    <div class="col-sm" >{{returnDB(row.db)}}</div>

                                    <div class="col-sm" >{{printCompanyName('companyName',row.aa1, row.ab1)}}</div>
                                    <div class="col-sm" >{{printCompanyName('companyCountry',row.aa2, row.ab2)}}</div>

                                    <div class="col-sm" v-if="lang=='Ita'" > {{row.aa3}}{{row.ab3}}</div>
                                    <div class="col-sm" v-if="lang=='Eng'" > {{row.aa31}}{{row.ab31}}</div>
                                    <div class="col-sm" v-if="lang=='Den'" > {{row.aa32}}{{row.ab32}}</div>

                                    <!-- <div class="col-sm" >{{row.a4}}</div> -->
                                   
                                    <div class="col-sm" >
                                        <!-- <a data-toggle="collapse" :href="'#notWorked'+index" class="list-group-item">{{searchevents(labels,'matchmaking','companydetails')}}</a> -->
                                        <button v-if="row.obligatoriskEmail!=''" type="button" class="btn btn-primary" @click="setSelectedCompanyIdDetail(row.id,row.companyTable,'N','Y')">{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                        <button v-if="row.obligatoriskEmail==''" type="button" class="btn btn-primary" @click="setSelectedCompanyIdDetail(row.id,row.companyTable,'N','N')">{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                    </div>
                                    <div class="col-sm" >{{row.score}}</div>

                                    <div class="col-sm" v-if="row.db=='companies' && (row.servCompaniesStateCompaniesState1!='H' && row.servCompaniesStateCompaniesState2!='H') ">
                                       <button type="button" class="btn btn-success" style="font-size: .8em;" @click="setSelectedCompanyId(row.id,row.companyTable,'-1',null,null)" >{{searchevents(labels,'menu','workglobal')}}</button>
                                    </div>
                                    <div class="col-sm" v-if="row.db=='companies' && (row.servCompaniesStateCompaniesState1=='H' || row.servCompaniesStateCompaniesState2=='H' )  " >
                                        {{searchevents(labels,'companies','suspended')}}
                                    </div>



                                    <div class="col-sm" v-if="row.db=='company_infocamere' && row.Email!=''">
                                        <!-- <button type="button" class="btn btn-success" style="font-size: .8em;" @click="setSelectedCompanyId(row.id,row.companyTable,'-1',null,null)" >{{searchevents(labels,'menu','sendemailglobal')}}</button> -->
                                        <!-- <button type="button" style="font-size: .8em; background-color: #cccccc; color:black;  border-color: #cccccc; border-radius: .3rem;" disabled >{{searchevents(labels,'menu','sendemailglobal')}}</button> -->
                                    </div>
                                    <div class="col-sm" v-if="row.db=='company_infocamere' && row.Email==''">
                                        <!-- <button type="button" style="font-size: .8em; background-color: #cccccc; color:black;  border-color: #cccccc; border-radius: .3rem;" disabled  >{{searchevents(labels,'matchmaking','notcontactable')}}</button> -->
                                    </div>

                                    <div class="col-sm" v-if="row.db=='company_house'">
                                        <!-- <button type="button" style="font-size: .8em; background-color: #cccccc; color:black; border-color: #cccccc; border-radius: .3rem;" disabled >{{searchevents(labels,'menu','sendemailglobal')}}</button> -->
                                    </div>

                                    <div class="col-sm" v-if="row.db=='company_denmark' && row.obligatoriskEmail!=''">
                                        <!-- <button type="button" style="font-size: .8em; background-color: #cccccc; color:black;  border-color: #cccccc; border-radius: .3rem;" disabled >{{searchevents(labels,'menu','sendemailglobal')}}</button> -->
                                    </div>
                                    <div class="col-sm" v-if="row.db=='company_denmark' && row.obligatoriskEmail==''">
                                        <!-- <button type="button" style="font-size: .8em; background-color: #cccccc; color:black;  border-color: #cccccc; border-radius: .3rem;" disabled  >{{searchevents(labels,'matchmaking','notcontactable')}}</button> -->
                                    </div>

                                    <div class="col-sm">
                                        <button type="button" class="btn btn-danger" style="font-size: .8em;" @click="onClickDeleteNetwork(row.id,row.companyTable)"  >{{searchevents(labels,'menu','delete')}}</button>
                                    </div>

                            </div>
                            <div :key="index"  :id="'notWorked'+index" class="panel-collapse collapse">
                                <div class="col-sm-12" >
                                    {{renderCompanyProfile(row)}} {{row}}
                                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myBusinessCard" >{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                </div>
                            </div>
                              </div>
                                </div>
                            </template>
                            
                        </td>
                        </tr>
                    </tbody>
                </table>
                
                </template>
                
            

    </div> <!-- termine container -->
<hr>
    <!-- 3# All worked ########################################################################################### -->
    <div v-if="this.selectedCompanyId==null" class="container tablelist" style="max-width: 95%;">
            <h2>{{searchevents(this.labels,'menu','mynetworkallworked')}}</h2>

            <template>        
                    <table class="table tablelist" >
                    <thead>
                    <tr>
                      <td>
                        <div :class="'row blu-header'">
                            <div class="col-sm" >{{searchevents(labels,'matchmaking','companyDB')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'companies','companyName')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'companies','countryOfOrigin')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'matchmaking','ateco1')}}</div>
                            <!-- <div class="col-sm" >{{searchevents(labels,'matchmaking','mainactivity')}}</div> -->
                            <div class="col-sm" >{{searchevents(labels,'table','companydetails')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'matchmaking','matchingscore')}}</div>
                            <!-- <div class="col-sm" >{{searchevents(this.labels,'table','viewedchat')}}</div> -->
                            <div class="col-sm" >{{searchevents(this.labels,'menu','workglobal')}}</div>

                            <!-- <div class="col-sm" >{{searchevents(labels,'matchmaking','mynetworkstatus')}}</div> -->

                            <div class="col-sm" >{{searchevents(labels,'menu','gobanned')}}</div>

                            <!--
                            <div class="col-sm" >{{searchevents(this.labels,'menu','networkstatusglobal')}}</div>
                            <div class="col-sm" >{{searchevents(this.labels,'table','viewedchat')}}</div>
                            <div class="col-sm" >{{searchevents(this.labels,'companies','companyName')}}</div>
                            <div class="col-sm" >{{searchevents(this.labels,'matchmaking','companydetails')}}</div>
                            <div class="col-sm" >{{searchevents(this.labels,'menu','workglobal')}} </div>
                            -->
                        </div>
                      </td>
                        
                    </tr>
                    </thead>
                    <tbody>
                        <tr><td >
                        <!-- <template v-for="row0 in MMComanies" > -->
                        <template>
                        <div>
                        <div v-for="(row, index) in mynetworkAll"  class="list-group-item" :key="index+25">
                                <div  :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">
                                    
                                    <div class="col-sm" >{{returnDB(row.db)}} </div>

                                    <div class="col-sm" >{{printCompanyName('companyName',row.aa1, row.ab1)}}</div>
                                    <div class="col-sm" >{{printCompanyName('companyCountry',row.aa2, row.ab2)}}</div>
                                    <!-- <div class="col-sm" >{{printCompanyName('companyAteco',row.aa3, row.ab3)}} {{row}}</div> -->

                                    <div class="col-sm" v-if="lang=='Ita'" > {{row.aa3}}{{row.ab3}}</div>
                                    <div class="col-sm" v-if="lang=='Eng'" > {{row.aa31}}{{row.ab31}}</div>
                                    <div class="col-sm" v-if="lang=='Den'" > {{row.aa32}}{{row.ab32}}</div>
                                    <!-- <div class="col-sm" >{{printCompanyName('companyDescription',row.aa4, row.ab4)}}</div> -->
                                   
                                    <div class="col-sm" >
                                        <!-- <a data-toggle="collapse" :href="'#collapseMM'+index" class="list-group-item">{{searchevents(labels,'matchmaking','companydetails')}}</a> -->
                                        <button type="button" class="btn btn-primary" data-target="#myBusinessCard" @click="setSelectedCompanyIdDetail(renderCompanyProfileId(row),row.companyTable,'N','N')">{{searchevents(labels,'table','companydetails')}}</button>
                                    </div>
                                    <div class="col-sm" >{{returnMyWorkedNetworkScore(row.score1,row.score2)}} </div>
                                    <!-- <div class="col-sm" >{{returnViewed(row.viewed)}} </div> -->

                                    <div class="col-sm" v-if="row.db=='companies' && (row.companyIdBanned=='-1' || row.companyIdBanned!=MyCompanyId) && (row.servCompaniesStateCompaniesState1!='H' && row.servCompaniesStateCompaniesState2!='H')" >
                                        <button type="button" class="btn btn-success" style="font-size: .8em;" @click="setSelectedCompanyId(row.id,row.companyTable,row.guid,row.companyId1,row.companyId2)" :key="row.id" >{{searchevents(labels,'menu','workglobal')}}</button>
                                    </div>
                                    <div class="col-sm" v-if="row.db=='companies' && (row.servCompaniesStateCompaniesState1=='H' || row.servCompaniesStateCompaniesState2=='H' ) " >
                                        {{searchevents(labels,'companies','suspended')}}
                                    </div>
                                    <div class="col-sm" v-if="row.db=='companies' && (row.companyIdBanned!='-1' && row.companyIdBanned==MyCompanyId) " >
                                        {{searchevents(labels,'dialog','opnotpermittenomessage')}}
                                    </div>
                                    <div class="col-sm" v-if="row.db!='companies'">
                                       <div v-if="row.message!='expired'">{{searchevents(labels,'matchmaking','nomoreactionspobbile')}}</div>
                                       <div v-if="row.message=='expired'">
                                           {{searchevents(labels,'matchmaking','deleteinvitednotaccepted')}}<br>
                                           <button type="button" class="btn btn-danger" style="font-size: .8em;" @click="onClickDeleteNetwork(row.companyIdTo,row.companyTable)"  >{{searchevents(labels,'menu','delete')}}</button>
                                        </div>

                                    </div>

                                    <!-- <div class="col-sm" :style="setBackgroudStyleStatus(row.userId, row.firstMessage, row.companyTable)">{{setBackgroudStyleStatusText(row.userId, row.firstMessage, row.companyTable)}}</div> 
                                    <div class="col-sm" >{{setBackgroudStyleStatusText(row.userId, row.firstMessage, row.companyTable)}}</div> -->
 
                                    <div class="col-sm" v-if="row.companyIdBanned=='-1' || row.companyIdBanned!=MyCompanyId" >
                                        <button type="button" class="btn btn-warning" style="font-size: .8em;" @click="banCompanyConfirm(row.guid,row.companyId1,row.companyId2)"  >{{searchevents(labels,'menu','gobanned')}}</button>
                                    </div>

                                    <div class="col-sm" v-if="row.companyIdBanned!='-1' && row.companyIdBanned==MyCompanyId" >
                                        
                                    </div>
                            </div>
                            <div :key="index"  :id="'collapseMM'+index" class="panel-collapse collapse">
                                <div class="col-sm-12" >
                                    {{renderCompanyProfile(row)}}
                                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myBusinessCard" >{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                    <button type="button" class="btn btn-warning" style="font-size: .8em;" @click="banCompanyConfirm(row.guid,row.companyId1,row.companyId2)"  >{{searchevents(labels,'menu','gobanned')}}</button>
                                </div>
                            </div>

                             </div>
                              </div>
                            </template>
                            
                        </td>
                        </tr>
                    </tbody>
                </table>
                
                </template>

            

    </div> <!-- termine container -->
<hr>
    <!-- 4# All banned ########################################################################################### -->
    <div v-if="this.selectedCompanyId==null" class="container tablelist" style="max-width: 95%;">
            <h2>{{searchevents(this.labels,'menu','mynetworkallbanned')}}</h2>

            <template>        
                <table class="table tablelist" >
                <thead>
                
                <tr>
                      <td>
                        <div :class="'row blu-header'">
                            <div class="col-sm" >{{searchevents(labels,'matchmaking','companyDB')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'companies','companyName')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'companies','countryOfOrigin')}}</div>
                            <div class="col-sm" >{{searchevents(labels,'matchmaking','ateco1')}}</div>
                            <!-- <div class="col-sm" >{{searchevents(labels,'matchmaking','mainactivity')}}</div> -->
                            <div class="col-sm" >{{searchevents(labels,'table','companydetails')}}</div>
                            
                            <!-- <div class="col-sm" >{{searchevents(labels,'matchmaking','mynetworkstatus')}}</div> -->

                            <div class="col-sm" >{{searchevents(labels,'menu','gounbanned')}}</div>
                        </div>
                      </td>
                        
                    </tr>
                    </thead>
                    <tbody>
                        <tr><td >
                        <!-- <template v-for="row0 in MMComanies" > -->
                          <template>
                        <div>
                        <div v-for="(row, index) in mynetworkAllBanned"  class="list-group-item"  :key="index+25">
                                <div  :class="{'row blu-ligth': index % 2 === 0, 'row blu-dark': index % 2 !== 0 }">
                                    <div class="col-sm" >{{returnDB(row.db)}} </div>

                                    <div class="col-sm" >{{printCompanyName('companyName',row.aa1, row.ab1)}}</div>
                                    <div class="col-sm" >{{printCompanyName('companyCountry',row.aa2, row.ab2)}}</div>
                
                                    <div class="col-sm" v-if="lang=='Ita'" > {{row.aa3}}{{row.ab3}}</div>
                                    <div class="col-sm" v-if="lang=='Eng'" > {{row.aa31}}{{row.ab31}}</div>
                                    <div class="col-sm" v-if="lang=='Den'" > {{row.aa32}}{{row.ab32}}</div>

                                    <!-- <div class="col-sm" >{{row.a4}}</div> -->
                                   
                                    <div class="col-sm" >
                                        <!-- <a data-toggle="collapse" :href="'#banned'+index" class="list-group-item">{{searchevents(labels,'matchmaking','companydetails')}}</a> -->
                                        <button type="button" class="btn btn-primary" @click="setSelectedCompanyIdDetail(row.id,row.companyTable,'N','N')">{{searchevents(labels,'matchmaking','companydetails')}}</button>
                                    </div>

                                    <!-- <div class="col-sm" v-if="lang=='Ita'">Bannata</div> -->
                                    <!-- <div class="col-sm" v-if="lang=='Eng'">Banned</div> -->

                                    <div class="col-sm" >
                                        <button type="button" class="btn btn-warning" style="font-size: .8em;" @click="unBanCompanyConfirm(row.guid)"  >{{searchevents(labels,'menu','gounbanned')}}</button>
                                    </div>
                                                                        
                            </div>
                            <div :key="index"  :id="'banned'+index" class="panel-collapse collapse">
                                <div class="col-sm-12" >
                                    {{renderCompanyProfile(row)}} {{row}}
                                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myBusinessCard" >{{searchevents(labels,'table','companydetails')}}</button>
                                    <button type="button" class="btn btn-warning" style="font-size: .8em;" @click="unBanCompanyConfirm(row.guid)"  >{{searchevents(labels,'menu','gounbanned')}}</button>
                                </div>
                            </div>
                            </div>
                            </div>
                            </template>
                            
                        </td>
                        </tr>
                
            </tbody>
                </table>
                
        </template>

    </div> <!-- termine container -->


    <div v-if="this.selectedCompanyId!=null" class="container tablelist" style="max-width: 95%;">
            <h2>{{this.mycompanies.companyName}}</h2>

            <template>        
                <table class="table tablelist" >
                <thead>
                <tr>
                    <th>{{searchevents(this.labels,'table','messagechat')}} {{setViewedAllChatsByGuid()}}</th>
                    <th>{{searchevents(this.labels,'table','messagetranslatedchat')}}</th>
                    <th>{{searchevents(this.labels,'table','viewedchat')}}</th>
                    <th>{{searchevents(this.labels,'table','createdatchat')}}</th>
                    <th>{{searchevents(this.labels,'table','usertextnamechat')}}</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                    <th>
                        <textarea class="form-control" rows="3" id="newmessage" v-model="newmessage"></textarea>
                    </th>
                    <th> </th>
                    <th>{{searchevents(this.labels,'chat','sendnewmessage')}}</th>
                    <th>
                        <button type="button" class="btn btn-success" style="font-size: .8em;" @click="sendNewMessage()" >{{searchevents(labels,'menu','sendglobal')}}</button>
                    </th>
                    <th> </th>
                </tr>
                <tr v-for="row in mynetworkChatList" :key="row.id" >
                    <td  :key="row.message">
                        <div :style="setBackgroudStyle(''+row.userId+'',''+getUserId+'')">{{row.message}}</div>
                    </td>
                    <td  :key="row.messageTranslated">
                        {{row.messageTranslated}}
                        <button v-if="row.messageTranslated=='' || row.messageTranslated==null" type="button" class="btn btn-success" style="font-size: .8em;" @click="translateGoogle(row.message, row.id)" :key="row.id" >{{searchevents(labels,'menu','translateglobal')}}</button>
                    </td>
                    <td  :key="row.viewed">
                        {{renderMessageViewed(row.viewed)}}
                    </td>
                    <td  :key="row.createdAt">
                        {{frontEndDateFormat(row.createdAt)}}
                    </td>
                    <td  :key="row.userInfo">
                        {{row.userInfo}}
                    </td>
                    
                </tr>
                </tbody>
            </table>
            
            </template>

    </div> <!-- termine container -->

 
  </div> <!-- secondo container -->



</template>

<script>

import router from '@/router';
import gAxios from 'axios';


export default {

  name: 'mynetwork',
  data () {
    return {
      mycompanies : [],
      mycompaniesDetails : [],
      mycompaniesDetailsDocs : [
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''},
      {"name":'',"description3":''}],
      mycompaniesDetailsCountries: [
      {"cnam1":''}],
      companyTypeSelected : null,
      remoteServer : process.env.VUE_APP_CONN_NODEJS,
      myusers : [],
      mynetwork : [],
      mynetworkAll : [],
      addedToNetwork : [],
      mynetworkAllBanned : [], // quelli che io ho bannato
      mynetworkCH : [],
      mynetworkINFO : [],
      mynetworkChatList : [],
      mynetworkTable : null,
      selectedCompanyId : null,
      selectedGuid : '-1',
      selectedCompanyTable : null,
      MyCompanyId : null,
      usersRoleRoleCompany : 'ooo',
      confirmDeleteNetwork : 'Confermi la cancellazione? / Confirm the cancellation?',
      confirmBanCompany : 'Confermi di volere bannare questa compagnia? / Are you sure you want to ban this company? ',
      confirmUnBanCompany : 'Confermi di NON volere più bannare questa compagnia? / Are you sure you want to NOT ban this company? ',
      newmessage : null,

    }
  },
  methods : {

    /*
    sendRequestVisura(companyId, guid){
        this.newmessage = 'Request for an Updated Version of the Company Report';
        this.sendNewMessage(companyId, guid);
    },
    sendNewMessage(companyId, guid){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

                let firstMessage = 'N';
                if(guid==''){ 
                  firstMessage = 'Y'; 
                  guid = this.generateUUID();
                }
                

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    companyIdFrom : getCompanyIdToAll.id,
                    companyIdTo : this.selectedCompanyId,
                    companyTable : this.mynetworkTable,
                    guid : this.selectedGuid,
                    firstMessage: firstMessage,
                    blockchainsHash : '',
                    message : this.newmessage,
                    messageTranslated : '',
                    chatId : '',
                    viewed: '0',
                    actionToDo : 'ADD'
                },auth)
                .then(res => {
                    this.sendEmail(this.selectedCompanyId,this.mynetworkTable,'SEND','',this.newmessage);
                    this.setSelectedCompanyId(this.selectedCompanyId,this.mynetworkTable,this.selectedGuid,null,null);
                    
                    this.newmessage = null;
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
                
    },
    
    generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        
        return newGuid;
    },
    */
   addToMyNetwork(companyId,companyTable,score,action,emailAddressTo, messageYN){
        const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
        }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/NetworkJoinByMe',
        {
            userId : this.getUserId,
            companyId : companyId,
            companyTable : companyTable,
            score : score,
            myCompany : this.MyCompanyId,
            actionToDo : action
        },auth)
        .then(res => {
            // in base alla action passata, eseguo delle operazioni: aggiungo alla chat
            //console.log(res);
            this.sendEmail(companyId,'companies','ADD','','Qualcuno ti ha aggiunto al proprio network / Someone added you to their network','N');
            
            //if(messageYN=='Y'){
                
            //}

            if(this.lang=='Ita'){ alert('Operazione completata con successo'); }
            if(this.lang=='Eng'){ alert('Successful operation!'); }
            if(this.lang=='Den'){ alert('Succesfuld operation'); }

            this.getMyNetwork();

            return res+emailAddressTo+messageYN;
        })
        .catch(
        error => {
        
        return error;
        }
        );
    },
    businessCardAction(action, companyId, guid){

      
      if(action=='addtomyfavorite'){
        // in ogni caso aggiungo ai preferiti, il server controlla se è già stata aggiunta
        this.addToMyNetwork(companyId,'companies','0','ADD','','Y');
      }

      if(action=='sendmessage'){
        // in ogni caso aggiungo ai preferiti, il server controlla se è già stata aggiunta
        this.addToMyNetwork(companyId,'companies','0','ADD','','N');

        // quest'operazione è possibile solo nelle pagine: area messaggi, network, match making
        router.push('/mychats');
      }

      if(action=='reqvisura'){
        // quest'operazione è possibile solo nelle pagine: area messaggi, network, match making
        this.sendRequestVisura(companyId,guid);
      }

      //alert(action+'--'+companyId);
      return action+guid;
    },
    doNothing(){
      return null;
    },
    returnYear(dt){
      if(dt){
       return dt.substring(0,4);
      }else{
        return '';
      }
    },
    setSelectedCompanyIdDetail(companyId,table,addNetwork,sendMessage){

        //console.log(this.getcompanyTableBusinessCard + '<<<<<<<<');

        this.$store.dispatch('setCompanyIdBusinessCard',companyId );
        this.$store.dispatch('setCompanyTableBusinessCard',table );

        //console.log(this.getcompanyTableBusinessCard + '<<<<<<<<');

        let canSendMessage = sendMessage;
        
        //this.$store.dispatch('setCompanyMessageBusinessCard',sendMessage );

        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/isInMyNetwork',
                {
                   companyId : companyId,
                   MyCompanyId: this.MyCompanyId,
                   table : table
                },auth)
                .then(res => {
                    
                    
                    let response = res.data.message;
                    //console.log(response+'kokokokoko');
                    //alert(response);
                    if(response=='OK'){
                        
                        this.$store.dispatch('setCompanyNetworkBusinessCard','N' );
                    }else{
                        
                        this.$store.dispatch('setCompanyNetworkBusinessCard','Y' );
                    }
                    
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
        
        // banned or not 
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/bannedornot',
                {
                    companyIdFrom : this.MyCompanyId,
                    companyIdTo : companyId,
                    companyTable : table,
                   
                },auth)
                .then(res => {
                  //console.log(res);
                   if(res.data.message=='OK'){
                       canSendMessage = 'Y';
                     this.$store.dispatch('setCompanyMessageBusinessCard',canSendMessage );
                   }else{
                       canSendMessage = 'N';
                     this.$store.dispatch('setCompanyMessageBusinessCard',canSendMessage );
                   }
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );


    },
    returnMyWorkedNetworkScore(a,b){
        if(a!=null && a!=''){
            return a;
        }else{
            return b;
        }
    },
    setViewedAllChatsByGuid(){
        const auth = {
            headers: {Authorization:'JWT ' + this.getToken} 
        }
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    guid : this.selectedGuid,
                    userId : this.getUserId,
                    actionToDo : 'VIEWED'
                },auth)
                .then(res => {
                    
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
        return '';
    },
    banCompanyConfirm(guid,companyId1,companyId2){
        let companyIdBanned = -1;
         if(companyId1!= null && companyId1!='')
         {
            companyIdBanned = companyId1;
         }

         if(companyId2!= null && companyId2!='')
         {
            companyIdBanned = companyId2;
         }
        if(this.lang=='Ita'){ this.confirmBanCompany = 'Confermi l\'operazione?'; }
        if(this.lang=='Eng'){ this.confirmBanCompany = 'Do you confirm the operation?'; }
        if(this.lang=='Den'){ this.confirmBanCompany = 'Bekræfte operation?'; }
        if (confirm(this.confirmBanCompany))
        this.banCompany(guid,'BAN', companyIdBanned);
    },
    unBanCompanyConfirm(guid){
        let companyIdBanned = -1;
        if(this.lang=='Ita'){ this.confirmUnBanCompany = 'Confermi l\'operazione?'; }
        if(this.lang=='Eng'){ this.confirmUnBanCompany = 'Do you confirm the operation?'; }
        if(this.lang=='Den'){ this.confirmUnBanCompany = 'Bekræfte operation?'; }
        if (confirm(this.confirmUnBanCompany))
        this.banCompany(guid,'UNBAN',companyIdBanned);
    },
    returnDB(db){
        let dbText = '';
        if(db == 'company_infocamere'){dbText = this.searchevents(this.labels,'companies','companyinfocamere');  }
        if(db == 'company_house'){ dbText = this.searchevents(this.labels,'companies','companycompanyhouse');  }
        if(db == 'companies'){ dbText = 'GoBiz'; }
        if(db == 'company_denmark'){ dbText = this.searchevents(this.labels,'companies','companycvr'); }
        
        return dbText;
    },
    banCompany(guid,action,companyIdBanned){

        const auth = {
            headers: {Authorization:'JWT ' + this.getToken} 
        }
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    guid : guid,
                    companyIdBanned : companyIdBanned,
                    actionToDo : action
                },auth)
                .then(res => {
                    this.getMyNetwork();
                    
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
    },
    renderCompanyProfile(row){
        if(row.companyId1!= null && row.companyId1!='')
        {
            return row.companyName1 + ' ' + row.companyId1;
        }

         if(row.companyId2!= null && row.companyId2!='')
        {
            return row.companyName2 + ' ' + row.companyId2;
        }
    },
    renderCompanyProfileId(row){
        if(row.companyId1!= null && row.companyId1!='')
        {
            return row.companyId1;
        }

         if(row.companyId2!= null && row.companyId2!='')
        {
            return row.companyId2;
        }
    },
    returnViewed(viewed){
        if(viewed=='1'){
            if(this.lang=='Ita'){
                return 'vista';
            }else{
                return 'viewed';
            }
        }else{
            if(this.lang=='Ita'){
                return 'non vista';
            }else{
                return 'not viewed';
            }
        }
    },
    generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        
        return newGuid;
    },
    setBackgroudStyleStatus(userId, firstMessage, companyTable){
        if(userId != this.getUserId){ return 'background-color: #07ff31; width: 50px;'; } // email da leggere

        if(userId == this.getUserId){ 
            if(companyTable!='companies' && firstMessage=='Y'){ return 'background-color: #ffc107; width: 50px;';  } // richiesta pendente verso companies extra GoBiz
            if(companyTable=='companies' && firstMessage=='Y'){ return 'background-color: #07baff; width: 50px;';  } // primo messaggio inviato e in attesa di risposta
            if(companyTable=='companies' && firstMessage=='N'){ return 'background-color: #ff07f7; width: 50px;';  } // messaggio inviato e in attesa di risposta
        }
        
    },
    setBackgroudStyleStatusText(userId, firstMessage, companyTable){
        if(userId != this.getUserId){ 
            if(this.lang=='Ita'){
                return 'Email da leggere'; 
            }else{
                return 'Emai to read'; 
            }
        } // email da leggere

        if(userId == this.getUserId){ 
            if(companyTable!='companies' && firstMessage=='Y'){ 
                if(this.lang=='Ita'){
                    return 'Richiesta pendente'; 
                }else{
                    return 'Pending request'; 
                }
            } // richiesta pendente verso companies extra GoBiz

            if(companyTable=='companies' && firstMessage=='Y'){ 
                if(this.lang=='Ita'){
                    return 'In attesa di risposta'; 
                }else{
                    return 'Waiting for an answer'; 
                }  
            } // primo messaggio inviato e in attesa di risposta

            if(companyTable=='companies' && firstMessage=='N'){ 
                if(this.lang=='Ita'){
                    return 'In attesa di risposta'; 
                }else{
                    return 'Waiting for an answer'; 
                }  
            } // messaggio inviato e in attesa di risposta
        }
        
    },
    printCompanyName(cosa, cName1, cName2){
        
      if(cosa!=''){
          if(cName1!=null && cName1!=''){ return cName1; }
        if(cName2!=null && cName2!=''){ return cName2; }
      }  
        
    },
    backToMyCompanies(){
        router.push('/dashboard');
    },
    renderMessageViewed(viewed){
        if(viewed==1){return 'V'; }else{return ''; }
    },
    frontEndDateFormat(date) {
        
        let dateDay = date.split('T');
        let dateHourTemp = dateDay[1].split('Z');
        let dateHour = dateHourTemp[0].split('.');
        let dateEng = dateDay[0];
        let dateItaTemp = dateDay[0].split('-');
        let dateIta = dateItaTemp[2]+'-'+dateItaTemp[1]+'-'+dateItaTemp[0];
        
        if(this.lang=='Ita'){ return dateIta+' '+dateHour[0]; }else{ return dateEng+' '+dateHour[0]; }
        
    },
    setBackgroudStyle(userIdx,userIdMe){
        
        if(userIdMe == userIdx){
            return 'border: 2px solid red; padding: 20px; border-radius: 20px 60px; background-color: aquamarine;';
        }else{
            return 'border: 2px solid red; padding: 20px; border-radius: 60px 20px; background-color: #b3d7ff;';
        }
    },
    updateTranslation(messageTranslated,chatId){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    companyIdFrom : this.getCompanyIdTo,
                    companyIdTo : this.selectedCompanyId,
                    companyTable : this.mynetworkTable,
                    blockchainsHash : '',
                    message : this.newmessage,
                    messageTranslated : messageTranslated,
                    chatId : chatId,
                    viewed: '0',
                    actionToDo : 'MODT'
                },auth)
                .then(res => {
                    this.setSelectedCompanyId(this.selectedCompanyId,this.mynetworkTable, this.selectedGuid,null,null);
                    
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );

            }else{
                return null;
            }
    },
    sendEmail(companyIdTo, companyTable, action,emailAddressTo,newMessage,printMessage){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

        if(companyTable=='companies'){
            
            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyAll',
                        {
                            id : companyIdTo,
                            table : companyTable,
                            what : ''
                        },auth)
                        .then(res => {
                            
                            let emailTo = '';
                            res.data.network.forEach(em =>{
                                if(emailTo==''){ emailTo = em.email; }else{
                                    emailTo = emailTo + ',' + em.email;
                                }
                            });
                            
                            gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/sendemail',
                            {
                                userId : userId,
                                emailAddressTo : emailTo,
                                emailAddressFrom : this.selectedCompanyEmailFrom,
                                companyFrom : this.selectedCompanyName,
                                message : newMessage,
                                action: action
                            },auth)
                            .then(res1 => {
                                
                                return res1;
                            })
                            .catch(
                                error => {
                                
                                return error;
                                }
                            );
                        })
                        .catch(
                            error => {
                            
                            return error;
                            }
                        );

                if(printMessage=='Y'){
                    if(this.lang=='Ita'){ alert('Operazione completata con successo!'); }
                    if(this.lang=='Eng'){ alert('Operation completed succesfully!'); }
                    if(this.lang=='Den'){ alert('Operation afsluttet med succes!'); } 
                }
               

                //if(this.lang=='Ita'){ alert('Operazione completata con successo'); }
                //if(this.lang=='Eng'){ alert('Successful operation!'); }
                //if(this.lang=='Den'){ alert('Succesfuld operation'); }
                
                this.newmessage = null;
        }

    },
    sendRequestVisura(companyIdfromCard, guid){
        this.newmessage = 'Request for an Updated Version of the Company Report';
        
        this.sendNewMessageBusinessCard(companyIdfromCard,guid);
    },
    sendNewMessageBusinessCard(companyIdfromCard,guid){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {
                let firstMessage = 'N';
                if(guid=='' || guid==null){ 
                  firstMessage = 'Y'; 
                  guid = this.generateUUID();
                }
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    companyIdFrom : this.MyCompanyId,
                    companyIdTo : companyIdfromCard,
                    companyTable : 'companies',
                    guid : guid,
                    firstMessage: firstMessage,
                    blockchainsHash : '',
                    message : this.newmessage,
                    messageTranslated : '',
                    chatId : '',
                    viewed: '0',
                    actionToDo : 'ADD'
                },auth)
                .then(res => {
                    this.sendEmail(companyIdfromCard,'companies','SEND','',this.newmessage,'Y');
                    //this.setSelectedCompanyIdDetail(companyIdfromCard,'companies');
                    this.newmessage = null;
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
                
            }else{
                return null;
            }
    },
    sendNewMessage(){
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            let companyIdFromNew = this.getCompanyIdTo;
            if(companyIdFromNew==null || companyIdFromNew==undefined){
                companyIdFromNew = this.mycompanies.id;
            }
            
            if(userId!=null)
            {
                let firstMessage = 'N';
                if(this.mynetworkChatList.length==0){ firstMessage = 'Y'; }
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/modchats',
                {
                    userId : userId,
                    companyIdFrom : companyIdFromNew,
                    companyIdTo : this.selectedCompanyId,
                    companyTable : this.mynetworkTable,
                    guid : this.selectedGuid,
                    firstMessage: firstMessage,
                    blockchainsHash : '',
                    message : this.newmessage,
                    messageTranslated : '',
                    chatId : '',
                    viewed: '0',
                    actionToDo : 'ADD'
                },auth)
                .then(res => {
                    this.sendEmail(this.selectedCompanyId,this.mynetworkTable,'SEND','',this.newmessage,'N');
                    this.setSelectedCompanyId(this.selectedCompanyId,this.mynetworkTable,this.selectedGuid,null,null);
                    
                    this.newmessage = null;
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
                
            }else{
                return null;
            }
    },
    onClickDeleteNetwork(companyId,table){
        if(this.lang=='Ita'){ this.confirmDeleteNetwork ='Confermi la cancellazione?'; }
        if(this.lang=='Eng'){ this.confirmDeleteNetwork = 'Confirm the cancellation?'; }
        if(this.lang=='Den'){ this.confirmDeleteNetwork = 'Bekræft aflysningen?'; }

      if (confirm(this.confirmDeleteNetwork))
        this.delSelectedCompanyId(companyId,table)
    },
    delSelectedCompanyId(companyId,table){
        
            const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {

                // recupero i dati aziendali
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/NetworkJoinByMe',
                {
                    userId : userId, 
                    companyId : companyId, 
                    companyTable : table,
                    myCompany : this.MyCompanyId,
                    actionToDo : 'DEL'
                },auth)
                .then(res => {
                    this.getMyNetwork();
                
                    return res;
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );

            }else{
                return null;
            }
        
    },
    translateGoogle(text,chatId){

        let texToTranslate = text;
        let langFrom = this.lang;
        let langTo = '';
        let textTranslated = '';
        if(langFrom == 'Ita'){ langFrom = 'it'; langTo = 'en';}else{ langFrom = 'en'; langTo = 'it'; }
        gAxios.get('https://translate.googleapis.com/translate_a/single?client=gtx&sl='+langFrom+'&tl='+langTo+'&dt=t&q='+texToTranslate,
                {
                   
                })
                .then(res => {
                    
                    let tempTextTranslated = res.data[0];
                    tempTextTranslated.forEach(element => {
                        textTranslated += element[0]+' ';
                        
                    });
                    
                    // se ritorno correttamente, allora aggiorno la chat
                    this.updateTranslation(textTranslated,chatId);
                })
                .catch(
                error => {
                
                return error;
                }
                );
    },
    setMyNetworkTable(table){
        this.mynetworkTable = table;
    },
    getMyNetwork(){

        this.$store.dispatch('setNeedNetworkRefresh','N' );

        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {
                this.mynetworkAll = [];
                this.mynetwork = [];
                this.mynetworkAllBanned = [];
                this.mynetworkAll = [];
                this.addedToNetwork = [];

                
                
                // non lavorati 1
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ1',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            this.mynetwork.push(n);
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                // non lavorati 2
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ2',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            this.mynetwork.push(n);
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                // non lavorati 3
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ3',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            this.mynetwork.push(n);
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                // non lavorati 4
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ4',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            this.mynetwork.push(n);
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
               
                return error;
                }
                );

                
                // bannati from me and to me
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLBANNED1',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    let trovato = 0;
                    if(network.length>0){
                        network.forEach(n=>{
                                trovato = 0;
                                this.mynetworkAllBanned.forEach(nn=>{
                                    if( (nn.id == n.id )  ){
                                        trovato++;
                                    }
                                }) 

                                if(trovato==0){
                                    this.mynetworkAllBanned.push(n);
                                }
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLBANNED2',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    let trovato = 0;
                    if(network.length>0){
                        network.forEach(n=>{
                                trovato = 0;
                                this.mynetworkAllBanned.forEach(nn=>{
                                    if( (nn.id == n.id )  ){
                                        trovato++;
                                    }
                                }) 

                                if(trovato==0){
                                    this.mynetworkAllBanned.push(n);
                                }
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL1',
                   userId :userId
                },auth)
                .then(res => {
                   
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAll.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAll.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                   
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL2',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAll.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAll.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL3',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAll.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAll.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL4',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAll.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAll.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
                {
                    userId : userId,
                    companyId : this.MyCompanyId,
                    //userId : '13',
                    action : 'ADDEDTONETWORK2'
                },auth)
                .then(res => {
                    
                    this.addedToNetwork = res.data.company;
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

            }else{
                return null;
            }
    },
    setSelectedCompanyId(companyId,table,guid,id1,id2){
        
        this.selectedCompanyId = companyId;
        if(id1!=null && id1!=''){ this.selectedCompanyId = id1; }
        if(id2!=null && id2!=''){ this.selectedCompanyId = id2; }
        
        // esco e vado alla chat di questa azienda
        router.push( { name: 'mychats', params: { idCompany: this.selectedCompanyId, table: table, guid: guid, id1: id1, id2:id2 } } );
        
        this.selectedGuid = guid;
        this.setMyNetworkTable(table);
        if(guid == '-1'){
            guid = this.generateUUID();
            this.selectedGuid = guid;
        }

        if(this.selectedCompanyId!=null){
            // chiamo la chat e i dati dell'azienda
            const userId = this.getUserId;
            const newGuid = guid;
            const userIdParent = this.getIdUsersParent;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {

                // recupero i dati aziendali
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyAll',
                {
                    id : this.selectedCompanyId,
                    table : table,
                    what : 'details'
                })
                .then(res => {
                    
                    this.mycompanies = res.data.network[0];
                    
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );
                

                // recupero tutte le chat
                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/getchats',
                {
                   companyIdTo : this.selectedCompanyId,
                   companyIdFrom : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   userIdParent : userIdParent,
                   guid : newGuid,
                   userId :userId,
                   action: 'ALL'
                },auth)
                .then(res1 => {
                    this.mynetworkChatList = res1.data.chats;
                    
                })
                .catch(
                    error => {
                    
                    return error;
                    }
                );

            }else{
                return null;
            }
        }else{
            this.getMyNetwork();
        }

    },
    /*
    setSelectedCompanyIdDetail(companyId,table){
        this.selectedCompanyTable = table;
        
        const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }
        
        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                {
                   companyId : companyId,
                   action : table
                },auth)
                .then(res => {
                    
                    this.mycompaniesDetails = res.data.company[0];

                    if(table=='companies'){
                        gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/company/getCompanyBusinessCard',
                        {
                        companyId : companyId,
                        action : 'companies_doc'
                        },auth)
                        .then(res1 => {
                            
                            // docs here
                            
                            if(res1.data.company.length>0)
                            {
                                this.mycompaniesDetailsDocs = res1.data.company;
                            }
                            
                        })
                        .catch(
                        error => {
                        
                        return error;
                        }
                        );
                    }
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );
        


    },
    */
    modifyCompany(company){
        this.$store.dispatch('SetregisteredCompany', company );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updatecompany');
        
    },
    modifyUsers(user){
        this.$store.dispatch('SetregisteredUser', user );
        //setTimeout(router.push('/updatecompany'),3000);
        router.push('/updateuser');
        
    },
    getM(){
        
    return null;
    },
    searchevents(events,filterValue,label){
    //events = events2;
    if(events){
        
        const filter = event => 
        event.tableName.includes(filterValue) &&
        event.fieldName.includes(label)
    
        var filteredArray = events.filter(filter);
        if(filteredArray.length >0)
        {
        var labelName = 'label'+this.lang;
        return filteredArray[0][labelName];
        }else{
        return '--';
        }
        
    }else{
        return null;
    }
    
    }
        
  },
  computed :{
    getcompanyTableBusinessCard(){
        return this.$store.getters.getcompanyTableBusinessCard;
    },
    lang(){
      var lng = this.$store.getters.lang;
        if(lng)
        {
          return lng;
        }else{
          return '';
        }
    },
    labels(){
      return this.$store.getters.labels;
    },
    dropM(){
      
      var menu = this.$store.getters.dropM;
      if(menu){
        //this.dropM1 = menu[11];
        return menu;
      }else{
        return null;
      }
      
    },
    getToken(){
       // return null;
       return this.$store.getters.getUserToken;
    },
    getUserId(){
        return this.$store.getters.getUserId;
    },
    getIdUsersParent(){
        return this.$store.getters.getIdUsersParent;
    },
    getCompanyIdTo(){
        return this.$store.getters.getCompamyIdTo;
    },
    getneedNetworkRefresh(){
        return this.$store.getters.getneedNetworkRefresh;
    }

    
  },
  created() {
     
    
  },
  watch : {
      dropM: function () {
      // potrei anche leggere il nuovo e il vecchio valore (newdropMservLandlinePrefixId,olddropMservLandlinePrefixId)
      return null;
    },
      getM: function () {
          return null;
    },
    lang: function () {
        
    },
    getneedNetworkRefresh: function(){
        //console.log(this.getneedNetworkRefresh+'<<<<<<<<<<<');
        if(this.getneedNetworkRefresh=='Y'){
            this.getMyNetwork();
        }
    },
    getcompanyTableBusinessCard: function () {
        //console.log('quando quando');
      if(this.getcompanyTableBusinessCard!=null){
        //console.log('sskdj sdkjsdk jdsd jksdjksdj ksdjksd jskdj sk');
      }
      
    },
        
  },
  components: {
    
  },
  mounted: function () {
    
    // DEVE ESSERE STATA SELEZIONATA IN PRECEDENZA
    //this.MyCompanyId = this.getCompanyIdTo;
    var reg = this.$store.getters.getregisteredCompany;
    if(reg!=null){ 
        this.MyCompanyId = reg.id;
        
    }
    
    this.mynetworkAllBanned = [];

    this.$nextTick(function () {
        
        const userId = this.getUserId;
            const auth = {
                headers: {Authorization:'JWT ' + this.getToken} 
            }

            if(userId!=null)
            {
                
                // non lavorati 1
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ1',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            this.mynetwork.push(n);
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                // non lavorati 2
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ2',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            this.mynetwork.push(n);
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                // non lavorati 3
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ3',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            this.mynetwork.push(n);
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                // non lavorati 4
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLGOBIZ4',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            this.mynetwork.push(n);
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
               
                return error;
                }
                );

                
                // bannati from me and to me
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLBANNED1',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    
                    let trovato = 0;
                    if(network.length>0){
                        network.forEach(n=>{
                                trovato = 0;
                                this.mynetworkAllBanned.forEach(nn=>{
                                    if( (nn.id == n.id )  ){
                                        trovato++;
                                    }
                                }) 

                                if(trovato==0){
                                    this.mynetworkAllBanned.push(n);
                                }
                        }) 
                    }

                
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALLBANNED2',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let network = res.data.network;
                    let trovato = 0;
                    if(network.length>0){
                        network.forEach(n=>{
                                trovato = 0;
                                this.mynetworkAllBanned.forEach(nn=>{
                                    if( (nn.id == n.id )  ){
                                        trovato++;
                                    }
                                }) 

                                if(trovato==0){
                                    this.mynetworkAllBanned.push(n);
                                }
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL1',
                   userId :userId
                },auth)
                .then(res => {
                   
                    
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAll.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAll.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                   
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL2',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAll.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAll.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL3',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAll.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAll.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/networks/allNetGoBiz/me',
                {
                   companyId : this.MyCompanyId,
                   companyTable : this.mynetworkTable,
                   action : 'ALL4',
                   userId :userId
                },auth)
                .then(res => {
                    
                    let trovata = 'xxxxxxxxxx--xxxxxxxxxx';
                    let network = res.data.network;
                    if(network.length>0){
                        network.forEach(n=>{
                            
                            if(n.companyName1!=null && n.companyName1!=trovata){ this.mynetworkAll.push(n); }
                            if(n.companyName2!=null && n.companyName2!=trovata){ this.mynetworkAll.push(n); }

                            if(n.companyName1!=null){ trovata = n.companyName1; }
                            if(n.companyName2!=null){ trovata = n.companyName2; }
                            
                        }) 
                    }
                    
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

                
                gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/chat/notifications',
                {
                    userId : userId,
                    companyId : this.MyCompanyId,
                    //userId : '13',
                    action : 'ADDEDTONETWORK2'
                },auth)
                .then(res => {
                    
                    this.addedToNetwork = res.data.company;
                    
                })
                .catch(
                error => {
                
                return error;
                }
                );

            }else{
                return null;
            }
    })
    
}
}

</script>

<style scoped lang="scss">

.modal-body {
  text-align: left;
}
.alert-dismissible {
padding-right: 0rem;
}

</style>