<template>
  <div class="container">

    <div class="row" style="margin-bottom:20px;">
        <div class="col">
            
          </div>
    </div>
    <div class="row" style="margin-bottom:100px;">
        
      <div class="col reportbodytext" style="text-align: initial;">
          <br>
        <img alt="Vue logo" src="../../assets/gobiz-logo-rectangle-blue.png" width="125em" style="width: 80px;">  &nbsp;&nbsp;&nbsp;&nbsp;
        <button type="button" class="btn btn-pdf" style="font-size: .9em" @click="refreshReport()" ><span class="fas fa-sync-alt" style="font-size:25px;color:#ffffff;width: 100%;" ></span></button>
         <br>
         <br> Ricerche effettuate da Smart MM: <span class="reportResult">{{ricercheEffettuateSmartMM}}</span>
         <br> Ricerche effettuate da Advanced MM: <span class="reportResult">{{ricercheEffettuateAdvancedtMM}}</span>
         <br> Aziende aggiunte al proprio Network: <span class="reportResult">{{aziendeAggiunteProprioNetwork}}</span>
         <br> Aziende eliminate dal proprio Network: <span class="reportResult">{{aziendeEliminateProprioNetwork}}</span>
         <br> Invita a registrarsi a GoBiz: <span class="reportResult">{{InvitaRegistrarsiGoBiz}}</span>
         <br> Registrazioni di nuove Imprese a seguito della ricezione di invito a registrarsi: <span class="reportResult">{{registrazioniNuoveImpreseSeguitoInvito}}</span>
         <br> Richieste di accesso a Visura: <span class="reportResult">{{richiesteAccessoVisura}}</span>
         <br> Richieste di accesso a Visura che sono state Accettate: <span class="reportResult">{{richiesteAccessoVisuraAccettate}}</span>
         <br> Richieste di accesso a Visura che sono state Rifiutate: <span class="reportResult">{{richiesteAccessoVisuraRifiutate}}</span>
         <br> Richieste di aggiornamento Visura: <span class="reportResult">{{richiesteAgiornamentoVisura}}</span>
         <br> Download Visura: <span class="reportResult">{{downloadVisura}}</span>
         <br> % utenti che abbiano compilato e tradotto (AND) i seguenti campi presenti nel Tab Dati di Business: <span class="reportResult">{{utentiCompilatoTradottoDatiBusiness}}%</span>
         <br> Numero di COL aggiunti: <span class="reportResult">{{numeroCOLAggiunti}}</span>
         <br> Numero di Imprese Aggiunte: <span class="reportResult">{{numeroImpreseAggiunte}}</span>
         <br> Numero di Imprese approvate: <span class="reportResult">{{numeroImpreseApprovate}}</span>
         <br> Numero di utenti che abbiano completato solo il primo data-entry: <span class="reportResult">{{utentiCompletatoSoloPrimoDataentry}}</span>
         <br> Numero di utenti che abbiano avviato ma non completato il primo data-entry: <span class="reportResult">{{utentiAvviatoNonCompletatoPrimoDataentry}}</span>
         <br> Numero di utenti registrati: <span class="reportResult">{{numeroUtentiRegistrati}}</span>
         <br> Numero di chat attive (ovvero che contengano almeno 1 messaggio inviato/ ricevuto): <span class="reportResult">{{numeroChatAttive}}</span>

         <br> Numero di ricerche con 0 risultati su tutti i DB: <span class="reportResult">{{numeroRicercheZeroRisultati}}</span>
         <br> Numero di ricerche con 0 risultati su CPA: <span class="reportResult">{{numeroRicercheZeroRisultatiCPA}}</span>
      
      </div>
    </div>

  </div>
</template>


<script>

//import PopUpRisposta from '@/components/PopUpRisposta.vue';
import gAxios from 'axios';

export default {
  name: 'Report',
  data () {
    return {
      email : '',
      emailR : '',
      password : '',
      poupvisibile : false,
      poupvisibile2 : false,
      msg : '',
      ricercheEffettuateSmartMM : 0,
      ricercheEffettuateAdvancedtMM : 0,
      aziendeAggiunteProprioNetwork : 0,
      aziendeEliminateProprioNetwork : 0,
      InvitaRegistrarsiGoBiz : 0,
      registrazioniNuoveImpreseSeguitoInvito : 0, 
      richiesteAccessoVisura : 0,
      richiesteAccessoVisuraAccettate : 0,
      richiesteAccessoVisuraRifiutate : 0,
      richiesteAgiornamentoVisura : 0,
      downloadVisura : 0,
      utentiCompilatoTradottoDatiBusiness : 0,
      numeroCOLAggiunti : 0,
      numeroImpreseAggiunte : 0,
      utentiCompletatoSoloPrimoDataentry : 0,
      utentiAvviatoNonCompletatoPrimoDataentry : 0,
      numeroUtentiRegistrati : 0,
      numeroChatAttive : 0,
      numeroRicercheZeroRisultati : 0,
      numeroRicercheZeroRisultatiCPA : 0,
      numeroImpreseApprovate : 0
    }
  },
  components : {
    
  },
  computed : {
    lang(){
      return this.$store.getters.lang;
    },
    labels(){
      return this.$store.getters.labels;
    },
    getToken(){
      // return null;
      return this.$store.getters.getUserToken;
    },
  },
  methods : {
    refreshReport(){
      this.callReport();
    },
    callReport(){
      //console.log('ddddddddddd');
      const auth = {
        headers: {Authorization:'JWT ' + this.getToken} 
      }

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 1,
          
        },auth)
        .then(res => {
        this.ricercheEffettuateSmartMM = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 2,
          
        },auth)
        .then(res => {
        this.ricercheEffettuateAdvancedtMM = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 3,
          
        },auth)
        .then(res => {
        this.aziendeAggiunteProprioNetwork = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })
    
    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 4,
          
        },auth)
        .then(res => {
        this.aziendeEliminateProprioNetwork = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 5,
          
        },auth)
        .then(res => {
        this.InvitaRegistrarsiGoBiz = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 6,
          
        },auth)
        .then(res => {
        this.registrazioniNuoveImpreseSeguitoInvito = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 7,
          
        },auth)
        .then(res => {
        this.richiesteAccessoVisura = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 8,
          
        },auth)
        .then(res => {
        this.richiesteAccessoVisuraAccettate = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 9,
          
        },auth)
        .then(res => {
        this.richiesteAccessoVisuraRifiutate = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 10,
          
        },auth)
        .then(res => {
        this.richiesteAgiornamentoVisura = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 11,
          
        },auth)
        .then(res => {
        this.downloadVisura = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 12,
          
        },auth)
        .then(res => {
        this.utentiCompilatoTradottoDatiBusiness = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 13,
          
        },auth)
        .then(res => {
        this.numeroCOLAggiunti = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 14,
          
        },auth)
        .then(res => {
        this.numeroImpreseAggiunte = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 15,
          
        },auth)
        .then(res => {
        this.utentiCompletatoSoloPrimoDataentry = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 16,
          
        },auth)
        .then(res => {
        this.utentiAvviatoNonCompletatoPrimoDataentry = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 17,
          
        },auth)
        .then(res => {
        this.numeroUtentiRegistrati = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
        {
          reportType : 18,
          
        },auth)
        .then(res => {
        this.numeroChatAttive = res.data.messages;
         
        })
        .catch(
          error => {
          this.logout();
          
          return error;
          })

    gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
      {
        reportType : 20,
        
      },auth)
      .then(res => {
      this.numeroRicercheZeroRisultatiCPA = res.data.messages;
        
      })
      .catch(
        error => {
        this.logout();
        
        return error;
        })
  

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
          {
            reportType : 21,
            
          },auth)
          .then(res => {
          this.numeroRicercheZeroRisultati = res.data.messages;
            
          })
          .catch(
            error => {
            this.logout();
            
            return error;
            })

      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/reports/reporttofront',
          {
            reportType : 22,
            
          },auth)
          .then(res => {
          this.numeroImpreseApprovate = res.data.messages;
            
          })
          .catch(
            error => {
            this.logout();
            
            return error;
            })


    },
    onSubmit(){
      const formData = {
        email : this.email,
        password : this.password
      }
      
      this.$store.dispatch('login', {email: formData.email, password: formData.password})
      
      setTimeout(this.vefifyUserLogged,1000);

    },
    onSubmitR(){
      
      gAxios.post(process.env.VUE_APP_CONN_NODEJS+'/auth/rememberPassword',
                {
                   email : this.emailR
                })
                .then(res => {
                    
                    if(res.data.messages=='OK')
                    {
                      this.poupvisibile2 = true;
                      this.msg = this.searchevents(this.labels,'modify','emailsentsuccessfully');
                      
                      setTimeout(this.closeAlert,3000);
                    }else{
                      this.poupvisibile2 = true;
                      this.msg = this.searchevents(this.labels,'login','emailnotpresent') + ' ' + res.data.messages.response;
                      //console.log(res);
                      setTimeout(this.closeAlert,3000);
                    }

                    this.emailR = '';
                })
                .catch(
                error => {
                  this.poupvisibile2 = true;
                  this.msg = this.searchevents(this.labels,'login','emailnotpresent');
                      
                  setTimeout(this.closeAlert,3000);

                return error;
                }
                );
      

    },
    vefifyUserLogged(){
      this.$store.dispatch('notIsLogged').then(()=>{
        this.poupvisibile = true;
        this.msg = this.searchevents(this.labels,'login','accessdenied');
        
        
        setTimeout(this.closeAlert,3000);
      }).catch(()=>{
        this.poupvisibile = false;
        
      })
      
    },
    closeAlert(){
      this.poupvisibile = false;
      this.poupvisibile2 = false;
    },
    searchevents(events,filterValue,label){
        if(events){
          const filter = event => 
            event.tableName.includes(filterValue) &&
            event.fieldName.includes(label)
        
          var filteredArray = events.filter(filter);
          if(filteredArray.length >0)
          {
            if(this.lang)
            {
              var labelName = 'label'+this.lang;
              return filteredArray[0][labelName];
            }else{
              return '--';
            }
            
          }else{
            return '--';
          }
        }else{
          return null;
        }
      }

    },
    mounted(){
      this.$nextTick(function () {
        this.callReport();
      });
    }
 }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.count{
  font-size: 7em;
}
.btn{
  font-size: 3em;
}
.logininput{
    width: 70%;
    min-height: 90px;
    display: inline-block;
    background-color: #ffffff;
    border: 1px solid #0c294b; 
    color: #0c294b;
    font-size: 1.3em;
}
.loginbutton{
    width: 70%;
    min-height: 90px;
    display: inline-block;
    background-color: #0c294b;
    color: #e6d235;
}

.reportResult{
  font-weight: bold;
}
.reportbodytext{
  font-size: 1.2rem;
}

</style>